import service from '../../service'

const PATH = {
  depotMovement: '/stock/depot-movement',
}

export class Stock {
  // -- Depot movements
  static getDepotMovements = async (params) => {
    const response = await service.get(PATH.depotMovement, {
      params
    })

    return response
  }

  static getDepotMovement = async (id) => {
    const response = await service.get(`${PATH.depotMovement}/${id}`)
    return response
  }

  static createDepotMovement = async (data) => {
    const response = await service.post(PATH.depotMovement, data)
    return response
  }

  static createDepotMovementDetail = async (data) => {
    const {movement_id, ...rest} = data;
    const response = await service.post(`${PATH.depotMovement}/${movement_id}/detail`, rest)
    return response
  }

  static createDepotMovementConfirm = async (movement_id) => {
    const response = await service.post(`${PATH.depotMovement}/${movement_id}/confirm`, {})
    return response
  }

  static deleteDepotMovementDetail = async (params) => {
    console.log(params)
    const { id: movement_id, ...rest } = params;
    const response = await service.post(`${PATH.depotMovement}/${movement_id}/delete-detail`, rest);
    return response;
  };

  static printInternalWaybill = async (id, copias) => {
    const response = await service.get(`${PATH.depotMovement}/${id}/print/remito?copias=${copias}`,{
      responseType: 'blob',
    });
    return response;
  };

}