import { useState, useRef, useCallback, useEffect, useMemo, forwardRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Dropdown, DropdownButton, FormCheck } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useClickOutside, useOutOrders, useWaves } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'
import { InputBS, CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Actions, CreateOrderDeliveryModal } from './partials'
import useGetColumns from './columns'
import { initialValues, dataAdapter } from './constants'

import { OutOrderStatusI } from 'interfaces'
import routes from 'app/routing/routes'

import "./styles.css";
import TransportSelectionModal from './TransportSelectionModal'
import { labelColor } from '../List'

const ESTADOS_OLA = {
  EN_PREPARACION: 1,
  COMPLETA: 2,
  CANCELADA: 3,
}

const ESTADOS_ORDEN_EGRESO = {
  EN_REVISION: 3,
  COORDINACION_ENTREGA: 2,
  PENDIENTE_PREPARACION: 9,
  EN_PREPARACION: 4,
  PENDIENTE_DESPACHO: 10,
  EN_DESPACHO: 11,
  EN_DISTRIBUCION: 5,
  ENTREGADA: 7,
  CANCELADO: 8
};

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <FormCheck ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const WaveDetail = () => {
  const { id } = useParams()
  const [editing, setEditing] = useState(false)
  const [modal, setModal] = useState(false)
  const [transportModal, setTransportModal] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [queryMD, setQueryMD] = useState([])
  const { COLUMNS } = useGetColumns()

  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetWave, handleUpdateWave, handleAddShippingCarrierService, handleDeleteWaveOrder, handlePrintConsolidado } = useWaves()
  const [wave, setWave] = useState(initialValues)
  const {handleMarkOrderAsReady} = useOutOrders();

  const navigate = useNavigate();

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({...currentPos, target:''})
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver orden de egreso",
      icon: "bi bi-eye fs-4 text-primary",
      action: actionViewOrder
    })

    if (currentRow?.out_order_status_id == ESTADOS_ORDEN_EGRESO.EN_PREPARACION && !currentRow?.remito_id) {
      actions.push({
        label: "Armado orden de egreso",
        icon: "bi bi-box-arrow-in-down fs-4 text-success",
        action: actionOrderPackaging
      })
    }

    if (currentRow?.out_order_status_id == ESTADOS_ORDEN_EGRESO.EN_PREPARACION && currentRow?.remito_id) {
      actions.push({
        label: "Marcar como lista",
        icon: "bi bi-check-lg fs-4 text-success",
        action: actionMarkAsReady
      })
    }

    actions.push({
      label: "Asignar transportista",
      icon: "bi bi-truck fs-4 text-primary",
      action: () => setTransportModal(true)
    })

    if (wave.wave_status_id == ESTADOS_OLA.EN_PREPARACION && currentRow?.out_order_status_id == ESTADOS_ORDEN_EGRESO.EN_PREPARACION) {
      actions.push({
        label: 'Quitar de ola',
        icon: 'bi bi-x-circle text-danger',
        action: () => removeOutOrderFromWave(currentRow.id, currentRow.out_order_id, ESTADOS_ORDEN_EGRESO.PENDIENTE_PREPARACION)
      })

      actions.push({
        label: 'Enviar a revisión',
        icon: 'bi bi-x-circle text-warning',
        action: () => removeOutOrderFromWave(currentRow.id, currentRow.out_order_id, ESTADOS_ORDEN_EGRESO.EN_REVISION)
      })
    }

    return actions
  }

  const actionOrderPackaging = () => {
    if (currentRow) {
      navigate(`${routes.OUT_ORDERS}/${currentRow.out_order_id}`, { state: { referer: 'olas', wave_id: id }});
    }
  }

  const actionViewOrder = () => {
    if (currentRow) {
      navigate(`${routes.OUT_ORDERS}/${currentRow.out_order_id}`);
    }
  }
  /* Fin menu contextual */

  const handleOrderSelection = (order) => {
    // console.log(selectedOrders)
    // console.log(order)

    if (selectedOrders.length == 0) {
      setSelectedOrders([order])

      return;
    }

    setSelectedOrders((prevSelectedOrders) => {
      if (prevSelectedOrders.some(i => i.id === order.id)) {
        return prevSelectedOrders.filter(i => i.id !== order.id)
      } else {
        return [...prevSelectedOrders, order]
      }
    })
  }

  const getWave = useCallback(async () => {
    try {
      const response = await handleGetWave(id, { getDetail: true })
      const data = dataAdapter(response.data.result)

      setWave(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetWave])

  const handleUpdate = async (values) => {
    try {
      let data = {
        name: values.name,
      }
      const response = await handleUpdateWave(id, data)

      toast.success("Registro actualizado correctamente")
    } catch (error) {
      toast.error(error.message)
    } finally {
      setEditing(false)
      getWave()
    }
  }

  const onHideTransportSelection = async (data) => {
    setTransportModal(false)

    try {
      // console.log(data)
      if (data) {
        if (selectedOrders.length > 0) {
          for (let i = 0; i < selectedOrders.length; i++) {
            await handleAddShippingCarrierService(id, selectedOrders[i].id, { shipping_carrier_service_id: data.shipping_carrier_service_id })
          }
        } else {
          await handleAddShippingCarrierService(id, currentRow.id, { shipping_carrier_service_id: data.shipping_carrier_service_id })
        }

        toast.success("Registro actualizado correctamente", { theme: 'colored' })
        await getWave()
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
    }
  }

  const removeOutOrderFromWave = async (wave_order_id, out_order_id, out_order_status_id) => {
    try {
      const values = {
        out_order_status_id: out_order_status_id
      }
      const text = out_order_status_id === ESTADOS_ORDEN_EGRESO.PENDIENTE_PREPARACION
        ? `¿Desea eliminar la orden de egreso #${out_order_id} de la Ola? Volverá al estado "Pendiente de preparación"`
        : `¿Desea enviar la orden de egreso #${out_order_id} a revisión? Esto también eliminará la orden de egreso de la Ola`

      const confirmButtonTtext = out_order_status_id === ESTADOS_ORDEN_EGRESO.PENDIENTE_PREPARACION
        ? 'Sí, remover'
        : 'Sí, enviar a revisión'

      const resp = await Swal.fire({
        title: "Advertencia",
        text: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: confirmButtonTtext,
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp?.isConfirmed) {
        const response = await handleDeleteWaveOrder(id, wave_order_id, values)

        const successMsg = out_order_status_id == ESTADOS_ORDEN_EGRESO.PENDIENTE_PREPARACION
          ? `Orden de egreso #${out_order_id} eliminada satisfactoriamente de la Ola`
          : `Orden de egreso #${out_order_id} eliminada satisfactoriamente de la Ola y enviada a revisión`

        toast.success(successMsg)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getWave()
    }
  }

  const actionMarkAsReady = async (wave_order_id) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Está seguro de marcar como lista la orden #${currentRow.out_order_id} de la Ola #${id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, marcar como lista`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleMarkOrderAsReady(currentRow.out_order_id)
        toast.success(`La órden ${currentRow.out_order_id} fue marcada como lista`, {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getWave()
    }
  }

  const printConsolidado = async() => {
    if (id) {
      try {
        const response = await handlePrintConsolidado(id)

        if (response.status === 200) {
          toast.success('¡Consolidado descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `consolidado-${id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          toast.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const columns = useMemo(() => [
    {
      id: 'selection',
      Cell: ({ row }) => (
        <IndeterminateCheckbox
          type="checkbox"
          {...row.getToggleRowSelectedProps()}
          onClick={() => handleOrderSelection(row.original)}
          disabled={selectedOrders.some(i => i.address_id_ship != row.original.address_id_ship)}
        />
      ),
    },
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{fontSize: "1.5rem", cursor:"pointer"}} onClick={event=>handleContextMenu(event, row)} ref={threeDotsRef}></i>
        </>
      )
    }
  ], [COLUMNS])

  const paginationOptions = {
    // totalSize: totalCount,
    // obQuery:queryMD ,
    // setObQuery:setQueryMD
  };

  const handleSelectedRows = (e, flatRows) => {
    // { "0": true,"1": true,"3": true}
    //const selectedData = selectedFlatRows.map((row) => row.original);
  }

  useEffect(() => {
    getWave()
  }, [getWave])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <CreateOrderDeliveryModal show={modal} onHide={() => setModal(false)} waveId={id} orders={selectedOrders} handleOrders={setSelectedOrders} handleGetWave={getWave} />

      <TransportSelectionModal loading={loading} show={transportModal} onHide={onHideTransportSelection} selectedOrders={selectedOrders} />

      <DetailContainer
        title={`Ola #${id}`}
        statusV2={wave &&
          <>
            <div className={`badge ${labelColor(wave.wave_status_id)} justify-content-center fw-normal`} style={{width:"150px"}}>
              {wave.wave_status_label}
            </div>
          </>
        }
        buttons={<Actions editing={editing} handleEditing={setEditing} submitRef={submitRef} cancelRef={cancelRef} disabled={loading || wave.id==""} />}
      >
        <Formik
          initialValues={wave}
          // validationSchema={schema}
          onSubmit={async (values, actions) => {
            try {
              handleUpdate(values)
            } catch (error) {
              actions.handleReset()
            }
          }}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column' encType='multipart/form-data'>
                <div className='row my-8'>
                  <div className='col-10'>
                    <InputBS id='name' name='name' label='Nombre' disabled={!editing} />
                  </div>

                  <div className='col-2'>
                    <InputBS id='created_at' name='created_at' label='Fecha creación' disabled />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='mb-8 d-flex justify-content-between'>
                  <h4>Órdenes en la ola</h4>

                  <DropdownButton align="end" title="Acciones" disabled={loading || wave.id == ""}>
                    <Dropdown.Item onClick={() => setModal(true)} disabled={selectedOrders.length === 0}>
                      <i className={`bi bi-boxes ${selectedOrders.length === 0 ? 'text-secondary' : 'text-danger'} fs-3 me-3`} />
                      Agrupar envío
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => setTransportModal(true)} disabled={selectedOrders.length === 0}>
                      <i className={`bi bi-truck ${selectedOrders.length === 0 ? 'text-secondary' : 'text-primary'} fs-3 me-3`} />
                      Asignar transportista
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => printConsolidado()} >
                      <i className='bi bi-printer text-success fs-3 me-3' />
                      Imprimir consolidado
                    </Dropdown.Item>
                  </DropdownButton>
                </div>


                <TableContainer>
                  {(loading || wave.id == "")  && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px"}}></l-helix>}

                  <CustomTable columns={columns} data={wave?.orders} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} handleSelectedRows={handleSelectedRows} handleContextMenu={handleContextMenu} />
                </TableContainer>

                <ContextMenu
                  ref={contextRef}
                  showContextMenu={showContextMenu}
                  setShowContextMenu={setShowContextMenu}
                  actions={availableActions()}
                  currentPos={currentPos}
                />

                {editing && (
                  <>
                    <div className='separator my-8'></div>

                    <div className='d-flex align-items-center justify-content-end'>
                      <Button
                        ref={cancelRef}
                        type='button'
                        variant='secondary'
                        className='me-3'
                        onClick={() => {
                          setEditing(false)
                          handleReset()
                        }}
                        disabled={loading}
                      >
                        <i className="bi bi-slash-circle" />
                        Cancelar
                      </Button>

                      <Button ref={submitRef} type='submit' variant='primary' disabled={loading}>
                        <i className='bi bi-save me-2' />
                        Guardar
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default WaveDetail