import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'

import { InputBS, SelectField } from 'components'

const initialValues = {
  id: '',
  product_var_id: '',
  name: '',
  discount: '0.00000',
  is_active: 1,
}

const UpdateDiscountModal = ({ show, onClose, discount, onSave }) => {
  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    if (discount) {
      setValues({
        id: discount?.id,
        product_var_id: discount?.product_var_id,
        name: discount?.product_var_name,
        discount: discount?.discount,
        is_active: discount?.is_active,
      })
    }
  }, [discount])

  if (!show) return null

  return (
    <Modal size='xl' show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Actualizar descuento #{discount?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={values}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row'>
                  <div className='col-8'>
                    <InputBS id='name' name='name' label='Nombre' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='discount' name='discount' label='Descuento' maxLength={7} />
                  </div>

                  <div className='col-2'>
                    <SelectField
                      id='is_active'
                      name='is_active'
                      label='Activo'
                      options={[
                        { value: 0, label: 'No' },
                        { value: 1, label: 'Sí' }
                      ]}
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>
                <div className='text-end'>
                  <Button type='submit' variant='primary'>Guardar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateDiscountModal