import React, {useState} from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Field, useField, useFormikContext } from 'formik';
import { FormLabel } from 'react-bootstrap';
import useProducts from 'hooks/Inventory/useProducts';

const accesorios = ['10499', '10500']

const ProductTypeahead = ({ name, label, ...rest }) => {
    const [options, setOptions] = useState([]);
    const {handleSearchProducts, handleGetProduct} = useProducts();
    
    const { setFieldValue, values } = useFormikContext();
    
    const  {product_type={}} = rest;

    const handleSearch = async (query) => {
      if (query.length > 2) {
        const data =  await handleSearchProducts({name:query})
        const options = data.result.map((p) => ({
          value: p.id,
          label: p.name
        }));
        setOptions(options);
      } else {
        return [];
      }
    };
    
    const loadProduct = async(reg)=>{
      if(reg && reg.length>0){
        handleGetProduct(reg[0].value).then((resp)=>{
          if(resp && resp.result && resp.result.length>0){
              setFieldValue('parent_product', resp.result[0])
              setFieldValue('product_type_name', resp.result[0]?.product_type_name)
              
          }         
        }, (err)=>console.log(err))
      }
      else{
        setFieldValue('parent_product', null)
        setFieldValue('product_type_name', null)
      }        
    }

    const labelStyle = {
        fontSize: "1.05rem",
        fontWeight: "500",
        color: "var(--bs-gray-800)"
    }

    return (
      <div>
          <style>
          {`
            .rbt-highlight-text {
              font-weight: bold;
              background-color:transparent;
              padding:0
            }
          `}
          </style>

          <FormLabel style={labelStyle}>{label}</FormLabel>
          <AsyncTypeahead
              id={name}
              placeholder="Buscar productos..."
              options={options} 
              onSearch={handleSearch}
              onChange={loadProduct}
          />
          {/* {form.touched[name] && form.errors[name] && <div>{form.errors[name]}</div>} */}

      </div>
    );
};

export default ProductTypeahead;
