import service from "../service";
import { ArticleI } from "../../interfaces";

const PATH = {
  ABArticle: "/ab/article",
  Action: "/ab/action",
  Via: "/ab/via",
  Shape: "/ab/shape",
  UnitType: "/ab/quantity",
  Size: "/ab/size",
  Potency: "/ab/potency",
  Control: "/ab/control"
};

export class Alfabeta {

  static getArticles = async (params:any) => {
    const response = await service.get(PATH.ABArticle, {params});
    return response;
  };

  static getArticle = async (id: ArticleI['id']) => {
    const response = await service.get(`${PATH.ABArticle}/${id}`);
    return response;
  };


  static getActions = async () => {
    const response = await service.get(PATH.Action);
    return response;
  };

  static getVias = async () => {
    const response = await service.get(PATH.Via);

    return response;
  };
  
  static getShapes = async () => {
    const response = await service.get(PATH.Shape);

    return response;
  };

  static getUnitTypes = async () => {
    const response = await service.get(PATH.UnitType);

    return response;
  };
  
  static getSizes = async () => {
    const response = await service.get(PATH.Size);

    return response;
  };

  static getPotencies = async () => {
    const response = await service.get(PATH.Potency);

    return response;
  };

  static getControls = async () => {
    const response = await service.get(PATH.Control);

    return response;
  };
  




}