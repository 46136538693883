import service from 'services/service';

const PATH = {
  salesOrders: '/sales-orders',
  outRequests: '/sales-orders/out-request',
  salesOrdersStatus: '/sales-orders/status',
  profitabilityAuth: '/sales-orders/profitability-auth',
  authMultiProfitability: '/sales-orders/profitability-auth/multiple-auth',
  unauthMultiProfitability: '/sales-orders/profitability-auth/multiple-unauth',
}

export class SalesOrders {
  static getSalesOrders = async (params, token) => {
    const response = await service.get(PATH.salesOrders, {
      params,
    })

    return response
  }

  static getSalesOrder = async (id, params, token) => {
    const response = await service.get(`${PATH.salesOrders}/${id}`, {
      params,
    })

    return response
  }

  static getSalesOrderStatus = async (params, token) => {
    const response = await service.get(PATH.salesOrdersStatus, {
      params,
    })

    return response
  }

  static addSalesOrder = async (data, token) => {
    const response = await service.post(PATH.salesOrders, data)

    return response
  }

  static addOutRequest = async (data) => {
    const response = await service.post(PATH.outRequests, data)
    return response
  }

  static updateSalesOrder = async (id, data, token) => {
    const response = await service.patch(`${PATH.salesOrders}/${id}`, data)

    return response
  }

  static updateSalesOrderStatus = async (id, new_status, params=undefined) => {

    const response = await service.post(`${PATH.salesOrders}/${id}/status/${new_status}`, params)

    return response
  }

  static checkSalesOrderPVP = async (id) => {
    const response = await service.get(`${PATH.salesOrders}/${id}/check-pvp`)
    return response
  }

  static updateOutRequestStatus = async (id, new_status) => {
    const response = await service.patch(`${PATH.outRequests}/${id}/status/${new_status}`)
    return response
  }

  static addDetail = async (id, data, token) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/detail`, data)

    return response
  }

  static addOutRequestDetail = async (id, data) => {
    const response = await service.post(`${PATH.outRequests}/${id}/detail`, data)
    return response
  }

  static updateDetail = async (id, detail_id, data, token) => {
    const response = await service.patch(`${PATH.salesOrders}/${id}/detail/${detail_id}`, data)

    return response
  }

  static updateDetailPrice = async (id, detail_id, data) => {
    const response = await service.patch(`${PATH.salesOrders}/${id}/detail/${detail_id}/update-price`, data)
    return response
  }

  static updateOutRequestDetail = async (id, detail_id, data) => {
    const response = await service.patch(`${PATH.outRequests}/${id}/detail/${detail_id}`, data)
    return response
  }

  static deleteDetail = async (id, detail_id, token) => {
    const response = await service.delete(`${PATH.salesOrders}/${id}/detail/${detail_id}`)

    return response
  }

  static deleteOutRequestDetail = async (id, detail_id) => {
    const response = await service.delete(`${PATH.outRequests}/${id}/detail/${detail_id}`)
    return response
  }

  static cloneSalesOrder = async (id) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/clone`)

    return response
  }

  static createOutOrder = async (id) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/out-order`)

    return response
  }

  static getSalesOrderFiles = async (id) => {
    const response = await service.get(`${PATH.salesOrders}/${id}/files`)
    return response
  }

  static getSalesOrderFile = async (id, file_id) => {
    const response = await service.get(`${PATH.salesOrders}/${id}/files/${file_id}`, { responseType: 'blob' })
    return response
  }

  static addSalesOrderFile = async (id, data) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/file`, data)
    return response
  }

  static deleteSalesOrderFile = async (id, file_id) => {
    const response = await service.delete(`${PATH.salesOrders}/${id}/file/${file_id}`)
    return response
  }

  static reactivateOutOrder = async (id) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/out-order/fill`)
    return response
  }

  static reactivateOutOrderAll = async () => {
    const response = await service.post(`${PATH.salesOrders}/out-order/fill`)
    return response
  }

  static getComprobantesAsociados = async (id, params) => {
    const response = await service.get(`${PATH.salesOrders}/${id}/comprobantes-asociados`, {
      params,
    })
    return response
  }

  static printSalesOrder = async (id) => {
    const response = await service.get(`${PATH.salesOrders}/${id}/print`, {
      responseType: 'blob',
    })
    return response
  }
  
  static removeReserved = async (data) => {
    const {sales_order_id, ...rest} = data;
    const response = await service.post(`${PATH.salesOrders}/${sales_order_id}/remove-reserved`, rest.items)

    return response
  }

  static createPartial = async (sales_order_id) => {
    
    const response = await service.post(`${PATH.salesOrders}/${sales_order_id}/out-order/create-partial`)

    return response
  }

  static getPendingDetail = async (params) => {
    const response = await service.get(`${PATH.salesOrders}/pending-detail/v2`, {
      params,
    })

    return response
  }

  static getPendingRent = async (params, token) => {
    const response = await service.get(PATH.salesOrders + '/profitability-auth', {
      params,
    })

    return response
  }

  static getPendingRentDetail = async (params, token) => {
    const response = await service.get(PATH.salesOrders + '/profitability-auth/detail', {
      params,
    })

    return response
  }

  static cancelSalesOrderSpecial = async (id) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/special-cancel`)
    return response
  }

  static authMultipleProfitability = async (data) => {
    const response = await service.post(PATH.authMultiProfitability, data)
    return response
  }

  static unauthMultipleProfitability = async (data) => {
    const response = await service.post(PATH.unauthMultiProfitability, data)
    return response
  }

  static updatePurchaseOrder = async (id, data) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/purchase-order`, data)
    return response
  }

  static updateObservations = async (id, data) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/observations`, data)
    return response
  }

  static updateDispatchObservations = async (id, data) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/dispatch-observations`, data)
    return response
  }

  static getNotes = async (id) => {
    const response = await service.get(`${PATH.salesOrders}/${id}/notes`)
    return response
  }

  static addNote = async (id, data) => {
    const response = await service.post(`${PATH.salesOrders}/${id}/notes`, data)
    return response
  }
}