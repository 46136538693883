export enum ListCostsStatusI {
  VIGENTE = 'Vigente',
  VENCIDO = 'Vencido',
  ANULADO = 'Anulado',
}

export enum ListCostsStatusColorI {
  VIGENTE = 'success',
  VENCIDO = 'danger',
  ANULADO = 'warning',
}