import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'

import { useSalesOrders, useOutOrders, useTransactionalDocuments, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './TransactionalDocumentsColumns'

export const label = (status) => {
  if (!status) {
    return ''
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "Aprobado c/observaciones";
    case "SUCCESS":
      return "Aprobado";
    case "DEFERRED":
      return "Factura Diferida";
    case "ERROR_NEGOCIO_AFIP":
      return "Error AFIP";
    case "DISCARDED":
      return "Descartada";
    case "CANCELED":
    case "ANULADO":
      return "Anulado";
    case "ERROR_PROCESAR_COMPROBANTE":
      return 'Error comprobante'
    default:
      return '';
  }
}

export const labelColor = (status) => {
  if (!status) {
    return ""
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "estado-amarillo";
    case "SUCCESS":
      return "estado-verde";
    case "ERROR_NEGOCIO_AFIP":
    case "ERROR_PROCESAR_COMPROBANTE":
      return "estado-rojo";
    case "DEFERRED":
      return "estado-celeste";
    case "CANCELED":
    case "DISCARDED":
    case "ANULADO":
      return "estado-naranja";
    default:
      return '';
  }
}

const TransactionalDocumentsTab = () => {
  const { values } = useFormikContext()
  const [transactionalDocuments, setTransactionalDocuments] = useState([])

  const { handleGetComprobantesAsociados } = useOutOrders()
  const { handlePrintFactura, handlePrintRemito } = useTransactionalDocuments()

  const getTransactionalDocuemnts = useCallback(async () => {
    if (values.id !== null) {
      try {
        const response = await handleGetComprobantesAsociados(values.id)
        const data = response.data.result.map(d => ({
          ...d,
          sales_order_id: values.sales_order_id,
          label: label(d.status),
          label_color: labelColor(d.status),
        }))

        setTransactionalDocuments(data)
      } catch (error) {
        setTransactionalDocuments([])
        toast.error(error.message)
      }
    }
  }, [handleGetComprobantesAsociados])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()
  const navigate = useNavigate();

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow?.afip && !currentRow?.remito && (currentRow?.status !== 'ERROR_NEGOCIO_AFIP' || currentRow?.status !== 'ERROR_PROCESAR_COMPROBANTE')) {
      actions.push({
        label: 'Imprimir comprobante',
        icon: 'bi bi-download text-primary',
        action: printFactura
      })
    }

    if (!currentRow?.afip && currentRow?.remito) {
      actions.push({
        label: 'Imprimir remito',
        icon: 'bi bi-download text-primary',
        action: printWaybill
      })
    }

    return actions
  }

  const printWaybill = async () => {
    try {
      let copias = 2
      const response = await handlePrintRemito(currentRow.id, copias)

      if (response.status === 200) {
        toast.success('¡Remito descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `remito-${currentRow.id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

      } else {
        // Manejar errores si la solicitud no fue exitosa
        console.error('Error al descargar el PDF')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const printFactura = async () => {
    try {
      const response = await handlePrintFactura(currentRow?.codigo_interno, currentRow?.id)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('¡Comprobante descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `comprobante-${currentRow.id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar el comprobante')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
      ),
    }
  ], [COLUMNS])

  useEffect(() => {
    getTransactionalDocuemnts()
  }, [getTransactionalDocuemnts])

  return (
    <>
      {/* <FileModal show={modal} onClose={handleCloseModal} onSave={addFile} selectedFile={selectedFile} handleSelectedFile={setSelectedFile} /> */}

      <div className='mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8'>
          <label className='form-label m-0'>Comprobantes del pedido</label>
        </div>

        <TableContainer>
          <CustomTable columns={columns} data={transactionalDocuments} />
        </TableContainer>

        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
          ref={contextRef}
        />

        {/* <FormTable columns={columns}>
          {transactionalDocuments.length <= 0
            ? <tr><td className='text-italic text-gray-400' colSpan={columns.length}>El pedido aún no tiene comprobantes asociados.</td></tr>
            : transactionalDocuments.map(td => (
              <tr key={`transactional-document-${td.tipo}-${td.id}`}>
                <td className='align-middle'>{td.tipo}</td>
                <td className='align-middle'>{td.number}</td>
                <td className='align-middle text-center'>{formatTableDate(td.date)}</td>
                <td className='align-middle text-center'>{td?.cae ? td.cae : '-'}</td>
                <td className='align-middle text-center'>
                  <span className={ `badge ${labelColor(td.status)} w-100 justify-content-center`}>
                    {td.status}
                  </span>
                </td>
                <td className='align-middle text-center'>
                  <Tooltip text='Descargar'>
                    <button
                      type='button'
                      className='btn btn-icon'
                      onClick={() => {
                        if (td.afip && !td.remito) {
                          if(td.status!='ERROR_NEGOCIO_AFIP')
                            printFactura(td)
                        } else {
                          printWaybill(values.id)
                        }
                      }}
                    >
                      <i className='bi bi-download fs-2 me-2 text-primary' />
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ))
          }
        </FormTable> */}
      </div>
    </>
  )
}

export default TransactionalDocumentsTab