import * as Yup from 'yup'

import { formatInputDate } from 'utilities'

const initialValues = {
  id: '',
  name: '',
  orders: [],
  created_at: '',
}

const schema = Yup.object().shape({

})

const ordersAdapter = (orders) => {
  let values = orders.map((order) => {
    return {
      ...order,
      created_at: formatInputDate(order.created_at),
      canceled_at: formatInputDate(order.canceled_at),
    }
  })

  return values
}

const dataAdapter = (data) => {
  const orders = ordersAdapter(data.orders)

  let values = {
    ...data,
    orders: orders,
    created_at: formatInputDate(data.created_at),
  }

  return values
}

export {
  initialValues,
  schema,
  dataAdapter,
}