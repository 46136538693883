import { FC } from 'react'
import { useIntl } from 'react-intl'
import { FormButtonI } from './types'

const FormButton: FC<FormButtonI> = ({ ref = null, text, loading = false, hasId = false, noAlign = false }) => {
  const intl = useIntl()

  return (
    <button
      ref={ref}
      className={`btn btn-primary ${!noAlign ? 'align-self-end' : ''}`}
      type='submit'
      disabled={loading}
    >
      {text
        ? text
        : loading
          ? intl.formatMessage({ id: 'GLOBAL.WAIT' })
          : hasId
            ? intl.formatMessage({ id: 'GLOBAL.EDIT' })
            : intl.formatMessage({ id: 'GLOBAL.SAVE' })
      }
    </button>
  )
}

export default FormButton