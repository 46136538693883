import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useSalesOrders, usePriceUpdate, useClickOutside } from 'hooks'

import { Checkbox, CustomTable, Label, SelectField, SwitchField } from 'components'
import ContextMenu from 'components/ContextMenu'

import { ArticlesModal, ArticleItemModal } from './'
import TotalSummaryTable from './TotalSummaryTable'
import { filterNewArticles, addArticlesInDetailAdapter, columnsSellOrder } from '../constants'

import { SalesOrderStatusI } from 'interfaces'
import { calculosVenta } from 'utilities/calculosVenta'

const ArticlesTab = ({ editing, handleEditing, handleGetSalesOrder, salesOrder }) => {
  const [modal, setModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [entities, setEntities] = useState([])
  const { values, handleReset } = useFormikContext()

  const [calcValues, setCalcValues] = useState({})

  const { loading, handleAddDetail, handleUpdateDetail, handleUpdatePrice, handleDeleteDetail } = useSalesOrders()
  const { handleGetPriceUpdates } = usePriceUpdate()
  const [priceUpdate, setPriceUpdate] = useState([])

  const handleCloseModal = () => { setModal(false) }

  const handleShowModal = () => { setModal(true) }

  const handleShowDetailModal = (detail) => {
    setDetailData(detail)
    setDetailModal(true)
  }

  const handleCloseDetailModal = () => {
    setDetailModal(false)
    handleReset()
    handleGetSalesOrder()
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (values.sales_order_status_id === SalesOrderStatusI.BORRADOR ){
      actions.push({
        label: "Editar",
        icon: "bi bi-pencil text-primary",
        action: actionEdit
      })
      actions.push({
        label: "Eliminar detalle",
        icon: "bi bi-trash text-danger",
        action: actionDelete
      })
    }
     

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      handleShowDetailModal(currentRow)
    }
  }

  const actionDelete = () => {
    if (currentRow) {
      handleDelete(currentRow.id)
    }
  }

  const actionUpdatePrice = async () => {
    if (currentRow) {
      try {
        const data = {
          ...currentRow,
        }
        const response = await handleUpdatePrice(values.id, currentRow.id, data)
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetSalesOrder()
      }
    }
  }
  /* Fin menu contextual */

  const handleSave = async (value) => {
    if (value.articles.length <= 0) {
      toast.error('Debe seleccionar un artículo')
      handleEditing(false)
      return
    }

    let articles = filterNewArticles(values.detail, value.articles)
    if (articles.length <= 0) {
      toast.error('No se seleccionaron artículos nuevos')
      handleEditing(false)
      return
    }

    try {
      await handleAddDetail(values.id, { detail: articles }, 'token')
      toast.success("Registro agregado correctamente", { theme: "colored" })
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleEditing(false)
      handleGetSalesOrder()
    }
  }

  const handleUpdate = async (detailId, data) => {
    try {
      let detail = {
        product_var_id: data.product_var_id,
        name: data.name,
        line: data?.line ? data.line : null,
        pvp: Number(data.precio_base),
        list_cost: Number(data.list_cost),
        qty: Number(data.qty),
        units: Number(data.units),
        discount: Number(data.discount),
        price: Number(data.unit_price),
        profitability: Number(data.profitability),
        profitability_rate: data.profitability_rate,
        vat: data.vat,
        vat_rate: Number(data.vat_rate),
        vat_amount: Number(data.vat_amount),
        subtotal: Number(data.subtotal),
        total: Number(data.total),
      }

      await handleUpdateDetail(values.id, detailId, { detail: detail }, 'token')

      toast.success("Registro actualizado correctamente", { theme: "colored" })
      handleCloseDetailModal()
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
    }
  }

  const handleDelete = async (detailId) => {
    const resp = await Swal.fire({
      title: "Advertencia",
      text: "¿Confirma la eliminación del artículo del pedido?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, quitar del pedido",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    })

    if (resp.isConfirmed) {
      try {
        await handleDeleteDetail(values.id, detailId, 'token')

        toast.success("Artículo quitado del pedido", { theme: "colored" })
      } catch (err) {
        toast.error(err.message, { theme: "colored" })
      } finally {
        handleEditing(false)
        handleGetSalesOrder()
      }
    }
  }

  const getPriceUpdate = useCallback(async () => {
    try {
      const response = await handleGetPriceUpdates()

      setPriceUpdate(response.data.result.map(item => ({ value: item.id, label: item.label })))
    } catch (error) {
      setPriceUpdate([])
      toast.error(error.message)
    }
  }, [handleGetPriceUpdates])

  const columns = useMemo(() => {
    let col = [...columnsSellOrder]

    // Verifica si algún objeto en values.detail tiene outdated_pvp == 1
    const hasOutdatedPVP = values?.detail && values.detail.length > 0 && values.detail.some(detail => Boolean(detail.outdated_pvp));

    // // Crea la nueva columna si hay algún outdated_pvp == 1
    // const newColumn = hasOutdatedPVP
    //   ?
    //     {
    //       Header: '',
    //       id: 'outdated_pvp_icon',
    //       className: 'col-icons',
    //       accessor: (row) => (
    //         <Tooltip text='PVP desactualizado'>
    //           <i className="bi bi-arrow-clockwise text-warning fs-3" />
    //         </Tooltip>
    //       )
    //     }
    //   : null;

    // // Agrega la nueva columna al inicio de las columnas
    // if (newColumn) {
    //   col.unshift(newColumn);
    // }

    return [
      ...col,
      {
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => (
          <>
            {(
              values.sales_order_status_id === SalesOrderStatusI.BORRADOR
            )
              ? <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
              : <></>
            }
          </>
        )
      }
    ]
  }, [values, values?.detail, columnsSellOrder])

  const loadEntities = ()=>{
    if(values.por_unidad){
      setEntities(values.detail.map(d=>({
        ...d,
        units_total: d.units * d.qty,
        actual_pvp: d.actual_pvp / d.units,
        list_cost: d.list_cost / d.units,
        price: d.price / d.units,
        por_unidad:values.por_unidad
      })))
    }
    else{
      setEntities(values.detail.map(d=>({
        ...d,
        units_total: d.units * d.qty,
        por_unidad:values.por_unidad
      })))
    }
  }

  useEffect(()=>{
    loadEntities()
  }, [values.detail])

  useEffect(()=>{
    loadEntities()
  }, [values.por_unidad])

  useEffect(() => {
    getPriceUpdate()
  }, [getPriceUpdate])

  return (
    <>
      <ArticlesModal show={modal} onClose={handleCloseModal} onSave={handleSave} salesOrder={salesOrder} />

      <div className='row mb-8'>
        <div className='col-4'>
          <SelectField
            id='price_update_id'
            name='price_update_id'
            label='Actualización de precios'
            options={priceUpdate}
            disabled={true}
          />
        </div>
        <div className="col-3" style={{marginLeft:"20px", paddingTop:"28px",paddingBottom:"25px"}}>
            <div className="d-flex">
                <SwitchField  id='por_unidad' name='por_unidad' disabled={!editing} /> 
                <span style={{ fontSize: '1.1rem' }}>Por unidad</span>
            </div>
        </div>
        <div className="col-3" style={{paddingTop:"28px",paddingBottom:"25px"}}>
            <div className="d-flex">
              <SwitchField  id='de_blister' name='de_blister' disabled={!editing} /> 
              <span style={{ fontSize: '1.1rem' }}>Destroquelar</span>
            </div>
        </div>
      </div>


      <div className='row mb-8 align-center'>
        {/* <div className={`${values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) ? 'd-flex flex-row align-items-center justify-content-between' : ''}`}> */}
          {/* {values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) &&
            <div className='col text-start my-8 fw-bold flex-grow-1'>
              Por favor, elimine los artículos con PVP desactualizado e ingreselos nuevamente.
            </div>
          } */}

          <div className='col text-end my-8' style={{ flex: 'none' }}>
            {(
              values?.sales_order_status_id == SalesOrderStatusI.BORRADOR
            ) &&
              (
                <Button
                  variant='primary'
                  onClick={handleShowModal}
                  className=""
                  disabled={loading}
                >
                  <i className="bi bi-plus-lg pe-2" />
                  Añadir artículo
                </Button>
              )
            }
          </div>
        {/* </div> */}

        <CustomTable columns={columns} data={entities || []}  handleContextMenu={handleContextMenu}  />

        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
          ref={contextRef}
        />

        <ArticleItemModal
          show={detailModal}
          onHide={handleCloseDetailModal}
          detail={detailData}
          handleUpdate={handleUpdate}
        />

        {values.detail.length > 0 && <div className='mt-8'><TotalSummaryTable editing={editing} /></div>}
      </div>
    </>
  )
}

export default ArticlesTab