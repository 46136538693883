import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useSalesOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { COLUMNS } from './columns'
import { SalesOrderStatusI } from 'interfaces'

import { labelColor } from 'pages/Sells/Orders/List'
import '../styles.css'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  sales_order_status_id_filter: [SalesOrderStatusI.FALTA_STOCK, SalesOrderStatusI.RESERVA_PARCIAL]
}

const LogisticActivation = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetSalesOrders, handleReactivateOutOrder, handleReactivateOutOrderAll } = useSalesOrders()
  const [data, setData] = useState([])

  const [filter, setFilter] = useState("")
  const [queryMD, setQueryMD] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [showResultModal, setShowResultModal] = useState(false)
  const [resultadoActivacion, setResultadoActivacion] = useState(null)

  const getSalesOrders = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...queryMD,
        }

        if (currentUser && currentUser?.activations) {
          params = {
            ...params,
            id_text: currentUser.activations?.id_text || '',
            nombre: currentUser.activations?.nombre || '',
            documento: currentUser.activations?.documento || '',
            paciente: currentUser.activations?.paciente || '',
            paciente_documento: currentUser.activations?.paciente_documento || '',
            from_date: currentUser.activations?.from_date || '',
            to_date: currentUser.activations?.to_date || '',
            article_text: currentUser.activations?.article_text || '',
            sales_order_status_id_filter: currentUser.activations?.sales_order_status_id_filter ? [currentUser.activations.sales_order_status_id_filter] : [SalesOrderStatusI.FALTA_STOCK, SalesOrderStatusI.RESERVA_PARCIAL],
          }
        }

        const response = await handleGetSalesOrders(params)

        if (response?.data?.result) {
          const data = response.data.result
          const dataColored = data.map(d => ({ ...d, label_color: labelColor(d.sales_order_status_id) }))

          setData(dataColored)
          setTotalCount(response.data.metadata.count)
        }
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetSalesOrders, queryMD])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if(currentPos.target == 'threeDots'){
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: actionEdit
    })

    if (currentRow?.sales_order_status_id == SalesOrderStatusI.FALTA_STOCK || currentRow?.sales_order_status_id == SalesOrderStatusI.RESERVA_PARCIAL) {
      actions.push({
        label: "Activar orden de egreso",
        icon: "bi bi-box-arrow-in-up-right text-success",
        action: actionActivate
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
    }

    navigate(`${routes.LOGISTICS_ACTIVATION}/${currentRow.id}`, {state: { in_request_id: currentRow.in_request_id }});
  }

  const actionPrint = () => {
    if (currentRow) {
      try {
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const actionActivate = async()=>{
    try {
      if (currentRow) { // CANCELADO o RECHAZADO
        const msg = `Va a reactivar el pedido #${currentRow.id} que tenía falta de stock, ¿confirma?`

        const resp = await Swal.fire({
          title: "Advertencia",
          text: msg,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, activar",
          customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          await handleReactivateOutOrder(currentRow.id)
          getSalesOrders()
          toast.success("Se reactivó la orden de egreso", { theme: "colored" })
        }
      }
    } catch(error) {
      toast.error(error.message)
    }
  }
  /* Fin menu contextual */

  const activateOrders = async() => {
    try {
      if (true) {//cancelado o rechazado
        const msg = `Va a reactivar los pedidos que tenían falta de stock, ¿confirma?`

        const resp = await Swal.fire({
          title: "Advertencia",
          text: msg,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, activar",
          customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          const result = await handleReactivateOutOrderAll()

          setResultadoActivacion(result.data)
          setShowResultModal(true)
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD ,
    setObQuery:setQueryMD
  };

  useEffect(() => {
    getSalesOrders()
  }, [getSalesOrders])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <Modal show={showResultModal} onHide={()=>setShowResultModal(false)}>
        <ModalHeader>
          <h3>Resultado activación</h3>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col">
              <div class="lista-pedidos">
                <p>Pedidos que se completaron: <b>{resultadoActivacion?.completados.length}</b></p>
                  <ul>
                    {resultadoActivacion?.completados?.map(item=> <li key={item}>{item}</li> )}
                  </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div class="lista-pedidos">
                  <p>Pedidos aún sin stock: <b>{resultadoActivacion?.sin_stock.length}</b></p>
                  <ul>
                    {resultadoActivacion?.sin_stock?.map(item => <li key={item}>{item}</li>)}
                  </ul>
              </div>
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={()=>setShowResultModal(false)}>Cerrar</Button>
        </ModalFooter>
      </Modal>

      <TableContainer
        title='Activación de Faltas'
        filter={<Filter setData={setData} setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} setTotalCount={setTotalCount} loading={loading}/>}
      >
        <div className='col text-end m-5'>
          <Button type="button" variant="primary" onClick={activateOrders} disabled={loading}>
            <i className="bi bi-clipboard-check pe-3" />
            Activar pedidos
          </Button>
        </div>

        {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", marginTop:"100px", left: "50%"}}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default LogisticActivation