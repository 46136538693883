import service from "../service";
import { PaymentMethodI, CreatePaymentMethodI, UpdatePaymentMethodI } from "interfaces";

const PATH = {
  paymentMethods: "/payment-methods",
};

export class PaymentMethods {
  static getPaymentMethods = async (token: string) => {
    const response = await service.get(PATH.paymentMethods, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static getPaymentMethod = async (id: PaymentMethodI['id'], token: string) => {
    const response = await service.get(`${PATH.paymentMethods}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static createPaymentMethod = async (data: CreatePaymentMethodI, token: string) => {
    const response = await service.post(PATH.paymentMethods, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static updatePaymentMethod = async (id: PaymentMethodI['id'], data: UpdatePaymentMethodI, token: string) => {
    const response = await service.patch(`${PATH.paymentMethods}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static deletePaymentMethod = async (id: PaymentMethodI['id'], token: string) => {
    const response = await service.delete(`${PATH.paymentMethods}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };
}