import { FC } from 'react'
import { Field } from 'formik'

import { InputI } from './types'

const Input: FC<InputI> = ({
  id,
  type = 'text',
  name,
  value,
  styles = '',
  placeholder = '',
  required = false,
  disabled = false,
  step,
  ...props
}) => {
  return (
    <Field
      className={`form-control ${styles}`}
      id={id}
      name={name ? name : id}
      type={type}
      defaultValue={value}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      step={step}
      {...props}
    />
  )
}

export default Input