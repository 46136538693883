import { useWaybill } from 'hooks';
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Select from "react-select"
import { toast } from 'react-toastify';
import moment from 'moment';
import { Mention, MentionsInput } from 'react-mentions';
import service from 'services/service';
import './index.css'
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';
import { Comments } from 'components';

const RESULTADO = {
  ENTREGADO: 1,
  DEVUELTO: 2,
};
const DEVOLUCION = {
  A_REVISION: 1,
  A_REDESPACHO: 2,
};
const optionsResultado=[
  {value:RESULTADO.ENTREGADO, label:"Entregado"}, 
  {value:RESULTADO.DEVUELTO, label:"Devuelto"}
]
const optionsDevolucion=[
  {value:DEVOLUCION.A_REVISION, label:"Enviar a revisión"}, 
  {value:DEVOLUCION.A_REDESPACHO, label:"Volver a despachar"}
]

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '38px',
    width:'200px'
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  menu: (base) => ({
    ...base,
    width: '200px'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};


const ShipmentResultModal = ({ show, onHide, shipment_order_id }) => {
  const [devolucion, setDevolucion] = useState({})
  const [resultado, setResultado] = useState({})
  const [notes, setNotes] = useState("")
  const [error, setError] = useState(false)
  const [data, setData] = useState({})
  const { handleGetShipmentOrder } = useWaybill()

  const getShipmentOrder = async()=> {
      try {
        const response = await handleGetShipmentOrder(shipment_order_id, { getDetail: true })
        const _data = response.data?.result
        setData(_data)
      } catch (error) {
        toast.error(error.message)
      }
  }

  const handleChangeResultado = (e)=>{

    if(e.value==RESULTADO.DEVUELTO){
      // elemento.entregado=false;
      // elemento.revision=false;
      // elemento.redespacho=false;
      // elemento.observaciones="";
      setResultado(optionsResultado.find(o=>o.value==RESULTADO.DEVUELTO));
      setDevolucion(optionsDevolucion.find(o=>o.value==DEVOLUCION.A_REDESPACHO))
      setError(false)
    }
    else{
      setResultado(optionsResultado.find(o=>o.value==RESULTADO.ENTREGADO));
      setNotes("")
      setDevolucion(null)
    }

  }

  const handleChangeDevolucion = (e)=>{
    setDevolucion(optionsDevolucion.find(o=>o.value==e.value))
    setError(false)
  }

/*
{
    "entregados": [
        {
            "id": 156
        }
    ],
    "devueltos": [
        {
            "id": 14,
            "observaciones": "",
            "revision": true,
            "redespacho": false
        }
    ]
}
*/
  const onSave = ()=>{
    if(resultado.value==RESULTADO.DEVUELTO && devolucion.value==DEVOLUCION.A_REVISION && notes.trim()==''){
      toast.error('El campo "Observaciones" es obligatorio')
      setError(true)
      return
    }

    const ret = {};
    ret.id = data.id;
    if(resultado.value==RESULTADO.ENTREGADO){
      ret.entregados = [{id:data.id}]
      ret.devueltos = []
    }
    else{
      ret.entregados=[]
      ret.devueltos=[{
        id:data.id,
        observaciones:notes,
        revision:devolucion?.value==DEVOLUCION.A_REVISION?true:false,
        redespacho:devolucion?.value==DEVOLUCION.A_REDESPACHO?true:false
      }]
    }
    onHide(ret)
  }

  useEffect(() => {
    if(show){
      setDevolucion(null)
      setResultado(optionsResultado.find(r=>r.value==RESULTADO.ENTREGADO))
      setError(false)
  
      if(shipment_order_id)
        getShipmentOrder()
      
      setNotes("")
      // setValue("")
    }

  }, [show])

  const [userData, setUserData] = useState([]);
  // const [value, setValue] = useState('');

  const getUsers = async () => {
    const response = await service.get("http://localhost:3000/api/users/");
    setUserData(response.data.result.map((user) => ({ id: user.id, display: user.name })));
  }

  const displayTransform = (id, display) => {
    return (
      <span
        style={{
          backgroundColor: 'lightgreen',
          padding: '2px 8px',
          borderRadius: '8px'
        }}
      >
        ${display}
      </span>
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    // setValue(newValue);
    setNotes(newValue);
  };

  return (
    <Modal size="lg" show={show} onHide={()=>onHide(null)} backdrop='static' centered>

      <Modal.Header closeButton>
        <Modal.Title>Entrega / Devolución</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        
        <div className='row'>
          <div className='col'>
            <div>
            <table>
              <tbody>
                <tr>
                  <td style={{width:"120px"}} >Órden de Envío:</td>
                  <td><span className='fw-bold ps-5'>{data?.id}</span></td>
                </tr>
                <tr>
                  <td style={{width:"120px"}} >F. creación:</td>
                  <td><span className='fw-bold ps-5'>{data?.created_at? moment(data.created_at).format("DD/MM/YYYY HH:mm"):''}</span></td>
                </tr>
                <tr>
                  <td style={{width:"120px"}} >Transporte:</td>
                  <td><span className='fw-bold ps-5'>{data?.shipping_carrier_name} - {data?.shipping_carrier_service}</span></td>
                </tr>
              </tbody>
            </table>
            </div>
            <div className='mt-8'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable border no-footer'>
              <thead>
                <tr>
                  <td>Pedido</td>
                  <td>Orden Egreso</td>
                  <td>Remito</td>
                  <td>Domicilio</td>
                </tr>
              </thead>
              <tbody>
                {
                  data?.order_delivery_rel?.map(row => (
                    <tr key={row.id}>
                      <td>{row.sales_order_id}</td>
                      <td>{row.out_order_id}</td>
                      <td>{""}</td>
                      <td>{row.shipment_address_formatted_address2}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            </div>
          </div>
        </div>

        <div className='row mt-10'>
          <div className='col'>
                <p className='p-0 m-0'>Envío</p>
                <Select 
                  options={optionsResultado}
                  styles={customStyles}
                  value={resultado}
                  onChange={handleChangeResultado}
                />
          </div>
          <div className='col'>
            {devolucion &&
              <>
                <p className='p-0 m-0'>Devolver</p>
                <Select 
                  options={optionsDevolucion}
                  styles={customStyles}
                  value={devolucion}
                  onChange={handleChangeDevolucion}
                />
              </>
            }
          </div>

        </div>

        {devolucion &&
            <div className='mt-8'>
              <Comments usersData={userData} notes={notes} setNotes={setNotes} title="Observaciones"/>
            </div>
        }



        <div className='row mt-15'>
          <div className='col text-end'>
            <Button variant='secondary' onClick={()=>onHide(null)} className='me-5'>Cancelar</Button>
            <Button variant='primary' onClick={onSave}>Guardar</Button>
          </div>
        </div>

      </Modal.Body>

    </Modal>
  )
}

export default ShipmentResultModal