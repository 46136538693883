import { useState, useCallback } from 'react';

import { GoogleMaps } from '../services';


const useMaps = () => {
  const [loading, setLoading] = useState(false);

  const getAutocompleteOptions = useCallback(async (searchText) => {
    try {
      setLoading(true);
      const response = await GoogleMaps.getAutocomplete(searchText);
      return response?.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);


  const getAddressDetail = useCallback(async (searchText) => {
    try {
      setLoading(true);
      const response = await GoogleMaps.getAutocompleteDetail(searchText);
      return response?.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  
  const postAddress = useCallback(async (placeId) => {
    try {
      setLoading(true);
      const response = await GoogleMaps.postAddress(placeId);
      return response?.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const postAddressCustom = useCallback(async (address) => {
    try {
      setLoading(true);
      const response = await GoogleMaps.postAddressCustom(address);
      return response?.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    getAutocompleteOptions,
    getAddressDetail,
    postAddress,
    postAddressCustom
  };
};

export default useMaps;