import React from 'react'
import { Card } from 'react-bootstrap'

import './styles.sass'

const Modal = ({ onClose, title, submodal, children }) => {
  return (
    <div className='Modal' onClick={onClose} style={submodal ? { zIndex: '999' } : { zIndex: '200' } }>
      <Card className='Modal-content' onClick={e => e.stopPropagation()}>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body className='Modal-body'>
          {children}
        </Card.Body>
      </Card>
    </div>
  )
}

export default Modal