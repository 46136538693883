import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useCustomerSuppliers } from 'hooks'

import { InputBS } from 'components'

const customerValues = {
  id: '',
  business_name: '',
  documentation: '',
}

const AssociateCustomerModal = ({ show, onClose, onSave }) => {
  const { loading, handleGetCustomerSuppliers } = useCustomerSuppliers()
  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedSCustomer] = useState(customerValues)

  const getCustomers = async (query) => {
    try {
      let params = {
        text: query,
        is_enabled: 1,
      }
      const response = await handleGetCustomerSuppliers(null, null, params)
      setCustomers(response.data.result)
    } catch (error) {
      setCustomers([])
      setSelectedSCustomer(customerValues)
      toast.error(error.message)
    }
  }

  return (
    <Modal show={show} onHide={onClose} size='xl' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Vincular cliente/proveedor</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={selectedCustomer}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col'>
                    <AsyncTypeahead
                      id='searchCliente'
                      isLoading={loading}
                      minLength={3}
                      labelKey={(o) => `#${o.id} | ${o.business_name} - ${o.documentation}`}
                      onSearch={getCustomers}
                      options={customers}
                      filterBy={() => true}
                      prompText='Escribe para buscar un cliente'
                      searchText='Buscando...'
                      placeholder='Escribe para buscar un cliente'
                      onChange={(e) => {
                        if (e && e.length > 0) {
                          setSelectedSCustomer({
                            id: e[0].id,
                            business_name: e[0].business_name,
                            documentation: e[0].documentation,
                          })
                        } else {
                          setSelectedSCustomer(customerValues)
                        }
                      }}
                    />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-2'>
                    <InputBS id='id' name='id' label='ID' disabled readOnly />
                  </div>

                  <div className='col-6'>
                    <InputBS id='business_name' name='business_name' label='Razón social' disabled readOnly />
                  </div>

                  <div className='col-4'>
                    <InputBS id='documentation' name='documentation' label='Documento' disabled readOnly />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='row'>
                  <div className='col text-end'>
                    <Button
                      type='button'
                      variant='secondary'
                      className='me-3'
                      onClick={() => {
                        setSelectedSCustomer(customerValues)
                        onClose()
                      }}
                    >
                      <i className='bi bi-slash-circle' />
                      Cerrar
                    </Button>

                    <Button type='submit' variant='primary'>
                      <i className='bi bi-save me-2' />
                      Vincular
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AssociateCustomerModal