import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useClickOutside, useReports } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import useGetColumns from './columns'

import { SalesOrderStatusI } from 'interfaces'

import { labelColor } from 'pages/Sells/Orders/List'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  sales_order_status_id_filter: [SalesOrderStatusI.FALTA_STOCK, SalesOrderStatusI.RESERVA_PARCIAL],
}

const AnalisisStockReport = () => {
  const { loading, handleGetReporteAnalisStock } = useReports()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { COLUMNS } = useGetColumns()

  const getReport = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      const response = await handleGetReporteAnalisStock(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.EstadoPedidoId),
      }))

      setData(data);
      setTotalCount(response.data.metadata.count);
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetReporteAnalisStock, queryMD])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD ,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    helix.register()
  }, [])

  useEffect(() => {
    getReport()
  }, [getReport])

  return (
    <>
      <TableContainer
        title='Reporte de Análisis de stock'
        filter={<Filter setData={setData} queryMD={queryMD} setQueryMD={setQueryMD} setTotalCount={setTotalCount} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default AnalisisStockReport