import { useState, useRef, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useStock, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import useGetColumns from './columns'
import SpaceStorageSelector from './partials/SpaceStorageSelector'
import routes from 'app/routing/routes'

import '../styles.css'
import {helix} from 'ldrs'

const initialObQuery = {
  pageNumber: "1",
  pageSize: "10"
}

const labelColor =(status)=>{
  switch (status.toUpperCase()) {
    case "BORRADOR":
      return "estado-gris"
    case "CONFIRMADO":
      return "estado-verde"
    default:
      return ""
  }
}

const LogisticStockMovements = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const [title, setTitle] = useState("Stock de Artículos");
  const [filter, setFilter] = useState("");
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0);
  const { handleGetDepotMovements, handlePrintInternalWaybill, loading } = useStock()

  const [movementsModal, setMovementsModal] = useState(false)

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver detalle",
      icon: "bi bi-eye text-primary",
      action: actionEdit
    })

    if(currentRow && currentRow.remito_id != null){
      actions.push({
        label: "Descargar remito",
        icon: "bi bi-download text-warning",
        action: printInternalWaybill
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.INVENTORY_MOVEMENTS}/${currentRow.id}`, {
        state: { product_id: currentRow.product_id }
      });
    }
  }

  const printInternalWaybill = async () => {
    try {
      if (currentRow) {
        let copias = 2
        const response = await handlePrintInternalWaybill(currentRow.id, copias)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('Remito descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `remito-${currentRow.id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)

        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getMovements = async () => {
    try {
      if (queryMD && queryMD.pageNumber) {
        const query = { filter, queryMD }

        const response = await handleGetDepotMovements(query)

        if (response.data.result) {
          const data = response.data.result
          const dataColored = data.map(d=>({...d, label_color: labelColor(d.depot_movement_status)}))

          setData(dataColored)
          setTotalCount(response.data.metadata.count)
        }
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      )
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD ,
    setObQuery:setQueryMD
  };

  const handleCloseModal = () => {
    setMovementsModal(false)
    getMovements()
  }

  useEffect(() => {
    getMovements()
  }, [handleGetDepotMovements, filter, queryMD])

  
  useEffect(()=>{
    helix.register()
  }, [])


  return (
    <>
      <SpaceStorageSelector show={movementsModal} onClose={handleCloseModal} handleGetDepotMovements={getMovements} />

      <TableContainer
        createButton={
          <Button variant='primary' onClick={() => setMovementsModal(true)} disabled={loading}>
            <i className='bi bi-plus-lg me-2' />
            Nuevo movimiento
          </Button>
        }
        title='Movimientos internos de stock'
        subtitle={title}
        filter={<Filter setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} setTitle={setTitle} loading={loading} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

        <CustomTable columns={columns} data={data || []} handleContextMenu={handleContextMenu} />
     
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default LogisticStockMovements
