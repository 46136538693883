import * as Yup from 'yup'

const formikInitialValues: any = {
  name: '',
  product_type_id:undefined,
  maker_id:undefined,
  family_id:undefined,
  channels:[],
}

const createSchema = Yup.object().shape({
    name: Yup.string().required('Ingrese un nombre para el producto'),
    // presentation: Yup.string().min(2).required('Ingrese un nombre para presentacion'),
})



export {
  formikInitialValues,
  createSchema
  
}