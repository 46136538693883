import { formatInputDate } from "utilities"

const initialValues = {
  id: '',
  order_delivery_status_id: '',
  order_delivery_status_label: '',
  order_delivery_status_color: '',
  created_at: '',
  returned_date: '',
  returned_reason_id: '',
  returned_reason_name: '',
  returning_pending_date: '',
  reship: '',
  shipping_carrier_service_id: '',
  shipping_carrier_service_name: '',
  shipping_carrier_service: '',
  shipping_carrier_name: '',
  carrier_name: '',
  shipping_cost: 0,
  shipped_date: '',
  guide_printed_date: '',
  ticket_printed_date: '',
  cancel_date: '',
  wave: {
    id: '',
    name: '',
    created_at: '',
  },
  order_delivery_rel: [],
}

const dataAdapter = (data) => {
  let values = {
    ...data,
    created_at: data.created_at, //formatInputDate(data.created_at),
    returned_date: data?.returned_date, //formatInputDate(data?.returned_date),
    returned_reason_id: data?.returned_reason_id ? data.returned_reason_id : '',
    returned_reason_name: data?.returned_reason_name ? data.returned_reason_name : '',
    returning_pending_date: data?.returning_pending_date, //formatInputDate(data?.returning_pending_date),
    reship: Boolean(data?.reship),
    shipped_date: formatInputDate(data?.shipped_date),
    guide_printed_date: data?.guide_printed_date, //formatInputDate(data?.guide_printed_date),
    ticket_printed_date: data?.ticket_printed_date, //formatInputDate(data?.ticket_printed_date),
    cancel_date: data.cancel_date, // formatInputDate(data?.cancel_date),
    wave: {
      ...data.wave,
      created_at: formatInputDate(data.wave.created_at),
    }
  }

  return values
}

export {
  initialValues,
  dataAdapter,
}