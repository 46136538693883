import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { formatTableDate } from 'utilities'
import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.PAYMENT_CONDITIONS}/${row.id}`}>{row.id}</Link>
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.PAYMENT_CONDITIONS.PAYMENT_METHOD' }),
      id: 'payment_method_name',
      accessor: (row) => (
        <Link to={`${routes.PAYMENT_METHODS}/${row.payment_method_id}`}>
          {row.payment_method_name}
        </Link>
      ),
    },
    {
      Header: <div className='text-center'>{intl.formatMessage({ id: 'COLUMN.PAYMENT_CONDITIONS.DAYS' })}</div>,
      id: 'days',
      accessor: (row) => (
        <div className='text-center'>
          {row.days}
        </div>
      )
    },
    {
      Header: <div className='text-center'>{intl.formatMessage({ id: 'COLUMN.PAYMENT_CONDITIONS.CANCEL_DATE' })}</div>,
      id: 'cancel_date',
      accessor: (row) => (
        <div className='text-center'>
          {row?.cancel_date ? formatTableDate(row.cancel_date) : '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Habilitada</div>,
      id: 'is_enabled',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row.is_enabled)
            ? <span className='badge bg-success'>{intl.formatMessage({ id: 'OPTION.YES' })}</span>
            : <span className='badge bg-danger'>{intl.formatMessage({ id: 'OPTION.NO' })}</span>
          }
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}