import { OutOrderStatusI } from 'interfaces';
import { useEffect, useState } from 'react'
import { Modal, FormLabel, Button, Form, Table } from 'react-bootstrap'

const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.PENDIENTE_PREPARACION:
    case OutOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case OutOrderStatusI.EN_PREPARACION:
    case OutOrderStatusI.EN_DESPACHO:
    case OutOrderStatusI.EN_DISTRIBUCION:
      return "estado-azul";
    // case OutOrderStatusI.EN_PREPARACION:
    //   return "estado-gris";
    case OutOrderStatusI.CANCELADO:
      return "estado-rojo";
    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case OutOrderStatusI.ENTREGADA:
      return "estado-verde";
    case OutOrderStatusI.EN_REVISION:
    case OutOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    default:
      return ""
  }
}

const agruparDatos = (detalle) => {
  const stockAgrupado = Object.values(
    detalle.reduce((acc, el) => {
      const key = `${el.product_var_id}-${el.storage_space_id}`
      if (!acc[key]) {
        acc[key] = { 
          product_var_id: el.product_var_id, 
          product_var_name: el.product_var_name, 
          gtin:el.gtin, 
          lot_number:el.lot_number, 
          storage_space_id:el.storage_space_id, 
          storage_space_name:el.storage_space_name, 
          qty: 1 }
      }
      acc[key].stock++
      return acc
    }, {})
  )

  return stockAgrupado
}

const CancelOrderModal = ({ show, onHide, cancelData }) => {
    
    const {currentRow, extras} = cancelData;
    const [entities, setEntities] = useState([])

    const handleSubmit = ()=>{
        onHide(cancelData)
    }

    useEffect(()=>{
        if(show){
            if(extras && extras.stock && extras.stock.length){
              setEntities(agruparDatos(extras.stock))
            }
            else{
              setEntities([])
            }
        }
    }, [show])


    return (
        <Modal size="lg" centered show={show} onHide={onHide} backdrop='static'>
        <Modal.Header closeButton>
            <Modal.Title>Cancelar órden de egreso</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row">
                <div className="col">

                <table className='mt-5'>
                    <tbody>
                      <tr style={{height:"40px"}} >
                        <td style={{width:"100px"}}><b>Órden:</b></td>
                        <td><span>{ currentRow?.id}</span></td>
                        <td>
                        </td>
                      </tr>  
                      <tr style={{height:"40px"}} >
                        <td style={{width:"100px"}}><b>Estado:</b></td>
                        <td style={{width:"130px"}}>
                            <span className={`badge ${labelColor(extras?.out_order_status_id)} w-100 justify-content-center m-0 fw-normal`}>
                              {extras?.out_order_status_label}
                            </span>
                        </td>
                        <td>
                        </td>
                      </tr>  
                      {(extras?.afip_estado=='SUCCESS' || extras?.afip_estado=='SUCCESS_WITH_OBSERVATIONS')
                        ?
                        <tr style={{height:"40px"}} >
                          <td style={{width:"100px"}}><b>AFIP:</b></td>
                          <td colSpan={2}>
                            <span>{(extras.afip_estado=='SUCCESS' || extras.afip_estado=='SUCCESS_WITH_OBSERVATIONS') ? 'La órden ya fue facturada, deberá realizar Nota de Crédito' : ''}</span>
                          </td>
                        </tr>
                        : 
                        <tr style={{height:"40px"}}></tr>
                      } 
                      {(extras?.hay_productos_con_traza)
                        ?
                        <tr style={{height:"40px"}} >
                          <td style={{width:"100px"}}><b>Trazabilidad:</b></td>
                          <td colSpan={2}>
                            <span>{extras.hay_productos_con_traza?'La órden tiene pedidos trazables. Se deben cancelar esas transacciones en ANMAT.':''} </span> 
                          </td>
                        </tr>
                        : 
                        <tr style={{height:"40px"}}></tr>
                      } 

                    </tbody>
                  </table>
                      {(entities.length)
                        ?
                        <>
                          <p className='mt-10'><b>Ubicación de los artículos:</b></p>
                          <Table striped hover>
                            <thead>
                              <tr>
                                <td>Producto</td>
                                <td>GTIN</td>
                                <td>Lote</td>
                                <td>Espacio de Almacenamiento</td>
                                <td>Cantidad</td>
                              </tr>
                            </thead>
                            <tbody>{entities?.map((v, idx)=>(
                              <tr key={idx}>
                                <td>{v.product_var_name}</td>
                                <td>{v.gtin}</td>
                                <td>{v.lot_number}</td>
                                <td>{v.storage_space_name}</td>
                                <td>{v.qty}</td>
                              </tr>
                            ))}
                            </tbody>
                          </Table>
                        </>
                        : <tr></tr>
                      } 


                </div>
            </div>


            <div className='d-flex align-items-center justify-content-end mt-20'>
                <Button variant='secondary' type='button' onClick={()=>onHide(null)} className='me-3' > <i className="bi bi-slash-circle" /> Cerrar </Button>
                <Button variant='primary' type='button' onClick={handleSubmit}><i className='bi bi-save me-2' /> Cancelar órden </Button>
            </div>
        </Modal.Body>
        </Modal>
    )
}

export default CancelOrderModal