import { useState, useCallback } from 'react';

import { DocumentationTypes } from 'services';
import { DocumentationTypeI, GetDocumentationTypesI, GetDocumentationTypeI } from 'interfaces';

interface UseDocumentationTypesReturn {
  loading: boolean;
  handleGetDocumentationTypes: (token: string) => Promise<GetDocumentationTypesI>;
  handleGetDocumentationType: (id: DocumentationTypeI['id'], token: string) => Promise<GetDocumentationTypeI>;
}

const useDocumentationTypes = (): UseDocumentationTypesReturn => {
  const [loading, setLoading] = useState(false);

  const handleGetDocumentationTypes = useCallback(async (token: string) => {
    try {
      setLoading(true);
      const response = await DocumentationTypes.getDocumentationTypes(token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetDocumentationType = useCallback(async (id: DocumentationTypeI['id'], token: string) => {
    try {
      setLoading(true);
      const response = await DocumentationTypes.getDocumentationType(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    handleGetDocumentationTypes,
    handleGetDocumentationType
  };
};

export default useDocumentationTypes;