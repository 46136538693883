import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import moment from 'moment'

import { useSalesOrders } from 'hooks'

import { Input, SwitchField } from 'components'
import { toast } from 'react-toastify'

const initialFilterValues = {
  text: "",
  text2: "",
  cantidad_mayor_a: "",
  dias_stock: "",
  vencimiento_menor_a: "",
  ultima_venta: "",
  solo_stock_en_falta: false
}

const Filter = ({ setFilter, setData, queryMD, setQueryMD, setTotalCount, exportData  }) => {
  const { handleGetPendingDetail, loading } = useSalesOrders()

    /*
      Laboratorio

      cantidad a comprar > N

      días de stock

      unidades con fecha de vencimiento < X días

      fecha última venta > x días

      Filtrar solo stock en falta
    */

    const filterData = async (values) => {
      const params = {
        ...queryMD,
        ...values,
        text: values?.text ? values.text : undefined,
        text2: values?.text2 ? values.text2 : undefined,
        cantidad_mayor_a: values?.cantidad_mayor_a ? values.cantidad_mayor_a : undefined,
        dias_stock: values?.dias_stock ? values.dias_stock : undefined,
        vencimiento_menor_a: values?.vencimiento_menor_a ? values.vencimiento_menor_a : undefined,
        ultima_venta: values?.ultima_venta ? values.ultima_venta : undefined,
        solo_stock_en_falta: values?.solo_stock_en_falta ? values.solo_stock_en_falta : undefined,
        order_status_id: 1,
      }

      try {
        const response = await handleGetPendingDetail(params)
        const data = response.data.result.map(d => ({
          ...d,
          Necesario: +d.Necesario<0 ? 0 : +d.Necesario,
          NecesarioMasAutorizacion: +d.NecesarioMasAutorizacion<0 ? 0 : +d.NecesarioMasAutorizacion
        }))

        setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
        setData(data)
        setTotalCount(response.data.metadata.count)
      } catch (error) {
        setData([])
        setTotalCount(0)
        toast.error(error.message)
      }
    }

  return (
    <Formik
      initialValues={initialFilterValues}
      onSubmit={filterData}
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-6'>
              <Input name='text' />
              <p className='text-muted'>Artículo / Droga</p>
            </div>

            <div className='col-6'>
              <Input name='text2' />
              <p className='text-muted'>Laboratorio</p>
            </div>
          </div>

          {/* <div className='row'>
            <div className='col'>
              <Input name='cantidad_mayor_a' />
              <p className='text-muted'>Cantidad mayor a</p>
            </div>

            <div className='col'>
              <Input name='dias_stock' />
              <p className='text-muted'>Días de stock</p>
            </div>

            <div className='col'>
              <Input name='vencimiento_menor_a' />
              <p className='text-muted'>F. vencimiento menor a X días</p>
            </div>

            <div className='col'>
              <div className="d-flex mt-3">
                <SwitchField
                  id='solo_stock_en_falta'
                  name='solo_stock_en_falta'
                  disabled={false}
                />
                <span style={{ fontSize: '1.1rem' }}>solo stock en falta</span>
              </div>
            </div>
          </div> */}

          {/* <div className='row'>
            <div className='col-3'>
              <Input name='ultima_venta' />
              <p className='text-muted'>Última venta mayor a X días</p>
            </div>
          </div> */}

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => exportData()}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-file-earmark-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm()
                  submitForm()
                }}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter