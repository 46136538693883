import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const labelColor = (status) => {
  if (!status) {
    return ""
  }

  switch (status.toUpperCase()) {
    case "MANUAL":
      return "estado-amarillo";
    case "GOOGLE":
      return "estado-verde";
    case "MIGRACION":
      return "estado-rojo";
    default:
      return '';
  }
}

const ShipmentAddressesColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Tooltip text='Ver dirección'>
          <Link to={`${routes.SHIPMENT_ADDRESSES}/${row.id}`}>
            {row.id}
          </Link>
        </Tooltip>
      ),
    },
    {
      Header: <div className='text-center'>Origen</div>,
      id: 'origin',
      accessor: (row) => (
        <span className={ `badge ${labelColor(row.address.origin)} w-100 justify-content-center`}>
          {row.address.origin}
        </span>
      ),
    },
    {
      Header: 'Nombre / Domicilio',
      id: 'address_id',
      accessor: (row) => (
        <>
          <strong className='m-0 p-0'>{row.name}</strong>
          <p className='m-0 p-0 mt-2'>{row.address?.domicilio?.trim()}</p>
        </>
      ),
    },
    {
      Header: 'Localidad',
      id: 'address.localidad_completa',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.address?.localidad_completa?.trim()}</p>
      ),
    },
    {
      Header: 'Provincia',
      id: 'address.administrative_area_level_1',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.address?.administrative_area_level_1?.trim()} ({row.address?.locality})</p>
      ),
    },
    {
      Header: 'Contacto',
      id: 'contact',
      accessor: (row) => (
        <>
          {row.contact_id
            ?
              <ul className='list-style-none m-0 p-0'>
                <li><strong>Nombre:</strong> {row.contact?.name}</li>
                <li><strong>Email:</strong> {row.contact?.email}</li>
                <li><strong>Teléfono:</strong> {row.contact?.phone}</li>
                <li><strong>Celular:</strong> {row.contact?.mobile_suffix} {row.contact?.mobile}</li>
              </ul>
            : <i>Sin datos de contacto</i>
          }
        </>
      ),
    },
    {
      Header: <div className='text-center'>Trazabilidad</div>,
      id: 'has_traceability',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${Boolean(row.has_traceability) ? 'success' : 'danger'}`}>{Boolean(row.has_traceability) ? 'Sí' : 'No'}</span>
        </div>
      )
    },
    {
      Header: <div className='text-center'>Tipo de agente</div>,
      id: 'agent_type_id',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row.agent_type_id)
            ? row.agent_type_name
            : <i>Sin tipo de agente</i>
          }
        </div>
      ),
    },
    {
      Header: 'GLN',
      id: 'gln',
      accessor: (row) => (
        <>
          {row?.gln
            ? row.gln
            : <i>Sin GLN</i>
          }
        </>
      )
    },
    {
      Header: <div className='text-center'>Verifarma</div>,
      id: 'verifarma',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${row?.verifarma ? 'success' : 'danger'}`}>{row?.verifarma ? 'Sí' : 'No'}</span>
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Habilitada</div>,
      id: 'is_enabled',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${Boolean(row.is_enabled) ? 'success' : 'danger'}`}>{Boolean(row.is_enabled) ? 'Sí' : 'No'}</span>
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}

export default ShipmentAddressesColumns
