import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { CreateWaveModal, AddToWaveModal } from './'
import { OutOrderStatusI } from 'interfaces'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import routes from 'app/routing/routes'
import { useWaves } from 'hooks'

const TableActions = ({ selectedRows, handleSelectedRows, handleGetOutOrders, loading, data, pendingOrders }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [availableWaves, setAvailableWaves] = useState([])
  const [createWaveModal, setCreateWaveModal] = useState(false)
  const [addToWaveModal, setAddToWaveModal] = useState(false)
  const [optionsEnabled, setOptionsEnabled] = useState(false)
  const { handleGetWaves } = useWaves()
  
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onHideCreateWaveModal = (response)=>{

      setCreateWaveModal(false)

      if(response && response.data)
        navigate(`${routes.WAVES}/${response.data.id}`);
  }

  const onHideAddToWaveModal = (wave_id)=>{

      setAddToWaveModal(false)

      if(wave_id)
        navigate(`${routes.WAVES}/${wave_id}`);
  }


  const createWave = async () => {
    console.log(selectedRows)
    const canCreateWave = selectedRows.every(row => row.status_id === OutOrderStatusI.PENDIENTE_PREPARACION)

    if (!canCreateWave) {
      toast.error(`Al menos una orden no puede ser añadida a la ola. Por favor, deseleccione las órdenes incorrectas.`);
      return;
    }

    setCreateWaveModal(true)
    handleClose()
  }

  const addToWave = async () => {
    const canAddToWave = selectedRows.every(row => row.status_id === OutOrderStatusI.PENDIENTE_PREPARACION)

    if (!canAddToWave) {
      toast.error(`Al menos una orden no puede ser añadida a la ola. Por favor, deseleccione las órdenes incorrectas.`);
      return;
    }
    const resp = await handleGetWaves();
    const olasEnProgreso = resp?.data?.result?.filter(w=>w.wave_status_id==1)
    setAvailableWaves(olasEnProgreso)
    if(!olasEnProgreso || olasEnProgreso.length<1){
      toast.error("No hay olas en progreso")
      return;
    }

    setAddToWaveModal(true)
    handleClose()
  }

  useEffect(()=>{
// console.log(selectedRows)
// console.log(selectedRows.some(r=>r.status_id==OutOrderStatusI.PENDIENTE_PREPARACION))
  }, [selectedRows])

  useEffect(()=>{
    if(pendingOrders?.length>0){
      setOptionsEnabled(true)
    }
    else{
      setOptionsEnabled(false)
    }
  }, [pendingOrders])

  return (
    <>
      <CreateWaveModal show={createWaveModal} onHide={onHideCreateWaveModal} selectedRows={selectedRows} pendingOrders={pendingOrders}
        handleOutOrders={handleSelectedRows} handleGetOutOrders={handleGetOutOrders} />

      <AddToWaveModal show={addToWaveModal} onHide={onHideAddToWaveModal} outOrders={selectedRows} handleOutOrders={handleSelectedRows} handleGetOutOrders={handleGetOutOrders} 
        availableWaves = {availableWaves} />

      <div className='row'>
        <div className='col'>
          {/* { selectedRows && selectedRows.some(r=>r.status_id==OutOrderStatusI.PENDIENTE_PREPARACION) &&
              <DropdownButton
                title={
                  <>
                    <i />
                    { loading ? 'Cargando...' : 'Acciones'}
                  </>
                }
                disabled={loading}
                align={{ lg: 'end' }}
              >
                  <Dropdown.Item onClick={createWave} disabled={selectedRows.length === 0}>
                    <i className={`bi bi-tsunami text-primary pe-2`} />
                    Crear Ola
                  </Dropdown.Item>
                  <Dropdown.Item onClick={addToWave} disabled={selectedRows.length === 0}>
                    <i className={`bi bi-water text-primary pe-2`} />
                    Agregar a Ola
                  </Dropdown.Item>
              </DropdownButton>
          } */}

              {optionsEnabled &&
                <DropdownButton
                  title={
                    <>
                      <i />
                      { loading ? 'Cargando...' : 'Acciones'}
                    </>
                  }
                  disabled={loading}
                  align={{ lg: 'end' }}
                >
                    <Dropdown.Item onClick={createWave} >
                      <i className={`bi bi-tsunami text-primary pe-2`} />
                      Crear Ola
                    </Dropdown.Item>
                    <Dropdown.Item onClick={addToWave} disabled={selectedRows?.length<1}>
                      <i className={`bi bi-water text-primary pe-2`} />
                      Agregar a Ola en progreso
                    </Dropdown.Item>
                </DropdownButton>
              }
        </div>
      </div>
    </>
  )
}

export default TableActions