import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { AssignmentOutlined, GroupsOutlined } from '@mui/icons-material'

import { useShipmentAddresses } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import { Actions, GeneralTab, AssociatedCustomersTab } from './partials'
import { initialValues, schema, dataAdapter, updateDataAdapter } from './constants'

const labelColor = (status) => {
  if (!status) {
    return ""
  }

  switch (status.toUpperCase()) {
    case "MANUAL":
      return "estado-amarillo";
    case "GOOGLE":
      return "estado-verde";
    case "MIGRACION":
      return "estado-rojo";
    default:
      return '';
  }
}

const ShipmentAddressDetail = () => {
  const { id } = useParams()
  const submitRef = useRef(null)
  const cancelRef = useRef(null)
  const [editing, setEditing] = useState(false)

  const { loading, handleGetShipmentAddress, handleUpdateShipmentAddress } = useShipmentAddresses()
  const [shipmentAddress, setShipmentAddress] = useState(initialValues)

  const handleUpdate = async (values) => {
    try {
      if (Boolean(values.has_traceability) && values.agent_type_id === '') {
        toast.error('Falta definir el tipo de agente')
        return
      }
      if (Boolean(values.has_traceability) && values.gln === '') {
        toast.error('Falta definir el GLN')
        return
      }
      if (values.contact.email !== '' && !values.contact.email
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ) {
        toast.error('El email del contacto no tiene el formato correcto. Ej: hola@gmail.com')
        return
      }
      if (values.contact.mobile !== '' && values.contact.mobile.length < 10) {
        toast.error('El número de celular debe tener al menos 10 dígitos')
        return
      }

      let data = updateDataAdapter(values)
      await handleUpdateShipmentAddress(id, data, 'token')

      toast.success(`Dirección de entrega actualizada correctamente`, { theme: "colored" })
      setEditing(false)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getShipmentAddress()
    }
  }

  const getShipmentAddress = useCallback(async () => {
    try {
      const response = await handleGetShipmentAddress(id, 'token')
      setShipmentAddress(dataAdapter(response.data.result))
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetShipmentAddress])

  useEffect(() => {
    getShipmentAddress()
  }, [getShipmentAddress])

  return (
    <>
      <DetailContainer
        title={`Dirección de entrega #${id}`}
        buttons={<Actions editing={editing} handleEditing={setEditing} submitRef={submitRef} cancelRef={cancelRef} />}
        statusV2={shipmentAddress &&
          <div className={`badge ${labelColor(shipmentAddress.address.origin)} justify-content-center fw-normal`} style={{ width: "150px" }}>
            {shipmentAddress.address.origin}
          </div>
        }
      >
        <Formik
          initialValues={shipmentAddress}
          validationSchema={schema}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form>
                <TabsContainer
                  initialValue='general'
                  tabs={[
                    { value: 'general', label: 'General', icon: <AssignmentOutlined />, show: true },
                    { value: 'associated-customers', label: 'Clientes asociados', icon: <GroupsOutlined />, show: true },
                  ]}
                  tabsContent={[
                    <GeneralTab editing={editing} handleUpdate={handleUpdate} handleGetShipmentAddress={getShipmentAddress} />,
                    <AssociatedCustomersTab />,
                  ]}
                />

                {editing && (
                  <>
                    <div className='separator my-8'></div>

                    <div className='d-flex align-items-center justify-content-end'>
                      <Button
                        ref={cancelRef}
                        variant='secondary'
                        type='button'
                        className='me-3'
                        onClick={() => {
                          setEditing(false)
                          handleReset()
                        }}
                      >
                        <i className="bi bi-slash-circle" />
                        Cancelar
                      </Button>

                      <Button variant='primary' type='submit' ref={submitRef} disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </div>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default ShipmentAddressDetail