import service from 'services/service'

const PATH = {
  businessUnits: '/business-units',
}

export class BusinessUnits {
  static getBusinessUnits = async (params, token) => {
    const response = await service.get(PATH.businessUnits, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }

  static getBusinessUnit = async (id, params, token) => {
    const response = await service.get(`${PATH.businessUnits}/${id}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }

  static addBusinessUnit = async (data, token) => {
    const response = await service.post(PATH.businessUnits, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }

  static updateBusinessUnit = async (id, data, token) => {
    const response = await service.patch(`${PATH.businessUnits}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }
}