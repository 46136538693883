import { FC } from 'react'
import { Formik, Form } from 'formik'

import { Input, FormButton, SelectField } from 'components'
import moment from 'moment';

const request_type = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Pedido de Compra' },
  { value: 2, label: 'Pedido de Ingreso' }
]

const initialValues = {
  text:"",
  request_type_id:null,
  order_status_id:"2"
}


const Filter = ({ setFilter, queryMD, setQueryMD  }) => {

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        let query = '?'
        for (const key in values) {
          if (values[key] !== '') {
            if (key === 'request_type_id') {
              if(values[key]){
                query += `${key}=${values[key]}&`
              }
            } 
            if(key === 'date_from' || key === 'date_to'){
              if(values[key]){
                query += `${key}=${moment(values[key]).format("YYYYMMDD")}&`
              }
            }
            else {
              query += `${key}=${values[key]}&`
            }
          }
        }
        query = query.slice(0, -1)
        setFilter(query)
        setQueryMD({...queryMD, pageNumber:1})
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className='row'>
            <div className='col'><Input id='text' name='text' /><p className='text-muted'><b>Proveedor</b></p></div>
            <div className='col'><SelectField marginTop={'-20px'} id='request_type_id' name='request_type_id' options={request_type}/><p className='text-muted'><b>Tipo</b> de pedido</p></div>
            <div className='col'><Input id='date_from' name='date_from' type='date' /><p className='text-muted'><b>Desde</b></p></div>
            <div className='col'><Input id='date_to' name='date_to' type='date' /><p className='text-muted'><b>Hasta</b></p></div>
          </div>
          <div className='row pull-right'>
            <div className='col pb-8 d-flex justify-content-end'><FormButton text='Buscar' /></div>
          </div>

          {/* <div className='d-flex justify-content-end'>
            <FormButton text='Buscar' />
          </div> */}
        </Form>
      )}
    </Formik>
  )
}

export default Filter