import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: 'ID',
    id: 'id',
    accessor: (row) => (
      <Tooltip text='Ver'>
        <Link to={`${routes.PRODUCTS}/${row.id}`}>
          {row.id}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
    sortOrder: true,
  },
  {
    Header: <div className=''>Tipo</div>,
    id: 'product_type_name',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.product_type_name.replace('_', ' ')}</p>
    ),
  },
  {
    Header: <div className=''>Nombre</div>,
    accessor: 'name',
    hasOrder: true,
  },
  {
    Header: <div className=''>Laboratorio / Familia</div>,
    accessor: 'maker_name',
    hasOrder: true,
  },
  {
    Header: <div className=''>Ppio. Activo / Subfamilia</div>,
    accessor: 'family_name',
    hasOrder: true,
  },
]

export {
  COLUMNS
}