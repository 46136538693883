import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useIncomeOrder } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { COLUMNS } from './columns'

import routes from 'app/routing/routes'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC',
}

export const ESTADOS_ORDEN = {
  GENERADO: 1,
  PENDIENTE_INGRESO: 2,
  INGRESO_PARCIAL: 3,
  VENCIDO: 4,
  CANCELADO: 5,
  COMPLETO: 6,
  BORRADOR: 7,
  AUTORIZADO: 8,
  CONFIRMADO: 9,
  COMPLETO_PARCIAL: 10,
  INGRESADO: 11,
  RECHAZADO: 12,
  ERROR_TRAZA: 13
}

export const labelColor = (order_status_id) => {
  switch (order_status_id) {
    case ESTADOS_ORDEN.BORRADOR:
      return "estado-gris"
    case ESTADOS_ORDEN.INGRESADO:
      return "estado-verde"
    case ESTADOS_ORDEN.ERROR_TRAZA:
    case ESTADOS_ORDEN.CANCELADO:
      return "estado-rojo"
    default:
      return 'estado-gris'
  }
}

const LogisticIncome = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetIncomeOrders } = useIncomeOrder()
  const [data, setData] = useState([])

  const [filter, setFilter] = useState("")
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getRequests = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      if (currentUser && currentUser?.in_orders) {
        const { in_orders } = currentUser

        params = {
          ...params,
          id_text: in_orders?.id_text || '',
          date_from: in_orders?.date_from || '',
          date_to: in_orders?.date_to || '',
          comprobante: in_orders?.comprobante || '',
          text: in_orders?.text || '',
          documento: in_orders?.documento || '',
          order_status_id: in_orders?.order_status_id || [],
          direccion: in_orders?.direccion || '',
          localidad: in_orders?.localidad || '',
          provincia: in_orders?.provincia || '',
          article_text: in_orders?.article_text || '',
        }
      }

      const response = await handleGetIncomeOrders(params)
      const data = response.result.map(d => ({
        ...d,
        label_color: labelColor(d.order_status_id)
      }))

      setData(data)
      setTotalCount(response.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetIncomeOrders, queryMD, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow?.order_status_id === 7) {
      actions.push({
        label: "Editar",
        icon: "bi bi-pencil text-primary",
        action: actionEdit
      })
    } else {
      actions.push({
        label: "Ver",
        icon: "bi bi-eye text-primary",
        action: actionEdit
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.LOGISTIC_INCOME}/${currentRow.id}`, {
        state: {
          in_request_id: currentRow.in_request_id
        }
      })
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD,
  }

  useEffect(() => {
    getRequests()
  }, [getRequests])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title='Órdenes de Ingreso'
        goTo={routes.LOGISTIC_INCOME_NEW}
        goToText='Nuevo Ingreso'
        filter={<Filter setData={setData} setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} setTotalCount={setTotalCount} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default LogisticIncome