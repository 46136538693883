import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useProducts } from 'hooks'

import { Input, SelectField } from 'components'

const product_types = [
  { value: '', label: 'Seleccionar tipo' },
  { value: 1, label: 'Medicamento' },
  { value: 2, label: 'Producto Médico' }
]

const initialValues = {
  name:"",
  maker_name:"",
  family_name:"",
  product_type_id:null
}


const Filter = ({ setData, setTotalCount, setFilter, queryMD, setQueryMD  }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetProductsV2 } = useProducts()

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id_filter: values?.id_filter || undefined,
      name: values?.name || undefined,
      maker_name: values?.maker_name || undefined,
      family_name: values?.family_name || undefined,
      product_type_id: values?.product_type_id || undefined,
      pageNumber: 1,
      pageSize: 10,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = filterData(values)
      const response = await handleGetProductsV2(params)
      const data = response.data.result

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        products: { ...params }
      }))

      setQueryMD({ ...params })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { products } = currentUser

      setInitialFormikValues({
        ...initialValues,
        id_filter: products?.id_filter || '',
        name: products?.name || '',
        maker_name: products?.maker_name || '',
        family_name: products?.family_name || '',
        product_type_id: products?.product_type_id || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_filter' name='id_filter' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-6'>
              <Input id='name' name='name' disabled={loading} />
              <p className='text-muted'>Nombre</p>
            </div>

            <div className='col-4'>
              <Input id='maker_name' name='maker_name' disabled={loading} />
              <p className='text-muted'>Laboratorio / Famila</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='family_name' name='family_name' disabled={loading} />
              <p className='text-muted'>Ppio. activo / Subfamilia</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='product_type_id'
                name='product_type_id'
                options={product_types}
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Tipo producto</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-search mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}






export default Filter