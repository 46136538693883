import React, { useEffect, useMemo, useState } from "react";
import { Button, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import { toast } from "react-toastify";
import { useIncomeOrder } from "hooks";

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px',
    height: '43px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};


export function EditLotNumberModal({ show, onHide, currentRow, lotNumberUpdated}) {
    const [updatedObject, setUpdatedObject] = useState({})
    const {handleUpdateLotNumberInTraceError, loading} = useIncomeOrder();

    const saveChanges = async ()=>{
      try{
        const resp = await handleUpdateLotNumberInTraceError(updatedObject)
        lotNumberUpdated();
      }
      catch(err){
        toast.error(err.message)
      } finally {

      }
    }
    useEffect(()=>{
      if(show && currentRow){
        console.log(currentRow)
        setUpdatedObject({
          in_order_id:currentRow.in_order_id,
          in_order_detail_id:currentRow.id,
          lot_number:currentRow.lot_number,
          serial_number:currentRow.serial_number,
          // expiration_date:currentRow.expiration_date ? moment(currentRow.expiration_date).format('YYYY-MM-DD'): ''
        })
      }
    }, [show])


    return (
      <>
            <Modal size="md" show={show} onHide={onHide} backdrop='static' >

              <Modal.Body className="">
                <h3>{currentRow?.product_var_name}</h3>       
                <div className="row mt-10">
                  <div className='col'>
                    <FormLabel style={labelStyle}>Espacio de almacenamiento</FormLabel>
                    <input type='text' className="form-control" value={currentRow?.storage_space_name} disabled></input>
                  </div>
                </div>
                {(loading) && 
                  <div className='w-100 text-center'>
                    <div className='spinner-border text-success'>

                    </div>
                  </div>
                }                
                <div className="row mt-5">
                  <div className='col'>
                    <FormLabel style={labelStyle}>GTIN</FormLabel>
                    <input type='text' className="form-control" value={currentRow?.gtin} disabled></input>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className='col'>
                    <FormLabel style={labelStyle}>Lote</FormLabel>
                    <div className="input-group">
                    <input type='text' className="form-control" 
                      value={updatedObject?.lot_number}
                      onChange={ ev => setUpdatedObject({...updatedObject, lot_number:ev?.target?.value})}
                    />
                      <div className="input-group-append">
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button"
                          onClick={()=>setUpdatedObject({...updatedObject, lot_number:updatedObject.lot_number.toLowerCase()})}
                        >
                          Pasar a minúscula
                        </button>
                      </div>
                    
                    </div>
                  </div>
                </div>
                {/* <div className="row mt-5">
                  <div className='col'>
                    <FormLabel style={labelStyle}>Vencimiento</FormLabel>
                    <input type='date' className="form-control" 
                        value={updatedObject?.expiration_date}
                        onChange={(ev)=>setUpdatedObject({...updatedObject, expiration_date:ev.target.value})}
                    />
                  </div>
                </div> */}
                <div className="row mt-5">
                  <div className='col'>
                    <FormLabel style={labelStyle}>Nro serie</FormLabel>
                    <div className="input-group">
                      <input type='text' className="form-control" 
                        value={updatedObject?.serial_number} 
                        onChange={ ev => setUpdatedObject({...updatedObject, serial_number:ev?.target?.value})}
                      ></input>
                      <div className="input-group-append">
                        <button 
                          className="btn btn-outline-secondary" 
                          type="button"
                          onClick={()=>setUpdatedObject({...updatedObject, serial_number:updatedObject.serial_number.toLowerCase()})}
                        >
                          Pasar a minúscula
                        </button>
                      </div>
                    </div>
                  </div>
                </div>


              </Modal.Body>

              <Modal.Footer style={{borderTop: "0 none"}}>
                <Button variant="secondary" onClick={onHide}>Salir</Button>
                <Button variant="primary" onClick={saveChanges} disabled={loading}>Guardar</Button>
              </Modal.Footer>
          </Modal>
    </>
    )
  }


  // const Traceable = ({updDetail, handleChange, getValueDepot, onChangeDepot, deleteSerialNumber, storageOptions, refrigeratedAlert})=>{
  //   return (
  //     <>
  //         <div className="row mt-10">
  //           <div className='col'>
  //             <FormLabel style={labelStyle}>Espacio de almacenamiento</FormLabel>
  //             <Select
  //               options={storageOptions}
  //               value={getValueDepot()}
  //               onChange={onChangeDepot}
  //               styles={customStyles}
  //             />
  //           </div>
  //           <div className="col">
  //             <label htmlFor='qty' className='form-label'>Cantidad</label>
  //             <input type="text" className='form-control' id='qty' name="qty" maxLength={5} 
  //               value={updDetail.qty || ''} autoComplete="off" onChange={handleChange} disabled={updDetail.product_var_traced} />
  //           </div>
  //         </div>
        
  //       { refrigeratedAlert==true && <div className="text-danger"> El espacio de almacenamiento no es de frío </div>}
        
        
  //         <div className="row mt-10">
  //           <div className="col">
  //             <label htmlFor='lot_number' className='form-label' maxLength={30}>Lote</label>
  //             <input type="text" className='form-control' id='lot_number' name="lot_number" autoComplete="off" 
  //               value={updDetail.lot_number || ''} onChange={handleChange} disabled={true}/>
  //           </div>
  //           <div className="col">
  //             <label htmlFor='expiration_date' className='form-label'>Vencimiento</label>
  //             <input type="date" className='form-control' id='expiration_date' 
  //               value={updDetail.expiration_date || ''} onChange={handleChange} name="expiration_date" disabled={true}/>
  //           </div>
  //         </div>      
  //         {updDetail.series && updDetail.series.filter(i=>i.serial_number!=null).length>0 && <div className="row mt-10">
  //           <div className="col">
  //             <Table striped bordered hover>
  //               <thead>
  //                   <tr>
  //                   <th>Nro. Serie</th>
  //                   <th></th>
  //                   </tr>
  //               </thead>
  //               <tbody>
  //                   {updDetail.series.map((serie, index) => (
                      
  //                     <tr key={index}>
  //                       <td>{serie.serial_number}</td>
  //                       <td className="text-center">
  //                         <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Eliminar</Tooltip>}>
  //                           <span className="">
  //                             <i className="bi bi-trash text-danger fs-3" style={{ cursor: 'pointer' }} onClick={()=>{deleteSerialNumber(serie)}}/>
  //                           </span>
  //                         </OverlayTrigger>
  //                       </td>
  //                     </tr>
  //                   )
  //                 )}
  //               </tbody>
  //             </Table>
  //           </div>                  
  //         </div>}
  //     </>
  //   )
  // }