import { useAuth } from 'app/modules/auth';
import { FC, useState, useMemo, useEffect } from 'react'
import { Button } from 'react-bootstrap';

const AdministrativeVoucherNew = () => {

    const [messageReceived, setMessageReceived] = useState("");
    
    return (
    <>
      <h1>Nuevo Comprobante</h1>
    </>
  )
}

export default AdministrativeVoucherNew