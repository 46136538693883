import React from 'react';
import './styles.css'

const Rack = ({rackData, tempType}) => {

    return (
        <div className={`rack ${tempType=='Ambiente'? "ambiente" : "frio"}`} >
        {/* Contenido de la estantería */}
        <h5>Rack: <b>{rackData.name}</b></h5>
        <h5>Estanterías: <b>{rackData.shelves?.length}</b> </h5>
        
        </div>
    )
  
};

export default Rack;