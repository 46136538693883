import { useState, useCallback } from 'react'

import { HealthInsurances } from 'services'

const useHealthInsurances = () => {
  const [loading, setLoading] = useState(false);

  const handleGetHealthInsurances = useCallback(async (params) => {
    try {
      setLoading(true)

      const response = await HealthInsurances.getHealthInsurances(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetHealthInsurance = useCallback(async (id, token) => {
    try {
      setLoading(true)
      const response = await HealthInsurances.getHealthInsurance(id, token)

      return response.data
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateHealthInsurance = useCallback(async (data, token) => {
    try {
      setLoading(true)
      const response = await HealthInsurances.createHealthInsurance(data, token)

      return response.data
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateHealthInsurance = useCallback(async (id, data, token) => {
    try {
      setLoading(true)
      const response = await HealthInsurances.updateHealthInsurance(id, data, token)

      return response.data
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetHealthInsurances,
    handleGetHealthInsurance,
    handleCreateHealthInsurance,
    handleUpdateHealthInsurance
  }
}

export default useHealthInsurances