import { FC } from 'react'
import { ErrorI } from './types'

const Error: FC<ErrorI> = ({ message }) => {
  return (
    <div className='mt-2 text-danger'>
      {message}
    </div>
  )
}

export default Error