import service from "../service"

const PATH = {
  genre: "/genre",
};

export class Genre {
  static getGenres = async (token) => {
    const response = await service.get(PATH.genre)

    return response
  }

  static getGenre = async (id, token) => {
    const response = await service.get(`${PATH.genre}/${id}`)

    return response
  }
}