import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { DetailContainer } from 'containers'
import { InputBS, SelectField, FormButton } from 'components'

import { usePaymentConditions, usePaymentMethods } from 'hooks'
import { createInitialValues, createSchema } from './constants'

import routes from 'app/routing/routes'

const AddPaymentCondition = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const [loading] = useState(false)
  const { handleCreatePaymentCondition } = usePaymentConditions()

  const [paymentMethods, setPaymentMethods] = useState([])
  const { handleGetPaymentMethods } = usePaymentMethods()

  const createPaymentCondition = async (values) => {
    try {
      const response = await handleCreatePaymentCondition(values, 'token')
      toast.success(response.message)
      navigate(routes.PAYMENT_CONDITIONS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await handleGetPaymentMethods('token')

      setPaymentMethods(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetPaymentMethods])

  useEffect(() => {
    getPaymentMethods()
  }, [getPaymentMethods])

  return (
    <>
      <DetailContainer title={intl.formatMessage({ id: 'TITLE.PAYMENT_CONDITIONS.CREATE' })}>
        <Formik
          initialValues={createInitialValues}
          validationSchema={createSchema}
          onSubmit={createPaymentCondition}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className='col col-9'>
                    <InputBS id='label' name='label' label='Label' placeholder='Ej: Cuenta corriente a 30 días' />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-3'>
                    <SelectField
                      name='payment_method_id'
                      label={intl.formatMessage({ id: 'FORM.PAYMENT_CONDITIONS.PAYMENT_METHOD' })}
                      options={paymentMethods.map(item => ({ value: item.id, label: item.name }))}
                    />
                  </div>

                  <div className='col-3'>
                    <InputBS id='days' name='days' type='number' label={intl.formatMessage({ id: 'FORM.PAYMENT_CONDITIONS.DAYS' })} />
                  </div>

                  <div className='col-3'>
                    <InputBS id='cancel_date' name='cancel_date' type='date' label={intl.formatMessage({ id: 'FORM.PAYMENT_CONDITIONS.CANCEL_DATE' })} />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddPaymentCondition