import { useState, useEffect } from 'react'

const useWindowsDimensions = () => {
  const [windowsDimensions, setWindowsDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    const handleResize = () => {
      setWindowsDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  })

  return {
    width: windowsDimensions.width,
    height: windowsDimensions.height,
  }
}

export default useWindowsDimensions