import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const Actions = ({ editing, handleEditing, submitRef, cancelRef, disabled }) => {
  const navigation = useNavigate()

  return (
    <div className='d-flex align-items-center justify-content-end'>
      {!editing
        ?
          <>
            <Button className='me-3' variant="secondary" type="button" onClick={() => navigation(-1)} disabled={disabled} ><i className="ki-duotone ki-left"></i>Volver</Button>
            <Button variant='primary' type="button" onClick={() => handleEditing(true)} disabled={disabled}><i className="bi bi-pencil" />Editar</Button>
          </>
        :
          <>
            <Button className='me-3' variant="secondary" type="button" onClick={() => cancelRef.current.click()} disabled={disabled}><i className="bi bi-slash-circle" />Cancelar</Button>
            <Button variant="primary" type="button" onClick={() => submitRef.current.click()} disabled={disabled}><i className="bi bi-save me-2" />Guardar</Button>
          </>
      }
    </div>
  )
}

export default Actions