import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { AssignmentOutlined } from '@mui/icons-material'
import { toast } from 'react-toastify';

import { useAuth } from 'app/modules/auth'
import { useSalesOrders } from 'hooks/Sales'
import { PageTitle } from '_metronic/layout/core'
import { DetailContainer, TabsContainer } from 'containers'
import { GeneralTab } from './partials'
import { FormButton } from 'components'

import { initialValues, schema, submitAdapter } from './constants'

import routes from 'app/routing/routes';

const AddSalesOrder = () => {
  const navigate = useNavigate()

  const { currentUser } = useAuth()
  const { loading, handleAddSalesOrder } = useSalesOrders()

  const handleSubmit = async (values) => {
    try {
      let data = submitAdapter(values)
      data = {
        ...data,
        user_id: currentUser?.id ? currentUser.id : currentUser?.user?.id,
        dispatch_date: values?.dispatch_date ? values.dispatch_date : null,
      }

      const response = await handleAddSalesOrder(data, 'token')

      toast.success("Registro creado correctamente", {theme:"colored"})
      navigate(`${routes.SALES_ORDERS}/${response.data.id}`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Ventas/', path: routes.DASHBOARD, isSeparator: false, isActive: false },
          { title: 'Pedidos/', path: routes.SALES_ORDERS, isSeparator: false, isActive: true },
          { title: 'Crear pedido de venta', path: routes.SALES_ORDERS_NEW, isActive: false }
        ]}
        description='Crear pedido de venta'
      />

      <DetailContainer title="Nuevo pedido de venta">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className="d-flex flex-column" encType="multipart/form-data">
                <TabsContainer
                  initialValue='general'
                  tabs={[{ value: 'general', label: "General", icon: <AssignmentOutlined />, show: true }]}
                  tabsContent={[<GeneralTab />]}
                />

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddSalesOrder