import { FormLabel, FormGroup, FormControl } from 'react-bootstrap'
import { useField } from 'formik'

const TextareaBS = ({label, disabled = false, ...props}) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;

  return (
    <>
      <FormGroup className={props.className}>
        <FormLabel>{label}</FormLabel>
        <FormControl as="textarea" rows={3} {...field} {...props} className={`form-control ${showError ? 'border-danger' : ''}`} disabled={disabled} />
      </FormGroup>
      {showError && <div className='text-danger position-absolute'>{meta.error}</div>}
    </>
  )
}

export default TextareaBS;