import React, { useEffect, useMemo, useState } from "react";
import { Button, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import '../article.css'
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { currentDate } from "utilities";
import { useLogisticStock } from "hooks";
import { toast } from "react-toastify";
import moment from 'moment';

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px',
    height: '43px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};


export function ArticleEditModal({ show, onHide, detailForEdit, deleteSerialDetail, storageOptions, default_storage_space_id, articlesGrouped}) {

    const [updDetail, setUpdDetail] = useState({});
    const [refrigeratedAlert, setRefrigeratedAlert]  = useState(false);
    const [existingLots, setExistingLots] = useState([]);
    const [expDateDisabled, setExpDateDisabled] = useState(false)
    const {handleGetStockDetail} = useLogisticStock()

    const getExistingLots = async(product_var_id)=>{
        try{
            if(product_var_id){
                const _resp = await handleGetStockDetail({product_var_id})
                setExpDateDisabled(false)
                if(_resp?.result){
                  const _lotes = _resp.result
                    .map(l=>({
                      product_var_id:l.product_var_id, 
                      lot_number:l.lot_number, 
                      expiration_date:l.expiration_date
                    }))
                  const _cargadosEnEstaOrden =articlesGrouped
                    .filter(a=>a.product_var_id==product_var_id)
                    .map(l=>({
                      product_var_id:l.product_var_id, 
                      lot_number:l.lot_number, 
                      expiration_date:l.expiration_date
                    }))

                  setExistingLots([..._lotes, ..._cargadosEnEstaOrden])
                }
                else{
                  setExistingLots([])
                }                
            }
        }
        catch(err){
          toast.error("Error cargando lotes")
        }
    }

    const handleChange = (e)=>{
        let {name, value} = e.target;
        if(name=='qty'){
          value = value.replace(/\D/g, '');
        }        
        if(name=='lot_number'){
          value = value?.toUpperCase()
        }

        setUpdDetail({...updDetail, [name]:value})
    }


    const getValueDepot = () => {
      if (storageOptions) {
        return storageOptions.find(option => option.value == updDetail.storage_space_id);
      } else {
        return "";
      }
    };

    const onChangeDepot = (option) => {
      setUpdDetail({...updDetail, storage_space_id:option.value});
      if(detailForEdit.refrigerated==1 && option.cool_type_id==1){
        setRefrigeratedAlert(true)
      }
      else{
        setRefrigeratedAlert(false)
      }
    };
    
    const onBlurLot = (ev)=>{
      let aux = existingLots.filter(s=>s.lot_number==ev?.target?.value)
      if(aux && aux.length){
          //puede haber mas del un row con el mismo lote, que esten en distintos espacios.. la fecha debería ser la misma, tomo el 0
          setUpdDetail({...updDetail, expiration_date:aux[0].expiration_date? moment(aux[0].expiration_date).format('YYYY-MM-DD'):""})
          setExpDateDisabled(true)
      }
      else{
        setExpDateDisabled(false)
      }
    }


    useEffect(()=>{      
      setUpdDetail({...detailForEdit, storage_space_id:detailForEdit.storage_space_id?detailForEdit.storage_space_id:default_storage_space_id});
    }, [detailForEdit])


  
    const deleteSerialNumber = async (serial)=>{
      const resp = await deleteSerialDetail({detail_id:serial.id, in_order_id:serial.in_order_id}) 
      const newSeries = updDetail.series.filter(s=>s.serial_number!=serial.serial_number)
      setUpdDetail({...updDetail, series:newSeries})
    }
  
    useEffect(()=>{
      if(show){
        getExistingLots(detailForEdit.product_var_id)
      }
    }, [show])


    return (
      <>
            <Modal size="md" show={show} onHide={onHide} backdrop='static' >

              <Modal.Body className="">
                <h3>{detailForEdit.product_var_name}</h3>       

                {
                  updDetail.product_var_traced == 1 ? 
                    <Traceable 
                      updDetail={updDetail} 
                      handleChange={handleChange} 
                      getValueDepot={getValueDepot} 
                      onChangeDepot= {onChangeDepot} 
                      deleteSerialNumber={deleteSerialNumber} 
                      storageOptions={storageOptions} 
                      refrigeratedAlert={refrigeratedAlert}/>
                  :
                    <NonTraceable 
                      updDetail={updDetail} 
                      handleChange={handleChange} 
                      getValueDepot={getValueDepot} 
                      onChangeDepot= {onChangeDepot} 
                      storageOptions={storageOptions}
                      refrigeratedAlert={refrigeratedAlert} 
                      onBlurLot= {onBlurLot} 
                      expDateDisabled= {expDateDisabled} />
                }

              </Modal.Body>

              <Modal.Footer style={{borderTop: "0 none"}}>
                <Button variant="secondary" onClick={onHide}>Salir</Button>
                <Button variant="primary" onClick={()=>onHide(updDetail)}>Guardar</Button>
              </Modal.Footer>
          </Modal>
    </>
    )
  }

  const NonTraceable = ({updDetail, handleChange, getValueDepot, onChangeDepot, storageOptions, refrigeratedAlert, onBlurLot, expDateDisabled})=>{
    

    return (
      <>
        <div className="row mt-10">
          <div className='col'>
            <FormLabel style={labelStyle}>Espacio de almacenamiento</FormLabel>
            <Select
              options={storageOptions}
              value={getValueDepot()}
              onChange={onChangeDepot}
              styles={customStyles}
            />
          </div>
          <div className="col">
            <label htmlFor='qty' className='form-label'>Cantidad</label>
            <input type="text" className='form-control' id='qty' name="qty" maxLength={5} 
              value={updDetail.qty || ''} autoComplete="off" onChange={handleChange} disabled={updDetail.product_var_traced} />
          </div>
        </div>
        
        { refrigeratedAlert==true && <div className="text-danger"> El espacio de almacenamiento no es de frío </div>}
        
        <div className="row mt-10">
          <div className="col">
            <label htmlFor='lot_number' className='form-label' maxLength={30}>Lote</label>
            <input type="text" className='form-control' id='lot_number' name="lot_number" autoComplete="off" 
              value={updDetail.lot_number || ''} onChange={handleChange} disabled={updDetail.trazable}
              onBlur={onBlurLot} />
          </div>
          <div className="col">
            <label htmlFor='expiration_date' className='form-label'>Vencimiento</label>
            <input type="date" className='form-control' id='expiration_date' 
              value={updDetail.expiration_date || ''} onChange={handleChange} name="expiration_date" disabled={updDetail.trazable || expDateDisabled} min={currentDate}/>
          </div>
        </div>
      </>
    )
  }



  const Traceable = ({updDetail, handleChange, getValueDepot, onChangeDepot, deleteSerialNumber, storageOptions, refrigeratedAlert})=>{
    return (
      <>
          <div className="row mt-10">
            <div className='col'>
              <FormLabel style={labelStyle}>Espacio de almacenamiento</FormLabel>
              <Select
                options={storageOptions}
                value={getValueDepot()}
                onChange={onChangeDepot}
                styles={customStyles}
              />
            </div>
            <div className="col">
              <label htmlFor='qty' className='form-label'>Cantidad</label>
              <input type="text" className='form-control' id='qty' name="qty" maxLength={5} 
                value={updDetail.qty || ''} autoComplete="off" onChange={handleChange} disabled={updDetail.product_var_traced} />
            </div>
          </div>
        
        { refrigeratedAlert==true && <div className="text-danger"> El espacio de almacenamiento no es de frío </div>}
        
        
          <div className="row mt-10">
            <div className="col">
              <label htmlFor='lot_number' className='form-label' maxLength={30}>Lote</label>
              <input type="text" className='form-control' id='lot_number' name="lot_number" autoComplete="off" 
                value={updDetail.lot_number || ''} onChange={handleChange} disabled={true}/>
            </div>
            <div className="col">
              <label htmlFor='expiration_date' className='form-label'>Vencimiento</label>
              <input type="date" className='form-control' id='expiration_date' 
                value={updDetail.expiration_date || ''} onChange={handleChange} name="expiration_date" disabled={true}/>
            </div>
          </div>      
          {updDetail.series && updDetail.series.filter(i=>i.serial_number!=null).length>0 && <div className="row mt-10">
            <div className="col">
              <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Nro. Serie</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {updDetail.series.map((serie, index) => (
                      
                      <tr key={index}>
                        <td>{serie.serial_number}</td>
                        <td className="text-center">
                          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Eliminar</Tooltip>}>
                            <span className="">
                              <i className="bi bi-trash text-danger fs-3" style={{ cursor: 'pointer' }} onClick={()=>{deleteSerialNumber(serie)}}/>
                            </span>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>                  
          </div>}
      </>
    )
  }