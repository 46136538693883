import React, { useEffect, useRef, useState } from "react";
import { Button, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Select from 'react-select';
import { currentDate } from "utilities";
import { useIncomeOrder, useLogisticStock } from "hooks";
import moment from 'moment';
import { toast } from "react-toastify";
import '../article.css'
import 'react-toastify/dist/ReactToastify.css';
import { ZebraScanner } from "components";
import { parseGS1DataMatrix } from "utilities/gs1DataMatrix";

export const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '39px',
    height: '39px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};

const defaultArticle={
  product_var_id:"",
  product_var_name:"",
  qty:1,
  lot_number:"",
  expiration_date:"",
  serial_number:"",
  refrigerated:""
}

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

export function GS1ParcialModal({ show, onHide, requestArticles, default_storage_space_id, storageOptions, in_order_id}) {

    const [storageData, setStorageData] = useState(defaultArticle);
    const [submitted, setSubmitted] = useState(false);
    const [refrigeratedAlert, setRefrigeratedAlert]  = useState(false);
    const [series, setSeries] = useState([]);


    const handleChange = (e)=>{
        let {name, value} = e.target;
        setStorageData({...storageData, [name]:value})
    }

    const getValueDepot = () => {
      if (storageOptions) {
        return storageData.storage_space_id? storageOptions.find(option => option.value === storageData.storage_space_id) : {};
      } else {
        return {};
      }
    };
    
    const onChangeDepot = (option) => {
      setStorageData({...storageData, storage_space_id:option.value});
      if(storageData.refrigerated==1 && option.cool_type_id==1){
        setRefrigeratedAlert(true)
      }
      else{
        setRefrigeratedAlert(false)
      }
    };


/*****************************************************************************************/

    const barcodesRef = useRef([]);
    const processingQueue = useRef(false);
    const canAddCode = useRef(true);
    const {handleCreateIncomeOrderDetail} = useIncomeOrder();
    const scanCodesRef = useRef([]);

    const processEnter = (cadena)=>{
      if(!getValueDepot() || getValueDepot()=="" || !storageData.lot_number || !storageData.expiration_date){
        setSubmitted(true)
        return;
      }

      // console.log(cadena)
      setSubmitted(false)

      if(canAddCode.current){
        canAddCode.current=false;
        const buffCadena=cadena;
        cadena="";
        const dataTransformada = buffCadena.replace(/Shift/g, "")
            .replace(/CapsLock/g, '') 
            .replace(/Alt0029/g, "\u001D")
            .replace(/'/g, '-')

        const data = parseGS1DataMatrix(dataTransformada)
        data.scan_code=dataTransformada;

        if(data.lot_number){
            toast.error("El código GS1 no es parcial, se debe pickear con el botón 'Pick!'")
            canAddCode.current=true;
            return;
        }

        const _product = requestArticles.filter(a=>a.gtin==data.gtin);
        if(!_product || _product.length<1){
            toast.error("El código escaneado es de un gtin no perteneciente al pedido")
            canAddCode.current=true;
            return;
        }
        if(!_product[0].traced){
          toast.error(`El artículo ${_product[0].label} no está marcado como trazable en el sistema`)
          return;
        }
        
        // const _depot = getValueDepot() 
        // setStorageData({...storageData, product_var_id:option.value, refrigerated:option.refrigerated})
        // if(option.refrigerated==1 && _depot && _depot.cool_type_id==1){
        //   setRefrigeratedAlert(true)
        // }
        // else{
        //   setRefrigeratedAlert(false)
        // }

// console.log(requestArticles)
// console.log(_product)
// console.log(data)

        if (!barcodesRef.current.map(c=>c.serial_number).includes(data.serial_number)) {
            const transfer = {
                qty: 1,
                product_var_name: _product[0]?.label,
                lot_number: storageData.lot_number,
                expiration_date: storageData.expiration_date,
                gtin: data.gtin,
                product_var_id: _product[0].value,
                serial_number: data.serial_number?data.serial_number:undefined,
                storage_space_id: getValueDepot().value,
                in_order_id: in_order_id,
                loading:true
            }

            setSeries(series=>[transfer, ...series])
            // handleNewTag(transfer, null)
            // setCountPicking(countPicking=>countPicking+1)
            const asyncJob = getAsyncJob(transfer);
            enqueue(asyncJob);
        }
        canAddCode.current=true;
      }
    }

    const nextJob = () => {
        
      if (processingQueue.current) 
          return;
        
        const next = barcodesRef.current.shift();
        // if the array is empty shift() will return undefined
        if (next) {
          processingQueue.current = true;
          next
            .job()
            .then((value) => {
              // console.log(value);
              next.resolve(value);
              updateTagView(value)
              processingQueue.current = false;
              nextJob();
            })
            .catch((error) => {
              // console.error(error);
              next.resolve(error);
              updateTagView(error)
              processingQueue.current = false;
              nextJob();
            });
        }
    }

    const enqueue = (job) => {
        new Promise((resolve, reject) => {
            barcodesRef.current.push({ job, resolve, reject });
        });
        nextJob();
    }

    const getAsyncJob = (item) => {
        return () =>
          new Promise((resolve, reject) => {
            const transport = { ...item, in_order_id: in_order_id }
            handleCreateIncomeOrderDetail(transport).then(res=>resolve(res)).catch(err=>reject({...transport, err}))
          });
    };


    const updateTagView = (value)=>{
      // console.log(value)
      // console.log(scanCodesRef.current)
        if(value.err){
          const newUpdate = [...scanCodesRef.current];
          const cambio = newUpdate.find(a => a.serial_number==value.serial_number && a.loading);
          console.log(cambio)
          if(cambio){
            cambio.loading=!cambio.loading;
            cambio.error=value.err?.message;
            setSeries(newUpdate);
          } 
        }
        else{
          const newUpdate = [...scanCodesRef.current];
          const cambio = newUpdate.find(a => a.serial_number==value.serial_number && a.loading);
          if(cambio){
            cambio.loading=!cambio.loading;
            cambio.error=null;
            setSeries(newUpdate);
          }  
        }
    }

    useEffect(() => {
      scanCodesRef.current = series;
    }, [series]);

/****************************************************************************************** */


    useEffect(()=>{

        setStorageData({...defaultArticle, storage_space_id:default_storage_space_id});
        setSubmitted(false);
        setRefrigeratedAlert(false)
        setSeries([])

        barcodesRef.current = [];
        processingQueue.current = false;
        canAddCode.current = true;
        scanCodesRef.current = [];
    

      }, [show])


    return (
      <>
            <Modal size="lg" show={show} onHide={onHide}  backdrop='static' >

            <Modal.Body className="">
              <h3>Ingreso por código GS1 parcial</h3>                       

                    <div className="row mt-10">
                      <div className='col'>                                  
                        <FormLabel style={labelStyle}>Espacio de almacenamiento</FormLabel>
                        <Select
                          options={storageOptions}
                          value={getValueDepot()}
                          onChange={onChangeDepot}
                          styles={customStyles}
                          className={`${submitted && (!storageData.storage_space_id || storageData.storage_space_id=='')?"border border-danger":""}`}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor='lot_number' className='form-label' maxLength={30}>Lote</label>
                        <input type="text"
                          className={`form-control ${submitted && (!storageData.lot_number || storageData.lot_number=='')?"border border-danger":""}`}
                          id='lot_number' name="lot_number" autoComplete="off" 
                          value={storageData.lot_number || ''} 
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor='expiration_date' className='form-label'>Vencimiento</label>
                        <input type="date" 
                          className={`form-control ${submitted && (!storageData.expiration_date || storageData.expiration_date=='')?"border border-danger":""}`}
                          id='expiration_date' 
                          value={storageData.expiration_date || ''} onChange={handleChange} name="expiration_date"  min={currentDate}
                        />                          
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                      { refrigeratedAlert==true && <div className="text-danger"> El espacio de almacenamiento no es de frío </div>}
                      </div>
                    </div>
                    <div className="row mt-10">

                    </div>

                    <>
                        <div className="row mt-10">
                          <div className="col">
                            <ZebraScanner 
                                processEnter = {processEnter}  
                                disabled={false}
                            />
                          </div>
                        </div>
                      
                      <div className="row mt-10">
                        <div className="col">
                        <Table striped bordered hover>
                          <thead>
                              <tr>
                                <th>Artículo</th>
                                <th>Nro. Serie</th>
                                <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              {series.map((serie, index) => (
                                
                                <tr key={index}>
                                  <td>{serie.product_var_name}</td>
                                  <td>{serie.serial_number}</td>
                                  <td className="text-center">{
                                    serie.loading
                                    ?<div className='spinner-border text-success'></div>
                                    :
                                    serie.error
                                      ? <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>{serie.error}</Tooltip>}>
                                          <span><i className="fa fa-times text-danger fs-3"/></span>
                                        </OverlayTrigger>
                                      : <span><i className="fa fa-check text-success fs-3"/></span>
                                    }
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                        </div>
                      </div>
                    </>
                

            </Modal.Body>

            <Modal.Footer style={{borderTop: "0 none"}}>
              <Button variant="secondary" onClick={onHide}>Cerrar</Button>
            </Modal.Footer>


          </Modal>
    </>
    )
  }
  