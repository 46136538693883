import { useEffect, useState } from 'react'
import { Modal, FormLabel, Button, Form } from 'react-bootstrap'

import { toast } from 'react-toastify'
import moment from 'moment'
import { useWaves } from 'hooks'
import {helix} from 'ldrs'
import { CustomTable, FormTable } from 'components'

const AddToWaveModal = ({ show, onHide, outOrders, handleOutOrders, handleGetOutOrders, availableWaves }) => {
  const [items, setItems] = useState([])
  const { loading, handleAddWaveOrders } = useWaves()
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
  };



  const columns = [
    {
      Header: "ID",
      id: 'id',
      accessor: 'id'
    },
    {
      Header: "Nombre",
      id: 'name',
      accessor: 'name'
    },
    {
      Header: "Creada",
      id: 'created_at',
      accessor:  (row) => (
        <>
          {row.created_at?moment(row.created_at).format("DD/MM/YYYY"):''}
        </>
      )
    },
    {
      Header: <div className='text-center'>Seleccionar</div>,
      id: 'select',
      accessor:  (row) => (
        <div className='text-center'>
          <Form.Check
            type="radio"
            checked={row==selectedRow}
            // checked={product.selected}
            onChange={() => {
                setSelectedRow(row);
              }}
          />
        </div>
      )
    },
  ]

  const handleSubmit = async () => {

    if(!selectedRow){
      return;
    }

    const items = outOrders.filter(item => item.checked)
    const data = {
      out_orders: outOrders,
    }
    delete data.text

    try {
      const response = await handleAddWaveOrders(selectedRow.id, data)
      toast.success("Registro agregado correctamente", {theme: "colored"})
      handleOutOrders([])
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetOutOrders()
      onHide(selectedRow.id)
    }
  }

  useEffect(() => {
    setItems(outOrders)
  }, [outOrders])

  useEffect(()=>{
    if(show){
      setSelectedRow(null)
    }
  }, [show])

  useEffect(()=>{
    helix.register()
  }, [])

  if (!show) return null

  return (
    <Modal size="lg" centered show={show} onHide={onHide} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Agregar a ola existente</Modal.Title>
      </Modal.Header>

      <Modal.Body>
          <div className="row">
            <div className="col">
              {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"30px"}}></l-helix>}
              <CustomTable columns={columns} data={availableWaves || []}></CustomTable>
            </div>
          </div>

          <div className="row">
            <div className="col" style={{height:"30px"}}>
                  { selectedRow ?(
                    outOrders.length==1?
                      <p className='pt-8 fst-italic'>Se va a agregar <b>{outOrders.length}</b> órden a la ola <b>{selectedRow.id}</b> </p>
                      :
                      <p className='pt-8 fst-italic'>Se van a agregar <b>{outOrders.length}</b> órdenes a la ola <b>{selectedRow.id}</b> </p>
                  ) 
                    
                    :
                    <></>
                  }
            </div>
          </div>

          <div className='separator my-8'></div>

          <div className='d-flex align-items-center justify-content-end'>
            <Button
              variant='secondary'
              type='button'
              onClick={() => {
                // setFieldValue('out_orders', outOrders)
                onHide()
              }}
              className='me-3'
            >
              <i className="bi bi-slash-circle" />
              Cancelar
            </Button>

            <Button variant='primary' type='submit' onClick={handleSubmit} disabled={loading}><i className='bi bi-save me-2' />Añadir</Button>
          </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddToWaveModal