import { useState, useEffect, useCallback } from 'react'

import { useArticles } from './';
import { ArticleI } from 'interfaces/Inventory';


const useGetArticleDetail = (params:any, isNew = false) => {
  const { loading, handleGetArticle } = useArticles();
  const [article, setArticle] = useState<ArticleI | null>(null);

  const refreshArticle = useCallback(async () => {
    try {
      const response = await handleGetArticle(params);
      setArticle(response);
    } catch (error) {
      throw error;
    }
  }, [handleGetArticle]);

  useEffect(() => {
    const getDetail = async () => {
      if (!isNew) {
        await refreshArticle();
      }
    }

    getDetail();
    
  }, [isNew, refreshArticle, handleGetArticle]);

  return {
    loading,
    article,
    refreshArticle,
  }
};

export default useGetArticleDetail;