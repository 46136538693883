import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useCustomerSuppliers, useAgentTypes } from 'hooks'
import { SelectField, Input } from 'components'
import { Button } from 'react-bootstrap'

const ORIGIN_OPTIONS = [
  { value: '', label: 'Todos' },
  { value: 'GOOGLE', label: 'GOOGLE' },
  { value: 'MANUAL', label: 'MANUAL' },
  { value: 'MIGRACION', label: 'MIGRACION' },
]

const initialValues = {
  text: '',
  localidad: '' ,
  provincia: '',
  gln: '',
  origin_filter: '',
  agent_type_id_filter: [],
  verifarma: '',
  is_enabled: '',
}

const Filter = ({ handleData, handleTotalCount, query, handleQuery }) => {
  const { id } = useParams()
  const { loading, handleGetCustomerSupplierDetailShipmentAddresses } = useCustomerSuppliers()

  const { handleGetAgentTypes } = useAgentTypes()
  const [agentTypes, setAgentTypes] = useState([])

  const filterData = async (values) => {
    let params = {
      ...query,
      ...values,
      text: values?.text ? values.text : undefined,
      localidad: values?.localidad ? values.localidad : undefined,
      provincia: values?.provincia ? values.provincia : undefined,
      gln: values?.gln ? values.gln : undefined,
      origin_filter: values?.origin_filter ? values.origin_filter : undefined,
      agent_type_id_filter: values?.agent_type_id_filter ? values.agent_type_id_filter : undefined,
      verifarma: values?.verifarma ? values.verifarma : undefined,
      is_enabled: values?.is_enabled ? values.is_enabled : undefined,
    }

    try {
      const response = await handleGetCustomerSupplierDetailShipmentAddresses(id, params)
      const data = response.data.result

      handleQuery({ ...params, pageNumber: 1, pageSize: 10 })
      handleData(data)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  const getAgentTypes = useCallback(async () => {
    try {
      const response = await handleGetAgentTypes()
      const data = response.result.map(d => ({
        value: d.id,
        label: d.name,
      }))

      setAgentTypes(data)
    } catch (error) {
      setAgentTypes([])
      toast.error(error.message)
    }
  }, [handleGetAgentTypes])

  useEffect(() => {
    getAgentTypes()
  }, [getAgentTypes])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filterData}
    >
      {({ values, errors, handleSubmit }) => {
        return (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit(); // Realizar la acción del filtro aquí
              }
            }}
          >
            <div className='row'>
              <div className='col-2'>
                <SelectField
                  id='origin_filter'
                  name='origin_filter'
                  options={ORIGIN_OPTIONS}
                  placeholder='Seleccione el origen'
                  marginTop={'-20px'}
                />
                <p className='text-muted'>Origen</p>
              </div>

              <div className='col-4'>
                <Input id='text' name='text' />
                <p className='text-muted'>Nombre / Domicilio</p>
              </div>

              <div className='col-4'>
                <Input id='localidad' name='localidad' />
                <p className='text-muted'>Localidad</p>
              </div>

              <div className='col-2'>
                <Input id='provincia' name='provincia' />
                <p className='text-muted'>Provincia</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-2'>
                <Input id='gln' name='gln' />
                <p className='text-muted'>GLN</p>
              </div>

              <div className='col-4'>
                <SelectField
                  id='agent_type_id_filter'
                  name='agent_type_id_filter'
                  options={agentTypes}
                  placeholder='Seleccione el tipo de agente'
                  marginTop={'-20px'}
                  isMulti
                />
                <p className='text-muted'>Tipo de agente</p>
              </div>

              <div className='col-2'>
                <SelectField
                  id='verifarma'
                  name='verifarma'
                  options={[
                    { value: '', label: 'Todos' },
                    { value: '1', label: 'Sí' },
                    { value: '0', label: 'No' },
                  ]}
                  placeholder='Seleccionar'
                  marginTop={'-20px'}
                />
                <p className='text-muted'>Verifarma</p>
              </div>

              <div className='col-2'>
                <SelectField
                  id='is_enabled'
                  name='is_enabled'
                  options={[
                    { value: '', label: 'Todos' },
                    { value: '1', label: 'Sí' },
                    { value: '0', label: 'No' },
                  ]}
                  placeholder='Seleccionar'
                  marginTop={'-20px'}
                />
                <p className='text-muted'>Habilitada</p>
              </div>
            </div>

            <div className='row'>
              <div className='col text-end'>
                <Button type='button' variant="primary" onClick={handleSubmit} disabled={loading}>
                  <i className="bi bi-search mb-1" />
                  Buscar
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter