import service from "../../service";

const PATH = {
  Incomes:"/incomes",
  InOrder: "/incomes/in-order",
  OutOrder: "/out-orders",
  Movement: "/stock/depot-movement",
  Stock:"/product/stock"
};


export class Logistic {

  static getIncomeOrders = async (params) => {
    const response = await service.get(PATH.InOrder, {
      params
    })
    return response;
  };

  static getIncomeOrder = async (id) => {
    const response = await service.get(`${PATH.InOrder}/${id}`);
    return response;
  };

  static getIncomeOrderDetailsWithErrors = async (params) => {
    const response = await service.get(`${PATH.InOrder}/details-with-errors`, {
      params,
    });

    return response
  }

  static createIncomeOrder= async (data) => {
    if(data!=undefined && data.estimated_in_date==""){
      data.estimated_in_date=null;
    }
    if(data!=undefined && data.supplier_invoice_date==""){
      data.supplier_invoice_date=null;
    }

    const response = await service.post(`${PATH.InOrder}`, data);
    return response;
  };

  static updateIncomeOrder = async (data) => {
    const {in_order_id, ...rest} = data;
    const response = await service.put(`${PATH.InOrder}/${in_order_id}`, rest);
    return response;
  };
  
  static createIncomeOrderDetail= async (data) => {
    const {in_order_id, ...rest} = data;
    const response = await service.post(`${PATH.InOrder}/${in_order_id}/detail`, rest);
    return response;
  };

  static updateIncomeOrderDetail = async (data) => {
    const {in_order_id, id, ...rest} = data;
    const response = await service.put(`${PATH.InOrder}/${in_order_id}/detail/${id}`, rest);
    return response;
  };

  static incomeOrderDetailManualTraced = async (in_order_id, in_order_detail_id) => {
    const response = await service.patch(`${PATH.InOrder}/${in_order_id}/detail/${in_order_detail_id}/manual-traced`)
    return response
  }

  static updateDetailStorageSpace= async (data) => {
    const {in_order_id, ...rest} = data;
    const response = await service.put(`${PATH.InOrder}/${in_order_id}/detail/storage-space`, rest);
    return response;
  };

  static deleteIncomeOrderDetail= async (data) => {
    const {in_order_id, ...rest} = data;
    const response = await service.post(`${PATH.InOrder}/${in_order_id}/detail/delete`, rest);
    return response;
  };

  static deleteSerialIncomeOrderDetail= async (data) => {
    const {in_order_id, detail_id} = data;
    const response = await service.delete(`${PATH.InOrder}/${in_order_id}/detail/${detail_id}`);
    return response;
  };


  static duplicateIncomeOrderDetail= async (data) => {
    const {in_order_id, ...rest} = data;
    const response = await service.post(`${PATH.InOrder}/${in_order_id}/detail/duplicate`, rest);
    return response;
  };

  static confirmIncomeOrder = async (id) => {
    const response = await service.post(`${PATH.InOrder}/${id}/confirm`);
    return response;
  };

  static confirmIncomeOrderForced = async (id) => {
    const response = await service.post(`${PATH.InOrder}/${id}/confirm-forced`);
    return response;
  };

  static setInvoice = async (data) => {
    const {id, ...rest} = data;
    const response = await service.post(`${PATH.InOrder}/${id}/set-invoice`, rest);
    return response;
  };

  static setWaybill = async (data) => {
    const { id, ...rest } = data
    const response = await service.post(`${PATH.InOrder}/${id}/set-waybill`, rest)
    return response
  }

  // static duplicateIncomeOrderDetail= async (data) => {
  //   const {in_order_id, id} = data;
  //   const response = await service.post(`${PATH.InOrder}/${in_order_id}/detail/${id}/duplicate`);
  //   return response;
  // };


  static getTracers = async () => {
    const response = await service.get(PATH.Incomes + '/payment-condition');
    return response;
  };


  static postInOrderGS1= async (in_order_id, scanCode, data) => {
    const response = await service.post(`${PATH.InOrder}/${in_order_id}/detail/scan-code/${scanCode}`, data);
    return response;
  };

  static postOutOrderGS1= async (out_order_id, scanCode, data) => {
    const response = await service.post(`${PATH.OutOrder}/${out_order_id}/detail/scan-code/${scanCode}`, data);
    return response;
  };

  static postMovementGS1= async (movement_id, scanCode, data) => {
    const response = await service.post(`${PATH.Movement}/${movement_id}/detail/scan-code/${scanCode}`, data);
    return response;
  };

  static anmatValidate= async (data) => {

    const {in_order_id, ...rest} = data

    const response = await service.post(`${PATH.InOrder}/${in_order_id}/anmat/validate`, {});
    return response;
  };

  static reintentarTraza= async (in_order_id) => {
    const response = await service.post(`${PATH.InOrder}/${in_order_id}/reconfirm`, {});
    return response;
  };

  static getStockList = async (params) => {
    // const response = await service.get(PATH.Stock + params);
    const response = await service.get(PATH.Stock, {params});
    return response;
  };

  static getStockDetail = async (params) => {
    const response = await service.get(`${PATH.Stock}/detail`, {params});
    return response;
  };

  
  static updateLotNumberInTraceError= async (data) => {
    const {in_order_id, in_order_detail_id, ...rest} = data;
    const response = await service.put(`${PATH.InOrder}/${in_order_id}/detail/${in_order_detail_id}/error-traza`, rest);
    return response;
  };

  static getStockReserved = async (params) => {
    const response = await service.get(`${PATH.Stock}/reserved`, {params});
    return response;
  };

  static transaccionesNoConfirmadas= async (in_order_id) => {
    const response = await service.get(`${PATH.InOrder}/${in_order_id}/anmat/transacciones-no-confirmadas`);
    return response;
  };


}