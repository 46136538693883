/*

  1	 GENERADO	Generado
  2	 PENDIENTE_INGRESO	Pendiente de ingreso
  3	 INGRESO_PARCIAL	Ingreso parcial
  4	 VENCIDO	Vencido
  5	 CANCELADO	Cancelado
  6	 COMPLETO	Completo
  7	 BORRADOR	Borrador
  8	 AUTORIZADO	Autorizado
  9	 CONFIRMADO	Confirmado
  10 COMPLETO_PARCIAL	Completo parcial
  11 INGRESADO
  12 RECHAZADO
  13 ERROR TRAZA

*/

const order_statuses = [
  // { value: '', label: 'Todos' },
  { value: 1, name: 'GENERADO', label: 'Pendiente de autorización' },
  { value: 2, name: 'PENDIENTE_INGRESO', label:'Pendiente de ingreso' },
  // { value: 3, name: 'INGRESO_PARCIAL', label:'Ingreso parcial' },
  // { value: 4, name: 'VENCIDO', label:'Vencido' },
  // { value: '5', name: 'CANCELADO', label:'Cancelado' },
  { value: 6, name: 'COMPLETO', label:'Completo' },
  { value: 7, name: 'BORRADOR', label:'Borrador' },
  // { value: '8', name: 'AUTORIZADO', label:'Autorizado' },
  // { value: 8, name: 'CONFIRMADO', label:'Confirmado' },
  { value: 10, name: 'COMPLETO_PARCIAL', label:'Completo parcial' },
]

const PurchaseRequestStatusI = {
  GENERADO : 1,
  PENDIENTE_INGRESO : 2,
  COMPLETO : 6,
  BORRADOR : 7,
  COMPLETO_PARCIAL:10
}

export {
  order_statuses,
  PurchaseRequestStatusI
}