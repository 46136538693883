import { forwardRef} from 'react'
import { Button } from '@mui/material'

const ButtonSecondary = forwardRef(({ type='button', text = 'Cancelar', handleClick, styles = '', disabled = false }, ref) => {
  return (
    <Button
      ref={ref}
      type={type}
      onClick={handleClick}
      variant='outlined'
      className={styles}
      sx={{
        color: 'black',
        backgroundColor: 'var(--bs-secondary)',
        border: '1px solid var(--bs-secondary)',
        '&:hover': {
          border: '1px solid var(--bs-primary)',
          backgroundColor: 'var(--bs-secondary-light)',
        },
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  )
})

export default ButtonSecondary