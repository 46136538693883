import { useState, useRef, useCallback, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap';
import { AssignmentOutlined, AttachMoneyOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify'

import { useCustomerSuppliers } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import { initialValues, dataAdapter } from './constants'
import { Actions, GeneralTab, PerceptionsTab } from './partials';
import routes from 'app/routing/routes'

const CustomerSupplierTaxes = () => {
  const { id } = useParams()
  const [updating, setUpdating] = useState(false)
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetCustomerSupplierTaxesData, handleUpdateCustomerSupplierTaxesData, handleGetCustomerSupplierMultilateralAgreement } = useCustomerSuppliers()

  const [taxesData, setTaxesData] = useState(initialValues)

  const getTaxesData = useCallback(async () => {
    try {
      let response = await handleGetCustomerSupplierTaxesData(id)
      setTaxesData(dataAdapter(response.data.result))

      response = await handleGetCustomerSupplierMultilateralAgreement(id)
      setTaxesData(prevState => ({ ...prevState, multilateral_agreement: response.data.result }))
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetCustomerSupplierTaxesData, handleGetCustomerSupplierMultilateralAgreement, id])

  const handleUpdate = async (data) => {
    try {
      const response = await handleUpdateCustomerSupplierTaxesData(id, data)
      toast.success("Registro actualizado correctamente" , { theme: "colored" })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setUpdating(false)
      getTaxesData()
    }
  }

  useEffect(() => {
    getTaxesData()
  }, [getTaxesData])

  return (
    <>
      <DetailContainer
        loading={loading}
        title={`#${taxesData?.id} - ${taxesData?.business_name} / ${taxesData?.documentation_type_name} - ${taxesData?.documentation}`}
        buttons={<Actions values={taxesData} updating={updating} handleUpdating={setUpdating} submitRef={submitRef} cancelRef={cancelRef} loading={loading} />}
      >
        <Formik
          initialValues={taxesData}
          onSubmit={handleUpdate}
          // validationSchema={}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column'>
                <TabsContainer
                  initialValue='general'
                  tabs={[
                    { value: 'general', label: 'General', icon: <AssignmentOutlined />, show: true },
                    { value: 'taxes', label: 'Percepciones', icon: <AttachMoneyOutlined />, show: true },
                  ]}
                  tabsContent={[
                    <GeneralTab updating={updating} handleGetTaxesData={getTaxesData} />,
                    <PerceptionsTab updating={updating} />,
                  ]}
                />

                {updating &&
                  <>
                    <div className='separator my-8'></div>

                    <div className='d-flex flex-row align-items-center justify-content-end'>
                      <Button
                        ref={cancelRef}
                        variant='secondary'
                        type='button'
                        className='me-3'
                        onClick={() => {
                          setUpdating(false)
                          handleReset()
                        }}
                      >
                        <i className="bi bi-slash-circle" />
                        Cancelar
                      </Button>

                      <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </div>
                  </>
                }
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default CustomerSupplierTaxes