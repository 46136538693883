import { useState, useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useCustomerSuppliers, usePatients, useShipmentAddresses } from 'hooks'

import { ShipmentAddressModal, PatientModal } from 'containers'
import { Label, Checkbox, SelectField, TextareaBS, InputBS, Textarea } from 'components'

import { HomeDeliveryOptionI, RoleI, SalesOrderStatusI } from 'interfaces'
import { specialDeliveryOptions } from "../constants"

import { EditObservationsModal } from '.'

import routes from 'app/routing/routes'

const AddresseeTab = ({ editing, handleGetSalesOrder }) => {
  const { currentUser } = useAuth()

  const { values, setFieldValue, errors, touched } = useFormikContext()
  const intl = useIntl()

  const [addressModal, setAddressModal] = useState(false)
  const [patientAddressInfo, setPatientAddressInfo] = useState(null)
  const [patientModal, setPatientModal] = useState(false)

  const [editObservations, setEditObservations] = useState(false)
  const [editDispatchObservations, setEditDispatchObservations] = useState(false)

  const { handleGetShipmentAddresses } = useShipmentAddresses()

  const { handleGetCustomerSupplierPatients, handleGetCustomerSupplierShipmentAddresses, handleAddCustomerSupplierPatient } = useCustomerSuppliers()

  const [patients, setPatients] = useState([])
  const [ourAddresses, setOurAddresses] = useState([])
  const [customerAddressesTrace, setCustomerAddressesTrace] = useState([])
  const [customerAddresses, setCustomerAddresses] = useState([])
  const [patientAddresses, setPatientAddresses] = useState([])

  const [optionsAddressShip, setOptionsAddressShip] = useState([])
  const [optionsAddressTraza, setOptionsAddressTraza] = useState([])

  const { handleCreatePatient, handleGetPatientAddresses, handleAddPatientAddress, handleUpdatePatientAddress } = usePatients()

  const [homeDeliveryOptions, setHomeDeliveryOptions] = useState(specialDeliveryOptions)

  /* Modal de cambio de domicilios */
  const patientAddressSubmit = async (data) => {
    try {
      let response

      if (patientAddressInfo?.id) {
        response = await handleUpdatePatientAddress(Number(values.patient_id), Number(values.address_id_ship), data)

        toast.success("Registro actualizado correctamente", { theme:"colored" })
        return
      }

      response = await handleAddPatientAddress(Number(values.patient_id), data, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      loadPatientAddresses()
      handleGetSalesOrder()
      setAddressModal(false)
    }
  }

  const loadAddressOptions = (customerAddrTrace, customerAddr, ourAddr, patientAddr) => {
    let _addresses = [];

    if (values.home_delivery_option_id === HomeDeliveryOptionI.NO) {
      setOptionsAddressTraza(customerAddrTrace?.filter(a => a.has_traceability == 1))
      _addresses = customerAddrTrace
    } else if (values.home_delivery_option_id === HomeDeliveryOptionI.CLIENTE) {
      setOptionsAddressTraza(ourAddr?.filter(a => a.has_traceability == 1))
      _addresses = customerAddr?.filter(a => (a.gln == null || a.gln == ''))
    } else if (values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {
      if (values.to_patient) {
        setOptionsAddressTraza(ourAddr?.filter(a => a.has_traceability == 1))
        _addresses = patientAddr?.filter(a => (a.gln == null || a.gln == ''))
        setPatientAddresses(_addresses)
      }
    } else if (values.home_delivery_option_id === HomeDeliveryOptionI.FARMACIA) {
      setOptionsAddressTraza(ourAddr?.filter(a => a.has_traceability == 1))
      _addresses = ourAddr?.filter(a => a.has_traceability == 1)
    }

    if (_addresses && _addresses.length) {
      const _options = _addresses.map(address => {
        const { name, address: addr } = address;

        return {
          value: address.id,
          label: `${name} - ${addr?.domicilio?.trim()}, ${addr.localidad_completa?.trim()}`
        };
      });

      setOptionsAddressShip(_options || [])
    }
  }

  const loadPatients = async() => {
    try {
      const params = {
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierPatients(Number(values.customer_supplier_id), params)
      const _patients = response.data.result.map(p => ({
        ...p,
        display_name : `[${p.id}] ${p.documentation ? `${p.documentation} - ${p.first_name} ${p.last_name}` : `${p.first_name} ${p.last_name}`}${p?.affiliate_number ? ` | Nro. afiliado: ${p.affiliate_number}` : ''}`
      }))

      setPatients(_patients)

    } catch (error) {
      toast.error(error.message)
    }
  }

  const loadAddresses = async () => {
    try {
      let _pac = {}
      let ourParams = {
        has_traceability: 1,
        is_ours: 1,
        is_enabled: 1,
      }
      const _our = await handleGetShipmentAddresses('', null, ourParams)
      setOurAddresses(_our.data.result);

      let cssaParams = {
        has_traceability: 1,
        is_enabled: 1,
      }
      const _cust_traceability = await handleGetCustomerSupplierShipmentAddresses(Number(values.customer_supplier_id), cssaParams, '')
      setCustomerAddressesTrace(_cust_traceability.data.result);

      cssaParams = {
        has_traceability: 0,
        is_enabled: 1,
      }
      const _cust = await handleGetCustomerSupplierShipmentAddresses(Number(values.customer_supplier_id), cssaParams, '')
      setCustomerAddresses(_cust.data.result);

      if (values.patient_id) {
        let pacParams = {
          is_enabled: 1,
          has_traceability: 0,
        }
        _pac = await handleGetPatientAddresses(Number(values.patient_id), pacParams)
        setPatientAddresses(_pac.data.result)
      }

      loadAddressOptions(_cust_traceability.data.result, _cust.data.result, _our.data.result, _pac.data?.result)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const loadPatientAddresses = async () => {
    const response = await handleGetPatientAddresses(Number(values.patient_id))
    setPatientAddresses(response.data.result)

    if (values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {

      if (values.to_patient) {
        const _options = response.data?.result?.filter(a => (a.gln == null || a.gln == '')).map(address => ({
          value: address.id,
          label: `${address.name} - ${address.address?.domicilio?.trim()}, ${address.address?.localidad_completa?.trim()}`
        }));

        setOptionsAddressShip(_options || [])
      }
    }
  }

  const changePatient = (selected) => {
    // console.log(selected)
    if (!selected || selected.length === 0) {
      setFieldValue('patient_id', '')
      setFieldValue('patient_name', '')
      setFieldValue('address_id_ship', '')
      setOptionsAddressShip([])
    } else {
      const value = selected[0]
      // const _name = `#${values.patient_id} | ${values?.patient_documentation ? `${values.patient_documentation} - ${values.patient_name}` : `${values.patient_name}`}`;
      setFieldValue('patient_id', value.id)
      setFieldValue('patient_name', value.display_name)
    }
  }

  const createPatient = async (patient) => {
    try {
      // Se crea el paciente
      const responsePatient = await handleCreatePatient(patient)
      toast.success(responsePatient.message)

      // Se vincula el paciente creado con el cliente / proveedor
      const responseCustomer = await handleAddCustomerSupplierPatient(values.customer_supplier_id, responsePatient.id)
      toast.success(responseCustomer.message)

      setPatientModal(false)
    } catch (error) {
      toast.error(error.message)
    } finally {
      loadPatients()
    }
  }

  useEffect(() => {
    if (editing) {
      if (values.home_delivery_option_id !== HomeDeliveryOptionI.NO) { // si es NO, ya hay un effect que lo actualiza
        // setFieldValue('address_id_ship', '')
      }
      if (values.to_patient == true) {
        setHomeDeliveryOptions(specialDeliveryOptions)
      } else {
        setFieldValue('patient_id', '')
        setHomeDeliveryOptions(specialDeliveryOptions?.filter(o => o.label !== 'A paciente'))

        if (values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {
          setFieldValue('home_delivery_option_id', HomeDeliveryOptionI.NO)
        }
      }
    }
  }, [values.to_patient])

  useEffect(() => {
    if (editing) {
      if (values.to_patient == true) {
        setHomeDeliveryOptions(specialDeliveryOptions)
      } else {
        setHomeDeliveryOptions(specialDeliveryOptions?.filter(o => o.label !== 'A paciente'))
        if(values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE){
          setFieldValue('home_delivery_option_id', HomeDeliveryOptionI.NO)
        }
      }
    }
  }, [editing])

  useEffect(() => {
    if (editing) {
      // setFieldValue('address_id_traza', '')
      // setFieldValue('address_id_ship', '')

      loadAddressOptions(customerAddressesTrace, customerAddresses, ourAddresses, patientAddresses)
    }
  }, [values.home_delivery_option_id])


  useEffect(() => {
    if (editing) {
      if (values.home_delivery_option_id === HomeDeliveryOptionI.NO || values.home_delivery_option_id === HomeDeliveryOptionI.FARMACIA) {
        setFieldValue('address_id_ship', values.address_id_traza)
      }
    }
  }, [values.address_id_traza])


  useEffect(() => {
    // console.log(patients)
    // console.log(values.patient_id)
    if (values.patient_id) {
      loadPatientAddresses()
      // const _name = `#${values.patient_id} | ${values?.patient_documentation ? `${values.patient_documentation} - ${values.patient_name}` : `${values.patient_name}`}`;
      // setFieldValue('patient_name', _name)
    }

  }, [values.patient_id, values.home_delivery_option_id, values.to_patient])

  useEffect(() => {
    loadAddresses()
    loadPatients()
  }, [])

  /* TODO: EL CAMPO DIRECCION ES OBLIGATORIO */

  return (
    <>
      <PatientModal
        show={patientModal}
        onHide={() => {
          // setPatientInfo(null)
          setPatientModal(false)
        }}
        // values={patientInfo}
        onSave={values => createPatient(values)}
      />

      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='dispatch_date' name='dispatch_date' type='date' label={'Fecha de entrega'} disabled={!editing} />
        </div>

        <div className='col-5'></div>

        {Boolean(values.to_patient) &&
          <div className='col-4 text-end'>
            <Button type='button' variant='primary' onClick={() => setPatientModal(true)} style={{ marginTop: '20px' }}>Crear paciente</Button>
          </div>
        }
      </div>
      <div className='row mb-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='to_patient' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE' })} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='requires_coordination' disabled={!editing} />
              <Label htmlFor='requires_coordination' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE.COORDINATION' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='to_patient' disabled={!editing}/>
              <Label htmlFor='to_patient' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE.TO_PATIENT' })} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        <div className='col-3'>
          <SelectField
            id='home_delivery_option_id'
            name='home_delivery_option_id'
            label='Entrega especial'
            options={homeDeliveryOptions}
            disabled={!editing}
          />
        </div>

        {/* Selección del paciente */}
        {values.to_patient && (
          <>
            <div className="col-4">
              <Label label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.PATIENT' })} htmlFor='patient_id' />
              <Typeahead
                id='patient_id'
                name='patient_id'
                minLength={3}
                labelKey={option => option?.display_name}
                options={patients}
                filterBy={['id', 'documentation', 'first_name', 'last_name', 'affiliate_number']}
                promptText={'Buscar paciente'}
                searchText='Buscando...'
                placeholder={'Buscar paciente'}
                emptyLabel={'No se encontró el paciente'}
                onChange={changePatient}
                onBlur={e => {
                  if (e.target.value === '') {
                    setFieldValue('patient_id', '')
                    setFieldValue('patient_name', '')
                  }
                }}
                defaultInputValue={values.patient_name}
                disabled={!editing}
                className={`${(errors.patient_id && touched.patient_id )? 'border border-danger' : ''}`}
              />
            </div>

            {values.patient_id &&
              <div className='col-2' style={{ marginTop: '29px' }}>
                <a href={`${routes.PATIENTS}/${values.patient_id}`} target="_blank" rel="noopener noreferrer">
                Ver paciente
                </a>
              </div>
            }
          </>
        )}
      </div>

      {/* Dirección traza */}
      {/* {values.home_delivery_option_id != HomeDeliveryOptionI.FARMACIA? */}
      <div className='row my-8'>
        <div className='col-12'>
          {editing
            ?
              <SelectField
                id='address_id_traza'
                name='address_id_traza'
                label={(values.home_delivery_option_id==HomeDeliveryOptionI.NO || values.home_delivery_option_id==HomeDeliveryOptionI.FARMACIA)
                  ? 'Dirección de trazabilidad / entrega'
                  : 'Dirección de trazabilidad'
                }
                options={[
                  ...optionsAddressTraza?.map(address => (
                    {
                      value: address.id,
                      label: `${address.name} - ${address.address?.domicilio}  ${address.address?.localidad_completa} ${address?.gln ? ` | GLN: ${address.gln}` : ''}`
                    }
                  ))
                ]}
                placeholder={(values.home_delivery_option_id == HomeDeliveryOptionI.NO || values.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA)
                  ? 'Seleccione una dirección de entrega'
                  : 'Seleccione una dirección de trazabilidad'
                }
                disabled={!editing}
              />
            :
              <InputBS
                id='direccion_traza'
                name='direccion_traza'
                label={(values.home_delivery_option_id == HomeDeliveryOptionI.NO || values.home_delivery_option_id==HomeDeliveryOptionI.FARMACIA)
                  ? 'Dirección de trazabilidad / entrega'
                  : 'Dirección de trazabilidad'
                }
                disabled
              />
          }
        </div>
      </div>
      {/* :
          <></>
      } */}

      {/* Modal para crear o editar direccion */}
      <ShipmentAddressModal
        show={addressModal}
        onClose={() => setAddressModal(false)}
        values={patientAddressInfo}
        onSave={patientAddressSubmit}
      />

      {/* Direccion entrega */}
      {(values.home_delivery_option_id == HomeDeliveryOptionI.CLIENTE || values.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE)
        ?
          <div className='row my-8'>
            <div className={editing ? 'col-8' : 'col-12'}>
              {editing
                ?
                  <SelectField
                    id='address_id_ship'
                    name='address_id_ship'
                    label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })}
                    placeholder='Seleccione un domicilio de entrega'
                    options={optionsAddressShip}
                    disabled={!editing}
                  />
                : <InputBS id='direccion_ship' name='direccion_ship' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })} disabled />
              }
            </div>

            {editing && values.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE
              ?
                <div className='col-sm-auto pt-6 d-flex flex-wrap'>
                  <Button
                    type='button'
                    variant="secondary"
                    onClick={() => {
                      setPatientAddressInfo(patientAddresses.find(address => address.id == values.address_id_ship))
                      setAddressModal(true)
                    }}
                    className='me-4'
                    disabled={!values.address_id_ship || values.address_id_ship == '' || values.home_delivery_option_id != HomeDeliveryOptionI.PACIENTE}
                  >
                    Editar dirección
                  </Button>

                  <Button
                    variant="primary"
                    type='button'
                    onClick={() => {
                      setPatientAddressInfo(null)
                      setAddressModal(true)
                    }}
                  >
                    Crear dirección
                  </Button>
                </div>
              :
                <></>
            }
          </div>
        : <></>
      }

      {/* Observaciones */}
      <div className='row my-8'>
        <div className='col-12'>
          <Label htmlFor='observations' label='Observaciones del pedido (se imprime en remito y factura)' />
          {
            !editObservations &&
            (
              values.sales_order_status_id == SalesOrderStatusI.BORRADOR ||
              values.sales_order_status_id == SalesOrderStatusI.CANCELADO ||
              values.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA ||
              values.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
            ) && currentUser.roles.some(r => (r.id == RoleI.ADMIN || r.id == RoleI.CONTROL_RENTAS || r.id == RoleI.CONTROL_CRED)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => {
                  setEditObservations(editObservations ? false : true)
                }}
              />
          }

          <Textarea id='observations' name='observations' disabled={!editing} />
          {/* <TextareaBS id='observations' name='observations' label="Observaciones del pedido (se imprime en remito y factura)" disabled={!editing} /> */}
        </div>
      </div>

      <EditObservationsModal show={editObservations} onClose={() => setEditObservations(false)} handleGetSalesOrder={handleGetSalesOrder} />

      <div className='row mb-8'>
        <div className='col-12'>
        <Label htmlFor='dispatch_observations' label='Observaciones despacho (se imprime en guía de despacho)' />
          {
            !editDispatchObservations &&
            (
              values.sales_order_status_id == SalesOrderStatusI.BORRADOR ||
              values.sales_order_status_id == SalesOrderStatusI.CANCELADO ||
              values.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA ||
              values.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
            ) && currentUser.roles.some(r => (r.id == RoleI.ADMIN || r.id == RoleI.CONTROL_RENTAS || r.id == RoleI.CONTROL_CRED)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => {
                  setEditDispatchObservations(editDispatchObservations ? false : true)
                }}
              />
          }

          <Textarea id='dispatch_observations' name='dispatch_observations' disabled={!editing} />
          {/* <TextareaBS id='dispatch_observations' name='dispatch_observations' label="Observaciones despacho (se imprime en guía de despacho)" disabled={!editing} /> */}
        </div>
      </div>

      <EditObservationsModal dispatch={true} show={editDispatchObservations} onClose={() => setEditDispatchObservations(false)} handleGetSalesOrder={handleGetSalesOrder} />
    </>
  )
}

export default AddresseeTab