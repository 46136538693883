import { useState, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useFormikContext, Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useProvinces } from 'hooks'

import { InputBS, SelectField } from 'components'

import { multilateralAgreement, multilateralAgreementSchema } from '../constants'
import { formatInputDate } from 'utilities'

const MultilateralAgreementModal = ({ show, onHide, values = multilateralAgreement, onSave }) => {
  const { values: fcValues } = useFormikContext()

  const [initialValues, setInitialValues] = useState(multilateralAgreement)

  const { handleGetProvinces } = useProvinces()
  const [provinces, setProvinces] = useState([])

  const getProvinces = useCallback(async () => {
    try {
      const response = await handleGetProvinces()

      if (!values?.id && fcValues.multilateral_agreement.length > 0) {
        setProvinces((response.data.result).filter(prov => !fcValues.multilateral_agreement.some(ma => ma.province_id === prov.id)))
        return
      }

      setProvinces(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetProvinces, values?.id, fcValues.multilateral_agreement])

  useEffect(() => {
    getProvinces()
  }, [getProvinces, fcValues.multilateral_agreement])

  useEffect(() => {
    setInitialValues({
      ...multilateralAgreement,
      ...values,
      period_from: formatInputDate(values?.period_from),
      period_to: formatInputDate(values?.period_to),
      date_from: formatInputDate(values?.date_from),
      date_to: values?.date_to ? formatInputDate(values?.date_to) : '',
    })
  }, [values, setInitialValues])

  if (!show) return null

  return (
    <Modal size='xl' show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>{`${values?.id ? 'Editar' : 'Agregar'} convenio multilateral`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={multilateralAgreementSchema}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-4'>
                    <SelectField
                      id='province_id'
                      name='province_id'
                      label='Provincia'
                      options={[...provinces.map(item => ({ value: item.id, label: item.label }))]}
                      disabled={values?.id}
                    />
                  </div>
                </div>

                {values?.province_id &&
                  <div className='row my-8'>
                    <div className='col-2'>
                      <InputBS id='date_from' name='date_from' type='date' label='Fecha inicio' />
                    </div>

                    <div className='col-2'>
                      <InputBS id='date_to' name='date_to' type='date' label='Fecha cese' />
                    </div>

                    <div className='col-2'>
                      <InputBS id='period' name='period' type='text' label='Período' placeholder='Ej. 2022' />
                    </div>

                    <div className='col-2'>
                      <InputBS id='coefficient' name='coefficient' label='Coeficiente' />
                    </div>
                  </div>
                }

                <div className='separator my-8'></div>

                <div className='d-flex align-items-ce<nter justify-content-end'>
                  <Button variant='secondary' type='button' className='me-3' onClick={onHide}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='submit'><i className='bi bi-save me-2' />Guardar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default MultilateralAgreementModal