import React from 'react'
import { useNavigate } from 'react-router-dom'

import routes from 'app/routing/routes'
import { Button } from 'react-bootstrap'

const Actions = ({ editing, handleEdit, submitRef, cancelRef }) => {
  const navigate = useNavigate()

  return (
    <div className='d-flex align-items-center justify-content-end'>
      {!editing
        ?
          <>
            <Button variant='secondary' type='button' onClick={() => navigate(-1)}><i className="ki-duotone ki-left" />Volver</Button>
            {/* <Button variant='primary' onClick={() => handleEdit(true)}>Editar</Button> */}
          </>
      :
        <>
          <Button variant='secondary' type='button' onClick={() => cancelRef.current.click()}><i className="bi bi-slash-circle" />Cancelar</Button>
          {/* <Button variant='primary' type='button onClick={() => submitRef.current.click()}><i className='bi bi-save me-2' />Guardar</Button> */}
        </>
    }
  </div>
)
}

export default Actions