import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { FormTable } from 'components'
import moment from 'moment'
import { useWaybill } from 'hooks'

const AddToDispatchModal = ({ show, onHide, shipmentOrder, pendingDispatches, getShipmentOrders}) => {
  const [items, setItems] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const {handleAddToDispatch} = useWaybill()


  const handleCheck = (index, row) => {
   
    if (selectedIndex?.index == index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex({ index, row });
    }

  }

  const handleSubmit = async () => {
    
    if(selectedIndex && selectedIndex.row){
        const data = {
          dispatch_order_id: selectedIndex.row.id,
          order_delivery_id: shipmentOrder.id
        }
    
        try {
          const response = await handleAddToDispatch(data)
          toast.success("Envío agregado al despacho correctamente", {theme: "colored"})
        } catch (error) {
          toast.error(error.message)
        } finally {
          getShipmentOrders()
          onHide()
        }
    }
  }

  useEffect(() => {
    if(show && pendingDispatches && shipmentOrder){
      const _availableDispatches = pendingDispatches.filter(p=>p.shipping_carrier_name=shipmentOrder.shipping_carrier_name)
      setItems(_availableDispatches.map(item=>({...item, checked:false})))
      setSelectedIndex(null)
    }    
  }, [show, shipmentOrder, pendingDispatches])

  if (!show) return null

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Agregar órden a despacho existente</Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <Formik
          initialValues={{
            shipment_orders: items,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            return (
              <Form>
                <div className="row">
                  <div className="col">

                    {/* {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>} */}

                    <FormTable
                      columns={[
                        { header: '', styles: 'text-center' },
                        { header: 'Transportista' },
                        { header: 'Órdenes de envío' },
                        { header: 'Creado' },
                      ]}
                      responsive={false}
                    >
                      {values.shipment_orders.map((item, index) => (
                        <tr key={index}>
                          <td className='align-center'>
                            <input
                              name="selected"
                              type="checkbox"
                              onChange={() => handleCheck(index, item)}
                            />
                          </td>
                          <td>{item.shipping_carrier_name}</td>
                          <td>{item.out_order_count}</td>
                          <td>{item.created_at?moment(item.created_at).format("DD/MM/YYYY HH:mm"):''}</td>
                        </tr>
                      ))}
                    </FormTable>
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex justify-content-end'>
                  <Button variant='secondary' type='button' className='me-3' onClick={() => onHide()}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='button' onClick={handleSubmit} disabled={!selectedIndex}><i className='bi bi-save me-2' />Agregar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddToDispatchModal