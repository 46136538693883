import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment'
import {helix} from 'ldrs'
import { useWaves } from 'hooks'

import { InputBS } from 'components'

const CreateWaveModal = ({ show, onHide, selectedRows, handleOutOrders, handleGetOutOrders, pendingOrders }) => {
  const date = new Date().toISOString()
  const { handleCreateWave, loading } = useWaves()
  const [note, setNote] = useState('')

  let response = null;

  const handleSubmit = async (values) => {

      let data = []
      if(selectedRows.length>0){
        data = {...values, out_orders:selectedRows.map(r=>({id:r.id, status_id:r.status_id, checked:true}))}
      }
      else{
        data =  {...values, out_orders:pendingOrders.map(r=>({id:r.id, status_id:r.out_order_status_id, checked:true}))}
      }

      try {
        response = await handleCreateWave(data)
        toast.success("Ola creada correctamente", {theme: "colored"})
        handleOutOrders([])
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetOutOrders()
        onHide(response)
      }
  }

  useEffect(() => {
    // setItems(selectedRows)
    if(selectedRows?.length>0){
      if(selectedRows.length==1){
        setNote("1 órden")
      }
      else{
        setNote(selectedRows.length + ' órdenes')
      }
    }
    else if(pendingOrders?.length>0){
      if(pendingOrders.length==1){
        setNote("1 órden")
      }
      else{
        setNote(pendingOrders.length + ' órdenes')
      }
    }
  }, [show])

  useEffect(()=>{
    helix.register()
  }, [])

  if (!show) return null

  return (
    <Modal size="lg" centered show={show} onHide={onHide} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Crear Ola</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            name: `Ola - ${moment(date.split('T')[0]).format('DD/MM/YYYY')} // ${date.split('T')[1].split('.')[0]}`,
            // out_orders: items,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {

            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col'>
                    <InputBS
                      id='name'
                      name='name'
                      label='Nombre de la ola'
                      placeholder='Ingrese el nombre de la ola'
                    />
                  </div>
                </div>

                { loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"30px"}}></l-helix>}
              
                <div className="row">
                  <div className="col">
                    <label className='form-label'>Se van a agregar <b> {note} </b> a la ola</label>
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex align-items-center justify-content-end'>
                  <Button
                    variant='secondary'
                    type='button'
                    className='me-3'
                    onClick={() => {
                      // setFieldValue('out_orders', selectedRows)
                      onHide()
                    }}
                  >
                    <i className="bi bi-slash-circle" />
                    Cancelar
                  </Button>

                  <Button variant='primary' type='submit' onClick={handleSubmit} disabled={loading}><i className='bi bi-save me-2' />Crear</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CreateWaveModal