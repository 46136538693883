const routes = {
  // DASHBOARD
  DASHBOARD: '/dashboard',
  // VENTAS
  BUDGETS: '/ventas/presupuestos',
  BUDGETS_NEW: '/ventas/presupuestos/crear',
  SALES_ORDERS: '/ventas/pedidos/pedido-venta',
  SALES_ORDERS_NEW: '/ventas/pedidos/pedido-venta/crear',
  OUT_REQUEST: '/ventas/pedidos/pedido-egreso',
  OUT_REQUEST_NEW: '/ventas/pedidos/pedido-egreso/crear',
  PROFITABILITY_AUTHORIZATIONS: '/ventas/autorizaciones-rentabilidad',
  CREDIT_AUTHORIZATIONS: '/ventas/autorizaciones-credito',
  LIST_COSTS: '/ventas/costos-lista',
  // COMPRAS
  PURCHASE_REQUESTS: '/compras/pedidos',
  PURCHASE_REQUEST_NEW: '/compras/pedidos/pedido-compra/crear',
  INCOME_REQUEST_NEW: '/compras/pedidos/pedido-ingreso/crear',
  PURCHASE_REQUEST_DETAIL: '/compras/pedidos/pedido-compra',
  INCOME_REQUEST_DETAIL: '/compras/pedidos/pedido-ingreso',
  AUTHORIZATIONS: '/compras/autorizaciones',
  COSTS: '/compras/costos',
  COST_CUSTOMER: '/compras/costos/cliente',
  COST_PRODUCT: '/compras/costos/producto',
  COSTS_NEW: '/compras/costos/crear',
  PURCHASE_STOCK_MANAGEMENT: '/compras/gestion',
  // LOGÍSTICA
  LOGISTIC_INCOME: '/logistica/ingreso',
  LOGISTIC_INCOME_NEW: '/logistica/ingreso/crear',
  LOGISTICS_ACTIVATION: '/logistica/activaciones',
  OUT_ORDERS: '/logistica/egreso',
  COORDINATION: '/logistica/coordinacion',
  LOGISTICS_REVISION: '/logistica/revisiones',
  WAVES: '/logistica/olas',
  LOGISTICS_SHIPMENT_ORDERS: '/logistica/ordenes-envio',
  LOGISTICS_DISPATCH_ORDERS: '/logistica/ordenes-despacho',
  // INVENTARIO
  ARTICLES: '/inventario/articulos',
  ARTICLES_NEW: '/inventario/articulos/crear',
  PRODUCTS: '/inventario/productos',
  PRODUCTS_NEW: '/inventario/productos/crear',
  WAREHOUSE: '/inventario/deposito',
  INVENTORY_STOCK: '/inventario/stock',
  INVENTORY_MOVEMENTS: '/inventario/movimientos',
  // RELACIONES COMERCIALES
  CUSTOMER_SUPPLIERS: '/relaciones-comerciales/clientes-proveedores',
  CUSTOMER_SUPPLIERS_NEW: '/relaciones-comerciales/clientes-proveedores/crear',
  PATIENTS: '/relaciones-comerciales/pacientes',
  PATIENTS_NEW: '/relaciones-comerciales/pacientes/crear',
  SHIPMENT_ADDRESSES: '/relaciones-comerciales/direcciones-entrega',
  SHIPMENT_ADDRESSES_NEW: '/relaciones-comerciales/direcciones-entrega/crear',
  // -- MAESTROS
  // Obras sociales
  HEALTH_INSURANCES: '/maestros/obras-sociales',
  HEALTH_INSURANCES_NEW: '/maestros/obras-sociales/crear',
  // Vendedores
  SELLERS: '/maestros/vendedores',
  SELLERS_NEW: '/maestros/vendedores/crear',
  // Transportistas
  CARRIERS: '/maestros/transportistas',
  CARRIERS_NEW: '/maestros/transportistas/crear',
  // Métodos de pago
  PAYMENT_METHODS: '/maestros/metodos-pago',
  PAYMENT_METHODS_NEW: '/maestros/metodos-pago/crear',
  // Condiciones de pago
  PAYMENT_CONDITIONS: '/maestros/condiciones-pago',
  PAYMENT_CONDITIONS_NEW: '/maestros/condiciones-pago/crear',
  // Tipos de documentación (DNI, CUIT, Otro)
  DOCUMENTATION_TYPES: '/maestros/tipos-documentación',
  DOCUMENTATION_TYPES_NEW: '/maestros/tipos-documentación/crear',
  // Tipos de documento
  DOCUMENT_TYPES: '/maestros/tipos-documento',
  DOCUMENT_TYPES_NEW: '/maestros/tipos-documento/crear',
  // Tipos (Obra social, Droguería, Sector público, Consumidor final, Otros)
  TYPES: '/maestros/tipos',
  TYPES_NEW: '/maestros/tipos/crear',
  // Tipos de agentes
  AGENT_TYPES: '/maestros/tipos-agente',

  // -- REPORTES
  REPORTE_INGRESOS: '/reportes/ingresos',
  REPORTE_COMPROBANTES: '/reportes/comprobantes',
  REPORTE_VENTAS: '/reportes/ventas',
  REPORTE_REMITOS: '/reportes/remitos',
  REPORTE_INVENTARIO: '/reportes/inventario',
  REPORTE_ANALISIS_STOCK: '/reportes/analisis-stock',

  // -- ADMINISTRACION
  // Comprobantes
  ADMINISTRATIVE_VOUCHERS: '/administracion/comprobantes',
  ADMINISTRATIVE_VOUCHER_NEW: '/administracion/comprobantes/crear',
  ADMINISTRATIVE_VOUCHER: '/administracion/comprobante/',
  ADMINISTRATIVE_PENDING_INVOICES: '/administracion/facturacion',
  ADMINISTRATIVE_PENDING_INVOICES_NEW: '/administracion/facturacion/crear',

  //Integraciones
  INTEGRATION_ANMAT_ERRORS: '/integraciones/ingresos-con-errores',
  INTEGRATION_VERIFARMA: '/integraciones/verifarma',
}

export default routes