import { useState, useCallback } from 'react';

import { Logistic, Depots } from 'services';



const initialQueryString = {
  filter:"",
  metadata:{
    pageNumber:"1",
    pageSize:"10"
  }
}

const useLogisticStock = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);

  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);
  
  const handleGetStockList = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);
      // const {filter, queryMD} = params;
      
      // if(filter!=null && filter!=''){
      //   query = filter + '&' + new URLSearchParams(queryMD).toString();
      // }
      // else{
      //   query = "?" + new URLSearchParams(queryMD).toString();
      // }

      // const response = await Logistic.getStockList(query);
      const response = await Logistic.getStockList(params);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetStockDetail = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Logistic.getStockDetail(params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetStockReserved = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Logistic.getStockReserved(params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetInternalMovements = useCallback(async (params) => {
    try {
      let query = "";
      const {filter, queryMD} = params;      
      if(filter!=null && filter!=''){
        query = filter + '&' + new URLSearchParams(queryMD).toString();
      }
      else{
        query = "?" + new URLSearchParams(queryMD).toString();
      }
      setLoading(true);
      const response = await Depots.getInternalMovements(query);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetInternalMovement = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Depots.getInternalMovement(id);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateLotNumberAndExpirationFromStock = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Depots.updateLotNumberAndExpirationFromStock(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])


  return {
    loading,
    queryString,
    setQueryString,
    handleGetStockList,
    handleGetStockDetail,
    handleGetInternalMovements,
    handleGetInternalMovement,
    handleGetStockReserved,
    handleUpdateLotNumberAndExpirationFromStock
  }
}

export default useLogisticStock;