import * as Yup from 'yup'

const initialValues = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  created_at: '',
  updated_at: '',
  is_enabled: false,
}

const schema = Yup.object().shape({
  id: Yup.number().required('Campo obligatorio'),
  first_name: Yup.string().required('* Ingrese un nombre'),
  last_name: Yup.string().required('* Ingrese un apellido'),
  email: Yup.string().email().required('* Ingrese un email'),
})

export {
  initialValues,
  schema
}