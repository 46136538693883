import { useState, useEffect} from 'react'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import axios from 'axios';

import { useAuth } from 'app/modules/auth'
import { useReports } from 'hooks'

import { Input, SelectField, SwitchField } from 'components'

import { labelColor, statusDisplay, labelColorSinComprobantes } from './constants'

const initialValues = {
  id: '',
  id_text: '',
  orden_compra: '',
  from_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  to_date: moment().format('YYYY-MM-DD'),
  text: '',
  paciente: '',
  paciente_documento: '',
  comprobante: '',
  sin_comprobantes: 0,
  verifarma: '',
  direccion: '',
  localidad: '' ,
  provincia: '',
}

const Filter = ({ setData, query, setQuery, setTotalCount, exportData, exportDataDetail, handleExportVerifarma, sinComprobantes = false, handleSinComprobantes,data}) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetReporteRemitos } = useReports()

  const filterValues = (values) => {
    let params = {
      ...query,
      ...values,
      id: values?.id || undefined,
      id_text: values?.id_text || undefined,
      orden_compra: values?.orden_compra || undefined,
      from_date: values?.from_date || undefined,
      to_date: values?.to_date || undefined,
      text: values?.text || undefined,
      paciente: values?.paciente || undefined,
      paciente_documento: values?.paciente_documento || undefined,
      comprobante: values?.comprobante || undefined,
      sin_comprobantes: values?.sin_comprobantes || undefined,
      verifarma: values?.verifarma || undefined,
      direccion: values?.direccion || undefined,
      localidad: values?.localidad || undefined,
      provincia: values?.provincia || undefined,
    }

    if (values?.sin_comprobantes) {
      handleSinComprobantes(true)
    } else {
      handleSinComprobantes(false)
    }

    return params
  }

  const handleSubmit = async (values) => {
    const params = {
      ...filterValues(values),
      pageNumber: 1,
      pageSize: 10,
    }

    try {
      const response = await handleGetReporteRemitos(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color_comprobante: !sinComprobantes ? labelColor(d.FacturaEstado) : '',
        status_display_comprobante: !sinComprobantes ? statusDisplay(d.FacturaEstado) : '',
        label_color_guia: labelColorSinComprobantes(d.GuiaEstadoId),
        status_display_guia: d.GuiaEstado,
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        reporte_remitos: { ...params }
      }))

      setQuery({ ...params, pageNumber: 1, pageSize: 10 })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  
  const txtRemitostxtOsprera = async () => {
    try {
      const remitoIds = data.map(row => row.RemitoId);
      if (remitoIds && remitoIds.length > 0) {
        console.log("IDs de remitos:", remitoIds);
        await sendRemitostxtOsprera(remitoIds);
        toast.success('¡Archivo TXT de remitos generado exitosamente!');
      } else {
        toast.error('No se encontraron IDs de facturas.');
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  

  const sendRemitostxtOsprera = async (ids) => {
    try {
      //cambiar por varable de entorno
      let baseUrl = 'https://riga-farma.drofar.com.ar/docu/print/txtOsprera';
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ remitos_ids: ids }),
      });
  
      if (!response.ok) {
        throw new Error('Error al obtener el archivo TXT');
      }
  
      // Lee el contenido como texto (Base64)
      const base64String = await response.text();
  
      // Decodifica la cadena Base64
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
  
      // Crea un Blob con los datos decodificados
      const blob = new Blob([byteArray], { type: 'text/plain;charset=utf-8' });
  
      // Descarga el archivo
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'remitos.txt';
      link.click();
  
    } catch (error) {
      toast.error(error.message);
    }
  }



  
  const csvRemitosCsvStaCruz = async (values) => {
    try {
      const remitoIds = data.map(row => row.RemitoId);
      if (!remitoIds || remitoIds.length === 0) {
        toast.error('No se encontraron IDs de remitos.');
        return;
      }
  
      //cambiar por varable de entorno
      let baseUrl = 'https://riga-farma.drofar.com.ar/docu/print/csvStaCruz';
      console.log(baseUrl);
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ remitos_ids: remitoIds }),
      });
  
      if (!response.ok) {
        throw new Error('Error al generar el archivo CSV de remitos');
      }
  
      // Lee el contenido como Blob
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'remitos.csv';
      document.body.appendChild(link);
      link.click();
      link.remove();
  
      toast.success('¡Archivo CSV de remitos generado exitosamente!');
    } catch (error) {
      toast.error(error.message);
    }
  }
  
  
  


  useEffect(() => {
    if (currentUser?.user_id) {
      const { reporte_remitos } = currentUser

      setInitialFormikValues((prevValues) => ({
        ...prevValues,
        id: reporte_remitos?.id || '',
        id_text: reporte_remitos?.id_text || '',
        orden_compra: reporte_remitos?.orden_compra || '',
        from_date: reporte_remitos?.from_date || '',
        to_date: reporte_remitos?.to_date || '',
        text: reporte_remitos?.text || '',
        paciente: reporte_remitos?.paciente || '',
        paciente_documento: reporte_remitos?.paciente_documento || '',
        comprobante: reporte_remitos?.comprobante || '',
        sin_comprobantes: reporte_remitos?.sin_comprobantes ? reporte_remitos.sin_comprobantes : sinComprobantes ? 1 : 0,
        verifarma: reporte_remitos?.verifarma || '',
        direccion: reporte_remitos?.direccion || '',
        localidad: reporte_remitos?.localidad || '',
        provincia: reporte_remitos?.provincia || '',
      }))
    }
  }, [sinComprobantes, currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => {
        return (
          <Form>
            <div className='row'>
              <div className='col-2'>
                <Input
                  id='id'
                  name='id'
                  disabled={loading}
                />
                <p className='text-muted'>Nro.</p>
              </div>

              <div className='col-3'>
                <Input
                  id='id_text'
                  name='id_text'
                  disabled={loading}
                />
                <p className='text-muted'>ID Pedido / ID O. Egreso / ID O. Envío</p>
              </div>

              <div className='col-3'>
                <Input
                  id='orden_compra'
                  name='orden_compra'
                  disabled={loading}
                />
                <p className='text-muted'>Orden de compra</p>
              </div>

              <div className='col-2'>
                <Input
                  id='from_date'
                  name='from_date'
                  type='date'
                  disabled={loading}
                />
                <p className='text-muted'>Desde F. remito</p>
              </div>

              <div className='col-2'>
                <Input id='to_date' name='to_date' type='date' disabled={loading} />
                <p className='text-muted'>Hasta F. remito</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <Input id='text' name='text' disabled={loading} />
                <p className='text-muted'>ID / Razón social</p>
              </div>

              <div className='col-3'>
                <Input id='paciente' name='paciente' disabled={loading} />
                <p className='text-muted'>ID / Paciente</p>
              </div>

              <div className='col-3'>
                <Input id='paciente_documento' name='paciente_documento' disabled={loading} />
                <p className='text-muted'>Documento / Nro. afiliado</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <Input id='comprobante' name='comprobante' disabled={loading} />
                <p className='text-muted'>Remito{sinComprobantes ? '' : ' / Comprobante'}</p>
              </div>

              <div className='col-4'>
                <div className="d-flex mt-3">
                  <SwitchField
                    id='sin_comprobantes'
                    name='sin_comprobantes'
                    disabled={loading}
                  />
                  <span style={{ fontSize: '1.1rem' }}>Sin comprobantes</span>
                </div>
              </div>

              <div className='col-4'>
                <SelectField
                  id='verifarma'
                  name='verifarma'
                  options={[
                    { value: '' , label: 'Todos' },
                    { value: '1', label: 'Sí' },
                    { value: '0', label: 'No' },
                  ]}
                  marginTop={'-20px'}
                  placeholder='Seleccionar'
                  disabled={loading}
                />
                <p className='text-muted'>Verifarma</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <Input id='direccion' name='direccion' disabled={loading} />
                <p className='text-muted'>Nombre / Domicilio / GLN</p>
              </div>

              <div className='col-4'>
                <Input id='localidad' name='localidad' disabled={loading} />
                <p className='text-muted'>Localidad</p>
              </div>

              <div className='col-4'>
                <Input id='provincia' name='provincia' disabled={loading} />
                <p className='text-muted'>Provincia</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-end'>
                <Dropdown className='d-inline me-3' disabled={loading}>
                  <Dropdown.Toggle id="dropdown-remitos" variant="secondary" disabled={loading}>
                    <i className="bi bi-download pe-3" />
                    Exportar
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: "200px" }}>
                    <Dropdown.Item onClick={() => exportData(filterValues(values))} disabled={loading}>Excel</Dropdown.Item>
                    {sinComprobantes && <Dropdown.Item onClick={() => exportDataDetail(filterValues(values))} disabled={loading}>Excel c/ Detalle</Dropdown.Item>}
                    <Dropdown.Item onClick={() => handleExportVerifarma(filterValues(values))} disabled={loading}>Verifarma</Dropdown.Item>
                    <Dropdown.Item onClick={() => txtRemitostxtOsprera(values)} disabled={false}>Txt Osprera</Dropdown.Item>
                    <Dropdown.Item onClick={() => csvRemitosCsvStaCruz(values)} disabled={false}>Csv Sta-Cruz</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  type='button'
                  variant="secondary"
                  onClick={() => {
                    resetForm({ values: initialValues })
                    submitForm()
                  }}
                  className="me-3"
                  disabled={loading}
                >
                  <i className="bi bi-zoom-out mb-1" />
                  Limpiar
                </Button>

                <Button type='submit' variant='primary' disabled={loading}>
                  <i className='bi bi-search mb-1' />
                  {loading ? 'Cargando...' : 'Buscar'}
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter