
export default {
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
      border: '1px solid transparent',
    },
    '&multiLine': {
      control: {
        fontFamily: 'sans-serif',
        minHeight: 38
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent'
      },
      input: {
        padding: 9,
        border: '1px solid silver',
      }
    },
  
    '&singleLine': {
      display: 'inline-block',
      width: '100%',
  
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },
  
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        textAlign:"center"
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#dedede',
        },
      },
    },    
  }