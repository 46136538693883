import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'

import { useAuth } from 'app/modules/auth'
import { useArticles } from 'hooks'

import { Input, SelectField } from 'components'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

const product_types = [
  { value: '', label: 'Seleccionar tipo' },
  { value: 1, label: 'Medicamento' },
  { value: 2, label: 'Producto Médico' }
]

const initialValues = {
  id_filter: '',
  text: '',
  product_type_id: null,
  maker_name: '',
  family_name: '',
  gtin: '',
  sku: '',
}

const Filter = ({ setData, setTotalCount, setFilter, queryMD, setQueryMD }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetArticlesV2 } = useArticles()

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id_filter: values?.id_filter ? values.id_filter : undefined,
      text: values?.text ? values.text : undefined,
      product_type_id: values?.product_type_id ? values.product_type_id : undefined,
      maker_name: values?.maker_name ? values.maker_name : undefined,
      family_name: values?.family_name ? values.family_name : undefined,
      gtin: values?.gtin ? values.gtin : undefined,
      sku: values?.sku ? values.sku : undefined,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = filterData(values)
      const response = await handleGetArticlesV2(params)
      const data = response.data.result

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        articles: { ...params },
      }))

      setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { articles } =  currentUser

      setInitialFormikValues({
        ...initialValues,
        id_filter: articles?.id_filter || '',
        text: articles?.text || '',
        product_type_id: articles?.product_type_id || '',
        maker_name: articles?.maker_name || '',
        family_name: articles?.family_name || '',
        gtin: articles?.gtin || '',
        sku: articles?.sku || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resertForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_filter' name='id_filter' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-7'>
              <Input id='text' name='text' disabled={loading} />
              <p className='text-muted'>Nombre / Producto padre</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='product_type_id'
                name='product_type_id'
                options={product_types}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Tipo producto</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='maker_name' name='maker_name' disabled={loading} />
              <p className='text-muted'>Laboratorio</p>
            </div>

            <div className='col-4'>
              <Input id='family_name' name='family_name' disabled={loading} />
              <p className='text-muted'>Familia (Ppio. activo)</p>
            </div>

            <div className='col-4'>
              <Input id='gtin' name='gtin' disabled={loading} />
              <p className='text-muted'>GTIN</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='sku' name='sku' disabled={loading} />
              <p className='text-muted'>SKU</p>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <Button
              type='button'
              variant='secondary'
              onClick={() => {
                resertForm({ values: initialValues })
                submitForm()
              }}
              className='me-3'
              disabled={loading}
            >
              <i className="bi bi-zoom-out mb-1" />
              Limpiar
            </Button>

            <Button type='submit' variant='primary' disabled={loading}>
              <i className='bi bi-search mb-1' />
              {loading ? 'Cargando...' : 'Buscar'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}






export default Filter