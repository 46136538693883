import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomTable } from 'components'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useClickOutside } from 'hooks'
import useTransactionalDocuments from 'hooks/useTransactionalDocuments'

import { TableContainer } from 'containers'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import useGetColumns from './columns'

import routes from 'app/routing/routes'

import { TransactionalDocumentTypeI } from 'interfaces'
import Swal from 'sweetalert2'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import CreditNoteModal from '../common/CreditNoteModal'
import ResponseAfipModal from '../common/ResponseAfipModal'

const initialObQuery = {
  pageNumber: "1",
  pageSize: "10",
  tipo_comprobante_codigo_interno: '1',
  sortField:'fecha',
  sortOrder:'desc'
}

const labelColor = (status) => {
    if (!status) {
      return ""
    }

    switch (status?.toUpperCase()) {
      case "SUCCESS_WITH_OBSERVATIONS":
        return "estado-amarillo";
      case "SUCCESS":
        return "estado-verde";
      case "ERROR_NEGOCIO_AFIP":
        return "estado-rojo";
      case "DEFERRED":
        return "estado-celeste";
      case "DISCARDED":
        return "estado-naranja";
      default:
        return '';
    }
}
const statusDisplay = (status) => {
    if (!status) {
      return ""
    }
    switch (status?.toUpperCase()) {
      case "SUCCESS_WITH_OBSERVATIONS":
        return "Aprobado c/observaciones";
      case "SUCCESS":
        return "Aprobado";
      case "DEFERRED":
        return "Factura Diferida";
      case "ERROR_NEGOCIO_AFIP":
        return "Error AFIP";
      case "DISCARDED":
        return "Descartada";
      default:
        return '';
    }
}
const AdministrativeVouchers = () => {
  const [data, setData] = useState([])
  const [showNCModal, setShowNCModal] = useState(false)
  const [showDetailAfipModal, setShowDetailAfipModal] = useState(false)
  const [responseAfip, setResponseAfip] = useState("")
  const { COLUMNS } = useGetColumns()
  const [filter, setFilter] = useState("")
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetDocuments, handlePrintFactura, handleCreateCreditNote, handleEmitirDiferido, handleDescartarDiferido } = useTransactionalDocuments()

  const getDocuments = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }
      const response = await handleGetDocuments(params)

      if (response.data.result) {
        const data = response.data.result

        const dataColored = data.map(d => ({ 
            ...d, 
            label_color: labelColor(d.estado),
            status_display: statusDisplay(d.estado)
          }))
        setData(dataColored);
        setTotalCount(response.data.metadata.count);
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetDocuments, queryMD])

  const onHideNCModal = async(data)=>{
    if(!data) {
      setShowNCModal(false);
      return;
    }
    try{
      await handleCreateCreditNote(currentRow.tipo_comprobante_codigo_interno, currentRow.id, data);
      setShowNCModal(false);
      toast.success(`Se generó la nota de crédito para la factura ${currentRow.number}`, {theme: "colored"})
      getDocuments();
    }
    catch(err){

    }
    
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()
  const navigate = useNavigate();

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  const showAfipJson = (row)=>{
      if(row && row.respuesta_json){
        let respuesta = JSON.parse(row.respuesta_json)
        if(respuesta && 1){
          setResponseAfip(JSON.parse(row.respuesta_json))
          setShowDetailAfipModal(true)
        }
      }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    // actions.push({
    //   label: 'Ver',
    //   icon: 'bi bi-eye text-primary',
    //   action: () => navigate(`${routes.ADMINISTRATIVE_VOUCHER}/${currentRow?.id}/${currentRow?.tipo_comprobante_codigo_interno}`)
    // })

    if (currentRow?.tipo_comprobante_codigo_interno == TransactionalDocumentTypeI.FACTURA && 
        currentRow?.estado?.toUpperCase() !== 'ERROR_NEGOCIO_AFIP' &&
        currentRow?.estado?.toUpperCase() !== 'DEFERRED' &&
        currentRow?.comprobante_cancelacion_id == null) {
      actions.push({
        label: 'Hacer nota de crédito',
        icon: 'bi bi-arrow-counterclockwise text-primary',
        action: ()=>setShowNCModal(true) //() => makeCreditNote()
      })

    }
    if (currentRow?.estado?.toUpperCase() != 'ERROR_NEGOCIO_AFIP' &&
        currentRow?.estado?.toUpperCase() != 'DEFERRED') {
    
        actions.push({
          label: 'Descargar Comprobante',
          icon: 'bi bi-download text-primary',
          action: () => printFactura()
        })
    }
    if (currentRow?.estado?.toUpperCase() == 'DEFERRED') {
    
        actions.push({
          label: 'Facturar',
          icon: 'bi bi-receipt text-primary',
          action: () => facturarDiferido()
        })
        actions.push({
          label: 'Descartar',
          icon: 'bi bi-clipboard-x text-danger',
          action: () => descartarFacturacionDiferida()
        })
    }

    return actions
  }

  const makeCreditNote = async()=>{

      if(currentRow){
        try{
          const inputValue = '';
          const resp = await Swal.fire({
            title:"Nota de crédito",
            text:`Va a realizar una nota de crédito total sobre la factura ${currentRow.number}, confirma?`,
            icon:"warning",
            input:"textarea",
            inputValue,
            inputLabel: "Motivo",
            inputAttributes: {
                autocomplete:"off"
            },
            inputValidator: (value) => {
                if(!value || value.trim()==''){
                  return "Por favor, completar motivo de rechazo"
                }
            },
            showDenyButton:true,
            denyButtonText:"No, cancelar",
            confirmButtonText:"Si, crear NC",
            customClass: {confirmButton:'btn btn-primary', denyButton:"btn btn-secondary"},
            reverseButtons: true 
            
          })
    
          if(resp.isConfirmed){
            await handleCreateCreditNote(currentRow.tipo_comprobante_codigo_interno, currentRow.id);
            toast.success(`Se generó la nota de crédito para la factura ${currentRow.number}`, {theme: "colored"})
            getDocuments();
          }
        }
        catch(err){
          toast.error(err.message, {theme: "colored"})
        }
      }
  }

  const descartarFacturacionDiferida = async()=>{
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `Descarta la facturación diferida del pedido #${currentRow?.sales_order_id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No',
        confirmButtonText: `Si, descartar factura`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDescartarDiferido(currentRow?.id)
        toast.success(`Se descartó la facturación del pedido ${currentRow?.sales_order_id}`, {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const facturarDiferido = async ()=>{
    console.log(currentRow)
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Va a realizar la factura diferida del pedido #${currentRow?.sales_order_id}, confirma?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No',
        confirmButtonText: `Si, facturar`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleEmitirDiferido(currentRow?.id)
        toast.success(`Se generó la factura de la órden ${currentRow?.sales_order_id}`, {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  
  const printFactura = async () => {
    if (currentRow) {
      try {
        const response = await handlePrintFactura(currentRow?.tipo_comprobante_codigo_interno, currentRow?.id)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('Factura descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `factura-${currentRow.id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          toast.error('Error al descargar la factura')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Estado</div>),
      id:'estado',
      className:'col-status',
      accessor: (row) => {
          let mostrar_detalle=false;
          
          if(row && row.respuesta_json){
            
            let respuesta = JSON.parse(row.respuesta_json)

            if(respuesta!=null && row.estado!='SUCCESS' ){
                mostrar_detalle=true;
            }
          }
          return (
            <span className={ `badge ${row.label_color} w-100 justify-content-center ${mostrar_detalle?'cursor-pointer':''} `} 
              onClick={()=>showAfipJson(row)}>
              {row.status_display}
            </span>
          )
        }
      
    },
    {
      Header: '',
      id: 'comprobante_cancelacion_id',
      accessor: (row) => (
        row.comprobante_cancelacion_id 
          ? <OverlayTrigger overlay={<Tooltip>Tiene asociada la nota de crédito #{row.comprobante_cancelacion_id} </Tooltip>}>
              <i className="bi bi-code-square text-danger"  />
            </OverlayTrigger>
          : <></>
      )
    },
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        (row?.estado?.toUpperCase() !== 'ERROR_NEGOCIO_AFIP')?
          <i id='threeDots' 
            className="bi bi-three-dots-vertical" 
            style={{ fontSize: "1.5rem", cursor: "pointer" }} 
            onClick={event => handleContextMenu(event, row)} 
            ref={threeDotsRef} 
          />
          :
          <></>

      )
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD ,
    setObQuery:setQueryMD
  };

  useEffect(() => {
    getDocuments()
  }, [getDocuments])

  return (
    <>
      <CreditNoteModal show={showNCModal} onHide={onHideNCModal}/>
      <ResponseAfipModal show={showDetailAfipModal} onHide={()=>setShowDetailAfipModal(false)} data={responseAfip} />
      <TableContainer
        title='Pendientes de procesar'
        // goTo={routes.ADMINISTRATIVE_VOUCHER_NEW}
        // goToText='Crear Comprobante'
        filter={<Filter setData={setData} setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />}
      >
        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu}  queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default AdministrativeVouchers