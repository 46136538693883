import { CustomerSupplierI, PaymentConditionI, SellerI, PatientI, MetadataI } from 'interfaces'

export enum BudgetStatusI {
  BORRADOR = 1,
  GENERADO = 2,
  ACEPTADO = 3,
  ACEPTADO_PARCIAL = 4,
  RECHAZADO = 5,
  VENCIDO = 6,
  ANULADO = 7,
  PEDIDO = 8,
}

export enum BudgetStatusLabelI {
  BORRADOR = 'Borrador',
  GENERADO = 'Generado',
  ACEPTADO = 'Aceptado',
  ACEPTADO_PARCIAL = 'Aceptado parcial',
  RECHAZADO = 'Rechazado',
  VENCIDO = 'Vencido',
  ANULADO = 'Anulado',
  PEDIDO = 'Pedido',
}

export interface BudgetI {
  id: number | string;
  // -- General
  user_id: number | string;
  budget_status_id: number | string;
  budget_status_name?: string;
  customer_supplier_id: CustomerSupplierI['id'];
  payment_condition_id: PaymentConditionI['id'];
  seller_id: SellerI['id'];
  seller_name?: string;
  issue_date: string;
  dispatch_date?: string;
  de_blister: boolean;
  // -- Articles
  detail: BudgetDetailI[];
  // -- Addressee
  to_patient: boolean;
  patient_id: PatientI['id'];
  patient_name?: string;
  requires_coordination: boolean;
  requires_home_delivery: boolean;
  address_id_traza: number | string;
  shipment_address_customer_supplier?: string;
  address_id_ship?: number | string;
  shipment_address_patient?: string;
  observations?: string;
  dispatch_observations?: string;
  created_at: string;
}

export interface BudgetDetailI {
  id: number | string;
  budget_id: BudgetI['id'];
  product_var_id: number | string;
  name: string;
  line?: string;
  pvp: number;
  list_cost: number;
  boxes: number;
  quantity: number;
  discount: number;
  price: number;
  profitability: number;
  vat_rate?: number;
  created_at: string;
  updated_at?: string;
}

export interface GetBudgetsI {
  metadata: MetadataI;
  result: BudgetI[];
}

export interface GetBudgetI {
  metadata: MetadataI;
  result: BudgetI;
}

export type AddBudgetI = Omit<BudgetI, 'id' | 'detail' | 'created_at'>;