import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import { useIntl } from 'react-intl'
import { DetailContainer } from 'containers'


import { useProducts } from 'hooks'

import {
  formikInitialValues,
  createSchema,
} from './constants'

import ProductForm from './ProductForm'
import { toast } from 'react-toastify'

const AddProduct = () => {
  const navigate = useNavigate()
  const { handleCreateProduct, loading } = useProducts()


  const submitForm = async(values)=>{

      try {
          const data = {
            product_type_id: values.product_type_id,
            name: values.name,
            family_id: values.family_id,
            maker_id: values.maker_id,
            distribution_channels:values.channels?.join(",")
          }
          const response = await handleCreateProduct(data, 'token');
          toast.success("Registro guardado correctamente", {theme: "colored"})
          navigate('/inventario/productos')
      } catch (error) {
          toast.error(error.message, {theme: "colored"})
          console.log(error)
      } finally {

      }
  }


  return (
    <>
      {/* <PageTitle
        breadcrumbs={[
          { title: 'Productos/', path: '/productos', isSeparator: false, isActive: true },
          { title: 'Crear producto', path: '/productos/nuevo', isActive: false }
        ]}
        description='Crear producto'
      /> */}
      <DetailContainer title="Crear nuevo producto">
        <Formik
          initialValues={formikInitialValues}
          validationSchema={createSchema}
          onSubmit={ (values)=>submitForm(values)}
          enableReinitialize
        >
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className="d-flex flex-column">
              
              <ProductForm />

              <button className="btn btn-primary align-self-end mt-10" type="submit" disabled={loading || !values.name}>
                {loading ? 'Espere...' : 'Crear'}
              </button>

            </Form>
          )}
        </Formik>

      </DetailContainer>
    </>
  )
}

export default AddProduct