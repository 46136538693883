import moment from 'moment'
import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import { insertLineBreaks } from 'utilities'
import routes from 'app/routing/routes'

const useGetColumns = (selectedRows = [], handleRowSelection) => {
  const COLUMNS =  [
    {
      Header: 'Nro.',
      id: 'id',
      accessor: (row) => (
        <Tooltip text='Ver detalle'>
          <Link to={`${routes.LOGISTICS_DISPATCH_ORDERS}/${row.id}`}>
            {row.id}
          </Link>
        </Tooltip>
      )
    },
    {
      Header: <div className='text-center'>F. creación</div>,
      id: 'created_at',
      accessor: (row) => (
        <div className='text-center'>
          <p className='p-0 m-0'>{moment(row.created_at).format("DD/MM/YYYY")}</p>
          <p className='p-0 m-0'>{moment(row.created_at).format("HH:mm")}</p>
        </div>
      ),
    },
    {
      Header: <div className='text-center'>F. despacho</div>,
      id: 'dispatch_at',
      accessor: (row) => (
        <div className='text-center'>
          {row.dispatch_at ?
            <>
              <p className='p-0 m-0'>{moment(row.dispatch_at).format("DD/MM/YYYY")}</p>
              <p className='p-0 m-0'>{moment(row.dispatch_at).format("HH:mm")}</p>
            </>
            : <>{'-'}</>
          }
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Cant. envíos</div>,
      id: 'cant_envios',
      accessor: (row) => (
        <div className='text-center'>
          <strong>{row.cant_envios}</strong>

          {row.cant_envios > 0 &&
            <Tooltip placement='right' text={insertLineBreaks(row?.ids_envios?.replaceAll(',', ', '), 5)} maxWidth='300px'>
              <i className='bi bi-info-circle text-primary ms-2' />
            </Tooltip>
          }
        </div>
      )
    },
    {
      Header: <div className='text-center'>Cant. ordenes egreso</div>,
      id: 'cant_ordenes_egreso',
      accessor: (row) => (
        <div className='text-center'>
          <strong>{row.cant_ordenes_egreso}</strong>

          {row.cant_ordenes_egreso > 0 &&
            <Tooltip placement='right' text={insertLineBreaks(row?.ids_ordenes_egreso?.replaceAll(',', ', '), 5)} maxWidth='300px'>
              <i className='bi bi-info-circle text-primary ms-2' />
            </Tooltip>
          }
        </div>
      )
    },
    {
      Header: <div className='text-center'>Transportista</div>,
      id: 'shipping_carrier_id',
      accessor: (row) => (
        <div className='text-center'>
          {row?.shipping_carrier_service_name}
        </div>
      ),
      hasOrder: true,
    },
    {
      Header: <div className="text-center">Estado</div>,
      id: 'order_status_label',
      className:'col-status',
      accessor: (row) => (
        <span className={`badge ${row.label_color} w-100 justify-content-center m-0 fw-normal`}>
          {row.dispatch_order_status_label}
        </span>
      )
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns