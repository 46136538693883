import { Link } from 'react-router-dom'
import { Tooltip } from 'components'

import { formatTableDate } from "utilities"

import routes from "app/routing/routes"

export const COLUMNS = [
  {
    Header: 'Fecha',
    id: 'created_at',
    accessor: (row) => (
      <>{formatTableDate(row.created_at)}</>
    )
  },
  {
    Header: 'Tipo',
    id: 'event_type',
    accessor: (row) => (
      <span className='d-flex align-items-center'>
        <i className={`${row.event_type === 'INGRESO' ? 'bi bi-box-arrow-in-up-right text-success' : 'bi bi-box-arrow-up-right text-danger'} fs-3 me-2`} />
        <strong className={`${row.event_type === 'INGRESO' ? 'text-success' : 'text-danger'}`}>
          {row.event_type}
        </strong>
      </span>
    )
  },
  {
    Header: 'Orden',
    id: 'order_id',
    accessor: (row) => (
      <>
        {row.in_order_detail_id
          ?
            <Tooltip text='Ir a Orden de ingreso'>
              <Link to={`${routes.LOGISTIC_INCOME}/${row.in_order_id}`} state={{ in_request_id: row.in_request_id }}>
                Orden de ingreso #{row.in_order_id}
              </Link>
            </Tooltip>
          :
            <Tooltip text='Ir a Orden de egreso'>
              <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>
                Orden de egreso #{row.out_order_id}
              </Link>
            </Tooltip>
      }
      </>
    )
  },
  {
    Header: 'Movimiento',
    id: 'movement',
    accessor: (row) => (
      <>
        El {formatTableDate(row.created_at)}
        <span className={`${row.event_type === 'INGRESO' ? 'text-success' : 'text-danger'}`}>
          {row.event_type === 'INGRESO'
            ? Math.abs(row.sum_a) > 1 ? ' ingresaron ' : ' ingresó '
            : Math.abs(row.sum_a) > 1 ? ' egresaron ' : ' egresó '
          }
        </span>
        <strong>{Math.abs(row.sum_a)} {Math.abs(row.sum_a) > 1 ? 'unidades' : 'unidad'}</strong>
      </>
    )
  },
  {
    Header: 'Unidad de negocio',
    id: 'business_unit',
    accessor: (row) => (
      <>{row.business_unit_name}</>
    ),
  },
  {
    Header: 'Dueño',
    id: 'stock_owner',
    accessor: (row) => (
      <>{row.stock_owner}</>
    ),
  },
  {
    Header: '',
    id: 'actions',
    className:'col-icons',
  },
]