import { SalesOrderStatusI } from 'interfaces';

export {default as ProfitabilityAuthorizations} from './Profitability'
export {default as CreditAuthorizations} from './Credit'

export const labelColor = (sales_order_status_id) => {
  switch(sales_order_status_id){
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA:
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO:
    case SalesOrderStatusI.PENDIENTE_PREPARACION:
    case SalesOrderStatusI.PENDIENTE_FACTURACION:
    case SalesOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case SalesOrderStatusI.ENTREGADO:
      return "estado-verde";
    case SalesOrderStatusI.BORRADOR:
      return "estado-gris";
    case SalesOrderStatusI.FALTA_STOCK:
    case SalesOrderStatusI.EN_REVISION:
    case SalesOrderStatusI.NO_AUTORIZADO:
    case SalesOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    case SalesOrderStatusI.EN_DISTRIBUCION:
    case SalesOrderStatusI.EN_PREPARACION:
    case SalesOrderStatusI.EN_DESPACHO:
      return "estado-azul";
    case SalesOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case SalesOrderStatusI.CANCELADO:
      return "estado-rojo";
    default:
      return ""
  }
}