import * as Yup from 'yup'

import { formatInputDate, currentDate } from 'utilities'
import { HomeDeliveryOptionI } from 'interfaces'

const initialValues = {
  user_id: '',
  sales_order_status_id: '',
  sales_order_status_name: '',
  sales_order_status_label: '',
  // -- General
  has_budget: false,
  budget_id: '',
  budget: '',
  customer_supplier_id: '',
  customer_supplier_name: '',
  business_unit_id: '',
  seller_id: '',
  payment_condition_id: '',
  issue_date: currentDate,
  dispatch_date: '',
  bidding: false,
  bidding_value: '',
  de_blister: false,
  billing_type_id: '',
  billing_date: '',
  // -- Articles
  search_product_var: '',
  detail: [],
  delivery_cost: 0,
  total: 0,
  total_profitability: 0,
  total_delivery_cost: 0,
  // -- Addressee
  requires_coordination: false,
  to_patient: false,
  home_delivery_option_id: '',
  patient_id: '',
  address_id_ship: '',
  shipment_address_id_home_delivery: '',
  address_id_traza: '',
  observations: '',
  dispatch_observations: '',
  // -- Audit
  created_at: '',
  user_creation_name: '',
  updated_at: '',
  updated_by_user_id: '',
  user_update_name: '',
  cancel_at: '',
  // canceled_at: '',
  // user_canceled_name: '',
}

const schema = Yup.object().shape({
  // -- General
  budget_id: Yup.number(),
  customer_supplier_id: Yup.number().required('* Seleccione un cliente').nonNullable(),
  seller_id: Yup.number().required('* Seleccione un vendedor').nonNullable(),
  payment_condition_id: Yup.number().required('* Seleccione una condición de pago').nonNullable(),
  issue_date: Yup.string().required('* Ingrese una fecha de emisión').nonNullable(),
  dispatch_date: Yup.string(),
  de_blister: Yup.boolean().default(false),
  // -- Articles
  // detail:
  // -- Addressee
  requires_coordination: Yup.boolean().default(false),
  to_patient: Yup.boolean().default(false),
  patient_id: Yup.number().when(['to_patient'], {
    is: (to_patient) => to_patient,
    then: (schema) => schema.required('* Seleccione un paciente').nonNullable(),
    otherwise: (schema) => schema,
  }),
  address_id_ship: Yup.number().when(['to_patient', 'home_delivery_option_id'], {
    is: (to_patient, home_delivery_option_id) => to_patient && home_delivery_option_id === HomeDeliveryOptionI.PACIENTE,
    then: (schema) => schema.required('* Seleccione una dirección de entrega').nonNullable(),
    otherwise: (schema) => schema,
  }),
  shipment_address_id_home_delivery: Yup.number().when(['home_delivery_option_id'], {
    is: (home_delivery_option_id) => home_delivery_option_id === HomeDeliveryOptionI.CLIENTE,
    then: (schema) => schema.required('* Seleccione una dirección de entrega').nonNullable(),
    otherwise: (schema) => schema,
  }),
  address_id_traza: Yup.number().when(['home_delivery_option_id'], {
    is: (home_delivery_option_id) => (home_delivery_option_id === HomeDeliveryOptionI.PACIENTE || Number(home_delivery_option_id) === HomeDeliveryOptionI.CLIENTE),
    then: (schema) => schema.required('* Seleccione una dirección de trazabilidad').nonNullable(),
    otherwise: (schema) => schema,
  }),
  observations: Yup.string().nullable(),
  dispatch_observations: Yup.string().nullable(),
});

const detailDataAdapter = (data) => {
  const detail = data.map(article => {
    return {
      id: article.id,
      product_var_id: article.product_var_id,
      product_var_desc: article.name,
      line: article?.line ? article.line : '',
      actual_pvp: article.pvp,
      list_cost: article.list_cost,
      qty: article.qty,
      units: article.units,
      discount: Number(article.discount),
      price: article.price,
      profitability: article.profitability,
      profitability_rate: article.profitability_rate,
      vat: article.vat,
      vat_rate: article.vat_rate * 100,
      total: article.subtotal,
    }
  })

  return detail
}

const dataAdapter = (values) => {
  const detail = detailDataAdapter(values.detail)

  let data = {
    ...values,
    // -- General
    has_budget: values?.budget_id ? true : false,
    budget_id: values?.budget_id ? values.budget_id : '',
    budget: values.budget_id ? `Presupuesto #${values.budget_id}` : '',
    customer_supplier_name: values.customer_supplier_business_name,
    business_unit_id: values.business_unit_id,
    seller_id: values.seller_id,
    payment_condition_id: values.payment_condition_id,
    issue_date: formatInputDate(values.issue_date),
    dispatch_date: formatInputDate(values.dispatch_date),
    de_blister: Boolean(values.de_blister),
    billing_type_id: values.billing_type_id,
    billing_date: formatInputDate(values.billing_date),
    // -- Articles
    lines: values.lines,
    detail: detail,
    delivery_cost: Number(values.delivery_cost).toFixed(2),
    total: Number(values.total).toFixed(2),
    total_profitability: Number(values.total_profitability).toFixed(5),
    total_delivery_cost: (Number(values.total) + Number(values.delivery_cost)).toFixed(2),
    // -- Addressee
    requires_coordination: Boolean(values.requires_coordination),
    to_patient: Boolean(values.to_patient),
    home_delivery_option_id: Number(values.home_delivery_option_id),
    patient_id: values?.patient_id ? values.patient_id : '',
    patient_name: values?.patient_id ? `${values.patient_documentation} - ${values.patient_name}` : '',
    address_id_ship: values?.address_id_ship ? values.address_id_ship : '',
    shipment_address_id_home_delivery: values?.shipment_address_id_home_delivery ? values.shipment_address_id_home_delivery : '',
    address_id_traza: values?.address_id_traza ? values.address_id_traza : '',
    observations: values?.observations ? values.observations : '',
    dispatch_observations: values?.dispatch_observations ? values.dispatch_observations : '',
    created_at: formatInputDate(values.created_at),
    updated_at: values?.updated_at ? formatInputDate(values.updated_at) : '',
    user_update_name: values?.user_update_name ? values.user_update_name : '',
    cancel_at: values?.cancel_at ? formatInputDate(values.cancel_at) : '',
  }

  return data
}

const filterNewArticles = (detail, newArticles) => {
  const ids = detail.map(d => d.product_var_id)

  const newFilteredArticles = newArticles.filter((newArticle) => !ids.includes(newArticle.id))

  return newFilteredArticles
}

const vatOptions = [
  { value: 1, label: 'No Gravado', percent: 0 },
  { value: 2, label: 'Exento', percent: 0 },
  { value: 3, label: '0%', percent: 0 },
  { value: 9, label: '2.5%', percent: 2.5 },
  { value: 8, label: '5%', percent: 5 },
  { value: 4, label: '10.5%', percent: 10.5 },
  { value: 5, label: '21%', percent: 21 },
  { value: 6, label: '27%', percent: 27 }
]

const addArticlesInDetailAdapter = (data) => {
  const detail = data.detail.map(article => {
    let _profitability = Number(article.subtotal) - Number(article.list_cost)
    _profitability = isNaN(_profitability) ? 0 : _profitability

    let _profitability_rate = (1 - (Number(article.list_cost) / Number(article.price))) * 100

    let vatValue = vatOptions.find(v => v.value === article.vat)

    return {
      product_var_id: article.var_id,
      name: article.name,
      line: article?.line ? article.line : null,
      pvp: article.actual_pvp,
      list_cost: article.list_cost,
      qty: Number(article.boxes),
      units: article.qty,
      discount: article.discount,
      vat: article.vat,
      vat_rate: vatValue.percent / 100,
      price: article.price,
      profitability: _profitability,
      profitability_rate: _profitability_rate,
      subtotal: article.total,
    }
  })

  return {
    ...data,
    detail: detail,
  }
}

const updateDataAdapter = (data) => {
  let values = {
    ...data,
    budget_id: data?.budget_id ? data.budget_id : null,
    // -- Articles
    delivery_cost: data?.delivery_cost ? Number(data.delivery_cost) : 0,
    // -- Addressee
    patient_id: data?.patient_id ? data.patient_id : null,
    address_id_ship: data?.address_id_ship ? data.address_id_ship : null,
    shipment_address_id_home_delivery: data?.shipment_address_id_home_delivery ? data.shipment_address_id_home_delivery : null,
    address_id_traza: data?.address_id_traza ? data.address_id_traza : null,
  }

  delete values.sales_order_status_label
  delete values.sales_order_status_name
  delete values.customer_supplier_business_name
  delete values.customer_supplier_name
  delete values.has_budget
  delete values.budget
  delete values.business_unit_name
  delete values.seller_name
  delete values.bidding
  delete values.bidding_value

  delete values.search_product_var
  delete values.detail
  delete values.lines
  delete values.total
  delete values.total_profitability
  delete values.total_delivery_cost

  delete values.requires_home_delivery
  delete values.patient_documentation
  delete values.patient_name

  delete values.created_at
  delete values.updated_at
  delete values.cancel_at
  delete values.cancel_by_user_id
  delete values.user_creation_name
  delete values.user_update_name

  return values
}

export {
  initialValues,
  schema,
  dataAdapter,
  filterNewArticles,
  addArticlesInDetailAdapter,
  updateDataAdapter,
  vatOptions,
}