import { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'

import { useWaybill, useWaves } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer, TableContainer } from 'containers'
import { CustomTable } from 'components'

import useGetColumns from './columns'
import { initialValues, dataAdapter } from './constants'
import { ESTADOS_ORDEN_ENVIO, labelColor } from '../List'

import routes from 'app/routing/routes'

const ShipmentOrderDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetShipmentOrder, handleUpdateShipmentOrderShippingCarrierService, handleMarkOrderAsDelivered } = useWaybill()
  const [shipmentOrder, setShipmentOrder] = useState(initialValues)

  const { handleGetShippingCarrierServices } = useWaves()
  const [shippingCarrierServices, setShippingCarrierServices] = useState([])

  const [editing, setEditing] = useState(false)

  const { COLUMNS } = useGetColumns()

  const availableActions = () => {
    let actions = [];
    if (shipmentOrder.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION) {
      // actions.push({
      //   label: "Envío entregado",
      //   icon: "bi bi-bag-check text-success",
      //   action: actionDelivered
      // })
      // actions.push({
      //   label: "Envío devuelto",
      //   icon: "bi bi-arrow-return-left text-danger",
      //   action: actionReturned
      // })
      // actions.push({
      //   label: "Entrega / Devolución",
      //   icon: "bi bi-bag-check text-primary",
      //   action: ()=>setShowShipmentResult(true)
      // })
    }
    return actions
  }

  const actionDelivered = async () => {
    if (shipmentOrder) {
      try {
        const resp = await Swal.fire({
          title:"Envío entregado",
          text:`¿Confirma que el envío ${shipmentOrder.id}, con ${shipmentOrder.out_order_count == 1 ? ' 1 órden': shipmentOrder.out_order_count + 'órdenes' } de egreso, se entregó?`,
          icon:"warning",
          showDenyButton:true,
          denyButtonText:"No, cancelar",
          confirmButtonText:"Si, confirmar",
          customClass: {confirmButton:'btn btn-primary', denyButton:"btn btn-secondary"},
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          await handleMarkOrderAsDelivered({ order_delivery_id: [shipmentOrder.id] });
          toast.success("Envío marcado como entregado", { theme: "colored" })
        }
      } catch (err) {
        toast.error(err.message, { theme: "colored" })
      }

    }
  }

  const actionReturned = async () => {
    //
  }

  const getShipmentOrder = useCallback(async () => {
    try {
      const response = await handleGetShipmentOrder(id, { getDetail: true })

      const data = dataAdapter(response.data.result)
      setShipmentOrder(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetShipmentOrder])

  const getShippingCarrierServices = useCallback(async () => {
    try {
      const response = await handleGetShippingCarrierServices()
      setShippingCarrierServices(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetShippingCarrierServices])

  const updateShipmentOrderShippingCarrierService = async (shipment_order_id, new_shipping_carrier_service_id) => {
    try {
      const response = await handleUpdateShipmentOrderShippingCarrierService(shipment_order_id, new_shipping_carrier_service_id)
      toast.success("Registro actualizado correctamente", { theme: "colored" })
    } catch (error) {
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
  ], [COLUMNS])

  useEffect(() => {
    getShipmentOrder()
    getShippingCarrierServices()
  }, [getShipmentOrder, getShippingCarrierServices])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Logística/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Órdenes de envío/', path: routes.LOGISTICS_SHIPMENT_ORDERS, isSeparator: false, isActive: true },
          { title: `Orden de envío #${id}`, path: `${routes.LOGISTICS_SHIPMENT_ORDERS}/${id}`, isSeparator: false, isActive: true },
        ]}
        description={`Orden de envío #${id}`}
      />

        <DetailContainer
          title={`Orden de envío #${id}`}
          statusV2={
            <>
              <div className={`badge ${labelColor(shipmentOrder.order_delivery_status_id)} justify-content-center fw-normal`} style={{ width: "150px" }}>
                {shipmentOrder.order_delivery_status_label}
              </div>

              <div className='mt-7'>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "120px" }}>F. creación:</td>
                      <td><span className='fw-bold ps-5'>{shipmentOrder?.created_at? moment(shipmentOrder.created_at).format("DD/MM/YYYY HH:mm"):''}</span></td>
                    </tr>
                    <tr>
                      <td style={{ width: "120px" }}>Transporte:</td>
                      <td><span className='fw-bold ps-5'>{shipmentOrder?.shipping_carrier_name} - {shipmentOrder?.shipping_carrier_service}</span></td>
                    </tr>
                    {shipmentOrder?.shipped_date &&
                      <tr>
                        <td style={{ width: "120px" }}>Entregado:</td>
                        <td><span className='fw-bold ps-5'>{shipmentOrder?.shipped_date? moment(shipmentOrder.shipped_date).format("DD/MM/YYYY HH:mm"):''}</span></td>
                      </tr>
                    }
                    {shipmentOrder?.guide_printed_date &&
                      <tr>
                        <td style={{ width: "120px" }}>Impresión guía:</td>
                        <td><span className='fw-bold ps-5'>{shipmentOrder?.guide_printed_date? moment(shipmentOrder.guide_printed_date).format("DD/MM/YYYY HH:mm"):''}</span></td>
                      </tr>
                    }
                    {shipmentOrder?.ticket_printed_date &&
                        <tr>
                          <td style={{ width: "120px" }}>Impresión ticket:</td>
                          <td><span className='fw-bold ps-5'>{shipmentOrder?.ticket_printed_date? moment(shipmentOrder.ticket_printed_date).format("DD/MM/YYYY HH:mm"):''}</span></td>
                        </tr>
                    }
                    <tr>
                      <td style={{ width: "120px" }}>Ola:</td>
                      <td><span className='fw-bold ps-5'>#{shipmentOrder?.wave?.id} {shipmentOrder?.wave?.name}</span></td>
                    </tr>
                    {shipmentOrder?.cancel_date &&
                      <tr>
                        <td style={{ width: "120px" }}>F. cancelación:</td>
                        <td><span className='fw-bold ps-5' >{shipmentOrder?.cancel_date? moment(shipmentOrder.cancel_date).format("DD/MM/YYYY HH:mm"):''}</span></td>
                      </tr>
                    }
                    {shipmentOrder?.returned_reason_name &&
                      <>
                        <tr>
                          <td style={{ width: "120px" }}>F. devolución:</td>
                          <td><span className='fw-bold ps-5' >{shipmentOrder?.returned_date? moment(shipmentOrder.returned_date).format("DD/MM/YYYY HH:mm"):''}</span></td>
                        </tr>
                        <tr>
                          <td style={{ width: "120px" }}>Motivo devolución:</td>
                          <td><span className='fw-bold ps-5' >{shipmentOrder?.returned_reason_name}</span></td>
                        </tr>
                      </>
                    }
                  </tbody>
                </table>
              </div>
            </>
            // <span className={`ms-4 badge ${ labelColor(shipmentOrder.order_delivery_status_id)} justify-content-center fw-normal`}>{shipmentOrder.order_delivery_status_label}</span>
          }
          buttons={
            <div className='d-flex justify-content-center align-items-center'>
              <Button variant='secondary' onClick={() => navigate(routes.LOGISTICS_SHIPMENT_ORDERS)} className='me-4'>Volver</Button>
              {shipmentOrder.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION &&
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Acciones
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{width:"200px"}}>
                      {availableActions().map((data, index)=>(
                          <Dropdown.Item key={index}  onClick={()=>{ data.action() }}><i className={`${data.icon} pe-3`}></i> {data.label}</Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
          }
        >

        {/* <ShipmentResultModal show={showShipmentResult} onHide={onHideShipmentResult} shipmentOrder={shipmentOrder} /> */}

        <Formik
          initialValues={shipmentOrder}
          // validationSchema={schema}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column' encType='multipart/form-data'>

                {/* <div className='row my-8'>
                  <div className='col-2'>
                    <InputBS id='created_at' name='created_at' type='date' label='Fecha de creación' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='cancel_date' name='cancel_date' type='date' label='Fecha de cancelación' disabled />
                  </div>
                </div> */}

                {/* <div className='row mb-8'>
                  <div className='col-2'>
                    <InputBS id='returned_date' name='returned_date' type='date' label='Fecha de devolución' disabled />
                  </div>

                  <div className='col-6'>
                    <InputBS id='returned_reason_name' name='returned_reason_name' label='Motivo de devolución' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='returning_pending_date' name='returning_pending_date' type='date' label='Devolución pendiente' disabled />
                  </div>

                  <div className='col-1'>
                    <div className='d-flex flex-column justify-content-between align-items-start'>
                      <Label htmlFor='reship' label='Devolución' />

                      <div className='d-flex flex-row justify-content-between align-items-center'>
                        <Checkbox id='reship' disabled />
                        <Label htmlFor='reship' label='¿Devuelta?' styles='mb-0 ms-4' />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className='row mb-8'>
                  <div className='col-6'>
                    <SelectField
                      id='shipping_carrier_service_id'
                      name='shipping_carrier_service_id'
                      label='Transportista'
                      options={shippingCarrierServices.map(item => ({ value: item.id, label: `${item.shipping_carrier_name} - ${item.shipping_carrier_service}` }))}
                      handleChange={(e) => {
                        let value = e.value
                        updateShipmentOrderShippingCarrierService(values.id, value)
                      }}
                      placeholder='Seleccione un servicio de transporte'
                      disabled={values.order_delivery_status_id !== ShipmentOrderStatusI.PENDIENTE_DESPACHO}
                    />
                  </div>
                </div> */}

                {/* <div className='row mb-8'>
                  <div className='col-2'>
                    <InputBS id='shipped_date' name='shipped_date' type='date' label='Fecha de envío' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='guide_printed_date' name='guide_printed_date' type='date' label='Impresión de guía' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='ticket_printed_date' name='ticket_printed_date' type='date' label='Impresión de ticket' disabled />
                  </div>
                </div> */}

                {/* <div className='separator mb-8'></div>
                <h4>Ola #{shipmentOrder.wave.id}</h4>

                <div className='row mb-8'>
                  <div className='col-8'>
                    <InputBS id='wave.name' name='wave.name' label='Nombre' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='wave.created_at' name='wave.created_at' type='date' label='Fecha' disabled />
                  </div>

                  <div className='col-2 d-flex align-items-end'>
                    <Link to={`${routes.WAVES}/${shipmentOrder.wave.id}`}>
                      <Button variant="primary" disabled={!shipmentOrder.order_delivery_status_id} >Ir a Ola</Button>
                    </Link>
                  </div>
                </div> */}

                <div className='row mb-8 mt-10'>
                  <div className='col'>
                  <TableContainer>
                    <CustomTable columns={columns} data={shipmentOrder?.order_delivery_rel || []} />
                  </TableContainer>
                  </div>
                </div>

                {editing && (
                  <div className='d-flex flex-row justify-content-end'>
                    <Button
                      ref={cancelRef}
                      variant="secondary"
                      type='button'
                      className='me-3'
                      onClick={() => {
                        setEditing(false)
                        handleReset()
                      }}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button ref={submitRef} variant="primary" type='button' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default ShipmentOrderDetail