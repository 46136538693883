import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useDocumentTypes } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { InputBS, SelectField } from 'components'
import { IconVisualizer } from '../partials'

import { initialValues, schema } from './constants'
import { documentTypeEntitiesSelect } from 'interfaces'

import routes from 'app/routing/routes'

const AddDocumentType = () => {
  const { loading, handleCreateDocumentType } = useDocumentTypes()
  const navigate = useNavigate()
  const intl = useIntl()
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const createDocumentType = async (values) => {
    try {
      const data = {
        ...values,
      }

      const response = await handleCreateDocumentType(data, 'token')

      toast.success(response.message)
      navigate(routes.DOCUMENT_TYPES)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <DetailContainer
        title={intl.formatMessage({ id: 'TITLE.DOCUMENT_TYPES.CREATE' })}
        buttons={
          <div className='d-flex align-self-center justify-content-end'>
            <Button className='me-3' variant="secondary" type='button' onClick={() => navigate(-1)}><i className="ki-duotone ki-left" />Volver</Button>
            <Button variant='primary' type='button' disabled={loading} onClick={() => submitRef.current.click()}><i className='bi bi-save me-2' />Guardar</Button>
          </div>
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={createDocumentType}
          enableReinitialize
        >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form className='d-flex flex-column'>
              <div className='row my-8'>
                <div className='col-6'>
                  <InputBS id='name' name='name' label='Nombre' />
                </div>

                <div className='col-6'>
                  <SelectField
                    id='entity'
                    name='entity'
                    lable='Entidad'
                    options={documentTypeEntitiesSelect}
                    placeholder='Seleccione una entidad'
                  />
                </div>
              </div>

              <div className='row my-8'>
                <IconVisualizer icon={values.icon} />

                <div className='col-8'>
                  <InputBS id='icon' name='icon' label={intl.formatMessage({ id: 'FORM.DOCUMENT_TYPES.ICON' })} />
                </div>

                <div className='col-2'>
                  <ul className='list-unstyled'>
                    <li>- <a href='https://icons.getbootstrap.com/' target='__blank'>Bootstrap</a></li>
                    <li>- <a href='https://fontawesome.com/icons' target='__blank'>Font Awesome</a></li>
                    <li>- <a href='https://icons8.com/line-awesome' target='__blank'>Line Awesome</a></li>
                  </ul>
                </div>
              </div>

              <div className='separator my-8'></div>

              <div className='d-flex align-items-center justify-content-end'>
                <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
              </div>
            </Form>
          )
        }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddDocumentType