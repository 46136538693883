export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)

  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

export function checkIsAllowed(roles: any[], allowedRoles: string[]) {
  
  if(!allowedRoles || allowedRoles.length==0){
    return true
  }

  if(roles?.map((r:any)=>r.name).some((rol:string)=>allowedRoles.includes(rol)))
  {
    return true;
  }
  else{
    return false;
  }

}
