import { useState, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useSellers } from 'hooks'
import { SelectField, InputBS } from 'components'

const SellingsTab = ({ editing }) => {
  const intl = useIntl()

  const { handleGetSellers } = useSellers()
  const [sellers, setSellers] = useState([])

  const getSellers = useCallback(async () => {
    try {
      const response = await handleGetSellers()
      setSellers(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetSellers])

  useEffect(() => {
    getSellers()
  }, [getSellers])

  return (
    <>
      <div className='row mb-8'>
        <div className='col-4'>
          <SelectField
            id='seller_id'
            name='seller_id'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SELLINGS.SELLER' })}
            options={sellers.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))}
            disabled={!editing}
          />
        </div>

        <div className='col-4'>
          <InputBS id='comission' name='comission' type='number' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SELLINGS.COMISSION' })} disabled={!editing} />
        </div>

        <div className='col-4'>
          <InputBS id='credit' name='credit' type='number' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SELLINGS.CREDIT' })} disabled={!editing} />
        </div>
      </div>
    </>
  )
}

export default SellingsTab