import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'

import routes from 'app/routing/routes'

const MenuActions = ({ values, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigation = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver detalle",
      icon: "bi bi-person-circle text-primary",
      action: actionGoToDetail
    })

    actions.push({
      label: "Ver impuestos",
      icon: "bi bi-bank text-primary",
      action: actionGoToTaxes
    })

    actions.push({
      label: "Ver descuentos",
      icon: "bi bi-coin text-primary",
      action: actionGoToDiscounts
    })

    return actions
  }

  const actionGoToDetail = async () => {
    navigation(`${routes.CUSTOMER_SUPPLIERS}/${values.id}`)
  }

  const actionGoToTaxes = async () => {
    navigation(`${routes.CUSTOMER_SUPPLIERS}/${values.id}/impuestos`)
  }

  const actionGoToDiscounts = async () => {
    navigation(`${routes.CUSTOMER_SUPPLIERS}/${values.id}/descuentos`)
  }

  return (
    <>
      <DropdownButton title="Acciones" disabled={disabled}>
        {availableActions().map((data, index)=>(
          <Dropdown.Item key={index} onClick={() => data.action()}>
            <i className={`${data.icon} pe-3`} />
            {data.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  )
}

const Actions = ({ values, loading }) => {
  const navigate = useNavigate()

  return (
    <div className='d-flex flex-row justify-content-center align-items-center'>
      <Button className='me-3' variant='secondary' type='button' onClick={() => navigate(-1)}><i className="ki-duotone ki-left" />Volver</Button>
      <MenuActions values={values} disabled={loading} />
    </div>
  )
}

export default Actions