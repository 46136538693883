import { useState, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { FormTable } from 'components'
import { ShipmentAddressModal } from 'containers/Modals'

const ShipmentAddressesTab = () => {
  const [modal, setModal] = useState(false)
  const { values, setFieldValue } = useFormikContext();
  const intl = useIntl()

  const columns = useMemo(() => [
    { header: intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.NAME' }) },
    { header: intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.ADDRESS' }) },
    { header: intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.CONTACT' }) },
    { header: intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY' }), styles: 'text-center' },
    { header: intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.TYPE' })},
    { header: intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.GLN' })},
    { header: intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.VERIFARMA' }), styles: 'text-center' },
    { header: '', styles: 'text-center' },
  ], [intl])

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleShowModal = () => {
    setModal(true)
  }

  const handleAddShipmentAddress = (new_shipment_address) => {
    setFieldValue('shipment_addresses', [
      ...values.shipment_addresses,
      {
        ...new_shipment_address,
        address: {
          ...new_shipment_address.address,
          origin: new_shipment_address.address.manual ? 'MANUAL' : 'GOOGLE',
        }
      }
    ])
    handleCloseModal()
  }

  const handleDeleteShipmentAddress = (index) => {
    const new_shipment_addresses = values.shipment_addresses.filter((sa, i) => i !== index)
    setFieldValue('shipment_addresses', new_shipment_addresses)
  }

  return (
    <>
      <ShipmentAddressModal show={modal} onClose={handleCloseModal} onSave={handleAddShipmentAddress} ours={false} />

      <div className='mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8'>
          <label className='form-label m-0'>{intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.TITLE' })}</label>
          <Button className='BSButton' variant='outline-primary' type='button' onClick={handleShowModal}>Añadir dirección de entrega</Button>
        </div>

        <FormTable columns={columns}>
          {values.shipment_addresses.length <= 0
            ? <tr><td className='text-italic text-gray-400' colSpan={columns.length}>El cliente / proveedor aún no tiene direcciones de entrega asociadas.</td></tr>
            : (values.shipment_addresses).map((sa, index) => (
              <tr key={`shipment-address-${index}`}>
                <td className='align-middle'>{sa.name}</td>
                <td className='align-middle'>
                  <ul className='list-style-none m-0 p-0'>
                    <li>{sa.address.route} {sa.address.street_number}{sa.address.floor !== '' ? ` (${sa.address.floor})` : ''}, {sa.address.postal_code}{sa.address.postal_code_suffix} {sa.address.custom_address_level1}, {sa.address.custom_address_level2}, {sa.address.administrative_area_level_1}</li>
                  </ul>
                </td>
                <td className='align-middle'>
                  <ul className='list-style-none m-0 p-0'>
                    <li><strong>Nombre:</strong> {sa.contact.name}</li>
                    <li><strong>Email:</strong> {sa.contact.email}</li>
                    <li><strong>Teléfono:</strong> {sa.contact.phone}</li>
                    <li><strong>Celular:</strong> {sa.contact.mobile}</li>
                  </ul>
                </td>
                <td className='align-middle text-center'>{sa.has_traceability ? 'SÍ' : 'NO'}</td>
                <td className={`align-middle${!sa.has_traceability ? ' text-center' : ''}`}>{sa.has_traceability ? sa.agent_type_name : '-'}</td>
                <td className={`align-middle${!sa.has_traceability ? ' text-center' : ''}`}>{sa.has_traceability ? sa.gln : '-'}</td>
                <td className='align-middle text-center'>
                  {sa.has_traceability
                    ? sa.integrates_verifarma ? 'SÍ' : 'NO'
                    : '-'
                  }
                </td>
                <td className='align-middle text-center'>
                  <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>Eliminar dirección de entrega</Tooltip>}>
                    <button type='button' className='btn btn-icon' onClick={() => handleDeleteShipmentAddress(index)}>
                      <i className='las la-ban fs-2 me-2 text-danger' />
                    </button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))
          }
        </FormTable>
      </div>
    </>
  )
}

export default ShipmentAddressesTab