import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useCustomerSuppliers, useProvinces } from 'hooks'

import { SelectField, Label, Checkbox, FormTable, Tooltip } from 'components'

import { MultilateralAgreementModal } from './'
import { formatTableDate } from 'utilities'

const GeneralTab = ({ updating, handleGetTaxesData }) => {
  const { values } = useFormikContext()

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const { handleAddCustomerSupplierMultilateralAgreement, handleUpdateCustomerSupplierMultilateralAgreement } = useCustomerSuppliers()

  const { handleGetProvinces } = useProvinces()
  const [provinces, setProvinces] = useState([])

  const addMultilateralAgreement = async (data) => {
    try {
      const response = await handleAddCustomerSupplierMultilateralAgreement(values.id, data)
      toast.success("Registro guardado correctamente", {theme: "colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      setModal(false)
      setModalData(null)
      handleGetTaxesData()
    }
  }

  const updateMultilateralAgreement = async (id, data) => {
    try {
      const response = await handleUpdateCustomerSupplierMultilateralAgreement(values.id, id, data)
      toast.success("Registro actualizado correctamente", {theme: "colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetTaxesData()
    }
  }

  const getProvinces = useCallback(async () => {
    try {
      const response = await handleGetProvinces()
      setProvinces(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetProvinces])

  useEffect(() => {
    getProvinces()
  }, [getProvinces])

  return (
    <>
      <div className='row mb-8'>
        <div className='col-4'>
          <SelectField
            id='province_id'
            name='province_id'
            label='Jurisdicción sede'
            options={[...provinces.map(item => ({ value: item.id, label: item.label }))]}
            placeholder='Selecionar jurisdicción sede'
            disabled={!updating}
          />
        </div>

        <div className='col-4'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='' label='Convenio multilateral' />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='has_multilateral_agreement' disabled={!updating} />
              <Label htmlFor='has_multilateral_agreement' label='¿Tiene convenio multilateral?' styles='mb-0 ms-4' />
            </div>
          </div>
        </div>


        {Boolean(values.has_multilateral_agreement) &&
          <>
            <div className='separator my-8'></div>

            <div className='d-flex justify-content-between align-items-center mb-8'>
              <label className='form-label m-0'>Convenio Multilateral</label>
              <Button variant='primary' onClick={() => setModal(true)} >Añadir provincia</Button>
            </div>

            <MultilateralAgreementModal
              show={modal}
              onHide={() => {
                setModalData(null)
                setModal(false)
              }}
              values={modalData}
              onSave={values => modalData?.id ? updateMultilateralAgreement(modalData.id, values) : addMultilateralAgreement(values)}
            />

            <FormTable
              columns={[
                { header: 'Provincia' },
                { header: 'Fecha inicio', styles: 'text-center' },
                { header: 'Fecha cese', styles: 'text-center' },
                { header: 'Periodo', styles: 'text-center' },
                { header: 'Coeficiente', styles: 'text-center' },
                { header: '' },
              ]}
            >
              {values.multilateral_agreement.length <= 0
                ? <tr><td colSpan='7'>El cliente/proveedor no tiene ningún convenio multilateral definido.</td></tr>
                : (values.multilateral_agreement).map(ma => (
                  <tr key={`multilateral-agreement-${ma.id}`}>
                    <td className='align-middle'>{ma.province_name}</td>
                    <td className='align-middle text-center'>{formatTableDate(ma.date_from)}</td>
                    <td className='align-middle text-center'>{ma?.date_to ? formatTableDate(ma.date) : '-'}</td>
                    <td className='align-middle text-center'>{ma.period}</td>
                    <td className='align-middle text-center'>{ma.coefficient}</td>
                    <td>
                      <Tooltip text='Editar'>
                        <button
                          type='button'
                          className='btn btn-icon'
                          onClick={() => {
                            setModalData(ma)
                            setModal(true)
                          }}
                        >
                          <i className='bi bi-pencil-square text-primary fs-3'></i>
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              }
            </FormTable>
          </>
        }
      </div>
    </>
  )
}

export default GeneralTab