import * as Yup from 'yup'

const healthInsurance = {
  health_insurance_id: '',
  affiliate_number: '',
  plan: '',
}

const contactInfo = {
  name: '',
  email: '',
  has_notifications: false,
  phone: '',
  mobile_suffix: '',
  mobile: '',
  observations: '',
}

const createInitialValues = {
  documentation_type_id: '',
  documentation: '',
  first_name: '',
  last_name: '',
  birthdate: '',
  genre_id: '',
  health_insurance: healthInsurance,
  contact: contactInfo,
}

const createSchema = Yup.object().shape({
  documentation_type_id: Yup.number().nullable(),
  // documentation_type_id: Yup.number().required('* Seleccione un tipo de documento').nonNullable(),
  documentation: Yup.string().nullable()
    // .required('* Ingrese un documento').nonNullable()
    .test('custom-validation', '* Longitud y formato incorrectos para el tipo de documento', function(value) {
      const { documentation_type_id } = this.parent;
      if (value !== undefined) {
        if (documentation_type_id == '1' && (value.length != 8 || !/^[0-9]+$/.test(value))) {
          throw this.createError({ message: '* El DNI debe contener 8 caracteres' });
        }

        if (documentation_type_id == '2' && (value.length != 11 || !/^[0-9]+$/.test(value))) {
          throw this.createError({ message: '* El CUIT debe contener 11 caracteres' });
        }
      }

      return true;
    }),
  first_name: Yup.string().required('* Ingrese el nombre del paciente').nonNullable(),
  last_name: Yup.string().required('* Ingrese el apellido del paciente').nonNullable(),
  birthdate: Yup.string(),
  genre_id: Yup.number(),
  health_insurance: Yup.object().shape({
    health_insurance_id: Yup.number().nullable(),
    affiliate_number: Yup.string().nullable(),
    // affiliate_number: Yup.string().when(['health_insurance_id'], {
    //   is: (health_insurance_id) => !!health_insurance_id,
    //   then: (schema) => schema.required('* Ingrese el número de afiliado'),
    //   otherwise: (schema) => schema.nullable(),
    // }),
    plan: Yup.string(),
  }),
  contact: Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().email('* Ingrese un email válido'),
    has_notifications: Yup.boolean().default(false),
    phone: Yup.string(),
    mobile_suffix: Yup.string().matches(/^(\+)?[0-9\s]+$/, '* El sufijo debe ser numérico y puede incluir el signo + al principio'),
    mobile: Yup.string().min(10, '* El número de celular debe tener al menos 10 dígitos').label('Celular'),
    observations: Yup.string(),
  }),
})

const dataAdapter = (data) => {
  let values = {
    ...data,
    health_insurance: {
      ...data.health_insurance,
      health_insurance_id: data.health_insurance?.health_insurance_id ? data.health_insurance.health_insurance_id : null,
      affiliate_number: data.health_insurance?.affiliate_number ? data.health_insurance.affiliate_number : null,
      plan: data.health_insurance?.plan ? data.health_insurance.plan : null,
    },
    contact: {
      ...data.contact,
      has_notifications: data.contact?.has_notifications ? true : false,
    }
  }

  return values
}

export {
  createInitialValues,
  createSchema,
  dataAdapter,
}