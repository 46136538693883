import { forwardRef} from 'react'
import { Button } from '@mui/material'

const ButtonPrimary = forwardRef(({ type = 'submit', text = 'Guardar', handleClick, disabled = false }, ref) => {
  return (
    <Button
      ref={ref}
      type={type}
      onClick={handleClick}
      variant='contained'
      sx={{
        color: 'white',
        backgroundColor: 'var(--bs-primary)',
        '&:hover': {
          backgroundColor: 'var(--bs-primary-light)',
        },
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  )
})

export default ButtonPrimary