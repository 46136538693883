import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { FormGroup, Input, Error, FormButton } from 'components'

import { useHealthInsurances } from 'hooks'
import { createInitialValues, createSchema } from './constants'

import routes from 'app/routing/routes'

const AddHealthInsurance = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { handleCreateHealthInsurance } = useHealthInsurances()

  const intl = useIntl()

  const createHealthInsurance = async (values) => {
    setLoading(true);

    try {
      const response = await handleCreateHealthInsurance(values, 'token')
      toast.success("Registro creado correctamente", {theme:"colored"})
      navigate(routes.HEALTH_INSURANCES)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <DetailContainer>
        <Formik
          initialValues={createInitialValues}
          validationSchema={createSchema}
          onSubmit={createHealthInsurance}
          enableReinitialize
        >
          {({ errors, touched }) => (
            <Form className="d-flex flex-column">
              <FormGroup
                styles={errors.name && touched.name ? 'required' : ''}
                htmlFor='name'
                label={intl.formatMessage({ id: 'COLUMN.HEALTH_INSURANCES.NAME' })}
              >
                <Input id='name' name='name' placeholder='Ingresar nombre' required />
                <ErrorMessage className="text-danger" name="name" render={msg => <Error message={msg} />} />
              </FormGroup>

              <FormButton loading={loading} />
            </Form>
          )}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddHealthInsurance