import { useState, useCallback } from 'react'

import { VatConditions } from 'services'

const useVatConditions = () => {
  const [loading, setLoading] = useState(false)

  const handleGetVatConditions = useCallback(async () => {
    try {
      setLoading(true)
      const response = await VatConditions.getVatConditions()

      return response
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, []);

  return {
    loading,
    handleGetVatConditions,
  };
};

export default useVatConditions