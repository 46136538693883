import React from 'react'
import { MenuItem as MUIMenuItem, ListItemIcon, ListItemText } from '@mui/material'

const MenuItem = ({ handleClick, icon, text, disabled = false }) => {
  return (
    <MUIMenuItem onClick={handleClick} disabled={disabled}>
      <ListItemIcon>
        <i className={`${icon} fs-3`} style={{ cursor: 'pointer' }} />
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MUIMenuItem>
  )
}

export default MenuItem
