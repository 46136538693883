import * as Yup from 'yup'
import { UpdateHealthInsuranceI } from "interfaces"

const updateInitialValues: UpdateHealthInsuranceI = {
  id: 0,
  name: '',
}

const updateSchema = Yup.object().shape({
  id: Yup.number().required('Campo requerido'),
  name: Yup.string().required('Campo requerido'),
})

export {
  updateInitialValues,
  updateSchema
}