import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useIncomeOrder } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

// import Filter from './Filter'
import useGetColumns from './columns'

import routes from 'app/routing/routes'

const initialObQuery = {
  pageNumber: "1",
  pageSize: "10",
  anmat_traced_errors: true,
}

const ANCHO_MENU_CONTEXTUAL = 200;

const IncomeErrors = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()

  const [filter, setFilter] = useState('')
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetIncomeOrderDetailsWithErrors, handleIncomeOrderDetailManualTraced } = useIncomeOrder()

  const [showContextMenu, setShowContextMenu] = useState(false)
  const [contextMenuX, setContextMenuX] = useState(0);
  const [contextMenuY, setContextMenuY] = useState(0);
  const threeDotsRef = useRef(null)
  const [currentRow, setCurrentRow] = useState(null)

  const handleContextMenu = (event, row) => {
    setCurrentRow(row)

    if (threeDotsRef.current) {
      const tableRect = threeDotsRef.current.getBoundingClientRect()
      event.preventDefault()

      setContextMenuX(event.nativeEvent.x - ANCHO_MENU_CONTEXTUAL)
      setContextMenuY(event.nativeEvent.y)
      setShowContextMenu(true)
    }
  }

  const handleCloseContextMenu = () => {
    setShowContextMenu(false)
  }

  const goToIncome = () => {
    if (currentRow) {
      navigate(`${routes.LOGISTIC_INCOME}/${currentRow.in_order_id}`, {
        state: {
          in_request_id: currentRow.in_order_in_request_id,
        }
      })
    }
  }

  const getRequests = useCallback(async () => {
    try {
      if (queryMD && queryMD.pageNumber) {
        const query = { filter, queryMD }
        const response = await handleGetIncomeOrderDetailsWithErrors(query)

        if (response.data.result) {
          setData(response.data.result)
          setTotalCount(response.data.metadata.count)
        }
      }
    } catch (error) {
      setData([])
      toast.error(error.message)
    }
  }, [handleGetIncomeOrderDetailsWithErrors, queryMD, filter])

  const manualTraced = async (in_order_id, in_order_detail_id) => {
    try {
      const response = await handleIncomeOrderDetailManualTraced(in_order_id, in_order_detail_id)

      if (response) {
        toast.success("Se trazó manualmente el producto", { theme: "colored" })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getRequests()
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <>
          <button className='btn btn-icon' type='button'>
            <i ref={threeDotsRef} className='bi bi-three-dots-vertical' style={{ fontSize: '1.5rem', }} onClick={(e) => handleContextMenu(e, row)} />
          </button>
        </>
      )
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD ,
    setObQuery:setQueryMD
  };

  useEffect(() => {
    getRequests()
  }, [getRequests, filter, queryMD])

  return (
    <>
      <TableContainer title='Ingresos con errores'>
        <CustomTable columns={columns} data={data}  paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>

      {showContextMenu && (
          <Dropdown
            style={{ position: 'absolute', top: contextMenuY, left: contextMenuX}}
            show={showContextMenu}
            onToggle={handleCloseContextMenu}
          >
            <Dropdown.Menu style={{ width: ANCHO_MENU_CONTEXTUAL}} >
              <Dropdown.Item onClick={goToIncome}>
                <span>
                  <i className='bi bi-eye text-primary fs-3 pe-3' style={{ fontSize: "1.25rem", verticalAlign: "middle" }} />
                  Ver
                </span>
              </Dropdown.Item>

              {currentRow.anmat_traced_error && !currentRow.manual_traced_at &&
                <Dropdown.Item onClick={() => manualTraced(currentRow.in_order_id, currentRow.id)}>
                  <span>
                    <i className="bi bi-check-circle text-primary fs-3 pe-3" style={{ fontSize: "1.25rem", verticalAlign: "middle" }} />
                    Marcar como trazado
                  </span>
                </Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
      )}
    </>
  )
}

export default IncomeErrors