import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { AppBar, Tab, Tabs, Typography } from '@mui/material';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';

import GeneralForm from '../../Add/partials/GeneralForm';
import MedicamentoForm from '../../Add/partials/MedicamentoForm';
import ImpuestosForm from '../../Add/partials/ImpuestosForm';
import ComercialForm from '../../Add/partials/ComercialForm';

import '../../articles.css'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

const TabProductVar = () => {
  const { setFieldValue, values } = useFormikContext();
  const [showTabMedicamento, setShowTabMedicamento] = useState(true)
  const [currentTab, setCurrentTab] = useState("general")

  useEffect(() => {
    if (values.product_type && values.product_type.value != 1) {
      setCurrentTab("general")
      setShowTabMedicamento(false)
    } else {
      setShowTabMedicamento(true)
    }
  }, [values.product_type])

  return (
    <>
      <div className="" style={{ minHeight: "400px" }}>
        <AppBar elevation={0} position="static" color="default" className="noShadow">
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            className="noShadow"
          >
            <Tab value="general" label="General" icon={<AssignmentOutlinedIcon />} />
            <Tab value="impuestos" label="Impuestos" icon={<ManageSearchOutlinedIcon />}  />
            <Tab value="comercial" label="Comercial" icon={<MonetizationOnOutlinedIcon />}  />
            {showTabMedicamento &&
              <Tab value="medicamento" label="Medicamento" icon={<MedicationOutlinedIcon />}  />
            }
          </Tabs>
        </AppBar>

        {currentTab === "general" && <TabContainer><GeneralForm /></TabContainer>}
        {currentTab === "impuestos" && <TabContainer><ImpuestosForm /></TabContainer>}
        {currentTab === "comercial" && <TabContainer><ComercialForm /></TabContainer>}
        {currentTab === "medicamento" && showTabMedicamento && <TabContainer><MedicamentoForm /></TabContainer>}
      </div>
    </>
  )
}

export default TabProductVar
