import { forwardRef, useEffect, useRef, useState } from "react";
import './styles.css'

const ContextMenu = forwardRef (({ showContextMenu, setShowContextMenu, currentPos, actions }, ref) => {

    const [clase, setClase] = useState('')
    const menuRef = useRef(null);
    const [dropdownStyle, setDropdownStyle] = useState({
        position: 'absolute',
        left: '0',
        top: '0',})

    useEffect(()=>{
        if(showContextMenu){
            setClase('active')
        }
        else{
            setClase('')
        }
    }, [showContextMenu])

    useEffect(()=>{
        if(currentPos){
            setDropdownStyle ({
                position: 'absolute',
                left: `${currentPos.x<350 ? currentPos.x : currentPos.x - 230}px`,
                // top: `${currentPos.y}px`,
                top: `${currentPos.y>700?currentPos.y-menuRef.current.clientHeight:currentPos.y}px`,
            });
        }
    }, [currentPos])

    return (
        <>        
            <div ref={ref}>
                <div style={{position:"fixed", left:"0", top:"0"}}>
                    <div className={`dropdown-context ${clase}`}  style={dropdownStyle} data-dropdown-context>
                        <div className="dropdown-context-menu information-grid">
                            <div className="dropdown-context-links"  ref={menuRef}>
                                {
                                    actions.map((data, index)=>(
                                            <span key={index} className="dropdown-links" 
                                                onClick={()=>{
                                                    setShowContextMenu(false); 
                                                    data.action()
                                                    }}>
                                                <i className={`${data.icon} pe-3`}></i>
                                                    {data.label}
                                            </span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>   
                </div>     
            </div>
        </>
    )
})

export default ContextMenu;
