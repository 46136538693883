import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import Select from 'react-select';
import { FormLabel } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify'

import { useCustomerSuppliers, useBusinessUnits, useRequest } from 'hooks'

import { Input, SelectField, Textarea, SwitchField } from 'components'
import ContextMenu from 'components/ContextMenu'

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '38px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};

const GeneralTab = ({ income, editEnable, loading }) => {
  const { values, setFieldValue } = useFormikContext()
  const { handleGetCustomerSuppliers, handleGetDistributor } = useCustomerSuppliers()
  const [suppliers, setSuppliers] = useState([])
  const [distributors, setDistributors] = useState([])

  const { handleGetBusinessUnits } = useBusinessUnits()
  const [businessUnits, setBusinessUnits] = useState([])

  const { handleGetPaymentConditions } = useRequest()
  const [paymentOptions, setPaymentOptions] = useState([])

  const [showContextMenu, setShowContextMenu] = useState(false)
  const[xPos, setXPos] = useState(0)
  const[yPos, setYPos] = useState(0)

  const loadSuppliers = async()=>{
    const data = await handleGetCustomerSuppliers()

    if (data && data.data &&data.data.result) {
      setSuppliers(data.data.result
        .filter(p => Boolean(p.is_supplier))
        .map(p => ({ ...p, label: p.business_name, value: p.id }))
      )
    }
  }

  const loadDistributor = async () => {
    if (values?.supplier_id) {
      try {
        const response = await handleGetDistributor(values.supplier_id)
        const data = response.data.result

        if (data && data.length > 0) {
          setDistributors([
            ...data.map(d => ({ ...d, label: d.customer_supplier_business_name, value: d.customer_supplier_id }))
          ])
        }
        setFieldValue('distributor_id_aux', values.distributor_id)

      } catch (error) {
        setFieldValue('distributor_id_aux', -1)
        toast.error(error.message)
      }
    } else {
      setFieldValue('distributor_id_aux', -1)
      setDistributors([])
    }
  }

  const loadBusinessUnits = async () => {
    const response = await handleGetBusinessUnits()

    if (response.data && response.data.result) {
      setBusinessUnits(response.data.result
        .map(i => ({ value: i.id, label: i.name }))
      )
    }
  }

  const loadPaymentConditions = async()=>{
    let params = {
      // is_enabled: 1,
    }
    const data = await handleGetPaymentConditions(params);
    if(data && data.result)
      setPaymentOptions(data.result)
  }

  const toggleContext = (e) => {
      if (e) {
        e.preventDefault()
      }

      setXPos(e.clientX)
      setYPos(e.clientY)

      const _actual = showContextMenu
      setShowContextMenu(!_actual)
  }

  const handleSupplierChange = async (selectedSupplier) => {
    try {
      setFieldValue('payment_condition_id', selectedSupplier.payment_condition_id)

      const response = await handleGetDistributor(selectedSupplier.value)
      if (response.data.result && response.data.result.length > 0) {
        setDistributors([
          ...response.data.result.map(d => ({ ...d, label: d.customer_supplier_business_name, value: d.customer_supplier_id }))
        ])
        if(values.con_distribuidor==true){
          setFieldValue('distributor_id_aux', values.distributor_id)
        }
        else{
          setFieldValue('distributor_id_aux', -1)
        }
      } else {
        setDistributors([])
        setFieldValue('distributor_id_aux', -1)
      }
    } catch (error) {
        setDistributors([])
        setFieldValue('distributor_id_aux', -1)
        toast.error(error.message)
    }
  }

  const getValue = () => {
      return distributors.find(option => option.value === values.distributor_id_aux) || null;
  };

  const onChange = (option) => {
    setFieldValue('distributor_id_aux', option.value)
  };


  useEffect(()=>{
      if(values.con_distribuidor==false){
          setFieldValue('distributor_id_aux', -1)
      }
  },[ values.con_distribuidor])

  useEffect(() => {
    loadSuppliers()
    loadDistributor()
    loadBusinessUnits()
    loadPaymentConditions()
  }, [values.id])

  return (
    <>
      <div className='row'>
        <div className='col mb-4 text-end'>
          {editEnable &&
            <Button variant="primary" type='submit' disabled={loading}>
              <i className="bi bi-save me-2" />
              Guardar
            </Button>
          }
        </div>
      </div>

      {
        (values?.order_status_id === 12 || values?.order_status_id === 5) && // Rechazado o cancelado
        <>
          <p>Pedido {values.order_status_id === 12 ? 'con autorización rechazada' : 'cancelado'} por <strong>{values.revocation_user_name}</strong></p>
          <p>Fecha <strong>{values.revocation_date ? moment(values.revocation_date).format("DD/MM/YYYY HH:mm") : ""}</strong></p>
          <p>Observaciones: <strong>{values.revocation_notes}</strong></p>
        </>
      }

      <div className='row mb-5'>

        <div className='col-6'>
          <SelectField
            label={'Proveedor'}
            name="supplier_id"
            options={suppliers.map(p => ({ ...p, label: p.business_name, value: p.id }))}
            isMulti={false}
            marginTop='3px'
            placeholder='Seleccionar'
            handleChange={handleSupplierChange}
            disabled={!editEnable}
          />
        </div>

        <div className="col-3  d-flex justify-content-end align-items-center" style={{paddingTop:"27px"}}>
            <div className="d-flex">
              <SwitchField  id='con_distribuidor' name='con_distribuidor'/> <span style={{ fontSize: '1.1rem' }}>Con distribuidor</span>
            </div>
        </div>

        <div className='col-3 pt-2'>
          <FormLabel style={labelStyle}>Distribuidor</FormLabel>
            <Select
              options={distributors}
              value={getValue()}
              onChange={onChange}
              styles={customStyles}
              isDisabled={!editEnable || !values.con_distribuidor}
              placeholder='Seleccionar'
            />
          {/* <SelectField
            label={'Distribuidor'}
            name="distributor_id"
            options={distributors}
            marginTop='3px'
            isMulti={false}
            // defaultValue={distributors.length > 0 ? distributors[0] : []}
            placeholder='' //'Seleccione el distribuidor'
            disabled={!editEnable || !values.con_distribuidor}
          /> */}
        </div>


      </div>

      <div className='row mb-5'>
        <div className="col-3">
          <label htmlFor='issue_date' className='form-label'>Fecha comprobante</label>
          <Input id='issue_date' name='issue_date' type='date' disabled />
        </div>
        <div className="col-3">
          <label htmlFor='estimated_in_date' className='form-label'>Fecha estimada de ingreso</label>
          <Input id='estimated_in_date' name='estimated_in_date' type='date' disabled={!editEnable}/>
        </div>
        <div className='col-3'>
          <SelectField
            isMulti={false}
            label={'Unidad de negocio'}
            name="business_unit_id"
            options={businessUnits}
            marginTop='3px'
            placeholder='Seleccione la unidad de negocio'
            disabled={!editEnable}
          />
        </div>

        <div className='col-3'>
          {!income &&
            <SelectField
              label={'Condición de pago'}
              name="payment_condition_id"
              options={paymentOptions.map(p => ({ ...p, label: p.label, value: p.id }))}
              isMulti={false}
              marginTop='3px'
              disabled={!editEnable}
            />
          }
        </div>
      </div>

      <div className='row mb-5'  onContextMenu={toggleContext}>
        <div className="col">
          <label htmlFor='notes' className='form-label'>Observaciones</label>
          <Textarea id='notes' name='notes' disabled={!editEnable}/>
        </div>
        <div className="col">
          <label htmlFor='internal_notes' className='form-label'>Notas internas</label>
          <Textarea id='internal_notes' name='internal_notes' disabled={!editEnable}/>
        </div>
      </div>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={[
          { label: "Guardar", icon: "bi bi-save", action: () => { console.log("Guardar") } },
          { label: "Editar", icon: "bi bi-pencil", action: () => { console.log("Editar") } }
        ]}
        x_pos={xPos}
        y_pos={yPos}
      />
    </>
  )
}

export default GeneralTab