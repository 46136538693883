import { useState, useEffect, useCallback } from 'react'

import { useRequest } from '.'



const useGetRequestDetail = (id = null, isNew = false) => {
  const { loading, handleGetRequest } = useRequest();
  const [request, setRequest] = useState(null);

  const refreshRequest = useCallback(async () => {
    try {
      const response = await handleGetRequest(id);
      setRequest(response.result[0]);
    } catch (error) {
      throw error;
    }
  }, [handleGetRequest, id]);

  const getDetail = async () => {
    if (!isNew) {
      await refreshRequest();
    }
  }

  useEffect(() => {
    getDetail();
  }, [isNew, refreshRequest, handleGetRequest ]);

  return {
    loading,
    request,
    refreshRequest,
    getDetail
  }
};

export default useGetRequestDetail;