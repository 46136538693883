import service from "../service"

const PATH = {
  priceUpdate: "/price-update",
};

export class PriceUpdate {
  static getBillingTypes = async (params) => {
    const response = await service.get(PATH.priceUpdate, {
      params,
    })

    return response
  }
}