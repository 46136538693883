import service from "services/service";

const PATH = {
  documentTypes: "/document-types",
};

export class DocumentTypes {
  static getDocumentTypes = async (token, params) => {
    const response = await service.get(PATH.documentTypes, {
      params
    });

    return response;
  };

  static getDocumentType = async (id, token) => {
    const response = await service.get(`${PATH.documentTypes}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static createDocumentType = async (data, token) => {
    const response = await service.post(PATH.documentTypes, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static updateDocumentType = async (id, data, token) => {
    const response = await service.patch(`${PATH.documentTypes}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static deleteDocumentType = async (id, token) => {
    const response = await service.delete(`${PATH.documentTypes}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };
}