import React from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useOutOrders } from 'hooks/Sales'
import { Input} from 'components'
import { Button } from 'react-bootstrap'

import { OutOrderStatusI } from 'interfaces'

const Filter = ({ handleData, query, handleQuery, handleTotalCount }) => {
  const { handleGetOutOrders } = useOutOrders()

  const filterData = async (values) => {
    let params = {
      ...query,
      ...values,
      id_text: values?.id_text ? values.id_text : undefined,
      text: values?.text ? values.text : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      from_date: values?.from_date ? values.from_date : undefined,
      to_date: values?.to_date ? values.to_date : undefined,
      getDetail: true,
      out_order_status_id: OutOrderStatusI.EN_REVISION,
    }

    try {
      const response = await handleGetOutOrders(params)

      handleQuery({ ...params, pageNumber: 1, pageSize: 10 })
      handleData(response.data.result)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      toast.error(error.message)
    }
  }

  return (
    <Formik
      initialValues={{
        id_text: '',
        text: '',
        article_text: '',
        from_date: '',
        to_date: '',
      }}
      onSubmit={filterData}
    >
      {({ values, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' /><p className='text-muted'>Nro. / ID Pedido</p>
            </div>

            <div className='col-6'>
              <Input id='text' name='text' /><p className='text-muted'>Cliente / CUIT / Dirección</p>
            </div>

            <div className='col-2'>
              <Input id='from_date' name='from_date' type='date' /><p className='text-muted'>Desde</p>
            </div>

            <div className='col-2'>
              <Input id='to_date' name='to_date' type='date' /><p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <Input id='article_text' name='article_text' /><p className='text-muted'>Artículo / Droga</p>
            </div>

            <div className='col-4 text-end'>
              <Button  variant="primary" type='submit'><i className="bi bi-search mb-1"></i>Buscar</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter