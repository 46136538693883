import service from "../service";

const PATH = {
  Depots: "/depots",
  Movements: "/stock/depot-movement",
  UpdateStock: '/product/stock/detail/actualizar-lote',
};

const fetchMovements = async () => {
  const dataFromBackend = [
    { 
      id: 1, 
      created_at: '2023-12-20 10:00:00', 
      depot_movement_type:'General', 
      storage_space_origin:{name:'Space A', id:1}, 
      storage_space_dest:{name:'Space B', id:2} ,
      created_by_user:'Pepe'
    }
  ];

  return Promise.resolve({
    status: 200,
    data: {result:dataFromBackend, metadata:{count:1}},
    headers: {},
    config: {},
    request: {
      responseURL: '',
    },
  });
};


export class Depots {

  static getDepots = async (params) => {
    const response = await service.get(PATH.Depots + '/detail' ); //service.get(PATH.Depots + params );
    return response;
  };

  static getDepot = async (id) => {
    const response = await service.get(`${PATH.Depots}/${id}`);
    return response;
  };

  static searchDepots = async (params) => {
    const response = await service.get(PATH.Depots, {params});
    return response;
  };
  static getWarehouses = async () => {
    const response = await service.get(`${PATH.Depots}/warehouse`);
    return response;
  };
  static getStorageSpaces = async (customer_supplier_id) => {
    const response = await service.get(`${PATH.Depots}/storage-space?customer_supplier_id=${customer_supplier_id}`);
    return response;
  };
    
  static getStorageOwners = async () => {
    const response = await service.get(`${PATH.Depots}/storage-space/owner`);
    return response;
  };
    
  static getStorageBusinessUnits = async () => {
    const response = await service.get(`${PATH.Depots}/storage-space`);
    return response;
  };
    
  static getStorageStatuses = async () => {
    const response = await service.get(`${PATH.Depots}/inventory-status`);
    return response;
  }

  static getInternalMovements = async (params) => {
    const response = await fetchMovements();// service.get(PATH.Movements + params);
    return response;
  }

  static getInternalMovement = async (id) => {
    const response = await service.get(`${PATH.Movements}/${id}`);
    return response;
  }

  static updateLotNumberAndExpirationFromStock = async (data) => {
    const response = await service.post(PATH.UpdateStock, data)
    return response
  }
}