import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'
import { insertLineBreaks } from 'utilities'

const COLUMNS = [
  {
    Header: 'Nro.',
    id: 'id',
    accessor: (row) => (
      <Tooltip text='Ir a detalle'>
        <Link to={`${routes.WAVES}/${row.id}`}>
          {row.id}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
  },
  {
    Header: 'Nombre',
    accessor: 'name',
  },
  {
    Header: <div className='text-center'>F. creación</div>,
    id: 'created_at',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.created_at).format('DD/MM/YYYY')}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>Usuario</div>,
    id: 'user_name',
    accessor: (row) => (
      <div className='text-center'>
        {row.user_name}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Remitidas</div>,
    id: 'waybill_count',
    accessor: (row) => (
      <div className='text-center'>
        {row.waybill_count}

        {row?.waybill_ids &&
          <Tooltip placement='right' text={insertLineBreaks(row.waybill_ids?.replaceAll(',', ', '), 5)} maxWidth='300px'>
            <i className='bi bi-info-circle text-warning ms-2' />
          </Tooltip>
        }
      </div>
    )
  },
  {
    Header: <div className='text-center'>Pendientes</div>,
    id: 'pending_count',
    accessor: (row) => (
      <div className='text-center'>
        {row.pending_count}

        {row?.pending_ids &&
          <Tooltip placement='right' text={insertLineBreaks(row.pending_ids?.replaceAll(',', ', '), 5)} maxWidth='300px'>
            <i className='bi bi-info-circle text-warning ms-2' />
          </Tooltip>
        }
      </div>
    )
  },
  {
    Header: <div className="text-center">Estado</div>,
    id: "wave_status_label",
    className:'col-status',
    accessor: (row) =>(
      <span className={ `badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.wave_status_label}
      </span>
    )
  },
  {
    Header: "",
    id: 'icons',
    accessor: (row) => (
      <div className='d-flex'>
        {Boolean(row.refrigeratedItems) && <Tooltip text='Requiere frío'><span className='bi bi-snow text-primary fs-3 me-2' /></Tooltip>}
        {Boolean(row.hasTraced) && <Tooltip text='Artículos trazables'><span className='bi bi-broadcast-pin text-primary fs-3 me-2' /></Tooltip>}
      </div>
    )
  }
]

export {
  COLUMNS
}