const currencyFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })

const formatTableDate = (date) => {
  if (!date) {
    return ''
  }

  // return new Date(date.replace('T', ' ').replace('Z', '')).toLocaleDateString('es-AR', { year: 'numeric', month: 'long', day: 'numeric' })
  return new Date(date.replace('T', ' ').replace('Z', '')).toLocaleDateString('es-AR', { year: 'numeric', month: 'numeric', day: 'numeric' })
}

const dataToTableData = (data, valueName) => {
  return data.map((item) => {
    return {
      id: item.id,
      value: item[valueName]
    }
  })
}

const formatInputDate = (date) => {
  if (!date) {
    return ''
  }

  return new Date(date).toISOString().split('T')[0]
}

const currentDate = new Date().toISOString().split('T')[0]

const replaceNullsWithEmptyString = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    } else if (typeof obj[key] === 'object') {
      replaceNullsWithEmptyString(obj[key]);
    }
  }

  return obj;
}

const decimalFormatter = new Intl.NumberFormat('es-AR', {
  style: 'decimal'
})


/**
   * 
   * @param {*} purchase_discount_extra Bonificacion
   * @param {*} purchase_add_cost Costo asociado
   * @param {*} actual_pvp 
   * @param {*} purchase_discount_base 
   * @returns 
   */
const getListCost = (purchase_discount_extra, purchase_add_cost, actual_pvp, purchase_discount_base) => {
  const _purchase_discount_extra = +purchase_discount_extra;
  const _purchase_add_cost = +purchase_add_cost;
  const _actual_pvp = +actual_pvp;
  const _purchase_discount_base = +purchase_discount_base;

  const _psl = getPSL(_actual_pvp, _purchase_discount_base);
  const _psl_discount = _psl * _purchase_discount_extra /100;
  const _iva = getIVA();
  const _psl_siniva = _psl - (_psl * _iva /100);
  const _alc = ((_psl - _psl_discount) * 1.005) + (_psl_siniva * (_purchase_add_cost) /100) ;
  return parseFloat( _alc.toFixed(2) );
  
}

const getPSL = (actual_pvp, purchase_discount_base) => {

  const _actual_pvp = +actual_pvp;
  const _purchase_discount_base = +purchase_discount_base;

  const _psl = _actual_pvp - (_actual_pvp * _purchase_discount_base /100);
  return parseFloat( parseFloat(_psl).toFixed(2) );
}

const getInitialListCost = (actual_pvp, purchase_discount_base) => {

  const _actual_pvp = +actual_pvp;
  const _purchase_discount_base = +purchase_discount_base;

  const _psl = _actual_pvp - (_actual_pvp * _purchase_discount_base /100);
  return parseFloat( parseFloat(_psl).toFixed(2) );
}

const getIVA = () => {
  return 21;
}

const insertLineBreaks = (string, commasPerLine) => {
  let count = 0;
  let result = '';

  for (let i = 0; i < string.length; i++) {
    if (string[i] === ',') {
      count++;
    }

    result += string[i];

    if (count === commasPerLine) {
      result += '\n\n\n';  // Insertar un salto de línea
      count = 0;
    }
  }

  return result;
}

export {
  currencyFormat,
  formatTableDate,
  dataToTableData,
  formatInputDate,
  currentDate,
  replaceNullsWithEmptyString,
  decimalFormatter,
  getPSL,
  getListCost,
  getInitialListCost,
  insertLineBreaks,
}