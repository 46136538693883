import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'

import { useClickOutside, useOutOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { OutOrdersColumns } from './'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const OutOrdersTab = () => {
  const navigate = useNavigate()
  const { values } = useFormikContext();

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetOutOrders } = useOutOrders()
  const [outOrders, setOutOrders] = useState([])

  const { COLUMNS } = OutOrdersColumns()

  const getOutOrders = useCallback(async () => {
    try {
      let params = {
        sales_order_id: values.id
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetOutOrders(params)
      setOutOrders(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setOutOrders([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetOutOrders, values.id, query])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Ver',
      icon: 'bi bi-eye text-primary',
      action: () => navigate(`${routes.OUT_ORDERS}/${currentRow?.id}`)
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getOutOrders()
  }, [getOutOrders])

  return (
    <>
      <div className='mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8'>
          <label className='form-label m-0'>Órdenes de egreso</label>
        </div>

        <TableContainer>
          <CustomTable columns={columns} data={outOrders || []} paginationOptions={paginationOptions} />
        </TableContainer>

        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
          ref={contextRef}
        />
      </div>
    </>
  )
}

export default OutOrdersTab
