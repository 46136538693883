import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Tooltip text='Editar obra social'>
          <Link to={`${routes.HEALTH_INSURANCES}/${row.id}`}>
            {row.id}
          </Link>
        </Tooltip>
      ),
      hasOrder: true
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.HEALTH_INSURANCES.NAME' }),
      accessor: 'name'
    },
    {
      Header: <div className='text-center'>Habilitada</div>,
      id: 'is_enabled',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge ${Boolean(row.is_enabled) ? "badge-success" : "badge-danger"}`}>
            {Boolean(row.is_enabled)
              ? intl.formatMessage({ id: 'OPTION.YES' })
              : intl.formatMessage({ id: 'OPTION.NO' })
            }
          </span>
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}