import { FC } from 'react'
import { Field } from 'formik'
import { TextareaI } from './types'

const Textarea: FC<TextareaI> = ({
  id,
  name,
  required = false,
  disabled = false,
}) => {
  return (
    <Field
      id={id}
      name={name ? name : id}
      as="textarea"
      className='form-control'
      required={required}
      disabled={disabled}
    />
  )
}

export default Textarea