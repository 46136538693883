import React from 'react'
import { formatTableDate } from 'utilities'

const FilesColumns = () => {
  const COLUMNS = [
    {
      Header: 'Tipo',
      id: 'type',
      accessor: (row) => (
        <>
          <span className={`${row.document_type_icon} me-2`} />{row.document_type_name}
        </>
      ),
    },
    {
      Header: 'Documento',
      id: 'document',
      accessor: (row) => (
        <>
          {row.riga_file_asset_name}
        </>
      ),
    },
    {
      Header: <div className='text-center'>Fecha de vencimiento</div>,
      id: 'expiration_date',
      accessor: (row) => (
        <div className='text-center'>
          {row?.expiration_date ? formatTableDate(row.expiration_date) : '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Fecha de carga</div>,
      id: 'created_at',
      accessor: (row) => (
        <div className='text-center'>
          {formatTableDate(row.riga_file_asset_created_at)}
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}

export default FilesColumns
