import * as Yup from 'yup'

const initialValues = {
  id: '',
  is_customer: 0,
  is_supplier: 0,
  business_name: '',
  documentation_type_id: '',
  documentation_type_name: '',
  vat_condition_label: '',
  province_id: '',
  has_multilateral_agreement: false,
  multilateral_agreement: [],
}

const multilateralAgreement = {
  province_id: '',
  date_from: '',
  date_to: '',
  period: '',
  coefficient: 0.0000,
}

const multilateralAgreementSchema = Yup.object().shape({
  province_id: Yup.string().required('* Seleccione una provincia'),
  date_from: Yup.string().required('* Indique la fecha de inicio'),
  date_to: Yup.string().nullable(),
  period: Yup.string()
    .matches(/^[0-9]+$/, '* Indique un número válido para el período')
    .required('* Indique el período'),
  coefficient: Yup.number().required('* Indique el coeficiente'),
})

const dataAdapter = (data) => {
  let values = {
    ...data,
    is_customer: Boolean(data.is_customer),
    is_supplier: Boolean(data.is_supplier),
    province_id: data?.province_id ? data.province_id : '',
    has_multilateral_agreement: Boolean(data.has_multilateral_agreement),
  }

  return values
}

export {
  initialValues,
  multilateralAgreement,
  multilateralAgreementSchema,
  dataAdapter,
}