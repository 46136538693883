import service from 'services/service'

const PATH = {
  sellers: "/sellers",
}

export class Sellers {
  static getSellers = async (token, query, params) => {
    const response = await service.get(`${PATH.sellers}${query}`, {
      params,
    })
    return response
  }

  static getSeller = async (id, token) => {
    const response = await service.get(`${PATH.sellers}/${id}`)
    return response
  }

  static createSeller = async (data, token) => {
    const response = await service.post(PATH.sellers, data)
    return response
  }

  static updateSeller = async (id, data, token) => {
    const response = await service.patch(`${PATH.sellers}/${id}`, data)
    return response
  }

  static deleteSeller = async (id, token) => {
    const response = await service.delete(`${PATH.sellers}/${id}`)
    return response
  }
}