import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'

import useTransactionalDocuments from 'hooks/useTransactionalDocuments'
import { Input, SelectField, SwitchField } from 'components'

import { TransactionalDocumentTypeI } from 'interfaces'

/*
  (1, 'Factura A'),
  (2, 'Nota de Débito A'),
  (3, 'Nota de Crédito A'),
  (6, 'Factura B'),
  (7, 'Nota de Débito B'),
  (8, 'Nota de Crédito B'),
  (51, 'Factura M. No habilitado para CAEA'),
  (52, 'Nota de Débito M. No habilitado para CAEA'),
  (53, 'Nota de Crédito M. No habilitado para CAEA'),
  (201, 'Factura de Crédito Electrónica MiPyMEs (FCE) A'),
  (202, 'Nota de Débito Electrónica MiPyMEs (FCE) A'),
  (203, 'Nota de Crédito Electrónica MiPyMEs (FCE) A'),
  (206, 'Factura de Crédito Electrónica MiPyMEs (FCE) B'),
  (207, 'Nota de Débito Electrónica MiPyMEs (FCE) B'),
  (208, 'Nota de Crédito Electrónica MiPyMEs (FCE) B'),
  (91, 'Remito (sólo para comprobantes asociados)'),
  (995, 'Remito Electrónico de Carne (sólo para comprobantes asociados)'),
  (88, 'Remito Electrónico de Tabaco Acondicionado (sólo para comprobantes asociados)'),
  (990, 'Remito Electrónico de Tabaco en Hebras (sólo para comprobantes asociados)'),
  (997, 'Remito Electrónico de Azúcar para Mercado Interno (sólo para comprobantes asociados)'),
  (993, 'Remito Electrónico de Harina en Camión (sólo para comprobantes asociados)'),
  (994, 'Remito Electrónico de Harina en Tren (sólo para comprobantes asociados)')
*/
const voucherTypes = [
  { value: 1, label: "Factura" },
  { value: 2, label: "Nota de Débito" },
  { value: 3, label: "Nota de Crédito" },
  { value: 4, label: "Remito" },
]

const letters = [
  { value: '', label: 'Seleccionar' },
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
]

const lettersRemito = [
  { value: '', label: 'Seleccionar' },
  { value: 'R', label: 'R' },
  { value: 'X', label: 'X' },
]

const punto_venta = [
  { value: '', label: ''},
]

const status = [
  { value: 'SUCCESS', label: 'Aprobado', },
  { value: 'SUCCESS_WITH_OBSERVATIONS', label: 'Aprobado c/ observaciones', },
  { value: 'ERROR_NEGOCIO_AFIP', label: 'Error AFIP', },
]

const statusDisplay = (status) => {
  if (!status) {
    return ""
  }
  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "Aprobado c/observaciones";
    case "SUCCESS":
      return "Aprobado";
    case "ERROR_NEGOCIO_AFIP":
      return "Error AFIP";
    default:
      return '';
  }
}

const initialValues = {
    text: '',
    date_from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
    status:[], //SUCCESS,SUCCESS_WITH_OBSERVATIONS&
    with_nc:0, //=0&
    transactional_document_type_id:[1], //1,2,3,4&
    punto_venta:'', //1&
    sales_order_id:'' //225
}

const Filter = ({ setData, setFilter, queryMD, setQueryMD, exportData  }) => {
  const { handleGetDocuments, handleGetPuntosVenta } = useTransactionalDocuments()
  const [puntosVenta, setPuntosVenta] = useState([])
  

  // const getPuntosVenta = async () => {
  //   try {
  //     const response = await handleGetPuntosVenta()
  //     const pv = response.data.result.map(_pv => ({ value: _pv.punto_venta, label: _pv.punto_venta }))

  //     setPuntosVenta(pv)
  //   } catch (error) {
  //     setPuntosVenta([])
  //     toast.error(error.message)
  //   }
  // }

  const handleChangeType = (ev, setFieldValue)=>{
    if(ev.value!=voucherTypes.filter(v=>v.label=='Factura')[0].value){
      setFieldValue('with_nc', false)
    }
  }
  


  //Nro de pedido, estado, si tiene o no nc asociada, tipo (multiple), fecha (por defecto 7 días para atras hasta hoy), 
  //sacar filtro letra, cambiar punto de venta por un input libre, id, nro, cliente
  //-04-30&punto_venta=1&sales_order_id=225
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {

         let params = {
          ...values,
          date_from: values?.date_from? moment(values.date_from).format("YYYYMMDD") : undefined,
          date_to: values?.date_to? moment(values.date_to).format("YYYYMMDD") : undefined,
          transactional_document_type_id: values?.transactional_document_type_id || undefined,
          sales_order_id: values?.sales_order_id || undefined,
          punto_venta: values?.punto_venta || undefined,
          text: values?.text || undefined,
          status: values?.status || undefined,
          with_nc:values?.with_nc? 1:0
        }

        try {
          // const response = await handleGetDocuments(params)
          setQueryMD({ ...params, pageNumber: 1 })
          // setData(response.data.result)
        } catch (error) {
          toast.error(error.message)
        }
      }}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-3'>
              <Input id='date_from' name='date_from' type='date' />
              <p className='text-muted'>Desde</p>
            </div>

            <div className='col-3'>
              <Input id='date_to' name='date_to' type='date' />
              <p className='text-muted'>Hasta</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='transactional_document_type_id'
                name='transactional_document_type_id'
                options={voucherTypes}
                defaultValue={[1]}
                handleChange={(ev)=>handleChangeType(ev, setFieldValue)}
                marginTop={'-20px'}
                isMulti="true"
              />
              <p className='text-muted'>Tipo</p>
            </div>
            <div className="col-3 pt-3">
                {/* <div className="d-flex">
                  <SwitchField  id='with_nc' name='with_nc' 
                    disabled={values.transactional_document_type_id!=voucherTypes.filter(v=>v.label=='Factura')[0]?.value}/> <span style={{ fontSize: '1.1rem' }}>con NC</span>
                </div> */}
            </div>


            {/* <div className='col-3'>
              <SelectField
                id='punto_venta'
                name='punto_venta'
                options={puntosVenta}
                placeholder='Seleccionar...'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Punto de venta</p>
            </div> */}
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='text' name='text' autoComplete="off" />
              <p className='text-muted'>{`ID / ${values.transactional_document_type_id == TransactionalDocumentTypeI.REMITO ? 'Remito' : 'Factura'} / Cliente`}</p>
            </div>
            <div className='col-2'>
              <Input id='sales_order_id' name='sales_order_id'  autoComplete="off"/>
              <p className='text-muted'>ID Pedido</p>
            </div>
            <div className='col-3'>
              <Input id='punto_venta' name='punto_venta'  autoComplete="off"/>
              <p className='text-muted'>Pto. Venta</p>
            
            </div>
            {values.transactional_document_type_id != TransactionalDocumentTypeI.REMITO &&
              <div className='col-3'>
                <SelectField
                  id='status'
                  name='status'
                  options={status}
                  placeholder='Seleccionar estado'
                  marginTop={'-20px'}
                  isMulti="true"
                />
                <p className='text-muted'>Estado</p>
              </div>
            }
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button variant="secondary" type='button' onClick={() => exportData()} className="me-3">
                <i className="bi bi-file-earmark-excel mb-1"/>
                Exportar
              </Button>

              <Button  variant="primary" type='submit' onClick={()=>{}}>
                <i className="bi bi-search mb-1"/>
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter