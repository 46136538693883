import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { usePatients, useCustomerSuppliers, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './AssociatedCustomersColumns'
import Filter from './AssociatedCustomersFilter'
import { AssociateCustomerModal } from './'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const AssociatedCustomers = () => {
  const { id } = useParams()

  const [modal, setModal] = useState(false)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetPatientAssociatedCustomers } = usePatients()
  const [customers, setCustomers] = useState([])

  const { handleAddCustomerSupplierPatient, handleDeleteCustomerSupplierPatient } = useCustomerSuppliers()

  const getPatientAssociatedCustomers = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetPatientAssociatedCustomers(id, params)
      const data = response.data.result

      setCustomers(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setCustomers([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetPatientAssociatedCustomers, id, query])

  const associateCustomer = async (customer) => {
    try {
      const response = await handleAddCustomerSupplierPatient(customer.id, id)
      toast.success(response.message)
    } catch (error) {
      toast.error(error.mesage)
    } finally {
      getPatientAssociatedCustomers()
    }
  }

  /* Menu contextual */
  const navigate = useNavigate()
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Ver',
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.customer_supplier_id}`)
    })

    actions.push({
      label: 'Desvincular',
      icon: 'bi bi-trash text-danger',
      action: actionDeleteCustomerSupplier
    })

    return actions
  }

  const actionDeleteCustomerSupplier = async () => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere desvincular al paciente del cliente/proveedor #${currentRow.customer_supplier_id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, desvincular`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteCustomerSupplierPatient(currentRow?.customer_supplier_id, id)
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getPatientAssociatedCustomers()
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getPatientAssociatedCustomers()
  }, [getPatientAssociatedCustomers])

  return (
    <>
      <div className='mb-8 d-flex flex-row justify-content-between align-items-center'>
        <h2 className='m-0 p-0'>Clientes asociados</h2>

        <Button type='button' onClick={() => setModal(true)}>
          Vincular cliente
        </Button>
      </div>

      <AssociateCustomerModal
        show={modal}
        onClose={() => setModal(false)}
        onSave={values => {
          console.log(values)
          associateCustomer(values)
          setModal(false)
        }}
      />

      <TableContainer>
        <Filter handleData={setCustomers} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />

        <CustomTable columns={columns} data={customers || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default AssociatedCustomers