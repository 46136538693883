import { useEffect, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

import defaultStyle from './defaultStyle'
import defaultMentionStyle from './defaultMentionStyle'
import './style.css';

const Comments = ({ usersData, handlePostNote = null, notes, setNotes, title = null }) => {
    const handleChange =  (event, newValue, newPlainTextValue, mentions) => {
        setNotes(newValue)
    }

    return (
        <>
            <div className="row">
                <div className={`${handlePostNote ? 'col-10' : 'col-12'}`}>
                    {title && <p className='m-0 p-0'>Observaciones</p>}

                    <MentionsInput value={notes} onChange={handleChange} style={defaultStyle} className="noti">
                        <Mention
                            trigger="@"
                            data={usersData}
                            style={defaultMentionStyle}
                            appendSpaceOnAdd={true}
                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                <div className={`user ${focused ? 'focused' : ''}`}>
                                    {highlightedDisplay}
                                </div>
                            )}
                            markup=	'@[__display__](__id__)'
                        />
                    </MentionsInput>
                </div>

                {handlePostNote &&
                    <div className='col-2'>
                        <button type="button" className="btn btn-primary ms-5" onClick={handlePostNote}>
                            <i className='bi bi-send-fill pe-4' />
                            Enviar
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

export default Comments;