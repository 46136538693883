import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const AvisoAfipModal = ({ show, onHide, data }) => {
    const [showDetail, setShowDetail] = useState(false)

    const toggleDetail = ()=>{
        setShowDetail(!showDetail)
    }
    useEffect(()=>{
        if(show){
            if(data && data.afip_error)
                setShowDetail(false)
            else
                setShowDetail(true)
        }
    }, [show])

    return (
        <Modal show={show} onHide={onHide} backdrop='static' centered>
        <Modal.Header closeButton>
            <Modal.Title>Respuesta enviada por el servicio de AFIP</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{minHeight:"250px"}}>
                {data?.afip_error && <div className="row">
                    <div className="col">
                        <label className='form-label'>La facturacion de este pedido no podrá ser procesada</label>
                        <label className='form-label mt-3'>Avisar a sistemas</label>
                        <br/>
                        <a href="#" style={{marginLeft: "5px"}} onClick={()=>toggleDetail()}> Ver detalle</a>
                    </div>
                </div>}
                <div className="row">
                    <div className="col">
                        {showDetail && <textarea className='form-control w-100' rows={5} disabled>{JSON.stringify(data?.afip_error) || ''}</textarea>}
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={onHide}>Cerrar</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default AvisoAfipModal