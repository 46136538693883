import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useWaves } from 'hooks'

import { Input, SelectField } from 'components'

const optionsWaveStatus = [
  { value: 1, label: "En preparación" },
  { value: 2, label: "Completa" }
]

const initialValues = {
  id: '',
  text: '',
  from_date: '',
  to_date: '',
  wave_status_id_filter: [1]
}

const Filter = ({ setData, query, setQuery, setTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()

  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetWaves } = useWaves()

  const filterData = async (values) => {
    let params = {
      ...query,
      ...values,
      id: values?.id || undefined,
      text: values?.text || undefined,
      from_date: values?.from_date || undefined,
      to_date: values?.to_date || undefined,
      wave_status_id_filter: values?.wave_status_id_filter || undefined,
      getDetail: true,
    }

    try {
      const response = await handleGetWaves(params)

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        waves: { ...params }
      }))

      setQuery({ ...params, pageNumber: 1, pageSize: 10 })
      setData(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      setInitialFormikValues({
        ...initialValues,
        id: currentUser?.waves?.id || '',
        text: currentUser?.waves?.text || '',
        from_date: currentUser?.waves?.from_date || '',
        to_date: currentUser?.waves?.to_date || '',
        wave_status_id_filter: currentUser?.waves?.wave_status_id_filter ? currentUser.waves.wave_status_id_filter : [1],
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => {
        return (
          <Form className='d-flex flex-column'>
            <div className='row'>
              <div className='col-2'>
                <Input id='id' name='id' disabled={loading} />
                <p className='text-muted'>ID</p>
              </div>

              <div className='col-6'>
                <Input id='text' name='text' disabled={loading} />
                <p className='text-muted'>Nombre</p>
              </div>

              <div className='col-2'>
                <Input id='from_date' name='from_date' type='date' disabled={loading} />
                <p className='text-muted'>Desde F. creación</p>
              </div>

              <div className='col-2'>
                <Input id='to_date' name='to_date' type='date' disabled={loading} />
                <p className='text-muted'>Hasta F. creación</p>
              </div>

            </div>
            <div className="row">
              <div className="col-6">
                <SelectField
                  id='wave_status_id_filter'
                  name='wave_status_id_filter'
                  options={optionsWaveStatus || []}
                  placeholder='Seleccionar estado'
                  marginTop={'-20px'}
                  isMulti
                  disabled={loading}
                />
                <p className='text-muted'>Estado</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-end">
                <Button
                  type='button'
                  variant="secondary"
                  onClick={() =>  {
                    resetForm({ values: initialValues })
                    submitForm()
                  }}
                  className='me-3'
                  disabled={loading}
                >
                  <i className="bi bi-zoom-out mb-1" />
                  Limpiar
                </Button>

                <Button type='submit' variant="primary" disabled={loading}>
                  <i className="bi bi-search mb-1" />
                  {loading ? 'Cargando...' : 'Buscar'}
                </Button>
              </div>
            </div>

          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter
