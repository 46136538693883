import service from "services/service"

const PATH = {
  users: "/users",
};

export class Users {
  static getUsers = async (params) => {
    const response = await service.get(PATH.users, {
      params,
    });

    return response;
  };

  static getUser = async (id) => {
    const response = await service.get(`${PATH.users}/${id}`);

    return response;
  };
}