/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {defaultAlerts, defaultLogs, KTIcon, toAbsoluteUrl} from '../../_metronic/helpers'
import { useNotifications } from 'hooks'
import moment from 'moment';

const convierteAMomento = (fechaComparar)=>{
    const diferenciaEnMinutos = moment().diff(fechaComparar, 'minutes');
    const diferenciaEnHoras = moment().diff(fechaComparar, 'hours');
    const diferenciaEnDias = moment().diff(fechaComparar, 'days');

     

    if (diferenciaEnMinutos < 15) {
      return 'Hace un momento';
    } else if (diferenciaEnMinutos < 60) {
      return `${diferenciaEnMinutos} ${diferenciaEnMinutos === 1 ? 'min' : 'mins'}`;
    } else if (diferenciaEnHoras < 24) {      
      return `${diferenciaEnHoras} ${diferenciaEnHoras === 1 ? 'hr' : 'hrs'}`;
    } else if (diferenciaEnDias < 3) {
      return `${diferenciaEnDias} ${diferenciaEnDias === 1 ? 'día' : 'días'}`;
    } else {
      return(fechaComparar ? moment(fechaComparar).format('DD/MM/YYYY') : ''); // Muestra la fecha directamente
    }

 
}

const NotificationsList = ({notifications}) => {

  const {handleGetNotifications, handleGetNotificationsAndClean} = useNotifications();

  return (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-500px w-lg-500px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-size-lg-cover rounded-top'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
        Notificaciones <span className='fs-8 opacity-75 ps-3'></span>
      </h3>

      {/* <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_1'
          >
            Alerts
          </a>
        </li>

        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_3'
          >
            Logs
          </a>
        </li>
      </ul> */}
    </div>

    <div className='tab-content'>
      {/* <div className='tab-pane fade' id='kt_topbar_notifications_1' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {defaultAlerts.map((alert, index) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                    {' '}
                    <KTIcon iconName={alert.icon} className={`fs-2 text-${alert.state}`} />
                  </span>
                </div>


                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    {alert.title}
                  </a>
                  <div className='text-gray-400 fs-7'>{alert.description}</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>{alert.time}</span>
            </div>
          ))}
        </div>


      </div> */}



      {notifications && <div className='tab-pane fade show active' id='kt_topbar_notifications_3' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {notifications.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className=''>
                {/* <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span> */}
                <p className='text-gray-800 text-hover-primary fw-bold mb-0'>{log.description}</p>
                <p className='fs-7'>{convierteAMomento(log.created_at)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>}

    </div>
  </div>
  )
}

export {NotificationsList}
