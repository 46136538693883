import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PurchaseIncomeOrderColumnI } from 'interfaces'

import moment from 'moment';

const useGetColumns = () => {

  const COLUMNS =  [
    {
      Header: "Artículo",
      accessor: 'product_var_name',
    },
    {
      Header: "Lote",
      accessor: 'lot_number',
    },
    {
      Header: "Vencimiento",
      id: 'expiration_date',
      accessor: (row) => (
        <span>
          {row.expiration_date?moment(row.expiration_date).format("DD/MM/YYYY"):""}
        </span>
      ),
    },
    {
      Header: "Unidades",
      accessor: 'qty',
    },
    {
      Header: "",
      id: 'has_refrigeration',
      accessor:(row)=>(
        row.has_refrigeration?
        <span className='ps-4 pe-4'><i className='bi bi-snow text-primary fs-3'></i></span>
        :
        <></>
      )
    },
    // {
    //   Header: "Reservado",
    //   accessor: 'stock_reserved',
    // },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns