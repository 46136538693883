import { useState, useCallback } from 'react';

import { PaymentConditions } from 'services';

const usePaymentConditions = () => {
  const [loading, setLoading] = useState(false);

  const handleGetPaymentConditions = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await PaymentConditions.getPaymentConditions(params);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetPaymentCondition = useCallback(async (id, token) => {
    try {
      setLoading(true);
      const response = await PaymentConditions.getPaymentCondition(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreatePaymentCondition = useCallback(async (data, token) => {
    try {
      setLoading(true);
      const response = await PaymentConditions.createPaymentCondition(data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdatePaymentCondition = useCallback(async (id, data, token) => {
    try {
      setLoading(true);
      const response = await PaymentConditions.updatePaymentCondition(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeletePaymentCondition = useCallback(async (id, token) => {
    try {
      setLoading(true);
      const response = await PaymentConditions.deletePaymentCondition(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [])

  return {
    loading,
    handleGetPaymentConditions,
    handleGetPaymentCondition,
    handleCreatePaymentCondition,
    handleUpdatePaymentCondition,
    handleDeletePaymentCondition
  };
};

export default usePaymentConditions;