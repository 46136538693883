import { useState, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useArticles, useLogisticStock } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { ReservasModalFilter } from '.'
import { COLUMNS } from './columns'
import { formatInputDate } from 'utilities'
import moment from 'moment'


const today = new Date();
const monthAgo = new Date();
monthAgo.setMonth(today.getMonth() - 1);

const initialObQuery = {
  pageNumber: "1",
  pageSize: "7",
  date_from: formatInputDate(monthAgo.toISOString()),
  date_to: formatInputDate(today.toISOString()),
}

const ReservasModal = ({ show, onHide, article }) => {
  const { loading, handleGetStockReserved } = useLogisticStock()
  const [reservas, setReservas] = useState([])


  const getReservas = async () => {
    if (show) {
      try {
        let params = {
            product_var_id: +article?.product_var_id,
            business_unit_id: +article?.business_unit_id,
            customer_supplier_id: +article?.customer_suppliers_id
        }

        const response = await handleGetStockReserved(params)
        if (response?.result) {
          const data = response.result
          setReservas(data)
        }
      } catch (error) {
        setReservas([])
        toast.error(error.message)
      }
    }
  }

  const columns = [
    {
        Header: 'Pedido',
        id: 'out_order_id',
        accessor: (row) => (
            <>{row.sales_order_id}</>
        ),
    },
    {
        Header: 'O. Egreso',
        id: 'stock_owner',
        accessor: (row) => (
            <>{row.out_order_id}</>
        ),
    },
    // {
    //     Header: 'Creada',
    //     id: 'out_order_created_at',
    //     accessor: (row) => (
    //         <>{row.out_order_created_at?moment(row.out_order_created_at).format("DD/MM/YYYY HH:mm"):""}</>
    //     ),
    // },
    {
        Header: 'Estado',
        id: 'out_order_status_label',
        accessor: (row) => (
            <>{row.out_order_status_label}</>
        ),
    },
    
    {
        Header: 'Cliente',
        id: 'business_name',
        accessor: (row) => (
            <>{row.business_name}</>
        ),
    },
    {
        Header: 'Envío Aut. Renta',
        id: 'profitability_auth_sent_date',
        accessor: (row) => (
            <>{row.profitability_auth_sent_date?moment(row.profitability_auth_sent_date).format("DD/MM/YYYY HH:mm"):""}</>
        ),
    },
    {
        Header: 'Reservado',
        id: 'r',
        accessor: (row) => (
            <div className='text-center'>{row.r}</div>
        ),
    },
  ]

  useEffect(() => {
    if(show){
        getReservas()
    }    
    else{
      setReservas([])
    }
  }, [show])

  
  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Reservas de #{article?.product_var_id} - {article?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TableContainer
        //   filter={<ReservasModalFilter setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} setTitle={setTitle} loading={loading} />}
        >
          {(loading) && 
              <div className='w-100 text-center'>
                <div className='spinner-border text-success'>

                </div>
              </div>
          }
          <CustomTable
            columns={columns}
            data={reservas || []}
          />
        </TableContainer>

        <Modal.Footer style={{borderTop: "0 none"}}>
          <div className="row mt-10">
            <div className="col">
            <Button variant="secondary" onClick={onHide} className='mt10'>Cerrar</Button>
            </div>
          </div>      
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default ReservasModal