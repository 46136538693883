import { useState, useCallback, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useWaves, useWaybill } from 'hooks'

import { SelectField } from 'components'

import { ShipmentOrderStatusI } from 'interfaces'

const UpdateShippingCarrierServiceModal = ({ show, onHide, data, handleGetShipmentOrders }) => {
  const { handleGetShippingCarrierServices } = useWaves()
  const [shippingCarrierServices, setShippingCarrierServices] = useState([])

  const { handleUpdateShipmentOrderShippingCarrierService } = useWaybill()

  const getShippingCarrierServices = useCallback(async () => {
    if (show) {
      try {
        const response = await handleGetShippingCarrierServices()
        setShippingCarrierServices(response.data.result)
      } catch (error) {
        setShippingCarrierServices([])
        toast.error(error.message)
      }
    }
  }, [show, handleGetShippingCarrierServices])

  const updateShipmentOrderShippingCarrierService = async (values) => {
    try {
      const response = await handleUpdateShipmentOrderShippingCarrierService(data.id, values.shipping_carrier_service_id)
      toast.success("Registro actualizado correctamente", {theme: "colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      onHide()
      handleGetShipmentOrders()
    }
  }

  useEffect(() => {
    getShippingCarrierServices()
  }, [getShippingCarrierServices])

  if (!show) return null

  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Actualizar transportista de Orden de envío #{data?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <Formik
          initialValues={{
            order_delivery_status_id: data?.order_delivery_status_id,
            shipping_carrier_service_id: data?.shipping_carrier_service_id,
          }}
          onSubmit={updateShipmentOrderShippingCarrierService}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-6'>
                    <SelectField
                      id='shipping_carrier_service_id'
                      name='shipping_carrier_service_id'
                      label='Transportista'
                      options={shippingCarrierServices.map(item => ({ value: item.id, label: `${item.shipping_carrier_name} - ${item.shipping_carrier_service}` }))}
                      placeholder='Seleccione un servicio de transporte'
                      disabled={values.order_delivery_status_id !== ShipmentOrderStatusI.PENDIENTE_DESPACHO}
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex align-items-center justify-content-end'>
                  <Button variant='secondary' type='button' onClick={onHide} className='me-3'><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='submit' text='Actualizar'><i className='bi bi-save me-2' />Aceptar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateShippingCarrierServiceModal
