import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import moment from 'moment';

import routes from 'app/routing/routes'

const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS =  [
    {
      Header: intl.formatMessage({ id: 'COLUMN.PURCHASE_INCOME.ID' }),
      accessor: 'id',
    },
    {
      Header: "Pedido",
      id: 'in_order_id',
      accessor: (row) => (
        <Link to={`${routes.LOGISTIC_INCOME}/${row.id}`} className='ml-2'>
          {row.in_order_id}
        </Link>
      ),
      hasOrder: true
    },
    {
      Header: "Producto",
      id: 'product_var_id',
      accessor: (row) => (
        <>#{row.product_var_id} - {row.product_var_name}</>
      ),
      hasOrder: true
    },
    {
      Header: "Datos del producto",
      id: 'extra',
      accessor: (row) => (
        <>
          <div className='row'>
            <div className='col-4'><strong>GTIN:</strong></div>
            <div className='col'>{row.gtin}</div>
          </div>
          <div className='row'>
            <div className='col-4'><strong>Lote:</strong></div>
            <div className='col'>{row.lot_number}</div>
          </div>
          <div className='row'>
            <div className='col-4'><strong>Serial:</strong></div>
            <div className='col'>{row.serial_number}</div>
          </div>
        </>
      ),
      hasOrder: true
    },
    {
      Header: 'Error ANMAT',
      id: 'anmat_traced_error',
      accessor: (row) => (
        <p className='badge bg-danger text-white'>{row.anmat_traced_error}</p>
      )
    },
    {
      Header: 'Trazado manualmente',
      id: 'manual_traced_at',
      accessor: (row) => (
        <>
          {row.manual_traced_at && row.manual_traced_user_id
            ? <p className='badge bg-success text-white'>Sí</p>
            : <p className='badge bg-danger text-white'>No</p>
          }
        </>
      )
    }
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns