import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { formatTableDate } from 'utilities'
import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.PAYMENT_METHODS}/${row.id}`}>{row.id}</Link>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.PAYMENT_METHODS.NAME' }),
      accessor: 'name',
    },
    {
      Header: <div className='text-center'>{intl.formatMessage({ id: 'COLUMN.PAYMENT_METHODS.CANCEL_DATE' })}</div>,
      id: 'cancel_date',
      accessor: (row) => (
        <div className='text-center'>
          {row?.cancel_date ? formatTableDate(row.cancel_date) : '-'}
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}