import { useAgentTypes, useShipmentAddresses } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormLabel, Modal, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import Select from 'react-select';
import { toast } from "react-toastify";
import './article.css';

export const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '39px',
    height: '39px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};


export function GLNEditModal({ show, onHide, address_id_traza}) {
    const { handleGetAgentTypes } = useAgentTypes();
    const { handleGetShipmentAddress, handleUpdateAgent, loading} = useShipmentAddresses();
    const [agentTypes, setAgentTypes] = useState([]);
    const [updData, setUpdData] = useState({});
    const [originalAddress, setOriginalAddress] = useState({});
    

    const getAgentTypes = async()=>{
        const _resp = await handleGetAgentTypes();
        if(_resp && _resp.result){
            const _agents = _resp.result.filter(a=>a.is_active==1);
            setAgentTypes(_agents.map(a=>({
                ...a,
                value:a.id,
                label:a.name
            })))
        }
    }

    const getAddress = async()=>{
        const _address = await handleGetShipmentAddress(address_id_traza)
        if(_address?.data?.result){
            const _original = _address.data.result;
            setOriginalAddress(_original)
        }
    }

    const saveAddress = async()=>{
        try{
            console.log(updData)
            console.log(originalAddress)
            if(updData.agent_type?.id && updData.gln!=''){
                const _newData = {
                    id:originalAddress.id,
                    gln:updData.gln,
                    agent_type_id:updData.agent_type.id
                }
                await handleUpdateAgent(originalAddress.id, _newData)
            }
            toast.success("Dirección actualizada correctamente")
            onHide()
        }
        catch(err){
            toast.error(err.message)

        }

    }
    useEffect(()=>{
        if(originalAddress.id && agentTypes.length){
            setUpdData({
                ...updData,
                gln:originalAddress.gln,
                agent_type:agentTypes.filter(a=>a.id==originalAddress.agent_type_id)[0]
            })
        }
    }, [originalAddress, agentTypes])

    useEffect(()=>{
        getAgentTypes()
        if(address_id_traza){
            getAddress()
        }
    }, [show])


    return (
      <>
            <Modal show={show} onHide={onHide}  backdrop='static' >

            <Modal.Body className="" style={{ position: 'relative'}}>
                <h3>{originalAddress.name}</h3>                       
                {loading && 
                    <div className="spinner-overlay">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                <div className="row mt-10">
                    <div className="col">
                        <label className="form-label">Tipo de Agente</label>
                        <Select
                            options={agentTypes}
                            value={updData?.agent_type || null}
                            onChange={e=>setUpdData({...updData, agent_type:e})}
                            placeholder='Seleccionar tipo de agente'
                        />
                    </div>
                </div>
                <div className="row mt-8">
                    <div className="col">
                        <label className="form-label">GLN</label>
                        <input type="text" 
                            className="form-control"
                            value={updData.gln || ""}
                            onChange={e=>setUpdData({...updData, gln:e.target.value})} 
                        />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer style={{borderTop: "0 none"}}>
              <Button variant="secondary" onClick={onHide} className="me-3" disabled={loading}>Salir</Button>
              <Button variant="primary" onClick={saveAddress} className="me-3" disabled={loading}>Guardar</Button>
            </Modal.Footer>


          </Modal>
    </>
    )
  }
  