export enum RoleI {
    ADMIN = 1,
    VENTAS = 2,
    CONTROL_RENTAS = 3,
    CONTROL_CRED = 4,
    COMPRAS = 5,
    OPERACIONES_GTE = 6,
    FARMACIA = 7,
    CONTROL_COMPRAS = 8,
    VENTAS_GTE = 9,
    OPERACIONES = 10,
    COORDINACION = 11,
    IMPUESTOS = 12,
    PRESENTACION = 13
}

export enum RoleNameI {
    ADMIN = 'Admin',
    VENTAS = 'Ventas',
    CONTROL_RENTAS = 'Control Rentas',
    CONTORL_CRED = 'Control Cred.',
    COMPRAS = 'Compras',
    OPERACIONES_GTE = 'Operaciones Gte.',
    FARMACIA = 'Farmacia',
    CONTROL_COMPRAS = 'Control Compras',
    VENTAS_GTE = 'Ventas Gte.',
    OPERACIONES = 'Operaciones',
    COORDINACION = 'Coordinación',
    IMPUESTOS = 'Impuestos',
    PRESENTACION = 'Presentación',
}