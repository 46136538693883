import { useState, useEffect, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { useCarriers } from 'hooks'
import { useGetColumns } from './constants'
import routes from 'app/routing/routes'

const Carriers = () => {
  const { handleGetCarriers, handleDeleteCarrier } = useCarriers()
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const intl = useIntl()

  const deleteCarrier = useCallback(async (id) => {
    try {
      let response = await handleDeleteCarrier(id, 'token')
      toast.info("Registro eliminado correctamente", {theme:"colored"})

      response = await handleGetCarriers()
      setData(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleDeleteCarrier, handleGetCarriers])

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <>
          <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>Editar transportista</Tooltip>}>
            <Link to={`${routes.CARRIERS}/${row.id}`}>
              <i className="bi bi-pencil-square text-primary fs-3" style={{ cursor: 'pointer' }} />
            </Link>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>Eliminar transportista</Tooltip>}>
            <span onClick={() => deleteCarrier(row.id)}>
              <i className="bi bi-trash3 text-danger fs-3 ms-4" style={{ cursor: 'pointer' }} />
            </span>
          </OverlayTrigger>
        </>
      ),
    },
  ], [COLUMNS, deleteCarrier])

  useEffect(() => {
    const getCarriers = async () => {
      try {
        const response = await handleGetCarriers()
        setData(response.result)
      } catch (error) {
        toast.error(error.message)
      }
    }

    getCarriers()
  }, [handleGetCarriers])

  useEffect(() => {}, [data])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Transportistas', path: routes.CARRIERS, isActive: false },
        ]}
        description='Listado de transportistas'
      />
      <TableContainer title={intl.formatMessage({ id: 'TITLE.CARRIERS' })} goTo={routes.CARRIERS_NEW}>
        <CustomTable columns={columns} data={data} />
      </TableContainer>
    </>
  )
}

export default Carriers