import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useWaves } from 'hooks'

import { Input, SelectField } from 'components'

export const ESTADOS_ORDEN_DESPACHO = {
  PENDIENTE: 1,
  EN_DISTRIBUCION: 2,
  CANCELADO: 3
};

const initialValues = {
  id: '',
  text: '',
  date_from: '',
  date_to: '',
  dispatched_from: '',
  dispatched_to: '',
  shipping_carrier_id_filter: [],
  dispatch_order_status_id_filter: [ESTADOS_ORDEN_DESPACHO.PENDIENTE],
}

const optionsStatus = [
  { label: "Pendiente", value: ESTADOS_ORDEN_DESPACHO.PENDIENTE },
  { label: "En distribución", value: ESTADOS_ORDEN_DESPACHO.EN_DISTRIBUCION },
  { label: "Cancelado", value: ESTADOS_ORDEN_DESPACHO.CANCELADO }
]

const Filter = ({ queryMD, setQueryMD  }) => {
  const { handleGetShippingCarriers } = useWaves()
  const [carriers, setCarriers] = useState([])

  const filterData = async (values) => {
    let params = {
      ...values,
      ...queryMD,
      id: values?.id ? values.id : undefined,
      text: values?.text ? values.text : undefined,
      date_from: values?.date_from ? values.date_from : undefined,
      date_to: values?.date_to ? values.date_to : undefined,
      dispatched_from: values?.dispatched_from ? values.dispatched_from : undefined,
      dispatched_to: values?.dispatched_to ? values.dispatched_to : undefined,
      shipping_carrier_id_filter: values?.shipping_carrier_id_filter ? values.shipping_carrier_id_filter : undefined,
      dispatch_order_status_id_filter: values?.dispatch_order_status_id_filter ? values.dispatch_order_status_id_filter : undefined,
    }

    setQueryMD({ ...params, pageNumber: 1 })
  }

  const getCarriers = useCallback(async () => {
    try {
      const response = await handleGetShippingCarriers()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.name
      }))

      setCarriers(data)
    } catch (error) {
      setCarriers([])
      toast.error(error.message)
    }
  }, [handleGetShippingCarriers])

  useEffect(() => {
    getCarriers()
  }, [getCarriers])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filterData}
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-4'>
              <Input id='id' name='id' />
              <p className='text-muted'>Nro.</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' />
              <p className='text-muted'>Desde F. creación</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' />
              <p className='text-muted'>Hasta F. creación</p>
            </div>

            <div className='col-2'>
              <Input id='dispatched_from' name='dispatched_from' type='date' />
              <p className='text-muted'>Desde F. despacho</p>
            </div>

            <div className='col-2'>
              <Input id='dispatched_to' name='dispatched_to' type='date' />
              <p className='text-muted'>Hasta F. despacho</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <SelectField
                id='shipping_carrier_id_filter'
                name='shipping_carrier_id_filter'
                options={carriers}
                marginTop={'-20px'}
                placeholder='Seleccionar'
                isMulti
              />
              <p className='text-muted'>Transportista</p>
            </div>

            <div className='col-6'>
              <SelectField
                id='dispatch_order_status_id_filter'
                name='dispatch_order_status_id_filter'
                options={optionsStatus}
                isMulti
                marginTop={'-20px'}
                placeholder='Seleccione un estado'
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-end">
              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm()
                  setFieldValue('dispatch_order_status_id_filter', [ESTADOS_ORDEN_DESPACHO.PENDIENTE])
                  submitForm()
                }}
                className='me-3'
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant='primary'>
                <i className="bi bi-search mb-1" />
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter