import { useState, useEffect, useCallback } from 'react'

import {
  useProducts,
} from './'

import {
  ProductFamilyI,
  ProductMakerI,
  ProductsI,
} from 'interfaces/Inventory'


const useGetProductDetail = (id: ProductsI['id'] | null = null, isNew = false) => {
  const { loading, handleGetProduct, handleGetProductFamilies, handleGetProductMakers } = useProducts();
  const [productFamilies, setProductFamilies] = useState<ProductFamilyI[]>([]);
  const [productMakers, setProductMakers] = useState<ProductMakerI[]>([]);
  const [product, setProduct] = useState<ProductsI | null>(null);

  const refreshProduct = useCallback(async () => {
    try {
      const response = await handleGetProduct(id!);
      setProduct(response.result[0]);
    } catch (error) {
      throw error;
    }
  }, [handleGetProduct, id]);

  const getDetail = async () => {
    if (!isNew) {
      await refreshProduct();
    }
  }
  const getProductFamilies = async () => {
    try {
      const response = await handleGetProductFamilies();
      setProductFamilies(response.result)

    } catch (error) {
      throw error;
    } finally {
    }
  };
  const getProductMakers = async () => {
    try {
      const response = await handleGetProductMakers();
      setProductMakers(response.result)

    } catch (error) {
      throw error;
    } finally {
    }
  };

  useEffect(() => {
    getDetail();
    getProductFamilies();
    getProductMakers();
  }, [isNew, refreshProduct, handleGetProduct, handleGetProductFamilies, handleGetProductMakers ]);

  return {
    loading,
    product,
    refreshProduct,
    productFamilies,
    productMakers
  }
};

export default useGetProductDetail;