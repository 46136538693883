import { Modal } from "react-bootstrap";
import moment from 'moment'
import { CustomTable, FrontPaginationTable } from "components";
import { useEffect, useState } from "react";


const SerialListModal = ({show, onHide, data, serialNumbers})=>{

    const [filter, setFilter] = useState("");
    const [tableData, setTableData] = useState([]);


    const columns = [
        { 
            Header: <div className="text-center">Serie</div>,
            id:"serial_number",
            accessor: (row) =>(
                <div className='text-center'>
                  {row.serial_number}
                </div>
              )
        },
        { 
            Header: <div className="text-center">Estado</div>,
            id:"stock_not_available",
            className:'col-status',
            accessor: (row) =>(
              <span className={ `badge ${row.stock_not_available==1 ? 'estado-rojo':'estado-verde'} w-100 justify-content-center fw-normal`}>
                {row.stock_not_available==1 ? 'No disponible':'Disponible'}
              </span>
            )
        }
    ]

    useEffect(()=>{
        setTableData(
            serialNumbers.filter(row =>
                row["serial_number"].toString().toLowerCase().includes(filter.toLowerCase())
              )
        )

    }, [filter])

    useEffect(()=>{
        setFilter("")
        setTableData(serialNumbers
                .filter(d=>d.stock_available_without_reserved==1)
                .map(d=>({
            serial_number:d.serial_number,
            storage_space_name:d.storage_space_name
        })))
    }, [show])

    return (
        <>
            <Modal size="md" show={show} onHide={onHide} backdrop='static' >
                <Modal.Header closeButton>
                <Modal.Title>Nros. de serie {`${serialNumbers[0]?.name || ''}`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <p className=''><b>Almacenamiento:</b> {serialNumbers[0]?.storage_space_name}</p>
                <p className=''><b>Lote:</b> {serialNumbers[0]?.lot_number}</p>
                <p className=''><b>Vto:</b>  {serialNumbers[0]?.expiration_date?moment(serialNumbers[0].expiration_date).format("DD/MM/YYYY"):""}</p>

                <FrontPaginationTable  
                    cellClassName="p-2"
                    columns={columns} 
                    data={tableData} 
                    filter={filter}
                    setFilter={setFilter}
                />

                </Modal.Body>
            </Modal>
        </>
    )
}

export default SerialListModal;