import * as Yup from 'yup'

const googleAddress = {
  manual: false,
  place_id: '',
  route: '',
  street_number: '',
  floor: '',
  sublocality_level_1: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  locality: '',
  postal_code: '',
  postal_code_suffix: '',
  country: '',
  latitude: 0,
  longitude: 0,
  google_result: '',
  origin: '',
  formatted_address: '',
}

const contactInfo = {
  name: '',
  email: '',
  has_notifications: false,
  phone: '',
  mobile_suffix: '',
  mobile: '',
  observations: '',
}

const addShipmentAddress = {
  name: '',
  address: googleAddress,
  contact: contactInfo,
  observations: '',
  has_traceability: false,
  agent_type_id: '',
  gln: '',
  integrates_verifarma: false,
  is_ours: false,
  is_enabled: true,
}

const schema = Yup.object().shape({
  name: Yup.string().required('* Ingrese un nombre para la dirección'),
  agent_type_id: Yup.string().when(['has_traceability'], {
    is: (has_traceability) => Boolean(has_traceability),
    then: (schema) => schema.required('* Seleccione un tipo de agente').nonNullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  gln: Yup.string().when(['has_traceability'], {
    is: (has_traceability) => has_traceability,
    then: (schema) => schema.required('* Indique el GLN').nonNullable(),
    otherwise: (schema) => schema.nullable(),
  }),
  integrates_verifarma: Yup.boolean().default(false),
  is_ours: Yup.boolean().default(false),
  is_enabled: Yup.boolean().default(true),
  observations: Yup.string(),
})

export {
  addShipmentAddress,
  schema
}