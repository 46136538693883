import service from "../../service";

const PATH = {
  healthInsurances: "/health-insurances",
};

export class HealthInsurances {
  static getHealthInsurances = async (params) => {
    const response = await service.get(PATH.healthInsurances, {
      params,
    });

    return response;
  };

  static getHealthInsurance = async (id, token) => {
    const response = await service.get(`${PATH.healthInsurances}/${id}`)
    return response
  };

  static createHealthInsurance = async (data, token) => {
    const response = await service.post(PATH.healthInsurances, data)
    return response
  };

  static updateHealthInsurance = async (id, data, token) => {
    const response = await service.patch(`${PATH.healthInsurances}/${id}`, data)
    return response
  };
}