import { FC, useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import Filter from './Filter'
import useGetColumns from './columns'

import routes from 'app/routing/routes'
import useDepots from 'hooks/Inventory/useDepots'
import {helix} from 'ldrs'

const initialObQuery = {
  pageNumber:"1", 
  pageSize:"10"
}

const Wharehouse = () => {
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const [filter, setFilter] = useState("");
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0);
  const { handleGetDepots, loading } = useDepots()

  const getDepots = async () => {
    try {
      if(queryMD && queryMD.pageNumber){
        const query = {filter, queryMD};
        const response = await handleGetDepots(query )
        if(response.result && response.result.length>0){
          // const data = response.result[0].wharehouse;
          const data = [];

          response.result.forEach(obj => {
            obj.wharehouse.forEach(wharehouse => {
              wharehouse.storage_spaces.forEach(space=>{
                data.push({
                  space_id: space.id,
                  business_unit_name:space.business_unit_name,
                  space_storage_name:space.name,
                  owner: space.customer_supplier_name,
                  general_depot: space.general_depot_name,
                  wharehouse: space.warehouse_name,
                  warehouse_temp: space.cool_type_name,
                });

              })
            });
          });

          setData(data);
          setTotalCount(data.length)
          //setTotalCount(response.metadata?.count);
        }
      }
    } catch (error) {
      setData([])
      console.log(error)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    // {
    //   Header: '',
    //   id: 'actions',
    //   accessor: (row) => (
    //     <div className='d-flex justify-content-around'>
    //       <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Ver Racks</Tooltip>}>
    //         <Link to={`${routes.WAREHOUSE}/${row.id}`}  state={{ depot: row }} >
    //           <i className="bi bi-pencil-square text-primary fs-3" style={{ cursor: 'pointer' }} />
    //         </Link>
    //       </OverlayTrigger>

    //       <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Imprimir</Tooltip>}>
    //         <Link to='' className='ml-2'>
    //           <i className="bi bi-printer text-primary fs-3" style={{ cursor: 'pointer' }} />
    //         </Link>
    //       </OverlayTrigger>

    //     </div>
    //   )
    // }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD ,
    setObQuery:setQueryMD
  };

  useEffect(() => {
    getDepots()
  }, [handleGetDepots, filter, queryMD])

  useEffect(()=>{
    helix.register()
  }, [])

  // if(loading){
  //   return <div className='text-center'>
  //     <l-helix color="var(--bs-primary)" style={{marginTop:"200px"}}></l-helix>
  //   </div>
  // }

  return (
    <TableContainer
      title='Depósitos'
    //   goTo={routes.PURCHASE_REQUEST_NEW}
    //   goToText='Pedido de compra'
    //   goToSecondary={routes.INCOME_REQUEST_NEW}
    //   goToSecondaryText='Pedido de ingreso'
    //   filter={<Filter setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD}/>}
    >
      {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}
      
      <CustomTable columns={columns} data={data} />

    </TableContainer>
  )
}

export default Wharehouse