import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap';
import moment from 'moment';

import { Tooltip } from 'components';

import { currencyFormat } from 'utilities';
import routes from 'app/routing/routes';

import '../reports.css'

let sistemaLabel = (sistema) => {
  if (!sistema) return ''

  switch (sistema) {
    case 'riga_farma':
      return 'Farma'
    case 'mi_test':
      return 'Mi test'
    default:
      return 'Sin sistema'
  }
}

let sistemaLabelColor = (sistema) => {
  if (!sistema) return ''

  switch (sistema) {
    case 'riga_farma':
      return 'estado-celeste'
      return 'estado-azul'
    case 'mi_test':
      return 'estado-amarillo'
    default:
      return 'estado-gris'
  }
}

/*
{
  "id_comprobante": 119,
  "sistema": "mi_test",
  "fecha_emision": "2024-05-31T03:00:00.000Z",
  "letra": "B",
  "cae": 74228935439958,
  "nro_completo": "B 0901-00000005",
  "razon_social": "Maite Echechiquia",
  "tipo_comprobante_afip": "Nota de Crédito B",
  "tipo_comprobante_agrupado": 3,
  "tipo_comprobante_agrupado_desc": "Nota de Crédito",
  "nro_documento": "31464044",
  "importe_gravado": "-16528.93",
  "importe_no_gravado": null,
  "importe_exento": null,
  "importe_subtotal": "-16528.93",
  "importe_iva": "-3471.07",
  "importe_otros_tributos": null,
  "importe_total": "-20000.00",
  "forma_pago": "PAGO ELECTRÓNICO",
  "id_comprobante_cancelacion": null,
  "comprobante_cancelacion_nro_completo": null,
  "comprobante_cancelacion_tipo_comprobante_afip": null,
  "comprobante_cancelacion_tipo_comprobante_agrupado": null,
  "comprobante_cancelacion_tipo_comprobante_agrupado_desc": ""
}
*/

const COLUMNS =  [
  {
    Header:  <div className="text-center">ID</div>,
    id: 'id_comprobante',
    accessor: (row) => (
      <div className='text-center'>
        {row.id_comprobante}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header:  <div className="text-center">Sistema</div>,
    id: 'sistema',
    accessor: (row) => (
      <span className={`badge ${sistemaLabelColor(row.sistema)} w-100 justify-content-center fw-normal`}>
        {sistemaLabel(row.sistema)}
      </span>
    ),
    hasOrder: true,
  },
  {
    Header:  <div className="text-center">ID Pedido</div>,
    id: 'id_pedido_venta',
    accessor: (row) => (
      <div className='text-center'>
        {row?.id_pedido_venta
          ?
            <Tooltip text='Ver pedido'>
              <Link to={`${routes.SALES_ORDERS}/${row.id_pedido_venta}`}>
                {row.id_pedido_venta}
              </Link>
            </Tooltip>
          : <>-</>
        }
      </div>
    ),
    hasOrder: true,
  },
  {
    Header:  <div className="text-center">ID O. Egreso</div>,
    id: 'id_orden_egreso',
    accessor: (row) => (
      <div className='text-center'>
        {row?.id_orden_egreso
          ?
            <Tooltip text='Ver OE'>
              <Link to={`${routes.OUT_ORDERS}/${row.id_orden_egreso}`}>
                {row.id_orden_egreso}
              </Link>
            </Tooltip>
          : <>-</>
        }
      </div>
    ),
    hasOrder: true,
  },
  {
    Header:  <div className="text-center">Fecha</div>,
    id: 'fecha_emision',
    accessor: (row) => (
      <div className='text-center'>
        {row.fecha_emision ? moment(row.fecha_emision).format("DD/MM/YYYY") : ''}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header:  <div className="text-center">Tipo</div>,
    id:'tipo_comprobante_afip',
    className:'col-comprobante',
    accessor: (row)=>(
      <div className="break-word text-center">
        {row.tipo_comprobante_afip}
      </div>
    )
  },
  {
    Header:  <div className="text-center">Comprobante</div>,
    id: 'nro_completo',
    accessor: (row) => (
      <div className='text-center'>
        {row.nro_completo}
      </div>
    )
  },
  {
    Header:  <div className="text-center">CAE</div>,
    id: 'cae',
    accessor: (row) => (
      <div className='text-center'>
        {row.cae}
      </div>
    )
  },
  {
    Header:  <div className="">Razón Social</div>,
    id: 'razon_social',
    accessor: (row) => (
      <div className=''>
        <strong className='m-0 p-0'>[{row.id_cliente}] {row.razon_social}</strong>
        <p className='m-0 p-0 mt-2'>Documento: {row.nro_documento}</p>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header:  <div className="text-end">Total</div>,
    id: 'importe_total',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.importe_total || 0)}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'estado',
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.status_display}
      </span>
    ),
  },
  {
    Header: '',
    id: 'comprobante_cancelacion_id',
    accessor: (row) => (
      <div className='text-center'>
        {row?.id_comprobante_cancelacion &&
          <OverlayTrigger
            placement='top'
            overlay={
              <TooltipBS id={`tooltip-${row.id}`}>
                <div className='text-start'>
                  <p className='m-0 p-0'>ID: {row.id_comprobante_cancelacion}</p>
                  <p className='m-0 p-0 mt-2'>Comprobante: {row?.comprobante_cancelacion_nro_completo}</p>
                  {row?.comprobante_cancelacion_tipo_comprobante_agrupado !== 1 && <p className='m-0 p-0 mt-2'>Tipo: {row?.comprobante_cancelacion_tipo_comprobante_afip}</p>}
                  {row?.comprobante_cancelacion_tipo_comprobante_agrupado == 1 && <p className='m-0 p-0 mt-2'>Motivo: {row?.comprobacion_cancelacion_motivo || '-'}</p>}
                </div>
              </TooltipBS>
            }
          >
            <i className="bi bi-code-square text-danger ms-2 fs-3" />
          </OverlayTrigger>
        }
      </div>
    )
  }
]

export {
  COLUMNS
}