import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'

import { useCarriers } from 'hooks'
import { InputBS, FormButton, SelectField } from 'components'

import { initialValues, schema } from './constants'
import { formatInputDate } from 'utilities'
import routes from 'app/routing/routes'

const CarrierDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()

  const { loading, handleGetCarrier, handleUpdateCarrier } = useCarriers()
  const [carrier, setCarrier] = useState(initialValues)

  const updateCarrier = async (values) => {
    try {
      const response = await handleUpdateCarrier(id, values, 'token')
      toast.success(response.message)
      navigate(routes.CARRIERS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const getCarrier = async () => {
      try {
        const response = await handleGetCarrier(id)

        setCarrier({
          ...response.result,
          created_at: formatInputDate(response.result.created_at),
        })
      } catch (error) {
        console.log(error)
      }
    }

    getCarrier()
  }, [id, handleGetCarrier])

  useEffect(() => {}, [carrier])

  if (!id) {
    navigate(routes.CARRIERS)
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Transportistas/', path: routes.CARRIERS, isSeparator: false, isActive: true },
          { title: 'Detalle del transportista', path: `${routes.CARRIERS}/${id}`, isActive: false },
        ]}
        description='Detalle del transportista'
      />

      <DetailContainer title={intl.formatMessage({ id: 'TITLE.CARRIERS.UPDATE' })}>
        <Formik
          initialValues={carrier}
          validationSchema={schema}
          onSubmit={updateCarrier}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className='col'>
                    <InputBS id='id' name='id' label={intl.formatMessage({ id: 'FORM.SELLERS.ID' })} disabled />
                  </div>

                  <div className='col'>
                    <InputBS id='created_at' name='created_at' label={intl.formatMessage({ id: 'FORM.CARRIERS.CREATED_AT' })} disabled />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className='col-12'>
                    <InputBS id='description' name='description' label={intl.formatMessage({ id: 'FORM.CARRIERS.DESCRIPTION' })} />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                    <SelectField id='is_enabled' name='is_enabled' label={intl.formatMessage({ id: 'FORM.CARRIERS.IS_ENABLED' })} options={[{ value: 0, label: 'NO' }, { value: 1, label: 'SÍ' }]} />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default CarrierDetail