import React, { useEffect, useMemo, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import Select from 'react-select';
import { useAlfabeta } from "hooks";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from 'moment';

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px',
    height: '43px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};

export function AlfabetaModal({ show, onHide, values, setFieldValue}) {
    const [optionsArticle, setOptionsArticle] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const {handleGetArticles, handleGetArticle} = useAlfabeta();

    const fetchOptionsArticle = async(maker_id, family_id) => {
        const qs = {maker_id,family_id}
        const _art = await handleGetArticles(qs)
        if (_art && _art.result) {
          const _opt = _art.result.map(i=>({value:i.id, label:i.nom + ' ' + i.pres}))
          setOptionsArticle(_opt)
        }
    }

    const handlePriceOnly = async ()=>{
      setSubmitted(true);
      if(!selectedArticle.value)
        return;

      try {
          const resp = await Swal.fire({
              title: "Advertencia",
              text: "Va a utilizar el precio de Alfabeta como precio del artículo, Confirma?",
              icon: "warning",
              showDenyButton: true,
              denyButtonText: "No, cancelar",
              confirmButtonText: "Si, utilizar precio de Alfabeta",
              customClass: { confirmButton:'btn btn-primary', denyButton: "btn btn-secondary" },
              reverseButtons: true
          })
          if (resp.isConfirmed) {
              const _article = await handleGetArticle(selectedArticle.value)
              if(_article && _article.result){
                const {reg, price, priceStart} = _article.result[0];
                setFieldValue('manual_price_on', false);
                setFieldValue('reg_id', reg);
                setFieldValue('ab_pvp', price);
                setFieldValue('actual_pvp', price);
                setFieldValue('ab_pvp_start', priceStart?moment(priceStart).format("DD/MM/YYYY"):"");
                toast.success("Precio de alfabeta cargado en artículo", {theme: "colored"})
                onHide();
              } else {
                toast.error("No se encontró el artículo", {theme: "colored"})
              }
          }
      } catch (err) {
        setSubmitted(false);
        toast.error(err.message, { theme: "colored" })
      }
    }

    const handleAllFields = async () => {
        setSubmitted(true);
        if (!selectedArticle.value)
          return;

        try {
            const resp = await Swal.fire({
                title: "Advertencia",
                text: "Va a cargar todos los campos del artículo de Alfabeta, Confirma?",
                icon: "warning",
                showDenyButton: true,
                denyButtonText: "No, cancelar",
                confirmButtonText: "Si, cargar datos de Alfabeta",
                customClass: { confirmButton:'btn btn-primary', denyButton:"btn btn-secondary" },
                reverseButtons: true
            })
            if (resp.isConfirmed) {
                const _article = await handleGetArticle(selectedArticle.value)
                if(_article && _article.result){

                  setFieldValue('ab', _article.result[0]);

                  const {reg, nom, tam, acc, control, via, iva, sno, for:form, upot, pres, gtins, bars, tro, uni, price, priceStart, hel, imp, pot} = _article.result[0];

                  setFieldValue('reg_id', reg);
                  setFieldValue('manual_price_on', false);
                  setFieldValue('name', nom + ' ' + pres);
                  setFieldValue('nom', nom);
                  setFieldValue('size_id', tam);
                  setFieldValue('action_id', acc);
                  setFieldValue('control_id', control);
                  setFieldValue('via_id', via);
                  setFieldValue('pot', pot);
                  setFieldValue('pot_id', upot);
                  setFieldValue('form_id', form);
                  setFieldValue('presentation', pres);
                  setFieldValue('gtin', gtins);
                  setFieldValue('bar', bars);
                  setFieldValue('score_line', tro || '');
                  setFieldValue('unit', uni);
                  setFieldValue('vat', iva=='N'?null:5); //{ value:5, label:'21%', percent:21},
                  setFieldValue('purchase_vat', iva=='N'?null:5); //{ value:5, label:'21%', percent:21},

                  setFieldValue('refrigerated', hel=='N'?false:true);
                  setFieldValue('imported', imp=='N'?false:true);
                  setFieldValue('snomed', sno);

                  setFieldValue('ab_pvp', price);
                  setFieldValue('actual_pvp', price);
                  setFieldValue('ab_pvp_start', priceStart?moment(priceStart).format("DD/MM/YYYY"):"");

                  toast.success("Datos de alfabeta cargados correctamente", { theme: "colored" })
                  onHide();
                } else {
                  toast.error("No se encontró el artículo", { theme: "colored" })
                }
            }
        } catch(err) {
          setSubmitted(false);
          toast.error(err.message, {theme: "colored"})
        }

    }

    useEffect(() => {
      if (show) {
        console.log(values)
        setSubmitted(false)
        setSelectedArticle({})
        if (values && values.parent_product) {
          const {maker_id, family_id} = values.parent_product;
          fetchOptionsArticle(maker_id, family_id)
        }
      }
    }, [show])

    useEffect(()=>{
        if(optionsArticle && optionsArticle.length>0){
          if(values.reg_id){
            const _art = optionsArticle.filter(i=>i.value==values.reg_id)
            if(_art && _art.length>0)
              setSelectedArticle(_art[0])
          }
        }

    }, [optionsArticle])

  return (
    <>
      <Modal  show={show} onHide={onHide} backdrop='static' >
        <Modal.Header closeButton>
          <h3>Vincular con artículo de Alfabeta</h3>
        </Modal.Header>

        <Modal.Body className="">
          <p className="form-label"><b>Laboratorio:</b> {values?.parent_product?.maker_name}</p>
          <p className="form-label"><b>Ppio. Activo:</b> {values?.parent_product?.family_name}</p>
          <div className="form-label mt-5" style={(submitted && !selectedArticle.value)?{border:"solid red 1px"}:{}}>
            <Select
              id='ab'
              name='ab'
              options={optionsArticle}
              styles={customStyles}
              onChange={art => setSelectedArticle(art)}
              defaultValue={selectedArticle || ''}
              placeholder="Seleccionar artículo Alfabeta"
            />
          </div>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "0 none" }}>
          <Button variant="secondary" onClick={onHide}>Salir</Button>
          <Button type="button" variant="primary" onClick={handlePriceOnly}>Vincular solo precio</Button>
          <Button type="button" variant="primary" onClick={handleAllFields}>Vincular todo</Button>
        </Modal.Footer>
        </Modal>
    </>
  )
}