import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useClickOutside, useDocumentTypes } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { TableContainer} from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { useGetColumns } from './constants'
import routes from 'app/routing/routes'

const DocumentTypes = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const { handleGetDocumentTypes, handleDeleteDocumentType } = useDocumentTypes()
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()

  const getDocumentTypes = useCallback(async () => {
    try {
      const response = await handleGetDocumentTypes()
      setData(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetDocumentTypes])

  const deleteDocumentType = useCallback(async (id) => {
    try {
      let response = await handleDeleteDocumentType(id, 'token')
      toast.info(response.message)

      response = await handleGetDocumentTypes()
      setData(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleDeleteDocumentType, handleGetDocumentTypes])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.DOCUMENT_TYPES}/${currentRow?.id}`)
    })

    // actions.push({
    //   label: Boolean(currentRow?.is_enabled) ? 'Deshabilitar' : 'Habilitar',
    //   icon: `${Boolean(currentRow?.is_enabled) ? 'bi-x-circle text-danger' : 'bi-check2-circle text-success'} `,
    //   action: enableDisableCustomerSupplier
    // })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
      <>
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      </>
    }
  ], [COLUMNS, deleteDocumentType])

  useEffect(() => {
    getDocumentTypes()
  }, [getDocumentTypes])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Tipos de documento', path: routes.DOCUMENT_TYPES, isActive: false },
        ]}
        description='Listado de tipos de documento'
      />
      <TableContainer title={intl.formatMessage({ id: 'TITLE.DOCUMENT_TYPES' })} goTo={routes.DOCUMENT_TYPES_NEW}>
        <CustomTable columns={columns} data={data} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default DocumentTypes
