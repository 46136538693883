import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { ContactInfo } from 'components'

const ContactTab = () => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    setFieldValue('contact.name', `${values.first_name} ${values.last_name}`)
  }, [values.first_name, values.last_name, setFieldValue])

  return (
    <>
      <ContactInfo />
    </>
  )
}

export default ContactTab