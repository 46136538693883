import { FormLabel, FormGroup, FormControl } from 'react-bootstrap';
import { useField } from 'formik';

const InputBS = ({label, className='', disabled = false, ...props}) => {
  const [field, meta] = useField(props)
  const showError = meta.touched && meta.error

  return (
    <>
      <FormGroup className={props.className}>
        <FormLabel>{label}</FormLabel>
        <FormControl type="text"  autoComplete="off" {...field} className={`form-control ${className} ${showError ? 'border-danger' : ''}`} disabled={disabled} {...props} />
      </FormGroup>

      {showError && <div className='text-danger'>{meta.error}</div>}
    </>
  )
}

export default InputBS;