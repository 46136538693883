import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useClickOutside, useSalesOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { useGetColumns } from './columns'
import { Filter, TableActions } from './partials'

import routes from 'app/routing/routes'
import { OrderTypeI, SalesOrderStatusI } from 'interfaces'

import { labelColor } from '..'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const CreditAuthorizations = () => {
  const [data, setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const intl = useIntl()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { loading, handleGetSalesOrders, handleUpdateSalesOrderStatus, handleCloneSalesOrder, handleCreateOutOrder, handleCheckSalesOrderPVP, handleUpdateObservations, handleUpdateDispatchObservations } = useSalesOrders()

  const handleRowSelection = useCallback((rowId, statusId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.some(i => i.PedidoId === rowId)) {
        return prevSelectedRows.filter(i => i.PedidoId !== rowId)
      } else {
        return [...prevSelectedRows, { PedidoId: rowId, status_id: statusId, checked: true }]
      }
    })
  }, [])

  const { COLUMNS } = useGetColumns(selectedRows, handleRowSelection)

  const getSalesOrders = useCallback(async () => {
    try {
      let params = {
        getDetail: true,
        sales_order_status_id_filter: [SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO],
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }
      const response = await handleGetSalesOrders(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.sales_order_status_id)
      }))

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetSalesOrders, query])

  /* Menu contextual */
  const navigate = useNavigate()
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({
        x: e.clientX,
        y: e.clientY,
        target: e.target.id,
      })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: 'Ir a detalle',
        icon: 'bi bi-eye text-primary',
        action: actionEdit,
      })

      if (currentRow.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO && parseInt(currentRow.lines) > 0) {
        actions.push({
          label: "Autorizar crédito",
          icon: "bi bi-clipboard-check text-success",
          action: actionCreditAutorize
        })

        actions.push({
          label: "No autorizar",
          icon: "bi bi-clipboard-x text-danger",
          action: actionCreditNotAutorize
        })

        actions.push({
          label: 'Añadir observación',
          icon: 'bi bi-book text-primary',
          action: actionAddObservation
        })

        actions.push({
          label: 'Añadir observaciones despacho',
          icon: 'bi bi-book-fill text-primary',
          action: actionAddDispatchObservation
        })
      }

      /* TODO verificar estados posibles para anulaciones */
    }

    return actions
  }

  const updateSalesOrderStatus = async (newStatus) => {
    try {
      // const response = await handleUpdateSalesOrderStatus(currentRow?.id, newStatus, 'token')
      let response;

      if (newStatus === SalesOrderStatusI.AUTORIZADO) {
        response = await handleCreateOutOrder(currentRow?.id)
      }
      else{
        response = await handleUpdateSalesOrderStatus(currentRow?.id, newStatus)
      }

      toast.success("Orden actualizada correctamente",{theme: "colored" })
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSalesOrders()
    }
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(currentRow?.order_type_id === OrderTypeI.PEDIDO_VENTA
        ? `${routes.SALES_ORDERS}/${currentRow.id}`
        : currentRow?.order_type_id === OrderTypeI.PEDIDO_EGRESO
          ? `${routes.OUT_REQUEST}/${currentRow.id}`
          : routes.DASHBOARD
      )
    }
  }

  const actionCreditAutorize = async () => {
    try {
      const response = await handleCheckSalesOrderPVP(currentRow?.id)

      let text = "¿Confirma la autorización de crédito?"
      if (response.data.result.length > 0) {
        let qty = response.data.result.length;
        text = `El pedido #${currentRow?.id} tiene (${qty}) detalle${qty > 1 ? `s` : ''} con PVP desactualizado, ¿quiere autorizar la rentabilidad?`
      }

      const resp = await Swal.fire({
        title: "Advertencia",
        text: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, autorizar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        await updateSalesOrderStatus(SalesOrderStatusI.AUTORIZADO)
      }

      getSalesOrders()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const actionCreditNotAutorize = async () => {
    try {
      const inputValue = '';
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Confirma el rechazo de la autorización de crédito del pedido #${currentRow?.id}?`,
        icon: "warning",
        input:"textarea",
        inputValue,
        inputLabel: "Motivo",
        inputAttributes: {
            autocomplete:"off"
        },
        inputValidator: (value) => {
          if(!value || value.trim()==''){
            return "Por favor, completar motivo de rechazo"
          }
        },
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, rechazar autorización",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        await handleUpdateSalesOrderStatus(currentRow?.id, SalesOrderStatusI.NO_AUTORIZADO, { observaciones: resp.value })
        toast.success("Autorización rechazada", { theme: "colored" })
      }

      getSalesOrders()
    } catch(error) {
      toast.error(error.message)
    }
  }

  const actionAddObservation = async () => {
    try {
      const inputValue = currentRow?.observations;
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Añadir observación al pedido?`,
        icon: "warning",
        input:"textarea",
        inputValue,
        inputLabel: "Observaciones",
        inputAttributes: {
          autocomplete:"off"
        },
        // inputValidator: (value) => {
        //   if (!value || value.trim() == '') {
        //     return "Por favor, completar motivo de rechazo"
        //   }
        // },
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, añadir",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const response = await handleUpdateObservations(currentRow?.id, { observations: resp.value })
        toast.success(response.data.message)
      }
    } catch (err) {
      toast.error(err.message)
    } finally {
      getSalesOrders()
    }
  }

  const actionAddDispatchObservation = async () => {
    try {
      const inputValue = currentRow?.dispatch_observations;
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Añadir observaciones de despacho al pedido?`,
        icon: "warning",
        input:"textarea",
        inputValue,
        inputLabel: "Observaciones de despacho",
        inputAttributes: {
          autocomplete:"off"
        },
        // inputValidator: (value) => {
        //   if (!value || value.trim() == '') {
        //     return "Por favor, completar motivo de rechazo"
        //   }
        // },
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, añadir",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const response = await handleUpdateDispatchObservations(currentRow?.id, { observations: resp.value })
        toast.success(response.data.message)
      }
    } catch (err) {
      toast.error(err.message)
    } finally {
      getSalesOrders()
    }
  }

  const nullifyOrder = () => {
    updateSalesOrderStatus(SalesOrderStatusI.ANULADO)
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{fontSize: "1.5rem", cursor:"pointer"}} onClick={event=>handleContextMenu(event, row)} ref={threeDotsRef} />
      ),
    }
  ], [COLUMNS, getSalesOrders])

  const paginationOptions = {
    totalSize: totalCount,
    setObQuery: setQuery,
    obQuery: query,
  }

  useEffect(() => {
    getSalesOrders()
  }, [getSalesOrders])

  return (
    <>
      <TableContainer
        title={intl.formatMessage({ id: 'TITLE.CREDIT_AUTHORIZATIONS' })}
        filter={<Filter setData={setData} query={query} setQuery={setQuery} setTotalCount={setTotalCount} loading={loading} />}
        actions={
          <TableActions
            loading={loading}
            data={data}
            selectedRows={selectedRows}
            handleSelectedRows={setSelectedRows}
            handleGetSalesOrders={getSalesOrders}
          />
        }
        doubleActions={true}
      >
        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default CreditAuthorizations