import { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useSalesOrders, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { FormTable, Tooltip, CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { FileModal } from './'
import { Button } from 'react-bootstrap'
import { formatTableDate } from 'utilities'

const FilesTab = ({ editing, handleEditing, handleGetSalesOrder }) => {
  const { values } = useFormikContext();

  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const { handleGetSalesOrderFiles, handleGetSalesOrderFile, handleAddSalesOrderFile, handleDeleteSalesOrderFile } = useSalesOrders()
  const [files, setFiles] = useState([])

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleShowModal = () => {
    setModal(true)
  }

  const getFiles = useCallback(async () => {
    try {
      const response = await handleGetSalesOrderFiles(values.id)
      setFiles(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetSalesOrderFiles, values.id])

  const getFile = async (fileId, fileName) => {
    try {
      const response = await handleGetSalesOrderFile(values.id, fileId)

      const blob = response.data;
      const contentType = response.headers['content-type'];
      const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success('Archivo del pedido descargado correctamente');
    } catch (error) {
      console.log(error)
      toast.error('Error al descargar el archivo del pedido')
    }
  }

  const addFile = async (file) => {
    try {
      const data = new FormData()
      data.append('document_type_id', file.document_type_id)
      data.append('file', selectedFile)
      data.append('expiration_date', file.expiration_date)

      const response = await handleAddSalesOrderFile(values.id, data)

      toast.success("Registro agregado correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      setSelectedFile(null)
      handleCloseModal()
      handleEditing(false)
      handleGetSalesOrder()
      getFiles()
    }
  }

  const deleteFile = async (file_id) => {
    try {
      const response = await handleDeleteSalesOrderFile(values.id, file_id)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
      getFiles()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Descargar',
      icon: 'bi bi-download text-primary',
      action: () => getFile(currentRow.id, currentRow.riga_file_asset_name)
    })

    actions.push({
      label: 'Eliminar',
      icon: 'bi bi-x-circle text-danger',
      action: () => {
        deleteFile(currentRow.id)
      }
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    {
      Header: <div className='text-center'>Fecha de carga</div>,
      id: 'riga_file_asset_created_at',
      accessor: (row) => (
        <div className='text-center'>
          {moment(row.riga_file_asset_created_at).format('DD/MM/YYYY')}
        </div>
      )
    },
    {
      Header: 'Documento',
      id: 'riga_file_asset_name',
      accessor: (row) => (
        <>
          {row.riga_file_asset_name}
        </>
      ),
    },
    {
      Header: <div className='text-center'>Tipo</div>,
      id: 'document_type_name',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`${row.document_type_icon} me-2`} />{row.document_type_name}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Fecha de vencimiento</div>,
      id: 'expiration_date',
      accessor: (row) => (
        <div className='text-center'>
          {row?.expiration_date ? moment(row.expiration_date).format('DD/MM/YYYY') : '--/--/----'}
        </div>
      ),
    },
    {
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
    },
  ], [])

  useEffect(() => {
    getFiles()
  }, [getFiles])

  return (
    <>
      <FileModal show={modal} onClose={handleCloseModal} onSave={addFile} selectedFile={selectedFile} handleSelectedFile={setSelectedFile} />

      <div className='mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8'>
          <label className='form-label m-0'>Documentos del pedido</label>
          <Button className='BSButton' variant='outline-primary' onClick={handleShowModal}>Añadir documento</Button>
        </div>

        <TableContainer>
          <CustomTable columns={columns} data={files} handleContextMenu={handleContextMenu} />
        </TableContainer>

        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
          ref={contextRef}
        />

        {/* <FormTable columns={columns}>
          {files.length <= 0
            ? <tr><td className='text-italic text-gray-400' colSpan={columns.length}>El pedido aún no tiene documentos asociados.</td></tr>
            : files.map(f => (
              <tr key={`sales-order-file-${f.id}`}>
                <td className='align-middle'><span className={`${f.document_type_icon} me-2`} />{f.document_type_name}</td>
                <td className='align-middle'>{f.riga_file_asset_name}</td>
                <td className='align-middle text-center'>{f?.expiration_date ? formatTableDate(f.expiration_date) : '-'}</td>
                <td className='align-middle text-center'>{formatTableDate(f.riga_file_asset_created_at)}</td>
                <td className='align-middle text-center'>
                  <Tooltip text='Ver/descargar'>
                    <button type='button' className='btn btn-icon' onClick={() => getFile(f.id, f.riga_file_asset_name)}>
                      <i className='fas fa-download fs-2 me-2 text-primary' />
                    </button>
                  </Tooltip>
                </td>
              </tr>
            ))
          }
        </FormTable> */}
      </div>
    </>
  )
}

export default FilesTab