import { useState, useCallback, useEffect, memo } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useDocumentationTypes, useGenres, useHealthInsurances } from 'hooks'
import { SelectField, InputBS } from 'components'

const GeneralTab = ({ updating }) => {
  const intl = useIntl()

  const { handleGetDocumentationTypes } = useDocumentationTypes()
  const [documentationTypes, setDocumentationTypes] = useState([])

  const { handleGetGenres } = useGenres()
  const [genres, setGenres] = useState([])

  const { handleGetHealthInsurances } = useHealthInsurances()
  const [healthInsurances, setHealthInsurances] = useState([])

  const getDocumentationTypes = useCallback(async () => {
    try {
      const response = await handleGetDocumentationTypes('token')

      setDocumentationTypes(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetDocumentationTypes])

  const getGenres = useCallback(async () => {
    try {
      const response = await handleGetGenres()
      setGenres(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetGenres])

  const getHealthInsurances = useCallback(async () => {
    try {
      let params = {
        is_enabled: 1,
      }
      const response = await handleGetHealthInsurances(params)
      setHealthInsurances(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetHealthInsurances])

  useEffect(() => {
    getDocumentationTypes()
    getGenres()
    getHealthInsurances()
  }, [getDocumentationTypes, getGenres, getHealthInsurances])

  return (
    <>
      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='first_name' name='first_name' label={intl.formatMessage({ id: 'FORM.PATIENTS.FIRST_NAME' })} disabled={!updating} />
        </div>

        <div className='col-3'>
          <InputBS id='last_name' name='last_name' label={intl.formatMessage({ id: 'FORM.PATIENTS.LAST_NAME' })} disabled={!updating} />
        </div>

        <div className='col-3'>
          <SelectField
            id='documentation_type_id'
            name='documentation_type_id'
            label={intl.formatMessage({ id: 'FORM.PATIENTS.DOCUMENTATION_TYPE' })}
            options={[...documentationTypes.map(item => { return { value: item.id, label: item.type }})]}
            placeholder='Seleccione el documento'
            disabled={!updating}
          />
        </div>

        <div className='col-3'>
          <InputBS id='documentation' name='documentation' label={intl.formatMessage({ id: 'FORM.PATIENTS.DOCUMENTATION' })} disabled={!updating} maxLength='11'/>
        </div>
      </div>

      <div className='row'>
        <div className='col-3'>
          <InputBS id='birthdate' name='birthdate' type='date' label='Fecha de nacimiento' disabled={!updating} />
        </div>

        <div className='col-3'>
          <SelectField
            id='genre_id'
            name='genre_id'
            label='Género'
            options={[...genres.map(item => { return { value: item.id, label: item.label }})]}
            placeholder='Seleccione el género'
            disabled={!updating}
          />
        </div>
      </div>

      <div className='separator my-8' />
      <h2 className='m-0'>{intl.formatMessage({ id: 'FORM.PATIENTS.HEALTH_INSURANCE' })}</h2>

      <div className='row my-5'>
        {/* <div className='col-4'>
          <SelectField
            id='health_insurance.health_insurance_id'
            name='health_insurance.health_insurance_id'
            label={intl.formatMessage({ id: 'FORM.PATIENTS.HEALTH_INSURANCE' })}
            options={[{ value: '' , label: 'Selecionar obra social' } , ...healthInsurances.map(item => { return { value: item.id, label: item.name }})]}
            disabled={!updating}
          />
        </div> */}

        <div className='col-6'>
          <InputBS id='health_insurance.affiliate_number' name='health_insurance.affiliate_number' label={intl.formatMessage({ id: 'FORM.PATIENTS.HEALTH_INSURANCE.AFFILIATE_NUMBER' })} disabled={!updating} />
        </div>

        {/* <div className='col-5'>
          <InputBS id='health_insurance.plan' name='health_insurance.plan' label={intl.formatMessage({ id: 'FORM.PATIENTS.HEALTH_INSURANCE.PLAN' })} disabled={!updating} />
        </div> */}
      </div>

      <div className='separator my-8' />

      <div className='row my-8'>
        <div className='col-2'>
          <SelectField
            id='is_enabled'
            name='is_enabled'
            label={intl.formatMessage({ id: 'FORM.PATIENTS.IS_ENABLED' })}
            options={[ { value: 0, label: 'NO' }, { value: 1, label: 'SÍ' } ]}
            disabled={!updating}
          />
        </div>
      </div>
    </>
  )
}

export default memo(GeneralTab)