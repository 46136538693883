import { CustomerSupplierI } from "interfaces";


export enum SalesOrderStatusI {
  BORRADOR = 1,
  PENDIENTE_AUTORIZACION_RENTA = 2,
  PENDIENTE_AUTORIZACION_CREDITO = 3,
  NO_AUTORIZADO = 4,
  AUTORIZADO = 5,  
  CANCELADO = 6,
  FALTA_STOCK = 7,
  ORDEN_EGRESO = 8,
  ANULADO = 9,  
  PENDIENTE_FACTURACION = 13,
  EN_REVISION = 14,
  COORDINACION_ENTREGA = 15,
  PENDIENTE_PREPARACION = 16,
  EN_PREPARACION = 17,
  PENDIENTE_DESPACHO = 18,
  EN_DESPACHO = 19,
  ENTREGADO = 20,
  EN_DISTRIBUCION = 21,
  RESERVA_PARCIAL = 22

}

export enum SalesOrdertStatusLabelI {
  BORRADOR = 'Borrador',
  PENDIENTE_AUTORIZACION_RENTA = 'Enviar a autorizar renta',
  PENDIENTE_AUTORIZACION_CREDITO = 'Enviar a autorizar crédito',
  NO_AUTORIZADO = 'No autorizado',
  AUTORIZADO = 'Autorizado',
  CANCELADO = 'Cancelado',
  FALTA_STOCK = 'Falta de stock',
  ORDEN_EGRESO = 'Orden de egreso',
  ANULADO = 'Anulado',
  PENDIENTE_PREPARACION = 'Pendiente de preparación',
}

export interface SalesOrderI {
  id: number;
  budget_id: number | null;
  created_at: string;
  customer_supplier_id: CustomerSupplierI['id'];
  customer_supplier_name: CustomerSupplierI['business_name'];
  user_id?: number | string;
  seller_id: number;
  seller_name: string;
  total_lines: number;
  total: number;
  status_id: number;
  status_name: string;
}

export interface CreateSellsOrderI {
  // General
  budget_id: SalesOrderI['budget_id'] | string;
  client_id: SalesOrderI['customer_supplier_id'] | string;
  sell_condition_id: number | string;
  user_id?: SalesOrderI['user_id'];
  seller_id: SalesOrderI['seller_id'] | string;
  issue_date: string;
  shipping_date: string;
  de_blister: boolean;
  // Bid (Licitación)
  has_bid: boolean;
  bid_id?: number | string;
  // Purchase Order (Orden de Compra [OC])
  has_purchase_order: boolean;
  purchase_order_number?: string;
  purchase_order_file?: File | null;
  purchase_order_observations?: string;
  // Addressee
  addressee: number | string;
  coordination: boolean;
  home_delivery: boolean;
  patient_id?: number | string;
  addressee_other?: string;
  traceability_address: string;
  shipping_address?: string;
  order_observation?: string;
  dispatch_observation?: string;
  // Receipt (Receta)
  has_receipt: boolean;
  receipt_number?: string;
  doctor?: string;
  license_type?: string;
  license_number?: string;
  medical_institution?: string;
  professional_identification?: string;
  diagnosis?: string;
  receipt_file?: File | null;
}