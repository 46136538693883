import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

import { Table } from 'react-bootstrap'
import { currencyFormat } from 'utilities'
import './article.css'

const TotalSummaryTable = ({ editing = false }) => {
  const { values, setFieldValue } = useFormikContext()
  const [datos, setDatos] = useState({});
//   console.log(values)

const {detail} = values;


useEffect(()=>{
    console.log(values)
    setDatos({
        subtotal: detail.reduce((acc, el)=> +acc + +el.subtotal, +0),
        vat: detail.reduce((acc, el)=> +acc + +el.vat, +0),
        total: detail.reduce((acc, el)=> +acc + +el.total, +0)
    })
    
}, [values])

  return (
    <div className='row mb-8'>
        <div className="col-8"></div>

        <div className="col-4">

        <Table striped bordered hover>
            <thead>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={3} className='fw-bold p-2'>Subtotal</td>
                    <td className='text-end p-2 pe-5'>{ currencyFormat.format(datos.subtotal)}</td>
                </tr>
                <tr>
                    <td colSpan={3} className='fw-bold p-2'>IVA</td>
                    <td className='text-end p-2 pe-5'>{ currencyFormat.format(datos.vat)}</td>
                </tr>
                <tr>
                    <td colSpan={3} className='fw-bold p-2'>Total</td>
                    <td className='text-end p-2 fw-bold pe-5'>{ currencyFormat.format(datos.total)}</td>
                </tr>
            </tbody>
        </Table>

        </div>


    </div>
  )
}

export default TotalSummaryTable