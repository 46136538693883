import * as Yup from 'yup'

const healthInsurance = {
  health_insurance_id: '',
  affiliate_number: '',
  plan: '',
}

const contact = {
  name: '',
  email: '',
  has_notifications: false,
  phone: '',
  mobile_suffix: '',
  mobile: '',
  observations: '',
}

const addPatient = {
  first_name: '',
  last_name: '',
  documentation_type_id: '',
  documentation: '',
  birthdate: '',
  genre_id: '',
  health_insurance: healthInsurance,
  contact: contact
}

const schema = Yup.object().shape({
  first_name: Yup.string().required('* Ingrese el nombre del paciente').nonNullable(),
  last_name: Yup.string().required('* Ingrese el apellido del paciente').nonNullable(),
  documentation_type_id: Yup.number().required('* Seleccione un tipo de documento').nonNullable(),
  documentation: Yup.string().required('* Ingrese un número de documento').nonNullable(),
  birthdate: Yup.string(),
  genre_id: Yup.number(),
  health_insurance: Yup.object().shape({
    health_insurance_id: Yup.number().required('* Seleccione una obra social').nonNullable(),
    affiliate_number: Yup.string().required('* Ingrese un número de afiliado').nonNullable(),
    plan: Yup.string(),
  }),
  contact: Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().email('* Ingrese un email válido'),
    has_notifications: Yup.boolean().default(false),
    phone: Yup.string(),
    mobile_suffix: Yup.string().matches(/^(\+)?[0-9\s]+$/, '* El sufijo debe ser numérico y puede incluir el signo + al principio'),
    mobile: Yup.string().min(10, '* El número de celular debe tener al menos 10 dígitos').label('Celular'),
    observations: Yup.string(),
  }),
})

export {
  addPatient,
  schema
}