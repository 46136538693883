import { FC, useEffect, useRef, useState } from 'react'
import { useTable, useGlobalFilter, usePagination } from 'react-table'
import moment from 'moment'
import './article.css'

const ArticleTable = ({ columns, data, className  }) => {

    const tableRef = useRef(null);


    let maxQtys = {};

    // Primero encontramos el valor máximo de qty para cada product_var_id
    data.forEach((item) => {
      let in_request_qty = Number(item.in_request_qty);
      if (maxQtys[item.product_var_id] === undefined || in_request_qty > maxQtys[item.product_var_id]) {
        maxQtys[item.product_var_id] = in_request_qty;
      }
    });
    
    let idsVistos = {};
    
    // Luego establecemos el campo qty_nuevo al valor máximo encontrado para la primera ocurrencia de cada product_var_id
    data.forEach((item, index) => {
      if (idsVistos[item.product_var_id]) {
        // Si ya hemos visto este product_var_id, no hacemos nada
      } else {
        // Si es la primera vez que vemos este product_var_id, 
        // añadimos la propiedad qty_nuevo y marcamos este ID como visto
        data[index].qty_nuevo = maxQtys[item.product_var_id];
        data[index].qty_pending = maxQtys[item.product_var_id] - item.already_qty;
        idsVistos[item.product_var_id] = true;
      }
    });
    

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
        // initialState: { pageIndex: 0, pageSize: 10, globalFilter: '' }
      },
      useGlobalFilter, usePagination
    )


    return (
      <div className="table-responsive">
        <table className={`table table-striped gy-7 gs-7 ${className || ''}`}  {...getTableProps}  ref={tableRef}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                    className={column.id === 'actions' ? 'text-center' : ''}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            {data.length === 0 && <tr><td className='text-italic text-gray-400' colSpan={columns.length}>No hay información</td></tr>}

            {rows.map((row, rowIndex) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} >
                  {row.cells.map((cell, index) => {
                  

                  
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        width: index === columns.length - 1 ? '100px' : 'auto',
                        textAlign: index === columns.length - 1 ? 'center' : 'left',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>

                  )})}
                </tr>
              )
            })}
          </tbody>
        </table>



      </div>
    )
}

export default ArticleTable