import { useOutOrders, useWaves } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import Select from 'react-select';
import {helix} from 'ldrs'
import { toast } from "react-toastify";

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}
const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px',
    height: '43px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};


export function WaybillModal({ show, onHide, out_order_id, remito_id, shipping_carrier_service_id, wave}) {
    const { handleGetOutOrderSimulation, handleSendWaybill, loading } = useOutOrders()
    const {handleGetShippingCarrierServices, handleAddShippingCarrierService, loading:loadingShipping} = useWaves()
    const [opcionesRemito, setOpcionesRemito] = useState([])
    const [opcionesTransportista, setOpcionesTransportista] = useState([])
    const [transportista, setTransportista] = useState(null)
    const [simulate, setSimulate] = useState({})
    const [nroRemito, setNroRemito] = useState("")
    const [idTalonario, setIdTalonario] = useState("")

    const loadData = async()=>{
        const resp = await handleGetOutOrderSimulation(out_order_id)
        setSimulate(resp?.data)
        const op =resp?.data?.datos_remitos?.map(d=>({...d, label:d.punto_venta, value:d.numero_completo}));
        setOpcionesRemito(op)

        const transportes = await handleGetShippingCarrierServices()
        const opcionesTransporte = transportes?.data?.result?.map(item => ({ value: item.id, label: `${item.shipping_carrier_name} - ${item.shipping_carrier_service}` })) 
        setOpcionesTransportista(opcionesTransporte)


    }
    const getValueTransportista = () => {
        if (opcionesTransportista) {
          return opcionesTransportista.find(option => option.value == transportista);
        } else {
          return "";
        }
    };

    
    const sendWaybill = async ()=>{
        try{
            if(!transportista || transportista<=0){
                toast.error("Falta definir el transportista");
                return;
            }
            if(!wave || !wave.id){
                toast.error("La órden no está en una Ola");
                return;
            }
            
            await handleSendWaybill(out_order_id, {id_talonario:idTalonario}); 

            toast.success(`La órden de egreso #${out_order_id} fue procesada`, {theme: "colored"})

            onHide()
        }
        catch(err){
            console.log(err)
            toast.error(err?.message)
        }
    }

    const onChangeTransportista = async(e)=>{
        if(!wave || !wave.id){
            toast.error("La órden no está en una Ola");
            return;
        }
        try{
            await handleAddShippingCarrierService(wave.id, wave.wave_order_id, { shipping_carrier_service_id: e.value })  
            setTransportista(e.value) 
    
        }
        catch(err){
            toast.error("Error al cambiar transportista")
        }
    }

    useEffect(()=>{
        if(show){
            helix.register()
            setNroRemito("")
            loadData();
            setTransportista(shipping_carrier_service_id)
        }
    }, [show])



    return (
      <>
            <Modal size="md" show={show} onHide={onHide}  backdrop='static' >

            <Modal.Body className="">
                <h3>Crear remito / factura</h3>                       

                {loading || loadingShipping?
                    <div className='text-center'>
                        <l-helix color="var(--bs-primary)" style={{marginTop:"50px"}}></l-helix>
                    </div>
                :
                    <>                        
                        <div className="row mt-10">
                            <div className="col">
                                <FormLabel style={labelStyle}><b>Transportista</b></FormLabel>
                                <Select
                                    options={opcionesTransportista}
                                    onChange={onChangeTransportista}
                                    styles={customStyles}
                                    className={``}
                                    value={getValueTransportista()}
                                    disabled={loadingShipping || loading}
                                />
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col">
                                <FormLabel style={labelStyle}><b>Punto de remito</b></FormLabel>
                                <Select
                                    options={opcionesRemito}
                                    onChange={(e)=>{
                                        setNroRemito(e.value)
                                        setIdTalonario(e.label)
                                    }}
                                    styles={customStyles}
                                    className={``}
                                    disabled={loadingShipping || loading}
                                />
                            </div>
                        </div>
                        <div className="row mt-8">
                            <div className="col">
                                <label className="form-label"><b>Facturación:</b> {simulate?.datos_factura?.billing_type_label}</label>
                            </div>
                        </div>
                        <div className="row mt-8">
                            <div className="col">
                                <label className="form-label"><b>Nro. Remito:</b> {nroRemito}</label>
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>

            <Modal.Footer style={{borderTop: "0 none"}}>
              <Button variant="secondary" onClick={onHide} disabled={loading || loadingShipping}>Salir</Button>
              <Button type="button" variant="primary" onClick={sendWaybill} disabled={loading || loadingShipping}>Remitir</Button>
            </Modal.Footer>


          </Modal>
    </>
    )
  }
  