import * as Yup from 'yup'
import { currentDate } from 'utilities'

const googleAddress = {
  manual: false,
  place_id: '',
  route: '',
  street_number: '',
  floor: '',
  sublocality_level_1: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  locality: '',
  postal_code: '',
  postal_code_suffix: '',
  country: '',
  latitude: 0,
  longitude: 0,
  google_result: '',
  origin: '',
  formatted_address: '',
  locality_custom: '',
  custom_address_level1_label: '',
  custom_address_level1: '',
  custom_address_level2_label: '',
  custom_address_level2: '',
}

const initialValues = {
  // -- General
  // Relación comercial
  cod_abrev: '',
  is_customer: false,
  is_supplier: false,
  is_distributor: false,
  business_name: '',
  documentation_type_id: '',
  documentation: '',
  vat_condition_id: '',
  enrollment_date: currentDate,
  address: googleAddress,
  type_id: '',
  is_big_company: false,
  company_system: 'SCA',
  payment_condition_id: '',
  billing_type_id: '',
  price_update_id: '',
  email_notifications: '',
  number_notifications_suffix: '+54 9',
  number_notifications: '',
  // -- Direcciones
  shipment_addresses: [],
  // -- Ventas
  seller_id: '',
  comission: 0,
  credit: 0,
  suppliers: [],
  //
  de_blister: false,
  rnos: '',
}

const addShipmentAddressInitialValues = {
  name: '',
  address: googleAddress,
  contact: {
    name: '',
    email: '',
    has_notifications: false,
    phone: '',
    mobile: '',
    observations: '',
  },
  observations: '',
  has_traceability: false,
  agent_type_id: '',
  gln: '',
  integrates_verifarma: false,
}

const addSupplierInitialValues = {
  supplier_id: '',
  supplier_business_name: '',
  supplier_documentation: '',
}

const directionSchema = Yup.object().shape({
  manual: Yup.boolean().default(false),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  street: Yup.string(),
  route: Yup.string().nonNullable(),
  street_number: Yup.string(),
  postal_code: Yup.string(),
  floor: Yup.string(),
  custom_address_level1: Yup.string(),
  custom_address_level2: Yup.string(),
  administrative_area_level_1: Yup.string(),
})

const schema = Yup.object().shape({
  // -- General
  cod_abrev: Yup.number().max(4294967295, 'El código de abreviatura debe ser menor o igual a 4294967295'),
  is_customer: Yup.boolean().default(false),
  is_supplier: Yup.boolean().default(false),
  is_distributor: Yup.boolean().default(false),
  business_name: Yup.string().required('* Ingrese una razón social'),
  documentation_type_id: Yup.string().required('* Seleccione el tipo de documento').nonNullable(),
  documentation: Yup.string()
    .required('* Ingrese un documento').nonNullable()
    .test('custom-validation', '* Longitud y formato incorrectos para el tipo de documento', function(value) {
      const { documentation_type_id } = this.parent;

      if (documentation_type_id === '1' && (value.length !== 8 || !/^[0-9]+$/.test(value))) {
        throw this.createError({ message: '* El DNI debe contener 8 caracteres' });
      }

      if (documentation_type_id === '2' && (value.length !== 11 || !/^[0-9]+$/.test(value))) {
        throw this.createError({ message: '* El CUIT debe contener 11 caracteres' });
      }

      return true;
    }),
  vat_condition_id: Yup.number().required('* Seleccione el tipo de condición frente al IVA'),
  enrollment_date: Yup.string().required('* Ingrese una fecha de alta'),
  type_id: Yup.string().required('* Seleccione un tipo'),
  payment_condition_id: Yup.string().required('* Seleccione una condición de pago').nonNullable(),
  billing_type_id: Yup.number().nullable(),
  // billing_type_id: Yup.number().when(['is_customer'], {
  //   is: (is_customer) => Boolean(is_customer),
  //   then: (schema) => schema.required('* Seleccione una facturación').nonNullable(),
  //   otherwise: (schema) => schema,
  // }),
  price_update_id: Yup.number().nullable(),
  // price_update_id: Yup.number().when(['is_customer'], {
  //   is: (is_customer) => Boolean(is_customer),
  //   then: (schema) => schema.required('* Seleccione una opción').nonNullable(),
  //   otherwise: (schema) => schema,
  // }),
  address: directionSchema.required('* Ingrese una dirección'),
  email_notifications: Yup.string(),
  number_notifications_suffix: Yup.string().matches(/^(\+)?[0-9\s]+$/, '* El sufijo debe ser numérico y puede incluir el signo + al principio'),
  number_notifications: Yup.string().min(10, '* El número de celular debe tener al menos 10 dígitos').label('Celular'),
  // -- Ventas
  // seller_id: Yup.string().when(['is_customer'], {
  //   is: (is_customer) => Boolean(is_customer),
  //   then: (schema) => schema.required('* Seleccione un vendedor').nonNullable(),
  //   otherwise: (schema) => schema,
  // }),
  // comission: Yup.number().when(['is_customer'], {
  //   is: (is_customer) => Boolean(is_customer),
  //   then: (schema) => schema.required('* Ingrese una comisión'),
  //   otherwise: (schema) => schema,
  // }),
  // credit: Yup.number().when(['is_customer'], {
  //   is: (is_customer) => Boolean(is_customer),
  //   then: (schema) => schema.required('* Ingrese un crédito'),
  //   otherwise: (schema) => schema,
  // }),
})

const addShipmentAddressSchema = Yup.object().shape({
  name: Yup.string().required('* Ingrese un nombre para la dirección'),
  direction: directionSchema,
  contact: Yup.object().shape({
    name: Yup.string().required('* Ingrese un nombre para el contacto'),
    email: Yup.string().email('* Ingrese un email válido'),
    has_notifications: Yup.boolean().default(false),
    phone: Yup.string(),
    mobile: Yup.string(),
    observations: Yup.string(),
  }),
  observations: Yup.string(),
  has_traceability: Yup.boolean().default(false),
  agent_type_id: Yup.string().when('has_traceability', {
    is: true,
    then: (schema) => schema.required('* Seleccione un tipo de agente').nonNullable(),
    otherwise: (schema) => schema,
  }),
  gln: Yup.string().when('has_traceability', {
    is: true,
    then: (schema) => schema.required('* Indique el GLN').nonNullable(),
    otherwise: (schema) => schema,
  }),
  integrates_verifarma: Yup.boolean().default(false),
})

const dataAdapter = (data) => {
  let shipment_addresses = (data.shipment_addresses).map(address => {
    return {
      ...address,
      agent_type_id: address.agent_type_id !== '' ? address.agent_type_id : null,
      gln: address.gln !== '' ? address.gln : null,
    }
  })

  let values = {
    ...data,
    cod_abrev: data?.cod_abrev ? data.cod_abrev : null,
    payment_condition_id: data.payment_condition_id,
    seller_id: data.seller_id !== '' ? data.seller_id : null,
    comission: data.comission,
    credit: data.credit,
    shipment_addresses: shipment_addresses,
    de_blister: Boolean(data?.de_blister),
    rnos: data?.rnos || null,
  }

  return values
}

export {
  initialValues,
  schema,
  addShipmentAddressInitialValues,
  addShipmentAddressSchema,
  addSupplierInitialValues,
  dataAdapter,
}