import React from 'react'

const IconVisualizer = ({ icon }) => {
  return (
    <div className='col-1 text-center'>
      <div className='bg-light rounded p-7'>
        {icon
          ? <i className={`${icon} fa-3x`} />
          : <i className='fa fa-3x' />
        }
      </div>
    </div>
  )
}

export default IconVisualizer