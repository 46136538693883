import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useSalesOrders } from 'hooks'
import { InputBS } from 'components'

const EditPurchaseOrderModal = ({ show, onClose, handleGetSalesOrder }) => {
  const [initialValues, setInitialValues] = useState({
    purchase_order: '',
  })
  const { values } = useFormikContext()
  const { loading, handleUpdatePurchaseOrder } = useSalesOrders()

  const updatePurchaseOrder = async (data) => {
    try {
      const response = await handleUpdatePurchaseOrder(values.id, data)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      onClose()
      handleGetSalesOrder()
    }
  }

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      purchase_order: values?.purchase_order,
    })
  }, [values])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' size='md' centered>
      <Modal.Header closeButton>
        <Modal.Title>Editar Nro. Orden de compra</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={updatePurchaseOrder}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row'>
                  <div className='col'>
                    <InputBS id='purchase_order' name='purchase_order' label='Nro. Orden de compra' />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='row'>
                  <div className='col-12 text-end'>
                    <Button type='button' variant='secondary' className='me-3' onClick={onClose} disabled={loading}>
                      <i className='bi bi-slash-circle' />
                      Cancelar
                    </Button>

                    <Button type='submit' variant='primary' disabled={loading}>
                      <i className='bi bi-save me-2' />
                      Guardar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EditPurchaseOrderModal