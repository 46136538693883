import service from '../service'

const PATH = {
  vatConditions: '/vat-conditions',
}

export class VatConditions {
  static getVatConditions = async () => {
    const response = await service.get(PATH.vatConditions)
    return response
  }
}