import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: <div className=''>ID</div>,
    id: 'id',
    accessor: (row) => (
      <Link to={`${routes.PATIENTS}/${row.id}`}>
        {row.id}
      </Link>
    ),
  },
  {
    Header: <div className=''>Nombre completo</div>,
    id: 'fullname',
    accessor: (row) => (
      <>
        {row.first_name} {row.last_name}
      </>
    )
  },
  {
    Header: <div className=''>Documento</div>,
    id: 'documentation_type_id',
    accessor: (row) => (
      <div className=''>
        {row?.documentation
          ?
            <Tooltip text={row?.documentation_type_name}>
              <span>
                {row.documentation}
              </span>
            </Tooltip>
          : <i>Sin documento</i>
        }
      </div>
    )
  },
  {
    Header: <div className=''>Obra social</div>,
    id: 'health_insurance',
    accessor: (row) => (
      <>
        {row.health_insurance?.id &&
          <ul className='list-style-none m-0 p-0'>
            <li>
              {row?.health_insurance?.health_insurance_id
                ?
                  <Link to={`${routes.HEALTH_INSURANCES}/${row.health_insurance.health_insurance_id}`}>
                    {row.health_insurance.health_insurance_name}
                  </Link>
                : <i>Sin obra social</i>
              }
            </li>

            <li className='mt-2'>
              {row.health_insurance?.affiliate_number
                ? <p className='m-0 p-0'><strong>Nro. afiliado:</strong> {row.health_insurance.affiliate_number}</p>
                : <i>Sin nro. afiliado</i>
              }
            </li>
          </ul>
        }
      </>
    )
  },
  {
    Header: <div className='text-center'>Clientes asoc.</div>,
    id: 'customer_suppliers',
    accessor: (row) => (
      <div className='text-center'>
        {row?.customer_suppliers &&
          <OverlayTrigger
            placement='top'
            overlay={
              <TooltipBS id={`tooltip-${row.id}`}>
                <div>
                  {row.customer_suppliers.split(',').map((item, index) => (
                    <div key={index}>{item.trim()}</div>
                  ))}
                </div>
              </TooltipBS>
            }
          >
            <i className="bi bi-info-circle text-primary ms-2 fs-3" />
          </OverlayTrigger>
        }
      </div>
    )
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'is_enabled',
    accessor: (row) => (
      <span className={`badge badge-${Boolean(row.is_enabled) ? 'success' : 'danger'} w-100 justify-content-center fw-normal p-2`}>
        {Boolean(row.is_enabled) ? 'Habilitado' : 'Deshabilitado'}
      </span>
    ),
  },
]

export {
  COLUMNS
}