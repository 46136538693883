import service from "../service";

const PATH = {
  transactionalDocuements: 'transactional-documents',
  reporteRemitos: 'transactional-documents/report/remitos',
  reportRemitosSinComprobantesDetalle: 'transactional-documents/report/remitos/sin-comprobantes/detalle',
  reporteInventario: 'transactional-documents/report/inventario',
  reporteAnalisisStock: 'transactional-documents/report/analisis-stock',
  Incomes: "/incomes",
  InRequest: "/incomes/in-request",
  Event: "/event"
}

export class Reports {

  static getIncomes = async (params) => {
    const response = await service.get('incomes/in-request/report', {
      params
    })
    return response
  }

  static getTransactionalDocuments = async (params) => {
    const response = await service.get('/transactional-documents/report/v2', {
      params
    })
    return response
  }

  static getVentas = async (params) => {
    const response = await service.get('/sales-orders/report', {
      params
    })
    return response
  }

  static getVentasDetalle = async (params) => {
    const response = await service.get('/sales-orders/report/detail', {
      params
    })
    return response
  };

  static getReporteRemitos = async (params) => {
    const response = await service.get(PATH.reporteRemitos, {
      params,
    })
    return response
  }

  static getReporteRemitosSinComprobantesDetalle = async (params) => {
    const response = await service.get(PATH.reportRemitosSinComprobantesDetalle, {
      params,
    })
    return response
  }

  static getReporteRemitosVerifarma = async (params) => {

    const response = await service.get(`${PATH.reporteRemitos}/verifarma`, {
      params,
      responseType: 'blob'
    })
    return response
  }

  static getReporteInventario = async (params) => {
    const response = await service.get(PATH.reporteInventario, {
      params,
    })
    return response
  }

  static getReporteAnalisisStock = async (params) => {
    const response = await service.get(PATH.reporteAnalisisStock, {
      params,
    })
    return response
  }
}