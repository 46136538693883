import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useUsers, useOutOrders } from 'hooks'
import { Input, SelectField} from 'components'

import { OutOrderCoordinationStatusI, OutOrderStatusI } from 'interfaces'

const coordination_status = [
  { value: '', label: 'Seleccione una opción' },
  { value: 'NULL', label: 'Pendiente de coordinación' },
  { value: 'EN_PROGRESO', label: 'En progreso' },
  { value: 'FALTAN_DATOS', label: 'Falta de datos' },
  { value: 'PENDIENTE_DE_RESPUESTA', label: 'Pendiente de respuesta' },
  { value: 'PENDIENTE_VENTAS', label: 'Pendiente ventas' },
  { value: 'COORDINADO', label: 'Coordinado' },
]

const initialValues = {
  id_text: '',
  from_date: '',
  to_date: '',
  from_dispatch_date: '',
  to_dispatch_date: '',
  // text_coordination: '',
  nombre: '',
  documento: '',
  paciente: '',
  paciente_documento: '',
  article_text: '',
  direccion: '',
  localidad: '',
  provincia: '',
  coordination_status_id_filter: [],
  coordination_user_id_filter: '',
}

const Filter = ({ handleData, query, handleQuery, handleTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues)

  const { loading, handleGetOutOrders, handleGetCoordinationStatus } = useOutOrders()
  const [coordinationStatus, setCoordinationStatus] = useState([])

  const { handleGetUsers } = useUsers()
  const [users, setUsers] = useState([])

  const filterData = async (values) => {
    let params = {
      ...values,
      ...query,
      id_text: values?.id_text ? values.id_text : undefined,
      from_date: values?.from_date ? values.from_date : undefined,
      to_date: values?.to_date ? values.to_date : undefined,
      from_dispatch_date: values?.from_dispatch_date ? values.from_dispatch_date : undefined,
      to_dispatch_date: values?.to_dispatch_date ? values.to_dispatch_date : undefined,
      // text_coordination: values?.text_coordination ? values.text_coordination : undefined,
      nombre: values?.nombre ? values.nombre : undefined,
      documento: values?.documento ? values.documento : undefined,
      paciente: values?.paciente ? values.paciente : undefined,
      paciente_documento: values?.paciente_documento ? values.paciente_documento : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      direccion: values?.direccion ? values.direccion : undefined,
      localidad: values?.localidad ? values.localidad : undefined,
      provincia: values?.provincia ? values.provincia : undefined,
      coordination_status_id_filter: values?.coordination_status_id_filter || undefined,
      coordination_user_id_filter: values?.coordination_user_id_filter || undefined,
      out_order_status_id: OutOrderStatusI.COORDINACION_ENTREGA,
      getDetail: true,
      sin_total: true,
    }

    try {
      const response = await handleGetOutOrders(params)

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        coordination: { ...params },
      }))

      handleQuery({ ...params, pageNumber: 1, pageSize: 10 })
      handleData(response.data.result)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  const getUsers = useCallback(async () => {
    try {
      const response = await handleGetUsers()
      let data = response.data.result.map(d => ({ value: d.id, label: d.name }))

      data.unshift({
        value: '',
        label: 'Seleccione un usuario',
      })

      setUsers(data)
    } catch (error) {
      setUsers([])
      toast.error(error.message)
    }
  }, [handleGetUsers])

  const getCoordinationStatus = useCallback(async () => {
    try {
      const response = await handleGetCoordinationStatus()
      let data = response.data.result.map(d => ({
        value: d.id,
        name: d.name,
        label: d.label,
      }))

      let all_items = data.filter(d => d.name !== OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2)
      let last_item = data.filter(d => d.name == OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2)

      setCoordinationStatus([...last_item, ...all_items])
    } catch (error) {
      setCoordinationStatus([])
      toast.error(error.message)
    }
  }, [handleGetCoordinationStatus])

  useEffect(() => {
    getUsers()
    getCoordinationStatus()
  }, [getUsers, getCoordinationStatus])

  useEffect(() => {
    if (currentUser && currentUser?.user_id) {
      setFormikInitialValues({
        ...initialValues,
        id_text: currentUser.coordination?.id_text || '',
        from_date: currentUser.coordination?.from_date || '',
        to_date: currentUser.coordination?.to_date || '',
        from_dispatch_date: currentUser.coordination?.from_dispatch_date || '',
        to_dispatch_date: currentUser.coordination?.to_dispatch_date || '',
        nombre: currentUser.coordination?.nombre || '',
        documento: currentUser.coordination?.documento || '',
        paciente: currentUser.coordination?.paciente || '',
        paciente_documento: currentUser.coordination?.paciente_documento || '',
        article_text: currentUser.coordination?.article_text || '',
        direccion: currentUser.coordination?.direccion || '',
        localidad: currentUser.coordination?.localidad || '',
        provincia: currentUser.coordination?.provincia || '',
        coordination_status_id_filter: currentUser.coordination?.coordination_status_id_filter || [],
        coordination_user_id_filter: currentUser.coordination?.coordination_user_id_filter || '',
      })
    }
  }, [currentUser?.user_id])

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-4'>
              <Input id='id_text' name='id_text' disabled={loading} />
              <p className='text-muted'>Nro. / ID Pedido</p>
            </div>

            <div className='col-2'>
              <Input id='from_date' name='from_date' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. creación</p>
            </div>

            <div className='col-2'>
              <Input id='to_date' name='to_date' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. creación</p>
            </div>

            <div className='col-2'>
              <Input id='from_dispatch_date' name='from_dispatch_date' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. entrega</p>
            </div>

            <div className='col-2'>
              <Input id='to_dispatch_date' name='to_dispatch_date' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. entrega</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-5'>
              <Input id='nombre' name='nombre' disabled={loading} />
              <p className='text-muted'>ID / Cliente</p>
            </div>

            <div className='col-2'>
              <Input id='documento' name='documento' disabled={loading} />
              <p className='text-muted'>Documento</p>
            </div>

            <div className='col-3'>
              <Input id='paciente' name='paciente' disabled={loading} />
              <p className='text-muted'>ID / Paciente</p>
            </div>

            <div className='col-2'>
              <Input id='paciente_documento' name='paciente_documento' disabled={loading} />
              <p className='text-muted'>Documento / Nro. afiliado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-7'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>Artículo / Droga</p>
            </div>

            <div className='col-5'>
              <Input id='direccion' name='direccion' disabled={loading} />
              <p className='text-muted'>Nombre / Dirección / GLN</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='localidad' name='localidad' disabled={loading} />
              <p className='text-muted'>Localidad</p>
            </div>

            <div className='col-4'>
              <Input id='provincia' name='provincia' disabled={loading} />
              <p className='text-muted'>Provincia</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='coordination_status_id_filter'
                name='coordination_status_id_filter'
                options={coordinationStatus}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Estado coordinación</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='coordination_user_id_filter'
                name='coordination_user_id_filter'
                options={users}
                placeholder='Seleccione un usuario'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Usuario coordinación</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter