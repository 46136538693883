import { Link } from 'react-router-dom';
import moment from 'moment';

import routes from 'app/routing/routes';

import '../reports.css'

const useGetColumns = () => {
  let COLUMNS =  [
    {
      Header: <div className="text-center">ID Pedido</div>,
      id: 'PedidoId',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.SALES_ORDERS}/${row.PedidoId}`}>
            {row.PedidoId}
          </Link>
        </div>
      ),
      hasOrder: true,
    },
    {
      Header: <div className="text-center">Fecha</div>,
      id: 'PedidoFecha',
      accessor: (row) => (
        <div className='text-center'>
          {row.PedidoFecha ? moment(row.PedidoFecha).format("DD/MM/YYYY") : '-'}
        </div>
      )
    },
    {
      Header: <div className="text-center">Fecha autorización</div>,
      id: 'PedidoFechaAutorizacion',
      accessor: (row) => (
        <div className='text-center'>
          {row?.FechaAut ? moment(row.FechaAut).format("DD/MM/YYYY HH:mm") : '-'}
        </div>
      )
    },
    {
      Header: <div className="text-center">Usuario</div>,
      id: 'Usuario',
      accessor: (row) => (
        <div className='text-center'>
          <p className='m-0'>{row.Usuario}</p>
        </div>
      )
    },
    {
      Header: <div className="">Cliente</div>,
      id: 'ClienteNombre',
      accessor: (row) => (
        <div className=''>
          <strong className='m-0 mb-2'>[{row.ClienteId}] {row.ClienteNombre}</strong>
          <p>{row.TipoDocumento}: {row.ClienteDocumento} </p>
        </div>
      )
    },
    {
      Header:  <div className="text-center">Estado pedido</div>,
      id: 'EstadoPedidoId',
      accessor: (row) => (
        <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
          {row.EstadoPedido}
        </span>
      )
    },
    {
      Header: <div className="">Artículo</div>,
      id: 'IdProducto',
      accessor: (row) => (
        <div className=''>
          <strong className='m-0'>[{row.IdProducto}] {row.Articulo}</strong>
          <p className='m-0 mt-2'>SKU: {row.Sku} </p>
          <p className='m-0 mt-1'>Droga: {row.Droga}</p>
          <p className='m-0 mt-1'>Laboratorio: {row.Laboratorio}</p>
        </div>
      )
    },
    {
      Header: <div className='text-center'>Días falta</div>,
      id: 'DiasFalta',
      accessor: (row) => (
        <div className='text-center'>
          {row.DiasFalta}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Pedido</div>,
      id: 'Pedido',
      accessor: (row) => (
        <div className='text-center'>
          {row.Pedido}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Remitido</div>,
      id: 'Remitido',
      accessor: (row) => (
        <div className='text-center'>
          {row.Remitido}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Reservado</div>,
      id: 'Reservado',
      accessor: (row) => (
        <div className='text-center'>
          {row.Reservado}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Pendiente</div>,
      id: 'Pendiente',
      accessor: (row) => (
        <div className='text-center'>
          {row.Pendiente}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Próx. ingreso</div>,
      id: 'ProxIngresoEstim',
      accessor: (row) => (
        <div className='text-center'>
          {row?.ProxIngresoEstim ? moment(row.ProxIngresoEstim).format('DD/MM/YYYY') : '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Uds. a ingresar</div>,
      id: 'UnidadesIngresarProd',
      accessor: (row) => (
        <div className='text-center'>
          {row.UnidadesIngresarProd}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Pte. total</div>,
      id: 'PendienteTotalProd',
      accessor: (row) => (
        <div className='text-center'>
          {row.PendienteTotalProd}
        </div>
      ),
    },
  ]

  return {
    COLUMNS,
  }

}

export default useGetColumns