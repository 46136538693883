import { useState } from 'react'
import { toast } from 'react-toastify'

import { CreateDispatchOrderModal } from './'
import { ShipmentOrderStatusI } from 'interfaces'
import { Dropdown, DropdownButton } from 'react-bootstrap'

const TableActions = ({ selectedRows, handleSelectedRows, handleGetShipmentOrders }) => {
  const [dispatchOrderModal, setDispatchOrderModal] = useState(false)

  const createDispatchOrder = async () => {
    const canCreateDispatchOrder = selectedRows.every(row => row.status_id === ShipmentOrderStatusI.PENDIENTE_DESPACHO)

    if (!canCreateDispatchOrder) {
      toast.error(`Al menos una orden no puede ser añadida a la orden de despacho. Por favor, deseleccione las órdenes incorrectas.`);
      return;
    }

    setDispatchOrderModal(true)
  }

  return (
    <>
      <CreateDispatchOrderModal show={dispatchOrderModal} onHide={() => setDispatchOrderModal(false)} shipmentOrders={selectedRows} handleShipmentOrders={handleSelectedRows} handleGetShipmentOrders={handleGetShipmentOrders} />

      <div className='row'>
        <div className='col'>
          <DropdownButton
            title={
              <>
                <i className=""/>
                Acciones
              </>
            }
            align={{ lg: 'end' }}
          >
            <Dropdown.Item disabled>
              {selectedRows.length > 0
                ? selectedRows.length > 1
                  ? `${selectedRows.length} órdenes seleccionadas`
                  : `${selectedRows.length} orden seleccionada`
                : 'No hay órdenes seleccionadas'
              }
            </Dropdown.Item>

            <Dropdown.Item onClick={createDispatchOrder} disabled={selectedRows.length === 0 || selectedRows.some(r => r.status_id !== ShipmentOrderStatusI.PENDIENTE_DESPACHO)}>
              <i className={`bi bi-boxes text-primary pe-2`}/>
              Crear Despacho
            </Dropdown.Item>

            {/* <Dropdown.Item onClick={() => console.log(selectedRows)} disabled={selectedRows.length === 0 || selectedRows.some(r => r.status_id !== ShipmentOrderStatusI.PENDIENTE_DESPACHO)}>
              <i className={`bi bi-inboxes text-primary pe-2`}/>
              Agregar a Despacho
            </Dropdown.Item> */}
          </DropdownButton>
        </div>
      </div>
    </>
  )
}

export default TableActions