import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useCustomerSuppliers, useTypes } from 'hooks'
import { Input, SelectField } from 'components'
import { Button } from 'react-bootstrap'

const initialValues = {
  id_text: '',
  text: '',
  document_filter: '',
  type_id_filter: [],
  relacion_comercial: [],
  is_enabled: '',
}

const relacionesComerciales = [
  { value: 'CLIENTE', label: 'Cliente' },
  { value: 'PROVEEDOR', label: 'Proveedor' },
  { value: 'DISTRIBUIDOR', label: 'Distribuidor' },
]

const Filter = ({ handleData, query, handleQuery, handleTotalCount }) => {
  const { loading, handleGetCustomerSuppliers } = useCustomerSuppliers()

  const [types, setTypes] = useState([])
  const { handleGetTypes } = useTypes()

  const filterData = async (values) => {
    let params = {
      ...query,
      ...values,
      id_text: values?.id_text ? values.id_text : undefined,
      text: values?.text ? values.text : undefined,
      document_filter: values?.document_filter ? values.document_filter : undefined,
      type_id_filter: values.type_id_filter?.length > 0 ? values.type_id_filter : undefined,
      relacion_comercial: values.relacion_comercial?.length > 0 ? values.relacion_comercial : undefined,
      is_enabled: values?.is_enabled ? values.is_enabled : undefined,
    }

    try {
      const response = await handleGetCustomerSuppliers('', null, params)

      handleQuery({ ...params, pageNumber: 1, pageSize: 10 })
      handleData(response.data.result)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  const getTypes = useCallback(async () => {
    try {
      const response = await handleGetTypes()
      const data = response.result.map(d => ({ value: d.id, label: d.type }))
      setTypes(data)
    } catch (error) {
      setTypes([])
      toast.error(error.message)
    }
  }, [handleGetTypes])

  useEffect(() => {
    getTypes()
  }, [getTypes])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filterData}
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form className='d-flex flex-column'>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' />
              <p className='text-muted'>ID / Abrev.</p>
            </div>

            <div className='col-6'>
              <Input id='text' name='text' />
              <p className='text-muted'>Razón social</p>
            </div>

            <div className='col-4'>
              <Input id='document_filter' name='document_filter' />
              <p className='text-muted'>Documento</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='relacion_comercial'
                name='relacion_comercial'
                options={relacionesComerciales}
                placeholder='Seleccionar'
                isMulti
                marginTop={'-20px'}
              />
              <p className='text-muted'>Relación comercial</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='type_id_filter'
                name='type_id_filter'
                options={types}
                isMulti
                marginTop={'-20px'}
                placeholder='Seleccione un tipo'
              />
              <p className='text-muted'>Tipo</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='is_enabled'
                name='is_enabled'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Habilitado' },
                  { value: '0', label: 'Deshabilitado' },
                ]}
                marginTop={'-20px'}
                placeholder='Seleccione'
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                className='me-3'
                onClick={() => {
                  resetForm()
                  submitForm()
                }}
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search"/>
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter