import { Link } from 'react-router-dom'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: <div className=''>ID</div>,
    id: 'id',
    accessor: (row) => (
      <Link to={`${routes.ARTICLES}/${row.id}`} state={{ product_id: row.product_id }}>
        {row.id}
      </Link>
    ),
  },
  {
    Header: <div className='text-center'>Tipo</div>,
    id: 'tipo',
    accessor: (row) => (
      <div className='text-center'>
        <p className='m-0 p-0 '>{row.product_type_name.replace('_', ' ')}</p>
        <p className='m-0 p-0'>{row.traced ? '(Trazable)' : ''}</p>
      </div>
    )
  },
  {
    Header: <div className=''>Nombre</div>,
    accessor: 'name',
  },
  {
    Header: <div className=''>Producto Padre</div>,
    accessor: 'product_name',
  },
  {
    Header: "Datos",
    accessor: (row) => (
      <>
        <p className='m-0 p-0 mt-2'><strong>Laboratorio:</strong> {row.maker_name}</p>
        <p className='m-0 p-0 mt-2'><strong>Familia:</strong> {row.family_name}</p>
        <p className='m-0 p-0 mt-2'><strong>GTIN:</strong> {row.gtin}</p>
        <p className='m-0 p-0 mt-2'><strong>SKU:</strong> {row.sku}</p>
      </>
    ),
  },
]

export {
  COLUMNS
}