import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useClickOutside, useCustomerSuppliers } from 'hooks'
import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { Filter } from './partials'
import { useGetColumns } from './constants'

import routes from 'app/routing/routes'
import ContextMenu from 'components/ContextMenu'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const CustomerSuppliers = () => {
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const intl = useIntl()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { loading, handleGetCustomerSuppliers, handleUpdateCustomerSupplier, handleEnableCustomerSupplier, handleDisableCustomerSupplier } = useCustomerSuppliers()

  const getCustomerSuppliers = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetCustomerSuppliers('token', null, params)
      setData(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setData([])
    }
  }, [handleGetCustomerSuppliers, query])

  const navigate = useNavigate();

  const enableDisableCustomerSupplier = async () => {
    try {
      let status = currentRow?.is_enabled ? 'deshabilitar' : 'habilitar'
      let msg = currentRow?.is_enabled ? 'deshabilitado' : 'habilitado'

      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere ${status} al cliente/proveedor ${currentRow?.business_name}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, ${status}`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        let data = {
          is_enabled: !Boolean(currentRow?.is_enabled)
        }

        if(data.is_enabled){
          await handleEnableCustomerSupplier(currentRow?.id)
        }
        else{
          await handleDisableCustomerSupplier(currentRow?.id)
        }
        //await handleUpdateCustomerSupplier(currentRow?.id, data)
        toast(`Cliente ${msg} correctamente`, { type: data.is_enabled ? 'success' : 'info' })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getCustomerSuppliers()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.id}`)
    })

    actions.push({
      label: "Ver impuestos",
      icon: "bi bi-bank text-primary",
      action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.id}/impuestos`)
    })

    actions.push({
      label: 'Ver descuentos',
      icon: 'bi bi-coin text-primary',
      action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.id}/descuentos`)
    })

    actions.push({
      label: Boolean(currentRow?.is_enabled) ? 'Deshabilitar' : 'Habilitar',
      icon: `${Boolean(currentRow?.is_enabled) ? 'bi-x-circle text-danger' : 'bi-check2-circle text-success'} `,
      action: enableDisableCustomerSupplier
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS, getCustomerSuppliers])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getCustomerSuppliers()
  }, [getCustomerSuppliers])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={intl.formatMessage({ id: 'TITLE.CUSTOMER_SUPPLIERS' })}
        goTo={routes.CUSTOMER_SUPPLIERS_NEW}
        filter={<Filter handleData={setData} query={query} handleQuery={setQuery} handleTotalCount={setTotalCount} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default CustomerSuppliers