import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'

import { useSellers, useWindowsDimensions } from 'hooks'
import { InputBS, FormButton, SelectField } from 'components'

import { initialValues, schema } from './constants'
import { formatInputDate } from 'utilities'
import routes from 'app/routing/routes'

const SellerDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()

  const { loading, handleGetSeller, handleUpdateSeller } = useSellers()
  const [seller, setSeller] = useState(initialValues)
  const { width } = useWindowsDimensions()

  const updateSeller = async (values) => {
    try {
      const response = await handleUpdateSeller(id, values, 'token')
      toast.success(response.message)
      navigate(routes.SELLERS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const getSeller = async () => {
      try {
        const response = await handleGetSeller(id)

        setSeller({
          ...response.result,
          created_at: formatInputDate(response.result.created_at),
        })
      } catch (error) {
        toast.error(error.message)
      }
    }

    getSeller()
  }, [id, handleGetSeller])

  useEffect(() => {}, [seller])

  if (!id) {
    navigate(routes.SELLERS)
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Vendedores/', path: routes.SELLERS, isSeparator: false, isActive: true },
          { title: 'Detalle del vendedor', path: `${routes.SELLERS}/${id}`, isActive: false },
        ]}
        description='Detalle del vendedor'
      />

      <DetailContainer title={intl.formatMessage({ id: 'TITLE.SELLERS.UPDATE' })}>
        <Formik
          initialValues={seller}
          validationSchema={schema}
          onSubmit={updateSeller}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className='col'>
                    <InputBS id='id' name='id' label={intl.formatMessage({ id: 'FORM.SELLERS.ID' })} disabled />
                  </div>

                  <div className='col'>
                    <InputBS id='created_at' name='created_at' label={intl.formatMessage({ id: 'FORM.SELLERS.CREATED_AT' })} disabled />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className={`col col-lg-6 col-md-6 col-sm-12 col-12 ${width <= 767 ? 'mb-8' : ''}`}>
                    <InputBS id='first_name' name='first_name' label={intl.formatMessage({ id: 'FORM.SELLERS.FIRST_NAME' })} />
                  </div>

                  <div className={`col col-lg-6 col-md-6 col-sm-12 col-12`}>
                    <InputBS id='last_name' name='last_name' label={intl.formatMessage({ id: 'FORM.SELLERS.LAST_NAME' })} />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className='col-lg-6 col'>
                    <InputBS id='email' name='email' label={intl.formatMessage({ id: 'FORM.SELLERS.EMAIL' })} />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className='col-lg-3 col-md-3 col-sm-6 col-6'>
                    <SelectField id='is_enabled' name='is_enabled' label={intl.formatMessage({ id: 'FORM.SELLERS.IS_ENABLED' })} options={[{ value: 0, label: 'NO' }, { value: 1, label: 'SÍ' }]} />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default SellerDetail