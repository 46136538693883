import { useState, useCallback, useEffect } from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useCustomerSuppliers, useArticles } from 'hooks'

import { InputBS, Input, SwitchField, Label, Error } from 'components'
import { FormLabel } from 'react-bootstrap'
import Select from 'react-select'
import { dataAdapter } from '../constants'

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}
const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '37.73px',
    height: '37.73',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '1.1rem',
  }),
}

const optionsInitialCostType = [
  {value: 0, label: "Porcentual" },
  {value: 1, label: "Fijo"}
]


const Form = ({ create = false, editing = false }) => {
  const { values, setFieldValue } = useFormikContext()
  const intl = useIntl()
  const { loading: loadingCS, handleGetCustomerSuppliers, handleGetListCosts } = useCustomerSuppliers()
  const [customerSuppliers, setCustomerSuppliers] = useState([])
  const { loading: loadingPV, handleGetArticles } = useArticles()
  const [productVars, setProductVars] = useState([])

  const [initialCost, setInitialCost] = useState(0)
  const [aditionalCost, setAditionalCost] = useState(0)
  const [sircrebValue, setSircrebValue] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const currentDate = new Date().toISOString().split('T')[0]

  var tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1)
  tomorrowDate.setHours(0, 0, 0, 0)
  tomorrowDate = tomorrowDate.toISOString().split('T')[0]

  const searchCustomerSupplier = async (query) => {
    if (!create) return

    try {
      const params = {
        text: query,
      }

      const response = await handleGetCustomerSuppliers('', '', params)

      if (response && response.data.result) {
        setCustomerSuppliers(response.data.result)
      }
    } catch (error) {
      toast.error(error.message)
      setCustomerSuppliers([])
    }
  }

  const searchProductVar = async (query) => {
    if (!create) return

    try {
      const queryMD = {
        text: query,
        add_price: 1,
        add_cost: 1,
      }

      const response = await handleGetArticles({ queryMD })

      if (response && response.result) {
        setProductVars(response.result)
      }
    } catch (error) {
      setProductVars([])
      toast.error(error.message)
    }
  }

  const onChangeArticle = async(e)=>{
      let params = {
        pageNumber:1,
        product_var_id:e[0].id,
        type:'lista'
      }

      const _resp =  await handleGetListCosts(params)

      if(_resp?.data?.result){
        
        const _cost = dataAdapter(_resp.data.result[0]);

        setFieldValue('product_var_id', _cost.product_var_id)
        setFieldValue('product_var_name', _cost.product_var_name)
        setFieldValue('pvp', _cost.pvp)
        setFieldValue('psl', _cost.psl)
        setFieldValue('purchase_discount_extra', _cost.purchase_discount_extra)
        setFieldValue('purchase_add_cost', _cost.purchase_add_cost)
        setFieldValue('purchase_add_cost_percent', _cost.purchase_add_cost_percent)
        setFieldValue('purchase_add_cost_type', _cost.purchase_add_cost_type)
        setFieldValue('purchase_initial_cost_type', _cost.purchase_initial_cost_type)
        setFieldValue('initial_cost_percent', _cost.initial_cost_percent)
        setFieldValue('initial_cost', _cost.initial_cost)
      }
      else{
          setFieldValue('product_var_id', '')
          setFieldValue('product_var_name', '')
          setFieldValue('pvp', '')
          setFieldValue('psl', '')
          setFieldValue('purchase_discount_extra', '')
          setFieldValue('purchase_add_cost', '')
          setFieldValue('purchase_add_cost_percent', '')
      }
  }
 
  useEffect(() => {
      let iva = +values.purchase_vat_rate || 0;
      iva = parseFloat(iva);
      if (values.purchase_add_cost_type==1) {
        setAditionalCost(values.purchase_add_cost);
      } else {
        let _val=0;
        if (values.psl) {
          _val = +values.psl * +values.purchase_add_cost_percent / 100
        }
        setAditionalCost(parseFloat(_val).toFixed(2))
      }
  }, [ values.purchase_add_cost_type, values.purchase_add_cost, values.purchase_add_cost_percent])

  useEffect(() => {
    let iva = +values.purchase_vat_rate || 0;
    iva = parseFloat(iva);
    if (values.initial_cost_type==1) {
      setInitialCost(values.initial_cost);
    } else {
      let _val=0;
      if (values.psl) {
        _val = +values.psl * (1 - (+values.initial_cost_percent / 100))
      }
      setInitialCost(parseFloat(_val).toFixed(2))
    }
  }, [ values.initial_cost_type, values.initial_cost, values.initial_cost_percent])

  useEffect(() => {
    let _sircreb = 0
    if(values.sircreb_rate && initialCost){
      _sircreb = parseFloat(+initialCost * +values.sircreb_rate)
    }

    setTotalCost(parseFloat(+initialCost + +aditionalCost + _sircreb).toFixed(2))
    setSircrebValue(_sircreb.toFixed(2))
  }, [initialCost, aditionalCost])


  useEffect(()=>{
   console.log(values)
    

  }, [values])

  return (
    <>

      {create
        ?
          <div className='row my-8'>
            <div className='col-6'>
              <Label label={'Artículo'} htmlFor='product_var_id' />
              <AsyncTypeahead
                id='searchProductVariation'
                isLoading={loadingPV}
                minLength={3}
                labelKey={(option) => `${option.name}`}
                onSearch={searchProductVar}
                options={productVars}
                filterBy={() => true}
                promptText='Buscar artículo'
                searchText='Buscando...'
                placeholder='Escribe para buscar un artículo'
                onChange={onChangeArticle}
              />
              <ErrorMessage className='text-danger' name='product_var_id' render={msg => <Error message={msg} />} />
            </div>

              <div className='col-4'>
              <Label label="Cliente" htmlFor='customer_supplier_id' />
              <AsyncTypeahead
                id='searchCustomerSupplier'
                // isLoading={loadingCS}
                minLength={3}
                labelKey={(option) => `${option.business_name}`}
                onSearch={searchCustomerSupplier}
                options={customerSuppliers}
                filterBy={() => true}
                promptText='Buscar cliente'
                searchText='Buscando...'
                placeholder='Escribe para buscar un cliente'
                onChange={(e) => {
                  if (!e[0]) {
                    setFieldValue('customer_supplier_id', '')
                    setFieldValue('customer_supplier_business_name', '')

                    return
                  }

                  setFieldValue('customer_supplier_id', e[0].id)
                  setFieldValue('customer_supplier_business_name', e[0].business_name)
                }}
              />
              <ErrorMessage className='text-danger' name='customer_supplier_id' render={msg => <Error message={msg} />} />
            </div>

          </div>
        :
          <div className='row my-8'>
            <div className='col-6'>
              <InputBS id='product_var_name' name='product_var_name' label='Artículo' disabled />
            </div>
            {values?.especial==true?
              <>
                <div className='col-4'>
                  <InputBS id='customer_supplier_business_name' name='customer_supplier_business_name' label={intl.formatMessage({ id: 'FORM.COSTS.CUSTOMER' })} disabled />
                </div>
                <div className='col-2'>
                  <InputBS id='created_at' name='created_at' label='F. creación' disabled />
                </div>
              </>
              :
              <></>
            }





            {/* {!create &&
              <div className='col-2'>
                <div className='d-flex' style={{ paddingTop: "32px" }}>
                  <Link to={`${routes.ARTICLES}/${values.product_var_id}`} state={{ product_id: values.product_id }}>Ir a variación</Link>
                </div>
              </div>
            } */}
          </div>
      }

      <div className='row my-8'>
        <div className='col-4'>
          <InputBS id='pvp' name='pvp' value={values.pvp} label='PVP' disabled />
        </div>
        <div className='col-4'>
          <InputBS id='psl' name='psl' value={values.psl} label='PSL' disabled />
        </div>
      </div>
      

      <div className="row mt-6">
        <div className="col-3">
          <FormLabel style={labelStyle}>Costo inicial</FormLabel>
          <Select
            options={optionsInitialCostType}
            value={optionsInitialCostType.filter(d => +d.value == +values?.initial_cost_type)[0]}
            onChange={(e)=>{ 
              setFieldValue('initial_cost_type', optionsInitialCostType.filter(d => d.value == e.value)[0].value) 
            }}
            styles={customStyles}
            isDisabled={!editing}
          />
        </div>

        {values?.initial_cost_type == 1
          ?
            <>
              <div className="col-3">
                <label className='form-label'>Costo fijo</label>
                <Input  id='initial_cost' name='initial_cost'  disabled={!editing}/>
              </div>

              <div className='col-3'>
                <div className='row'>
                  {/* <div className='col-12'>
                    <label className='form-label'>Desde</label>
                    <Input id='date_from' name='date_from' type='date' min={currentDate}  disabled={!editing}/>
                  </div> */}

                  <div className='col'>
                    <label className='form-label'>Hasta</label>
                    <Input id='date_to' name='date_to' type='date'  min={tomorrowDate}  disabled={!editing}/>
                  </div>
                </div>
              </div>
            </>
          :
            <>
              <div className="col-3">
                <label className='form-label'>Bonificación %</label>
                <Input  id='initial_cost_percent' name='initial_cost_percent' label="Bonificación compra %" disabled={!editing}/>
              </div>

              <div className='col-3'></div>
            </>
        }

        <div className="col-3">
          <label className='form-label'>Costo inicial</label>
          <input type="text" value={initialCost} onChange={()=>{}} className='form-control text-end' disabled />
        </div>
      </div>

      <div className="row mt-6">
        <div className="col-9"></div>
        <div className="col-3">
            <label className='form-label'>SIRCREB {values?.sircreb_rate?'(' + values?.sircreb_rate + '%)':''}</label>
            <input type="text" value={sircrebValue} onChange={()=>{}} className='form-control text-end' disabled />
        </div>
      </div>

      <div className="row mt-6">
        <div className="col-3">
          <FormLabel style={labelStyle}>Costo asociado</FormLabel>
          <Select
            options={optionsInitialCostType}
            value={optionsInitialCostType.filter(d => d.value == values?.purchase_add_cost_type)[0]}
            onChange={(e)=>{ setFieldValue('purchase_add_cost_type', optionsInitialCostType.filter(d => d.value == e.value)[0].value)}}
            styles={customStyles}
            isDisabled={create || !editing || values.especial}
          />
        </div>

        {values?.purchase_add_cost_type == 1
          ?
            <>
              <div className="col-3">
                <label className='form-label'>Costo asociado (fijo)</label>
                <Input  id='purchase_add_cost' name='purchase_add_cost' disabled={ create || !editing || values.especial} />
              </div>
            </>
          :
            <>
              <div className="col-3">
                <label className='form-label'>Costo asociado (%)</label>
                <Input  id='purchase_add_cost_percent' name='purchase_add_cost_percent' disabled={ create || !editing || values.especial} />
              </div>
            </>
        }

        <div className='col-3'></div>

        <div className='col-3'>
          <label className='form-label'>Costo asociado</label>
          <input type="text" value={aditionalCost} onChange={()=>{}} className='form-control text-end' disabled />
        </div>
      </div>

      <div className='row mt-6 mb-20'>
        <div className='col'></div>
        <div className='col'></div>
        <div className='col'></div>
        <div className="col">
          <label className='form-label'>Costo de lista total</label>
          <input type="text" value={totalCost} onChange={() => {}} className={`form-control  text-end ${totalCost > values?.psl ? 'text-danger' : ''}`}  disabled />
        </div>
      </div>

    </>
  )
}

export default Form