
import React from 'react';
import { useFormikContext } from 'formik';
import { InputBS, SelectField, SwitchField, TypeaheadField } from 'components';

import {vatOptions} from "../../constants"

const ImpuestosForm = () => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <div style={{ flex: '2', marginRight:"10px" }}>
    
        <div className="row mt-10">
            <div className="col">                
                <SelectField isMulti={false} label={'IVA Compra'} name="purchase_vat"
                options={vatOptions} marginTop='3px' /> 
            </div>
            <div className="col">                
                <SelectField isMulti={false} label={'IVA Venta'} name="vat"
                options={vatOptions} marginTop='3px' /> 
            </div>
        </div>

    </div>
  );
};


export default ImpuestosForm;
