import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { InputBS, FormButton } from 'components'

import { usePaymentMethods } from 'hooks'
import { createInitialValues, createSchema } from './constants'

import routes from 'app/routing/routes'

const AddPaymentMethod = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const [loading] = useState(false)
  const { handleCreatePaymentMethod } = usePaymentMethods()

  const createPaymentMethod = async (values) => {
    try {
      const response = await handleCreatePaymentMethod(values, 'token')

      toast.success(response.message)
      navigate(routes.PAYMENT_METHODS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Métodos de pago/', path: routes.PAYMENT_METHODS, isSeparator: false, isActive: true },
          { title: 'Crear método de pago', path: routes.PAYMENT_METHODS_NEW, isActive: false },
        ]}
        description='Crear método de pago'
      />

      <DetailContainer title={intl.formatMessage({ id: 'TITLE.PAYMENT_METHODS.CREATE' })}>
        <Formik
          initialValues={createInitialValues}
          validationSchema={createSchema}
          onSubmit={createPaymentMethod}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className='col col-12'>
                    <InputBS id='name' name='name' label={intl.formatMessage({ id: 'FORM.PAYMENT_METHODS.NAME' })} />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className='col col-lg-4 col-md-4 col-sm-4 col-8'>
                    <InputBS id='cancel_date' name='cancel_date' type='date' label={intl.formatMessage({ id: 'FORM.PAYMENT_METHODS.CANCEL_DATE' })} />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddPaymentMethod