import { useEffect, useState, useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useWaybill } from 'hooks'

import { FormTable } from 'components'

import { ShipmentOrderStatusI } from 'interfaces'
import { helix } from 'ldrs'

const NewDispatchModal = ({ show, onHide, handleGetDispatchOrders }) => {
  const [items, setItems] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleCheck = (index, row) => {
    // const _selected = items.filter(i=>i.shipping_carrier_id==row.shipping_carrier_id && i.courier_id==row.courier_id)[0]

    // setSelectedItem({..._selected, checked:e.target.checked})

    // setSelectedItemId(id)
    
    if (selectedIndex?.index == index) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex({ index, row });
    }

  }

  const { handleCreateDispatchOrderGrouped, handleGetDispatchOrdersPending, loading } = useWaybill()

  const getShipmentOrders = async () => {
    try {
      const response = await handleGetDispatchOrdersPending()
      setItems(response.data.result.map(item=>({...item, checked:false})))
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleSubmit = async () => {
    
    if(selectedIndex && selectedIndex.row){
      const data = {
        shipping_carrier_id: selectedIndex.row.shipping_carrier_id,
        courier_id: selectedIndex.row.courier_id
      }
  
      try {
        const response = await handleCreateDispatchOrderGrouped(data)
        toast.success("Despacho creado correctamente", {theme: "colored"})
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetDispatchOrders()
        onHide()
      }
    }
  }

  useEffect(() => {
    if (show) {
      getShipmentOrders()
      setSelectedIndex(null)
    }
  }, [show])


  useEffect(()=>{
    helix.register()
  }, [])

  if (!show) return null

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Crear nuevo despacho</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            shipment_orders: items,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            const handleCheckboxChange = (index) => {
              const updated_shipment_orders = [...values.shipment_orders]
              updated_shipment_orders[index].checked = !updated_shipment_orders[index].checked
              setFieldValue('shipment_orders', updated_shipment_orders)
            }

            return (
              <Form>
                <div className="row">
                  <div className="col">

                    {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

                    <FormTable
                      columns={[
                        { header: '', styles: 'text-center' },
                        { header: 'Transportista' },
                        { header: 'Órdenes de envío' },
                      ]}
                      responsive={false}
                    >
                      {values.shipment_orders.map((item, index) => (
                        <tr key={index}>
                          <td className='align-center'>
                            <input
                              name="selected"
                              type="checkbox"
                              onChange={() => handleCheck(index, item)}
                              // checked={selectedItems.some(i => i.id === item.id)}
                              // disabled={item.order_delivery_status_id !== ShipmentOrderStatusI.PENDIENTE_DESPACHO || selectedItems.some(i => i.shipping_carrier_service_id !== item.shipping_carrier_service_id)}
                            />
                          </td>
                          <td>{item.shipping_carrier_name}</td>
                          <td>{item.cantidad}</td>
                          {/* <td className='align-center'>
                            <span className={`badge badge-${item.order_delivery_status_color}`}>{item.order_delivery_status_label}</span>
                          </td> */}
                        </tr>
                      ))}
                    </FormTable>
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex justify-content-end'>
                  <Button variant='secondary' type='button' className='me-3' onClick={() => onHide()} disabled={loading}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='button' onClick={handleSubmit} disabled={!selectedIndex || loading} ><i className='bi bi-save me-2' />Crear</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default NewDispatchModal