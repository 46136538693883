import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { formatTableDate } from 'utilities'
import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.SELLERS}/${row.id}`}>{row.id}</Link>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.SELLERS.NAME' }),
      id: 'full_name',
      accessor: (row) => (
        <>
          {row.first_name} {row.last_name}
        </>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.SELLERS.EMAIL' }),
      accessor: 'email',
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.SELLERS.CREATED_AT' }),
      id: 'created_at',
      accessor: (row) => (
        <>
          {row?.created_at ? formatTableDate(row.created_at) : ''}
        </>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.SELLERS.UPDATED_AT' }),
      id: 'updated_at',
      accessor: (row) => (
        <>
          {formatTableDate(row.updated_at)}
        </>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.SELLERS.IS_ENABLED' }),
      id: 'is_enabled',
      accessor: (row) => (
        <>
          {row.is_enabled || row.is_enabled === 1 ? (
            <span className="badge badge-success">
              {intl.formatMessage({ id: 'OPTION.YES' })}
            </span>
          ) : (
            <span className="badge badge-danger">
              {intl.formatMessage({ id: 'OPTION.NO' })}
            </span>
          )}
        </>
      ),
    },
  ]

  return {
    COLUMNS
  }
}