import { Menu as MUIMenu } from '@mui/material'
import { Tooltip } from 'components'

const Menu = ({
  id = 'menu-actions',
  text = 'Acciones',
  row,
  anchorEl,
  open,
  handleClick,
  handleClose,
  noTooltip = false,
  children
}) => {
  return (
    <>
      {!noTooltip &&
        <Tooltip text={text}>
          <i
            className='bi bi-caret-down-square-fill text-primary fs-3'
            aria-controls={open ? id : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => handleClick(e, row)}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      }

      <MUIMenu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiMenu-paper': {
            boxShadow: '0px 1px 1px -10px rgba(0,0,0,0.1), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
          },
        }}
      >
        {children}
      </MUIMenu>
    </>
  )
}

export default Menu