/* eslint-disable react/jsx-no-target-blank */
import {KTIcon} from '../../../helpers'
import React, { useState, useEffect } from 'react';
import './styles.css'

const SidebarFooter = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleEsc = (event : any) => {
      if (event.keyCode === 27 && isMenuOpen) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("keydown", handleEsc);
    
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isMenuOpen])
  
  // return (
  //   <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
  //     <a
  //       href={process.env.REACT_APP_PREVIEW_DOCS_URL}
  //       target='_blank'
  //       className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
  //       data-bs-toggle='tooltip'
  //       data-bs-trigger='hover'
  //       data-bs-dismiss-='click'
  //       title='Metronic Docs & Components'
  //     >
  //       <span className='btn-label'>Docs & Components</span>
  //       <KTIcon iconName='document' className='btn-icon fs-2 m-0' />
  //     </a>
  //   </div>
  // )
  return (<></>)

  /*return (
      <div 
        className='app-sidebar-footer flex-column-auto pt-2 pb-0 px-6' id='kt_app_sidebar_footer'
        style={{ marginBottom: '-4px' }}
      >
        <a
          style={{ backgroundColor: '#f2f2f2', color: '#3d5171' }}
          className='btn btn-flex flex-center btn-custom overflow-hidden text-nowrap px-0 h-40px w-100'
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
          title='Mensaje nuevo'
          onClick={toggleMenu}
        >
          <span className='btn-label'>Mensaje nuevo</span>
          <KTIcon iconName='document' className='btn-icon fs-2 m-0' />
        </a>

        {isMenuOpen && (
          <div className={`overlay ${isMenuOpen ? 'open' : ''}`}>
            <div className="menu-header">
              <h4>Mensaje nuevo</h4>
              <button className="close-button" onClick={toggleMenu}>&times;</button>
            </div>
          </div>
        )}
        
      </div>
    )*/

}

export {SidebarFooter}
