import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'

import routes from 'app/routing/routes'

const MenuActions = ({ values, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigation = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver detalle",
      icon: "bi bi-person-circle text-primary",
      action: actionGoToDetail
    })

    return actions
  }

  const actionGoToDetail = async () => {
    navigation(`${routes.CUSTOMER_SUPPLIERS}/${values.id}`)
  }

  return (
    <>
      <DropdownButton title="Acciones" disabled={disabled}>
        {availableActions().map((data, index)=>(
          <Dropdown.Item key={index} onClick={() => data.action()}>
            <i className={`${data.icon} pe-3`} />
            {data.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  )
}

const Actions = ({ values, updating, handleUpdating, submitRef, cancelRef, loading }) => {
  const navigate = useNavigate()

  return (
    <>
      {!updating
        ?
          <div className='d-flex align-items-center justify-content-end'>
            <Button className='me-3' variant='secondary' type='button' onClick={() => navigate(routes.CUSTOMER_SUPPLIERS)}><i className="ki-duotone ki-left" />Volver</Button>
            <Button className='me-3' variant='primary' type='button' onClick={handleUpdating}><i className='bi bi-pencil' />Editar</Button>
            <MenuActions values={values} disabled={loading} />
          </div>
        :
        <div className='d-flex align-items-center justify-content-end'>
            <Button className='me-3' variant='secondary' type='button' onClick={() => cancelRef.current.click()}><i className="bi bi-slash-circle" />Cancelar</Button>
            <Button className='me-3' variant='primary' type='button' onClick={() => submitRef.current.click()}><i className='bi bi-save me-2' />Guardar</Button>
            <MenuActions values={values} disabled={loading} />
          </div>
      }
    </>
  )
}

export default Actions