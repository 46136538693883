import service from 'services/service'

const PATH = {
  budgets: "/budgets",
  budgetStatus: "/budgets/status",
};

export class Budgets {
  static getBudgets = async (params) => {
    const response = await service.get(PATH.budgets, {
      params,
    })

    return response
  }

  static getBudgetStatus = async (params) => {
    const response = await service.get(PATH.budgetStatus, {
      params,
    })

    return response
  }

  static getBudget = async (id, params) => {
    const response = await service.get(`${PATH.budgets}/${id}`, {
      params,
    })

    return response
  }

  static addBudget = async (data) => {
    const response = await service.post(PATH.budgets, data)
    return response
  }

  static updateBudget = async (id, data) => {
    const response = await service.patch(`${PATH.budgets}/${id}`, data)
    return response
  }

  static updateBudgetStatus = async (id, status_id) => {
    const response = await service.patch(`${PATH.budgets}/${id}/status/${status_id}`)
    return response
  }

  static addDetail = async (id, data) => {
    const response = await service.post(`${PATH.budgets}/${id}/detail`, data)
    return response
  }

  static updateDetail = async (id, detail_id, data) => {
    const response = await service.patch(`${PATH.budgets}/${id}/detail/${detail_id}`, data)
    return response
  }

  static deleteDetail = async (id, detail_id) => {
    const response = await service.delete(`${PATH.budgets}/${id}/detail/${detail_id}`)
    return response
  }

  static cloneBudget = async (id) => {
    const response = await service.post(`${PATH.budgets}/${id}/clone`)
    return response
  }

  static printBudget = async (id) => {
    const response = await service.get(`${PATH.budgets}/${id}/print`, {
      responseType: 'blob',
    })
    return response
  }

  static createSalesOrder = async (id) => {
    const response = await service.post(`${PATH.budgets}/${id}/sales-order`, { user_id: 1 })
    return response
  }
}