import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'

import { usePaymentMethods } from 'hooks'
import { InputBS, FormButton } from 'components'

import { initialValues, schema } from './constants'
import { formatInputDate } from 'utilities'
import routes from 'app/routing/routes'

const PaymentMethodDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()

  const [paymentMethod, setPaymentMethod] = useState(initialValues)
  const { loading, handleGetPaymentMethod, handleUpdatePaymentMethod } = usePaymentMethods()

  const updatePaymentMethod = async (values) => {
    let data = {
      ...values,
      cancel_date: values?.cancel_date ? values.cancel_date : null,
    }
    delete data.id

    try {
      const response = await handleUpdatePaymentMethod(id, data, 'token')
      toast.success(response.message)
      navigate(routes.PAYMENT_METHODS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const getPaymentMethod = async () => {
      try {
        const response = await handleGetPaymentMethod(id, 'token')

        setPaymentMethod({
          ...response.result,
          cancel_date: response.result?.cancel_date ? formatInputDate(response.result.cancel_date) : '',
        })
      } catch (error) {
        toast.error(error.message)
        console.log(error)
      }
    }

    getPaymentMethod()
  }, [id, handleGetPaymentMethod])

  useEffect(() => {}, [paymentMethod])

  if (!id) {
    navigate(routes.PAYMENT_METHODS)
  }

  return (
    <>
      <DetailContainer title={`${intl.formatMessage({ id: 'TITLE.PAYMENT_METHODS.UPDATE' })} #${id}`}>
        <Formik
          initialValues={paymentMethod}
          validationSchema={schema}
          onSubmit={updatePaymentMethod}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className='col col-9'>
                    <InputBS id='name' name='name' label={intl.formatMessage({ id: 'FORM.PAYMENT_METHODS.NAME' })} />
                  </div>

                  <div className='col-3'>
                    <InputBS id='cancel_date' name='cancel_date' type='date' label={intl.formatMessage({ id: 'FORM.PAYMENT_METHODS.CANCEL_DATE' })} />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default PaymentMethodDetail