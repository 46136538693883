import * as Yup from 'yup'

import { currentDate } from 'utilities'
import { BillingTypesI } from 'interfaces'

const initialValues = {
  user_id: '',
  has_budget: false,
  budget_id: '',
  budget: '',
  business_unit_id: '',
  customer_supplier_id: '',
  customer_supplier_name: '',
  seller_id: '',
  payment_condition_id: '',
  issue_date: currentDate,
  dispatch_date: '',
  de_blister: false,
  bidding: false,
  bidding_value: '',
  billing_type_id: '',
  billing_date: '',
}

const schema = Yup.object().shape({
  has_budget: Yup.boolean().default(false),
  budget_id: Yup.number().when(['has_budget'], {
    is: (has_budget) => has_budget,
    then: (schema) => schema.required('* Seleccione un presupuesto').nonNullable(),
    otherwise: (schema) => schema,
  }),
  business_unit_id: Yup.number().required('* Seleccione una unidad de negocio').nonNullable(),
  customer_supplier_id: Yup.number().required('* Seleccione un cliente').nonNullable(),
  seller_id: Yup.number().required('* Seleccione un vendedor').nonNullable(),
  payment_condition_id: Yup.number().required('* Seleccione una condición de pago').nonNullable(),
  issue_date: Yup.string().required('* Ingrese una fecha de emisión').nonNullable(),
  dispatch_date: Yup.string(),
  de_blister: Yup.boolean().default(false),
  billing_type_id: Yup.number().required('* Seleccione un tipo de facturación').nonNullable(),
  billing_date: Yup.string().when(['billing_type_id'], {
    is: (billing_type_id) => billing_type_id === BillingTypesI.DIFERIDA,
    then: (schema) => schema.required('* Ingrese una fecha de facturación').nonNullable(),
    otherwise: (schema) => schema,
  }),
})

const submitAdapter = (values) => {
  let data = {
    ...values,
    budget_id: values.budget_id ? values.budget_id : null,
    billing_date: values?.billing_date && values.billing_date !== '' ? values.billing_date : null,
  }
  delete data.has_budget
  delete data.budget
  delete data.customer_supplier_name
  delete data.bidding
  delete data.bidding_value

  return data
}

export {
  initialValues,
  schema,
  submitAdapter,
}