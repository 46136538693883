import { useState, useCallback, useEffect } from 'react'
import { Formik } from 'formik'
import { Modal, FormLabel, Button } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'

import { useCustomerSuppliers } from 'hooks'
import { InputBS } from 'components'

import { addSupplierInitialValues } from '../constants'

const SupplierModal = ({ show, onClose, onSave }) => {
  const { loading: sLoading, handleGetCustomerSuppliers } = useCustomerSuppliers()
  const [options, setOptions] = useState([])

  const getSuppliers = useCallback(async (query) => {
    try {
      let params = {
        is_supplier: 1,
        text: query,
      }

      const response = await handleGetCustomerSuppliers('token', null, params)
      setOptions(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetCustomerSuppliers])

  const handleSelected = async (selected, setFieldValue) => {
    setFieldValue()
  }

  useEffect(() => {
    getSuppliers()
  }, [getSuppliers])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Añadir Proveedor</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={addSupplierInitialValues}
          // validationSchema={{}}
          onSubmit={(values, actions) => {
            onSave(values)
          }}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <>
                <div className='row mb-8'>
                  <div className='col'>
                    <FormLabel style={{ fontSize: '1.05rem', fontWeight: '500', color: 'var(--bs-gray-800)' }}>Proveedor</FormLabel>
                    <AsyncTypeahead
                      id='supplierSearch'
                      isLoading={sLoading}
                      minLength={3}
                      labelKey={(o) => `${o.documentation} - ${o.business_name}`}
                      onSearch={getSuppliers}
                      options={options}
                      filterBy={() => true}
                      prompText='Escriba el documento y/o nombre del proveedor'
                      searchText='Buscando...'
                      placeholder='Escriba el documento o nombre del proveedor'
                      onChange={(e) => {
                        if (e && e.length > 0) {
                          console.log(e)
                          setFieldValue('supplier_id', e[0].id)
                          setFieldValue('supplier_business_name', e[0].business_name)
                          setFieldValue('supplier_documentation', e[0].documentation)
                        }
                      }}
                    />
                  </div>
                </div>

                <div className='text-end'>
                  <Button variant='primary' type='button' onClick={handleSubmit}>Añadir</Button>
                </div>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default SupplierModal