import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment'

import { useAuth } from 'app/modules/auth'
import { usePatients } from 'hooks'

import { Input, SelectField, SwitchField } from 'components'

const initialValues = {
  id: '',
  nombre: '',
  documento: '',
  is_enabled: '',
  sin_documento: 0,
  sin_nro_afiliado: 0,
  cliente_asociado: '',
}

const Filter = ({ handleData, query, handleQuery, handleTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetPatients } = usePatients()

  const filterData = (values) => {
    const params = {
      ...query,
      ...values,
      id: values?.id ? values.id : undefined,
      nombre: values?.nombre ? values.nombre : undefined,
      documento: values?.documento ? values.documento : undefined,
      is_enabled: values?.is_enabled ? values.is_enabled : undefined,
      sin_documento: values?.sin_documento ? values.sin_documento : undefined,
      sin_nro_afiliado: values?.sin_nro_afiliado ? values.sin_nro_afiliado : undefined,
      cliente_asociado: values?.cliente_asociado ? values.cliente_asociado : undefined,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = filterData(values)
      const response = await handleGetPatients('', '', params)

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        patients: { ...params },
      }))

      handleQuery({ ...params, pageNumber: 1, pageSize: 10 })
      handleData(response.data.result)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  const exportData = async (values) => {
    try {
      let params = filterData(values)
      params = {
        ...params,
        pageNumber: 1,
        pageSize: undefined,
      }

      const response = await handleGetPatients('', '', params)
      const data = response.data.result.map(d => {
        const { contact, health_insurance, ...rest } = d

        const patient = {
          'ID': d.id,
          'Nombre completo': `${rest.first_name} ${rest.last_name}`,
          'Tipo documento': rest.documentation_type_name,
          'Documento': rest.documentation,
          'F. nacimiento': rest?.birthdate ? moment(rest.birthdate).format('DD/MM/YYYY') : '',
          'Género': rest.genre_label,
          'ID Obra social': health_insurance?.health_insurance_id || '',
          'Obra social': health_insurance?.health_insurance_id ? health_insurance.health_insurance_name : '',
          'Nro. afiliado': health_insurance?.affiliate_number || '',
          'Contacto - Nombre': contact?.name || '',
          'Contacto - Email': contact?.email || '',
          'Contacto - Notificaciones': contact?.has_notifications ? 'Sí' : 'No',
          'Contacto - Teléfono': contact?.phone || '',
          'Contacto - Celular': `${contact?.mobile_suffix ? `${contact.mobile_suffix} ` : ''}${contact?.mobile || ''}`,
          'Contacto - Observaciones': contact?.observations || '',
        }

        return patient
      })

      const ws = XLSX.utils.json_to_sheet(data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Pacientes");
      XLSX.writeFile(wb, `Pacientes ${moment().format("YYYYMMDD-HHmm")}.xlsx` );
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { patients } = currentUser

      setInitialFormikValues({
        ...initialValues,
        id: patients?.id || '',
        nombre: patients?.nombre || '',
        documento: patients?.documento || '',
        is_enabled: patients?.is_enabled || '',
        sin_documento: patients?.sin_documento || 0,
        sin_nro_afiliado: patients?.sin_nro_afiliado || 0,
        cliente_asociado: patients?.cliente_asociado || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form className='d-flex flex-column'>
          <div className='row'>
            <div className='col-2'>
              <Input id='id' name='id' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-6'>
              <Input id='nombre' name='nombre' disabled={loading} />
              <p className='text-muted'>Nombre completo</p>
            </div>

            <div className='col-2'>
              <Input id='documento' name='documento' disabled={loading} />
              <p className='text-muted'>Documento / Nro. afiliado</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='is_enabled'
                name='is_enabled'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Habilitado' },
                  { value: '0', label: 'Deshabilitado' },
                ]}
                marginTop={'-20px'}
                placeholder='Seleccionar'
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className="col-2 pt-3">
              <div className="d-flex">
                <SwitchField
                  id='sin_documento'
                  name='sin_documento'
                  disabled={loading}
                />
                <span style={{ fontSize: '1.1rem' }}>sin Documento</span>
              </div>
            </div>

            <div className="col-2 pt-3">
              <div className="d-flex">
                <SwitchField
                  id='sin_nro_afiliado'
                  name='sin_nro_afiliado'
                  disabled={loading}
                />
                <span style={{ fontSize: '1.1rem' }}>sin Nro. afiliado</span>
              </div>
            </div>

            <div className='col-8'>
              <Input id='cliente_asociado' name='cliente_asociado' disabled={loading} />
              <p className='text-muted'>ID / Cliente asociado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                className='me-3'
                onClick={() => exportData(values)}
                disabled={loading}
              >
                <i className="bi bi-file-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='button'
                variant="secondary"
                className='me-3'
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button  variant="primary" type='submit' disabled={loading}>
                <i className="bi bi-search mb-1"/>
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter