import React from 'react'
import './styles.sass'

const Loading = () => {
  return (
    <div id="modalLoading" className="modal-loading text-center">
      <div className="modal-loading-content">
        <p>
          <img src="/images/drofar-2.gif" alt="Drofar's loading"/>
        </p>
      </div>
    </div>
  )
}

export default Loading