import * as Yup from 'yup'
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import moment from 'moment'

import { formatInputDate, currentDate, currencyFormat } from 'utilities'
import { HomeDeliveryOptionI } from 'interfaces';

const initialValues = {
  user_id: '',
  sales_order_status_id: '',
  sales_order_status_name: '',
  sales_order_status_label: '',
  has_budget: false,
  budget_id: '',
  budget: '',
  customer_supplier_id: '',
  customer_supplier_business_name: '',
  business_unit_id: '',
  seller_id: '',
  payment_condition_id: '',
  issue_date: currentDate,
  dispatch_date: '',
  bidding: false,
  bidding_value: '',
  de_blister: false,
  billing_type_id: '',
  billing_date: '',
  purchase_order: '',
  search_product_var: '',
  detail: [],
  price_update_id: '',
  delivery_cost: 0,
  total: 0,
  total_profitability: 0,
  total_delivery_cost: 0,
  requires_coordination: false,
  to_patient: false,
  home_delivery_option_id: '',
  patient_id: '',
  address_id_ship: '',
  address_id_traza: '',
  observations: '',
  dispatch_observations: '',
  created_at: '',
  user_creation_name: '',
  updated_at: '',
  updated_by_user_id: '',
  user_update_name: '',
}

const schema = Yup.object().shape({
  budget_id: Yup.number(),
  customer_supplier_id: Yup.number().required('* Seleccione un cliente').nonNullable(),
  seller_id: Yup.number().required('* Seleccione un vendedor').nonNullable(),
  payment_condition_id: Yup.number().required('* Seleccione una condición de pago').nonNullable(),
  issue_date: Yup.string().required('* Ingrese una fecha de emisión').nonNullable(),
  dispatch_date: Yup.string(),
  de_blister: Yup.boolean().default(false),
  requires_coordination: Yup.boolean().default(false),
  to_patient: Yup.boolean().default(false),
  observations: Yup.string().nullable(),
  dispatch_observations: Yup.string().nullable(),
});

const detailDataAdapter = (data) => {
  const detail = data.map(article => {
    return {
      id: article.id,
      product_var_id: article.product_var_id,
      product_var_desc: article.name,
      line: article?.line ? article.line : '',
      actual_pvp: article.pvp,
      list_cost: article.list_cost,
      qty: article.qty,
      units: article.units,
      discount: Number(article.discount),
      price: article.price,
      profitability: article.profitability,
      profitability_rate: article.profitability_rate,
      vat: article.vat,
      vat_rate: article.vat_rate,
      vat_amount: article.vat_amount,
      total: article.total ,
      subtotal: article.subtotal,
      outdated_pvp: Boolean(article.outdated_pvp),
      pvp_original:article.pvp_original
    }
  })

  return detail
}

const dataAdapter = (values) => {
  const detail = detailDataAdapter(values.detail)

  let data = {
    ...values,
    has_budget: values?.budget_id ? true : false,
    budget_id: values?.budget_id ? values.budget_id : '',
    purchase_order: values.purchase_order || '',
    budget: values.budget_id ? `Presupuesto #${values.budget_id}` : '',
    customer_supplier_business_name: values.customer_supplier_business_name,
    business_unit_id: values.business_unit_id,
    seller_id: values.seller_id,
    payment_condition_id: values.payment_condition_id,
    issue_date: formatInputDate(values.issue_date),
    dispatch_date: values?.dispatch_date ? moment(values.dispatch_date).format('YYYY-MM-DD') : '',
    // dispatch_date: formatInputDate(values.dispatch_date),
    de_blister: Boolean(values.de_blister),
    billing_type_id: values.billing_type_id,
    billing_date: formatInputDate(values.billing_date),
    lines: values.lines,
    price_update_id: Number(values.price_update_id),
    detail: detail,
    delivery_cost: Number(values.delivery_cost).toFixed(2),
    total: Number(values.total).toFixed(2),
    total_profitability: Number(values.total_profitability).toFixed(5),
    total_delivery_cost: (Number(values.total) + Number(values.delivery_cost)).toFixed(2),
    requires_coordination: Boolean(values.requires_coordination),
    to_patient: Boolean(values.to_patient),
    home_delivery_option_id: Number(values.home_delivery_option_id),
    patient_id: values?.patient_id ? values.patient_id : '',
    patient_name: values?.patient_id ? `[${values.patient_id}] ${values?.patient_documentation ? `${values.patient_documentation} - ${values.patient_name}` : `${values.patient_name}`}${values?.patient_affiliate_number ? ` | Nro. afiliado: ${values.patient_affiliate_number}` : ''}` : '',
    address_id_ship: values?.address_id_ship ? values.address_id_ship : '',
    address_id_traza: values?.address_id_traza ? values.address_id_traza : '',
    observations: values?.observations ? values.observations : '',
    dispatch_observations: values?.dispatch_observations ? values.dispatch_observations : '',
    created_at: formatInputDate(values.created_at),
    updated_at: values?.updated_at ? formatInputDate(values.updated_at) : '',
    user_update_name: values?.user_update_name ? values.user_update_name : '',
    cancel_at: values?.cancel_at ? formatInputDate(values.cancel_at) : '',
    direccion_traza:
      values?.address_id_traza
        ? `${values?.nombre_traza} - ${values?.domicilio_traza?.trim()}, ${values?.localidad_traza?.trim()}${values?.gln_traza ? ` | GLN: ${values.gln_traza}` : ''}`
        : (values?.home_delivery_option_id == HomeDeliveryOptionI.NO || values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA)
          ? 'Seleccione una dirección de entrega'
          : 'Seleccione una dirección de trazabilidad',
    direccion_ship:
      values?.address_id_ship
        ? `${values?.nombre_ship} - ${values?.domicilio_ship?.trim()}, ${values?.localidad_ship?.trim()}${values?.gln_ship ? ` | GLN: ${values.gln_ship}` : ''}`
        : 'Seleccione un domicilio de entrega',
  }

  return data
}

const filterNewArticles = (detail, newArticles) => {
  const ids = detail.map(d => d.product_var_id)

  const newFilteredArticles = newArticles.filter((newArticle) => !ids.includes(newArticle.id))

  return newFilteredArticles
}

const vatOptions = [
  { value: 1, label: 'No Grav.', percent: 0 },
  { value: 2, label: 'Exento', percent: 0 },
  { value: 3, label: '0%', percent: 0 },
  { value: 9, label: '2.5%', percent: 2.5 },
  { value: 8, label: '5%', percent: 5 },
  { value: 4, label: '10.5%', percent: 10.5 },
  { value: 5, label: '21%', percent: 21 },
  { value: 6, label: '27%', percent: 27 }
]

const specialDeliveryOptions = [
  { value: 1, label: 'No'},
  { value: 2, label: 'A paciente'},
  { value: 3, label: 'A cliente'},
  { value: 4, label: 'Farmacia'},
]

const updateDataAdapter = (data) => {
  let values = {
    ...data,
    budget_id: data?.budget_id ? data.budget_id : null,
    delivery_cost: data?.delivery_cost ? Number(data.delivery_cost) : 0,
    patient_id: data?.patient_id ? data.patient_id : null,
    address_id_ship: data?.address_id_ship ? data.address_id_ship : null,
    address_id_traza: data?.address_id_traza ? data.address_id_traza : null,
  }

  delete values.sales_order_status_label
  delete values.sales_order_status_name
  delete values.order_type_name
  delete values.order_type_label
  delete values?.customer_supplier_business_name
  delete values?.customer_supplier_name
  delete values.customer_supplier_documentation
  delete values.customer_supplier_province_id
  delete values.has_budget
  delete values.budget
  delete values.business_unit_name
  delete values.seller_name
  // delete values.bidding
  // delete values.bidding_value

  delete values.search_product_var
  delete values.detail
  delete values.lines
  delete values.subtotal
  delete values.total
  delete values.total_calc
  delete values.total_profitability
  delete values.total_delivery_cost
  delete values.iibb
  delete values.iva

  delete values.requires_home_delivery
  delete values.patient_documentation
  delete values.patient_name
  delete values.patient_affiliate_number

  delete values.created_at
  delete values.updated_at
  delete values.cancel_at
  delete values.cancel_by_user_id
  delete values.user_creation_name
  delete values.user_update_name

  delete values.customer_supplier_payment_condition_label
  delete values.customer_supplier_credit
  delete values.payment_condition_label

  delete values.por_unidad

  delete values.nombre_traza
  delete values.domicilio_traza
  delete values.localidad_traza
  delete values.direccion_traza
  delete values.nombre_ship
  delete values.domicilio_ship
  delete values.localidad_ship
  delete values.direccion_ship

  return values
}

const columnsSellOrder = [
  {
    Header: "Artículo",
    accessor: 'product_var_desc',
  },
  {
    Header: () => (<div style={{textAlign:"center"}}>Precio base</div>),
    id: 'actual_pvp',
    accessor: (row) => (
      <div className='text-end'>{currencyFormat.format(row.actual_pvp) || ''}</div>
    )
  },
  {
    Header: '',
    id: 'alerts',
    className:'p-0',
    accessor: (row) => (      
      <>
          {row.por_unidad ? (+row.actual_pvp != +row.pvp_original/row.qty) : (+row.actual_pvp != +row.pvp_original)
              ?
            <OverlayTrigger placement="left"
              overlay={
                <Tooltip id="tooltip">
                  <div>Precio base distinto al PVP</div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Precio base</th>
                        <th>PVP</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td>{parseFloat(row.actual_pvp).toFixed(2)}</td>
                          <td>{parseFloat(row.pvp_original).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
              </Tooltip>
            }
          >
            <span ><i className='bi bi-exclamation-triangle text-danger'></i></span>
          </OverlayTrigger>

          :
          <></>
        }
      </>
    )
  },
  {
    Header: () => (<div style={{textAlign:"center"}}>Costo lista</div>),
    id: 'list_cost',
    accessor: (row) => (
      <div className='text-end'>{currencyFormat.format(row.list_cost) || ''}</div>
    )
  },
  {
    Header: () => (<div style={{textAlign:"center"}}>Descuento</div>),
    id: 'discount',
    accessor: (row) => (
      <div className='text-end'>{parseFloat(row.discount).toFixed(2) || ''} %</div>
    )
  },
  {
    Header: () => (<div style={{textAlign:"center"}}>P. caja</div>),
    id: 'price',
    accessor: (row) => (
      <div className='text-end'>{currencyFormat.format(row.price) || ''}</div>
    )
  },
  {
    Header: () => (<div style={{textAlign:"center"}}>Rentabilidad</div>),
    id: 'profitability_rate',
    accessor: (row) => (
      <div className='text-end'>{parseFloat(row.profitability_rate).toFixed(2) || ''} %</div>
    )
  },
  {
    Header: () => (<div style={{textAlign:"center"}}>Cajas</div>),
    id:"qty",
    accessor: (row) => (
      <div className='text-center'>{row.qty || ''}</div>
    )
  },
  {
    Header: () => (<div style={{textAlign:"center"}}>Unidades</div>),
    id:"units_total",
    accessor: (row) => (
      <div className='text-center'>{row.units_total || ''}</div>
    )
  },
  {
    Header:  () => (<div style={{textAlign:"center"}}>Subtotal</div>),
    id: 'subtotal',
    accessor: (row) => (
      <div className='text-end'>{currencyFormat.format(row.subtotal) || ''}</div>
    )
  },
  {
    Header:  () => (<div style={{textAlign:"center"}}>IVA</div>),
    id: 'vat',
    accessor: (row) => (
      <>
        <p className='text-end m-0'>{currencyFormat.format(row.vat_amount) || ''}</p>
        <p className='text-end m-0'>{'(' + vatOptions.find(v => v.value === row.vat).label + ')'}</p>
      </>
    )
  },
  {
    Header:  () => (<div style={{textAlign:"center"}}>Total</div>),
    id: 'total',
    accessor: (row) => (
      <div className='text-end'>{currencyFormat.format(row.total)}</div>
    )
  },
]

const columnsPedidoEgreso = [
  {
    Header: "Artículo",
    accessor: 'product_var_desc',
  },
  {
    Header: "PVP",
    id: 'actual_pvp',
    accessor: (row) => (
      <span>{currencyFormat.format(row.actual_pvp)}</span>
    )
  },
  {
    Header: "PSL",
    id: 'psl',
    accessor: (row) => (
      <span>{currencyFormat.format(row.psl)}</span>
    )
  },
  {
    Header: "Cantidad",
    id: 'qty',
    accessor: (row) => (
      <span>{row.qty}</span>
    )
  },
]


export {
  initialValues,
  schema,
  dataAdapter,
  filterNewArticles,
  // addArticlesInDetailAdapter,
  updateDataAdapter,
  vatOptions,
  specialDeliveryOptions,
  columnsSellOrder,
  columnsPedidoEgreso
}