import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useOutOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter, CoordinationStatusModal } from './partials'
import { COLUMNS } from './columns'

import { OutOrderStatusI, OutOrderCoordinationStatusI, OutOrderCoordinationStatusLabelI, RoleI } from 'interfaces'
import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  out_order_status_id: OutOrderStatusI.COORDINACION_ENTREGA,
}

const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-celeste";
    case OutOrderStatusI.EN_REVISION:
      return "estado-naranja";
    case OutOrderStatusI.CANCELADO:
      return "estado-rojo";
    default:
      return ""
  }
}

const coordinationLabel = (coordination_status) => {
  switch (coordination_status) {
    case OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION:
    case OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2:
      return OutOrderCoordinationStatusLabelI.PENDIENTE_DE_COORDINACION
    case OutOrderCoordinationStatusI.EN_PROGRESO:
      return OutOrderCoordinationStatusLabelI.EN_PROGRESO
    case OutOrderCoordinationStatusI.FALTAN_DATOS:
      return OutOrderCoordinationStatusLabelI.FALTAN_DATOS
    case OutOrderCoordinationStatusI.PENDIENTE_DE_RESPUESTA:
      return OutOrderCoordinationStatusLabelI.PENDIENTE_DE_RESPUESTA
    case OutOrderCoordinationStatusI.PENDIENTE_VENTAS:
      return OutOrderCoordinationStatusLabelI.PENDIENTE_VENTAS
    case OutOrderCoordinationStatusI.COORDINADO:
      return OutOrderCoordinationStatusLabelI.COORDINADO
    case OutOrderCoordinationStatusI.RECOORDINACION:
      return OutOrderCoordinationStatusLabelI.RECOORDINACION
    case OutOrderCoordinationStatusI.SIN_RESPUESTA:
      return OutOrderCoordinationStatusLabelI.SIN_RESPUESTA
    default:
      return ''
  }
}

const coordinationLabelColor = (coordination_status) => {
  switch (coordination_status) {
    case OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION:
    case OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2:
      return 'estado-gris';
    case OutOrderCoordinationStatusI.EN_PROGRESO:
      return "estado-azul";
    case OutOrderCoordinationStatusI.FALTAN_DATOS:
      return "estado-naranja";
    case OutOrderCoordinationStatusI.PENDIENTE_DE_RESPUESTA:
    case OutOrderCoordinationStatusI.PENDIENTE_VENTAS:
      return "estado-amarillo";
    case OutOrderCoordinationStatusI.COORDINADO:
      return "estado-verde";
    case OutOrderCoordinationStatusI.RECOORDINACION:
      return "estado-celeste";
    case OutOrderCoordinationStatusI.SIN_RESPUESTA:
      return 'estado-rojo';
    default:
      return "estado-gris"
  }
}

const Coordination = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetOutOrders, handleUpdateOutOrderStatus, handleUpdateOutOrderCoordination } = useOutOrders()
  const [data, setData] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [modal, setModal] = useState(false)

  const getOutOrders = useCallback(async () => {
    try {
      let params = {
        getDetail: true,
        sin_total: true,
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.user_id && currentUser?.coordination) {
        params = {
          ...params,
          id_text: currentUser.coordination?.id_text || '',
          from_date: currentUser.coordination?.from_date || '',
          to_date: currentUser.coordination?.to_date || '',
          from_dispatch_date: currentUser.coordination?.from_dispatch_date || '',
          to_dispatch_date: currentUser.coordination?.to_dispatch_date || '',
          nombre: currentUser.coordination?.nombre || '',
          documento: currentUser.coordination?.documento || '',
          paciente: currentUser.coordination?.paciente || '',
          paciente_documento: currentUser.coordination?.paciente_documento || '',
          article_text: currentUser.coordination?.article_text || '',
          direccion: currentUser.coordination?.direccion || '',
          localidad: currentUser.coordination?.localidad || '',
          provincia: currentUser.coordination?.provincia || '',
          coordination_status_id_filter: currentUser.coordination?.coordination_status_id_filter || [],
          coordination_user_id_filter: currentUser.coordination?.coordination_user_id_filter || '',
        }
      }

      const response = await handleGetOutOrders(params)

      const dataColored = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.out_order_status_id),
        coordination_label: coordinationLabel(d.coordination_status_name),
        coordination_label_color: coordinationLabelColor(d.coordination_status_name),
      }))
      setData(dataColored)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetOutOrders, query])

  const updateOutOrderCoordination = async (out_order_id, coordination_status, coordination_user_id) => {
    try {
      const data = {
        coordination_status: coordination_status,
        coordination_user_id: coordination_user_id,
      }
      const response = await handleUpdateOutOrderCoordination(out_order_id, data)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getOutOrders()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()
  const navigate = useNavigate()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: actionEdit
    })

    if (currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE || r.id == RoleI.COORDINACION)) {
      if (currentRow?.out_order_status_id === OutOrderStatusI.COORDINACION_ENTREGA) {
        actions.push({
          label: "Enviar a armado",
          icon: "bi bi-boxes text-success",
          action: actionReloadOrder
        })

        actions.push({
          label: "Enviar a revisión",
          icon: "bi bi-clipboard-check text-primary",
          action: sendToRevision
        })

        actions.push({
          label: 'Actualizar estado de coordinación',
          icon: 'bi bi-file-post text-primary',
          action: () => setModal(true)
        })
      }
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.OUT_ORDERS}/${currentRow.id}`, {state:{referer:'coordinacion'}})
    }
  }

  const actionReloadOrder = async () => {
    try {
      if (currentRow) {
        const resp = await Swal.fire({
          title: "Advertencia",
          text: `Va a enviar a preparar la órden #${currentRow.id}, ¿confirma?`,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No",
          confirmButtonText: "Si, enviar a armado",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          await handleUpdateOutOrderStatus(currentRow.id, OutOrderStatusI.PENDIENTE_PREPARACION)
          toast.success("La órden se envió a armado", { theme: "colored" })
          getOutOrders()
        }
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const sendToRevision = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a enviar a revisión a la órden #${currentRow.id}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si, enviar a revisión",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handleUpdateOutOrderStatus(currentRow.id, OutOrderStatusI.EN_REVISION)
        toast.success("La órden se envió a revisión", { theme: "colored" })
        getOutOrders()
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      ),
    }
  ], [COLUMNS, getOutOrders])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getOutOrders()
  }, [getOutOrders])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Coordinación'}
        filter={<Filter handleData={setData} query={query} handleQuery={setQuery} handleTotalCount={setTotalCount} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />

      <CoordinationStatusModal
        show={modal}
        onClose={() => setModal(false)}
        values={currentRow}
        onSubmit={(data) => {
          updateOutOrderCoordination(currentRow?.id, data.coordination_status, data.coordination_user_id)
          setModal(false)
        }}
      />
    </>
  )
}

export default Coordination