const PROVINCES = Object.freeze({
  CABA: 1,
  BUENOS_AIRES: 2,
  CATAMARCA: 3,
  CORDOBA: 4,
  CORRIENTES: 5,
  ENTRE_RIOS: 6,
  JUJUY: 7,
  MENDOZA: 8,
  LA_RIOJA: 9,
  SALTA: 10,
  SAN_JUAN: 11,
  SAN_LUIS: 12,
  SANTA_FE: 13,
  SANTIAGO_DEL_ESTERO: 14,
  TUCUMAN: 15,
  CHACO: 16,
  CHUBUT: 17,
  FORMOSA: 18,
  MISIONES: 19,
  NEUQUEN: 20,
  LA_PAMPA: 21,
  RIO_NEGRO: 22,
  SANTA_CRUZ: 23,
  TIERRA_DEL_FUEGO: 24,
});

const PROVINCES_COD = Object.freeze({
  [PROVINCES.CABA]: '0',
  [PROVINCES.BUENOS_AIRES]: '1',
  [PROVINCES.CATAMARCA]: '2',
  [PROVINCES.CORDOBA]: '3',
  [PROVINCES.CORRIENTES]: '4',
  [PROVINCES.ENTRE_RIOS]: '5',
  [PROVINCES.JUJUY]: '6',
  [PROVINCES.MENDOZA]: '7',
  [PROVINCES.LA_RIOJA]: '8',
  [PROVINCES.SALTA]: '9',
  [PROVINCES.SAN_JUAN]: '10',
  [PROVINCES.SAN_LUIS]: '11',
  [PROVINCES.SANTA_FE]: '12',
  [PROVINCES.SANTIAGO_DEL_ESTERO]: '13',
  [PROVINCES.TUCUMAN]: '14',
  [PROVINCES.CHACO]: '16',
  [PROVINCES.CHUBUT]: '17',
  [PROVINCES.FORMOSA]: '18',
  [PROVINCES.MISIONES]: '19',
  [PROVINCES.NEUQUEN]: '20',
  [PROVINCES.LA_PAMPA]: '21',
  [PROVINCES.RIO_NEGRO]: '22',
  [PROVINCES.SANTA_CRUZ]: '23',
  [PROVINCES.TIERRA_DEL_FUEGO]: '24',
});

export {
  PROVINCES,
  PROVINCES_COD,
}