import { useState, useCallback } from 'react'

import { Users } from 'services'

const useUsers = () => {
  const [loading, setLoading] = useState(false);

  const handleGetUsers = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Users.getUsers(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetUser = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Users.getUser(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetUsers,
    handleGetUser,
  }
}

export default useUsers