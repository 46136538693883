import React from 'react'
import { formatTableDate } from 'utilities'

const IncomesColumns = () => {
  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <>
          {row.id}
        </>
      ),
    },
    {
      Header: 'Fecha',
      id: 'created_at',
      accessor: (row) => (
        <>
          {formatTableDate(row.created_at)}
        </>
      ),
    },
    {
      Header: 'Remito',
      id: 'waybill_number',
      accessor: (row) => {
        if (row?.waybill_number) {
          return row.waybill_number
        }

        return (
          <div className='text-center'>
            -
          </div>
        )
      }
    },
    {
      Header: 'Factura',
      id: 'supplier_invoice',
      accessor: (row) => {
        if (row?.supplier_invoice) {
          return row.supplier_invoice
        }

        return (
          <div className='text-center'>
            -
          </div>
        )
      }
    },
    {
      Header: 'Remitente',
      id: 'remitente',
      accessor: (row) => (
        <ul className='list-style-none m-0 p-0'>
          <li className='mb-2'><strong>{row.shipment_address_name}</strong> - {row.formatted_address}</li>
          <li><strong>GLN:</strong> {row.shipment_address_gln}</li>
          {/* Remitente (direcciones envío proveedor) */}
        </ul>
      ),
    },
    {
      Header: <div className='text-center'>Estado</div>,
      id: 'order_status_label',
      className:'col-status',
      accessor: (row) => (
        <span className={ `badge ${row.label_color} w-100 justify-content-center fw-normal`}>
          {row.order_status_label}
        </span>
      ),
    },
  ]

  return {
    COLUMNS
  }
}

export default IncomesColumns