import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useClickOutside, usePaymentConditions } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { useGetColumns } from './constants'
import routes from 'app/routing/routes'

const PaymentConditions = () => {
  const navigate = useNavigate()

  const { handleGetPaymentConditions, handleUpdatePaymentCondition } = usePaymentConditions()
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const intl = useIntl()

  const getPaymentConditions = useCallback(async () => {
    try {
      const response = await handleGetPaymentConditions()
      setData(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetPaymentConditions])

  const enableDisablePaymentCondition = async (id, is_enabled) => {
    try {
      let data = {
        is_enabled: !Boolean(is_enabled)
      }
      await handleUpdatePaymentCondition(id, data, 'token')

      let message = `Condición de pago ${Boolean(is_enabled) ? 'deshabilitada' : 'habilitada'} correctamente`
      toast.info(message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getPaymentConditions()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Editar",
      icon: "bi bi-pencil-square text-primary",
      action: () => navigate(`${routes.PAYMENT_CONDITIONS}/${currentRow?.id}`)
    })

    actions.push({
      label: Boolean(currentRow?.is_enabled) ? 'Deshabilitar' : 'Habilitar',
      icon: `${Boolean(currentRow?.is_enabled) ? 'bi-x-circle text-danger' : 'bi-check2-circle text-success'} `,
      action: () => enableDisablePaymentCondition(currentRow?.id, currentRow?.is_enabled)
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
      ),
    },
  ], [COLUMNS])

  useEffect(() => {
    getPaymentConditions()
  }, [handleGetPaymentConditions])

  useEffect(() => {}, [data])

  return (
    <>
      <TableContainer title={intl.formatMessage({ id: 'TITLE.PAYMENT_CONDITIONS' })} goTo={routes.PAYMENT_CONDITIONS_NEW}>
        <CustomTable columns={columns} data={data} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default PaymentConditions