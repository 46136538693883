import { useState, useCallback, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useDocumentationTypes, useGenres, useHealthInsurances } from 'hooks'
import { SelectField, InputBS, FormTable } from 'components'

import { ContactModal } from 'containers/Modals'

import { addPatient, schema } from './constants'
import { formatInputDate } from 'utilities'

const PatientModal = ({ show, onHide, values = addPatient, onSave }) => {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState({})
  const [contactModal, setContactModal] = useState(false)

  const [documentationTypes, setDocumentationTypes] = useState([])
  const { handleGetDocumentationTypes } = useDocumentationTypes()

  const { handleGetGenres } = useGenres()
  const [genres, setGenres] = useState([])

  // const [healthInsurances, setHealthInsurances] = useState([])
  // const { handleGetHealthInsurances } = useHealthInsurances()

  const getDocumentationTypes = useCallback(async () => {
    try {
      const response = await handleGetDocumentationTypes('token')

      setDocumentationTypes(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetDocumentationTypes])

  const getGenres = useCallback(async () => {
    try {
      const response = await handleGetGenres()
      setGenres(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetGenres])

  // const getHealthInsurances = useCallback(async () => {
  //   try {
  //     let params = {
  //       is_enabled: 1,
  //     }
  //     const response = await handleGetHealthInsurances(params)

  //     setHealthInsurances(response.data.result)
  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // }, [handleGetHealthInsurances])

  useEffect(() => {
    getDocumentationTypes()
    getGenres()
    // getHealthInsurances()
  }, [getDocumentationTypes, getGenres, /*getHealthInsurances*/])

  useEffect(() => {
    setInitialValues({
      ...addPatient,
      ...values,
      birthdate: values?.birthdate ? formatInputDate(values.birthdate) : '',
      health_insurance: {
        ...addPatient.health_insurance,
        ...values?.health_insurance,
      },
      contact: {
        ...addPatient.contact,
        ...values?.contact,
      }
    })
  }, [setInitialValues, values])

  if (!show) return null

  return (
    <Modal show={show} onHide={onHide} backdrop='static' size='xl' centered>
      <Modal.Header closeButton>
        <Modal.Title>{values?.id ? `Editar paciente #${values.id}` : 'Nuevo paciente'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          // validationSchema={schema}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-3'>
                    <InputBS id='first_name' name='first_name' label={intl.formatMessage({ id: 'FORM.PATIENTS.FIRST_NAME' })} />
                  </div>

                  <div className='col-3'>
                    <InputBS id='last_name' name='last_name' label={intl.formatMessage({ id: 'FORM.PATIENTS.LAST_NAME' })} />
                  </div>

                  <div className='col-3'>
                    <SelectField
                      id='documentation_type_id'
                      name='documentation_type_id'
                      label={intl.formatMessage({ id: 'FORM.PATIENTS.DOCUMENTATION_TYPE' })}
                      options={[{ value: '', label: 'Selecionar tipo de documento' }, ...documentationTypes.map(item => { return { value: item.id, label: item.type }})]}
                      placeholder='Selecionar tipo de documento'
                    />
                  </div>

                  <div className='col-3'>
                    <InputBS id='documentation' name='documentation' label={intl.formatMessage({ id: 'FORM.PATIENTS.DOCUMENTATION' })}  maxLength='11' />
                  </div>

                </div>

                <div className='row mb-8'>
                  <div className='col-3'>
                    <InputBS id='birthdate' name='birthdate' type='date' label='Fecha de nacimiento' />
                  </div>

                  <div className='col-3'>
                    <SelectField
                      id='genre_id'
                      name='genre_id'
                      label='Género'
                      options={[{ value: '', label: 'Selecionar género' }, ...genres.map(item => { return { value: item.id, label: item.label }})]}
                      placeholder='Selecionar género'
                    />
                  </div>
                </div>

                <div className='separator my-8' />
                <h4 className='m-0'>Obra social</h4>

                <div className='row my-5'>
                  {/* <div className='col-4'>
                    <SelectField
                      id='health_insurance.health_insurance_id'
                      name='health_insurance.health_insurance_id'
                      label="Obra social"
                      options={[{ value: '' , label: 'Selecionar obra social' } , ...healthInsurances.map(item => { return { value: item.id, label: item.name }})]}
                      placeholder='Seleccionar obra social'
                    />
                  </div> */}

                  <div className='col-6'>
                    <InputBS id='health_insurance.affiliate_number' name='health_insurance.affiliate_number' label={intl.formatMessage({ id: 'FORM.PATIENTS.HEALTH_INSURANCE.AFFILIATE_NUMBER' })} />
                  </div>

                  {/* <div className='col-5'>
                    <InputBS id='health_insurance.plan' name='health_insurance.plan' label={intl.formatMessage({ id: 'FORM.PATIENTS.HEALTH_INSURANCE.PLAN' })} />
                  </div> */}
                </div>

                <div className='separator my-8' />
                <div className='my-8'>
                  <div className='d-flex justify-content-between align-items-center mb-8'>
                    <label className='form-label m-0'>Datos de contacto</label>

                    <div>
                      <Button className='BSButton' variant='outline-primary' type='button' onClick={() => setContactModal(true)} >{values.contact_id && values.contact_id !== '' ? 'Editar datos de contacto' : 'Añadir datos de contacto'}</Button>
                    </div>
                  </div>
                </div>

                <ContactModal
                  show={contactModal}
                  onClose={() => setContactModal(false)}
                  values={values}
                  onSave={values => {
                    if (values.contact && values.contact.name == '') {
                      toast.error('No se ingresó el nombre del contacto')
                      return
                    }
                    setFieldValue('contact', values.contact)
                    setContactModal(false)
                  }}
                  submodal
                />

                {values?.contact_id && values.contact_id !== '' &&
                  <FormTable
                    columns={[
                      { header: 'Nombre' },
                      { header: 'Email' },
                      { header: 'Notificaciones', styles: 'text-center' },
                      { header: 'Teléfono' },
                      { header: 'Celular' },
                      { header: '', styles: 'text-center' },
                    ]}
                  >
                    <tr>
                      <td className='align-middle'>{values.contact?.name ? values.contact.name : '-'}</td>
                      <td className='align-middle'>{values.contact?.email ? values.contact.email : '-'}</td>
                      <td className='align-middle text-center'>
                        {Boolean(values.contact.has_notifications)
                          ? <span className="badge badge-success">{intl.formatMessage({ id: 'OPTION.YES' })}</span>
                          : <span className="badge badge-danger">{intl.formatMessage({ id: 'OPTION.NO' })}</span>
                        }
                      </td>
                      <td className='align-middle'>{values.contact?.phone ? values.contact.phone : '-'}</td>
                      <td className='align-middle'>{values.contact?.mobile_suffix ? (values.contact?.mobile ? `${values.contact.mobile_suffix} ${values.contact.mobile}` : values.contact.mobile_suffix) : '-'}</td>
                      <td className='align-middle text-center'>
                        <button type='button' className='btn btn-icon' onClick={() => setContactModal(true)}>
                          <i className='bi bi-pencil-square text-primary'></i>
                        </button>
                      </td>
                    </tr>
                  </FormTable>
                }

                <div className='separator my-8' />
                <div className='d-flex flex-row justify-content-end'>
                  <Button type='button' className='me-3' variant='secondary' onClick={onHide}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button type='submit' variant='primary' onClick={handleSubmit}><i className='bi bi-save me-2' />Guardar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default PatientModal