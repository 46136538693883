


export const parseGS1DataMatrix = (dataMatrix) => {
    const fields = {
        gtin: null,
        serial_number: null,
        lot_number: null,
        expiration_date: null,
      };
    
      // Split the dataMatrix string by the ASCII 29 separator
      const segments = dataMatrix.split('\x1D');
      segments.forEach(segment => {
        let pos = 0;
    
        while (pos < segment.length) {
          const ai = segment.substring(pos, pos + 2);
          pos += 2;
    
          switch (ai) {
            case '01':
              fields.gtin = segment.substring(pos, pos + 14);
              pos += 14;
              break;
            case '17':
              fields.expiration_date = segment.substring(pos, pos + 6);
              pos += 6;
              break;
            case '10':
              let lotEnd = segment.indexOf('\x1D', pos);
              if (lotEnd === -1) lotEnd = segment.length;
              fields.lot_number = segment.substring(pos, lotEnd);
              pos = lotEnd;
              break;
            case '11':
              fields.production_date = segment.substring(pos, pos + 6);
              pos += 6;
              break;
            case '21':
              let serialEnd = segment.indexOf('\x1D', pos);
              if (serialEnd === -1) serialEnd = segment.length;
              fields.serial_number = segment.substring(pos, serialEnd);
              pos = serialEnd;
              break;
            default:
              // Skip unknown AI
              let nextAiPos = segment.search(/\d{2}/, pos);
              if (nextAiPos === -1) break;
              pos = nextAiPos;
          }
        }
      });
    
      return fields;
  };