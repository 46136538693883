import { useState, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useOutOrders, useUsers } from 'hooks'

import { SelectField } from 'components'
import { OutOrderCoordinationStatusI } from 'interfaces'

const CoordinationStatusModal = ({ values, show, onClose, onSubmit }) => {
  const { currentUser } = useAuth()

  const { handleGetCoordinationStatus } = useOutOrders()
  const [coordinationStatus, setCoordinationStatus] = useState([])

  const { handleGetUsers } = useUsers()
  const [users, setUsers] = useState([])

  const [initialValues, setInitialValues] = useState({
    coordination_status: '',
    coordination_user_id: '',
  })

  const getCoordinationStatus = useCallback(async () => {
    try {
      const response = await handleGetCoordinationStatus()
      let data = response.data.result.map(d => ({
        value: d.id,
        name: d.name,
        label: d.label,
      }))

      let all_items = data.filter(d => d.name !== OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2)
      let last_item = data.filter(d => d.name == OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2)

      setCoordinationStatus([...last_item, ...all_items])
    } catch (error) {
      setCoordinationStatus([])
      toast.error(error.message)
    }
  }, [handleGetCoordinationStatus])

  const getUsers = useCallback(async () => {
    try {
      const response = await handleGetUsers()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.name
      }))

      setUsers(data)
    } catch (error) {
      setUsers([])
      toast.error(error.message)
    }
  }, [handleGetUsers])

  useEffect(() => {
    setInitialValues({
      coordination_status: values?.coordination_status || '',
      coordination_user_id: currentUser?.user_id || '',
    })
  }, [values, currentUser.user_id])

  useEffect(() => {
    getCoordinationStatus()
    getUsers()
  }, [getCoordinationStatus, getUsers])

  return (
    <Modal show={show} onHide={onClose} size='lg' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Actualizar coordinación de OE #{values?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={(data) => {
            let formValues = {
              coordination_status: coordinationStatus.find(cs => cs.value == data.coordination_status).name,
              coordination_user_id: data.coordination_user_id,
            }
            onSubmit(formValues)
          }}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-6'>
                    <SelectField
                      id='coordination_status'
                      name='coordination_status'
                      label='Estado coordinación'
                      placeholder='Seleccionar estado'
                      options={coordinationStatus}
                    />
                  </div>

                  <div className='col-6'>
                    <SelectField
                      id='coordination_user_id'
                      name='coordination_user_id'
                      label='Usuario de coordinación'
                      placeholder='Seleccionar usuario'
                      options={users}
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>
                <div className='d-flex flex-row justify-content-end align-items-center'>
                  <Button type='button' variant='secondary' className='me-3' onClick={onClose}><i className='bi bi-slash-circle' />Cancelar</Button>
                  <Button type='submit' variant='primary'><i className='bi bi-save me-2' />Guardar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CoordinationStatusModal