import * as Yup from 'yup'

const initialValues = {
  name: '',
  cancel_date: '',
}

const schema = Yup.object().shape({
  name: Yup.string().required('* Ingrese un nombre para el método de pago'),
  cancel_date: Yup.string(),
})

export {
  initialValues,
  schema
}