import * as Yup from 'yup'
import { currentDate } from 'utilities';

const initialValues = {
  id: null,
  supplier_id: null,
  distributor_id: null,
  distributor_id_aux:-1,
  business_unit_id: null,
  payment_condition_id: "",
  issue_date: currentDate,
  estimated_in_date: '',
  notes: '',
  internal_notes: '',
  order_type_id: null,
  con_distribuidor: false
}

const purchaseSchema = Yup.object().shape({
  supplier_id: Yup.number().required('* Seleccione un proveedor'),
  // distributor_id: Yup.number(),
  business_unit_id: Yup.number().required('* Seleccione una unidad de negocio'),
  payment_condition_id: Yup.number().required('* Seleccione una condición de pago'),
  issue_date: Yup.string(),
  estimated_in_date: Yup.string(),
  notes: Yup.string(),
  internal_notes: Yup.string()
})

const incomeSchema = Yup.object().shape({
  supplier_id: Yup.number().required('* Seleccione un proveedor'),
  business_unit_id: Yup.number().required('* Seleccione una unidad de negocio'),
  payment_condition_id: Yup.number(),
  issue_date: Yup.string(),
  estimated_in_date: Yup.string(),
  notes: Yup.string(),
  internal_notes: Yup.string()
})

const providers = [
  { value: 1, label: 'Test' },
  { value: 2, label: 'Tomás López' },
  { value: 3, label: 'Ezequiel López' },
  { value: 4, label: 'Diego de la Parra' },
  { value: 5, label: 'Christian Palazzo' },
]

const buyers = [
  { value: 1, label: 'Test' },
  { value: 2, label: 'Vendedor Tomás' },
  { value: 3, label: 'Vendedor Ezequiel' },
  { value: 4, label: 'Vendedor Diego' },
  { value: 5, label: 'Vendedor Christian' },
]

const payment_conditions = [
  { id: 1, name: 'Cta. Cte. 15 días' },
  { id: 2, name: 'Cta. Cte. 30 días' },
  { id: 3, name: 'Cta. Cte. 60 días' },
]

export {
  providers,
  buyers,
  payment_conditions
}

export {
  initialValues,
  purchaseSchema,
  incomeSchema
}