import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button } from 'react-bootstrap';
import moment from 'moment';

import { useCustomerSuppliers } from 'hooks';
import { InputBS, SelectField, Textarea } from 'components'

import UpdateInvoiceModal from '../partials/UpdateInvoiceModal';
import UpdateWaybillModal from '../partials/UpdateWaybillModal'

const GeneralTab = ({ editEnable, income_order_id, updateInvoice, updateWaybill, disabled }) => {
  const {handleGetCustomerSupplierShipmentAddresses} = useCustomerSuppliers();
  const currentDate = new Date().toISOString().split('T')[0];

  const [showFcModal, setShowFcModal] = useState(false);
  const [newInvoice, setNewInvoice] = useState({});
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [showWaybillModal, setShowWaybillModal] = useState(false)

  const [listSupplierAddresses, setListSupplierAddresses] = useState([])
  const {values, setFieldValue} = useFormikContext();
  const [errorLength, setErrorLength] = useState(false)
  const [waybillNumberError, setWaybillNumberError] = useState([])

  const handleChangeWaybill = (e) => {
    let { value } = e.target;
    // value = value.replace(/\D/g, '');
    setWaybillNumberError(false)
    setFieldValue('waybill_number', value)
  }

  const handleSaveFc = () => {
    if (newInvoice.supplier_invoice?.length <= 14) {
      setErrorLength(true)
      return
    }
    updateInvoice({ ...newInvoice, supplier_invoice: "A" + newInvoice.supplier_invoice, id: income_order_id })
    setShowFcModal(false)
  }

  const handleChangeInvoice = (e) => {
    let { value } = e.target
    // const value = e.target.value.replace(/\D/g, '');
    setErrorLength(false)
    setFieldValue('supplier_invoice', value)
  }

  const loadAddresses = async (supplier_id) => {
    const data = await handleGetCustomerSupplierShipmentAddresses(supplier_id);
    setListSupplierAddresses(data?.data?.result.map(d => ({ ...d, label: d.address?.formatted_address, value: d.id })))
  }

  useEffect(() => {
    const {supplier_invoice, supplier_invoice_date} = values;
    setNewInvoice({ supplier_invoice, supplier_invoice_date })

    if (values && values.supplier_id) {
      loadAddresses(values.supplier_id);
    }

    setErrorLength(false)
  }, [values.distributor_id, values.supplier_id])

  return (
    <>
      {/* MODAL MODIFICAR FACTURA */}
      <UpdateInvoiceModal values={values} show={showInvoiceModal} onClose={() => setShowInvoiceModal(false)} onSave={updateInvoice} />
      {/* FIN MODAL MODIFICAR FACTURA */}

      {/* MODAL MODIFICAR REMITO */}
      <UpdateWaybillModal values={values} show={showWaybillModal} onClose={() => setShowWaybillModal(false)} onSave={updateWaybill} />
      {/* FIN MODAL MODIFICAR REMITO */}

      <div className='row'>
        <div className='col text-start pt-4'>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "120px" }}>{values.order_type_name == 'PEDIDO_INGRESO' ? 'Pedido de ingreso:' : 'Pedido de compra:'}</td>
                <td className='fw-bold'>{values.in_request_id}</td>
              </tr>

              <tr>
                <td>Proveedor:</td>
                <td className='fw-bold'>{values.supplier_name}</td>
              </tr>

              <tr>
                <td>Distribuidor:</td>
                <td className='fw-bold'>{values?.distributor_name || 'Sin distribuidor'}</td>
              </tr>

              <tr>
                <td>Condición de pago</td>
                <td className='fw-bold'>{values.payment_condition_label}</td>
              </tr>

              <tr>
                <td>Fecha pedido</td>
                <td className='fw-bold'>{values.issue_date ? moment(values.issue_date).format("DD/MM/YYYY") : ''}</td>
              </tr>

              <tr>
                <td>Fecha ingreso</td>
                <td className='fw-bold'>{values.income_date ? moment(values.income_date).format("DD/MM/YYYY") : ''}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='col mb-4 text-end' >
          {!editEnable &&
            <Button
                variant="primary"
                type='button'
                onClick={() => setShowInvoiceModal(true)}
                // onClick={() => setShowFcModal(true)}
                disabled={!values.in_request_id}
            >
                <i className="bi bi-pencil" />
                Modificar Factura
            </Button>
          }

          {!editEnable &&
            <Button
                variant="primary"
                type='button'
                className='ms-2'
                onClick={() => setShowWaybillModal(true)}
                disabled={
                    !values.in_request_id &&
                    values.order_status_id == 13 &&
                    values.detail.some(d => d.stock_id !== null)
                }
            >
                <i className='bi bi-pencil' />
                Modificar Remito
            </Button>
          }

          {editEnable && <Button variant="primary" type='submit'><i className="bi bi-save" />Guardar</Button>}
        </div>
    </div>

    <div className='row mb-10 '></div>
      <div className='row mb-5'>
        <div className='col'>
          <label className='form-label'>Remito</label>
          <input
            type="text"
            value={values.waybill_number || ""}
            onChange={handleChangeWaybill}
            autoComplete="off"
            className="form-control"
            onBlur={() => !(values.waybill_number.length > 12 && values.waybill_number.length <= 20) ? setWaybillNumberError(true) : setWaybillNumberError(false)}
            minLength={12}
            maxLength={20}
            disabled={!editEnable}
          />

          {waybillNumberError == true && <p className='text-danger'>El remito debe tener como mínimo 12 caracteres y no superar los 20 caracteres (incluyendo la letra)</p>}
        </div>

        <div className='col'>
          <SelectField isMulti={false} label={'Dirección'} name="shipment_address_id" options={listSupplierAddresses} disabled={!editEnable} />
        </div>
      </div>

      <div className='row mb-10 '>
        <div className="col-3">
          <label className='form-label'>Factura</label>
          <input
            // id='supplier_invoice'
            // name='supplier_invoice'
            type='text'
            value={values.supplier_invoice || ''}
            onChange={handleChangeInvoice}
            className='form-control'
            onBlur={() => !(values.supplier_invoice.length > 12 && values.supplier_invoice.length <= 20) ? setErrorLength(true) : setErrorLength(false)}
            minLength={12}
            maxLength={20}
            disabled={!editEnable}
          />

          {waybillNumberError == true && <p className='text-danger'>La factura debe tener como mínimo 12 caracteres y no superar los 20 caracteres (incluyendo la letra)</p>}
        </div>

        <div className='col-3'>
          <InputBS label='Fecha Factura' id='supplier_invoice_date' name='supplier_invoice_date' type='date' max={currentDate} disabled={!editEnable} />
        </div>

        <div className='col-6'>
          <InputBS label='Transportista' id='carrier' name='carrier' type='text' disabled={!editEnable} />
        </div>
      </div>


      <div className='row mb-20'>
        <div className="col">
          <label htmlFor='notes' className='form-label'>Observaciones</label>
          <Textarea id='notes' name='notes' disabled={!editEnable} />
        </div>

        <div className="col">
          <label htmlFor='internal_notes' className='form-label'>Notas internas</label>
          <Textarea id='internal_notes' name='internal_notes' disabled={!editEnable} />
        </div>
      </div>
    </>
  )
}

export default GeneralTab

{/* MODAL MODIFICAR DATOS FACTURA */}
{/* <Modal show={showFcModal} onHide={() => setShowFcModal(false)} backdrop='static'>
    <Modal.Header closeButton>
        <h4>Modificación datos factura</h4>
    </Modal.Header>

    <Modal.Body>
        <div className='mt-5'>
            <label>Factura</label>
            <input
                type="text"
                value={newInvoice.supplier_invoice || ""}
                onChange={handleChangeInvoice}
                autoComplete="off" className="form-control"
                minlength={12}
                maxlength={14}
            />
        </div>

        {errorLength == true && <p className='text-danger'>La factura debe tener 12 o 13 dígitos</p>}

        <div className='mt-5'>
            <label>Fecha Factura</label>
            <input
                type="date"
                value={newInvoice.supplier_invoice_date || ""}
                onChange={(e) => { setNewInvoice({ ...newInvoice, supplier_invoice_date: e.target.value })}}
                autoComplete="off"
                className={`form-control`}
                max={currentDate}
            />
        </div>
    </Modal.Body>

    <Modal.Footer style={{ borderTop: "0 none" }}>
        <Button variant="secondary" onClick={() => {setShowFcModal(false)}}>Salir</Button>

        <Button variant="primary" onClick={handleSaveFc}>
            <i className="bi bi-database-add me-2" />
            Modificar Factura
        </Button>
    </Modal.Footer>
</Modal> */}
{/* FIN MODAL MODIFICAR DATOS FACTURA*/}