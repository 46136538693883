import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { Button } from 'react-bootstrap'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'
import { useCustomerSuppliers } from 'hooks'

import { Input } from 'components'

const initialValues = {
  type: 'lista',
  text: '',
  article_text: '',
  // created_at: '',
}

const Filter = ({ handleData, query, handleTotalCount, handleQuery, exportData }) => {
  const { currentUser, setCurrentUser } = useAuth()

  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)
  const { loading, handleGetListCosts } = useCustomerSuppliers()

  const filterData = (values) => {
    let params = {
      ...values,
      ...query,
      type: 'lista',
      text: values?.text || undefined,
      article_text: values?.article_text || undefined,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: 10,
      }
      const response = await handleGetListCosts(params)
      const data = response.data.result.map(d => ({
        ...d,
        total_cost: +d.initial_cost + +d.add_cost + +d.sircreb_amount,
        date_from: d.date_from ? moment(d.date_from).format("YYYY-MM-DD") : '',
        date_to: d.date_to ? moment(d.date_to).format("YYYY-MM-DD") : '',
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        costs_sales: { ...params },
      }))

      handleQuery({ ...params })
      handleData(data)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  const handleExportData = async (values) => {
    const data = {
      ...query,
      ...values,
      ...filterData(values),
      type: 'lista',
    }

    await exportData(data)
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { costs_sales } = currentUser

      setInitialFormikValues({
        ...initialValues,
        type: 'lista',
        text: costs_sales?.text || '',
        article_text: costs_sales?.article_text || '',
        // created_at: costs_sales?.created_at || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form className=''>
          <div className='row'>
            <div className='col-8'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>ID / Artículo</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => handleExportData(values)}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-file-excel pe-3" />
                Exportar
              </Button>

              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter