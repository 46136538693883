import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import moment from "moment";

import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'

const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: intl.formatMessage({ id: 'COLUMN.PURCHASE_INCOME.ID' }),
      id: 'id',
      accessor: (row) => (
        <Link to={`${row.order_type_id == 1 ? routes.PURCHASE_REQUEST_DETAIL : routes.INCOME_REQUEST_DETAIL}/${row.id}`}>
          {row.id}
        </Link>
      ),
      hasOrder:true
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.ORDERS_AUTHORIZATIONS.TYPE' }),
      accessor: 'order_type_label',
      hasOrder: true,
    },
    {
      Header: 'Proveedor',
      id: 'supplier_id',
      accessor: (row) => (
        <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.supplier_id}`}>
          {row.supplier_name}
        </Link>
      ),
    },
    {
      Header: 'Distribuidor',
      id: 'distributor_id',
      accessor: (row) => (
        <div>
          {row?.distributor_id
            ?
              <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.distributor_id}`}>
                {row.distributor_name}
              </Link>
            : <i>Sin distribuidor</i>}
        </div>
      )
    },
    {
      Header: "Condición",
      accessor: 'payment_condition_label',
    },
    {
      Header: <div className='text-center'>F. Pago</div>,
      id: 'payment_estimated_in_date',
      accessor: (row) => (
        <div className='text-center'>
          {row?.payment_estimated_in_date ? moment(row.payment_estimated_in_date).format("DD/MM/YYYY") : '-'}
        </div>
      )
    },
    {
      Header: "Creación",
      id: 'date',
      accessor: (row) => (
        <>
          {moment(row.created_at).format("DD/MM/YYYY")}
        </>
      )
    },
    {
      Header: <div className='text-center'>Ingreso estimado</div>,
      id: 'estimated_in_date',
      accessor: (row) => (
        <div className='text-center'>
          {row?.estimated_in_date ? moment(row.estimated_in_date).format("DD/MM/YYYY") : '-'}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Ingreso</div>,
      id: 'in_order_created_at',
      accessor: (row) => (
        <div className='text-center'>
          {row?.in_order_created_at ? moment(row.in_order_created_at).format("DD/MM/YYYY") : '-'}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Artículos</div>,
      id: 'product_qty',
      accessor: (row) => (
        <div className='text-center'>
          {row.product_qty}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Total</div>,
      id: 'total',
      accessor: (row) => (
        <div className='text-end pe-20'>
          {currencyFormat.format(row.total)}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Estado</div>,
      id:'status',
      className:'col-status',
      accessor: (row) =>(
        <span className={`badge ${row.label_color} w-100 justify-content-center`}>
          {row.order_status_label}
        </span>
      )
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns