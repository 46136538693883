import * as Yup from 'yup'

const formikInitialValues: any = {
  id:undefined,
  product_type_id:undefined,
  name: '',
  family_id:undefined,
  maker_id:undefined,
  channels:[]
}

const createSchema = Yup.object().shape({
    name: Yup.string().required('Ingrese un nombre para el producto'),
})

export {
  formikInitialValues,
  createSchema
}