import { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useLogisticStock } from 'hooks'

import { InputBS } from 'components'

const formikInitialValues = {
  lot_number: '',
  expiration_date: '',
}

const UpdateLotModal = ({ show, onClose, detail, handleGetStockDetail }) => {
  const [initialValues, setInitialValues] = useState(formikInitialValues)

  const { loading, handleUpdateLotNumberAndExpirationFromStock } = useLogisticStock()

  const handleSubmit = async (values) => {
    try {
      if (values.lot_number == null || values.lot_number == '') {
        toast.error('El lote tiene que tener un nombre asignado')
        return
      }
      if (values.expiration_date == null || values.expiration_date == '') {
        toast.error('El lote tiene que tener una fecha de vencimiento')
        return
      }

      const data = {
        product_var_id: detail?.product_var_id,
        lot_number: detail?.lot_number,
        expiration_date: moment(detail?.expiration_date).format('YYYY-MM-DD HH:mm:ss'),
        new_lot_number: values.lot_number,
        new_expiration_date: moment(values.expiration_date).format('YYYY-MM-DD HH:mm:ss'),
      }

      const response = await handleUpdateLotNumberAndExpirationFromStock(data)
      toast.success(response.data.message)

      handleGetStockDetail()
      onClose()
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    setInitialValues({
      lot_number: detail?.lot_number,
      expiration_date: moment(detail?.expiration_date).format('YYYY-MM-DD'),
    })
  }, [detail])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='md' backdrop='static' centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Lote: {detail?.lot_number} | Vencimiento: {moment(detail?.expiration_date).format('DD/MM/YYYY')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row'>
                  <div className='col-6'>
                    <InputBS id='lot_number' name='lot_number' label='Lote' autoFocus disabled={loading} />
                  </div>

                  <div className='col-6'>
                    <InputBS id='expiration_date' name='expiration_date' type='date' label='Vencimiento' disabled={loading} />
                  </div>
                </div>

                <div className='separator my-6' />

                <div className='row'>
                  <div className='col-12 text-end'>
                    <Button type='button' variant='secondary' onClick={onClose} className='me-3' disabled={loading}>
                      <i className='bi bi-slash-circle' />
                      Cancelar
                    </Button>

                    <Button type='submit' variant='primary' disabled={loading}>
                      <i className='bi bi-save me-2' />
                      Guardar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateLotModal