import * as Yup from 'yup'
import { CreateHealthInsuranceI } from "interfaces"

const createInitialValues: CreateHealthInsuranceI = {
  name: '',
}

const createSchema = Yup.object().shape({
  name: Yup.string().required('Ingrese un nombre'),
})


export {
  createInitialValues,
  createSchema,
}