export enum OutOrderStatusI {
  EN_REVISION = 3,
  COORDINACION_ENTREGA = 2,
  PENDIENTE_PREPARACION = 9,
  EN_PREPARACION = 4,
  PENDIENTE_DESPACHO = 10,
  EN_DESPACHO= 11,
  EN_DISTRIBUCION = 5,
  ENTREGADA = 7,
  CANCELADO = 8,
  RESERVA_PARCIAL= 12
}

export enum OutOrderCoordinationStatusI {
  PENDIENTE_DE_COORDINACION = '',
  PENDIENTE_DE_COORDINACION_2 = 'PENDIENTE_COORDINACION',
  EN_PROGRESO = 'EN_PROGRESO',
  FALTAN_DATOS = 'FALTAN_DATOS',
  PENDIENTE_DE_RESPUESTA = 'PENDIENTE_DE_RESPUESTA',
  PENDIENTE_VENTAS = 'PENDIENTE_VENTAS',
  COORDINADO = 'COORDINADO',
  RECOORDINACION = 'RECOORDINACION',
  SIN_RESPUESTA = 'SIN_RESPUESTA',
}

export enum OutOrderCoordinationStatusLabelI {
  PENDIENTE_DE_COORDINACION = 'Pendiente de coordinación',
  EN_PROGRESO = 'En progreso',
  FALTAN_DATOS = 'Falta de datos',
  PENDIENTE_DE_RESPUESTA = 'Pendiente de respuesta',
  PENDIENTE_VENTAS = 'Pendiente ventas',
  COORDINADO = 'Coordinado',
  RECOORDINACION = 'Recoordinado',
  SIN_RESPUESTA = 'Sin respuesta',
}

export enum OutOrdertStatusLabelI {
  COORDINACION_ENTREGA = 'En coordinación de entrega',
  EN_PREPARACION = 'En preparación',
  EN_DISTRIBUCION = 'En distribución',
  EN_DESPACHO = 'En despacho',
  ENTREGADA = 'Entregada',
  CANCELADO = 'Cancelada',
  PENDIENTE_PREPARACION = 'Pendiente de prepraración',
  PENDIENTE_DESPACHO = 'Pendiente de despacho',
  EN_REVISION = 'En revisión',
  RESERVA_PARCIAL = 'Reserva Parcial',
}