import * as Yup from 'yup'
import { formatInputDate } from 'utilities'

const initialValues = {
  id: 0,
  documentation_type_id: '',
  documentation: '',
  first_name: '',
  last_name: '',
  birthdate: '',
  genre_id: '',
  health_insurance: {
    health_insurance_id: '',
    name: '',
    affiliate_number: '',
    plan: '',
  },
  contact_id: '',
  contact: {
    name: '',
    email: '',
    has_notifications: false,
    phone: '',
    mobile_suffix: '',
    mobile: '',
    observations: '',
  },
  is_enabled: false,
}

const schema = Yup.object().shape({
  id: Yup.number().required('Campo requerido'),
  documentation_type_id: Yup.string().nullable(),
  // documentation_type_id: Yup.string().required('* Seleccione un tipo de documento'),
  documentation: Yup.string().nullable()
    // .required('* Ingrese un documento').nonNullable()
    .test('custom-validation', '* Longitud y formato incorrectos para el tipo de documento', function(value) {
      const { documentation_type_id } = this.parent;

      if (value !== undefined) {
        if (documentation_type_id == '1' && (value.length != 8 || !/^[0-9]+$/.test(value))) {
          throw this.createError({ message: '* El DNI debe contener 8 caracteres' });
        }

        if (documentation_type_id == '2' && (value.length != 11 || !/^[0-9]+$/.test(value))) {
          throw this.createError({ message: '* El CUIT debe contener 11 caracteres' });
        }
      }

      return true;
    }),
  first_name: Yup.string().required('* Ingrese un nombre para el paciente'),
  last_name: Yup.string().required('* Ingrese un apellido para el paciente'),
  birthdate: Yup.string(),
  genre_id: Yup.number(),
  health_insurance: Yup.object().shape({
    health_insurance_id: Yup.number().nullable(),
    affiliate_number: Yup.string().nullable(),
    // affiliate_number: Yup.string().when(['health_insurance_id'], {
    //   is: (health_insurance_id) => !!health_insurance_id,
    //   then: (schema) => schema.required('* Ingrese el número de afiliado'),
    //   otherwise: (schema) => schema.nullable(),
    // }),
    plan: Yup.string(),
  }),
  contact: Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().nullable(),
    has_notifications: Yup.boolean(),
    phone: Yup.string().nullable(),
    mobile_suffix: Yup.string().matches(/^(\+)?[0-9\s]+$/, '* El sufijo debe ser numérico y puede incluir el signo + al principio'),
    mobile: Yup.string().min(10, '* El número de celular debe tener al menos 10 dígitos').label('Celular'),
    observations: Yup.string().nullable(),
  }),
})

const dataAdapter = (data) => {
  let values = {
    ...data,
    birthdate: formatInputDate(data.birthdate),
  }

  delete data.addresses

  return values
}

const updateDataAdapter = (data) => {
  let values = {
    ...data,
    birthdate: data.birthdate !== '' ? data.birthdate : null,
    genre_id: data.genre_id !== '' ? data.genre_id : null,
    health_insurance: {
      ...data.health_insurance,
      health_insurance_id: data.health_insurance?.health_insurance_id ? data.health_insurance.health_insurance_id : null,
      affiliate_number: data.health_insurance?.affiliate_number ? data.health_insurance.affiliate_number : null,
      plan: data.health_insurance?.plan ? data.health_insurance.plan : null,
    },
    contact: {
      ...data.contact,
      name: data.contact?.name,
      email: data.contact?.email,
      has_notifications: (data.contact?.has_notifications),
      phone: data.contact?.phone,
      mobile_suffix: data.contact?.mobile_suffix,
      mobile: data.contact?.mobile,
      observations: data.contact?.observations,
    }
  }

  delete values.id
  delete values.documentation_type_name
  delete values.health_insurance.health_insurance_name
  delete values.genre_label
  delete values.addresses

  return values
}

export {
  initialValues,
  schema,
  dataAdapter,
  updateDataAdapter,
}