import moment from 'moment';

import { TransactionalDocumentTypeI } from 'interfaces'

const types = {
  [TransactionalDocumentTypeI.FACTURA]: 'Factura',
  [TransactionalDocumentTypeI.NOTA_DEBITO]: 'Nota de débito',
  [TransactionalDocumentTypeI.NOTA_CREDITO]: 'Nota de crédito',
  [TransactionalDocumentTypeI.REMITO]: 'Remito',
}

const useGetColumns = () => {

  const COLUMNS = [
    {
      Header: "ID",
      id: 'id',
      accessor: "id",
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Fecha</div>),
      id: 'fecha',
      accessor: (row) => (
        <div className='text-center'>
          {row?.fecha ? moment(row.fecha).format("DD/MM/YYYY") : '-'}
        </div>
      ),
      hasOrder:true
    },
    {
      Header: 'Tipo',
      id: 'tipo_comprobante',
      accessor: (row) => (
        <>
          {row.tipo_comprobante}
        </>
      )
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Número</div>),
      id: 'nro_completo',
      accessor: (row) => (
        <div className='text-center'>
          {row.nro_completo}
        </div>
      ),
      hasOrder:true
    },
    {
      Header: "Cliente / Proveedor",
      accessor: 'razon_social',
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Pedido</div>),
      id: 'id_pedido',
      accessor: (row) => (
        <div className='text-center'>
          {row.id_pedido}
        </div>
      ),
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>CAE</div>),
      id: 'cae',
      accessor: (row) => (
        <div className='text-center'>
          {row?.cae || '-'}
        </div>
      ),
      hasOrder:true
    },
    {
      Header: 'Total',
      id: 'total',
      accessor: (row) => (
        <>
          $ {Number(row.total).toFixed(2)}
        </>
      ),
      hasOrder:true
    },


  ]

  return {
    COLUMNS
  }
}

export default useGetColumns