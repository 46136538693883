import service from "../service";
import { TypeI } from "../../interfaces"

const PATH = {
  types: "/types",
};

export class Types {
  static getTypes = async (token: string) => {
    const response = await service.get(PATH.types, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static getType = async (id: TypeI['id'], token: string) => {
    const response = await service.get(`${PATH.types}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };
}