import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Button, Form, FormControl, FormLabel, Modal, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from 'react-toastify'

import { useArticles } from "hooks";

import { valuesCalculator } from "./valuesCalculator";
import { decimalFormatter, formatInputDate, formatTableDate } from "utilities";
import './article.css';

const  ArticlesModal = ({ show, onClose, onSave }) => {
  const [articlesOptions, setArticlesOptions] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const { handleGetArticles, handleGetArticle } = useArticles()
  const [txtSearch, setTxtSearch] = useState('')

  const { values } = useFormikContext()

  const handleKeyDown = async (event) => {
    if ((event.keyCode === 13 && event.target?.value?.length > 2) || event.target?.name === 'btnSearch') {
      const queryMD = {
        text: txtSearch,
        add_stock: 1,
        add_price: 1,
        add_cost: 1,
        customer_supplier_id: values.customer_supplier_id,
      }

      const data = await handleGetArticles({ queryMD })
      if (data && data.result) {
        const val = data.result.map(r => ({ ...r, selected: false, qty: r.unit ? parseInt(r.unit) : 1, boxes: 1 }))
        setArticlesOptions(val)
      }
    }
  }

  const handleSave = async () => {
    const arts = await Promise.all(articlesOptions
      .filter(a => a.selected === true)
      .map(async a => {
        const {
          id: var_id,
          purchase_discount_base,
          purchase_discount_extra,
          name: product_var_desc,
          vat,
          vat_rate,
          boxes,
          qty,
          unit,
          actual_pvp,
          list_cost,
          product_id,
        } = a

        let detail = {
          ...a,
          discount: purchase_discount_extra,
        }
        delete detail.purchase_discount_base
        delete detail.purchase_discount_extra

        let art = null
        try {
          art = await handleGetArticle({ product_id, product_var_id: var_id })
        } catch (error) {
          toast.error(error.message)
        }

        const val = valuesCalculator({ ...detail, list_cost: art.list_cost });

        const data = {
          var_id,
          purchase_discount_base: 0,
          purchase_discount_extra: 0,
          purchase_discount_extra_2: 0,
          discount: purchase_discount_extra && 0,
          product_var_desc,
          boxes: boxes,
          qty: unit ? parseInt(unit) : 1,
          // qty: qty,
          actual_pvp,
          list_cost: val.list_cost,
          vat: vat,
          vat_rate: vat_rate,
          psl: val.psl ? parseFloat(val.psl) : 0,
          unit_purchase_price: val.unit_purchase_price ? parseFloat(val.unit_purchase_price) : null,
          subtotal: val.subtotal ? parseFloat(val.subtotal) : null,
          total: val.total ? parseFloat(val.total) : null
        }

        return data
      })
    )

    const invalid = arts.filter(a => a.qty === '' || !a.qty)
    if (invalid.length > 0) {
      return
    }

    const total = arts.reduce((acc, article) => acc + (article.total || 0), 0);

    onSave({ articles: arts, total: parseFloat(total) })
    setTxtSearch('')
    onClose()
  }

  const handleQuantityChange = (productId, event) => {
    let value = event.target.value.replace(/\D/g, '')

    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, boxes: value, selected: value ? true : false } : product)
    setArticlesOptions(updatedProducts)
  }

  const handleSelectionChange = (productId) => {
    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, selected: !product.selected } : product)
    setArticlesOptions(updatedProducts)
  };

  useEffect(() => {
    if (show) {
      setArticlesOptions([])
      setSelectedRows([])
      setTxtSearch('')
    }
  }, [show])

  if (!show) return null

  return (
    <Modal size="xl" show={show} onHide={onClose} backdrop='static' >
      <Modal.Header closeButton>
        <Modal.Title>Agregar un artículo al pedido</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <div className='row'>
          <div className='col-10'>
            <FormLabel>Articulo</FormLabel>
            <FormControl
              type="text"
              onKeyDown={handleKeyDown}
              autoComplete="off"
              placeholder="Escriba el nombre del artículo y presione <Enter> para buscar..."
              value={txtSearch}
              onChange={(e) => setTxtSearch(e.target.value)}
            />
          </div>

          <div className='col mt-6'>
            <Button onClick={handleKeyDown} name="btnSearch"><i className="bi bi-search me-2"></i>Buscar</Button>
          </div>
        </div>

        <div className="row">
          <div className='col mt-5'>
            <Table striped bordered hover>
              <thead>
                <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th className="text-center">Stock</th>
                <th className="text-center">IVA %</th>
                <th className="text-center">Precio</th>
                <th className="text-center">Últ. Act. PP</th>
                <th className="text-center" style={{ width: '100px' }}>Cajas</th>
                <th className="text-center" style={{ width: '100px' }}>Unidades</th>
                <th className="text-center" style={{ width: '100px' }}>Seleccionar</th>
                </tr>
              </thead>
              <tbody>
                {articlesOptions.map(product => (
                  <tr key={product.id}>
                    <td className="align-middle">{product.id}</td>
                    <td className="align-middle">
                      {product.name}
                      {product?.observations
                        ?
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="waves-print-tooltip">
                                <div className=''>
                                  <p>{product.observations}</p>
                                </div>
                              </Tooltip>
                            }
                          >
                            <span className="ms-5"> <i className="fa-regular fa-circle-question text-primary"></i> </span>
                          </OverlayTrigger>
                        : null
                      }
                    </td>
                    <td className="align-middle text-center">
                      {product.stock_available}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="waves-print-tooltip">
                            <div className=''>
                              <table className='table table-rounded table-striped'>
                                <thead>
                                  <tr className=' text-center'>
                                    <th>OC</th>
                                    <th>Faltante</th>
                                    <th>Reservado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className='align-middle text-center'>
                                    <td>{product.oc || 0}</td>
                                    <td>{product.pending || 0}</td>
                                    <td>{product.stock_reserved || 0}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Tooltip>
                        }
                      >
                        <span className="ms-5"> <i className="fa-regular fa-circle-question text-primary"></i> </span>
                      </OverlayTrigger>
                    </td>
                    <td className='align-middle text-center'>{`${product?.vat_rate ? parseFloat(product.vat_rate).toFixed(5) : 0} %`}</td>
                    <td className='align-middle text-center'>$ {decimalFormatter.format(product.actual_pvp)}</td>
                    <td className='align-middle text-center'>{formatTableDate(product.last_update)}</td>
                    <td className="align-middle">
                      <Form.Control
                        type="number"
                        maxLength={5}
                        value={product.boxes}
                        onChange={(e) => handleQuantityChange(product.id, e)}
                        className={(product.selected && !product.boxes) ? 'border-danger' : ''}
                        style={{ width: '100px' }}
                      />
                    </td>
                    <td className="align-middle">
                      <Form.Control
                        type="number"
                        maxLength={5}
                        value={product.boxes * product.qty}
                        className={(product.selected && !product.qty) ? 'border-danger' : ''}
                        style={{ width: '100px' }}
                        disabled
                      />
                    </td>
                    <td className="align-middle text-center">
                      <Form.Check
                        type="checkbox"
                        checked={product.selected}
                        onChange={() => handleSelectionChange(product.id)}
                        className="chk-selector"
                        style={{ width: '100px' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "0 none" }}>
        <Button variant="secondary" onClick={onClose}>Salir</Button>
        <Button variant="primary" onClick={handleSave}><i className="bi bi-database-add me-2"></i>Agregar artículo</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ArticlesModal