import service from "services/service";
import { AgentTypeI } from "interfaces";

const PATH = {
  agentTypes: "/agent-types",
};

export class AgentTypes {
  static getAgentTypes = async (token: string) => {
    const response = await service.get(PATH.agentTypes, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static getAgentType = async (id: AgentTypeI['id'], token: string) => {
    const response = await service.get(`${PATH.agentTypes}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };
}