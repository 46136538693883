import { useIntl } from 'react-intl'
import moment from 'moment'
import { decimalFormatter } from 'utilities'



const useGetColumns = () => {
  const intl = useIntl()
/*
{
    "id": 1,
    "order_type_id": 1,
    "supplier_id": 2,
    "customer_id": 3,
    "payment_condition_id": 7,
    "creation_user_id": 1,
    "estimated_in_date": "2023-08-15T03:00:00.000Z",
    "notes": "Nota de ejemplo",
    "internal_notes": "Nota interna de ejemplo",
    "created_at": "2023-08-15T14:20:18.000Z",
    "issue_date": "2023-08-14T03:00:00.000Z",
    "revocation_user_id": null,
    "revocation_date": null,
    "order_status_id": 7
}
*/
  const COLUMNS = [
    {
      Header: "Nro.",
      id: 'id',
      accessor: "id"
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.PURCHASE_INCOME.TYPE' }),
      accessor: 'order_type_label',
      hasOrder:true
    },
    {
      Header: 'Proveedor', // (props) => <OrderedCustomHeader tableProps={props} title='Proveedor' />,
      id: 'supplier_name',
      accessor: 'supplier_name',
      hasOrder:true
    },
    {
      Header: 'Ditribuidor',
      id: 'distributor_name',
      accessor: (row) => (
        <>
          {row?.distributor_id ? row.distributor_name : 'Sin distribuidor'}
        </>
      ),
      hasOrder:true
    },
    {
      Header: "Total",
      id: 'total',
      accessor: row=>(
        <>
          {decimalFormatter.format(row.total)}
        </>
      ),
      hasOrder:true
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.PURCHASE_INCOME.DATE' }),
      id: 'date',
      accessor: (row) => (
        <>
          {moment(row.created_at).format("DD/MMM/YYYY")}
        </>
      )
    },
    {
      Header: "Estado",
      accessor: (row) =>(
        <div className="badge badge-warning w-100 justify-content-center" style={{color:"#333333"}}>
          {row.order_status_label}
        </div>
        
      ) 
    },

  ]

  return {
    COLUMNS
  }
}

export default useGetColumns