import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'

import { InputBS } from 'components'

import { invoiceSchema } from './schema'

const UpdateInvoiceModal = ({ values, show, onClose, onSave }) => {
  const [initialValues, setInitialValues] = useState({
    supplier_invoice: '',
    supplier_invoice_date: '',
  })

  useEffect(() => {
    setInitialValues((prevInitialValues) => ({
      ...prevInitialValues,
      supplier_invoice: values.supplier_invoice,
      supplier_invoice_date: values.supplier_invoice_date,
    }))
  }, [values])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Modificar factura de la Orden de Ingreso #{values.in_order_id}</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={invoiceSchema}
          onSubmit={(data) => {
            onSave(data)
            onClose()
          }}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row mb-6'>
                  <div className='col-12'>
                    <InputBS
                      id='supplier_invoice'
                      name='supplier_invoice'
                      type='text'
                      label='Factura'
                      maxLength={20}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <InputBS
                      id='supplier_invoice_date'
                      name='supplier_invoice_date'
                      label='Fecha factura'
                      type='date'
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='text-end'>
                  <Button type='button' variant='secondary' className='me-3' onClick={onClose}>
                    <span className='bi bi-slash-circle me-2' />Cancelar
                  </Button>

                  <Button type='submit' variant='primary'>
                    <i className='bi bi-save' />Modificar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateInvoiceModal