import { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import moment from "moment"

import { useRequest } from "hooks"
import { HistoryWidget } from "./HistoryWidget"

export function PurchaseHistoryDialog({ show, onClose, currentRow }) {
  /*
    Para que quede bien alineada la línea del timeline,
    toqué el archivo /src/_metronic/assets/sass/components/timeline-6.scss
    que no está bajo control de versiones
    le puse left: 151px; en el &:before
  */
  const [events, setEvents] = useState([{id:null, description:null, display_date:null}]);
  const {handleGetEvents} = useRequest();

  const fetchEvents = async() => {
    if (currentRow && currentRow.id) {
      const data = await handleGetEvents(currentRow.id)

      const newEvents = []
      if (data && data.result && data.result.length) {
        let currentDate = "19700101"

        for (let i = 0; i < data.result.length; i++) {
          let ev = { ...data.result[i], display_date: null }

          if (moment(ev.event_date).format("YYYYMMDD") !== currentDate) {
            ev.display_date = moment(ev.event_date).format("DD/MM/YYYY HH:mm")
            currentDate = moment(ev.event_date).format("YYYYMMDD")
          } else {
            ev.display_date = moment(ev.event_date).format("HH:mm")
          }

          newEvents.push(ev)
        }
      }

      setEvents(newEvents)
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [currentRow])

  return (
    <Modal size="md" show={show} onClose={onClose} backdrop='static' scrollable centered>
      <Modal.Header closeButton>
        <Modal.Title>Orden #{currentRow?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <HistoryWidget events={events} />
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "0 none" }}>
        <Button variant="secondary" onClick={onClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  )
}
