import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useIncomeOrder, useClickOutside } from 'hooks'

import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import IncomeDetailModal from './IncomeDetailModal'
import IncomesColumns from './IncomesColumns'

const ESTADOS_ORDEN = {
  GENERADO: 1,
  PENDIENTE_INGRESO: 2,
  INGRESO_PARCIAL: 3,
  VENCIDO: 4,
  CANCELADO: 5,
  COMPLETO: 6,
  BORRADOR: 7,
  AUTORIZADO: 8,
  CONFIRMADO: 9,
  COMPLETO_PARCIAL: 10,
  INGRESADO: 11,
  RECHAZADO: 12,
  ERROR_TRAZA: 13
};

export const labelColor = (order_status_id) => {
  switch(order_status_id){
    case ESTADOS_ORDEN.BORRADOR:
      return "estado-gris";
    case ESTADOS_ORDEN.INGRESADO:
      return "estado-verde";
    case ESTADOS_ORDEN.ERROR_TRAZA:
      return "estado-rojo";
    default:
      return '';
  }
}

const IncomesTab = ({ income, setInitialTab }) => {
  const { id } = useParams()
  const { handleGetIncomeOrders } = useIncomeOrder()
  const [orders, setOrders] = useState([])

  const [showDetail, setShowDetail] = useState(false)
  const [inOrderId, setInOrderId] = useState(null)
  const [detail, setDetail] = useState([])

  const { COLUMNS } = IncomesColumns()

  const getIncomeOrders = useCallback(async () => {
    try {
      let params = {
        in_request_id: id,
        getDetail: 1,
      }

      const response = await handleGetIncomeOrders(params)
      const data = response.result.map(d => ({ ...d, label_color: labelColor(d.order_status_id) }))
      setOrders(data)
    } catch (error) {
      setOrders([])
      toast.error(error.message)
    }
  }, [handleGetIncomeOrders, id])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow && currentRow.detail_grouped) {
      actions.push({
        label: 'Ver detalle',
        icon: 'bi bi-eye text-primary',
        action: () => {
          setInOrderId(currentRow.id)
          setDetail(currentRow.detail_grouped)
          setShowDetail(true)
        }
      })
    }

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

  useEffect(() => {
    getIncomeOrders()
  }, [handleGetIncomeOrders])

  return (
    <>
      <IncomeDetailModal
        show={showDetail}
        onClose={() => {
          setShowDetail(false)
          setInOrderId(null)
          setDetail([])
        }}
        inOrderId={inOrderId}
        detail={detail}
      />

      <CustomTable columns={columns} data={orders} handleContextMenu={handleContextMenu} />

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default IncomesTab