import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useCustomerSuppliers } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { ButtonPrimary, ButtonSecondary } from 'components'

import { Form as FormCost } from '../partials'
import { initialValues, schema, addDataAdapter } from '../constants'
import routes from 'app/routing/routes'
import { Button } from 'react-bootstrap'

const AddCost = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const { auth } = useAuth()
  const { loading, handleAddListCost } = useCustomerSuppliers()

  const addListCost = async (values) => {
    const data = addDataAdapter({
      ...values,
      created_by_user_id: auth?.id ? auth.id : auth?.user.id
    })

    try {
      const response = await handleAddListCost(data)
      toast.success("Registro agregado correctamente", {theme:"colored"})
      // navigate(`${routes.COSTS}/cliente/${response.data.id}`, { state: { row: data }})
      navigate(`${routes.COSTS}`)
      
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Compras/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Costos/', path: routes.COSTS, isSeparator: false, isActive: true },
          { title: 'Crear costo de lista especial', path: routes.COSTS_NEW, isActive: false }
        ]}
        description='Crear costo de lista especial'
      />

      <DetailContainer title="Crear costo especial">
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={addListCost}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className="d-flex flex-column">
                
                <FormCost create editing />

                <div className='d-flex align-items-center justify-content-end'>
                  <Button variant='secondary' type='button' className='me-3' onClick={() => navigate(routes.COSTS)}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddCost
