import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'
import {socket} from '../../../../src/socket'

export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    logout()
    socket.disconnect();
    document.location.reload()

  }, [logout])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
