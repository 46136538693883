import routes from 'app/routing/routes'
import { Link } from 'react-router-dom'
import { formatTableDate } from 'utilities'

const useGetColumns = () => {
  const COLUMNS = [
    {
      Header: "ID",
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.INVENTORY_MOVEMENTS}/${row.id}`}>
          {row.id}
        </Link>
      )
    },
    {
      Header: "Fecha",
      id: 'created_at',
      accessor: (row) => (
        <>
          <span>
            {formatTableDate(row.created_at)}
          </span>
        </>
      ),
    },
    {
      Header: "Tipo",
      accessor: 'depot_movement_type',
    },
    {
      Header: "Origen",
      accessor: 'storage_space_origin.name',
    },
    {
      Header: "Destino",
      accessor: 'storage_space_dest.name',
    },
    {
      Header: "Usuario",
      accessor: 'created_by_user',
    },
    {
      Header: <div className="text-center">Estado</div>,
      id: "depot_movement_status",
      accessor: (row) => (
        <p className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
          {row.depot_movement_status}
        </p>
      )
    },
    {
      Header: "",
      id: 'has_refrigeration',
      accessor:(row) => (
        row.has_refrigeration
        ? (
          <span className='ps-4 pe-4'>
            <i className='bi bi-snow text-primary fs-3' />
          </span>
        )
        : <></>
      )
    },


  ]

  return {
    COLUMNS
  }
}

export default useGetColumns