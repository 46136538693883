import { useEffect, useState } from 'react'
import { Formik, Form, useFormikContext } from 'formik'


import { Input, SelectField } from 'components'
import useDepots from 'hooks/Inventory/useDepots'
import { Button } from 'react-bootstrap'
import { formatInputDate } from 'utilities'

const optionsBusinessUnits = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Farma' },
  { value: 2, label: 'Shop' }
]

const today = new Date();
const monthAgo = new Date();
monthAgo.setMonth(today.getMonth() - 1);

const initialValues = {
  storage_space_id: '',
  customer_supplier_id: '',
  business_unit_id: '',
  date_from: formatInputDate(monthAgo.toISOString()),
  date_to: formatInputDate(today.toISOString()),
}

const InnerFilter = ({setTitle, loading})=>{
  const {values, setFieldValue} = useFormikContext();
  const {handleGetStorageSpaces, handleGetStorageOwners, handleGetStorageStatuses, handleGetWarehouses} = useDepots()
  const [optionsStorageSpaces, setOptionStorageSpaces] = useState([])
  const [optionsOwners, setOptionOwners] = useState([])
  const [optionsStatus, setOptionStatus] = useState([])
  const [optionsWarehouse, setOptionWarehouse] = useState([])

  const loadOptions = async()=>{
    const _storage = await handleGetStorageSpaces();
    const _owners = await handleGetStorageOwners();
    const _statuses = await handleGetStorageStatuses();
    const _warehouses = await handleGetWarehouses();
    _storage.unshift({ id: '', name: 'Todos' });
    _owners.unshift({ id: '', business_name: 'Todos' });
    _statuses.unshift({ id: '', name: 'Todos' });
    _warehouses.unshift({ id: '', name: 'Todos' });
    setOptionStorageSpaces(_storage?.map(i => ({ ...i, value: i.id, label: i.name})))
    setOptionOwners(_owners?.map(i => ({ value: i.id, label: i.business_name })))
    setOptionStatus(_statuses?.map(i => ({ value: i.id, label: i.name })))
    setOptionWarehouse(_warehouses?.map(i => ({ value: i.id, label: (i.name !== 'Todos' ? i.general_depot_name + ' - ' + i.name : 'Todos') })))
  }

  useEffect(() => {
      const _storage = optionsStorageSpaces.filter(i => i.value === values.storage_space_id)[0];

      if (_storage) {
        setFieldValue('customer_supplier_id', _storage.customer_suppliers_id)
        setFieldValue('business_unit_id', _storage.business_unit_id)
        setFieldValue('inventory_status_id', _storage.inventory_status_id)
        setFieldValue('warehouse_id', _storage.warehouse_id)
        setTitle(`depósito ${_storage.general_depot_name}, almacén ${_storage.warehouse_name}`)
      }
  }, [values.storage_space_id])

  useEffect(() => {
    loadOptions()
  }, [])

  return(
    <>
      <div className='row'>
        <div className='col'>
          <Input id='date_from' name='date_from' type='date' />
          <p className='text-muted'>Fecha desde</p>
        </div>

        <div className='col'>
          <Input id='date_to' name='date_to' type='date' />
          <p className='text-muted'>Fecha hasta</p>
        </div>

        <div className='col'>
          <SelectField
            id='business_unit_id'
            name='business_unit_id'
            options={optionsBusinessUnits}
            marginTop={'-20px'}
            disabled={values.storage_space_id>0}
          />
          <p className='text-muted'>Unidad de negocio</p>
        </div>

        <div className='col'>
          <SelectField
            id='customer_supplier_id'
            name='customer_supplier_id'
            options={optionsOwners}
            marginTop={'-20px'}
            disabled={values.storage_space_id>0}
          />
          <p className='text-muted'>Dueño</p>
        </div>

        <div className='col text-end'>
          <Button  variant="primary" type='submit'><i className="bi bi-search mb-1"></i>Buscar</Button>
        </div>
      </div>
      <div className='row'>

      </div>
    </>
  )
}

const Filter = ({ setFilter, queryMD, setQueryMD, setTitle, loading  }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        let query = '?';

        // Actualiza las propiedades directamente en el objeto values
        for (const key in values) {
          if (values[key] !== '') {
            if (key === 'date_from' || key === 'date_to') {
              // Asegura que las fechas sean cadenas
              values[key] = values[key].toString();
            } else if (values[key] != null) {
              values[key] = values[key];
            }
          }
        }

        // Construye la cadena de consulta
        for (const key in values) {
          if (values[key] === null) {
            values[key] = undefined
          }

          query += `${key}=${values[key]}&`;
        }

        // Elimina el último '&'
        query = query.slice(0, -1);

        setFilter('');
        setQueryMD({ ...queryMD,...values, pageNumber: 1 });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <InnerFilter setTitle={setTitle} loading={loading} />
        </Form>
      )}
    </Formik>
  )
}

export default Filter