import { Link } from 'react-router-dom'
import moment from 'moment'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: <div className='text-center'>Nro.</div>,
    id: 'id',
    accessor: (row) => (
      <Link to={`${routes.OUT_ORDERS}/${row.id}`}>
        {row.id}
      </Link>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>F. creación</div>,
    id: 'created_at',
    accessor: (row) => (
      <p className='text-center m-0'>
        {moment(row.created_at).format('DD/MM/YYYY')}
      </p>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>F. entrega est.</div>,
    id: 'dispatch_date',
    accessor: (row) => (
      <p className='text-center m-0'>
        {row.dispatch_date ? moment(row.dispatch_date).format('DD/MM/YYYY') : '-'}
      </p>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>ID Pedido</div>,
    id: 'sales_order_id',
    accessor: (row) => (
      <div className='text-center'>
        <Link to={`${routes.SALES_ORDERS}/${row.sales_order_id}`}>
          {row.sales_order_id}
        </Link>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div>Cliente</div>,
    id: 'customer_supplier_business_name',
    accessor: (row) => (
      <>
        <strong className='m-0 p-0'>[{row.customer_supplier_id}] {row.customer_supplier_business_name}</strong>
        <p className='m-0 p-o mt-2'>{row?.customer_supplier_documentation_type}: {row.customer_supplier_documentation}</p>

        {row.patient_id &&
          <ul className='list-style-none m-0 p-0 mt-4'>
            <li>Paciente: <strong>[{row.patient_id}] {row.patient_name}</strong></li>
            <li className='mt-2'>Documento: {row?.patient_documentation || '-'}</li>
            <li className='mt-2'>Nro. afiliado: {row?.patient_affiliate_number || '-'}</li>
            <li className='mt-2'>Tel: {row?.patient_phone?.trim() || '-'}</li>
            <li className='mt-2'>Cel: {row?.patient_mobile?.trim() || '-'}</li>
          </ul>
        }
      </>
    ),
    hasOrder: true,
  },
  {
    Header: 'Nombre / Dirección',
    id: 'ship_name',
    accessor: (row) => (
      <div>
        <strong>{row.ship_name}</strong>

        <p className='m-0 p-0 mt-4'>{row?.domicilio?.trim()}</p>
        <p className='m-0 p-0 mt-2'>GLN: {row?.ship_gln || '-'}</p>
      </div>
    )
  },
  {
    Header: 'Localidad',
    id: 'localidad_completa',
    accessor: (row) => (
      <p className='m-0 p-0'>{row?.localidad_completa?.trim()}</p>
    )
  },
  {
    Header: 'Provincia',
    id: 'ship_provincia',
    accessor: (row) => (
      <p className='m-0 p-0'>{row?.ship_provincia}</p>
    )
  },
  {
    Header: <p className='text-center m-0'>Estado</p>,
    id: 'out_order_status_id',
    accessor: (row) =>(
      <p className={`badge ${row.label_color} w-100 justify-content-center m-0 fw-normal`}>
        {row.out_order_status_label}
      </p>
    ),
  },
  {
    Header: <p className='text-center m-0'>Estado coordinación</p>,
    id: 'coordination_status',
    accessor: (row) => (
      <p className={`badge ${row.coordination_label_color} w-100 justify-content-center m-0 fw-normal`}>
        {row.coordination_label}
      </p>
    )
  },
  {
    Header: <p className='text-center m-0'>Usuario coordinación (últ.)</p>,
    id: 'coordination_user_id',
    accessor: (row) => (
      <p className='text-center m-0'>
        {row?.coordination_user_id ? row.coordination_user : '-'}
      </p>
    )
  },
  {
    Header: "",
    id: 'has_regrigeration',
    accessor: (row) => (
      <>
        {Boolean(row.has_refrigeration)
          ? <span><i className='bi bi-snow text-primary fs-3'></i></span>
          : <span></span>
        }
      </>
    )
  },
]

export {
  COLUMNS,
}