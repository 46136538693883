import React from 'react'

import { ContactInfo } from 'components'

const ContactTab = ({ updating }) => {
  return (
    <>
      <ContactInfo updating={!updating} />
    </>
  )
}

export default ContactTab