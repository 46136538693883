import { useState, useCallback, useEffect } from 'react';

import { Depots } from '../../services';


const initialQueryString = {
  filter:"",
  metadata:{
    pageNumber:"1",
    pageSize:"10"
  }
}


const useDepots = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);

  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);

  const handleGetDepots = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);
      
      const {filter, queryMD} = params;
      
      if(filter!=null && filter!=''){
        query = filter + '&' + new URLSearchParams(queryMD).toString();
      }
      else{
        query = "?" + new URLSearchParams(queryMD).toString();
      }

      const response = await Depots.getDepots(query);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetDepot = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Depots.getDepot(id);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetStorageSpaces = useCallback(async (customer_supplier_id) => {
    try {
      setLoading(true);
      const response = await Depots.getStorageSpaces(customer_supplier_id);

      return response.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetStorageOwners = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Depots.getStorageOwners();

      return response.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetStorageBusinessUnits = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Depots.getStorageBusinessUnits();

      return response.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetStorageStatuses = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Depots.getStorageStatuses();

      return response.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);
  const handleGetWarehouses = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Depots.getWarehouses(params);

      return response.data?.result;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const tempZonesOptions = [
    { value: 1, label: 'Shop' },
    { value: 2, label: 'Droguería' }  
  ];


  return {
    loading,
    queryString,
    setQueryString,
    handleGetDepots,
    handleGetDepot,
    handleGetStorageSpaces,
    handleGetStorageOwners,
    handleGetStorageStatuses,
    handleGetStorageBusinessUnits,
    handleGetWarehouses,
    tempZonesOptions
  };
};

export default useDepots;