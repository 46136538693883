import service from "../service";

const PATH = {
  geo: "/geo",
};

export class GoogleMaps {
  static getAutocomplete = async ( searchText) => {
    const pathParams = {query:searchText};

    const response = await service.get(PATH.geo, {params:pathParams});

    return response;

  };

  static getAutocompleteDetail = async ( searchText) => {

    const response = await service.get(`${PATH.geo}/place/${searchText}`);

    return response;

  };

  static postAddress = async ( placeId) => {

    const response = await service.post(`${PATH.geo}/address/place/${placeId}`);

    return response;

  };
  static postAddressCustom = async ( customAddress) => {

    const response = await service.post(`${PATH.geo}/address/custom`, customAddress);

    return response;

  };

}