import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useShipmentAddresses, useAgentTypes } from 'hooks'

import { SelectField, Input } from 'components'

const FROM_OPTIONS = [
  { value: 'CLIENTES', label: 'Clientes' },
  { value: 'PACIENTES', label: 'Pacientes' },
  { value: 'SIN_RELACION', label: 'Ninguno' },
]

const ORIGIN_OPTIONS = [
  { value: '', label: 'Todos' },
  { value: 'GOOGLE', label: 'GOOGLE' },
  { value: 'MANUAL', label: 'MANUAL' },
  { value: 'MIGRACION', label: 'MIGRACION' },
]

const TRACEABILITY_OPTIONS = [
  { value: '', label: 'Todas' },
  { value: '1', label: 'Sí' },
  { value: '0', label: 'No' },
]

const BOOLEAN_OPTIONS = [
  { value: '', label: 'Todas' },
  { value: '1', label: 'Sí' },
  { value: '0', label: 'No' },
]

const initialValues = {
  from: 'CLIENTES',
  text: '',
  localidad: '',
  provincia: '',
  origin_filter: '',
  has_traceability: '',
  agent_type_id_filter: [],
  gln: '',
  is_ours: '',
  is_enabled: '',
}

const Filter = ({ handleData, handleTotalCount, query, handleQuery }) => {
  const { currentUser, setCurrentUser } = useAuth()

  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)
  const { loading, handleGetShipmentAddresses } = useShipmentAddresses()

  const { handleGetAgentTypes } = useAgentTypes()
  const [agentTypes, setAgentTypes] = useState([])

  const filterData = (values) => {
    let params = {
      ...query,
      ...values,
      from: values?.from || undefined,
      text: values?.text ? values.text : undefined,
      localidad: values?.localidad ? values.localidad : undefined,
      provincia: values?.provincia ? values.provincia : undefined,
      origin_filter: values?.origin_filter ? values.origin_filter : undefined,
      has_traceability: values?.has_traceability ? values.has_traceability : undefined,
      agent_type_id_filter: values?.agent_type_id_filter ? values.agent_type_id_filter : undefined,
      gln: values?.gln ? values.gln : undefined,
      is_ours: values?.is_ours ? values.is_ours : undefined,
      is_enabled: values?.is_enabled ? values.is_enabled : undefined,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: 10,
      }
      const response = await handleGetShipmentAddresses('', '', params)

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        shipment_addresses: { ...params }
      }))

      handleQuery({ ...params })
      handleData(response.data.result)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  const getAgentTypes = useCallback(async () => {
    try {
      const response = await handleGetAgentTypes()
      const data = response.result.map(d => ({
        value: d.id,
        label: d.name
      }))

      setAgentTypes(data)
    } catch (error) {
      setAgentTypes([])
      toast.error(error.message)
    }
  }, [handleGetAgentTypes])

  useEffect(() => {
    getAgentTypes()
  }, [getAgentTypes])

  useEffect(() => {
    if (currentUser?.user_id) {
      const { shipment_addresses } = currentUser

      setInitialFormikValues({
        ...initialValues,
        from: shipment_addresses?.from ? shipment_addresses.from : 'CLIENTES',
        text: shipment_addresses?.text || '',
        localidad: shipment_addresses?.localidad || '',
        provincia: shipment_addresses?.provincia || '',
        origin_filter: shipment_addresses?.origin_filter || '',
        has_traceability: shipment_addresses?.has_traceability || '',
        agent_type_id_filter: shipment_addresses?.agent_type_id_filter || [],
        gln: shipment_addresses?.gln || '',
        is_ours: shipment_addresses?.is_ours || '',
        is_enabled: shipment_addresses?.is_enabled || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
    {({ values, errors, setFieldValue, resetForm, submitForm }) => {
      return (
        <Form className='d-flex flex-column'>
          <div className='row'>
            <div className='col-2'>
              <SelectField
                id='from'
                name='from'
                options={FROM_OPTIONS}
                handleChange={(e) => {
                  resetForm({ values: {
                    ...initialValues,
                    from: e.value,
                  }})
                  setFieldValue('from', e.value)

                  submitForm()
                }}
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>De</p>
            </div>

            <div className='col-4'>
              <Input id='text' name='text' disabled={loading} />
              <p className='text-muted'>Nombre / Domicilio</p>
            </div>

            <div className='col-4'>
              <Input id='localidad' name='localidad' disabled={loading} />
              <p className='text-muted'>Localidad</p>
            </div>

            <div className='col-2'>
              <Input id='provincia' name='provincia' disabled={loading} />
              <p className='text-muted'>Provincia</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <SelectField
                id='origin_filter'
                name='origin_filter'
                options={ORIGIN_OPTIONS}
                placeholder='Seleccione el origen'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Origen</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='has_traceability'
                name='has_traceability'
                options={TRACEABILITY_OPTIONS}
                placeholder='Seleccione una opción'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Trazabilidad</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='agent_type_id_filter'
                name='agent_type_id_filter'
                options={agentTypes}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Tipo de agente</p>
            </div>

            <div className='col-4'>
              <Input id='gln' name='gln' disabled={loading} />
              <p className='text-muted'>GLN</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <SelectField
                id='is_ours'
                name='is_ours'
                options={BOOLEAN_OPTIONS}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Nuestra</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='is_enabled'
                name='is_enabled'
                options={BOOLEAN_OPTIONS}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Habilitada</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )
    }}
    </Formik>
  )
}

export default Filter