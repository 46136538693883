
import React, { useEffect } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Error, InputBS, ProductTypeahead } from 'components';


const ParentProduct = () => {
  const { values } = useFormikContext();

  return (
    <>
        <div style={{ borderBottom:"solid 1px #cdcdcd", marginBottom:"20px", paddingBottom:"20px"}}>
          <div className="row mt-4">
            <p className='m-1'><b>Producto Padre: </b>{values.product_name}</p>
            <p className='m-1'><b>{values.product_type_id==1?'Laboratorio':'Fabricante'}:</b>     {values.maker_name}</p>
            <p className='m-1'><b>{values.product_type_id==1?'Ppio. Activo':'Familia'}:</b>    {values.family_name}</p>
          </div>
        </div>
    </>
  );
};


export default ParentProduct;
