import * as Yup from 'yup'

const createInitialValues = {
  // name: '',
  label: '',
  payment_method_id: '',
  days: '',
  cancel_date: '',
}

const createSchema = Yup.object().shape({
  // name: Yup.string().required('* Ingrese un nombre para la condición de pago'),
  label: Yup.string().required('* Ingrese un label para la condición de pago'),
  payment_method_id: Yup.number().required('* Elija un método de pago'),
  days: Yup.number().required('* Ingrese los días de la condición de pago'),
})

export {
  createInitialValues,
  createSchema,
}