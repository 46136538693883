import { useState, useCallback } from 'react'

import { HomeDeliveryOptions } from 'services'

const useHomeDeliveryOptions = () => {
  const [loading, setLoading] = useState(false)

  const handleGetHomeDeliveryOptions = useCallback(async () => {
    try {
      setLoading(true)
      const response = await HomeDeliveryOptions.getHomeDeliveryOptions()

      return response
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, []);

  const handleGetHomeDeliveryOption = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await HomeDeliveryOptions.getHomeDeliveryOption(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, []);

  return {
    loading,
    handleGetHomeDeliveryOptions,
    handleGetHomeDeliveryOption,
  };
};

export default useHomeDeliveryOptions