import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { DetailContainer } from 'containers'
import { FormGroup, SelectField, InputBS } from 'components'

import { useProducts, useGetProductDetail } from 'hooks'

import { formikInitialValues, createSchema } from './constants'

import { FormLabel, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'


const ProductDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState(formikInitialValues)
  const { product, productFamilies, productMakers, loading } = useGetProductDetail(id)
  const { handleUpdateProduct, distributionChannelOptions, productTypesOptions, loading: loading_upd } = useProducts()


  const submitForm = async (values) => {
    try {
      const data = {
        id: values.id,
        name: values.name,
        product_type_id: values.product_type_id,
        family_id: values.family_id,
        maker_id: values.maker_id,
        distribution_channels: values.channels?.join(",")
      }
      const response = await handleUpdateProduct(data)

      toast.success("Registro guardado correctamente", { theme: "colored" })
      navigate('/inventario/productos')
    } catch (error) {
      toast.error(error.message, { theme: "colored" })
      console.log(error)
    } finally {
    }
  }


  useEffect(() => {
    if (product && productMakers && productFamilies && distributionChannelOptions) {
      const { id, name, vars, maker_id, family_id, product_type_id } = product
      const channels = product.distribution_channels.map(d => d.distribution_channel_id)

      if (initialValues.id != product.id) {
        setInitialValues({ ...initialValues, id, name, vars, product_type_id, channels, family_id, maker_id })
      }
    }
  }, [product, productMakers, productFamilies, distributionChannelOptions])

  return (
    <>
      <DetailContainer title="Edición de producto">
        <Formik
          initialValues={initialValues}
          validationSchema={createSchema}
          onSubmit={submitForm}
          enableReinitialize
        >
          {({ errors, values }) => {
            return (
              <Form className="d-flex flex-column" >
                <div className="row mt-5 mb-5">
                  <div className='col'>
                    <SelectField
                      label="Tipo"
                      name="product_type_id"
                      options={productTypesOptions}
                      marginTop='18px'
                      isDisabled={values.vars?.length > 0}
                    />
                  </div>

                  <div className='col'>
                    <InputBS name="name" label="Nombre" className="mt-5" />
                  </div>
                </div>

                <div className="row mt-5 mb-5">
                  <div className='col'>
                    <SelectField
                      label={product?.product_type_id == 1 ? 'Ppio. Activo' : 'Familia'}
                      name="family_id"
                      options={productFamilies?.map(f => ({ label: f.description, value: f.id }))}
                      marginTop='3px'
                      isDisabled={values.vars?.length > 0}
                    />
                  </div>

                  <div className='col'>
                    <SelectField
                      label={product?.product_type_id == 1 ? 'Laboratorio' : 'Fabricante'}
                      name="maker_id"
                      options={productMakers?.map(f => ({ label: f.description, value: f.id }))}
                      marginTop='3px'
                      isDisabled={values.vars?.length > 0}
                    />
                  </div>

                  <div className="col">
                    <SelectField
                      label="Canal Comercial"
                      name="channels"
                      options={distributionChannelOptions}
                      isMulti="true"
                      placeholder="Seleccionar..."
                    />
                  </div>
                </div>

                {values.vars?.length > 0 &&
                  <FormGroup>
                    <FormLabel className="mt-10">El producto tiene las siguientes variaciones</FormLabel>

                    <Table striped hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nombre</th>
                          <th>GTIN</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.vars?.map(v => (
                          <tr key={v.id}>
                            <td>{v.id}</td>
                            <td>{v.name}</td>
                            <td>{v.gtin}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </FormGroup>
                }

                <button className="btn btn-primary align-self-end mt-10" type="submit" disabled={!product?.name  || loading_upd}>
                  {(!product?.name || loading_upd) ? 'Cargando...' : 'Guardar'}
                </button>
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default ProductDetail