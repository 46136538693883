import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { InputBS, FormButton } from 'components'

import { useCarriers } from 'hooks'
import { createInitialValues, createSchema } from './constants'

import routes from 'app/routing/routes'

const AddCarrier = () => {
  const { handleCreateCarrier } = useCarriers()
  const navigate = useNavigate()
  const intl = useIntl()
  const [loading] = useState(false)

  const createCarrier = async (values) => {
    try {
      const response = await handleCreateCarrier(values, 'token')
      toast.success("Registro creado correctamente", {theme:"colored"})
      navigate(routes.CARRIERS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Transportistas/', path: routes.CARRIERS, isSeparator: false, isActive: true },
          { title: 'Crear transportista', path: routes.CARRIERS_NEW, isActive: false },
        ]}
        description='Crear transportista'
      />

      <DetailContainer title={intl.formatMessage({ id: 'TITLE.CARRIERS.CREATE' })}>
        <Formik
          initialValues={createInitialValues}
          validationSchema={createSchema}
          onSubmit={createCarrier}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className='col-12'>
                    <InputBS id='description' name='description' label={intl.formatMessage({ id: 'FORM.CARRIERS.DESCRIPTION' })} />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddCarrier