import { useState, useRef, useMemo, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

import { useClickOutside, useOutOrders } from "hooks"

import { TableContainer } from "containers"
import { CustomTable } from "components"
import ContextMenu from "components/ContextMenu"

import Filter from "./Filter"
import CancelOrderModal from "./CancelOrderModal"
import { useGetColumns } from './columns'
import { OutOrderStatusI } from "interfaces"

import routes from "app/routing/routes"

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  out_order_status_id: OutOrderStatusI.EN_REVISION,
}

const LogisticRevision = () => {
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const {handleGetOutOrders, handleUpdateOutOrderStatus, handleGetSimulateCancel, handleCancelOrder} = useOutOrders();
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  const [cancelData, setCancelData] = useState("")

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const navigate = useNavigate();

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
      setCurrentRow(row)

      if (e) {
        e.preventDefault();
        setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

        if (!showContextMenu) {
          setShowContextMenu(true)
        }
      }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({...currentPos, target:''})
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: 'Ir a detalle',
        icon: 'bi bi-eye text-primary',
        action: actionEdit,
      })

      if (currentRow.out_order_status_id === OutOrderStatusI.EN_REVISION) {
        actions.push({
          label: "Enviar a armado",
          icon: "bi bi-boxes text-primary",
          action: actionReloadOrder
        })

        actions.push({
          label:"Coordinar entrega",
          icon:"bi bi-check-circle text-success",
          action: actionCoordinate
        })

        actions.push({
          label: "Cancelar órden",
          icon: "bi bi-clipboard-x text-danger",
          action: actionCancelOrder
        })
      }
    }

    return actions
  }

  const actionCancelOrder = async () => {
    try {
      const resp = await handleGetSimulateCancel(currentRow?.id)

      if (resp && resp.data) {
        setCancelData({
          currentRow,
          extras: resp.data
        })
        setShowCancelOrderModal(true)
      }
      // console.log(resp)
    } catch (err) {
      toast.error(err?.message)
    }
  }

  const onHideCancelOrderModal = async (ob) => {
    if (!ob) {
      setShowCancelOrderModal(false)
    } else {
      try {
        const resp = await handleCancelOrder(currentRow.id, {
          generar_nc:true,
          stock:[]
        })
        getOutOrders()
        toast.success("Orden actualizada correctamente", { theme: "colored" })
      } catch (err) {
        toast.error(err?.message)
      }
    }
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.OUT_ORDERS}/${currentRow.id}`, { state: { referer: 'Revisiones' }});
    }
  }

  const actionReloadOrder = async () => {
    try {
      if (currentRow) {
        const resp = await Swal.fire({
          title: "Advertencia",
          text: `Va a enviar a preparar la órden #${currentRow.id}, ¿confirma?`,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No",
          confirmButtonText: "Si, enviar a armado",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          await handleUpdateOutOrderStatus(currentRow.id, OutOrderStatusI.PENDIENTE_PREPARACION)
          toast.success("La órden se envió a armado", { theme: "colored" })
          getOutOrders()
        }
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const actionCoordinate = async () => {
    try {
      if (currentRow) {
        const resp = await Swal.fire({
          title: "Advertencia",
          text: `Va a enviar a coordinación de entrega a la órden #${currentRow.id}, ¿confirma?`,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No",
          confirmButtonText: "Si, enviar a coordinación",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          await handleUpdateOutOrderStatus(currentRow.id, OutOrderStatusI.COORDINACION_ENTREGA)
          toast.success("La órden se envió a coordinación", { theme: "colored" })
          getOutOrders()
        }
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }
  /* Fin menu contextual */

  const getOutOrders = useCallback(async () => {
    try {
      let params = {
        getDetail: true,
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }
      const response = await handleGetOutOrders(params)
      const data = response.data.result

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
    }
    // const resp = await handleGetOutOrders(query)
    // setData(resp.data?.result)
    // console.log(resp)
  }, [handleGetOutOrders, query])

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
      ),
    }
  ], [COLUMNS])


  useEffect(() => {
    getOutOrders()
  }, [getOutOrders])

  return (
    <>
      <CancelOrderModal show={showCancelOrderModal} onHide={onHideCancelOrderModal} cancelData={cancelData} />

      <TableContainer
        title={'Órdenes en revisión'}
        filter={<Filter handleData={setData} query={query} handleQuery={setQuery} handleTotalCount={setTotalCount} />}
      >
        <CustomTable columns={columns} data={data} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default LogisticRevision