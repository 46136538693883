import * as Yup from 'yup'

const createInitialValues = {
  description: '',
}

const createSchema = Yup.object().shape({
  description: Yup.string().required('* Ingrese una descripción'),
})

export {
  createInitialValues,
  createSchema,
}