import { useState, useCallback } from 'react'

import { Stock } from 'services'

const useStock = () => {
  const [loading, setLoading] = useState(false)

  // -- Depot movements
  const handleGetDepotMovements = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Stock.getDepotMovements(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDepotMovement = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Stock.getDepotMovement(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateDepotMovement = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Stock.createDepotMovement(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateDepotMovementDetail = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Stock.createDepotMovementDetail(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleConfirmDepotMovement = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Stock.createDepotMovementConfirm(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteInternalMovementDetail = useCallback(async (params) => {
    try {
      setLoading(true);

      const response = await Stock.deleteDepotMovementDetail(params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handlePrintInternalWaybill = useCallback(async (id, copias) => {
    try {
      setLoading(true);
      const response = await Stock.printInternalWaybill(id, copias);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    // -- Depot movements
    handleGetDepotMovements,
    handleGetDepotMovement,
    handleCreateDepotMovement,
    handleCreateDepotMovementDetail,
    handleConfirmDepotMovement,
    handlePrintInternalWaybill,
    handleDeleteInternalMovementDetail
  }
}

export default useStock