import { useTable, usePagination } from 'react-table'


const FrontPaginationTable = ({ columns, data, filter, setFilter, cellClassName='' }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize
  } = useTable({ columns, data }, usePagination);

  const {pageIndex} = state;


  return (
    <>
      {setFilter!=null ?
        <input
          className='form-control mt-6 mb-3'
          type="text"
          value={filter || ''}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Buscar número de serie..."
        />
      :
        <></>
      }

      <table className={`table table-striped gy-7 gs-7 `} {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()} className={`${cellClassName}`}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="d-flex justify-content-between align-items-center flex-wrap p-10">
            <div className="d-flex flex-wrap py-2 mr-3 ">
              <ul className="pagination">
                <li className={`page-item ${!canPreviousPage && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={()=>gotoPage(0)} disabled={!canPreviousPage}>
                    <i className="ki ki-double-arrow-back icon-sm"></i>
                  </button>
                </li>

                <li className={`page-item previous ${!canPreviousPage && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={()=>previousPage()} disabled={!canPreviousPage}>
                    <i className="ki ki-arrow-back icon-sm"></i>
                  </button>
                </li>

                {/* {obQuery.pageNumber > 1 && ( <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"> ... </a>)}

                {renderPageButtons()}

                {obQuery.pageNumber < noOfPages && ( <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"> ... </a>)} */}



                <li className={`page-item next ${!canNextPage && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={()=>{nextPage()}} disabled={!canNextPage}>
                    <i className="ki ki-arrow-next icon-sm"></i>
                  </button>
                </li>

                <li className={`page-item next ${!canNextPage && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={()=>gotoPage(pageCount-1)} disabled={!canNextPage}>
                    <i className="ki ki-double-arrow-next icon-sm"></i>
                  </button>
                </li>

                <li className='ps-10 pt-3'>
                  <span>
                    Página {pageIndex + 1} de {pageOptions.length}
                  </span>
                </li>
              </ul>

            </div>
          </div>

    </>
  );
};



export default FrontPaginationTable