import { useState, useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { DetailContainer } from 'containers'

import { usePaymentConditions, usePaymentMethods } from 'hooks'
import { InputBS, SelectField, FormButton } from 'components'

import { initialValues, schema } from './constants'
import { formatInputDate } from 'utilities'
import routes from 'app/routing/routes'

const PaymentConditionDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()

  const { loading, handleGetPaymentCondition, handleUpdatePaymentCondition } = usePaymentConditions()
  const [paymentCondition, setPaymentCondition] = useState(initialValues)

  const [paymentMethods, setPaymentMethods] = useState([])
  const { handleGetPaymentMethods } = usePaymentMethods()

  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await handleGetPaymentMethods('token')

      setPaymentMethods(response.result)
    } catch (error) {
      toast.error(error.message)
      console.log(error)
    }
  }, [handleGetPaymentMethods])

  const updatePaymentCondition = async (values) => {
    let data = {
      ...values,
      cancel_date: values?.cancel_date ? values.cancel_date : null,
    }
    delete data.id
    delete data.payment_method_name

    try {
      const response = await handleUpdatePaymentCondition(id, data, 'token')
      toast.success(response.message)
      navigate(routes.PAYMENT_CONDITIONS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const getPaymentCondition = async () => {
      try {
        const response = await handleGetPaymentCondition(id)

        setPaymentCondition({
          ...response.result,
          cancel_date: response.result?.cancel_date ? formatInputDate(response.result.cancel_date) : '',
        })
      } catch (error) {
        toast.error(error.message)
      }
    }

    getPaymentCondition()
  }, [id, handleGetPaymentCondition])

  useEffect(() => {}, [paymentCondition])

  useEffect(() => {
    getPaymentMethods()
  }, [getPaymentMethods])

  if (!id) {
    navigate(routes.PAYMENT_CONDITIONS)
  }

  return (
    <>
      <DetailContainer title={`${intl.formatMessage({ id: 'TITLE.PAYMENT_CONDITIONS.UPDATE' })} #${id}`}>
        <Formik
          initialValues={paymentCondition}
          validationSchema={schema}
          onSubmit={updatePaymentCondition}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className='col col-6'>
                    <InputBS id='label' name='label' label='Label' />
                  </div>

                  <div className='col col-6'>
                    <InputBS id='name' name='name' label={intl.formatMessage({ id: 'FORM.PAYMENT_CONDITIONS.NAME' })} disabled />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className='col-3'>
                    <SelectField
                      name='payment_method_id'
                      label={intl.formatMessage({ id: 'FORM.PAYMENT_CONDITIONS.PAYMENT_METHOD' })}
                      options={paymentMethods.map(item => ({ value: item.id, label: item.name }))}
                    />
                  </div>

                  <div className='col-3'>
                    <InputBS id='days' name='days' type='number' label={intl.formatMessage({ id: 'FORM.PAYMENT_CONDITIONS.DAYS' })} />
                  </div>

                  <div className='col-3'>
                    <InputBS id='cancel_date' name='cancel_date' type='date' label={intl.formatMessage({ id: 'FORM.PAYMENT_CONDITIONS.CANCEL_DATE' })} />
                  </div>
                </div>

                <div className='row my-6'>
                  <div className='col-3'>
                    <SelectField
                      id='is_enabled'
                      name='is_enabled'
                      label={'Habilitada'}
                      options={[
                        { value: 1, label: intl.formatMessage({ id: 'OPTION.YES' }) },
                        { value: 0, label: intl.formatMessage({ id: 'OPTION.NO' }) },
                      ]}
                    />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default PaymentConditionDetail