import React from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

const PrintDispatchGuideModal = ({ show, onHide }) => {
  const { id } = useParams()

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Imprimir Guía de Despacho #{id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      </Modal.Body>
    </Modal>
  )
}

export default PrintDispatchGuideModal