import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useArticles, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './constants'
import Filter from './Filter'
import { StockModal } from './partials'

import routes from 'app/routing/routes'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const Articles= () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetArticlesV2 } = useArticles()
  const [data, setData] = useState()

  const [filter, setFilter] = useState("");
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [stockModal, setStockModal] = useState(false)

  const getArticles = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD
        }
      }

      if (currentUser && currentUser?.articles) {
        const { articles } = currentUser

        params = {
          ...params,
          id_filter: articles?.id_filter || '',
          text: articles?.text || '',
          product_type_id: articles?.product_type_id || '',
          maker_name: articles?.maker_name || '',
          family_name: articles?.family_name || '',
          gtin: articles?.gtin || '',
          sku: articles?.sku || '',
        }
      }

      const response = await handleGetArticlesV2(params)
      const data = response.data.result

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetArticlesV2, queryMD, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: actionEdit
    })

    actions.push({
      label: 'Ver stock',
      icon: 'bi bi-box-seam text-success',
      action: actionStock
    })

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.ARTICLES}/${currentRow.id}`, {
        state: { product_id: currentRow.product_id }
      })
    }
  }

  const actionStock = () => {
    if (currentRow) {
      navigate(`${routes.INVENTORY_STOCK}`, { state: { filter_text: currentRow.name }})
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD ,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    getArticles()
  }, [getArticles])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <StockModal show={stockModal} onHide={() => setStockModal(false)} article={currentRow} />

      <TableContainer
        title={'Artículos'}
        goTo={routes.ARTICLES_NEW}
        filter={<Filter setData={setData} setTotalCount={setTotalCount} setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default Articles