import React from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: 'ID',
    id: 'supplier_id',
    accessor: (row) => (
      <Tooltip text='Ver proveedor'>
        <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.supplier_id}`}>
          {row.supplier_id}
        </Link>
      </Tooltip>
    ),
  },
  {
    Header: 'Razón social',
    id: 'supplier_business_name',
    accessor: (row) => (
      <>
        {row.supplier_business_name}
      </>
    ),
  },
  {
    Header: 'Documento',
    id: 'supplier_documentation',
    accessor: (row) => (
      <>
        {row?.supplier_documentation ? row.supplier_documentation : '-'}
      </>
    )
  },
  {
    Header: <div className='text-center'>Habilitado</div>,
    id: 'supplier_is_enabled',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row.supplier_is_enabled) ? 'success' : 'danger'}`}>
          {Boolean(row?.supplier_is_enabled) ? 'Sí' : 'No'}
        </span>
      </div>
    )
  },
]

export {
  COLUMNS
}
