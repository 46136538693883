import { useState, useCallback } from 'react'

import { Genre } from 'services'

const useGenres = () => {
  const [loading, setLoading] = useState(false)

  const handleGetGenres = useCallback(async () => {
    try {
      setLoading(true)
      const response = await Genre.getGenres()

      return response
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, []);

  const handleGetGenre = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Genre.getGenre(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, []);

  return {
    loading,
    handleGetGenres,
    handleGetGenre,
  };
};

export default useGenres