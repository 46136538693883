import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useSalesOrders } from 'hooks'

const AuthModal = ({ auth = true, show, onClose, selectedRows, handleSelectedRows, handleGetSalesOrders }) => {
  const { loading, handleAuthMultipleProfitability, handleUnauthMultipleProfitability } = useSalesOrders()

  const multipleAuth = async () => {
    const resp = await Swal.fire({
      icon: "warning",
      title: 'Autorización múltiple de rentabilidad',
      text: '¿Quiere autorizar todos los pedidos seleccionados?',
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, autorizar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    })

    if (resp.isConfirmed) {
      try {
        const response = await handleAuthMultipleProfitability(selectedRows)
        toast.success(response.data.message)
        handleSelectedRows([])
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetSalesOrders()
        onClose()
      }
    }
  }

  const multipleUnauth = async () => {
    const resp = await Swal.fire({
      icon: "warning",
      title: 'Desautorización múltiple de rentabilidad',
      text: '¿Quiere desautorizar todos los pedidos seleccionados?',
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, desautorizar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    })

    if (resp.isConfirmed) {
      try {
        const response = await handleUnauthMultipleProfitability(selectedRows)
        toast.success(response.data.message)
        handleSelectedRows([])
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetSalesOrders()
        onClose()
      }
    }
  }

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='md' backdrop='shadow' centered>
      <Modal.Header closeButton>
        <Modal.Title>{`${auth ? 'Autorización' : 'Desautorización'} múltiple de rentabilidad`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <ul className='list-style-none m-0 p-0'>
              {selectedRows.map(r => (
                <li key={r.PedidoId} className='mb-3'>Pedido #{r.PedidoId}</li>
              ))}
            </ul>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='row'>
          <div className='col text-end'>
            <Button type='button' variant='secondary' onClick={onClose} className='me-3' disabled={loading}>
              <i className='bi bi-slash-circle' />
              Cerrar
            </Button>

            <Button type='button' variant='primary' onClick={auth ? multipleAuth : multipleUnauth} disabled={loading}>
              <i className='bi bi-save me-2' />
              {auth ? 'Autorizar' : 'Desautorizar'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AuthModal