import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PurchaseIncomeOrderColumnI } from 'interfaces'

import { currencyFormat, formatTableDate } from 'utilities'
import routes from 'app/routing/routes'
import moment from 'moment';
import { Tooltip } from 'react-bootstrap'

const useGetColumns = () => {
  const intl = useIntl()
/*
{
      "product_var_id": 5,
      "gtin": "7797811099219",
      "name": "BICARBONATO DE SODIO EWE est.x 250 g",
      "lot_number": "cuatrocuatro",
      "serial_number": "",
      "expiration_date": "2001-01-01T03:00:00.000Z",
      "stock_available": "4",
      "stock_reserved": 0
}
*/
  const COLUMNS =  [
    {
      Header: "GTIN",
      accessor: 'gtin',
    },
    {
      Header: "Nombre",
      accessor: 'name',
    },
    {
      Header: "Almacenamiento",
      accessor: 'storage_space_name',
    },
    {
      Header: "Lote",
      accessor: 'lot_number',
    },
    {
      Header: "Vto",
      id: 'expiration_date',
      accessor: (row) => (
        <span>
          {row.expiration_date?moment(row.expiration_date).format("DD/MM/YYYY"):""}
        </span>
      ),
    },
    // {
    //   Header: "Serie",
    //   accessor: 'serial_number',
    // },
    {
      Header: "Total habilitado",
      accessor: 'stock_available_without_reserved',
    },
    {
      Header: "No disponible",
      accessor: 'stock_not_available',
    },
    // {
    //   Header: "Reservado",
    //   accessor: 'stock_reserved',
    // }


  ]

  return {
    COLUMNS
  }
}

export default useGetColumns