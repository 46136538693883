import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
//const URL = process.env.REACT_APP_SOCKET_URL === 'production' ? undefined : 'http://localhost:3000';

//const newSocket = io('https://qa-riga-farma.drofar.com.ar', { path: '/socket.io' });
const URL = process.env.REACT_APP_SOCKET_URL;
const config = {
    autoConnect : false,
    path : process.env.NODE_ENV=='development' ? undefined : '/socket.io'
};

// console.log(process.env)
export const socket = io(URL, config);