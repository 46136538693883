import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: <div className='text-end'>ID Artículo</div>,
    id: 'product_var_id',
    accessor: (row) => (
      <div className="text-end">
        <Tooltip text='Ir a artículo'>
          <Link to={`${routes.ARTICLES}/${row.product_var_id}`} state={{ product_id: row.product_id }}>
            {row.product_var_id}
          </Link>
        </Tooltip>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className=''>Nombre</div>,
    id: 'product_var_name',
    accessor: (row) => (
      <>
        {row.product_var_name}
      </>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>Descuento</div>,
    id: 'discount',
    accessor: (row) => (
      <div className='text-center'>
        {row.discount}%
      </div>
    )
  },
  {
    Header: <div className='text-center'>Activo</div>,
    id: 'is_active',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row.is_active) ? 'success' : 'danger'}`}>{Boolean(row.is_active) ? 'Sí' : 'No'}</span>
      </div>
    )
  }
]

export {
  COLUMNS
}