import { useState, useCallback } from 'react'

import { useAuth } from 'app/modules/auth'
import { BusinessUnits } from 'services'

const useBusinessUnits = () => {
  const [loading, setLoading] = useState(false)

  const { auth } = useAuth()

  const handleGetBusinessUnits = useCallback(async (params) => {
    try {
      setLoading(true)

      if (!auth?.token) {
        throw new Error('Token not found')
      }

      const response = await BusinessUnits.getBusinessUnits(params, auth.token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [auth])

  const handleGetBusinessUnit = useCallback(async (id, params) => {
    try {
      setLoading(true)
      if (!auth?.token) {
        throw new Error('Token not found')
      }

      const response = await BusinessUnits.getBusinessUnit(id, params, auth.token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [auth])

  const handleAddBusinessUnit = useCallback(async (data) => {
    try {
      setLoading(true)

      if (!auth?.token) {
        throw new Error('Token not found')
      }

      const response = await BusinessUnits.addBusinessUnit(data, auth.token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [auth])

  const handleUpdateBusinessUnit = useCallback(async (id, data) => {
    try {
      setLoading(true)

      if (!auth?.token) {
        throw new Error('Token not found')
      }

      const response = await BusinessUnits.updateBusinessUnit(id, data, auth.token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [auth])

  return {
    loading,
    handleGetBusinessUnits,
    handleGetBusinessUnit,
    handleAddBusinessUnit,
    handleUpdateBusinessUnit,
  }
}

export default useBusinessUnits