import service from 'services/service'

const PATH = {
  dispatchOrders: '/dispatch-orders',
  shipmentOrderGrouped: '/waybills/shipment-order-grouped',
  dispatchOrder: '/waybills/dispatch-order',
  createDispatchOrder: '/waybills/shipment-order/create-dispatch-order'
}

export class DispatchOrders {
  static get = async (params) => {
    const response = await service.get(`${PATH.dispatchOrders}`, {
      params
    })
    return response
  }

  static getOne = async (id) => {
    const response = await service.get(`${PATH.dispatchOrders}/${id}`)
    return response
  }

  static getDetail = async (id, params) => {
    const response = await service.get(`${PATH.dispatchOrders}/${id}/detail`, {
      params
    })
    return response
  }
}