import { useNavigate } from 'react-router-dom'


import routes from 'app/routing/routes'
import { Button } from 'react-bootstrap'

const Actions = ({ editing, handleEditing, submitRef, cancelRef }) => {
  const navigation = useNavigate()

  return (
    <div className='d-flex align-items-center justify-content-end'>
      {!editing
        ?
          <>
            <Button className='me-3' variant='secondary' type='button' onClick={() => navigation(routes.SHIPMENT_ADDRESSES)}><i className="ki-duotone ki-left" />Volver</Button>
            <Button variant='primary' type='button' onClick={() => handleEditing(true)}><i className='bi bi-pencil' />Editar</Button>
          </>
        :
          <>
            <Button className='me-3' variant='secondary' type='button' onClick={() => cancelRef.current.click()}><i className="bi bi-slash-circle" />Cancelar</Button>
            <Button variant='primary' type='button' onClick={() => submitRef.current.click()}><i className='bi bi-save me-2' />Guardar</Button>
          </>
      }
    </div>
  )
}

export default Actions