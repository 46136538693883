import React, { useEffect, useMemo, useState } from "react";
import { Button, FormGroup, FormLabel, Modal, Table } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormikContext } from "formik";
import BarCodeReader from "./BarCodeReader";


const defaultArticle={
  code:"",
  description:"",
  ean13:"",
  gtin13:"",
  qty:"",
  lote:"",
  vencimiento:"",
}


export function ArticleScanModal({ show, onHide, movement_id, getStockDetail }) {

    const [scanCodes, setScanCodes] = useState([]);

    const handleNewTag = (value, err)=>{

        if(!value){
          toast.error(err.message, {theme: "colored"})
          return;
        }
        const newScanCodes = [...scanCodes];
        newScanCodes.unshift(value.prod);
        setScanCodes(newScanCodes)
       
        // getStockDetail();
    }

    useEffect(()=>{
        setScanCodes([])
    }, [show])


    return (
      <>
            <Modal size="xl" show={show} onHide={onHide} backdrop='static' >

              <Modal.Body className="">
                <h3>Picking</h3>

                <BarCodeReader  handleNewTag={handleNewTag} movement_id={movement_id} storage_space_id={1}/>

                <div>
                { true && 
                    <FormGroup>
                      <FormLabel className="mt-10">Escaneados</FormLabel>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <td>Producto</td>
                            <td>Código</td>
                            <td>Serie</td>
                            <td>Lote</td>
                            <td>Vencimiento</td>
                          </tr>
                        </thead>
                        <tbody>{scanCodes?.map((v, idx)=>(
                          <tr key={idx}>
                            <td>{v.name}</td>
                            <td>{v.gs1?.gtin13}</td>
                            <td>{v.gs1?.serie || 'No trazable'}</td>
                            <td>{v.gs1?.lote || 'No trazable'}</td>
                            <td>{v.gs1?.vencimiento || 'No trazable'}</td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </FormGroup>
                }
                </div>

              </Modal.Body>

              <Modal.Footer style={{borderTop: "0 none"}}>
                <Button variant="secondary" onClick={()=>onHide()}>Salir</Button>
              </Modal.Footer>
          </Modal>
    </>
    )
  }
  