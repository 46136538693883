
import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Select from 'react-select';
import { FormLabel } from 'react-bootstrap';
import { useCustomerSuppliers, useBusinessUnits, useRequest } from 'hooks'

import {  Input, SelectField, Textarea, SwitchField } from 'components'

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '38px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};


const GeneralTab = ({ income }) => {
  const { values, setFieldValue } = useFormikContext()
  const [loading] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [distributors, setDistributors] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const currentDate = new Date().toISOString().split('T')[0]

  const { handleGetCustomerSuppliers, handleGetDistributor } = useCustomerSuppliers()
  const { handleGetBusinessUnits } = useBusinessUnits()
  const { handleGetPaymentConditions } = useRequest()

  const loadSuppliers = async () => {
    const data = await handleGetCustomerSuppliers()

    if (data && data.data && data.data.result) {
      setSuppliers(data.data.result
        .filter(p => Boolean(p.is_supplier))
        .map(p => ({ ...p, label: p.business_name, value: p.id }))
      )
    }
  }

  const loadBusinessUnits = async () => {
    const response = await handleGetBusinessUnits()

    if (response.data && response.data.result) {
      setBusinessUnits(response.data.result
        .map(i => ({ value: i.id, label: i.name }))
      )
    }
  }

  const loadPaymentConditions = async () => {
    let params = {
      // is_enabled: 1,
    }
    const data = await handleGetPaymentConditions(params)

    if (data && data.result) {
      setPaymentOptions(data.result
        .map(i => ({ ...i, label: i.label, value: i.id }))
      )
    }
  }

  const handleSupplierChange = async (selectedSupplier) => {
    
    setFieldValue('payment_condition_id', selectedSupplier.payment_condition_id)

    try {
      const response = await handleGetDistributor(selectedSupplier.value)
      if (response.data.result && response.data.result.length > 0) {
        setDistributors([
          ...response.data.result.map(d => ({ ...d, label: d.customer_supplier_business_name, value: d.customer_supplier_id }))
        ])
        if(values.con_distribuidor==true){
          setFieldValue('distributor_id_aux', values.distributor_id)
        }
        else{
          setFieldValue('distributor_id_aux', -1)
        }
      } else {
        setDistributors([])
        setFieldValue('distributor_id_aux', -1)
      }
    } catch (error) {
        setDistributors([])
        setFieldValue('distributor_id_aux', -1)
        toast.error(error.message)
    }
  }

  const getValue = () => {
    return distributors.find(option => option.value === values.distributor_id_aux) || null;
  };

  const onChange = (option) => {
    setFieldValue('distributor_id_aux', option.value)
  };

  useEffect(()=>{
      if(values.con_distribuidor==false){
          setFieldValue('distributor_id_aux', -1)
      }
  },[ values.con_distribuidor])

  useEffect(() => {
    loadSuppliers()
    loadPaymentConditions()
    loadBusinessUnits()
  }, [])

  return (
    <>
      <div className='row'>
        <div className='col mb-4 text-end'>
          <Button variant="primary" type='submit'>
            <i className="bi bi-save"/>
            Guardar
          </Button>
        </div>
      </div>

      <div className='row mb-10 mt-5'>

        <div className='col-6'>
          <SelectField
            label={'Proveedor'}
            name="supplier_id"
            options={suppliers}
            marginTop='3px'
            isMulti={false}
            placeholder='Seleccione el proveedor'
            handleChange={handleSupplierChange}
          />
        </div>

        <div className="col-3  d-flex justify-content-end align-items-center" style={{paddingTop:"27px"}}>
            <div className="d-flex">
              <SwitchField  id='con_distribuidor' name='con_distribuidor'/> <span style={{ fontSize: '1.1rem' }}>Con distribuidor</span>
            </div>
        </div>        
        
        <div className='col-3 pt-2'>
            <FormLabel style={labelStyle}>Distribuidor</FormLabel>
            <Select
              options={distributors}
              value={getValue()}
              onChange={onChange}
              styles={customStyles}
              isDisabled={!values.con_distribuidor}
            />

          {/* <SelectField
            label={'Distribuidor'}
            name="distributor_id"
            options={distributors}
            marginTop='3px'
            isMulti={false}
            defaultValue={distributors.length > 0 ? distributors[0] : []}
            placeholder='Seleccione el distribuidor'
          /> */}
        </div>

      </div>

      <div className='row mb-5'>
        <div className="col-3">
          <label htmlFor='issue_date' className='form-label'>Fecha comprobante</label>
          <Input id='issue_date' name='issue_date' type='date' disabled />
        </div>

        <div className="col-3">
          <label htmlFor='estimated_in_date' className='form-label'>Fecha estimada de ingreso</label>
          <Input id='estimated_in_date' name='estimated_in_date' type='date' min={currentDate} />
        </div>

        <div className='col-3'>
          <SelectField
            isMulti={false}
            label={'Unidad de negocio'}
            name="business_unit_id"
            options={businessUnits}
            marginTop='3px'
            placeholder='Seleccione la unidad de negocio'
          />
        </div>

        <div className='col-3'>
          {!income &&
            <SelectField
              isMulti={false}
              label={'Condición de pago'}
              name="payment_condition_id"
              options={paymentOptions}
              marginTop='3px'
              placeholder='Seleccione la condición de pago'
            />
          }
        </div>
      </div>


      <div className='row mb-20'>
        <div className="col">
          <label htmlFor='notes' className='form-label'>Observaciones</label>
          <Textarea id='notes' name='notes' />
        </div>

        <div className="col">
          <label htmlFor='internal_notes' className='form-label'>Notas internas</label>
          <Textarea id='internal_notes' name='internal_notes' />
        </div>
      </div>
    </>
  )
}

export default GeneralTab