import { FC } from 'react'

import { Label } from 'components'
import { FormGroupI } from './types'

const FormGroup: FC<FormGroupI> = ({
  htmlFor,
  label,
  width = '',
  styles = '',
  labelStyles = '',
  isCheckbox = false,
  noMargin = false,
  children
}) => {
  return (
    <div className={`fv-row ${!noMargin ? 'mb-8' : ''} ${width} ${styles}`}>
      {isCheckbox
        ?
        <div className='d-flex'>
          {children}
          <Label styles={labelStyles} htmlFor={htmlFor} label={label} />
        </div>
        :
        <>
          <Label styles={labelStyles} htmlFor={htmlFor} label={label} />
          {children}
        </>
      }
    </div>
  )
}

export default FormGroup