import { FC, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'

import { Input, SelectField, SwitchField } from 'components'
import { Button, Dropdown } from 'react-bootstrap'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs';
import { toast } from 'react-toastify'
import { useOutOrders, useSalesOrders } from 'hooks'
import useReports from 'hooks/useReports'


const groupedStatusOptions = [
  { value: 1, label: "Pendiente" },
  { value: 2, label: "Operaciones" },
  { value: 3, label: "Finalizado" },
]

const initialFormikValues = {
  issue_date_from:'',
  issue_date_to:'',
  transac_doc_date_from:'',
  transac_doc_date_to:'',
  sales_order_status_id_filter:'',
  grouped_status_id:'',
  text:'',
  with_nc:'',
  with_fc:''
  // addDetail:false
  // sortField: id,
  // sortOrder: desc
}


const Filter = ({ queryMD, setQueryMD, getExport, setConDetalle }) => {
    const [statusOptions, setStatusOptions] = useState([]);  

    const { handleGetSalesOrderStatus } = useSalesOrders()

    const transformData = (values)=>{
        const params = {}
        for (const key in values) {
          if (values[key] !== '') {

              if(key=='issue_date_from' || key=='issue_date_to' || key=='transac_doc_date_from' || key=='transac_doc_date_to'){
                params[key] = moment(values[key]).format('YYYY-MM-DD')
              }
              else if(key=='sales_order_status_id_filter' || key=='grouped_status_id'){
                if(values[key] && values[key].length){
                  params[key]=values[key].join(',')
                }                
              }
              // else if(key=='addDetail'){
              //   if(values[key]){
              //     params[key] = 1
              //   }                
              // }
              else{              
                params[key] = values[key]
              }
          }
        }
        return params;
    }

    const filterData = (values) => {
      console.log(values.with_fc)
        const params = transformData(values);
        let _with_fc = undefined;
        let _with_nc = undefined;

        if(values.with_fc > -1){
          _with_fc= values.with_fc
        }
        if(values.with_nc > -1){
          _with_nc= values.with_nc
        }
        
        
        const newQuery = {
          ...params, 
          with_fc : _with_fc,
          with_nc : _with_nc,
          pageNumber : queryMD.pageNumber ? queryMD.pageNumber : 1, 
          pageSize : queryMD.pageSize ? queryMD.pageSize : 10
        }
        setQueryMD(newQuery)
    }

    const exportData = async (values) => {
      try {
        const params = transformData(values);
        let _with_fc = undefined;
        let _with_nc = undefined;

        if(values.with_fc > -1){
          _with_fc= values.with_fc
        }
        if(values.with_nc > -1){
          _with_nc= values.with_nc
        }
        
        
        const newQuery = {
          ...params, 
          with_fc : _with_fc,
          with_nc : _with_nc,
          with_fc : (values.with_fc==-1 || values.with_fc=='') ? undefined : values.with_fc,
          with_nc : (values.with_nc==-1 || values.with_nc=='') ? undefined : values.with_nc,
          pageNumber:1, 
          pageSize:undefined
        }

        const response = await getExport(newQuery)

        if (response) {
          const datos = response.map(d => {
            return d;
          })

          var ws = XLSX.utils.json_to_sheet(datos);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Ventas");
          XLSX.writeFile(wb, `Ventas ${moment().format("YYYYMMDD-HHmm")}.xlsx` );
        }
      } catch (error) {
        toast.error(error.message)
      }
    }

    const exportDetailData = async (values) => {
      try {
        const params = transformData(values);
        const newQuery = {
          ...params, 
          addDetail:1,
          pageNumber:1, 
          pageSize:undefined
        }

        const response = await getExport(newQuery)

        if (response) {
          const datos = response.map(d => {
            return d;
          })

          var ws = XLSX.utils.json_to_sheet(datos);

          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Ventas_Detalle");
          XLSX.writeFile(wb, `Ventas_Detalle ${moment().format("YYYYMMDD-HHmm")}.xlsx` );
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  
    const getSalesOrderStatus = async () => {
        let params = {}

        try {
          const response = await handleGetSalesOrderStatus(params)

          const statuses = response.data.result
              .filter(s=>s.name!='RESERVA_PARCIAL')
              .map(s => {
                  return {
                    value: s.id,
                    label: s.label,
                  }
              })

          setStatusOptions(statuses)
        } catch (error) {
          toast.error(error.message)
        }
    }

    const cambiaSwitchRemito = (ev)=>{
      setConDetalle(ev?.target?.checked)
    }

    useEffect(()=>{
      getSalesOrderStatus()
    }, [])

    return (
      <Formik
        initialValues={initialFormikValues}
        onSubmit={filterData}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className='row'>
              {/* <div className='col-6'>
                <SelectField id='transactional_document_type_id' name='transactional_document_type_id' marginTop={'-20px'}  
                  options={optionsComprobantes || []} isMulti="true" />
                <p className='text-muted'>Comprobante</p>
              </div> */}
              <div className='col'><Input id='issue_date_from' name='issue_date_from' type='date' max={values.issue_date_to} /><p className='text-muted'>Pedido desde</p></div>
              <div className='col'><Input id='issue_date_to' name='issue_date_to' type='date' min={values.issue_date_from} /><p className='text-muted'>Pedido hasta</p></div>
              <div className='col'><Input id='transac_doc_date_from' name='transac_doc_date_from' type='date' max={values.transac_doc_date_to}/><p className='text-muted'>Comprobante desde</p></div>
              <div className='col'><Input id='transac_doc_date_to' name='transac_doc_date_to' type='date' min={values.transac_doc_date_from} /><p className='text-muted'>Comprobante hasta</p></div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <SelectField
                  id='sales_order_status_id_filter'
                  name='sales_order_status_id_filter'
                  options={statusOptions || []}
                  isMulti
                  marginTop={'-20px'}
                  placeholder='Seleccione un estado'
                />
                <p className='text-muted'>Estado</p>
              </div>
              <div className='col-3'>
                <SelectField
                  id='grouped_status_id'
                  name='grouped_status_id'
                  options={groupedStatusOptions || []}
                  isMulti
                  marginTop={'-20px'}
                  placeholder='Seleccione un estado'
                />
                <p className='text-muted'>Estado agrupado</p>
              </div>
             
              <div className="col-2">
                <Input id='text' name='text' />
                <p className='text-muted'>Cliente</p>
              </div>
              <div className='col-2'>
                <SelectField
                  id='with_fc'
                  name='with_fc'
                  options={[{value:-1, label:"Todos"}, {value:0, label:"Sin Factura"}, {value:1, label:"Con Factura"}]}
                  marginTop={'-20px'}
                  placeholder=''
                />
                <p className='text-muted'>Factura</p>
              </div>
              <div className='col-2'>
                <SelectField
                  id='with_nc'
                  name='with_nc'
                  options={[{value:-1, label:"Todos"}, {value:0, label:"Sin Nota de crédito"}, {value:1, label:"Con Nota de crédito"}]}
                  marginTop={'-20px'}
                  placeholder=''
                />
                <p className='text-muted'>Nota de crédito</p>
              </div>
              


              {/* <div className="col-3">
                  <div className="d-flex">
                    <SwitchField  id='conFactura' name='conFactura' handleChange={(ev)=>cambiaSwitchRemito(ev)}/> 
                    <span style={{ fontSize: '1.1rem' }}>Con factura</span>
                  </div>
                  <div className="d-flex mt-2">
                    <SwitchField  id='conRemito' name='conRemito' handleChange={(ev)=>cambiaSwitchRemito(ev)}/> 
                    <span style={{ fontSize: '1.1rem' }}>Con remito</span>
                  </div>

              </div> */}

            </div>
            <div className="row mt-3">
              <div className='col text-end'>
                <Dropdown className='d-inline me-3'>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic"><i className="bi bi-file-excel pe-3"/>Exportar</Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: "200px" }}>
                      <Dropdown.Item onClick={() => exportData(values)} disabled={false}>Cabecera</Dropdown.Item>
                      <Dropdown.Item onClick={() => exportDetailData(values)} disabled={false}>Detalle</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button  variant="primary" type='submit' disabled={false}><i className="bi bi-play mb-1"></i>Ejecutar</Button>
              </div>
            </div>

        </Form>
      )}
    </Formik>
  )
}

export default Filter