import { FC, useState, useEffect } from 'react'
import { Card, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

import { Label } from 'components'
import { ProductI, ProductOptionI } from 'interfaces'
import { ArticlesModalI } from './types'
import { currencyFormat } from 'utilities'

import './styles.sass'

const ArticlesModal: FC<ArticlesModalI> = ({ show, onClose }) => {
  const [loading] = useState(false)
  const [articlesOptions, setArticlesOptions] = useState<Array<ProductOptionI>>([])

  const handleSearchArticles = async () => {
    setArticlesOptions(mock_articles)
  }

  useEffect(() => {
  }, [articlesOptions])

  if (!show) return null

  return (
    <div className='Modal' onClick={onClose}>
      <Card className='Modal-content' onClick={e => e.stopPropagation()}>
        <Card.Header>
          <Card.Title>Artículos</Card.Title>
        </Card.Header>
        <Card.Body className='Modal-body'>
          <Label label='' htmlFor='search-product' />
          <AsyncTypeahead
            id='search-product'
            isLoading={loading}
            minLength={3}
            labelKey={option => {
              let product = option as ProductI;
              return `${product.id} - ${product.name}`
            }}
            onSearch={handleSearchArticles}
            options={articlesOptions}
            filterBy={() => true}
            promptText='Buscar artículo'
            searchText='Buscando...'
            placeholder='Buscar por código/nombre'
            emptyLabel='No se encontró el artículo'
          />

          <div className='table-responsive table-wrapper'>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Stock</th>
                  <th>PVP</th>
                  <th>PSL</th>
                  <th>Próx. Fecha Venc.</th>
                  <th className='text-center'>Obs.</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {articlesOptions.map(article => (
                  <tr className='align-middle' key={article.id}>
                    <td>{article.id}</td>
                    <td>{article.name}</td>
                    <td>
                      {article.stock}
                      <span className='p-2' />
                      <OverlayTrigger trigger='click' placement='right' overlay={
                        <Popover id='article-stock-popover' title='Stock'>
                          <div className='table-responsive-lg table-wrapper'>
                            <table className='table table-rounded table-striped gs-7 mb-0'>
                              <thead>
                                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 text-center'>
                                  <th>OC</th>
                                  <th>En falta</th>
                                  <th>Reservado</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className='align-middle text-center'>
                                  <td>{article.oc}</td>
                                  <td>{article.pending}</td>
                                  <td>{article.reservated}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Popover>
                      }>
                        <i className='las la-info text-primary cursor-pointer' />
                      </OverlayTrigger>
                    </td>
                    <td>{currencyFormat.format(article.pvp)}</td>
                    <td>{currencyFormat.format(article.psl)}</td>
                    <td>
                      <select className='form-select form-select-solid'>
                        <option value=''>Vencimiento</option>
                        {article?.next_due_date && (article.next_due_date).map((date, index) => (
                          <option key={index} value={date}>{date}</option>
                        ))}
                      </select>
                    </td>
                    <td className='text-center'>
                      {article.observations !== '' &&
                        <OverlayTrigger placement='bottom' overlay={<Tooltip>{article.observations}</Tooltip>}>
                          <button className='btn btn-icon btn-outline btn-outline-dashed btn-outline-primary btn-sm'>
                            <i className='las la-info' />
                          </button>
                        </OverlayTrigger>
                      }
                    </td>
                    <td className='text-center'>
                      <button className='btn btn-primary'>
                        Agregar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

const mock_articles = [
  {
    id: 864,
    name: 'HUMIRA AC X 2 40 MG 40mg/0.4ml lap.prell.x 2',
    stock: 11,
    oc: 2,
    pending: 2,
    reservated: 0,
    pvp: 502378,
    psl: 502378,
    observations: 'Test observations',
    next_due_date: [
      '30-09-2023',
    ],
  },
  {
    id: 865,
    name: 'HUMIRA PEDIATRICO vial x 2 x 0.8 ml',
    stock: 11,
    oc: 2,
    pending: 2,
    reservated: 0,
    pvp: 104340,
    psl: 104340,
    observations: '',
    next_due_date: []
  },
  {
    id: 56800,
    name: 'HUMIRA AC X 2 20 MG 20mg/0.2ml jer.prell.x 2',
    stock: 0,
    oc: 0,
    pending: 0,
    reservated: 0,
    pvp: 251189.15,
    psl: 251189.15,
    observations: '',
    next_due_date: []
  },
  {
    id: 56801,
    name: 'HUMIRA AC X 2 20 MG 20mg/0.2ml jer.prell.x 2',
    stock: 0,
    oc: 0,
    pending: 0,
    reservated: 0,
    pvp: 251189.15,
    psl: 251189.15,
    observations: '',
    next_due_date: []
  },
]

export default ArticlesModal
