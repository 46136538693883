import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { InputBS, FormButton } from 'components'

import { useSellers, useWindowsDimensions } from 'hooks'
import { createInitialValues, createSchema } from './constants'

import routes from 'app/routing/routes'

const AddSeller = () => {
  const { handleCreateSeller } = useSellers()
  const navigate = useNavigate()
  const intl = useIntl()
  const [loading] = useState(false)
  const { width } = useWindowsDimensions()

  const createSeller = async (values) => {
    try {
      const response = await handleCreateSeller(values, 'token')

      toast.success(response.message)
      navigate(routes.SELLERS)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Vendedores/', path: routes.SELLERS, isSeparator: false, isActive: true },
          { title: 'Crear vendedor', path: routes.SELLERS_NEW, isActive: false },
        ]}
        description='Crear vendedor'
      />

      <DetailContainer title={intl.formatMessage({ id: 'TITLE.SELLERS.CREATE' })}>
        <Formik
          initialValues={createInitialValues}
          validationSchema={createSchema}
          onSubmit={createSeller}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-5'>
                  <div className={`col col-lg-6 col-md-6 col-sm-12 col-12 ${width <= 767 ? 'mb-8' : ''}`}>
                    <InputBS id='first_name' name='first_name' label={intl.formatMessage({ id: 'FORM.SELLERS.FIRST_NAME' })} />
                  </div>

                  <div className={`col col-lg-6 col-md-6 col-sm-12 col-12`}>
                    <InputBS id='last_name' name='last_name' label={intl.formatMessage({ id: 'FORM.SELLERS.LAST_NAME' })} />
                  </div>
                </div>

                <div className='row my-5'>
                  <div className='col col-lg-6 col-md-6 col-sm-12 col-12'>
                    <InputBS id='email' name='email' label={intl.formatMessage({ id: 'FORM.SELLERS.EMAIL' })} />
                  </div>
                </div>

                <FormButton loading={loading} />
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddSeller