import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useWaves } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter } from './partials'
import { COLUMNS } from './columns'

import routes from 'app/routing/routes'
import { OutOrderStatusI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  wave_status_id_filter: 1
}

const ESTADOS_OLA = {
  EN_PREPARACION: 1,
  COMPLETA: 2,
  CANCELADA: 3,
}

export const labelColor = (order_status_id) => {
  switch(order_status_id){
    case ESTADOS_OLA.EN_PREPARACION:
      return "estado-azul";
    case ESTADOS_OLA.COMPLETA:
      return "estado-verde";
    case ESTADOS_OLA.CANCELADA:
      return "estado-rojo";
  }
}

const Waves = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetWaves, handlePrintConsolidado, handleUpdateWaveStatus, handleCancelWave } = useWaves()
  const [data, setData] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getWaves = useCallback(async () => {
    try {
      let params = {
        getDetail: true
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.waves) {
        params = {
          ...params,
          id: currentUser.waves?.id || '',
          text: currentUser.waves?.text || '',
          from_date: currentUser.waves?.from_date || '',
          to_date: currentUser.waves?.to_date || '',
          wave_status_id_filter: currentUser.waves?.wave_status_id_filter ? currentUser.waves.wave_status_id_filter : [1]
        }
      }

      const response = await handleGetWaves(params)
      const _waves = response?.data?.result

      const enrichedData = _waves.map(item => ({
        ...item,
        refrigeratedItems: item.orders.some(detail => detail.has_refrigeration == true),
        hasTraced: item.orders.some(detail => detail.has_traced == true),
        label_color: labelColor(item.wave_status_id),
        waybill_count: item.orders.reduce((acc, item)=> { return item.remito_id != null ? +acc + +1 : acc }, 0),
        waybill_ids: item.orders.filter(o => o.remito_id != null).map(o => o.id).join(','),
        pending_count: item.orders.reduce((acc, item) => { return item.remito_id == null ? +acc + +1 : acc }, 0),
        pending_ids: item.orders.filter(o => o.remito_id == null).map(o => o.id).join(','),
      }))
      setData(enrichedData)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetWaves, query, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: "Ver detalle",
        icon: "bi bi-pencil text-primary",
        action: editWave
      })

      actions.push({
        label: "Imprimir consolidado",
        icon: "bi bi-printer text-warning",
        action: printConsolidado
      })
    }

    if (
      currentRow &&
      currentRow.wave_status_id == ESTADOS_OLA.EN_PREPARACION &&
      currentRow.orders.every(o => o.out_order_status_id !== OutOrderStatusI.EN_PREPARACION && o.remito_id !== null)
    ) {
      actions.push({
        label: "Completar Ola",
        icon: "bi bi-check-circle text-success",
        action: () => completarOla(currentRow.id)
      })
    }

    if (
      currentRow &&
      currentRow.wave_status_id == ESTADOS_OLA.EN_PREPARACION &&
      currentRow.orders.every(o => o.out_order_status_id == OutOrderStatusI.EN_PREPARACION)
    ) {
      actions.push({
        label: "Cancelar Ola",
        icon: "bi bi-x-circle text-danger",
        action: () => cancelarOla(currentRow.id)
      })
    }

    return actions
  }

  const editWave = async () => {
    if (currentRow) {
      navigate(`${routes.WAVES}/${currentRow.id}`)
    }
  }

  const printConsolidado = async() => {
    if (currentRow) {
      try {
        const response = await handlePrintConsolidado(currentRow.id)

        if (response.status === 200) {
          toast.success('¡Consolidado descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `consolidado-${currentRow.id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          toast.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const completarOla = async (wave_id) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Está seguro de marcar como "Completada" la Ola #${wave_id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, completar`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleUpdateWaveStatus(wave_id, ESTADOS_OLA.COMPLETA);
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getWaves()
    }
  }

  const cancelarOla = async (wave_id) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Está seguro de cancelar la Ola #${wave_id}? Todas las órdenes van a volver al estado "Pendiente de preparación"`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cerrar',
        confirmButtonText: `Si, cancelar`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleCancelWave(wave_id)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getWaves()
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{fontSize: "1.5rem", cursor:"pointer"}} onClick={event=>handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getWaves()
  }, [getWaves])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Olas'}
        filter={<Filter setData={setData} query={query} setQuery={setQuery} setTotalCount={setTotalCount} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default Waves