import React, { useEffect, useMemo, useState } from "react"
import { useFormikContext } from 'formik'
import { Button, Form, FormControl, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap"

import { useArticles } from "hooks"

import '../article.css'
import {helix} from 'ldrs'
import { calculosCompra } from "utilities/calculosCompra"

export function ArticleSelectorModal({ show, onHide }) {
  const { values } = useFormikContext()

  const [articlesOptions, setArticlesOptions] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const {handleGetArticles, handleGetArticle, loading} = useArticles()
  const [txtSearch, setTxtSearch] = useState('')

  const handleKeyDown = async (event) => {
    if ((event.keyCode === 13 && event.target?.value?.length > 2) || (event.target?.name === 'btnSearch' && txtSearch.length>2)) {
      const queryMD = {
          text: txtSearch,
          add_stock: 1,
          add_price: 1,
          business_unit_id: values.business_unit_id,
          add_in_order_pending_qty: 1,
          add_missing_stock: 1,
          add_cost:1
      }
      /* Al menos, escribir 3 letras */
      if (txtSearch.length > 2) {
        const data =  await handleGetArticles({ queryMD })

        if (data && data.result) {
          console.log(data.result)
          const val = data.result.map(r => ({ 
            ...r, 
            purchase_discount_extra:(r.manual_list_cost && r.manual_list_cost.list_cost && +r.manual_list_cost.list_cost>0)? "0":r.purchase_discount_extra,
            selected: false, 
            qty: "" 
          }))
          
          setArticlesOptions(val)
        }
      }
    }
  };

  const onSave = () => {
    const arts = articlesOptions
      .filter(a => a.selected === true)
      .map(art => {

          let _precio_base = +art.psl;
          let _precio_compra = +art.psl - (art.psl * +art.purchase_discount_extra / 100);
          if(art.manual_list_cost && +art.manual_list_cost.list_cost>0){
              _precio_compra = +art.manual_list_cost.list_cost
              _precio_base= +art.manual_list_cost.list_cost
          }
          
          let _total = _precio_compra * +art.qty;
          let _subtotal= _total;
          let _monto_iva= 0;
          if(art.purchase_vat_rate && +art.purchase_vat_rate>0){
              _subtotal = +_total * 100 / (100 + +art.purchase_vat_rate);
              _monto_iva = +_total - +_subtotal;        
          }

          const data = {
              var_id: art.id,
              purchase_discount_base: art.purchase_discount_base,
              purchase_discount_extra: art.purchase_discount_extra,
              purchase_discount_extra_2: 0,
              product_var_desc: art.name,
              qty: art.qty,
              actual_pvp: art.actual_pvp,
              vat: _monto_iva,
              vat_rate: art.purchase_vat_rate,
              vat_id: art.purchase_vat,
              psl: _precio_base,
              unit_purchase_price: _precio_compra,
              subtotal: _subtotal,
              total: _total,
              psl_original:art.psl,
              outdated_pvp: 0
          }

          return data;
      })

    const invalid = arts.filter(a => a.qty === '' || !a.qty)

    if (invalid.length > 0) {
      return;
    }

    onHide({ articles: arts })
  }

  const handleQuantityChange = (productId, event) => {
    let value = event.target.value.replace(/\D/g, '')

    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, qty: value, selected: value ? true : false } : product)

    setArticlesOptions(updatedProducts)
  }
  const handleDiscountChange = (productId, event) => {
    let value = event.target.value.replace(/\D/g, '')

    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, purchase_discount_extra: value, selected: value ? true : false } : product)

    setArticlesOptions(updatedProducts)
  }
  const handleSelectionChange = (productId) => {
    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, selected: !product.selected } : product)
    setArticlesOptions(updatedProducts)
  }

  useEffect(() => {
    if (show) {
      setArticlesOptions([])
      setSelectedRows([])
      helix.register()
      setTxtSearch("")
    }
  }, [show])

  if (!show) return null

  return (
    <>
      <Modal size="xl" show={show} onHide={onHide} backdrop='static' scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Agregar un artículo al pedido</Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <div className='row'>
            <div className='col-10'>
              <FormLabel>Articulo</FormLabel>
              <FormControl
                type="text"
                onKeyDown={handleKeyDown}
                autoComplete="off"
                placeholder="Nombre del artículo (al menos 3 caracteres)..."
                value={txtSearch}
                onChange={(e) => setTxtSearch(e.target.value)}
                disabled={loading}
                autoFocus
              />
            </div>

            <div className='col mt-6'>
              <Button onClick={handleKeyDown} name="btnSearch" disabled={loading}>
                <i className="bi bi-search me-2" />
                Buscar
              </Button>
            </div>
          </div>

          <div className="row">
            <div className='col mt-5'>
              <Table striped={!loading} bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Laboratorio</th>
                    <th>Ppio. Activo</th>
                    <th className="text-center">Stock</th>
                    <th className="text-center" style={{ width: '100px' }}>Cantidad</th>
                    <th className="text-center" style={{ width: '100px' }}>Bonif.</th>
                    <th className="text-center" style={{ width: '100px' }}>Seleccionar</th>
                  </tr>
                </thead>

                <tbody>
                  {loading
                    ?
                      <tr>
                        <td colSpan='8' className='text-center bg-white'>
                          <div className='m-10'>
                            <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", top: "63%" }} />
                          </div>
                        </td>
                      </tr>
                    :
                      <>
                        {articlesOptions.map(product => (
                          <tr key={product.id}>
                            <td className="align-middle">{product.id}</td>
                            <td className="align-middle">{product.name}</td>
                            <td className="align-middle">{product.maker_name}</td>
                            <td className="align-middle">{product.family_name}</td>

                            <td className="align-middle text-center">
                              {product.stock_available}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="waves-print-tooltip">
                                      <div className=''>
                                        <table className='table table-rounded table-striped'>
                                          <thead>
                                            <tr className=' text-center'>
                                              <th>Pedido Compra</th>
                                              <th>Faltante</th>
                                              <th>Reservado</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr className='align-middle text-center'>
                                              <td>{product.in_order_pending_qty || 0}</td>
                                              <td>{product.missing_stock || 0}</td>
                                              <td>{product.stock_reserved || 0}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  </Tooltip>
                                }
                              >
                                <span className="ms-5">
                                  <i className="fa-regular fa-circle-question text-primary" />
                                </span>
                              </OverlayTrigger>
                            </td>

                            <td className="align-middle">
                              <Form.Control
                                type="text"
                                maxLength={5}
                                value={product.qty}
                                onChange={(e) => handleQuantityChange(product.id, e)}
                                className={(product.selected && !product.qty)?'border-danger':''}
                                style={{ width: '100px' }}
                              />
                            </td>

                            <td className="align-middle">
                              <Form.Control
                                type="text"
                                maxLength={5}
                                value={product.purchase_discount_extra}
                                onChange={(e) => handleDiscountChange(product.id, e)}
                                style={{ width: '100px' }}
                              />
                            </td>

                            <td className="align-middle text-center">
                              <Form.Check
                                type="checkbox"
                                checked={product.selected}
                                onChange={() => handleSelectionChange(product.id)}
                                className="chk-selector"
                                style={{ width: '100px' }}
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer style={{borderTop: "0 none"}}>
          <Button variant="secondary" onClick={onHide}>Salir</Button>
          <Button variant="primary" onClick={onSave} disabled={loading}>
            <i className="bi bi-database-add me-2" />
            Agregar Artículo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}