import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import { currencyFormat } from 'utilities'
import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: "ID Artículo",
    id: 'product_var_id',
    accessor: (row) => (
      <Tooltip text='Ver artículo'>
        <Link to={`${routes.ARTICLES}/${row.product_var_id}`} state={{ product_id: row.product_id }}>
          {row.product_var_id}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
  },
  {
    Header: "Artículo",
    id: 'product_var_name',
    accessor: (row) => (
      <>
        {row.product_var_name}
      </>
    ),
    hasOrder: true,
  },
  {
    Header: "PVP",
    id: 'pvp',
    accessor: (row) => (
      <>{currencyFormat.format(row.pvp)}</>
    )
  },
  {
    Header: "PSL",
    id: 'psl',
    accessor: (row) => (
      <>{currencyFormat.format(row.psl)}</>
    )
  },
  {
    Header: "Bonif. (%)",
    id: 'initial_cost_percent',
    accessor: (row) => (
      <>{row.initial_cost_percent? parseFloat(row.initial_cost_percent).toFixed(5):''}</>
    )
  },
  {
    Header: "C. inicial",
    id: 'initial_cost',
    accessor: (row) => (
      <>{currencyFormat.format(row.initial_cost)}</>
    )
  },
  {
    Header: "C. asoc. (%)",
    id: 'add_cost_percent',
    accessor: (row) => (
      <>
        {row.add_cost_percent?parseFloat(row.add_cost_percent).toFixed(5):''}
      </>
    )
  },
  {
    Header: "C. asoc.",
    id: 'add_cost',
    accessor: (row) => (
      <>
        {currencyFormat.format(row.add_cost)}
      </>
    )
  },
  {
    Header: "SIRCREB",
    id: 'sircreb_amount',
    accessor: (row) => (
      <>
        {currencyFormat.format(row.sircreb_amount)}
      </>
    )
  },
  {
    Header: "Costo total",
    id: 'total_cost',
    accessor: (row) => (
      <>
        {currencyFormat.format(row.total_cost)}
      </>
    )
  },
  // {
  //   Header: intl.formatMessage({ id: 'COLUMN.COSTS.PERIOD' }),
  //   id: 'period',
  //   accessor: (row) => (
  //     <>
  //       {row.manual_price_on
  //         ? (row.date_to === null || row.date_to === '')
  //           ? formatTableDate(row.date_from)
  //           : `${formatTableDate(row.date_from)} - ${formatTableDate(row.date_to)}`
  //         : '-'
  //       }
  //     </>
  //   )
  // },
  // {
  //   Header: intl.formatMessage({ id: 'COLUMN.COSTS.STATUS' }),
  //   id: 'status',
  //   accessor: (row) => (
  //     <span className={`badge badge-light-${getListCostStatusColor(getListCostStatus(row))} fs-8 fw-bolder`}>{getListCostStatus(row)}</span>
  //   )
  // },
]

export {
  COLUMNS
}