import { useState, useCallback, useEffect } from 'react'
import { Formik } from 'formik'
import { Modal, Button, Card } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useDocumentTypes } from 'hooks'

import { InputBS, SelectField } from 'components'

const FileModal = ({ show, onClose, onSave, selectedFile, handleSelectedFile }) => {
  const intl = useIntl()

  const { handleGetDocumentTypes } = useDocumentTypes()
  const [documentTypes, setDocumentTypes] = useState([])

  const getDocumentTypes = useCallback(async () => {
    try {
      let params = {
        entity: 'ClienteProveedor'
      }
      const response = await handleGetDocumentTypes('', params)
      let dt = response.result.map(dt => ({ value: dt.id, label: dt.name }))
      setDocumentTypes(dt)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetDocumentTypes])

  useEffect(() => {
    getDocumentTypes()
  }, [getDocumentTypes])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'MODAL.CUSTOMER_SUPPLIERS.FILES.TITLE' })}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{ file: '', expiration_date: '', }}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <>
                <div className='row mb-8'>
                  <div className='col-6'>
                    <SelectField
                      id='document_type_id'
                      name='document_type_id'
                      label='Tipo de documento'
                      options={documentTypes}
                      placeholder='Seleccione el tipo de documento'
                      required
                    />
                  </div>

                  <div className='col-3'>
                    <InputBS id='expiration_date' name='expiration_date' type='date' label='Fecha de vencimiento' />
                  </div>
                </div>

                <div className='row mb-8'>
                  <div className='col-9'>
                      <div className='row'>
                        <div className='col'>
                          <InputBS
                            id='file'
                            name='file'
                            type='file'
                            label='Documento'
                            onChange={e => {
                              handleSelectedFile(e.currentTarget.files[0])
                            }}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <span style={{ color: '#8d8c8c' }}>{selectedFile?.name}</span>
                        </div>
                      </div>
                    </div>
                </div>

                <Card.Footer className='d-flex flex-row justify-content-end'>
                  <Button variant='primary' onClick={handleSubmit}>Añadir</Button>
                </Card.Footer>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default FileModal