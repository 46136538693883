import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import moment from 'moment';

import { useArticles } from 'hooks'
import { DetailContainer } from 'containers'

import TabProductVar from './partials/TabProductVar'
import ParentProduct from './partials/ParentProduct'
import { createInitialValues, createSchema, vatOptions } from '../constants'

const AddProduct = () => {
  const navigate = useNavigate()
  const { handleCreateArticle, loading } = useArticles()
  const [errorMsg, setErrorMsg] = useState("")
  const intl = useIntl()

  const submitForm = async(values, setFieldTouched)=>{
    /*
    product_id
    name
    vat
    vat_rate
    purchase_discount_base
    purchase_discount_extra
    purchase_add_cost
    purchase_vat
    purchase_vat_rate
    purchase_add_cost_percent
    */

    if (!values.name) {
      setFieldTouched('name', true)
      setErrorMsg("El campo Nombre es obligatorio")
      toast.error("El campo Nombre es obligatorio")
      return
    }
    if (!values.vat || !values.purchase_vat) {
      setFieldTouched('vat', true)
      setFieldTouched('purchase_vat', true)
      setErrorMsg("Los campos IVA compra e IVA venta son obligatorios")
      toast.error("Los campos IVA compra e IVA venta son obligatorios")
      return
    }
    if (!values.gtin || values.gtin == "") {
      setFieldTouched('gtin', true)
      setErrorMsg("El campo GTIN es obligatorio")
      toast.error('El campo GTIN es obligatorio')
      return
    }
    if (!values.unit || values.unit<=0) {
      setFieldTouched('unit', true)
      setErrorMsg("El campo unidades es obligatorio")
      toast.error('El campo unidades es obligatorio')
      return
    }
    if (values.manual_price_on && (!values.manual_price.price || values.manual_price.price == '' || !values.manual_price.date_from || values.manual_price.date_from == '' )) {
      toast.error("Debe cargar un PVP y fecha desde en la solapa 'Comercial' - PVP Manual")
      return
    }

    try {
      const data = {
        name: values.name,
        reg_id: values.reg_id != '' ? values.reg_id : undefined,
        score_line_cancelled: values.score_line_cancelled ? 1 : 0,
        score_line: values.score_line?.toString() != '' ? values.score_line.toString() : undefined,
        vat: values.vat,
        vat_rate: vatOptions.filter(v => v.value == values.vat)[0].percent,
        purchase_vat: values.purchase_vat,
        purchase_vat_rate: vatOptions.filter(v => v.value == values.purchase_vat)[0].percent,
        presentation: values.presentation != '' ? values.presentation : undefined,
        unit: values.unit,
        unit_type_id: values.unit_type_id,
        refrigerated: values.refrigerated ? 1 : 0,
        traced: values.traced ? 1 : 0,
        customer_supplier_id: values.customer_supplier_id,
        notes: values.notes,
        via_id: values.via_id,
        // sale_type_id: values.shape_id?.value,
        control_id: values.control_id,
        snomed: values.snomed,
        action_id: values.action_id,
        imported: values.imported ? 1 : 0,
        size_id: values.size_id,
        pot: values.pot,
        pot_id: values.pot_id,
        form_id: values.form_id,
        // controlled: values.form_id,
        sku: values.sku,
        gtin: values.gtin,
        bar: values.bar != '' ? values.bar : undefined,
        profitablity_min: values.profitablity_min ? parseFloat(values.profitablity_min) : null,
        manual_price:values.manual_price_on
          ?
            {
              ...values.manual_price,
              date_from: values.manual_price.date_from ? moment(values.manual_price.date_from, 'DD/MM/YYYY').format("YYYYMMDD") : undefined
            }
          : undefined,
        manual_list_cost: values.initial_cost_type == 1 // 1: 'Fijo'
          ?
            {
              ...values.manual_list_cost,
              date_from: values.manual_list_cost.date_from ? moment(values.manual_list_cost.date_from).format("YYYYMMDD") : undefined,
              date_to: values.manual_list_cost.date_to ? moment(values.manual_list_cost.date_to).format("YYYYMMDD") : undefined
            }
          : undefined,
        distribution_channels: values.distribution_channel && values.distribution_channel.length > 0 ? values.distribution_channel.map(d => d.value).join(',') : undefined,
        purchase_discount_base: values.parent_product.default_purchase_discount_base || 0,
        // purchase_add_cost: values.purchase_add_cost || 0,
        purchase_discount_extra: values.purchase_discount_extra || 0,
        purchase_add_cost: values.purchase_add_cost_type == 1 ? +values.purchase_add_cost : undefined, // 1: 'Fijo'
        purchase_add_cost_percent: values.purchase_add_cost_type == 1 ? undefined : +values.purchase_add_cost_percent, // 1: 'Fijo'
        // list_cost: values.alc
      }

      const response = await handleCreateArticle({ ...data, product_id: values.parent_product?.id }, 'token');

      toast.success("Artículo creado", { theme: "colored" })

      navigate('/inventario/articulos')
    } catch (error) {
      toast.error(error.message, { theme: "colored" })
      console.log(error)
    } finally {
    }
  }

  return (
    <>
      <DetailContainer title="Crear Artículo">
        <Formik
          initialValues={{...createInitialValues}}
          validationSchema={createSchema}
          // onSubmit={ submitForm }
          enableReinitialize
        >
          {({ errors, values, setFieldTouched, validateForm }) => {
            return (
              <Form className="d-flex flex-column mt-2">
                <ParentProduct />

                <TabProductVar validateForm={validateForm} />

                {Object.values(errors).length > 0 && errorMsg && <label className='text-danger'>{Object.values(errors).join(', ')}</label>}

                <button className="btn btn-primary align-self-end" type="button" disabled={loading} onClick={() => submitForm(values, setFieldTouched)}>
                  <i className={`bi ${loading ? 'bi-arrow-clockwise' : 'bi-save'} me-2`} />
                  {loading ? 'Espere...' : 'Crear'}
                </button>
              </Form>
            )
          }
          }
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddProduct