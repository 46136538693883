import { useState, useEffect, useCallback } from 'react'

import { useIncomeOrder } from '.'
import { useRequest } from 'hooks/Requests';



const useGetIncomeOrderDetail = (id = null, isNew = false) => {
  const [loading, setLoading] = useState(false)  
  const { handleGetIncomeOrder } = useIncomeOrder();
  const {handleGetRequest} = useRequest();
  const [incomeOrder, setIncomeOrder] = useState(null);

  const refreshIncomeOrder = useCallback(async () => {
    try {
      setLoading(true)
      const response = await handleGetIncomeOrder(id);
      const _incomeOrder = response.result[0];

      if(_incomeOrder.in_request_id){
        const _request = await handleGetRequest(_incomeOrder.in_request_id);
        _incomeOrder.in_request = _request?.result[0];
      }

      setIncomeOrder(_incomeOrder);
      
    } catch (error) {
      throw error;
    }
    finally{
      setLoading(false);
    }
  }, [handleGetIncomeOrder, id]);

  const getDetail = async () => {
    if (!isNew) {
      await refreshIncomeOrder();
    }
  }

  useEffect(() => {
    getDetail();
  }, [isNew, refreshIncomeOrder, handleGetIncomeOrder ]);

  return {
    loading,
    incomeOrder,
    refreshIncomeOrder,
    getDetail
  }
};

export default useGetIncomeOrderDetail;