import service from "../service";
import { CarrierI, CreateCarrierI, UpdateCarrierI } from "interfaces";

const PATH = {
  carriers: "/carriers",
};

export class Carriers {
  static getCarriers = async (token: string) => {
    const response = await service.get(PATH.carriers, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static getCarrier = async (id: CarrierI['id'], token: string) => {
    const response = await service.get(`${PATH.carriers}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static createCarrier = async (data: CreateCarrierI, token: string) => {
    const response = await service.post(PATH.carriers, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static updateCarrier = async (id: CarrierI['id'], data: UpdateCarrierI, token: string) => {
    const response = await service.patch(`${PATH.carriers}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }

  static deleteCarrier = async (id: CarrierI['id'], token: string) => {
    const response = await service.delete(`${PATH.carriers}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }
}