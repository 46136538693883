import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: intl.formatMessage({ id: 'COLUMN.CUSTOMER_SUPPLIERS.ID' }),
      id: 'id',
      accessor: (row) => (
        <Tooltip text='Ver'>
          <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.id}`}>
            {row.id}
          </Link>
        </Tooltip>
      ),
    },
    {
      Header: <div className='text-center'>Abrev.</div>,
      id: 'cod_abrev',
      accessor: (row) => (
        <div className='text-center'>
          {row?.cod_abrev || '-'}
        </div>
      )
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.CUSTOMER_SUPPLIERS.BUSINESS_NAME' }),
      accessor: 'business_name',
    },
    {
      Header: <div className='text-center'>Documento</div>,
      id: 'documentation_type_id',
      accessor: (row) => (
        <div className='text-center'>
          <Tooltip text={row?.documentation_type_name}>
            <span>
              {row?.documentation ? row.documentation : <i>Sin documento</i>}
            </span>
          </Tooltip>
        </div>
      )
    },
    {
      Header: <div className='text-center'>Relación comercial</div>,
      id: 'business_relationship',
      accessor: (row) => (
        <ul className='list-style-none m-0 p-0 text-center'>
          {Boolean(row.is_customer) && <li>Cliente</li>}
          {Boolean(row.is_supplier) && <li>Proveedor</li>}
          {Boolean(row.is_distributor) && <li>Distribuidor</li>}
        </ul>
      ),
    },
    {
      Header: <div className='text-center'>Tipo</div>,
      id: 'type_id',
      accessor: (row) => (
        <div className='text-center'>
          {row.type_name}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Gran empresa</div>,
      id: 'is_big_company',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${Boolean(row.is_big_company) ? 'success' : 'danger'}`}>
            {Boolean(row.is_big_company) ? 'Sí' : 'No'}
          </span>
        </div>
      )
    },
    {
      Header: <div className='text-center'>Sistema</div>,
      id: 'company_system',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row.is_big_company) ? row.company_system : '-'}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Fecha de alta</div>,
      id: 'enrollment_date',
      accessor: (row) => (
        <div className='text-center'>
          {moment(row.enrollment_date).format('DD/MM/YYYY')}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Estado</div>,
      id: 'is_enabled',
      accessor: (row) => (
        <div className='text-center'>
          {row.is_enabled || row.is_enabled === 1 ? (
            <span className={ `badge badge-success w-100 justify-content-center fw-normal p-2`}>Habilitado</span>
          ) : (
            <span className={ `badge badge-danger w-100 justify-content-center fw-normal p-2`}>Deshabilitado</span>
          )}
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}