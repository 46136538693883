import { useState, useCallback } from 'react'

import { Provinces } from 'services'

const useProvinces = () => {
  const [loading, setLoading] = useState(false)

  const handleGetProvinces = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Provinces.getProvinces(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetProvinces,
  }
}

export default useProvinces