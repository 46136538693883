import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import { CustomTable, SelectField } from 'components'
import {helix} from 'ldrs'
import { useOutOrders } from 'hooks'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from 'app/modules/auth'
import { RoleI } from 'interfaces'



const AnmatTab = ({ anmatValidate, cancelarTraza, retrazar, loadingTraza }) => {
  const [showAnmatModal, setShowAnmatModal] = useState(false);
  const [showEditLotNumberModal, setShowEditLotNumberModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null)
  const {currentUser} = useAuth();
  const {loading} = useOutOrders();

  
  // const loadAnmatValidate = async (waybill_number) => {
  //   try {
  //     const respAnmat = await handleGetAnmatValidate({ out_order_id:values.id })
  //     setEntities(respAnmat.data.result)

  //   } catch(error){
  //     toast.error(error.message)
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const handleEditRow = (row)=>{
    setCurrentRow(row)
    setShowEditLotNumberModal(true)
  }

  useEffect(() => {
    setCurrentRow(null)
    setShowAnmatModal(false)
    setShowEditLotNumberModal(false)

    // if (waybill_number) {
    //   loadAnmatValidate()
    // }

  }, [])

 
  useEffect(()=>{
    // const { waybill_number } = values
    // if (waybill_number) {
      // loadAnmatValidate()
    // }
  }, [])

  const columns =[
    {
      Header: "Artículo",
      accessor: 'articulo',
    },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Nro. serie</div>),
      id: 'serie',
      accessor: (row) =>(
        <div className="text-center">
          {row.serie}
        </div>
      ) 
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>GTIN</div>),
      id: 'gtin',
      accessor: (row) => (
        <div className='text-center'>{row.gtin}</div>
      )
    },
    // {
    //   Header: () => (<div style={{textAlign:"center"}}>GTIN</div>),
    //   id: 'gtin',
    //   accessor: (row) => (
    //     <div className='text-center'>{row.gtin}</div>
    //   )
    // },
    {
      Header: () => (<div style={{textAlign:"center"}}>T. Ingreso</div>),
      id: 'transac_ingreso',
      accessor: (row) => (
        <div className='text-center'>{row.transac_ingreso}</div>
      )
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>T.Egreso</div>),
      id: 'transac_egreso',
      accessor: (row) => (
        <div className='text-center'>{row.transac_egreso}</div>
      )
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>Fc. Ingreso</div>),
      id: 'factura_ingreso',
      accessor: (row) => (
        <div className='text-center'>{row.factura_ingreso}</div>
      )
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>Rem. Ingreso</div>),
      id: 'remito_ingreso',
      accessor: (row) => (
        <div className='text-center'>{row.remito_ingreso}</div>
      )
    },
    // {
    //   Header:  () => (<div style={{textAlign:"center"}}>Lote</div>),
    //   id:"lot_number",
    //   accessor: (row) =>(
    //     <div className="text-center">
    //       {row.lot_number}
    //     </div>
    //   ) 
    // },
    // {
    //   Header:  () => (<div style={{textAlign:"center"}}>Inf. prov.</div>),
    //   id:"informado_proveedor",
    //   accessor: (row) =>(
    //     row?.anmat_traced_at &&
    //       <>
    //         <div className={ `bi bi-check-lg text-success text-center`} style={{fontSize:"1.5rem"}}/>
    //       </>
    //   ) 
    // },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Trazado</div>),
      id:"traced_status",
      accessor: (row) => {
        if(row){
              if(row.anmat_traced_error){
                return <>
                  <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>{row.anmat_traced_error.split("GTIN")[0].trim()}</Tooltip>}>
                    <div className={ `bi bi-slash-circle text-danger text-center`} style={{fontSize:"1.5rem"}} />
                  </OverlayTrigger>
                </>
              }
              if(row.anmat_transaction_id){
                return <>
                  <div className={ `bi bi-check-lg text-success text-center`} style={{fontSize:"1.5rem"}} />
                </>
              }
        }
      }
    },
    // {
    //   Header:  () => (<div style={{textAlign:"center"}}>En stock</div>),
    //   id:"stock_id",
    //   accessor: (row) =>(
    //     row?.stock_id &&
    //       <>
    //         <div className={ `bi bi-check-lg text-success text-center`} style={{fontSize:"1.5rem"}} />
    //       </>
    //   ) 
    // },
    // {
    //   Header: '',
    //   id: 'actions',
    //   className:'col-icons',
    //   accessor: (row) => (
    //     row?.anmat_traced_error && row?.anmat_traced_error != '' &&
    //       <>
    //         <div className={ `bi bi-check fs-2 text-success text-center`} />
    //       </>
        
    //   )
    // }
  ]


  useEffect(()=>{
    helix.register()
  }, [])


  return (
    <>

          <>
            <div className="row">
              <div className="col">
                <table>
                  <tbody>
                      <tr>
                        <td style={{width:"100px"}}>Fecha:</td>
                        <td><b>{anmatValidate.fecha}</b></td>
                      </tr>
                      <tr>
                        <td style={{width:"100px"}}>Remito:</td>
                        <td><b>{anmatValidate.remito}</b></td>
                      </tr>
                      <tr>
                        <td style={{width:"100px"}}>Evento:</td>
                        <td><b>{anmatValidate.evento}</b></td>
                      </tr>
                      <tr>
                        <td style={{width:"100px"}}>GLN origen:</td>
                        <td><b>{anmatValidate.glnOrigen}</b></td>
                      </tr>
                      <tr>
                        <td style={{width:"100px"}}>GLN destino:</td>
                        <td><b>{anmatValidate.glnDestino}</b></td>
                      </tr>                    
                  </tbody>
                </table>
              </div>
            </div>
            
            <div className="row">
              { anmatValidate?.items?
                <div className="col text-end">
                  <Button variant='secondary' style={{width:"130px"}} className='me-3' onClick={cancelarTraza} disabled={loadingTraza}>Cancelar traza</Button>
                  <Button variant='primary' style={{width:"130px"}} onClick={retrazar} disabled={loadingTraza}>Retrazar</Button>
                </div>
                :
                <></>
              }
            </div>
            {loadingTraza && 
                <div className='text-center'>
                  <div className='spinner-border text-success' />
                </div>
            }

            <CustomTable columns={columns} data={anmatValidate?.items ||[]} />
          </>
  </>
  )
}

export default AnmatTab