import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { InputBS, SelectField } from 'components'
import { currencyFormat } from 'utilities'

import { vatOptions } from '../constants'

const initialValues = {
  id: '',
  line: '',
  product_var_desc: '',
  actual_pvp: 0,
  list_cost: 0,
  qty: 0,
  units: 0,
  discount: 0,
  vat: 1,
  vat_rate: 0,
  price: 0,
  profitability: 0,
  profitability_rate: 0,
  profitability_total: 0,
  total: 0,
}
const inputStateInitialValues = {
  qty: '',
  discount: '',
  vat: '',
  vat_rate: '',
}
const displayValuesInitialValues = {
  pvp: '',
  list_cost: '',
  price: '',
  profitability: '',
  total: '',
}

const ArticleItemModal = ({ show, onHide, detail, handleUpdate }) => {
  const [values, setValues] = useState(initialValues)
  const [inputState, setInputState] = useState(inputStateInitialValues);
  const [displayValues, setDisplayValues] = useState(displayValuesInitialValues)

  const updateBudgetDetail = async (values) => {
    if (detail === null) return toast.error('No se ha seleccionado un artículo')

    try {
      const data = {
        ...values,
        subtotal: values?.price,
      }

      handleUpdate(detail?.id, data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleSetDisplayValues = useCallback((values) => {
    setDisplayValues((prevState) => ({
      ...prevState,
      pvp: currencyFormat.format(Number(Number(values?.actual_pvp).toFixed(2))),
      list_cost: currencyFormat.format(Number(Number(values?.list_cost).toFixed(2))),
      price: currencyFormat.format(Number(Number(values?.price).toFixed(2))),
      profitability: `${Number(values?.profitability_rate).toFixed(5)} %`,
      total: currencyFormat.format(Number(Number(values?.total).toFixed(2))),
    }))
  }, [])

  useEffect(() => {
    if (detail !== null) {
      setValues(detail)

      setInputState({
        qty: detail.qty,
        discount: detail.discount,
        vat: detail.vat,
        vat_rate: detail.vat_rate,
      })

      handleSetDisplayValues({
        pvp: detail.pvp,
        list_cost: detail.list_cost,
        price: detail.price,
        profitability: detail.profitability_rate,
        total: detail.total,
      })
    }
  }, [detail, handleSetDisplayValues])

  useEffect(() => {
    const { qty, discount, vat: vatId, vat_rate } = inputState

    const vat = Number(values.actual_pvp) * Number(vat_rate) / 100;
    const price_with_discount = Number(values.actual_pvp) - (Number(values.actual_pvp) * Number(discount) / 100);
    const price = Number(price_with_discount) + Number(vat);
    const profitability = Number(price) - Number(values.list_cost);
    const profitability_rate = (1 - (Number(values.list_cost) / +Number(values.price))) * 100;

    const total = +price * Number(qty);
    const profitability_total = Number(profitability) * Number(qty);

    setValues((prevState) => ({
      ...prevState,
      qty,
      discount,
      vat: vatId,
      vat_rate,
      price,
      profitability,
      profitability_rate,
      profitability_total,
      total,
    }))

    handleSetDisplayValues({
      ...values,
      price,
      profitability,
      profitability_rate,
      profitability_total,
      total,
    })
  }, [values, inputState, setValues, handleSetDisplayValues]);

  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Detalle de pedido #{detail?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <Formik
          initialValues={values}
          onSubmit={updateBudgetDetail}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-1'>
                    <InputBS id='line' name='line' label='Línea' />
                  </div>

                  <div className='col-7'>
                    <InputBS id='product_var_desc' name='product_var_desc' label='Artículo' />
                  </div>

                  <div className='col-2'>
                    <InputBS id='pvp' name='pvp' value={displayValues.pvp} label='PVP' className='text-end' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='list_cost' name='list_cost' value={displayValues.list_cost} label='Costo de lista' className='text-end' disabled />
                  </div>

                </div>

                <div className='row my-8'>
                  <div className='col-1'>
                    <InputBS
                      id='qty'
                      name='qty'
                      type='number'
                      label='Cajas'
                      value={inputState.qty}
                      onChange={e => {
                        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
                        setInputState((prevState) => ({
                          ...prevState,
                          qty: value,
                        }));
                      }}
                    />
                  </div>

                  <div className='col-1'>
                    <InputBS id='quantity' name='quantity' value={values?.qty * detail?.units} type='number' label='Unidades' disabled />
                  </div>

                  {/* <div className='col-2'>
                    <InputBS
                      id='discount'
                      name='discount'
                      value={inputState.discount}
                      onChange={(e) => {
                        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
                        setInputState((prevState) => ({
                          ...prevState,
                          discount: value,
                        }));
                      }}
                      label='Descuento'
                    />
                  </div> */}

                  <div className='col-2'>
                    <SelectField
                      id='vat'
                      name='vat'
                      options={vatOptions.map(v => ({ value: v.value, label: v.label }))}
                      label='IVA'
                      disabled
                    />
                  </div>

                  {/* <div className='col-2'>
                    <InputBS
                      id='vat_rate'
                      name='vat_rate'
                      value={inputState.vat_rate}
                      onChange={(e) => {
                        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
                        setInputState((prevState) => ({
                          ...prevState,
                          vat_rate: value,
                        }));
                      }}
                      label='IVA'
                      disabled
                    />
                  </div> */}

                  <div className='col'></div>

                  {/* <div className='col-2'>
                    <InputBS id='price' name='price' value={displayValues.price} label='Precio unitario' className='text-end' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS id='profitability_rate' name='profitability_rate' value={displayValues.profitability} label='% Rentabilidad' className='text-end' disabled />
                  </div> */}
                </div>

                <div className='row my-8'>
                  <div className='col-8'></div>

                  {/* <div className='col-4'>
                    <InputBS id='total' name='total' value={displayValues.total} label='Total' className='text-end' disabled />
                  </div> */}
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex align-items-center justify-content-end'>
                  <Button variant='secondary' type='button' onClick={onHide} className='me-3'><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='submit'><i className='bi bi-save me-2' />Actualizar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ArticleItemModal