import * as Yup from 'yup'

const addAddressInitialValues = {
  address: {
    manual: false,
    place_id: '',
    route: '',
    street_number: '',
    floor: '',
    sublocality_level_1: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    locality: '',
    postal_code: '',
    postal_code_suffix: '',
    country: '',
    latitude: 0,
    longitude: 0,
    google_result: '',
    origin: '',
    formatted_address: '',
  }
}

const addAddressSchema = Yup.object().shape({
  name: Yup.string(),
  direction: Yup.object().shape({
    manual: Yup.boolean().default(false),
    place_id: Yup.string().default(''),
    route: Yup.string().default(''),
    street_number: Yup.string().default(''),
    floor: Yup.string().default(''),
    sublocality_level_1: Yup.string().default(''),
    administrative_area_level_1: Yup.string().default(''),
    administrative_area_level_2: Yup.string().default(''),
    locality: Yup.string().default(''),
    postal_code: Yup.string().default(''),
    postal_code_suffix: Yup.string().default(''),
    country: Yup.string().default(''),
    latitude: Yup.number().default(0),
    longitude: Yup.number().default(0),
    google_result: Yup.string().default(''),
    origin: Yup.string().default(''),
    formatted_address: Yup.string().default(''),
  }),
})

export {
  addAddressInitialValues,
  addAddressSchema
}