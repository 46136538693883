import { useState, useCallback } from 'react'

import { DispatchOrders } from 'services'

const useDispatchOrders = () => {
  const [loading, setLoading] = useState(false)

  const handleGetDispatchOrders = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await DispatchOrders.get(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDispatchOrder = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await DispatchOrders.getOne(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDispatchOrderDetail = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await DispatchOrders.getDetail(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetDispatchOrders,
    handleGetDispatchOrder,
    handleGetDispatchOrderDetail,
  }
}

export default useDispatchOrders