import { useState, useMemo, useRef, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useLogisticStock, useClickOutside } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import useGetColumns from './columns'
import SerialListModal from './SerialListModal'
import UpdateLotModal from './UpdateLotModal'

const articlesGroupBy = (data) => {
  const groupedData = {}

  data.forEach((item) => {
    const key = `${item.gtin}_${item.lot_number}_${item.storage_space_name}`;

    if (!groupedData[key]) {
      groupedData[key] = {
        ...item,
        stock_available_without_reserved: item.stock_available_without_reserved,
        stock_not_available: item.storage_space_inventory_status_id!=1?1:0
      }
    } else {
      if (item.storage_space_inventory_status_id != 1) {
        groupedData[key].stock_not_available++;
      } else {
        groupedData[key].stock_available_without_reserved += item.stock_available_without_reserved;
      }
    }
  })

  const result = Object.values(groupedData);

  return result;
};

const LogisticStockDetail = () => {
  const { id } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()

  const { product_var_id, business_unit_id, customer_supplier_id, customer_suppliers_name, business_unit_name, stock_reserved, stock_available, stock_not_available } = state || {};

  const { handleGetStockDetail, handleGetStockList, loading } = useLogisticStock()

  const [data, setData] = useState([])
  const [stockDetail, setStockDetail] = useState([])

  const [showSerialNumbers, setShowSerialNumbers] = useState(false)
  const [serialNumbers, setSerialNumbers] = useState([])

  const { COLUMNS } = useGetColumns()

  const [modal, setModal] = useState(false)

  const getStockDetail = async () => {
    try {
      if (id) {
          if (!business_unit_id || !customer_supplier_id) {
            navigate('/inventario/stock')
            toast.error("Faltan datos de dueño o unidad de negocio")
          }
          const response = await handleGetStockDetail({ product_var_id, business_unit_id, customer_supplier_id })
          if (response.result) {
            const _data = response.result;
            const agrupados = articlesGroupBy(_data)

            setStockDetail(_data)
            setData(agrupados);
            // setTotalCount(response.metadata.count);
          }
      }
    } catch (error) {
      setData([])
      console.log(error)
    }
  }

  const handleSerialNumbers = (row)=>{
    const series = stockDetail.filter(s=>s.lot_number==row.lot_number && s.stock_available_without_reserved>0)
    setSerialNumbers(series)
    setShowSerialNumbers(true)
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
};

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (Boolean(currentRow?.traced) && currentRow?.stock_available_without_reserved > 0) {
      actions.push({
        label: 'Ver nros. de serie',
        icon: 'bi bi-eye text-primary',
        action: () => handleSerialNumbers(currentRow),
      })
    }

    actions.push({
      label: "Modificar lote",
      icon: "bi bi-boxes text-primary",
      // action: actionModificarLote
      action: () => {
        setModal(true)
      }
    })

    return actions
  }

  // const actionModificarLote = async () => {
  //   if (currentRow) {
  //     try {
        
  //     } catch (error) {
  //       toast.error(error.message)
  //     }
  //   }
  // }
  /* Fin Menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      )
    }
  ], [COLUMNS])

  useEffect(() => {
    setShowSerialNumbers(false);
    getStockDetail()

    helix.register()
  }, [id])

  return (
    <>
      <SerialListModal show={showSerialNumbers} onHide={() => setShowSerialNumbers(false)} data={stockDetail} serialNumbers={serialNumbers} />

      <DetailContainer
        title={`${data[0]?.name || ''}`}
        buttons={
          <div className='align-self-center'>
            <Button variant="secondary" onClick={() => navigate(-1)}>
              <i className="ki-duotone ki-left" />
              Volver
            </Button>
          </div>
        }
      >
        <>
          <div className='mt-10 mb-10'>
            <Form.Label className='d-block'>Unidad de negocio: <b>{business_unit_name}</b></Form.Label>
            <Form.Label className='d-block'>Dueño: <b>{customer_suppliers_name}</b></Form.Label>
            <Form.Label className='d-block'>Disponible: <b>{stock_available}</b></Form.Label>
            <Form.Label className='d-block'>Reservado: <b>{stock_reserved}</b></Form.Label>
            <Form.Label className='d-block'>No disponible: <b>{stock_not_available}</b></Form.Label>
          </div>

          {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop:"100px" }}></l-helix>}

          <TableContainer>
            <CustomTable columns={columns} data={data} />
          </TableContainer>

          <ContextMenu
            ref={contextRef}
            showContextMenu={showContextMenu}
            setShowContextMenu={setShowContextMenu}
            actions={availableActions()}
            currentPos={currentPos}
          />

          <UpdateLotModal
            show={modal}
            onClose={() => setModal(false)}
            detail={{
              product_var_id: id,
              lot_number: currentRow?.lot_number,
              expiration_date:  currentRow?.expiration_date
            }}
            handleGetStockDetail={getStockDetail}
          />
        </>
      </DetailContainer>

    </>
  )
}

export default LogisticStockDetail