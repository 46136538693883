import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useDocumentationTypes, useTypes, useVatConditions, usePaymentConditions } from 'hooks'

import { AddressModal } from 'containers/Modals'
import { Label, InputBS, SelectField, TextareaBS, Checkbox, FormTable } from 'components'
import { Button } from 'react-bootstrap'

const GeneralTab = () => {
  const intl = useIntl()
  const { values, errors, setFieldValue } = useFormikContext()

  const [modal, setModal] = useState(false)

  const { handleGetDocumentationTypes } = useDocumentationTypes()
  const { handleGetVatConditions } = useVatConditions()
  const { handleGetTypes } = useTypes()
  const { handleGetPaymentConditions } = usePaymentConditions()

  const [documentationTypes, setDocumentationTypes] = useState([])
  const [vatConditions, setVatConditons] = useState([])
  const [types, setTypes] = useState([])
  const [paymentConditions, setPaymentConditions] = useState([])

  const getDocumentationTypes = useCallback(async () => {
    try {
      const response = await handleGetDocumentationTypes('token')
      setDocumentationTypes((response.result).map(item => { return { value: item.id, label: item.type }}))
    } catch (error) {
      setDocumentationTypes([])
      toast.error(error.message)
    }
  }, [handleGetDocumentationTypes])

  const getVatConditions = useCallback(async () => {
    try {
      const response = await handleGetVatConditions()
      setVatConditons((response.data.result).map(item => { return { value: item.id, label: item.label }}))
    } catch (error) {
      setVatConditons([])
      toast.error(error.message)
    }
  }, [handleGetVatConditions])

  const getTypes = useCallback(async () => {
    try {
      const response = await handleGetTypes('token')
      setTypes((response.result).map(item => { return { value: item.id, label: item.type }}))
    } catch (error) {
      setTypes([])
      toast.error(error.message)
    }
  }, [handleGetTypes])

  const getPaymentConditions = useCallback(async () => {
    try {
      let params = {
        is_enabled: 1,
      }
      const response = await handleGetPaymentConditions(params)
      setPaymentConditions((response.result).map(item => { return { value: item.id, label: item.label }}))
    } catch (error) {
      setPaymentConditions([])
      toast.error(error.message)
    }
  }, [handleGetPaymentConditions])

  useEffect(() => {
    getDocumentationTypes()
    getVatConditions()
    getTypes()
    getPaymentConditions()
  }, [getDocumentationTypes, getVatConditions, getTypes, getPaymentConditions])

  return (
    <>
      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='cod_abrev' name='cod_abrev' label='Abrev.' />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='is_customer' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.BUSINESS_RELATIONSHIP' })} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_customer' />
              <Label htmlFor='is_customer' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.BUSINESS_RELATIONSHIP.CUSTOMER' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_supplier' />
              <Label htmlFor='is_supplier' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.BUSINESS_RELATIONSHIP.SUPPLIER' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_distributor' />
              <Label htmlFor='is_distributor' label={'Distribuidor'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        <div className='col'>
          <InputBS id='business_name' name='business_name' label={intl.formatMessage({ id: 'COLUMN.CUSTOMER_SUPPLIERS.BUSINESS_NAME' })} />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <SelectField
            id='documentation_type_id'
            name='documentation_type_id'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.DOCUMENT_TYPE' })}
            options={documentationTypes}
            placeholder='Seleccione un tipo'
          />
          {/* {errors.documentation_type_id && <div className='text-danger'>{errors.documentation_type_id}</div>} */}
        </div>

        <div className='col-3'>
          <InputBS id='documentation' name='documentation' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.DOCUMENT' })} maxLength='11' />
        </div>

        <div className='col-3'>
          <SelectField
            id='vat_condition_id'
            name='vat_condition_id'
            label='Condición frente al IVA'
            options={vatConditions}
            placeholder='Seleccione una condición'
          />
        </div>

        <div className='col-3'>
          <SelectField
            id='type_id'
            name='type_id'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.TYPE' })}
            placeholder='Seleccione un tipo'
            options={types}
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='is_big_company' label={'Gran empresa'} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_big_company' />
              <Label htmlFor='is_big_company' label={'Gran empresa'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        {Boolean(values.is_big_company) &&
          <div className='col-6'>
            <SelectField
              id='company_system'
              name='company_system'
              label='Sistema'
              options={[
                { value: 'SCA', label: 'SCA: Sistemas de Circulación Abierta' },
                { value: 'ADC', label: 'ADC: Agentes de Depósito Colectivo o similares' },
              ]}
              placeholder='Seleccione un sistema'
            />
          </div>
        }

        <div className='col-3'>
          <SelectField
            id='payment_condition_id'
            name='payment_condition_id'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.PAYMENT_CONDITION' })}
            options={paymentConditions}
            placeholder='Seleccione una condición'
          />
        </div>

        {/* <div className={`col-3 ${Boolean(values.is_big_company) ? 'mt-8' : ''}`}>
          <SelectField
            id='billing_type_id'
            name='billing_type_id'
            label={'Facturación'}
            options={billingTypes}
            placeholder='Seleccione una facturación'
          />
        </div>

        <div className={`col-3 ${Boolean(values.is_big_company) ? 'mt-8' : ''}`}>
          <SelectField
            id='price_update_id'
            name='price_update_id'
            label={'Actualización de precio'}
            options={priceUpdates}
            placeholder='Seleccione una opción'
          />
        </div> */}
      </div>

      <div className='separator my-8' />
      <div className='mb-8 d-flex flex-row justify-content-between align-items-center'>
        <h4 className='m-0'>Dirección comercial</h4>
        <Button className='BSButton' variant='outline-primary' onClick={() => setModal(true)}>Añadir dirección</Button>
      </div>

      <AddressModal
        show={modal}
        onClose={() => setModal(false)}
        values={values}
        onSave={(data) => {
          let address = {
            ...data.address,
            origin: data.address.manual ? 'MANUAL' : 'GOOGLE',
          }
          setFieldValue('address', address)
          setModal(false)
        }}
      />

      {values?.address?.route &&
        <FormTable
          columns={[
            { header: 'Calle' },
            { header: 'CP' },
            { header: values.address.custom_address_level1_label },
            { header: values.address.custom_address_level2_label },
            { header: 'País' },
            { header: '', styles: 'text-center' },
          ]}
          responsive={false}
        >
          <tr>
            <td className='align-middle'>{values.address.route} {values.address.street_number}</td>
            <td className='align-middle'>{values.address.postal_code}{values.address.postal_code_suffix}</td>
            <td className='align-middle'>{values.address.custom_address_level1}</td>
            <td className='align-middle'>{values.address.custom_address_level2}</td>
            <td className='align-middle'>{values.address.country}</td>
            <td className='align-middle text-center'>
              <button type='button' className='btn btn-icon' onClick={() => setModal(true)}>
                <i className='fas fa-pencil text-primary'></i>
              </button>
            </td>
          </tr>
        </FormTable>
      }
      <div className='separator my-8' />
      <h4 className='m-0'>Datos de contacto</h4>

      <div className='row my-8'>
        <div className='col-4'>
          <InputBS id='email_notifications' name='email_notifications' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.EMAIL' })} />
        </div>

        <div className='col-2'>
          <InputBS id='number_notifications_suffix' name='number_notifications_suffix' label='Sufijo' />
        </div>

        <div className='col-4'>
          <InputBS id='number_notifications' name='number_notifications' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.WHATS_APP' })} />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-12'>
          <TextareaBS id='observations' name='observations' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.OBSERVATIONS' })} />
        </div>
      </div>
    </>
  )
}

export default GeneralTab