import { useState } from 'react'
import { Formik } from 'formik'
import { Button, Card, FormLabel } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useShipmentAddresses } from 'hooks'

import { Modal, FormTable, InputBS } from 'components'

import { initialValues } from './constants'

const LinkShipmentAddressModal = ({ show, onClose, values = {}, onSave, submodal = false }) => {
  const intl = useIntl()

  const { loading, handleSearchAddress } = useShipmentAddresses()
  const [options, setOptions] = useState([])

  const handleSearch = async (query) => {
    let queryMD = {
      text: query,
      has_traceability: 1,
      is_enabled: 1,
    }

    try {
      const response = await handleSearchAddress({ queryMD })

      if (response.data) {
        setOptions(response.data.result)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  if (!show) return null

  return (
    <Modal title='Relacionar dirección de entrega existente' onClose={onClose} submodal={submodal}>
      <Formik
        initialValues={initialValues}
        // validationSchema={{}}
        onSubmit={values => onSave(values.id)}
        enableReinitialize
      >
        {({ values, errors, setFieldValue, handleSubmit }) => {
          return (
            <>
              <div className='row my-8'>
                <div className='col-12'>
                  <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>Dirección de entrega</FormLabel>
                  <AsyncTypeahead
                    id='buscadorLinkAlfabeta'
                    isLoading={loading}
                    minLength={3}
                    labelKey={(option) => {
                      let address = `${option.name} - ${option.address?.domicilio?.trim()}, ${option.address?.localidad_completa?.trim()}`
                      let agent_gln = `${option?.agent_type_id ? ` | ${option.agent_type_name}` : ''}${option?.gln ? ` | GLN: ${option.gln}` : ''}`

                      return `${address}${agent_gln}`
                    }}
                    onSearch={handleSearch}
                    options={options}
                    filterBy={() => ['name', 'agent_type_name', 'gln', 'address.domicilio', 'address.localidad']}
                    promptText='Escribe para buscar una dirección'
                    searchText='Buscando...'
                    placeholder='Escribe para buscar un dirección'
                    onChange={e => {
                      if (e.length > 0) {
                        setFieldValue('id', e[0].id)
                        setFieldValue('name', e[0].name)
                        setFieldValue('address_id', e[0].address_id)
                        setFieldValue('address', { ...initialValues.address, ...e[0].address })
                        setFieldValue('contact_id', e[0]?.contact_id ? e[0].contact_id : '')
                        setFieldValue('contact', { ...initialValues.contact, ...e[0].contact })
                        setFieldValue('observations', e[0].observations)
                        setFieldValue('has_traceability', Boolean(e[0].has_traceability))
                        setFieldValue('agent_type_id', e[0].agent_type_id)
                        setFieldValue('agent_type_name', e[0].agent_type_name)
                        setFieldValue('gln', e[0].gln)
                        setFieldValue('verifarma', Boolean(e[0].verifarma))
                        setFieldValue('is_ours', Boolean(e[0].is_ours))
                        setFieldValue('is_enabled', Boolean(e[0].is_enabled))
                        return
                      }

                      setFieldValue('id', '')
                      setFieldValue('name', '')
                      setFieldValue('address', initialValues.address)
                      setFieldValue('contact', initialValues.contact)
                      setFieldValue('observations', '')
                      setFieldValue('has_traceability', true)
                      setFieldValue('agent_type_id', '')
                      setFieldValue('agent_type_name', '')
                      setFieldValue('gln', '')
                      setFieldValue('verifarma', false)
                      setFieldValue('is_ours', false)
                      setFieldValue('is_enabled', true)
                    }}
                  />
                </div>
              </div>

              {values.id !== '' &&
                <div className='row my-8'>
                  <div className='col-8'>
                    <InputBS id='name' name='name' label='Nombre' disabled/>
                  </div>
                </div>
              }

              {values.address_id !== '' &&
                <>
                  <div className='separator my-8'></div>
                  <h2>Dirección</h2>

                  <FormTable
                    columns={[
                      { header: 'Domicilio' },
                      { header: 'Calle' },
                      { header: 'Nro.' },
                      { header: 'Piso' },
                      { header: 'CP' },
                      { header: 'Localidad' },
                      { header: '' },
                    ]}
                  >
                    <tr>
                      <td>{values?.address?.domicilio?.trim() || '-'}</td>
                      <td className='align-middle'>{values.address?.route || '-'}</td>
                      <td className='align-middle'>{values.address?.street_number || '-'}</td>
                      <td className='align-middle'>{values.address?.floor ? values.address.floor : 'N/A'}</td>
                      <td className='align-middle'>{values.address?.postal_code_suffix ? `${values.address?.postal_code_suffix} ` : ''}{values.address?.postal_code || ''}</td>
                      <td>{values?.address?.localidad_completa?.trim() || '-'}</td>
                      <td className='align-middle'>{values.address?.custom_address_level1?.trim() ? `${values.address?.custom_address_level1}, ` : ''}{values.address?.custom_address_level2?.trim() ? `${values.address?.custom_address_level2}, ` : ''}{values.address?.country || ''}</td>
                    </tr>
                  </FormTable>
                </>
              }

              {values.id !== '' &&
                <>
                  <div className='separator my-8'></div>
                  <h2>Trazabilidad</h2>
                  <FormTable
                    columns={[
                      { header: 'Trazabilidad' },
                      { header: 'Tipo de agente' },
                      { header: 'GLN' },
                      { header: 'Verifarma' },
                      { header: 'Nuestra' },
                    ]}
                  >
                    <tr>
                      <td className='align-middle'>
                        {Boolean(values.has_traceability)
                          ? <span className="badge badge-success">{intl.formatMessage({ id: 'OPTION.YES' })}</span>
                          : <span className="badge badge-danger">{intl.formatMessage({ id: 'OPTION.NO' })}</span>
                        }
                      </td>
                      <td className='align-middle'>{values?.agent_type_id ? values.agent_type_name : '-'}</td>
                      <td className='align-middle'>{values?.gln ? values.gln : '-'}</td>
                      <td className='align-middle'>
                        {Boolean(values.verifarma)
                          ? <span className="badge badge-success">{intl.formatMessage({ id: 'OPTION.YES' })}</span>
                          : <span className="badge badge-danger">{intl.formatMessage({ id: 'OPTION.NO' })}</span>
                        }
                      </td>
                      <td className='align-middle'>
                        {Boolean(values.is_ours)
                          ? <span className="badge badge-success">{intl.formatMessage({ id: 'OPTION.YES' })}</span>
                          : <span className="badge badge-danger">{intl.formatMessage({ id: 'OPTION.NO' })}</span>
                        }
                      </td>
                    </tr>
                  </FormTable>
                </>
              }

              {values.contact_id !== '' &&
                <>
                  <div className='separator my-8'></div>
                  <h2>Contacto</h2>

                  <FormTable
                    columns={[
                      { header: 'Nombre' },
                      { header: 'Email' },
                      { header: 'Celular' },
                      { header: 'Teléfono' },
                    ]}
                  >
                    <tr>
                      <td className='align-middle'>{values.contact.name}</td>
                      <td className='align-middle'>{values.contact?.email ? values.contact.email : '-'}</td>
                      <td className='align-middle'>
                        {values.contact?.mobile_suffix ? `${values.contact.mobile_suffix} ` : ''}{values.contact?.mobile ? values.contact.mobile : '-'}
                      </td>
                      <td className='align-middle'>{values.contact?.phone ? values.contact.phone : '-'}</td>
                    </tr>
                  </FormTable>
                </>
              }

              <Card.Footer className='d-flex align-items-center justify-content-end mt-8'>
                <Button type='button' variant='secondary' className='me-3' onClick={onClose}><i className="bi bi-slash-circle" />Cancelar</Button>
                <Button type='submit' variant='primary' onClick={handleSubmit}><i className='bi bi-save me-2' />Guardar</Button>
              </Card.Footer>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default LinkShipmentAddressModal