import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useCustomerSuppliers } from 'hooks'

import { Input, SelectField } from 'components'
import { Button } from 'react-bootstrap'

const initialValues = {
  product_var_id: '',
  text: '',
  is_active: '',
}

const Filter = ({ handleData, handleTotalCount, query, handleQuery }) => {
  const { id } = useParams()
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetDiscounts } = useCustomerSuppliers()

  const filterData = (values) => {
    let params = {
      ...query,
      ...values,
      product_var_id: values?.product_var_id || undefined,
      text: values?.text || undefined,
      is_active: values?.is_active || undefined,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: 10,
      }
      const response = await handleGetDiscounts(id, params)

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        clientes_descuentos: { ...params }
      }))

      handleQuery({ ...params })
      handleData(response.data.result)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { clientes_descuentos } = currentUser

      setInitialFormikValues({
        ...initialValues,
        product_var_id: clientes_descuentos?.product_var_id || '',
        text: clientes_descuentos?.text || '',
        is_active: clientes_descuentos?.is_active || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              submitForm(); // Realizar la acción del filtro aquí
            }
          }}
        >
          <div className='row'>
            <div className='col-2'>
              <Input id='product_var_id' name='product_var_id' disabled={loading} />
              <p className='text-muted'>ID Artículo</p>
            </div>

            <div className='col-8'>
              <Input id='text' name='text' disabled={loading} />
              <p className='text-muted'>Nombre</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='is_active'
                name='is_active'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Sí' },
                  { value: '0', label: 'No' },
                ]}
                placeholder='Seleccione'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Activo</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                className='me-3'
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter