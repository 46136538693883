
import React from 'react'
import { useFormikContext } from 'formik'
import { InputBS, SelectField } from 'components'

import { useAlfabeta } from 'hooks'

const MedicamentoForm = () => {
  const { setFieldValue, values } = useFormikContext()
  const {
    actions,
    vias,
    shapes,
    sizes,
    potencies,
    controls
  } = useAlfabeta()

  console.log(values)

  return (
    <div style={{ flex: '2', marginRight:"10px" }}>
      <div className="row">
        <div className='col'>
          <SelectField label={'Tamaño'} name="size_id" options={sizes.map(f=>({ label: f.description, value: f.id }))} marginTop='3px' />
        </div>

        <div className="col">
          <SelectField label='Acción terapéutica' name="action_id" options={actions.map(f=>({label:f.description, value:f.id}))} marginTop='3px' />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-3">
          <InputBS  id='pot' name='pot' label="Potencia" />
        </div>

        <div className='col-3'>
          <SelectField label={'Unidad de potencia'} name="pot_id" options={potencies.map(f => ({ label: f.description, value: f.id }))} marginTop='3px' />
        </div>

        <div className='col-6'>
          <SelectField label={'Control'} name="control_id" options={controls.map(f => ({ label: f.description, value: f.id}))} marginTop='3px' />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <SelectField label='Forma Farmacéutica' name="form_id" options={shapes.map(f=>({label:f.description, value:f.id}))} marginTop='3px' />
        </div>

        <div className="col">
          <SelectField label='Vía de administración' name="via_id" options={vias.map(f=>({label:f.description, value:f.id}))} marginTop='3px' />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <InputBS id='bar' name='bar' label="Cód. Barras" maxLength={13} />
        </div>

        <div className="col">
          <InputBS id='score_line' name='score_line' label="Troquel" />
        </div>

        <div className="col"></div>
        <div className="col"></div>
      </div>
    </div>
  )
}


export default MedicamentoForm