import { useNotifications } from 'hooks';
import { NotificationsList } from './NotificationsList';
import './styles.css';
import { useEffect, useState } from 'react';

const btnClass ='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'

const ENV = process.env.REACT_APP_ENV

const Notifications = (props) => {
    const [notificationCount, setNotificationCount] = useState(0);
    const [animateBell, setAnimateBell] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const {messageReceived, notificationEvents, handleGetUnreadedNotifications, handleGetNotificationsAndClean} = useNotifications();

    const fetchUnreaded = async()=>{
      const data = await handleGetUnreadedNotifications();
      if(data && data.result){
        setNotificationCount(data.result.length)
        if(data.result.length){
          setAnimateBell(true)
        }
      }
    }

    const fetchAndClean = async()=>{
      const data = await handleGetNotificationsAndClean();
      if(data && data.result){
        setNotifications(data.result)
        setNotificationCount(0);
      }
    }

    const handleNotification = () => {

      setNotificationCount(notificationCount + 1);
      setAnimateBell(true);
  
      // Agrega un temporizador para detener la animación después de un tiempo
      // setTimeout(() => {
      //   setAnimateBell(false);
      // }, 500); // ajusta según la duración de la animación
    };
  
    const handleBellClick = () => {
      // Detener la animación al hacer clic en la campanita

      fetchAndClean()
      setAnimateBell(false);
    };
    
    // setTimeout(() => {
    //     setNotificationCount(notificationCount + 1);
    //   setAnimateBell(true);
    // }, 5000); // ajusta según la duración de la animación

    useEffect(()=>{
      if(notificationEvents){
        fetchUnreaded();
      }
        // handleNotification();
    }, [notificationEvents])

    useEffect(()=>{
      fetchUnreaded();
    }, [])

  return (
    <>
        <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            className={btnClass}
          >
            <div className={`notification-icon justify-content-center ${animateBell ? 'notification-animation' : ''}`}  onClick={handleBellClick}>
                
                <i className={`bi ${animateBell ? 'bi-bell bi-2x text-primary' : ENV?.toUpperCase()=='QA'?'bi-bell text-dark':'bi-bell text-secondary'}`}></i>
                {notificationCount > 0 && (
                    <span className="badge bg-danger text-white">{notificationCount}</span>
                )}
            </div>
        </div>
        <NotificationsList notifications={notifications} />

    </>
  )
}

export default Notifications;