import * as Yup from 'yup'

const createInitialValues = {
  name: '',
  cancel_date: '',
}

const createSchema = Yup.object().shape({
  name: Yup.string().required('* Ingrese un nombre para el método de pago'),
  cancel_date: Yup.string(),
})

export {
  createInitialValues,
  createSchema,
}