import { useState, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useSalesOrders } from 'hooks/Sales'

import { FormTable, Tooltip } from 'components'
import { ArticlesModal, ArticleItemModal } from './'

import { filterNewArticles, addArticlesInDetailAdapter, vatOptions } from '../constants'
import { currencyFormat } from 'utilities'
import { SalesOrderStatusI } from 'interfaces'
import { Button } from 'react-bootstrap'

const ArticlesTab = ({ editing, handleEditing, handleGetSalesOrder }) => {
  const intl = useIntl()
  const [modal, setModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const { values, handleReset } = useFormikContext()

  const { handleAddOutRequestDetail, handleUpdateOutRequestDetail, handleDeleteOutRequestDetail } = useSalesOrders()

  const handleCloseModal = () => { setModal(false) }

  const handleShowModal = () => { setModal(true) }

  const handleShowDetailModal = (detail) => {
    setDetailData(detail)
    setDetailModal(true)
  }

  const handleCloseDetailModal = () => {
    setDetailModal(false)
    handleReset()
    handleGetSalesOrder()
  }

  const handleSave = async (value) => {
    if (value.articles.length <= 0) {
      toast.error('Debe seleccionar un artículo')
      handleEditing(false)
      return
    }

    let articles = filterNewArticles(values.detail, value.articles)
    if (articles.length <= 0) {
      toast.error('No se seleccionaron artículos nuevos')
      handleEditing(false)
      return
    }

    try {
      let articlesWithDiscount = []

      articlesWithDiscount = articles.map(article => {
        let new_article = {
          ...article,
          line: '',
          discount: article.discount,
          actual_pvp: article.actual_pvp,
          price: article.subtotal,
          name: article.product_var_desc,
        }
        delete new_article.purchase_discount_base
        delete new_article.purchase_discount_extra
        delete new_article.purchase_discount_extra_2

        return new_article
      })

      let data = addArticlesInDetailAdapter({
        detail: articlesWithDiscount,
      })

      const response = await handleAddOutRequestDetail(values.id, data)

      toast.success("Registro agregado correctamente", {theme:"colored"})
      handleGetSalesOrder()
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleEditing(false)
    }
  }

  const handleUpdate = async (detailId, data) => {
    try {
      let _profitability_rate = (1 - (Number(data.list_cost) / Number(data.subtotal))) * 100

      let _total = values.detail.filter(d => d.id !== detailId).reduce((acc, d) => acc + parseFloat(d?.total), 0)
      _total = _total + Number(data.total)

      let detail = {
        product_var_id: data.product_var_id,
        name: data.product_var_desc,
        line: data?.line ? data.line : null,
        pvp: Number(data.actual_pvp),
        list_cost: Number(data.list_cost),
        qty: Number(data.qty),
        units: Number(data.units),
        discount: Number(data.discount),
        price: Number(data.subtotal),
        profitability: Number(data.profitability),
        profitability_rate: _profitability_rate,
        vat: data.vat,
        vat_rate: Number(data.vat_rate) / 100,
        delivery_cost: values.delivery_cost,
        profitability_total: Number(data.profitability_total),
        subtotal: Number(data.total),
        total: _total,
      }

      const response = await handleUpdateOutRequestDetail(values.id, detailId, { detail: detail })

      toast.success("Registro actualizado correctamente", {theme:"colored"})
      handleCloseDetailModal()
      handleGetSalesOrder()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleDelete = async (detailId) => {
    try {
      const response = await handleDeleteOutRequestDetail(values.id, detailId)

      toast.success("Registro eliminado correctamente", {theme:"colored"})
      handleGetSalesOrder()
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleEditing(false)
    }
  }

  const columns = useMemo(() => [
    { header: intl.formatMessage({ id: 'COLUMN.BUDGETS.ARTICLES.ARTICLE' }) },
    { header: intl.formatMessage({ id: 'COLUMN.BUDGETS.ARTICLES.PVP' }) },
    { header: intl.formatMessage({ id: 'COLUMN.BUDGETS.ARTICLES.LIST_COST' }) },
    { header: intl.formatMessage({ id: 'COLUMN.BUDGETS.ARTICLES.IVA' }) },
    { header: intl.formatMessage({ id: 'COLUMN.BUDGETS.ARTICLES.QUANTITY' }) },
    { header: '', styles: 'text-center' },
  ], [intl])

  return (
    <>
      <ArticlesModal show={modal} onClose={handleCloseModal} onSave={handleSave} />

      <div className=' mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8' >
          <label className=' form-label m-0'>Artículos</label>
          <Button  variant="primary" type='button' onClick={handleShowModal}><i className="bi bi-plus-lg pe-2 mb-1"></i>Añadir artículo</Button>
        </div>

        <FormTable columns={columns}>
          {values.detail.length <= 0
            ? <tr><td className='text-italic text-gray-400' colSpan={columns.length}>El pedido de egreso aún no tiene artículos asociados</td></tr>
            : (values.detail).map(d => (
              <tr key={`budget-detail-${d.id}`}>
                <td className='align-middle'>{d.product_var_desc}</td>
                <td className='align-middle'>{currencyFormat.format(d.actual_pvp)}</td>
                <td className='align-middle'>{currencyFormat.format(d.list_cost)}</td>
                <td className='align-middle'>{vatOptions.find(v => v.value === d.vat).label}</td>
                <td className='align-middle'>{d.qty}</td>
                {!(
                  values.budget_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA ||
                  values.budget_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO ||
                  values.budget_status_id === SalesOrderStatusI.AUTORIZADO ||
                  values.budget_status_id === SalesOrderStatusI.FALTA_STOCK ||
                  values.budget_status_id === SalesOrderStatusI.ORDEN_EGRESO ||
                  values.budget_status_id === SalesOrderStatusI.ANULADO
                ) &&
                  <td className='align-middle text-center'>
                    <div className='d-flex flex-row'>
                      <Tooltip text={`Editar detalle #${d.id}`}>
                        <button type='button' className='btn btn-icon' onClick={() => handleShowDetailModal(d)}>
                          <i className='bi bi-pencil-square fs-2 text-primary' />
                        </button>
                      </Tooltip>

                      <Tooltip text={`Eliminar detalle #${d.id}`}>
                        <button type='button' className='btn btn-icon' onClick={() => handleDelete(d.id)}>
                          <i className='bi bi-trash fs-2 text-danger' />
                        </button>
                      </Tooltip>
                    </div>
                  </td>
                }
              </tr>
            ))
          }
        </FormTable>

        <ArticleItemModal
          show={detailModal}
          onHide={handleCloseDetailModal}
          detail={detailData}
          handleUpdate={handleUpdate}
        />
      </div>
    </>
  )
}

export default ArticlesTab