import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { InputBS, SelectField } from 'components'
import { vatOptions } from '../constants'
import { calculosVenta } from 'utilities/calculosVenta'


const initialValues = {
  id: '',
  line: '',
  product_var_desc: '',
  actual_pvp: 0,
  list_cost: 0,
  qty: 0,
  units: 0,
  discount: 0,
  vat: 1,
  vat_rate: 0,
  price: 0,
  profitability: 0,
  profitability_rate: 0,
  profitability_total: 0,
  total: 0,
  pvp_original: 0,
  list_cost: 0,
  price: 0,
  total: 0,
}
 

const inputStateInitialValues = {
  qty: '',
  discount: '',
  precio_base:'',
  profitability_rate:'',
  unit_price:'',
  list_cost:'',
  total:''
}


const ArticleItemModal = ({ show, onHide, detail, handleUpdate }) => {
  const [article, setArticle] = useState(initialValues)
  const [inputState, setInputState] = useState(inputStateInitialValues);


  const updateBudgetDetail = async (values) => {
    if (detail === null) return toast.error('No se ha seleccionado un artículo')

    try {
      const {display_actual_pvp, display_list_cost, display_total, ...rest } = article;
      
      const calcValues = calculosVenta(inputState.precio_base, inputState.qty, article.vat_rate, parseFloat(article.list_cost).toFixed(2), 
        parseFloat(inputState.discount).toFixed(5), inputState.unit_price)

      const data = {
        line:values.line,
        product_var_id:article.product_var_id,
        name:article.product_var_id,
        discount:inputState.discount,
        qty:inputState.qty,
        units:article.units,
        precio_base:inputState.precio_base,
        list_cost:article.list_cost,
        profitability_rate:inputState.profitability_rate,
        profitability:calcValues.profitability,
        unit_price:inputState.unit_price,
        name:values.product_var_desc,
        vat:article.vat,
        vat_rate:article.vat_rate,
        vat_amount:calcValues.vat_amount,
        subtotal: calcValues.subtotal,
        total: calcValues.total
      }

      handleUpdate(detail?.id, data)

    } catch (error) {
      toast.error(error.message)
    }
  }

    const precioBaseChange = (e)=>{
      if(isNaN(e.target.value))
        return
  
      let _value = e.target.value.trim()
      
      let _precio = +_value * ((+1 - +inputState.discount / 100));
      let _profitability_rate = (+1 - (+article.list_cost / +_precio)) * 100;
      let _total = +inputState.qty * +_precio;

      setInputState((prevState) => ({
        ...prevState,
        precio_base: _value,
        unit_price: parseFloat(_precio).toFixed(2),
        discount: parseFloat(inputState.discount).toFixed(5),
        profitability_rate:parseFloat(_profitability_rate).toFixed(5), 
        total: '$' + parseFloat(_total).toFixed(2),
        profitability: parseFloat(_profitability_rate) * parseFloat(_total) / 100
      }))
    }

    const discountChange = (e)=>{
        if(isNaN(e.target.value))
          return
    
        let _value = e.target.value.trim()

        let _precio = inputState.precio_base * ((+1 - +_value/100));
        let _profitability_rate = (+1 - (+article.list_cost / +_precio)) * 100;
        let _total = +inputState.qty * +_precio;

        setInputState((prevState) => ({
          ...prevState,
          discount: _value,
          unit_price: parseFloat(_precio).toFixed(2),
          profitability_rate: parseFloat(_profitability_rate).toFixed(5),
          total: '$' + parseFloat(_total).toFixed(2)
        }));
    }

    const unitPriceChange = (e)=>{
      if(isNaN(e.target.value))
        return
  
      let _value = e.target.value.trim()
      
      if(+_value>+inputState.precio_base){
        _value = inputState.precio_base
      }
      
      let _descuento = (+1 - +_value/+inputState.precio_base) * 100;
      let _profitability_rate = (+1 - (+article.list_cost / +_value)) * 100;
      let _total = +inputState.qty * +_value;

      console.log(_descuento)
      console.log(_profitability_rate)
      console.log(_total)


      setInputState((prevState) => ({
        ...prevState,
        unit_price: _value,
        discount: parseFloat(_descuento).toFixed(5),
        profitability_rate:parseFloat(_profitability_rate).toFixed(5), 
        total: '$' + parseFloat(_total).toFixed(2)
      }))
    }

    const profitabilityChange = (e)=>{
      if(isNaN(e.target.value))
        return
  
      let _value = e.target.value.trim()

      if(_value<100){
        let _precio = (+100  * +article.list_cost) / (+100 - +_value)
        let _descuento = (+1 - +_precio/+inputState.precio_base) * 100;
        let _total = +inputState.qty * +_precio;

        setInputState((prevState) => ({
          ...prevState,
          unit_price: parseFloat(_precio).toFixed(2),
          discount: parseFloat(_descuento).toFixed(5),
          profitability_rate:_value,       
          total: '$' + parseFloat(_total).toFixed(2)
        }))
      }
      else{
        setInputState((prevState) => ({
          ...prevState,
          profitability_rate:_value
          // unit_price:'',
          // discount:'',
          // precio_base: '',
        }))
      }
    }

    const qtyChange = (e)=>{
        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
        
        let _total = +value * +inputState.unit_price;

        setInputState((prevState) => ({
          ...prevState,
          qty: value,       
          total: '$' + parseFloat(_total).toFixed(2)
        }));
    }


    useEffect(()=>{
      
      if(detail){
        setArticle({...detail,
          display_pvp_original: '$' + parseFloat(detail.pvp_original).toFixed(2), //currencyFormat.format(detail.pvp_original),
          display_list_cost: '$' + parseFloat(detail.list_cost).toFixed(2), // currencyFormat.format(detail.list_cost),
          display_total: '$' + parseFloat(detail.total).toFixed(2), // currencyFormat.format(detail.total)
        })

        setInputState({
          qty: detail.qty,
          total:  '$' + parseFloat(detail.total).toFixed(2),
          discount: parseFloat(detail.discount).toFixed(5),
          precio_base:parseFloat(detail.actual_pvp).toFixed(2),
          profitability_rate:parseFloat(detail.profitability_rate).toFixed(5),
          unit_price:parseFloat(detail.price).toFixed(2),
          list_cost:parseFloat(detail.list_cost).toFixed(2)
        })
      }
    }, [show])


  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Detalle de pedido #{detail?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <Formik
          initialValues={article || {}}
          onSubmit={values=>updateBudgetDetail(values)}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='row mb-8'>
                  <div className='col-4'>
                    <InputBS id='product_var_desc' name='product_var_desc' label='Artículo'/>
                  </div>
                  <div className='col-2'>
                    <InputBS id='line' name='line' label='Línea' />
                  </div>
                  <div className='col-2'>
                    <InputBS id='display_pvp_original' name='display_pvp_original' label='PVP' disabled  className='text-end'/>
                  </div>
                  <div className='col-2'>
                    {/* <InputBS id='pvp' name='pvp' value={article?.display_actual_pvp} label='PVP' className='text-end'  /> */}
                    <InputBS
                      id='precio_base'
                      name='precio_base'
                      value={inputState.precio_base}
                      onChange={precioBaseChange}
                      label='Precio base'
                      maxLength={10}
                    />
                  </div>

                  <div className='col-2'>
                    <InputBS id='list_cost' name='list_cost' value={article?.display_list_cost} label='Costo de lista' className='text-end' disabled />
                  </div>

                </div>

                <div className='row my-8'>
                  <div className='col-1'>
                    <InputBS
                      id='qty'
                      name='qty'
                      type='number'
                      label='Cajas'
                      value={inputState.qty}
                      onChange={qtyChange}
                    />
                  </div>

                  <div className='col-1'>
                    <InputBS id='quantity' name='quantity' value={inputState?.qty * article?.units} type='number' label='Unidades' disabled />
                  </div>

                  <div className='col-2'>
                    <InputBS
                      id='discount'
                      name='discount'
                      value={inputState.discount}
                      onChange={discountChange}
                      label='Descuento (%)'
                      maxLength={8}
                    />
                  </div>

                  <div className='col-2'>
                    <SelectField
                      id='vat'
                      name='vat'
                      options={vatOptions.map(v => ({ value: v.value, label: v.label }))}
                      label='IVA'
                      disabled
                    />
                  </div>

                  {/* <div className='col-2'>
                    <InputBS
                      id='vat_rate'
                      name='vat_rate'
                      value={inputState.vat_rate}
                      onChange={(e) => {
                        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
                        setInputState((prevState) => ({
                          ...prevState,
                          vat_rate: value,
                        }));
                      }}
                      label='IVA'
                      disabled
                    />
                  </div> */}

                  <div className='col'></div>

                  <div className='col-2'>
                    {/* <InputBS id='price' name='price' value={article?.display_price} label='Precio unitario' className='text-end' disabled /> */}
                    <label className='form-label'>Precio caja</label>
                    <input type='text' className='form-control' value={inputState?.unit_price} onChange={unitPriceChange} />
                  </div>

                  {/* <div className='col-2'>
                    <InputBS id='profitability_rate' name='profitability_rate' value={article?.profitability_rate} label='% Rentabilidad' className='text-end' disabled />
                  </div> */}
                  <div className='col-2'>
                    <label className='form-label'>% Rentabilidad</label>
                    <input type='text' className='form-control' value={inputState?.profitability_rate} onChange={profitabilityChange} />
                  </div>

                </div>

                <div className='row my-8'>
                  <div className='col-8'></div>

                  <div className='col-4'>
                    <label className='form-label'>Total</label>
                    <input type='text' className='form-control text-end' value={inputState?.total} disabled />
                    {/* <InputBS id='total' name='total' value={article?.display_total} label='Total' className='text-end' disabled /> */}
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex align-items-center justify-content-end'>
                  <Button variant='secondary' type='button' onClick={onHide} className='me-3'><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='submit'><i className='bi bi-save me-2' />Actualizar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ArticleItemModal