import { FC } from 'react'
import { ButtonI } from './types'
import { Button } from 'react-bootstrap'

const CreateButton: FC<ButtonI> = ({ text, disabled = false, styles, handleClick, children }) => {
  return (
    // <button className={`btn btn-outline btn-outline-primary ${styles}`} type='button' onClick={handleClick} disabled={disabled}>
    //   <i className="las la-plus-circle fs-2" />
    //   {text}
    // </button>
    <Button variant='primary' onClick={handleClick} disabled={disabled}><i className="bi bi-file-earmark-plus mb-1 me-2 fs-4" />{text}</Button>
  )
}

export default CreateButton