
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { InputBS, ProductTypeahead } from 'components'

const ParentProduct = () => {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (values.parent_product) {
      if (values.parent_product.default_purchase_discount_base) {
        setFieldValue('purchase_discount_base', values.parent_product.default_purchase_discount_base)
      }
      if (Boolean(values.parent_product.family_traced)) {
        setFieldValue('traced', Boolean(values.parent_product.family_traced))
      }
    }
  }, [values.parent_product])

  return (
    <>
      <div style={{ height: "180px", borderBottom: "solid 1px #cdcdcd", marginBottom: "10px" }}>
        <div className="row mt-4">
            <div className='col'>
              <ProductTypeahead name="parent_name" label="Seleccionar producto padre" />
            </div>

            <div className='col'>
              {values.parent_product && <InputBS name="product_type_name" label="Tipo" disabled />}
            </div>

            {values.parent_product &&
              <div className='mt-2'>
                <p className='m-1'>
                  <b>{values.parent_product.product_type_id == 1 ? 'Laboratorio' : 'Fabricante'}:</b> {values.parent_product.maker_name}
                </p>

                <p className='m-1'>
                  <b>{values.parent_product.product_type_id == 1 ? 'Ppio. Activo' : 'Familia'}:</b> {values.parent_product.family_name}
                </p>

                <p className='m-1'>
                  <b>Canal Comercial:</b> {values.parent_product.distribution_channels.map(d => d.distribution_channel_name).join(", ")}
                </p>
              </div>
            }
        </div>
      </div>
    </>
  )
}

export default ParentProduct