import { useState, useEffect, useCallback } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useSalesOrders } from 'hooks'
import { InputBS } from 'components'

import { SalesOrderStatusI } from 'interfaces'

const AuthModal = ({ show, onClose, salesOrder, handleGetSalesOrder }) => {
  const [initialValues, setInitialValues] = useState({
    dispatch_date: '',
  })

  const [pvpError, setPvpError] = useState(false)
  const [pvpErrorQty, setPvpErrorQty] = useState(0)

  const { loading, handleCheckSalesOrderPVP, handleUpdateSalesOrderStatus } = useSalesOrders()

  const autorizarPedido = async (values) => {
    if (salesOrder && salesOrder?.id) {
      if (salesOrder.address_id_traza == '' || !salesOrder.address_id_traza || salesOrder.address_id_ship == '' || !salesOrder.address_id_ship) {
        toast.error('El pedido no tiene dirección cargada')
        return
      }

      try {
        const data = {
          dispatch_date: values?.dispatch_date ? moment(values.dispatch_date).format('YYYY-MM-DD HH:mm:ss') : null,
        }

        await handleUpdateSalesOrderStatus(salesOrder.id, SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA, data)
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetSalesOrder()
        onClose()
      }
    }
  }

  const checkSalesOrderPVP = useCallback(async () => {
    if (salesOrder && salesOrder?.id) {
      try {
        const response = await handleCheckSalesOrderPVP(salesOrder.id)

        if (response.data.result && response.data.result.length > 0) {
          setPvpError(true)
          setPvpErrorQty(response.data.result.length)
        } else {
          setPvpError(false)
          setPvpErrorQty(0)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }, [handleCheckSalesOrderPVP, salesOrder])

  useEffect(() => {
    setInitialValues({
      dispatch_date: salesOrder?.dispatch_date ? moment(salesOrder.dispatch_date).format('YYYY-MM-DD') : ''
    })
  }, [salesOrder])

  useEffect(() => {
    checkSalesOrderPVP()
  }, [checkSalesOrderPVP])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' size='md' centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Enviar a Autorización de rentabilidad</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={autorizarPedido}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row mb-6'>
                  <div className='col-6'>
                    <InputBS id='dispatch_date' name='dispatch_date' type='date' label='Fecha de entrega estimada' disabled={loading} />
                  </div>
                </div>

                <div className='row'>
                  <div className='12'>
                    {pvpError && pvpErrorQty > 0 &&
                      <p className='px-2'>
                        <i className='bi bi-exclamation-circle text-danger me-2' />
                        El <strong>Pedido #{salesOrder.id}</strong> tiene ({pvpErrorQty}) detalle{pvpErrorQty > 1 ? `s` : ''} con <i>PVP desactualizado.</i>
                      </p>
                    }
                    <p>Va a enviar a autorizar la rentabilidad del <strong>Pedido #{salesOrder.id}</strong>, ¿confirma?</p>
                  </div>
                </div>

                <div className='separator my-6'></div>

                <div className='row'>
                  <div className='col-12 text-end'>
                    <Button type='button' variant='secondary' onClick={onClose} className='me-3' disabled={loading}>
                      <i className='bi bi-slash-circle' />
                      Cancelar
                    </Button>

                    <Button type='submit' variant='primary' disabled={loading}>
                      <i className='bi bi-save me-2' />
                      Enviar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AuthModal