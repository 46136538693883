
import routes from 'app/routing/routes'
import { Link } from 'react-router-dom'
import { currencyFormat } from 'utilities'

const useGetColumns = (selectedRows = [], handleRowSelection) => {
  const COLUMNS =  [
    {
      Header: 'Pedido',
      id: 'sales_order_id',
      accessor: (row) => (
        <div className=''>
          <Link to={`${routes.SALES_ORDERS}/${row.sales_order_id}`}>
            {row.sales_order_id}
          </Link>
        </div>
      ),
    },
    {
      Header: 'Órden egreso',
      id: 'out_order_id',
      accessor: (row) => (
        <div className=''>
          <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>
            {row.out_order_id}
          </Link>
        </div>
      )
    },
    {
      Header: 'Dirección',
      id:"shipment_address_name",
      accessor: (row) => (
        <>
          <p className='p-0 m-0 fw-bold'>{row.shipment_address_name2}</p>
          <p>{row.shipment_address_formatted_address2}</p>
        </>
      ),
    },
    {
      Header: 'Total',
      id:"total",
      accessor: (row) => (
        <div>
          {currencyFormat.format(row.total)}
        </div>
      ),
    },
    {
      Header: <div className=''>AFIP</div>,
      id: 'afip',
      accessor: (row) => (
        <div>
          {row.afip?.afip_nro_cbt_completo}
        </div>
      ),
    },
    {
      Header: <div className=''>Remito</div>,
      id: 'remito',
      accessor: (row) => (
        <div>
          {row.remito?.remito_nro_cbt_completo}
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns