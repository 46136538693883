import { useEffect, useRef, useState } from 'react';
import {Logistic} from 'services'
import { KTSVG } from '_metronic/helpers';
import { useAuth } from 'app/modules/auth';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const useFocus=()=>{
    const htmlElRef=useRef(null);
    const setFocus= ()=>{htmlElRef.current && htmlElRef.current.focus()}
    return [htmlElRef, setFocus]
}

const BarCodeReader = ({handleNewTag, movement_id, storage_space_id}) => {
    // const [dataInputText, setDataInputText] = useState("");
    const [inputRef, setInputFocus] = useFocus();
    const [inputInFocus, setInputInFocus] = useState(false);
    const {currentUser} = useAuth();
    const [gs1Manual, setGs1Manual] = useState("");

    let cadena="";

    const enviar = async()=>{

        try{
            const scanCode = encodeURIComponent(cadena.replace(/Shift/g, "")
                .replace(/CapsLock/g, '') 
                .replace("Enter","")
                .replace(/Alt0029/g, "\u001D") // el caracter oculto
                .replace(/'/g, '-') //reemplaza ' por -
                .toUpperCase()) 

            const data = {
                "storage_space_id": storage_space_id
            }
            const resp = await Logistic.postMovementGS1(movement_id, scanCode, data)

            
            handleNewTag(resp.data, null)
        }
        catch(err){
            if (err.response) {
                handleNewTag(null, err.response.message)
            } else {
                handleNewTag(null, err)
            }
        }
        finally{
            // setDataInputText("");
            cadena="";
        }
    }

    const handleKeyDown = (event)=>{
        cadena=cadena + event.key
        if(event.key=='Enter'){
            event.preventDefault();
            enviar()
            cadena="";
            inputRef.current.value="";
        }
    }    

    const btnClick = (ev)=>{
        ev.preventDefault();
        setInputFocus()
        setInputInFocus(true);
    }

    const handleManualKeyDown = (event)=>{
        if(gs1Manual && gs1Manual.length>0){
            cadena=gs1Manual;
            if(event.key=='Enter'){
                event.preventDefault();
                enviar()
                cadena="";
                inputRef.current.value="";                
            }   
        }
    } 

    useEffect(()=>{
        setInputFocus();
        setInputInFocus(true);
    }, [])

    return (
        <>  
            <div className='row'>
                <div className='col-10'></div>
                    <div className='col-2 text-center'>

                        <a href="#" className="btn btn-icon btn-light pulse pulse-white "  onClick={btnClick} >
                            <KTSVG path="/media/icons/duotune/electronics/elc007.svg"
                                className={`svg-icon svg-icon-3x ${inputInFocus?"text-success":"text-danger"}`}
                            />
                            { !inputInFocus && <span className="pulse-ring"></span>}
                        </a> 

                        <span className={`'p-3 d-block' ${inputInFocus? "":"text-danger"}`}>{inputInFocus? "Lector Activo":"Lector Inactivo"}</span> 
                        
                    </div>

                    <input 
                        type="text"  
                        autoComplete="off" 
                        defaultValue={""} //{dataInputText}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        style={{opacity:"0", width:"100px"}}
                        onBlur={()=>setInputInFocus(false)}
                    />

                    {currentUser?.user_id==1?
                    <>
                        <FormGroup className="">
                            <FormLabel>Codigo GS1</FormLabel>
                            <FormControl type="text"  autoComplete="off" className="form-control" value={gs1Manual || ""} onChange={e=>setGs1Manual(e?.target?.value)} 
                                onKeyDown={handleManualKeyDown}/>
                        </FormGroup>
                    </>
                    :
                    <></>
                    }
            </div>                    

        </>
    );
};

export default BarCodeReader;
