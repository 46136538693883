import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { InputBS, SelectField } from 'components'
import { vatOptions } from '../../constants'

const initialValues = {
  id: '',
  in_request_id: '',
  product_var_desc: '',
  product_var_id: '',
  actual_pvp: 0,
  psl: 0,
  qty: 0,
  units: 0,
  purchase_discount_base: 0,
  purchase_discount_extra: 0,
  purchase_discount_extra_2: 0,
  vat: 0,
  vat_rate: 0,
  traced: 0,
  unit_purchase_price: 0,
  subtotal: 0,
  total: 0,
}
const inputStateInitialValues = {
  qty: '',
  purchase_discount_extra: '',
  purchase_discount_extra_2: '',
  precio_base:'',
  unit_price:'',
  unit_vat_amount:'',
  vat_amount:'',
  subtotal:'',
  total:'',
  display_unit_price:'',
  display_vat_amount:'',
  display_subtotal:'',
  display_total:''
}
const displayValuesInitialValues = {
  actual_pvp: '',
  psl: '',
  unit_purchase_price: '',
  subtotal: '',
  vat: '',
  total: '',
}

const ArticleItemModal = ({ show, onHide, detail, handleUpdate }) => {
    const [values, setValues] = useState(initialValues)
    const [inputState, setInputState] = useState(inputStateInitialValues);
    const [displayValues, setDisplayValues] = useState(displayValuesInitialValues)
    
/*

tabla:
id
in_request_id
product_var_id
actual_pvp
psl
qty
purchase_discount_base
purchase_discount_extra
vat_rate
vat
subtotal
product_var_desc
purchase_discount_extra_2
unit_purchase_price
total
outdated_pvp
vat_id
psl_original
*/
    const updateDetail = async (values) => {
      if (detail === null) return toast.error('No se ha seleccionado un artículo')

      const {display_subtotal, display_total, display_unit_price, display_vat_amount, precio_base, unit_price, vat_amount, ...rest} = inputState;

      const params = {...rest,
        id: detail.id,
        in_request_id: detail.in_request_id,
        product_var_id: detail.product_var_id,
        product_var_desc: detail.product_var_desc,
        actual_pvp: detail.actual_pvp,
        vat: vat_amount,
        vat_rate: detail.vat_rate,
        psl: precio_base,
        psl_original: detail.psl_original,
        purchase_discount_base: detail.purchase_discount_base,
        unit_purchase_price: unit_price,
        outdated_pvp: detail.outdated_pvp,
        traced: detail.traced,
        refrigerated: detail.refrigerated,
        gtin: detail.gtin,
        vat_id:detail.vat_id

      }

      handleUpdate(params)
      onHide();
    }



    const precioBaseChange = (e)=>{
      if(isNaN(e.target.value))
        return

      let _value = e.target.value.trim()

      let _precio = _value;

      if(inputState.purchase_discount_extra && +inputState.purchase_discount_extra>0){
        _precio = _precio - ( _value * +inputState.purchase_discount_extra/100);
      }
      // Si hay descuento extra, se aplica sobre el precio_base
      if(inputState.purchase_discount_extra_2 && +inputState.purchase_discount_extra_2>0){
        _precio = _precio - ( _value * +inputState.purchase_discount_extra_2/100);
      }
      
      let _total = +_precio * +inputState.qty;
      let _subtotal = + _total;
      let _monto_iva = 0;
      let _monto_iva_unitario = 0;

      if(detail.vat_rate && +detail.vat_rate>0){
          _subtotal = +_total * 100 / (100 + +detail.vat_rate);
          _monto_iva = +_total - +_subtotal;        
          _monto_iva_unitario = +inputState.qty>0 ?  +_monto_iva / +inputState.qty : 0;
      }

      setInputState((prevState) => ({
        ...prevState,
        precio_base:_value,
        unit_price: parseFloat(_precio),
        display_unit_price: '$' + parseFloat(_precio).toFixed(2),
        unit_vat_amount:_monto_iva_unitario,
        subtotal: parseFloat(_subtotal),
        display_subtotal: '$' + parseFloat(_subtotal).toFixed(2),
        vat_amount: parseFloat(_monto_iva),
        display_vat_amount: '$' + parseFloat(_monto_iva).toFixed(2),
        total: parseFloat(_total),
        display_total: '$' + parseFloat(_total).toFixed(2),
      })); 

    }



    const qtyChange = (e)=>{
      if(isNaN(e.target.value))
        return

      let _value = e.target.value.replace(/\D/g, '').trim()

      let _precio = inputState.precio_base;

      if(inputState.purchase_discount_extra && +inputState.purchase_discount_extra>0){
        _precio = _precio - ( inputState.precio_base * +inputState.purchase_discount_extra/100);
      }
      // Si hay descuento extra, se aplica sobre el precio_base
      if(inputState.purchase_discount_extra_2 && +inputState.purchase_discount_extra_2>0){
        _precio = _precio - ( inputState.precio_base * +inputState.purchase_discount_extra_2/100);
      }
      
      let _total = +_precio * +_value;
      let _subtotal = + _total;
      let _monto_iva = 0;
      let _monto_iva_unitario = 0;

      if(detail.vat_rate && +detail.vat_rate>0){
          _subtotal = +_total * 100 / (100 + +detail.vat_rate);
          _monto_iva = +_total - +_subtotal;        
          _monto_iva_unitario = +_value>0 ?  +_monto_iva / +_value : 0;
      }

      setInputState((prevState) => ({
        ...prevState,
        qty:_value,
        unit_price: parseFloat(_precio),
        display_unit_price: '$' + parseFloat(_precio).toFixed(2),
        unit_vat_amount:_monto_iva_unitario,
        subtotal: parseFloat(_subtotal),
        display_subtotal: '$' + parseFloat(_subtotal).toFixed(2),
        vat_amount: parseFloat(_monto_iva),
        display_vat_amount: '$' + parseFloat(_monto_iva).toFixed(2),
        total: parseFloat(_total),
        display_total: '$' + parseFloat(_total).toFixed(2),
      }));      
    }


    const bonificacionChange = (e)=>{
      if(isNaN(e.target.value))
        return
      let _value = e.target.value.trim()

      let _precio = inputState.precio_base * ((+1 - +_value/100));
      // Si hay descuento extra, se aplica sobre el precio_base
      if(inputState.purchase_discount_extra_2 && +inputState.purchase_discount_extra_2>0){
        _precio = _precio - ( inputState.precio_base * +inputState.purchase_discount_extra_2/100);
      }
      
      let _total = +_precio * +inputState.qty;
      let _subtotal = + _total;
      let _monto_iva = 0;
      let _monto_iva_unitario = 0;

      if(detail.vat_rate && +detail.vat_rate>0){
          _subtotal = +_total * 100 / (100 + +detail.vat_rate);
          _monto_iva = +_total - +_subtotal;        
          _monto_iva_unitario = +inputState.qty>0 ?  +_monto_iva / +inputState.qty : 0;
      }

      setInputState((prevState) => ({
        ...prevState,
        purchase_discount_extra: _value,
        unit_price: parseFloat(_precio),
        display_unit_price: '$' + parseFloat(_precio).toFixed(2),
        unit_vat_amount:_monto_iva_unitario,
        subtotal: parseFloat(_subtotal),
        display_subtotal: '$' + parseFloat(_subtotal).toFixed(2),
        vat_amount: parseFloat(_monto_iva),
        display_vat_amount: '$' + parseFloat(_monto_iva).toFixed(2),
        total: parseFloat(_total),
        display_total: '$' + parseFloat(_total).toFixed(2),
      }));
    }

    const descuentoChange = (e)=>{
      if(isNaN(e.target.value))
        return
      let _value = e.target.value.trim()

      let _precio = inputState.precio_base * ((+1 - +_value/100));
      // Si hay bonificacion, se aplica sobre el precio_base
      if(inputState.purchase_discount_extra && +inputState.purchase_discount_extra>0){
        _precio = +_precio - ( inputState.precio_base * +inputState.purchase_discount_extra/100);
      }
      
      let _total = +_precio * +inputState.qty;
      let _subtotal = + _total;
      let _monto_iva = 0;
      let _monto_iva_unitario = 0;

      if(detail.vat_rate && +detail.vat_rate>0){
          _subtotal = +_total * 100 / (100 + +detail.vat_rate);
          _monto_iva = +_total - +_subtotal;        
          _monto_iva_unitario = +inputState.qty>0 ?  +_monto_iva / +inputState.qty : 0;
      }

      setInputState((prevState) => ({
        ...prevState,
        purchase_discount_extra_2: _value,
        unit_price: parseFloat(_precio),
        display_unit_price: '$' + parseFloat(_precio).toFixed(2),
        unit_vat_amount:_monto_iva_unitario,
        subtotal: parseFloat(_subtotal),
        display_subtotal: '$' + parseFloat(_subtotal).toFixed(2),
        vat_amount: parseFloat(_monto_iva),
        display_vat_amount: '$' + parseFloat(_monto_iva).toFixed(2),
        total: parseFloat(_total),
        display_total: '$' + parseFloat(_total).toFixed(2),
      }));
    }


    useEffect(()=>{        
      if(detail){
        console.log(detail)
        setValues({...detail,
          display_pvp: '$' + parseFloat(detail.actual_pvp).toFixed(2), //currencyFormat.format(detail.pvp_original),
          display_psl: '$' + parseFloat(detail.psl_original).toFixed(2), 
          display_unit_purchase_price: '$' + parseFloat(detail.unit_purchase_price).toFixed(2), // currencyFormat.format(detail.list_cost),
          display_subtotal: '$' + parseFloat(detail.subtotal).toFixed(2), // currencyFormat.format(detail.total)
          display_vat: '$' + parseFloat(detail.vat).toFixed(2), // currencyFormat.format(detail.total)
          display_total: '$' + parseFloat(detail.total).toFixed(2), // currencyFormat.format(detail.total)
          vat_id: (+detail.vat_rate==21) ? 5 : 2
        })

        setInputState({
          qty: detail.qty,
          purchase_discount_extra:detail.purchase_discount_extra,
          purchase_discount_extra_2:detail.purchase_discount_extra_2,
          precio_base:parseFloat(detail.psl).toFixed(2),
          unit_price: parseFloat(detail.unit_purchase_price),
          display_unit_price:'$' + parseFloat(detail.unit_purchase_price).toFixed(2),
          vat_amount: parseFloat(detail.vat),
          display_vat_amount:'$' + parseFloat(detail.vat).toFixed(2),
          subtotal: parseFloat(detail.subtotal),
          display_subtotal:'$' + parseFloat(detail.subtotal).toFixed(2),
          total: parseFloat(detail.total),
          display_total:'$' + parseFloat(detail.total).toFixed(2),
        })
      }
    }, [show])

  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Detalle de pedido #{detail?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <Formik
          initialValues={values}
          onSubmit={updateDetail}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <div className='row mb-8'>

                  <div className='col-6'>
                    <InputBS id='product_var_desc' name='product_var_desc' label='Artículo' />
                  </div>

                  <div className='col-2'>
                    <InputBS id='display_pvp' name='display_pvp' label='PVP' className='text-end' disabled />
                  </div>
                  <div className='col-2'>
                    <InputBS id='display_psl' name='display_psl'  label='PSL' className='text-end' disabled />
                  </div>
                  <div className='col-2'>
                    {/* <InputBS id='psl' name='psl' value={displayValues.psl} label='PSL' className='text-end' disabled /> */}
                    {/* <InputBS id='precio_base' name='precio_base'
                      value={inputState.precio_base}  
                      onChange={precioBaseChange}
                      label='Precio base'
                      maxLength={10}
                    /> */}
                    
                    <label className='form-label'>Precio base</label>
                    <input type='text' className='form-control text-end' value={inputState?.precio_base} onChange={precioBaseChange} />
                  </div>

                </div>

                <div className='row my-8'>
                  <div className='col-2'>
                    {/* <InputBS
                      id='qty'
                      name='qty'
                      type='number'
                      label='Cantidad'
                      value={inputState.qty}
                      onChange={e => {
                        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
                        setInputState((prevState) => ({
                          ...prevState,
                          qty: value,
                        }));
                      }}
                    /> */}
                    <label className='form-label'>Cantidad</label>
                    <input type='text' className='form-control text-end' value={inputState?.qty} onChange={qtyChange} />
                  </div>

                  <div className='col-2'>
                    {/* <InputBS
                      id='purchase_discount_extra'
                      name='purchase_discount_extra'
                      value={inputState.purchase_discount_extra}
                      onChange={(e) => {
                        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
                        setInputState((prevState) => ({
                          ...prevState,
                          purchase_discount_extra: value,
                        }));
                      }}
                      label='Bonificación'
                    /> */}
                    <label className='form-label'>Bonificación (%)</label>
                    <input type='text' className='form-control text-end' value={inputState?.purchase_discount_extra} onChange={bonificacionChange} />
                  </div>

                  <div className='col-2'>
                    {/* <InputBS
                      id='purchase_discount_extra_2'
                      name='purchase_discount_extra_2'
                      value={inputState.purchase_discount_extra_2}
                      onChange={(e) => {
                        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
                        setInputState((prevState) => ({
                          ...prevState,
                          purchase_discount_extra_2: value,
                        }));
                      }}
                      label='Descuento pactado'
                    /> */}

                    <label className='form-label'>Descuento pactado (%)</label>
                    <input type='text' className='form-control text-end' value={inputState?.purchase_discount_extra_2} onChange={descuentoChange} />

                  </div>

                  <div className='col'>
                    <SelectField
                      id='vat_id'
                      name='vat_id'
                      options={vatOptions.map(v => ({ value: v.value, label: v.label }))}
                      label='IVA'
                      disabled
                    />
                  </div>

                  <div className='col-4'>
                    {/* <InputBS id='display_unit_purchase_price' name='display_unit_purchase_price' label='Precio unitario' className='text-end' disabled /> */}
                    <label className='form-label'>Precio unitario</label>
                    <input type='text' className='form-control text-end' value={inputState?.display_unit_price} disabled />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-8'></div>
                  <div className='col-4'>
                    {/* <InputBS id='display_subtotal' name='display_subtotal' label='Subtotal' className='text-end' disabled /> */}
                    <label className='form-label'>Subtotal</label>
                    <input type='text' className='form-control text-end' value={inputState?.display_subtotal} disabled />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-8'></div>
                  <div className='col-4'>
                    {/* <InputBS id='display_vat' name='display_vat' label='IVA' className='text-end' disabled /> */}
                    <label className='form-label'>IVA</label>
                    <input type='text' className='form-control text-end' value={inputState?.display_vat_amount} disabled />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-8'></div>
                  <div className='col-4'>
                    {/* <InputBS id='display_total' name='display_total' label='Total' className='text-end' disabled /> */}
                    <label className='form-label'>Total</label>
                    <input type='text' className='form-control text-end' value={inputState?.display_total} disabled />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex align-items-center justify-content-end'>
                  <Button variant='secondary' onClick={onHide} className='me-3'><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='submit'><i className='bi bi-save me-2' />Guardar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ArticleItemModal