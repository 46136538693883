
import { useEffect, useState } from "react";

const selectStyle = {
  width:"75px",
  height: "calc(1.35em + 1.1rem + 2px)",
  fontSize: ".925rem",
  lineHeight: "1.35",
  fontFamily: "Poppins, Helvetica, sans-serif"
}

const SizePerPageList = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];


const TablePagination = ({ paginationOptions }) => {
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const [visiblePages, setVisiblePages] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);

  const { totalSize = 10, obQuery = {}, setObQuery  } = paginationOptions;

  const onNextPage = () => setObQuery({ ...obQuery, pageNumber: +obQuery.pageNumber + +1 });
  const onPrevPage =  () => setObQuery({ ...obQuery, pageNumber: +obQuery.pageNumber - +1 });
  const onPageSelect =   (pageNo) => setObQuery({ ...obQuery, pageNumber: pageNo });

  const handleChange = (page)=>{
    if(setObQuery && obQuery.pageNumber!=page)
      setObQuery({...obQuery, pageNumber:page})
      // pageChangeHandler(page);
  }

  const getPages = (page, pagesCount, paginationSize) => {
    const result = [];

    if (!page) {
      return result;
    }

    if (pagesCount === 1) {
      result.push(1);
      return result;
    }

    if (pagesCount < page) {
      return result;
    }

    if (pagesCount < paginationSize + 1) {
      for (let i = 1; i < pagesCount + 1; i++) {
        result.push(i);
      }
      return result;
    }

    if (page === 1) {
      for (let i = 1; i < paginationSize + 1; i++) {
        if (i < pagesCount) {
          result.push(i);
        }
      }
      return result;
    }

    if (page === pagesCount) {
      for (let i = pagesCount - paginationSize + 1; i <= pagesCount; i++) {
        if (i <= pagesCount) {
          result.push(i);
        }
      }
      return result;
    }

    const shiftCount = Math.floor(paginationSize / 2);
    if (shiftCount < 1) {
      result.push(page);
      return result;
    }

    if (page < shiftCount + 2) {
      for (let i = 1; i < paginationSize + 1; i++) {
        result.push(i);
      }
      return result;
    }

    if (pagesCount - page < shiftCount + 2) {
      for (let i = pagesCount - paginationSize; i < pagesCount + 1; i++) {
        result.push(i);
      }
      return result;
    }

    for (let i = page - shiftCount; i < page; i++) {
      if (i > 0) {
        result.push(i);
      }
    }
    result.push(page);
    for (let i = page + 1; i < page + shiftCount + 1; i++) {
      if (i <= pagesCount) {
        result.push(i);
      }
    }

    return result;
  };

  const renderPageButtons = () => {
    return visiblePages.map((page, index) => (
      <li key={index} className={`page-item`}>
        <button
          key={page}
          type='button'
          style={{fontSize:"0.95rem"}}
          className={`page-link btn btn-icon btn-sm ${page === obQuery.pageNumber? 'active':'btn-light'}`}
          onClick={() => onPageSelect(page)}
        >
          {page}
        </button>
      </li>
    ));
  };

  const onChangeSizePerPage = (e) => {
    if(setObQuery)
      setObQuery({ ...obQuery, pageSize: e.target.value })
    setNoOfPages(Math.ceil(totalSize / e.target.value))
  }

  useEffect(() => {
    if (noOfPages === obQuery.pageNumber) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }

    if (obQuery.pageNumber === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }

    const val = getPages(obQuery.pageNumber, noOfPages, 5);
    setVisiblePages(val);
  }, [noOfPages, obQuery.pageNumber]);


  useEffect(() => {
    handleChange(obQuery.pageNumber);

  }, [obQuery.pageNumber, obQuery.pageSize]);

  useEffect(() => {
    setNoOfPages(Math.ceil(totalSize / obQuery.pageSize))
  }, [totalSize])



  return (
    <>
      {noOfPages < 2 && <></>}
      {noOfPages > 1 && (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap p-10">
            <div className="d-flex flex-wrap py-2 mr-3 ">
              <ul className="pagination">
                <li className={`page-item ${!canGoBack && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={()=>onPageSelect(1)} disabled={!canGoBack}>
                    <i className="ki ki-double-arrow-back icon-sm"></i>
                  </button>
                </li>

                <li className={`page-item previous ${!canGoBack && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={onPrevPage} disabled={!canGoBack}>
                    <i className="ki ki-arrow-back icon-sm"></i>
                  </button>
                </li>

                {obQuery.pageNumber > 1 && ( <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"> ... </a>)}

                {renderPageButtons()}

                {obQuery.pageNumber < noOfPages && ( <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"> ... </a>)}

                <li className={`page-item next ${!canGoNext && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={onNextPage} disabled={!canGoNext}>
                    <i className="ki ki-arrow-next icon-sm"></i>
                  </button>
                </li>

                <li className={`page-item next ${!canGoNext && 'disabled'}`}>
                  <button type='button' className="page-link btn btn-icon btn-sm btn-light" onClick={()=>onPageSelect(noOfPages)} disabled={!canGoNext}>
                    <i className="ki ki-double-arrow-next icon-sm"></i>
                  </button>
                </li>
              </ul>

            </div>
            <div className="d-flex align-items-center py-3">
              <select
                className="form-control form-control-sm form-select font-weight-bold mr-4 border-0 bg-light"
                style={selectStyle}
                value={obQuery.pageSize}
                onChange={(e) => onChangeSizePerPage(e)
                }
              >
                {SizePerPageList.map(opcion => (
                  <option key={opcion.value} value={opcion.value}>{opcion.label}</option>
                ))}
              </select>

              <span className="react-bootstrap-table-pagination-total ms-5 text-muted">
                Mostrando { ((obQuery.pageNumber-1)  * obQuery.pageSize) + 1 } a { (((+obQuery.pageNumber-1) * +obQuery.pageSize)) +
                  +obQuery.pageSize < +totalSize ? (((+obQuery.pageNumber-1) * +obQuery.pageSize)) + +obQuery.pageSize : +totalSize}  de { totalSize } Resultados
              </span>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default TablePagination