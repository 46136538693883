import { useState, useCallback } from 'react';

import { Patients } from 'services';

const usePatients = () => {
  const [loading, setLoading] = useState(false);

  const handleGetPatients = useCallback(async (token, query = '', params = {}) => {
    try {
      setLoading(true);

      if (!query) query = ''
      const response = await Patients.getPatients(token, query, params);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetPatient = useCallback(async (id, token) => {
    try {
      setLoading(true);
      const response = await Patients.getPatient(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreatePatient = useCallback(async (data, token) => {
    try {
      setLoading(true);
      const response = await Patients.createPatient(data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdatePatient = useCallback(async (id, data, token) => {
    try {
      setLoading(true);
      const response = await Patients.updatePatient(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdatePatientStatus = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Patients.updatePatientStatus(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPatientAddresses = useCallback(async (id, params = {}) => {
    try {
      setLoading(true);
      const response = await Patients.getPatientAddresses(id, params);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [])

  const handleAddPatientAddress = useCallback(async (id, data, token) => {
    try {
      setLoading(true);
      const response = await Patients.addPatientAddress(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLinkAddressToPatient = useCallback(async (id, address_id) => {
    try {
      setLoading(true)
      const response = await Patients.linkAddressToPatient(id, address_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdatePatientAddress = useCallback(async (id, address_id, data) => {
    try {
      setLoading(true)
      const response = await Patients.updatePatientAddress(id, address_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeletePatientAddress = useCallback(async (id, address_id, token) => {
    try {
      setLoading(true);
      const response = await Patients.deletePatientAddress(id, address_id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetPatientAssociatedCustomers = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Patients.getPatientAssociatedCustomers(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetPatients,
    handleGetPatient,
    handleCreatePatient,
    handleUpdatePatient,
    handleUpdatePatientStatus,
    handleGetPatientAddresses,
    handleAddPatientAddress,
    handleLinkAddressToPatient,
    handleUpdatePatientAddress,
    handleDeletePatientAddress,
    handleGetPatientAssociatedCustomers,
  };
};

export default usePatients;