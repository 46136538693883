import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useClickOutside, useCustomerSuppliers } from 'hooks'

// import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { SuppliersColumns, SupplierModal } from '.'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const SuppliersTab = () => {
  const { values, setFieldValue } = useFormikContext()
  const [showModal, setShowModal] = useState(false)

  const { COLUMNS } = SuppliersColumns()

  const addSupplier = (data) => {
    const suppliers = [...values.suppliers, data]
    setFieldValue('suppliers', suppliers)
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Eliminar",
      icon: "bi bi-trash text-danger",
      action: actionDeleteSupplier
    })

    return actions
  }

  const actionDeleteSupplier = () => {
    if (currentRow) {
      console.log(`Eliminar proveedor #${currentRow.customer_supplier_id}`)
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

  useEffect(() => {
  }, [values.suppliers])

  return (
    <>
      <SupplierModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={(value) => {
          console.log(value)
          setShowModal(false)
          addSupplier(value)
        }}
      />

      <div className='text-end'>
        <Button variant='primary' type='button' onClick={() => setShowModal(true)}>Añadir proveedor</Button>
      </div>

      <CustomTable columns={columns} data={values?.suppliers} handleContextMenu={handleContextMenu} />

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default SuppliersTab