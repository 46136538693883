/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import { useIntl } from 'react-intl'

import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import "./login.css"

const AuthLayout = () => {
  const intl = useIntl()

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div className='w-100 h-100 d-flex'>
      <div
        className='w-30 d-flex flex-column justify-content-center align-items-start p-10 flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          position:"relative",
          backgroundImage: `url(${toAbsoluteUrl('images/login-riga-farma-liso.jpg')})`
        }}
      >
        <img src={toAbsoluteUrl('images/riga-login.gif')} 
          alt="Animated GIF" 
          className='camion'
        />
        <h1 className='text-white'>{intl.formatMessage({ id: 'APP.TITLE' })}</h1>
        <p className='text-white'>{intl.formatMessage({ id: 'APP.VERSION' }, { version: '0.0.1' })}</p>
      </div>
      <div className='w-70 d-flex justify-content-center align-items-center'>
        <div className='w-lg-500px'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
