import * as Yup from 'yup'
import moment from 'moment'

const createInitialValues = {
  name: '',
  reg: {},
  ab: {},
  reg_id: '',
  nom: '',
  product_type_id: null,
  refrigerated: 0,
  imported: false,
  traced: false,
  score_line: '',
  score_line_cancelled: false,
  presentation: '',
  ab_link: false,
  gtin: '',
  bar: '',
  sku: '',
  unit: '',
  distribution_channel: undefined,
  pot: '',
  form: undefined,
  via: undefined,
  control: undefined,
  vat: null,
  purchase_vat: null,
  profitablity_min: '',
  var_name: '',
  tam: null,
  purchase_discount_base: 31.03448,
  purchase_discount_extra: 0,
  psl: '',
  alc: '',
  manual_price_on: true,
  manual_price: { price: '', date_from: moment().format("DD/MM/YYYY") },
  initial_cost_type: 'Porcentual',
  manual_list_cost: { list_cost: "", date_from: "", date_to: "" },
  customer_supplier_id: undefined,
  ab_pvp: '',
  ab_pvp_start: '',
  purchase_add_cost_type: 0,
  purchase_add_cost: '',
  purchase_add_cost_percent: 0,
}

const createSchema = Yup.object().shape({
    name: Yup.string().required('El nombre del artículo es obligatorio'),
    vat: Yup.number().required('La carga del IVA venta es obligatoria'),
    purchase_vat: Yup.number().required('La carga del IVA compra es obligatoria'),
    gtin: Yup.number().required('El GTIN es obligatorio'),
    sku: Yup.string().required('La carga del SKU es obligatoria'),
    // manual_price_on: Yup.bool(),
    // manual_price: Yup.object().when(['manual_price_on'], {
    //       is: (manual_price_on) => manual_price_on === true,
    //       then: Yup.object().shape({
    //           fecha: Yup.date(),
    //           price: Yup.string().required('El precio es obligatorio cuando manual_price_on es true'),
    //         }),
    //       otherwise: Yup.object().shape({
    //           fecha: Yup.date(),
    //           price: Yup.string(),
    //         }),
    // })

    // presentation: Yup.string().min(2).required('Ingrese un nombre para presentacion'),
})

const vatOptions = [
  { value: 1, label: 'No Gravado', percent: 0 },
  { value: 2, label: 'Exento', percent: 0 },
  { value: 3, label: '0%', percent: 0 },
  { value: 9, label: '2.5%', percent: 2.5 },
  { value: 8, label: '5%', percent: 5 },
  { value: 4, label: '10.5%', percent: 10.5 },
  { value: 5, label: '21%', percent: 21 },
  { value: 6, label: '27%', percent: 27 }
]

export {
  createInitialValues,
  createSchema,
  vatOptions
}

