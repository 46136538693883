import React from 'react'
import { useFormikContext } from 'formik'

import { InputBS } from 'components'
import { Table } from 'react-bootstrap'
import { currencyFormat } from 'utilities'
import './article.css'

const TotalSummaryTable = ({ editing = false }) => {
  const { values, setFieldValue } = useFormikContext()
//   console.log(values)
  return (
    <div className='row mb-8'>
        <div className="col-8"></div>

        <div className="col-4">

        <Table striped bordered hover>
            <thead>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={3} className='fw-bold p-2'>Subtotal</td>
                    <td className='text-end p-2'>{ currencyFormat.format(values?.subtotal)}</td>
                </tr>
                <tr>
                    <td colSpan={3} className='fw-bold p-2'>IVA</td>
                    <td className='text-end p-2'>{ currencyFormat.format(values?.iva)}</td>
                </tr>
                <tr>
                    <td colSpan={3} className='fw-bold p-2'>Rentabilidad</td>
                    <td className='text-end p-2'>{ values?.total_profitability_rate? parseFloat(values.total_profitability_rate).toFixed(2) + '%' : ''}</td>
                </tr>
                <tr>
                    <td colSpan={3} className='p-2'><span className='fw-bold'>IIBB</span><span className='fst-italic ms-3'>{values.address_id_traza?'':'El pedido no tiene dirección cargada'}</span></td>
                    <td className='text-end p-2'>{values.iibb?.total ? '$'+values.iibb.total : ''}</td>
                </tr>
                {
                    // values.iibb && values.iibb.percepciones?
                    // values.iibb.percepciones.map((val, idx)=>(
                    //     <tr key={idx} className='p-0 m-0 no-striping'  style={{ backgroundColor: '#f0f0f0' }} >
                    //         <td className='text-start p-1' ><span>{val.provincia}</span></td>
                    //         <td className='text-end p-1' >{val.porcentaje}%</td>
                    //         <td className='text-end p-1' >${val.percepcion}</td>
                    //         <td className='text-end p-1' ></td>
                    //     </tr>
                    // ))
                    values.iibb && values.iibb.percepcionesAfipRequest?
                    values.iibb.percepcionesAfipRequest.map((val, idx)=>(
                        <tr key={idx} className='p-0 m-0 no-striping'  style={{ backgroundColor: '#f0f0f0' }} >
                            <td className='text-start p-1' ><span className='ps-4'>{val.descripcion}</span></td>
                            <td className='text-end p-1' >${val.importe}</td>
                            <td className='text-end p-1' ></td>
                        </tr>
                    ))
                    :
                    <></>
                }
                <tr>
                    <td colSpan={3} className='fw-bold p-2'>Total</td>
                    <td className='text-end p-2 fw-bold'>{ currencyFormat.format(values?.total_calc)}</td>
                </tr>
            </tbody>
        </Table>

        </div>


    </div>
  )
}

export default TotalSummaryTable