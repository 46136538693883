import { useState, useEffect, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { PageTitle } from '_metronic/layout/core'
import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { useSellers } from 'hooks'
import { useGetColumns } from './constants'
import routes from 'app/routing/routes'

const Sellers = () => {
  const { handleGetSellers, handleDeleteSeller } = useSellers()
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const intl = useIntl()

  const deleteSeller = useCallback(async (id) => {
    try {
      let response = await handleDeleteSeller(id, 'token')
      toast.info(response.message)

      response = await handleGetSellers()
      setData(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleDeleteSeller, handleGetSellers])

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <>
          <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>Editar vendedor(a)</Tooltip>}>
            <Link to={`${routes.SELLERS}/${row.id}`}>
              <i className="bi bi-pencil-square text-primary fs-3" style={{ cursor: 'pointer' }} />
            </Link>
          </OverlayTrigger>

          <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>Eliminar vendedor(a)</Tooltip>}>
            <span onClick={() => deleteSeller(row.id)}>
              <i className="bi bi-trash3 text-danger fs-3 ms-4" style={{ cursor: 'pointer' }} />
            </span>
          </OverlayTrigger>
        </>
      ),
    },
  ], [COLUMNS, deleteSeller])

  useEffect(() => {
    const getSellers = async () => {
      try {
        const response = await handleGetSellers()
        setData(response.data.result)
      } catch (error) {
        toast.error(error.message)
      }
    }

    getSellers()
  }, [handleGetSellers])

  useEffect(() => {}, [data])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Maestros/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Vendedores', path: routes.SELLERS, isActive: false },
        ]}
        description='Listado de vendedores'
      />
      <TableContainer title={intl.formatMessage({ id: 'TITLE.SELLERS' })} goTo={routes.SELLERS_NEW}>
        <CustomTable columns={columns} data={data} />
      </TableContainer>
    </>
  )
}

export default Sellers