import * as Yup from 'yup'

const googleAddress = {
  manual: false,
  place_id: '',
  route: '',
  street_number: '',
  floor: '',
  sublocality_level_1: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  locality: '',
  postal_code: '',
  postal_code_suffix: '',
  country: '',
  latitude: 0,
  longitude: 0,
  google_result: '',
  origin: '',
  formatted_address: '',
}

const contact = {
  name: '',
  email: '',
  has_notifications: false,
  phone: '',
  mobile: '',
  observations: '',
}

const initialValues = {
  name: '',
  is_ours: false,
  address: googleAddress,
  has_traceability: false,
  agent_type_id: '',
  gln: '',
  integrates_verifarma: false,
  contact: contact,
  observations: '',
}

const schema = Yup.object().shape({
  name: Yup.string().required('* Ingrese un nombre para la dirección'),
  observations: Yup.string(),
  has_traceability: Yup.boolean().default(false),
  agent_type_id: Yup.string().when(['has_traceability'], {
    is: (has_traceability) => Boolean(has_traceability),
    then: (schema) => schema.required('* Seleccione un tipo de agente').nonNullable(),
    otherwise: (schema) => schema,
  }),
  gln: Yup.string().when(['has_traceability'], {
    is: (has_traceability) => Boolean(has_traceability),
    then: (schema) => schema.required('* Indique el GLN').nonNullable(),
    otherwise: (schema) => schema,
  }),
  integrates_verifarma: Yup.boolean().default(false),
})

const dataAdapter = (data) => {
  let values = {
    ...data,
    address: {
      ...data.address,
      formatted_address: Boolean(data.address.manual) ? `${data.address.route} ${data.address.street_number} ${data.address.floor}, ${data.address.postal_code}, ${data.address.locality}, ${data.address.administrative_area_level_2}, ${data.address.administrative_area_level_1}` : data.address.formatted_address,
      origin: Boolean(data.address.manual) ? 'MANUAL' : data.address.origin,
    }
  }

  return values
}

export {
  initialValues,
  schema,
  dataAdapter,
}