import service from "../service"

const PATH = {
  homeDeliveryOptions: "/home-delivery-options",
};

export class HomeDeliveryOptions {
  static getHomeDeliveryOptions = async (token) => {
    const response = await service.get(PATH.homeDeliveryOptions)

    return response
  }

  static getHomeDeliveryOption = async (id, token) => {
    const response = await service.get(`${PATH.homeDeliveryOptions}/${id}`)

    return response
  }
}