import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'

import { useOutOrders } from 'hooks/Sales'
import { Input, SelectField } from 'components'
import { Button } from 'react-bootstrap'

import { OutOrderStatusI } from 'interfaces'

const todosMenosParciales = (status) => {
  return [
    ...status.filter(s => s.id != OutOrderStatusI.RESERVA_PARCIAL).map(s => s.id) // 12: Reserva parcial
  ]
}

const initialValues = {
  id_text: '',
  from_date: '',
  to_date: '',
  out_order_status_id_filter: [OutOrderStatusI.PENDIENTE_PREPARACION],
  nombre: '',
  documento: '',
  has_refrigeration: '',
  article_text: '',
  paciente: '',
  paciente_documento: '',
  direccion: '',
  localidad: '',
  provincia: '',
  amount_min: '',
}

const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.PENDIENTE_PREPARACION:
    case OutOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case OutOrderStatusI.EN_PREPARACION:
    case OutOrderStatusI.EN_DESPACHO:
    case OutOrderStatusI.EN_DISTRIBUCION:
      return "estado-azul";
    // case OutOrderStatusI.EN_PREPARACION:
    //   return "estado-gris";
    case OutOrderStatusI.CANCELADO:
      return "estado-rojo";
    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case OutOrderStatusI.ENTREGADA:
      return "estado-verde";
    case OutOrderStatusI.EN_REVISION:
    case OutOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    default:
      return ""
  }
}

const Filter = ({  setData, query, setQuery, setTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()

  const [formikInitialValues, setFormikInitialValues] = useState(initialValues)

  const { loading, handleGetOutOrders, handleGetOutOrderStatus } = useOutOrders()
  const [status, setStatus] = useState([])

  const filterData = async (values) => {
    let params = {
      ...values,
      ...query,
      id_text: values?.id_text ? values.id_text : undefined,
      from_date: values?.from_date ? values.from_date : undefined,
      to_date: values?.to_date ? values.to_date : undefined,
      out_order_status_id_filter: values?.out_order_status_id_filter && values.out_order_status_id_filter.length > 0 ? values.out_order_status_id_filter : todosMenosParciales(status),
      nombre: values?.nombre ? values.nombre : undefined,
      documento: values?.documento ? values.documento : undefined,
      has_refrigeration: values?.has_refrigeration ? values.has_refrigeration : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      paciente: values?.paciente ? values.paciente : undefined,
      paciente_documento: values?.paciente_documento ? values.paciente_documento : undefined,
      direccion: values.direccion ? values.direccion : undefined,
      localidad: values?.localidad ? values.localidad : undefined,
      provincia: values?.provincia ? values.provincia : undefined,
      amount_min: values?.amount_min ? values.amount_min : undefined,
      getDetail: true
    }

    try {
      const response = await handleGetOutOrders(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.out_order_status_id)
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        out_orders: { ...params },
      }))

      setQuery({ ...params, pageNumber: 1, pageSize: 10 })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  const getOutOrderStatus = useCallback(async () => {
    try {
      let params = {}
      const response = await handleGetOutOrderStatus(params)

      const statuses = response.data.result.filter(s => s.id != OutOrderStatusI.RESERVA_PARCIAL).map(s => {
        return {
          id: s.id,
          label: s.label,
          default_status: s.id === OutOrderStatusI.PENDIENTE_PREPARACION
        }
      })

      setStatus(statuses)
    } catch (error) {
      setStatus([])
      toast.error(error.message)
    }
  }, [handleGetOutOrderStatus])

  useEffect(() => {
    getOutOrderStatus();
  }, [getOutOrderStatus])

  useEffect(() => {
    if (currentUser?.user_id) {
      setFormikInitialValues({
        ...initialValues,
        id_text: currentUser?.out_orders?.id_text || '',
        from_date: currentUser?.out_orders?.from_date || '',
        to_date: currentUser?.out_orders?.to_date || '',
        out_order_status_id_filter: [OutOrderStatusI.PENDIENTE_PREPARACION],
        nombre: currentUser?.out_orders?.nombre || '',
        documento: currentUser?.out_orders?.documento || '',
        has_refrigeration: currentUser?.out_orders?.has_refrigeration || '',
        article_text: currentUser?.out_orders?.article_text || '',
        paciente: currentUser?.out_orders?.paciente || '',
        paciente_documento: currentUser?.out_orders?.paciente_documento || '',
        direccion: currentUser?.out_orders?.direccion || '',
        localidad: currentUser?.out_orders?.localidad || '',
        provincia: currentUser?.out_orders?.provincia || '',
        amount_min: currentUser?.out_orders?.amount_min || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' disabled={loading} />
              <p className='text-muted'>Nro. / ID Pedido</p>
            </div>

            <div className='col-2'>
              <Input id='from_date' name='from_date' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. entrega</p>
            </div>

            <div className='col-2'>
              <Input id='to_date' name='to_date' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. entrega</p>
            </div>

            <div className='col-6'>
              <SelectField
                id='out_order_status_id_filter'
                name='out_order_status_id_filter'
                options={[...status.map(item => { return { value: item.id, label: item.label } })]}
                isMulti
                marginTop={'-20px'}
                placeholder='Seleccione un estado'
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='nombre' name='nombre' disabled={loading} />
              <p className='text-muted'>ID / Cliente</p>
            </div>

            <div className='col-4'>
              <Input id='documento' name='documento' disabled={loading} />
              <p className='text-muted'>Documento</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='has_refrigeration'
                name='has_refrigeration'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Si' },
                  { value: '0', label: 'No' },
                ]}
                marginTop={'-20px'}
                placeholder='Seleccionar'
                disabled={loading}
              />
              <p className='text-muted'>Refrigeración</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>ID / Artículo / Droga</p>
            </div>

            <div className='col-4'>
              <Input id='paciente' name='paciente' disabled={loading} />
              <p className='text-muted'>ID / Paciente</p>
            </div>

            <div className='col-2'>
              <Input id='paciente_documento' name='paciente_documento' disabled={loading} />
              <p className='text-muted'>Documento / Nro. afiliado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='direccion' name='direccion' disabled={loading} />
              <p className='text-muted'>Nombre / Domicilio</p>
            </div>

            <div className='col-4'>
              <Input id='localidad' name='localidad' disabled={loading} />
              <p className='text-muted'>Localidad</p>
            </div>

            <div className='col-4'>
              <Input id='provincia' name='provincia' disabled={loading} />
              <p className='text-muted'>Provincia</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <Input
                id='amount_min'
                name='amount_min'
                onChange={(e) => setFieldValue('amount_min', e.target.value.replace(/\D/g, ''))}
                disabled={loading}
              />
              <p className='text-muted'>Monto mayor a</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm()
                  submitForm()
                }}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button  variant="primary" type='submit' disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter