import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AccountBoxOutlined, AssignmentOutlined, Inventory2Outlined } from '@mui/icons-material'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useSalesOrders } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer, TabsContainer } from 'containers'

import { Actions, GeneralTab, ArticlesTab, AddresseeTab } from './partials'
import { initialValues, schema, dataAdapter, updateDataAdapter } from './constants'

import routes from 'app/routing/routes'

const SalesOrderDetail = () => {
  const { id } = useParams()
  const intl = useIntl()

  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { currentUser } = useAuth()
  const { loading, handleGetSalesOrder, handleUpdateSalesOrder } = useSalesOrders()

  const [salesOrder, setSalesOrder] = useState(initialValues)
  const [editing, setEditing] = useState(false)

  const getSalesOrder = useCallback(async () => {
    try {
      const response = await handleGetSalesOrder(id, { getDetail: true }, 'token')

      const data = dataAdapter({ ...initialValues, ...response.data.result })
      setSalesOrder(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetSalesOrder])

  const handleUpdate = async (values) => {
    try {
      const data = updateDataAdapter({
        ...values,
        updated_by_user_id: currentUser?.id ? currentUser.id : currentUser?.user?.id,
      })

      if(!data.address_id_traza){
        toast.error("El domicilio de entrega es obligatorio")
        return
      }

      const response = await handleUpdateSalesOrder(id, data, 'token')
      toast.success("Orden actualizadda correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      setEditing(false)
      getSalesOrder()
    }
  }

  useEffect(() => {
    getSalesOrder()
  }, [getSalesOrder])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Ventas/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Pedidos/', path: routes.SALES_ORDERS, isSeparator: false, isActive: true },
          { title: `Pedido de egreso #${id}`, path: `${routes.OUT_REQUEST}/${id}`, isSeparator: false, isActive: true },
        ]}
        description={`Pedido de egreso #${id}`}
      />

      <DetailContainer
        title={`Pedido de egreso #${id}`}
        status={salesOrder.sales_order_status_label}
        buttons={<Actions values={salesOrder} editing={editing} handleEditing={setEditing} submitRef={submitRef} cancelRef={cancelRef} handleGetSalesOrder={getSalesOrder} />}
      >
        <Formik
          initialValues={salesOrder}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            try {
              handleUpdate(values)
            } catch (error) {
              actions.handleReset()
            }
          }}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column' encType='multipart/form-data'>
                <TabsContainer
                  initialValue='general'
                  tabs={[
                    { value: 'general', label: intl.formatMessage({ id: 'TABS.SALES_ORDERS.GENERAL' }), icon: <AssignmentOutlined />, show: true },
                    { value: 'articles', label: intl.formatMessage({ id: 'TABS.SALES_ORDERS.ARTICLES' }), icon: <Inventory2Outlined />, show: true },
                    { value: 'addressee', label: intl.formatMessage({ id: 'TABS.SALES_ORDERS.ADDRESSEE' }), icon: <AccountBoxOutlined />, show: true }
                  ]}
                  tabsContent={[
                    <GeneralTab editing={editing} />,
                    <ArticlesTab editing={editing} handleEditing={setEditing} handleGetSalesOrder={getSalesOrder} />,
                    <AddresseeTab editing={editing} handleGetSalesOrder={getSalesOrder} />,
                  ]}
                />

                {editing && (
                  <div className='d-flex align-items-center justify-content-end'>
                    <Button
                      ref={cancelRef}
                      variant='secondary'
                      type='button'
                      className='me-3'
                      onClick={() => {
                        setEditing(false);
                        handleReset();
                      }}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default SalesOrderDetail