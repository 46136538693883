import { useState, useCallback, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useArticles } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { MovementsModalFilter } from '.'
import { COLUMNS } from './columns'
import { formatInputDate } from 'utilities'

const today = new Date();
const monthAgo = new Date();
monthAgo.setMonth(today.getMonth() - 1);

const initialObQuery = {
  pageNumber: "1",
  pageSize: "7",
  date_from: formatInputDate(monthAgo.toISOString()),
  date_to: formatInputDate(today.toISOString()),
}

const MovementsModal = ({ show, onHide, article }) => {
  const { loading, handleGetMovements } = useArticles()
  const [movements, setMovements] = useState([])

  const [title, setTitle] = useState("Stock de Artículos");
  const [filter, setFilter] = useState("");
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0);

  const getMovements = async () => {
    if (show) {
      try {
        let params = {
        }

        if (queryMD && queryMD.pageNumber) {
          params = {
            ...params,
            ...queryMD,
          }
        }

        const response = await handleGetMovements(article?.id, params)

        if (response.data) {
          const data = response.data.result
          setMovements(data)
          setTotalCount(response.data.metadata.count)
        }
      } catch (error) {
        setMovements([])
        toast.error(error.message)
      }
    }
  }

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD
  };

  useEffect(() => {
    getMovements()
  }, [filter, queryMD])

  if (!show) return null

  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static' scrollable style={{ height: '100vh' }}>
      <Modal.Header closeButton>
        <Modal.Title>Movimientos de #{article.id} - {article.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ height: '100vh' }}>
        <TableContainer
          filter={<MovementsModalFilter setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} setTitle={setTitle} loading={loading} />}
        >
          <CustomTable
            columns={COLUMNS}
            data={movements || []}
            paginationOptions={paginationOptions}
          />
        </TableContainer>
      </Modal.Body>
    </Modal>
  )
}

export default MovementsModal