import service from "../service";
import { PaymentConditionI, CreatePaymentConditionI, UpdatePaymentConditionI } from "interfaces";

const PATH = {
  paymentConditions: "/payment-conditions",
};

export class PaymentConditions {
  static getPaymentConditions = async (params) => {
    const response = await service.get(PATH.paymentConditions, {
      params,
    });

    return response;
  };

  static getPaymentCondition = async (id, token) => {
    const response = await service.get(`${PATH.paymentConditions}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static createPaymentCondition = async (data, token) => {
    const response = await service.post(PATH.paymentConditions, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }

  static updatePaymentCondition = async (id, data, token) => {
    const response = await service.patch(`${PATH.paymentConditions}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }

  static deletePaymentCondition = async (id, token) => {
    const response = await service.delete(`${PATH.paymentConditions}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }
}