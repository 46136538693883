import { useState, useCallback, useEffect } from 'react';

import { Notifications } from '../services';
import { useAuth } from 'app/modules/auth';
import { socket } from '../socket';


const initialQueryString = {
  filter:"",
  metadata:{
    pageNumber:"1",
    pageSize:"10"
  }
}

const useNotifications = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);
  const [messageReceived, setMessageReceived] = useState("");
  const {currentUser} = useAuth();
  const [isJoined, setIsJoined] = useState(false);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [notificationEvents, setNotificationEvents] = useState([]);


  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);
  
  const handleGetNotifications = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);

      const response = await Notifications.getNotificationsAndClean();

      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetUnreadedNotifications = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);

      const response = await Notifications.getUnreadedNotifications();

      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);
  
  const handleGetNotificationsAndClean = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);

      const response = await Notifications.getNotificationsAndClean();

      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {

    if(!isConnected){
      socket.connect();
      socket.emit("join room", {currentUser});
    }

    function onConnect() {
      console.log("Socket Connected")
      setIsConnected(true);
    }

    function onDisconnect() {
      console.log("Socket Disconnected")
      setIsConnected(false);
    }

    function onNotificationEvent(value) {
      console.log("Socket notification")
      setNotificationEvents(previous => [...previous, value]);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('notification', onNotificationEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('notification', onNotificationEvent);
    };
  }, []);

  return {
    loading,
    queryString,
    setQueryString,
    handleGetNotifications,
    handleGetNotificationsAndClean,
    handleGetUnreadedNotifications,
    messageReceived,
    notificationEvents
  }
}

export default useNotifications;