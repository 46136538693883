import { useState, useCallback } from 'react'

import { PriceUpdate } from 'services'

const usePriceUpdate = () => {
  const [loading, setLoading] = useState(false)

  const handleGetPriceUpdates = useCallback(async () => {
    try {
      setLoading(true)
      const response = await PriceUpdate.getBillingTypes()

      return response
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, []);

  return {
    loading,
    handleGetPriceUpdates,
  };
};

export default usePriceUpdate