import * as Yup from 'yup'

const contactInitialValues = {
  contact: {
    name: '',
    email: '',
    has_notifications: false,
    phone: '',
    mobile_suffix: '',
    mobile: '',
    observations: '',
  }
}
const schema = Yup.object().shape({
  contact: Yup.object().shape({
    name: Yup.string().required('* Ingrese un nombre de contacto'),
    email: Yup.string().email('* Ingrese un email válido').nullable(),
    has_notifications: Yup.boolean().default(false),
    phone: Yup.string(),
    mobile_suffix: Yup.string().matches(/^(\+)?[0-9\s]+$/, '* El sufijo debe ser numérico y puede incluir el signo + al principio'),
    mobile: Yup.string().min(10, '* El número de celular debe tener al menos 10 dígitos').label('Celular'),
    observations: Yup.string(),
  }),
})

export {
  contactInitialValues,
  schema
}