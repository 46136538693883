import { useState, useEffect, useCallback } from 'react'

import { Alfabeta } from '../services';
import {AlfabetaAuxTableI} from '../interfaces'

interface UseAlfabetaReturn {
  loading: boolean;
  handleGetArticles: (params:any) => Promise<any>;
  handleGetArticle: (params:any) => Promise<any>;
  vias: AlfabetaAuxTableI[];
  sizes: AlfabetaAuxTableI[];
  potencies: AlfabetaAuxTableI[];
  actions: AlfabetaAuxTableI[];
  shapes: AlfabetaAuxTableI[];
  unitTypes: AlfabetaAuxTableI[];
  controls: AlfabetaAuxTableI[];
}

const useAlfabeta = (): UseAlfabetaReturn => {

  const [loading, setLoading] = useState(false);
  const [vias, setVias] = useState<AlfabetaAuxTableI[]>([]);
  const [sizes, setSizes] = useState<AlfabetaAuxTableI[]>([]);
  const [potencies, setPotencies] = useState<AlfabetaAuxTableI[]>([]);
  const [actions, setActions] = useState<AlfabetaAuxTableI[]>([]);
  const [shapes, setShapes] = useState<AlfabetaAuxTableI[]>([]);
  const [unitTypes, setUnitTypes] = useState<AlfabetaAuxTableI[]>([]);
  const [controls, setControls] = useState<AlfabetaAuxTableI[]>([]);

  const handleGetArticles = useCallback(async (params: any) => {
    try {
      setLoading(true);
      const response = await Alfabeta.getArticles(params);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetArticle = useCallback(async (params: any) => {
    try {
      setLoading(true);
      const response = await Alfabeta.getArticle(params);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const getVias = async () => {
    try {
      const response = await Alfabeta.getVias();
      setVias(response.data?.result);
    } catch (error) {
      throw error;
    }
  }
  const getSizes = async () => {
    try {
      const response = await Alfabeta.getSizes();
      setSizes(response.data?.result);
    } catch (error) {
      throw error;
    }
  }
  const getPotencies = async () => {
    try {
      const response = await Alfabeta.getPotencies();
      setPotencies(response.data?.result);
    } catch (error) {
      throw error;
    }
  }

  const getActions = async () => {
    try {
      const response = await Alfabeta.getActions();
      setActions(response.data?.result);
    } catch (error) {
      throw error;
    }
  }
  
  const getShapes = async () => {
    try {
      const response = await Alfabeta.getShapes();
      setShapes(response.data?.result);
    } catch (error) {
      throw error;
    }
  }
  
  const getUnitTypes = async () => {
    try {
      const response = await Alfabeta.getUnitTypes();
      setUnitTypes(response.data?.result);
    } catch (error) {
      throw error;
    }
  }

  const getControls = async () => {
    try {
      const response = await Alfabeta.getControls();
      setControls(response.data?.result);
    } catch (error) {
      throw error;
    }
  }


  useEffect(()=>{
    getVias();
    getSizes();
    getPotencies();
    getActions();
    getShapes();
    getUnitTypes();
    getControls();
  }, [])

  return {
    loading,
    handleGetArticles,
    handleGetArticle,
    vias,
    sizes, 
    potencies,
    actions,
    shapes,
    unitTypes,
    controls
  };
};

export default useAlfabeta;