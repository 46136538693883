import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { AssignmentOutlined, AccountBoxOutlined } from '@mui/icons-material'

import { usePatients } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import { createInitialValues, createSchema, dataAdapter } from './constants'
import { GeneralTab, ContactTab } from './partials'

import routes from 'app/routing/routes'

const AddPatient = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const submitRef = useRef(null)

  const { loading, handleCreatePatient } = usePatients()

  const handleCreate = async (patient) => {
    if (!patient.documentation && !patient.health_insurance.affiliate_number) {
      toast.error('Debe completar el documento y/o nro. de afiliado del paciente')
      return
    }

    if (patient.documentation && !patient.documentation_type_id) {
      toast.error('Debe seleccionar un tipo de documento para el paciente')
      return
    }

    try {
      let data = dataAdapter(patient)
      const response = await handleCreatePatient(data)

      toast.success(response.message)
      navigate(`${routes.PATIENTS}/${response.id}`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <DetailContainer
        title={intl.formatMessage({ id: 'TITLE.PATIENTS.CREATE' })}
        buttons={
          <div className='d-flex align-items-center justify-content-end'>
            <Button className='me-3' variant='secondary' type='button' onClick={() => navigate(routes.PATIENTS)}><i className="ki-duotone ki-left" />Volver</Button>
            <Button variant='primary' type='button' onClick={() => submitRef.current.click()} disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
          </div>
        }
      >
        <Formik
          initialValues={createInitialValues}
          validationSchema={createSchema}
          onSubmit={handleCreate}
          enableReinitialize
        >
          {({ errors, touched }) => {
            return (
              <Form className="d-flex flex-column">
                <TabsContainer
                  initialValue='general'
                  tabs={[
                    { value: 'general', label: intl.formatMessage({ id: 'TABS.PATIENTS.GENERAL' }), icon: <AssignmentOutlined />, show: true},
                    { value: 'contact', label: intl.formatMessage({ id: 'TABS.PATIENTS.CONTACT' }), icon: <AccountBoxOutlined />, show: true},
                  ]}
                  tabsContent={[
                    <GeneralTab />,
                    <ContactTab />,
                  ]}
                />

                <div className='separator my-8'></div>

                <div className='d-flex flex-row justify-content-end'>
                  <div className='row'>
                    <div className='col'>
                      <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddPatient