import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, checkIsAllowed, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import { useAuth } from 'app/modules/auth'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  allowedRoles?:any
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  allowedRoles
}) => {
  const {currentUser} = useAuth()
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const [isAllowed, setIsAllowed] = useState(false)
  // const isAllowed = checkIsAllowed(currentUser?.payload?.roles || [], allowedRoles) 

  useEffect(()=>{
    if(currentUser){
      // let _roles = [];
      // if(currentUser.payload){
      //   _roles = currentUser.payload.roles || [];
      // }
      // else{
      //   _roles = currentUser.roles || [];
      // }
      const _roles = currentUser.roles || [];
      const _isAllowed = checkIsAllowed(_roles, allowedRoles);
      setIsAllowed(_isAllowed)
    }
  }, [currentUser])



  if(!isAllowed)
    return null;

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive} )} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
