import { AppBar, Tab, Tabs, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useFormikContext } from 'formik';
import GeneralForm from './GeneralForm';
import ImpuestosForm from './ImpuestosForm';
import MedicamentoForm from './MedicamentoForm';
import ComercialForm from './ComercialForm';

import '../../articles.css'

function TabContainer(props) {
    return (
      <Typography component="div" style={{ paddingTop: 8 * 3 }}>
        {props.children}
      </Typography>
    );
}
  
  // TabContainer.propTypes = {
  //   children: PropTypes.node.isRequired,
  // };

  // const useStyles = makeStyles(theme => ({
  //   root: {
  //     flexGrow: 1,
  //     width: '100%',
  //     backgroundColor: 'white'
  //   },
  //   noShadow: {
  //     boxShadow: 'none',
  //     backgroundColor: 'white'
  //   },
  // }));

const TabProductVar = ()=>{

    const { setFieldValue, values, setFieldTouched, validateForm, errors } = useFormikContext();
    const [showTabMedicamento, setShowTabMedicamento] = useState(true)
    const [currentTab, setCurrentTab] = useState("general");
    
    const handleTabChange = async (event, newValue) => {
      setCurrentTab(newValue)  
      // const errors = await validateForm();
      // // Hago las validaciones al hacer cambio de tab
      // if(currentTab=='general'){
      //   setFieldTouched('name', true)
      //   if (!Object.keys(errors).includes('name')) {
      //     setCurrentTab(newValue)
      //   } else {
      //     event.preventDefault();
      //   }
      // }
      // if(currentTab=='impuestos'){
      //   setFieldTouched('vat', true)
      //   setFieldTouched('purchase_vat', true)
      //   if (Object.keys(errors).some(elemento => ['vat', 'purchase_vat'].indexOf(elemento) !== -1)) {
      //     event.preventDefault();
      //   } else {
      //     setCurrentTab(newValue)          
      //   }
      // }
    };

    useEffect(()=>{
        if(values.product_type && values.product_type.value!=1){
            setCurrentTab("general")
            setShowTabMedicamento(false)
        }
        else{
          setShowTabMedicamento(true)
        }

        
    }, [values.product_type_id])

    return (
        <>
            <div className="" style={{minHeight:"400px"}}>
                <AppBar elevation={0} position="static" color="default" className="noShadow" >
                  <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      className="noShadow"
                  >
                      <Tab value="general" label="General" icon={<AssignmentOutlinedIcon />} disabled={!values.parent_product} />
                      <Tab value="impuestos" label="Impuestos" icon={<ManageSearchOutlinedIcon />} disabled={!values.parent_product}/>
                      <Tab value="comercial" label="Comercial" icon={<MonetizationOnOutlinedIcon />}  disabled={!values.parent_product} />
                      {showTabMedicamento && 
                        <Tab value="medicamento" label="Medicamento" icon={<MedicationOutlinedIcon />}  disabled={!values.parent_product}/>
                      }
                  </Tabs>
                </AppBar>
                  {currentTab === "general" && <TabContainer><GeneralForm /></TabContainer>}
                  {currentTab === "medicamento" && showTabMedicamento && <TabContainer><MedicamentoForm /></TabContainer>}
                  {currentTab === "impuestos" && <TabContainer><ImpuestosForm /></TabContainer>}
                  {currentTab === "comercial" && <TabContainer><ComercialForm /></TabContainer>}
            </div>
        </>
    )
}

export default TabProductVar
