import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useShipmentAddresses, useAgentTypes } from 'hooks'

import { AddressModal, ContactModal } from 'containers/Modals'
import { InputBS, SelectField, TextareaBS, FormTable } from 'components'

import { RoleI } from 'interfaces'

const GeneralTab = ({ editing, handleUpdate, handleGetShipmentAddress }) => {
  const { currentUser } = useAuth()

  const { values, setFieldValue } = useFormikContext()
  const intl = useIntl()

  const [addressModal, setAddressModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)

  const { handleUpdateShipmentAddress } = useShipmentAddresses()

  const { handleGetAgentTypes } = useAgentTypes()
  const [agentTypes, setAgentTypes] = useState([])

  const getAgentTypes = useCallback(async () => {
    try {
      const response = await handleGetAgentTypes()

      let agents = response.result
      setAgentTypes(agents)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetAgentTypes])

  useEffect(() => {
    getAgentTypes()
  }, [getAgentTypes])

  return (
    <>
      <div className='row mb-8'>
        <div className={`col-${currentUser.roles.find(r => r.id == RoleI.ADMIN) ? '10' : '12'}`}>
          <InputBS id='name' name='name' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.NAME' })} disabled={!editing} />
        </div>

        {currentUser.roles.find(r => r.id == RoleI.ADMIN) &&
          <div className='col-2'>
            <SelectField
              id='is_ours'
              name='is_ours'
              options={[ { value: true, label: 'SÍ' }, { value: false, label: 'NO' }]}
              label={'Nuestra'}
              disabled={!editing}
            />
          </div>
        }
      </div>

      <div className='separator my-8' />
        <div className='my-8'>
          <div className='d-flex justify-content-between align-items-center mb-8'>
            <label className='form-label m-0'>Datos de dirección</label>

            <div>
              <Button
                className='BSButton'
                variant='outline-primary'
                onClick={() => setAddressModal(true)}
              >
                {values?.address?.route !== '' ? 'Editar dirección' : 'Añadir dirección'}
              </Button>
            </div>
          </div>
        </div>

        <AddressModal
          show={addressModal}
          onClose={() => setAddressModal(false)}
          values={values}
          onSave={data => {
            setAddressModal(false)
            setFieldValue('address', data.address)
          }}
          submodal
        />

        {values?.address_id &&
          <FormTable
            columns={[
              { header: 'Calle' },
              { header: 'CP' },
              { header: 'Localidad' },
              { header: 'Comuna / Partido' },
              { header: 'Provincia' },
              { header: '', styles: 'text-center' },
            ]}
          >
            <tr>
              <td className='align-middle'>{values.address?.route} {values.address?.street_number}{values.address?.floor !== '' ? ` (${values.address.floor})` : ''}</td>
              <td className='align-middle'>{values.address?.postal_code}{values.address?.postal_code_suffix}</td>
              <td className='align-middle'>{values.address?.locality}</td>
              <td className='align-middle'>{values.address?.administrative_area_level_2}</td>
              <td className='align-middle'>{values.address?.administrative_area_level_1}</td>
              <td className='align-middle text-center'>
                <button type='button' className='btn btn-icon' onClick={() => setAddressModal(true)}>
                  <i className='bi bi-pencil-square text-primary'></i>
                </button>
              </td>
            </tr>
          </FormTable>
        }

      <div className='separator my-8'></div>
      <h1 className='my-8'>Trazabilidad</h1>

      <div className='row my-8'>
        <div className='col-2'>
          <SelectField
            id='has_traceability'
            name='has_traceability'
            options={[{ value: false, label: 'NO' }, { value: true, label: 'SÍ' }]}
            label={'Trazabilidad'}
            disabled={!editing}
          />
        </div>

        {Boolean(values.has_traceability) && (
          <>
            <div className='col-4'>
              <SelectField
                id='agent_type_id'
                name='agent_type_id'
                label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.TYPE' })}
                options={agentTypes.map(item => { return { value: item.id, label: item.name }})}
                placeholder='Seleccionar tipo de agente'
                disabled={!editing}
              />
            </div>

            <div className='col'>
              <InputBS id='gln' name='gln' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.GLN' })} disabled={!editing} />
            </div>

            {/* <div className='col-2'>
              <SelectField
                id='integrates_verifarma'
                name='integrates_verifarma'
                options={[ { value: true, label: 'SÍ' }, { value: false, label: 'NO' }]}
                label={'Verifarma'}
                disabled={!editing}
              />
            </div> */}
          </>
        )}
      </div>

      <div className='separator my-8' />
        <div className='my-8'>
          <div className='d-flex justify-content-between align-items-center mb-8'>
            <label className='form-label m-0'>Datos de contacto</label>

            <div>
              <Button className='BSButton' variant='outline-primary' type='button' onClick={() => setContactModal(true)} >{values.contact.name ? 'Editar datos de contacto' : 'Añadir datos de contacto'}</Button>
            </div>
          </div>
        </div>

        <ContactModal
          show={contactModal}
          onClose={() => setContactModal(false)}
          values={values}
          onSave={data => {
            setContactModal(false)
            setFieldValue('contact', data.contact)
          }}
          submodal
        />

        {values?.contact_id &&
          <FormTable
            columns={[
              { header: 'Nombre' },
              { header: 'Email' },
              { header: 'Notificaciones' },
              { header: 'Teléfono' },
              { header: 'Celular' },
              { header: '', styles: 'text-center' },
            ]}
          >
            <tr>
              <td className='align-middle'>{values.contact?.name !== '' ? values.contact.name : '-'}</td>
              <td className='align-middle'>{values.contact?.email !== '' ? values.contact.email : '-'}</td>
              <td className='align-middle'>
                {values.contact.has_notifications
                  ? <span className="badge badge-success">{intl.formatMessage({ id: 'OPTION.YES' })}</span>
                  : <span className="badge badge-danger">{intl.formatMessage({ id: 'OPTION.NO' })}</span>
                }
              </td>
              <td className='align-middle'>{values.contact.phone && values.contact.phone !== '' ? values.contact.phone : '-'}</td>
              <td className='align-middle'>{values.contact.mobile_suffix && values.contact.mobile_suffix !== '' ? `${values.contact.mobile_suffix} ` : ''}{values.contact.mobile !== '' ? values.contact.mobile : '-'}</td>
              <td className='align-middle text-center'>
                <button type='button' className='btn btn-icon' onClick={() => setContactModal(true)}>
                  <i className='bi bi-pencil-square text-primary'></i>
                </button>
              </td>
            </tr>
          </FormTable>
        }
      <div className='separator my-8' />

      <div className='row my-8'>
        <div className='col-12'>
          <TextareaBS id='observations' name='observations' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.OBSERVATIONS' })} disabled={!editing} />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-2'>
          <SelectField
            id='is_enabled'
            name='is_enabled'
            label='Habilitada'
            options={[{ value: true, label: 'SÍ' }, { value: false, label: 'NO' }]}
            disabled={!editing}
          />
        </div>
      </div>
    </>
  )
}

export default GeneralTab