import * as Yup from 'yup'

const initialValues = {
  id: 0,
  label: '',
  name: '',
  payment_method_id: '',
  days: 0,
  cancel_date: '',
  is_enabled: 0,
}

const schema = Yup.object().shape({
  id: Yup.number().required('Campo obligatorio'),
  label: Yup.string().required('* Ingrese un nombre para la condición de pago'),
  payment_method_id: Yup.number().required('* Seleccione un método de pago'),
  days: Yup.number().required('* Ingrese los días de la condición de pago'),
})

export {
  initialValues,
  schema
}