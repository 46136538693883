import { Link } from 'react-router-dom'
import moment from "moment"

export const defaultDetail = {
    id: null,
    product_var_id: null,
    in_order_id: null,
    qty: 10,
    gtin: "",
    lot_number: "",
    expiration_date: "",
    serial_number: "",
    purchase_value: null,
    cancel_date: "",
    updated_at: "",
    scan_code: "",
    storage_space_id: 1
}

export const useGetColumns = () => {

  const COLUMNS = [
    // {
    //   Header: 'Código',
    //   id: 'id',
    //   accessor: 'id',
    //   show: false
    // },
    {
      Header: "Cód.",
      accessor: 'product_var_id',
    },
    {
      Header: "Artículo",
      accessor: 'product_var_name',
    },
    {
      Header: "GTIN",
      accessor: 'gtin',
    },
    {
      Header: "Lote",
      accessor: 'lot_number',
    },
    {
      Header: "Vencimiento",
      accessor:  (row) => (row.expiration_date?moment(row.expiration_date).format("DD/MM/YYYY"):"")
    },
    {
      Header: "Depósito",
      accessor: 'storage_space_name'
    },
    { 
        Header: "Pedido",                
        accessor: 'qty_nuevo',/* Esta es la cantidad que se calcula para que quede solo en el primer row en caso que haya varios rows del mismo producto */
        
    },  
    { 
        Header: "Ingresado",                
        accessor: 'qty',/* Esta es la cantidad que se calcula para que quede solo en el primer row en caso que haya varios rows del mismo producto */
        
    },          
    { 
        Header: "Pendiente",                
        accessor: 'qty_pending'
    },
    // { 
    //     Header: "Anmat",                
    //     accessor: 'cant_anmat',
    // },

  ]

  return {
    COLUMNS
  }
}