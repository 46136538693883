import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useSalesOrders } from 'hooks'
import { Input, SelectField } from 'components'

import { SalesOrderStatusI } from 'interfaces'

import { labelColor } from 'pages/Sells/Orders/List'

const initialValues = {
  id_text: '',
  from_date: '',
  to_date: '',
  nombre: '',
  documento: '',
  paciente: '',
  paciente_documento: '',
  article_text: '',
  sales_order_status_id_filter: '',
}

const Filter = ({ setData, setFilter, queryMD, setQueryMD, setTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetSalesOrders } = useSalesOrders()

  const filterData = async (values) => {
    let params = {
      ...queryMD,
      ...values,
      id_text: values?.id_text ? values.id_text : undefined,
      nombre: values?.nombre ? values.nombre : undefined,
      documento: values?.documento ? values.documento : undefined,
      paciente: values?.paciente ? values.paciente : undefined,
      paciente_documento: values?.paciente_documento ? values.paciente_documento : undefined,
      from_date: values?.from_date ? values.from_date : undefined,
      to_date: values?.to_date ? values.to_date : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      sales_order_status_id_filter: values?.sales_order_status_id_filter ? [values.sales_order_status_id_filter] : [SalesOrderStatusI.FALTA_STOCK, SalesOrderStatusI.RESERVA_PARCIAL],
    }

    try {
      const response = await handleGetSalesOrders(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.sales_order_status_id)
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        activations: {
          ...prevCurrentUser?.activations,
          ...params,
          sales_order_status_id_filter: values?.sales_order_status_id_filter ? values.sales_order_status_id_filter : '',
        }
      }))

      setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser && currentUser?.user_id && currentUser?.activations) {
      setInitialFormikValues({
        ...initialValues,
        id_text: currentUser.activations?.id_text || '',
        nombre: currentUser.activations?.nombre || '',
        documento: currentUser.activations?.documento || '',
        paciente: currentUser.activations?.paciente || '',
        paciente_documento: currentUser.activations?.paciente_documento || '',
        from_date: currentUser.activations?.from_date || '',
        to_date: currentUser.activations?.to_date || '',
        article_text: currentUser.activations?.article_text || '',
        sales_order_status_id_filter: currentUser.activations?.sales_order_status_id_filter ? currentUser.activations.sales_order_status_id_filter : '',
      })
    }
  }, [currentUser])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' disabled={loading} />
              <p className='text-muted'>ID Pedido</p>
            </div>

            <div className='col-6'>
              <Input id='nombre' name='nombre' disabled={loading} />
              <p className='text-muted'>ID / Cliente</p>
            </div>

            <div className='col-4'>
              <Input id='documento' name='documento' disabled={loading} />
              <p className='text-muted'>Documento</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-5'>
              <Input id='paciente' name='paciente' disabled={loading} />
              <p className='text-muted'>ID / Paciente</p>
            </div>

            <div className='col-3'>
              <Input id='paciente_documento' name='paciente_documento' disabled={loading} />
              <p className='text-muted'>Documento / Nro. afiliado</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. creación</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. creación</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>Artículo / Droga</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='sales_order_status_id_filter'
                name='sales_order_status_id_filter'
                options={[
                  { value: '', label: 'Ambos' },
                  { value: SalesOrderStatusI.FALTA_STOCK, label: 'Falta de stock' },
                  { value: SalesOrderStatusI.RESERVA_PARCIAL, label: 'Reserva parcial' },
                ]}
                placeholder='Seleccione un estado'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter