import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useLogisticStock, useDepots } from 'hooks'

import { Input, SelectField, SwitchField } from 'components'

const optionsBusinessUnits = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Farma' },
  { value: 2, label: 'Shop' }
]

const initialObQuery = {
  pageNumber: "1",
  pageSize: "10"
}

const initialValues = {
  id_article: '',
  text: '',
  gtin: '',
  storage_space_id: '',
  customer_supplier_id: '',
  business_unit_id: '',
  traced: '',
  only_available: 0,
}

const Filter = ({ setData, setTotalCount, queryMD, setQueryMD }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetStockList } = useLogisticStock()

  const { handleGetStorageSpaces, handleGetStorageOwners } = useDepots()
  const [optionsStorageSpaces, setOptionStorageSpaces] = useState([])
  const [optionsOwners, setOptionOwners] = useState([])

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id_article: values?.id_article || undefined,
      text: values?.text || undefined,
      gtin: values?.gtin || undefined,
      storage_space_id: values?.storage_space_id || undefined,
      customer_supplier_id: values?.customer_supplier_id || undefined,
      business_unit_id: values?.business_unit_id || undefined,
      traced: values?.traced || undefined,
      only_available: values?.only_available || undefined,
      pageNumber: 1,
      pageSize: 10,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = filterData(values)
      const response = await handleGetStockList(params)
      const data = response.result

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        stock_management: { ...params }
      }))

      setQueryMD({ ...params })
      setData(data)
      setTotalCount(response.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  const loadOptions = useCallback(async () => {
    try {
      const _storage = await handleGetStorageSpaces()
      const _owners = await handleGetStorageOwners()

      _storage.unshift({ id: '', name: 'Todos' })
      _owners.unshift({ id: '', business_name: 'Todos' })

      setOptionStorageSpaces(_storage?.map(i => ({
        ...i,
        value: i.id,
        label: i.name
      })))

      setOptionOwners(_owners?.map(i => ({
        value: i.id,
        label: i.business_name
      })))
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetStorageSpaces, handleGetStorageOwners])

  const loadStorages = async (customerSupplierId) => {
    const _storage =  await handleGetStorageSpaces(customerSupplierId)

    setOptionStorageSpaces(_storage?.map(i => ({
      ...i,
      value: i.id,
      label: i.name
    })))
  }

  useEffect(() => {
    loadOptions()
  }, [loadOptions])

  useEffect(() => {
    if (currentUser?.user_id) {
      const { stock_management } = currentUser

      setInitialFormikValues({
        ...initialValues,
        id_article: stock_management?.id_article || '',
        text: stock_management?.text || '',
        gtin: stock_management?.gtin || '',
        storage_space_id: stock_management?.storage_space_id || '',
        customer_supplier_id: stock_management?.customer_supplier_id || '',
        business_unit_id: stock_management?.business_unit_id || '',
        traced: stock_management?.traced || '',
        only_available: stock_management?.only_available || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => {
        return (
          <Form>
            <div className='row'>
              <div className='col-2'>
                <Input id='id_article' name='id_article' disabled={loading} />
                <p className='text-muted'>ID Artículo</p>
              </div>

              <div className='col-6'>
                <Input id='text' name='text' disabled={loading} />
                <p className='text-muted'>Artículo o Ppio.Activo</p>
              </div>

              <div className='col-4'>
                <Input id='gtin' name='gtin' disabled={loading} />
                <p className='text-muted'>GTIN</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <SelectField
                  id='business_unit_id'
                  name='business_unit_id'
                  options={optionsBusinessUnits}
                  placeholder='Seleccione una opción'
                  marginTop={'-20px'}
                  disabled={loading}
                />
                <p className='text-muted'>Unidad de negocio</p>
              </div>

              <div className='col-4'>
                <SelectField
                  id='customer_supplier_id'
                  name='customer_supplier_id'
                  options={optionsOwners}
                  handleChange={e => {
                    setFieldValue('customer_supplier_id', e.value)
                    setFieldValue('storage_space_id', '')

                    if (e.value !== '') {
                      loadStorages(e.value)
                    } else {
                      loadOptions()
                    }
                  }}
                  placeholder='Seleccione un dueño'
                  marginTop={'-20px'}
                  disabled={loading}
                />
                <p className='text-muted'>Dueño</p>
              </div>

              <div className='col-4'>
                <SelectField
                  id='storage_space_id'
                  name='storage_space_id'
                  options={optionsStorageSpaces}
                  placeholder='Seleccione una opción'
                  marginTop={'-20px'}
                  disabled={loading}
                />
                <p className='text-muted'>Espacio Almacenamiento</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <SelectField
                  id='traced'
                  name='traced'
                  options={[
                    { value: '', label: 'Todos' },
                    { value: '0', label: 'No' },
                    { value: '1', label: 'Sí' },
                  ]}
                  placeholder='Seleccione una opción'
                  marginTop={'-20px'}
                  disabled={loading}
                />
                <p className='text-muted'>Trazable</p>
              </div>

              <div className='col-4'>
                <div className="d-flex mt-3">
                  <SwitchField
                    id='only_available'
                    name='only_available'
                    disabled={loading}
                  />
                  <span style={{ fontSize: '1.1rem' }}>Con stock</span>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-end'>
                <Button
                  type='button'
                  variant='secondary'
                  onClick={() => {
                    resetForm({ values: initialValues })
                    submitForm()
                  }}
                  className='me-3'
                  disabled={loading}
                >
                  <i className='bi bi-zoom-out mb-1 ' />
                  Limpiar
                </Button>

                <Button  variant="primary" type='submit' disabled={loading}>
                  <i className="bi bi-search mb-1" />
                  Buscar
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter