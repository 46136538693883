import { useState, useMemo, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Dropdown, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useClickOutside, useLogisticStock, useStock } from 'hooks'

import { DetailContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'


import useGetColumns from './columns'
import { ArticleScanModal } from './ArticleScanModal'
import { ArticleLotNumberModal } from './ArticleLotNumberModal'

import { DepotMovementStatusI } from 'interfaces'
import '../styles.css'

const initialObQuery = {
  pageNumber: "1",
  pageSize: "10"
}

const LogisticStockMovementDetail = () => {
  const { id } = useParams()
  const [movement, setMovement] = useState({})
  const [stockReserved, setStockReserved] = useState('')
  const { COLUMNS } = useGetColumns()
  const { handleGetInternalMovement, loading } = useLogisticStock()
  const {handleCreateDepotMovementDetail, handleConfirmDepotMovement, handleDeleteInternalMovementDetail, handlePrintInternalWaybill} = useStock()
  const navigate = useNavigate();
  const [showLotNumberModal, setShowLotNumberModal] = useState(false)
  const [showScanModal, setShowScanModal] = useState(false)

  const getStockDetail = async () => {
    try {
      if (id) {
        const response = await handleGetInternalMovement(id)

        if (response.result) {
          const data = response.result
          setMovement(data)
          // setTotalCount(response.metadata.count);
        }
      }
    } catch (error) {
      setMovement([])
      toast.error(error.message)
      console.log(error)
    }
  }

  const onCloseScanModal = async(data)=>{
    try{
      // if (!data) { return }

      console.log(data)
      // const transfer = {
      //   qty: +data.qty,
      //   lot_number: data.lot_number,
      //   // expiration_date: data.expiration_date,
      //   gtin: data.gtin,
      //   product_var_id: data.product_var_id,
      //   movement_id:movement.id
      // }

      // console.log(transfer)
      // const resp = await handleCreateDepotMovementDetail(transfer)
      getStockDetail()
      // toast.success("Registro actualizado correctamente", { theme: "colored" })
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    } finally {
      setShowScanModal(false)
    }
  }

  const onCloseLotNumberModal = async (data) => {
    try{

      if (!data) { return }

      const transfer = {
        qty: +data.qty,
        lot_number: data.lot_number,
        // expiration_date: data.expiration_date,
        gtin: data.gtin,
        product_var_id: data.product_var_id,
        movement_id:movement.id,
        serial_number:data.serial_number
      }
      const resp = await handleCreateDepotMovementDetail(transfer)
      getStockDetail()
      toast.success("Registro actualizado correctamente", { theme: "colored" })
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    } finally {
      setShowLotNumberModal(false)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    actions.push({
      label: "Ver detalle",
      icon: "bi bi-eye text-primary",
      action: ()=>{}
    })

    actions.push({
      label: "Descargar remito",
      icon: "bi bi-download text-warning",
      action: ()=>{}
    })

    return actions
  }

  const deleteInternalMovementDetail = async (row) => {
    console.log(row)

    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: "¿Confirma la eliminación del registro?",
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, eliminar registro",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        // pastel
        const { product_var_id, lot_number } = row;
        const resp = await handleDeleteInternalMovementDetail({ id, product_var_id, lot_number })

        getStockDetail()
        toast.success("Registro eliminado correctamente", { theme: "colored" })
      }
    } catch (err) {
      toast.error(err.message, {theme: "colored"})
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => 
        { movement?.depot_movement_status_id==1 &&  
          (<OverlayTrigger overlay={<Tooltip>Eliminar movimiento</Tooltip>}>
            <i className="bi bi-trash text-danger" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={() => deleteInternalMovementDetail(row)} />
          </OverlayTrigger>
          )
        }
    }
  ], [COLUMNS])

  const handleConfirm = async()=>{
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `Confirma el movimiento interno?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, confirmar`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleConfirmDepotMovement(movement.id)
        toast.success("Movimiento guardado correctamente", {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getStockDetail()
    }

  }

  const printInternalWaybill = async()=>{
    try {
      if (movement) {

        let copias=2
        const response = await handlePrintInternalWaybill(movement.id, copias)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('Remito descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `remito-${movement.id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)

        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const paginationOptions = {
    // totalSize: totalCount,
    // obQuery:queryMD ,
    // setObQuery:setQueryMD
  };

  useEffect(() => {
    getStockDetail()
  }, [id])

  useEffect(()=>{
    helix.register()
  }, [])

  if (loading) {
    return <div className='text-center'>
      <l-helix color="var(--bs-primary)" style={{marginTop:"200px"}}></l-helix>
    </div>
  }

  return (
    <DetailContainer
      title={`Detalle del movimiento de stock #${id}`}
      buttons={
        <div className='d-flex pt-5'>
          <Button variant="secondary" type="button" className='me-3' style={{ height:"38px" }} onClick={() => navigate(-1)}>
            <i className="ki-duotone ki-left" />
            Volver
          </Button>

          {(movement.remito_id!=null || (movement.depot_movement_status_id === DepotMovementStatusI.BORRADOR && movement.detail?.length > 0)) &&
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">Acciones</Dropdown.Toggle>

              <Dropdown.Menu align={'end'}>
                {movement.depot_movement_status_id === DepotMovementStatusI.BORRADOR && movement.detail?.length > 0 &&
                  <Dropdown.Item href="" onClick={handleConfirm}>
                    <i className="bi bi-arrow-left-right text-primary me-2" />
                    Confirmar movimiento
                  </Dropdown.Item>
                }

                {movement.remito_id!=null &&  <Dropdown.Item href="" onClick={printInternalWaybill}>
                  <i className="bi bi-printer text-warning me-2" />
                  Descargar remito
                </Dropdown.Item>}

              </Dropdown.Menu>
            </Dropdown>
          }
        </div>
      }
    >
      <>
        <ArticleScanModal show={showScanModal} onHide={onCloseScanModal} movement_id={id} getStockDetail={getStockDetail}/>

        <ArticleLotNumberModal  show={showLotNumberModal} onHide={onCloseLotNumberModal}  movement={movement}/>

        <div className="row">
          <div className="col">
            <Table className='mt-4'>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Unidad de Negocio:</td>
                  <td style={{}} className='p-1'>{movement?.storage_space_origin?.business_unit_name}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Dueño:</td>
                  <td className='p-1'>{movement?.storage_space_origin?.customer_supplier_business_name}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Origen:</td>
                  <td className='p-1'>{movement?.storage_space_origin?.name}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Destino:</td>
                  <td className='p-1'>{movement?.storage_space_dest?.name}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Observaciones:</td>
                  <td className='p-1'>{movement?.observations}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        {movement.depot_movement_status_id === DepotMovementStatusI.BORRADOR
          ?
            <div className="row mb-8">
              <div className="col justify-content-end mt-5 d-flex">
                <Button variant='primary' onClick={() => setShowScanModal(true)} className='me-2'>
                  <i className='bi bi-qr-code-scan me-2 mb-1' />
                  Pick!
                </Button>

                <Button variant='primary' onClick={()=>setShowLotNumberModal(true)} className='me-2'>
                  <i className='bi bi-hand-index-thumb me-2 mb-1' />
                  Carga manual
                </Button>
              </div>
            </div>
          : <></>
        }

        <CustomTable columns={columns} data={movement.detail || []} paginationOptions={paginationOptions}  handleContextMenu={handleContextMenu} />

        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
          ref={contextRef}
        />
      </>
    </DetailContainer>
  )
}

export default LogisticStockMovementDetail