import * as Yup from 'yup'
import { currentDate, currencyFormat } from 'utilities';
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const formikInitialValues = {
  id:null,
  supplier_id: '',
  distributor_id: null,
  payment_condition_id: '',
  issue_date: currentDate,
  estimated_in_date: '',
  notes: '',
  internal_notes:'',
  order_type_id:1,
  supplier_name:'',
  order_status_id:0,
  revocation_user_name:'',
  revocation_date:'',
  revocation_notes:'',
  detail:[],
  addArticle:()=>{},
  deleteArticle:()=>{},
  updateArticle:()=>{},
  con_distribuidor:false,
}

const purchaseSchema = Yup.object().shape({
  supplier_id: Yup.number().required('Seleccione un cliente/proveedor'),
  // distributor_id: Yup.number(),
  payment_condition_id: Yup.number().required('Seleccione una condición de pago'),
  issue_date: Yup.string(),
  estimated_in_date: Yup.string(),
  notes: Yup.string(),
  internal_notes: Yup.string()
});

const incomeSchema = Yup.object().shape({
  supplier_id: Yup.number().required('Seleccione un cliente/proveedor'),
  payment_condition_id: Yup.number().required('Seleccione una condición de pago'),
  issue_date: Yup.string(),
  estimated_in_date: Yup.string(),
  notes: Yup.string(),
  internal_notes: Yup.string()
});

const columnsPedidoCompra = [
  {
    Header: "Artículo",
    accessor: 'product_var_desc',
  },
  {
    Header: "PVP",
    id: 'actual_pvp',
    accessor: (row) => (
      <span>{currencyFormat.format(row.actual_pvp)}</span>
    )
  },
  {
    Header: "Precio base",
    id: 'psl',
    accessor: (row) => (
      <span>{currencyFormat.format(row.psl)}</span>
    )
  },
  {
    Header: '',
    id: 'alerts',
    className:'p-0',
    accessor: (row) => (
      <>
        {(+row.psl != +row.psl_original)
          ?
          <OverlayTrigger placement="left"
            overlay={
              <Tooltip id="tooltip">
                <div>Precio base distinto al PSL</div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Precio base</th>
                      <th>PSL</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>{parseFloat(row.psl).toFixed(2)}</td>
                        <td>{parseFloat(row.psl_original).toFixed(2)}</td>
                      </tr>
                  </tbody>
              </Table>
            </Tooltip>
          }
        >
          <span ><i className='bi bi-exclamation-triangle text-danger'></i></span>
        </OverlayTrigger>

          :
          <></>
        }
      </>
    )
  },
  {
    Header: "Bonificación",
    id: 'purchase_discount_extra',
    accessor: (row) => (
      <span>{row.purchase_discount_extra}</span>
    )
  },
  {
    Header: "Descuento pactado",
    accessor: 'purchase_discount_extra_2',
  },
  {
    Header: "Precio compra unitario",
    id: 'unit_purchase_price',
    accessor: (row) => (
      <span>{currencyFormat.format(row.unit_purchase_price)}</span>
    )
  },
  {
    Header: "Cantidad",
    id: 'qty',
    accessor: (row) => (
      <span>{row.qty}</span>
    )
  },
  {
    Header: "Subtotal",
    id: 'subtotal',
    accessor: (row) => (
      <span>{currencyFormat.format(row.subtotal)}</span>
    )
  },
  {
    Header: "IVA",
    id: 'vat',
    accessor: (row) => (
      <span>{currencyFormat.format(row.vat)}</span>
    )
  },
  {
    Header: "Total",
    id: 'total',
    accessor: (row) => (
      <span>{currencyFormat.format(row.total)}</span>
    )
  },
]

const columnsPedidoIngreso = [
  {
    Header: "Artículo",
    accessor: 'product_var_desc',
  },
  {
    Header: "PVP",
    id: 'actual_pvp',
    accessor: (row) => (
      <span>{currencyFormat.format(row.actual_pvp)}</span>
    )
  },
  {
    Header: "PSL",
    id: 'psl',
    accessor: (row) => (
      <span>{currencyFormat.format(row.psl)}</span>
    )
  },
  {
    Header: "Cantidad",
    id: 'qty',
    accessor: (row) => (
      <span>{row.qty}</span>
    )
  },
]

const vatOptions = [
  { value: 1, label: 'No Grav.', percent: 0 },
  { value: 2, label: 'Exento', percent: 0 },
  { value: 3, label: '0%', percent: 0 },
  { value: 9, label: '2.5%', percent: 2.5 },
  { value: 8, label: '5%', percent: 5 },
  { value: 4, label: '10.5%', percent: 10.5 },
  { value: 5, label: '21%', percent: 21 },
  { value: 6, label: '27%', percent: 27 }
]


export {
  formikInitialValues,
  purchaseSchema,
  incomeSchema,
  columnsPedidoCompra,
  columnsPedidoIngreso,
  vatOptions
}