import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useSalesOrders } from 'hooks'
import { SalesOrderStatusI } from 'interfaces'

const AuthModal = ({ auth = true, show, onClose, selectedRows, handleSelectedRows, handleGetSalesOrders }) => {
  const { loading, handleCreateOutOrder, handleUpdateSalesOrderStatus } = useSalesOrders()

  const multipleAuth = async () => {
    const resp = await Swal.fire({
      icon: "warning",
      title: 'Autorización múltiple de crédito',
      text: '¿Quiere autorizar todos los pedidos seleccionados?',
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, autorizar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    })

    if (resp.isConfirmed) {
      try {
        await Promise.all(selectedRows.map(async r => {
          await handleCreateOutOrder(r.PedidoId)
        }))

        toast.success('Pedidos autorizados correctamente')
        handleSelectedRows([])
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetSalesOrders()
        onClose()
      }
    }
  }

  const multipleUnauth = async () => {
    const inputValue = '';

    const resp = await Swal.fire({
      icon: "warning",
      title: 'Desautorización múltiple de rentabilidad',
      text: '¿Quiere desautorizar todos los pedidos seleccionados?',
      input:"textarea",
      inputValue,
      inputLabel: "Motivo",
      inputAttributes: {
        autocomplete:"off"
      },
      inputValidator: (value) => {
        if (!value || value.trim() == '') {
          return "Por favor, completar motivo de rechazo"
        }
      },
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, desautorizar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    })

    if (resp.isConfirmed) {
      try {
        await Promise.all(selectedRows.map(async r => {
          await handleUpdateSalesOrderStatus(r.PedidoId, SalesOrderStatusI.NO_AUTORIZADO, { observaciones: resp.value })
        }))

        toast.success('Pedidos no autorizados correctamente')
        handleSelectedRows([])
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetSalesOrders()
        onClose()
      }
    }
  }

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='md' backdrop='shadow' centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>{`${auth ? 'Autorización' : 'Desautorización'} múltiple de rentabilidad`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <ul className='list-style-none m-0 p-0'>
              {selectedRows.map(r => (
                <li key={r.PedidoId} className='mb-3'>Pedido #{r.PedidoId}</li>
              ))}
            </ul>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='row'>
          <div className='col text-end'>
            <Button type='button' variant='secondary' onClick={onClose} className='me-3' disabled={loading}>
              <i className='bi bi-slash-circle' />
              Cerrar
            </Button>

            <Button type='button' variant='primary' onClick={auth ? multipleAuth : multipleUnauth} disabled={loading}>
              <i className='bi bi-save me-2' />
              {auth ? 'Autorizar' : 'Desautorizar'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AuthModal