import { useState, useCallback } from 'react';

import { Types } from 'services';
import { TypeI, GetTypesI, GetTypeI } from 'interfaces';

interface UseTypesReturn {
  loading: boolean;
  handleGetTypes: (token: string) => Promise<GetTypesI>;
  handleGetType: (id: TypeI['id'], token: string) => Promise<GetTypeI>;
}

const useTypes = (): UseTypesReturn => {
  const [loading, setLoading] = useState(false);

  const handleGetTypes = useCallback(async (token: string) => {
    try {
      setLoading(true);
      const response = await Types.getTypes(token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetType = useCallback(async (id: TypeI['id'], token: string) => {
    try {
      setLoading(true);
      const response = await Types.getType(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    handleGetTypes,
    handleGetType
  };
};

export default useTypes;