import { useState, useCallback } from 'react'

import { BillingTypes } from 'services'

const useBillingTypes = () => {
  const [loading, setLoading] = useState(false)

  const handleGetBillingTypes = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await BillingTypes.getBillingTypes(params)

      return response
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, []);

  const handleGetBillingType = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await BillingTypes.getBillingType(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, []);

  return {
    loading,
    handleGetBillingTypes,
    handleGetBillingType,
  };
};

export default useBillingTypes