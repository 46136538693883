import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const ResponseAfipModal = ({ show, onHide, data }) => {
    const [showDetail, setShowDetail] = useState(false)

    const toggleDetail = ()=>{
        setShowDetail(!showDetail)
    }
    useEffect(()=>{
        if(data && data.status)
            setShowDetail(false)
        else
            setShowDetail(true)
    }, [show])

    return (
        <Modal show={show} onHide={onHide} backdrop='static' centered>
        <Modal.Header closeButton>
            <Modal.Title>Respuesta enviada por el servicio de AFIP</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                {data?.status && <div className="row">
                    <div className="col p-5">
                        <label className='form-label'><b>Status:</b>  {data?.status}</label>
                        <label className='form-label mt-3'><b>Mensaje:</b> {data?.message}</label>
                        <label className='form-label mt-5'><i className='bi bi-info-circle text-danger cursor-pointer' onClick={toggleDetail}></i> Ver detalle</label>
                    </div>
                </div>}
                <div className="row">
                    <div className="col">
                        {showDetail && <textarea className='form-control w-100' rows={5} disabled>{data ? JSON.stringify(data) : ''}</textarea>}
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={onHide}>Cerrar</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default ResponseAfipModal