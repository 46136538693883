import { useIntl } from 'react-intl'

import { useWindowsDimensions } from 'hooks'
import { InputBS, SwitchField, TextareaBS } from 'components'

const ContactInfo = ({ updating = false }) => {
  const intl = useIntl()
  const { width } = useWindowsDimensions()

  return (
    <>
      <div className='row mb-8'>
        <div className={`col-xl-6 col-lg-4 col-sm-12 col-12 ${width <= 992 ? 'mb-8' : ''}`}>
          <InputBS id='contact.name' name='contact.name' label={intl.formatMessage({ id: 'CONTACT.NAME' })} disabled={updating} />
        </div>

        <div className={`col-xl-4 col-lg-4 col-sm-8 col-8 ${width <= 992 ? 'mb-8' : ''}`}>
          <InputBS id='contact.email' name='contact.email' label={intl.formatMessage({ id: 'CONTACT.EMAIL' })} disabled={updating} />
        </div>

        <div className='col-xl-2 col-lg-4 col-sm-4 col-4 align-self-center'>
          <div className='d-flex' style={{ paddingTop: `${width > 992 ? '20px' : ''}` }}>
            <SwitchField id='contact.has_notifications' name='contact.has_notifications' disabled={updating} />
            <span style={{ fontSize: '1.1rem' }}>{intl.formatMessage({ id: 'CONTACT.HAS_NOTIFICATIONS' })}</span>
          </div>
        </div>
      </div>

      <div className='row my-8'>
        <div className="col">
          <InputBS id='contact.phone' name='contact.phone' label={intl.formatMessage({ id: 'CONTACT.PHONE' })} disabled={updating}  maxLength={15} />
        </div>

        {/* <div className='col-2'>
          <InputBS id='contact.mobile_suffix' name='contact.mobile_suffix' label='Sufijo' disabled={updating} />
        </div> */}

        <div className='col'>
          <InputBS id='contact.mobile' name='contact.mobile' label={intl.formatMessage({ id: 'CONTACT.MOBILE' })} disabled={updating} maxLength={10}/>
        </div>
      </div>

      <div className='row my-8'>
        <div className='col'>
          <TextareaBS id='contact.observations' name='contact.observations' label={intl.formatMessage({ id: 'CONTACT.OBSERVATIONS' })} disabled={updating} />
        </div>
      </div>
    </>
  )
}

export default ContactInfo