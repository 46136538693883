import { useState, useCallback, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'

import { useShipmentAddresses } from 'hooks'
import { FormTable, Tooltip } from 'components'

import routes from 'app/routing/routes'

const AssociatedCustomersTab = () => {
  const { values } = useFormikContext()

  const { handleGetShipmentAddressAssociatedCustomers } = useShipmentAddresses()
  const [customers, setCustomers] = useState([])

  const getAssociatedCustomers = useCallback(async () => {
    try {
      const response = await handleGetShipmentAddressAssociatedCustomers(values.id)
      setCustomers(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetShipmentAddressAssociatedCustomers, values.id])

  const columns = useMemo(() => [
    { header: 'ID' },
    { header: 'Cliente' },
    { header: '', styles: 'text-center' },
  ], [])

  useEffect(() => {
    getAssociatedCustomers()
  }, [getAssociatedCustomers])

  return (
    <>
      <FormTable columns={columns}>
        {customers.length <= 0
          ? <tr><td className='text-italic text-gray-400'>La dirección de entrega no está asociada a ningún cliente.</td></tr>
          : (customers).map(c => (
            <tr key={`patient-associated-customer-${c.id}`}>
              <td className='align-middle'>
                <Tooltip text='Ir a cliente/proveedor'>
                  <Link to={`${routes.CUSTOMER_SUPPLIERS}/${c.id}`}>
                    {c.customer_supplier_id}
                  </Link>
                </Tooltip>
              </td>
              <td><strong>{c.customer_supplier_business_name}</strong></td>
              <td className='align-middle text-center'>
                <Tooltip text='Ir a cliente/proveedor'>
                  <Link  to={`${routes.CUSTOMER_SUPPLIERS}/${c.id}`}>
                    <i className='fas fa-external-link-alt text-primary'></i>
                  </Link>
                </Tooltip>
              </td>
            </tr>
          ))
        }
      </FormTable>
    </>
  )
}

export default AssociatedCustomersTab