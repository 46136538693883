import { useState, useCallback } from 'react'
import { Budgets } from 'services'

const useBudgets = () => {
  const [loading, setLoading] = useState(false)

  const handleGetBudgets = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Budgets.getBudgets(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetBudgetStatus = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Budgets.getBudgetStatus(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetBudget = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Budgets.getBudget(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddBudget = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Budgets.addBudget(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateBudget = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Budgets.updateBudget(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateBudgetStatus = useCallback(async (id, status_id) => {
    try {
      setLoading(true)
      const response = await Budgets.updateBudgetStatus(id, status_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDetail = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Budgets.addDetail(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDetail = useCallback(async (id, detail_id, data) => {
    try {
      setLoading(true)
      const response = await Budgets.updateDetail(id, detail_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteDetail = useCallback(async (id, detail_id) => {
    try {
      setLoading(true)
      const response = await Budgets.deleteDetail(id, detail_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCloneBudget = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Budgets.cloneBudget(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintBudget = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Budgets.printBudget(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateSalesOrder = useCallback(async (id, user_id) => {
    try {
      setLoading(true)
      const response = await Budgets.createSalesOrder(id, user_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetBudgets,
    handleGetBudgetStatus,
    handleGetBudget,
    handleAddBudget,
    handleUpdateBudget,
    handleUpdateBudgetStatus,
    handleAddDetail,
    handleUpdateDetail,
    handleDeleteDetail,
    handleCloneBudget,
    handlePrintBudget,
    handleCreateSalesOrder,
  }
}

export default useBudgets