import { decimalFormatter } from "utilities"

const valuesCalculator = (article) => {
  const { actual_pvp, list_cost, discount, vat_rate, boxes } = article
  let _psl = null
  let _unit_purchase_price = null
  let _subtotal = null
  let _total = null
  let _profitability_total = null

  let vat = 0;
  if (vat_rate && vat_rate > 0) {
    vat = +actual_pvp * +vat_rate / 100
  }

  _subtotal = +actual_pvp - (+actual_pvp * (+discount / 100)) + +vat
  _unit_purchase_price = _subtotal
  _total = +_subtotal * +boxes

  _subtotal = parseFloat(_subtotal).toFixed(2)
  _total = parseFloat(_total).toFixed(2)

  let _profitability = +_subtotal - +list_cost
  _profitability_total = +_profitability * +boxes

  return {
    psl: _psl,
    unit_purchase_price: _unit_purchase_price,
    subtotal: _subtotal,
    total: _total,
    psl_display: decimalFormatter.format(_psl),
    unit_purchase_price_display: decimalFormatter.format(actual_pvp),
    subtotal_display: decimalFormatter.format(_subtotal),
    profitability: decimalFormatter.format(_profitability),
    total_display: decimalFormatter.format(_total),
    profitability_total: _profitability_total,
    list_cost: +list_cost,
    discount,
  }
}

export {
  valuesCalculator
}