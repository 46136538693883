import service from 'services/service'

const PATH = {
  waves: '/waves',
  shippingCarriers: '/waves/shipping-carriers',
  shippingCarrierServices: '/waves/shipping-carrier-services',
}

export class Waves {
  static getWaves = async (params) => {
    const response = await service.get(PATH.waves, {
      params,
    })

    return response
  }

  static getWave = async (id, params) => {
    const response = await service.get(`${PATH.waves}/${id}`, {
      params,
    })

    return response
  }

  static createWave = async (data) => {
    const response = await service.post(PATH.waves, data)
    return response
  }

  static updateWave = async (id, data) => {
    const response = await service.patch(`${PATH.waves}/${id}`, data)
    return response
  }

  static updateStatus = async (id, status_id) => {
    const response = await service.post(`${PATH.waves}/${id}/status/${status_id}`)
    return response
  }

  static cancelWave = async (id) => {
    const response = await service.post(`${PATH.waves}/${id}/cancelar`)
    return response
  }

  static addWaveOrders = async (id, data) => {
    const response = await service.post(`${PATH.waves}/${id}/wave-order`, data)
    return response
  }

  static deleteWaveOrder = async (id, wave_order_id, data) => {
    const response = await service.post(`${PATH.waves}/${id}/wave-order/${wave_order_id}/remove`, data)
    return response
  }

  static getShippingCarriers = async (params) => {
    const response = await service.get(PATH.shippingCarriers, {
      params,
    })

    return response
  }

  static getShippingCarrierServices = async (params) => {
    const response = await service.get(PATH.shippingCarrierServices, {
      params,
    })

    return response
  }

  static addShippingCarrierService = async (id, wave_order_id, data) => {
    const response = await service.post(`${PATH.waves}/${id}/wave-order/${wave_order_id}`, data)
    return response
  }

  static createOrderDelivery = async (id, data) => {
    const response = await service.post(`${PATH.waves}/${id}/order-delivery`, data)
    return response
  }

  static printConsolidado = async (id) => {
    const response = await service.get(`${PATH.waves}/${id}/print/consolidado`, {
      responseType: 'blob',
    })
    return response
  }


}