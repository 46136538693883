import service from "../service";

const PATH = {
  Incomes:"/incomes",
  InRequest: "/incomes/in-request",
  Event:"/event"
};

export class Purchases {

  static getRequests = async (params) => {
    const response = await service.get(PATH.InRequest + params);
    return response;
  };

  static getRequestsV2 = async (params) => {
    const response = await service.get(PATH.InRequest, {
      params
    })
    return response
  }

  static getRequest = async (id) => {
    const response = await service.get(`${PATH.InRequest}/${id}`)
    return response
  }

  static createRequest= async (data) => {
    console.log(data.estimated_in_date)

    if (data !== undefined && data.estimated_in_date === "") {
      data.estimated_in_date=null
    }

    const response = await service.post(`${PATH.InRequest}`, data)
    return response
  }

  static updateRequest = async (data) => {
    try{
      const {in_request_id, ...rest} = data;
      const response = await service.put(`${PATH.InRequest}/${in_request_id}`, rest);
      return response;
    }
    catch(err){
      throw err;
    }
  };
  
  static authorizeRequest = async (data) => {
    const {id, ...rest} = data;
    const response = await service.post(`${PATH.InRequest}/${id}/authorize`);
    return response;
  };
  static rejectRequest = async (data) => {
    const {id, ...rest} = data;
    const response = await service.post(`${PATH.InRequest}/${id}/revocate`, rest);
    return response;
  };
  static cancelRequest = async (data) => {
    const {id, ...rest} = data;
    const response = await service.post(`${PATH.InRequest}/${id}/cancel`, rest);
    return response;
  };

  static checkInRequestPVP = async (id) => {
    const response = await service.get(`${PATH.InRequest}/${id}/check-pvp`);
    return response;
  }

  static publishRequest = async (id) => {
    const response = await service.post(`${PATH.InRequest}/${id}/publish`);
    return response;
  };

  static closePartialRequest = async (id) => {
    const response = await service.post(`${PATH.InRequest}/${id}/close-partial`);
    return response;
  };

  static createRequestDetail= async (data) => {
    const {request_id, ...rest} = data;
    const response = await service.post(`${PATH.InRequest}/${request_id}/detail`, rest.articles);
    return response;
  };

  static updateRequestDetail= async (data) => {
    const {in_request_id, id, ...rest} = data;
    const response = await service.put(`${PATH.InRequest}/${in_request_id}/detail/${id}`, rest);
    return response;
  };
  
  static deleteRequestDetail= async (data) => {
    const {request_id, id} = data;
    const response = await service.delete(`${PATH.InRequest}/${request_id}/detail/${id}`);
    return response;
  };

  static getPaymentConditions = async () => {
    const response = await service.get(PATH.Incomes + '/payment-condition');
    return response;
  };

  static printRequest = async (id) => {
    const response = await service.get(`${PATH.InRequest}/${id}/print`, {
      responseType: 'blob',
    })
    return response
  }
  static getEvents = async (request_id) => {
    const response = await service.get(`${PATH.Event}/in-request/${request_id}`);
    return response;
  };

  

}