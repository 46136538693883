import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import './styles.css';
import { Notifications } from 'components';
import { useAuth } from 'app/modules/auth';

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-2x gps_ring'
const btnClass ='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'

const Navbar = () => {
  const {config} = useLayout()
  const {auth} = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
          {/* <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            className={btnClass}
          >
          <KTIcon iconName='notification-on' className={btnIconClass} /> */}
            <Notifications />
            {/* <HeaderNotificationsMenu /> */}
          {/* </div> */}
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} /> */}
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' /> */}
           
           { auth?.user?.avatar?
            <img alt='Logo' src={toAbsoluteUrl(`/media/avatars/${auth.user.avatar}`)} />
            :
            <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
          }

        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}