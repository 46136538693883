import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AssignmentOutlined, Inventory2Outlined } from '@mui/icons-material'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useRequest } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import GeneralTab from './GeneralTab'
import ArticlesTab from './ArticlesTab'

import { incomeSchema, initialValues, purchaseSchema } from './constants'
import routes from 'app/routing/routes'

const AddRequest = ({ income = false }) => {
  const navigate = useNavigate()

  const { handleCreateRequest } = useRequest()

  const tabsContent = useMemo(() => [
    <GeneralTab income={income} />,
    <ArticlesTab />,
  ], [])

  const tabs = useMemo(() => [
    { value: 'general', label: "General", icon: <AssignmentOutlined />, show: true },
    { value: 'articles', label: "Articulos", icon: <Inventory2Outlined />, show: true, disabled:true }
  ], [])

  const createPurchaseRequest = async (data) => {
    try {
      const response = await handleCreateRequest(data)

      if (response) {
        toast.success(response.message, { theme: "colored" })
        navigate(
          income
            ? `${routes.INCOME_REQUEST_DETAIL}/${response.id}`
            : `${routes.PURCHASE_REQUEST_DETAIL}/${response.id}`, { state: { tab: 'articles' } }
        )
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const handleSubmit = (values) => {
    const { id, distributor_id_aux, con_distribuidor,  ...rest } = values

    rest.distributor_id=distributor_id_aux>0?distributor_id_aux:null;
    
    const data = {
      ...rest,
      order_type_id: income ? 2 : 1,
      payment_condition_id: income ? null : rest.payment_condition_id,
    }

    createPurchaseRequest(data)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={income?incomeSchema:purchaseSchema }
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched }) => (
          <Form className="d-flex flex-column mt-4" >
            <DetailContainer
              title={income ? 'Nuevo pedido de ingreso' : 'Nuevo pedido de compra'}
              status={'Borrador'}
              buttons={
                <div className='align-self-center'>
                  <Button variant="secondary" onClick={() => navigate(-1)}>
                    <i className="ki-duotone ki-left" />
                    Volver
                  </Button>
                </div>
              }
            >
              <TabsContainer
                initialValue='general'
                tabs={tabs}
                tabsContent={tabsContent}
              />
            </DetailContainer>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddRequest