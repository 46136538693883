import { useState, useCallback } from 'react';

import { AgentTypes } from 'services';
import { AgentTypeI, GetAgentTypesI, GetAgentTypeI } from 'interfaces';

interface UseAgentTypesReturn {
  loading: boolean;
  handleGetAgentTypes: (token: string) => Promise<GetAgentTypesI>;
  handleGetAgentType: (id: AgentTypeI['id'], token: string) => Promise<GetAgentTypeI>;
}

const useAgentTypes = (): UseAgentTypesReturn => {
  const [loading, setLoading] = useState(false);

  const handleGetAgentTypes = useCallback(async (token: string) => {
    try {
      setLoading(true);
      const response = await AgentTypes.getAgentTypes(token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetAgentType = useCallback(async (id: AgentTypeI['id'], token: string) => {
    try {
      setLoading(true);
      const response = await AgentTypes.getAgentType(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    handleGetAgentTypes,
    handleGetAgentType
  };
};

export default useAgentTypes;