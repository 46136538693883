import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const PatientsColumns = () => {
  const COLUMNS = [
  {
    Header: 'ID',
    id: 'id',
    accessor: (row) => (
      <Tooltip text='Ver paciente'>
        <Link to={`${routes.PATIENTS}/${row.id}`}>{row.id}</Link>
      </Tooltip>
    ),
  },
  {
    Header: 'Nombre completo',
    id: 'name',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.first_name} {row.last_name}</p>
    ),
  },
    {
      Header: <div className='text-center'>Documento</div>,
      id: 'documentation',
      accessor: (row) => (
        <div className='text-center'>
          {row?.documentation
            ? row.documentation
            : <i className='text-gray-500'>Sin documento</i>
          }
        </div>
      )
    },
    {
      Header: <div className='text-center'>Nro. afiliado</div>,
      id: 'affiliate_number',
      accessor: (row) => (
        <div className={'text-center'}>
          {row.health_insurance?.affiliate_number
            ? row.health_insurance.affiliate_number
            : <i className='text-gray-500'>Sin nro. afiliado</i>
          }
        </div>
      ),
    },
    {
      Header: 'Contacto',
      id: 'contact',
      accessor: (row) => (
        <>
          {row.contact_id
            ?
              <ul className='list-style-none m-0 p-0'>
                <li><strong>Nombre:</strong> {row.contact?.name || '-'}</li>
                <li><strong>Email:</strong> {row.contact?.email || '-'}</li>
                <li><strong>Teléfono:</strong> {row.contact?.phone || '-'}</li>
                <li><strong>Celular:</strong> {row.contact?.mobile || '-'}</li>
              </ul>
            : <i className='text-gray-500'>Sin datos de contacto</i>
          }
        </>
      ),
    },
    {
      Header: <div className='text-center'>Habilitado</div>,
      id: 'is_enabled',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${Boolean(row.is_enabled) ? 'success' : 'danger'}`}>{Boolean(row.is_enabled) ? 'Sí' : 'No'}</span>
        </div>
      )
    }
  ]

  return {
    COLUMNS
  }
}

export default PatientsColumns
