import service from "../service"

const PATH = {
  billingTypes: "/billing-types",
};

export class BillingTypes {
  static getBillingTypes = async (params) => {
    const response = await service.get(PATH.billingTypes, {
      params,
    })
    return response
  }

  static getBillingType = async (id, token) => {
    const response = await service.get(`${PATH.billingTypes}/${id}`)

    return response
  }
}