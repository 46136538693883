import * as Yup from 'yup'
import { currentDate } from 'utilities';

const formikInitialValues = {
  id: null,
  supplier_id: null,
  distributor_id: null,
  payment_condition_id: '',
  issue_date: '',
  income_date: '',
  estimated_in_date: '',
  notes: '',
  internal_notes:'',
  order_type_id: null,
  supplier_name: '',
  distributor_name: '',
  payment_condition_label: '',
  waybill_number: '',
  carrier: '',
  supplier_invoice: '',
  supplier_invoice_date: '',
  in_request_id: null,
  storage_space_id: null,
  detail: []
}
/*
{
    "id": 26,
    "order_type_id": 1,
    "supplier_id": 1,
    "customer_id": null,
    "payment_condition_id": 1,
    "creation_user_id": 1,
    "estimated_in_date": "2019-12-31T03:00:00.000Z",
    "notes": "x",
    "internal_notes": "x",
    "created_at": "2023-09-01T18:26:55.000Z",
    "issue_date": "2023-08-31T03:00:00.000Z",
    "revocation_user_id": null,
    "revocation_date": null,
    "order_status_id": 7,
    "order_type_name": "PEDIDO_COMPRA",
    "order_type_label": "Pedido de compra",
    "supplier_name": "DROFAR",
    "customer_name": null,
    "payment_condition_name": "CUENTA_CORRIENTE_30",
    "payment_condition_label": "Cuenta corriente a 30 días",
    "order_status_name": "BORRADOR",
    "order_status_label": "Borrador"
}
*/

const schema = Yup.object().shape({
  supplier_name: Yup.string(),
  supplier_invoice: Yup.string()
    .nullable()
    .test(
      'contains-ABCMET',
      'Debe contener una de las siguientes letras: A, B, C, M, E, T',
      function (value) {
        if (!value) return true;
        return /[ABCMET]/.test(value);
      }
    )
    .test(
      'contains-more-than-one-ABCMET',
      'La factura contiene más de una letra A, B, C, M, E o T',
      function (value) {
        if (!value) return true;
        const matches = value.match(/[ABCMET]/g || []);
        const uniqueMatches = new Set(matches);
        return (matches.length === 1 && uniqueMatches.size === 1);
      }
    )
    .test(
      'len',
      'El mínimo de caracteres, incluyendo la letra, es de 12 caracteres. El máximo de caracteres, incluyendo la letra, deben ser de 20 caracteres',
      function (value) {
        if (!value) return true;
        return value.length >= 12 && value.length <= 20;
      }
    ),
  supplier_invoice_date: Yup.string(),
  distributor_name: Yup.string(),
  issue_date: Yup.string(),
  waybill_number: Yup.string()
    .nullable()
    .test(
      'contains-R',
      'Debe contener la letra R',
      function (value) {
        if (!value) return true;
        return value.includes('R');
      }
    )
    .test(
      'contains-more-than-one-R',
      'Debe contener una única letra R',
      function (value) {
        if (!value) return true;
        const rCount = (value.match(/R/g) || []).length;
        return rCount === 1;
      }
    )
    .test(
      'len',
      'El mínimo de caracteres, incluyendo la letra, es de 12 caracteres. El máximo de caracteres, incluyendo la letra R, deben ser de 20 caracteres',
      function (value) {
        if (!value) return true;
        return value.length >= 12 && value.length <= 20;
      }
    ),
  notes: Yup.string(),
  internal_notes: Yup.string()
});



const providers = [
  { value: 1, label: 'Test' },
  { value: 2, label: 'Tomás López' },
  { value: 3, label: 'Ezequiel López' },
  { value: 4, label: 'Diego de la Parra' },
  { value: 5, label: 'Christian Palazzo' },
];


const buyers = [
  { value: 1, label: 'Test' },
  { value: 2, label: 'Vendedor Tomás' },
  { value: 3, label: 'Vendedor Ezequiel' },
  { value: 4, label: 'Vendedor Diego' },
  { value: 5, label: 'Vendedor Christian' },
];

const payment_conditions = [
  { id: 1, name: 'Cta. Cte. 15 días' },
  { id: 2, name: 'Cta. Cte. 30 días' },
  { id: 3, name: 'Cta. Cte. 60 días' },  
];



export {
  providers,
  buyers,
  payment_conditions

}

export {
  formikInitialValues,
  schema
}