import { useState, useEffect } from "react";
import { Button, Form, FormControl, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import moment from 'moment'
import { helix } from 'ldrs'

import { useArticles } from "hooks";
import { decimalFormatter } from "utilities";

const  DiscountModal = ({ show, onClose, onSave, customerSupplier }) => {
  const [articlesOptions, setArticlesOptions] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const { loading, handleGetArticles } = useArticles()
  const [txtSearch, setTxtSearch] = useState('')

  const handleKeyDown = async (event) => {
    if ((event.keyCode === 13 && event.target?.value?.length > 2) || (event.target?.name === 'btnSearch' && txtSearch.length > 2)) {
      const queryMD = {
        text: txtSearch,
        add_price: 1,
        add_cost: 1,
        customer_id: customerSupplier?.id || undefined,
      }

      const data = await handleGetArticles({ queryMD })

      if (data && data.result) {
        const val = data.result.map(r => ({ ...r, selected: false }))
        setArticlesOptions(val)
      }
    }
  }

  const handleSave = async () => {
    const arts = await Promise.all(articlesOptions
      .filter(a => a.selected === true)
      .map(async a => {
        const data = {
          product_var_id: a.id,
          discount: a.discount ? parseFloat(a.discount) : 0,
        }

        return data
      })
    )

    onSave({ discounts: arts })
    setTxtSearch('')
    onClose()
  }

  const handleDiscountChange = (productId, e) => {
    let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value

    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, discount: value} : product)
    setArticlesOptions(updatedProducts)
  }

  const handleSelectionChange = (productId) => {
    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, selected: !product.selected } : product)
    setArticlesOptions(updatedProducts)
  };

  useEffect(() => {
    if (show) {
      setArticlesOptions([])
      setSelectedRows([])
      setTxtSearch('')
      helix.register()
    }
  }, [show])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size="xl" backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Agregar descuento</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <div className='row'>
          <div className='col-10'>
            <FormLabel>Articulo</FormLabel>
            <FormControl
              type="text"
              onKeyDown={handleKeyDown}
              autoComplete="off"
              placeholder="Nombre del artículo (al menos 3 caracteres)"
              value={txtSearch}
              onChange={(e) => setTxtSearch(e.target.value)}
            />
          </div>

          <div className='col mt-6'>
            <Button type='button' onClick={handleKeyDown} name="btnSearch"><i className="bi bi-search me-2"></i>Buscar</Button>
          </div>
        </div>

        <div className="row mt-10">
          <div className='col mt-5'>
            {loading &&
              <>
                <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", top: "180px" }}></l-helix>
              </>
            }

            <Table striped bordered hover>
              <thead>
                <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th className="text-center">Precio</th>
                <th className="text-center">Últ. Act. PP</th>
                <th className="text-center">Descuento</th>
                <th className="text-center" style={{ width: '100px' }}>Seleccionar</th>
                </tr>
              </thead>

              <tbody>
                {articlesOptions.map(product => (
                  <tr key={product.id}>
                    <td className="align-middle">{product.id}</td>
                    <td className="align-middle">
                      {product.name}
                      {product?.observations
                        ?
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="waves-print-tooltip">
                                <div className=''>
                                  <p>{product.observations}</p>
                                </div>
                              </Tooltip>
                            }
                          >
                            <span className="ms-5"> <i className="fa-regular fa-circle-question text-primary"></i> </span>
                          </OverlayTrigger>
                        : null
                      }
                    </td>
                    <td className='align-middle text-center'>$ {decimalFormatter.format(product.actual_pvp)}</td>
                    <td className='align-middle text-center'>{product?.last_update?moment(product.last_update).format("DD/MM/YYYY"):""}</td>
                    <td className="align-middle text-center">
                      <FormControl
                        type="text"
                        value={product.discount || ''}
                        style={{ width: '100px' }}
                        onChange={(e) => handleDiscountChange(product.id, e)}
                        maxLength={7}
                        autoComplete="off"
                      />
                    </td>
                    <td className="align-middle text-center">
                      <Form.Check
                        type="checkbox"
                        className="chk-selector"
                        style={{ width: '100px' }}
                        onChange={() => handleSelectionChange(product.id)}
                        checked={product.selected}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "0 none" }}>
        <Button variant="secondary" type='button' onClick={onClose}>Salir</Button>
        <Button variant="primary" type='button' onClick={handleSave}><i className="bi bi-database-add me-2"></i>Guardar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DiscountModal