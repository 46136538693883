import { formatInputDate } from "utilities"

const initialValues = {
  id: '',
  transactional_document_type_id: '',
  customer_supplier_name: '',
  date: '',
  number: '',
  cae: '',
  out_order_id: '',
  sales_order_id: '',
  status: '',
}

const dataAdapter = (data) => {
  let values = {
    ...data,
    date: formatInputDate(data.date),
    cae: data?.cae ? data.cae : '',
  }

  return values
}

export {
  initialValues,
  dataAdapter,
}