import routes from "app/routing/routes";
import { CustomTable } from "components";
import { DetailContainer } from "containers";
import { Formik } from "formik";
import { useSalesOrders } from "hooks";
import { helix } from "ldrs";
import { useEffect, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from 'moment';
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ActivationDetail = ()=>{
    const [salesOrder, setSalesOrder] = useState({})
    const {id} = useParams();
    const navigate = useNavigate();
    const {loading, handleGetSalesOrder, handleRemoveReserved} = useSalesOrders();

    const loadOrder = async()=>{
        if(id){
            const _resp = await handleGetSalesOrder(id)
            if(_resp?.data?.result){
                setSalesOrder(_resp.data.result)
            }
        }
    }

    const freeReserved = async (row)=>{
        try{
            if (row) {//cancelado o rechazado
                const msg = `Va a liberar la cantidad reservada del producto ${row.product_var_name} del pedido de venta #${salesOrder.id}, confirma?`      
                const inputValue = '';
                const resp = await Swal.fire({
                    title: "Advertencia",
                    text: msg,
                    icon: "warning",
                    input:"text",
                    inputValue,
                    inputLabel: "Cantidad",
                    inputAttributes: {
                        maxlength: "5",
                        autocomplete:"off"
                    },
                    inputValidator: (value) => {
                        if (!/^\d+$/.test(value)) {
                            return "Ingresar un valor numérico!";
                        }
                        if(+value>row.qty_reserved){
                            return "Cantidad ingresada mayor a la reserva!";
                        }
                    },
                    showDenyButton: true,
                    denyButtonText: "No, cancelar",
                    confirmButtonText: "Si, liberar reserva",
                    customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
                    reverseButtons: true
                })
      
              if (resp.isConfirmed) {
                const data = {
                    sales_order_id: salesOrder.id,
                    items:[
                        {
                            product_var_id: row.product_var_id,
                            qty: +resp.value
                        }
                    ]
                }
                await handleRemoveReserved(data)
                loadOrder()
                toast.success("Se reactivó la órden de egreso", { theme: "colored" })
              }
            }
          }
          catch(error){
            toast.error(error.message)
          }
    }

    const columns = [
        {
            Header: "Artículo",
            id: 'product_var_name',
            accessor: 'product_var_name'
          },
          {
            Header: () => (<div style={{textAlign:"center"}}>Pedido</div>),
            id: 'qty_sales_order',
            accessor: (row) => (
                <div className='text-center m-0'>
                  {row.qty_sales_order}
                </div>
              )
          },
          {
            Header: () => (<div style={{textAlign:"center"}}>Reservado</div>),
            id: 'qty_reserved',
            accessor: (row) => (
                <div className='text-center m-0'>
                  {row.qty_reserved}
                </div>
              )
          },
          {
            Header: () => (<div style={{textAlign:"center"}}>Utilizado</div>),
            id: 'qty_used',
            accessor: (row) => (
                <div className='text-center m-0'>
                  {row.qty_used}
                </div>
              )
          },
          {
            Header: () => (<div style={{textAlign:"center"}}>Faltante</div>),
            id: 'qty_remaining',
            accessor: (row) => (
                <div className='text-center m-0'>
                  {row.qty_remaining}
                </div>
              )
          },
          {
            Header: '',
            id: 'actions',
            className:'col-icons',
            accessor: (row) => (
                <OverlayTrigger overlay={<Tooltip>Liberar reservado</Tooltip>}>
                <i className="bi bi-arrow-bar-up text-primary" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={() => freeReserved(row)} />
              </OverlayTrigger>
            )
          }
    ]

    useEffect(()=>{
        loadOrder()
    }, [id])

    useEffect(()=>{
        helix.register()
    }, [])

    return (
        <>
        
        <Formik
            initialValues={{}}
            onSubmit={()=>{}}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
            <Form className="" >
                <DetailContainer
                statusV2={
                    <>
                        {/* <div className={`badge ${labelColor(values?.order_status_id)} justify-content-center ms-5 fw-normal`} style={{width:"150px"}}>{values.order_status_label}</div> */}
                    </>
                }

                title={ 'Pedido de venta #' + id}
                buttons={
                    <div className='d-flex mt-5'>
                      <Button
                        type="button"
                        variant="secondary"
                        style={{ height: "38px" }}
                        className='me-3'
                        // disabled={loading}
                        onClick={() => navigate(routes.LOGISTICS_ACTIVATION)}
                      >
                        <i className="ki-duotone ki-left"/>
                        Volver
                      </Button>

                    </div>
                  }
                >
                <div className="row mt-10">
                    <div className='col text-start pt-4'>
                    {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

                        <table>
                            <tbody>
                                <tr>
                                    <td>Cliente:</td>
                                    <td className='fw-bold'>{salesOrder.customer_supplier_business_name}</td>
                                </tr>
                                <tr>
                                    <td>CUIT</td>
                                    <td className='fw-bold'>{salesOrder.customer_supplier_documentation}</td>
                                </tr>
                                <tr>
                                    <td>Fecha pedido</td>
                                    <td className='fw-bold'>{salesOrder.created_at?moment(salesOrder.created_at).format("DD/MM/YYYY"):''}</td>
                                </tr>
                                <tr>
                                    <td>Vendedor</td>
                                    <td className='fw-bold'>{salesOrder.seller_name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col mb-4 text-end' >
                        {/* {!editEnable && <Button variant="primary" type='button' onClick={()=>setShowFcModal(true)} disabled={!values.in_request_id}><i className="bi bi-pencil"></i>Modificar Factura</Button>}
                        {editEnable && <Button variant="primary" type='submit'><i className="bi bi-save"></i>Guardar</Button>} */}
                    </div>
                </div>
                
                <div className="row mt-10">
                    <div className="col">                        
                        <CustomTable columns={columns} data={salesOrder?.detail_reserved_and_used || []}  />
                    </div>
                </div>

                </DetailContainer>
            </Form>
            )}
        </Formik>
        </>
    )
}

export default ActivationDetail