import { ESTADOS_ORDEN_ENVIO } from "pages/Logistic/ShipmentOrders/List";

const labelColor = (status) => {
  if (!status) {
    return "estado-gris"
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "estado-amarillo";
    case "SUCCESS":
      return "estado-verde";
    case "ERROR_NEGOCIO_AFIP":
      return "estado-rojo";
    case "DEFERRED":
      return "estado-celeste";
    case "DISCARDED":
    case "ANULADO":
      return "estado-naranja";
    default:
      return '';
  }
}

const statusDisplay = (status) => {
  if (!status) {
    return "Sin estado de comprobante"
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "Aprobado c/observaciones";
    case "SUCCESS":
      return "Aprobado";
    case "DEFERRED":
      return "Factura Diferida";
    case "ERROR_NEGOCIO_AFIP":
      return "Error AFIP";
    case "DISCARDED":
      return "Descartada";
    case "ANULADO":
      return "Anulado";
    default:
      return '';
  }
}

const labelColorSinComprobantes = (order_delivery_status_id) => {
  switch (order_delivery_status_id) {
    case ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO:
      return "estado-amarillo";
    case ESTADOS_ORDEN_ENVIO.EN_DESPACHO:
      return "estado-celeste";
    case ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION:
      return "estado-azul";
    case ESTADOS_ORDEN_ENVIO.ENTREGADA:
      return "estado-verde";
    case ESTADOS_ORDEN_ENVIO.CANCELADO:
      return "estado-rojo";
    default:
      return ""
  }
}

export {
  labelColor,
  statusDisplay,
  labelColorSinComprobantes,
}