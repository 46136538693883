import { Link } from "react-router-dom"

import { Tooltip } from "components"

import routes from "app/routing/routes"

const COLUMNS = [
  {
    Header: 'ID',
    id: 'customer_supplier_id',
    accessor: (row) => (
      <Tooltip text='Ver cliente'>
        <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.customer_supplier_id}`}>
          {row.customer_supplier_id}
        </Link>
      </Tooltip>
    )
  },
  {
    Header: 'Cliente',
    id: 'customer_supplier_business_name',
    accessor: (row) => (
      <>
        {row.customer_supplier_business_name}
      </>
    )
  },
  {
    Header: 'Documento',
    id: 'customer_supplier_documentation',
    accessor: (row) => (
      <>
        {row.customer_supplier_documentation}
      </>
    )
  },
  {
    Header: <div className='text-center'>Habilitado</div>,
    id: 'customer_supplier_is_enabled',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row.customer_supplier_is_enabled) ? 'success' : 'danger'}`}>{Boolean(row.customer_supplier_is_enabled) ? 'Sí' : 'No'}</span>
      </div>
    )
  },
]

export {
  COLUMNS,
}