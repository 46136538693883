import { useState, useRef, useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useArticles, useCustomerSuppliers } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { Actions } from './partials'
import { Form as FormCost } from '../partials'
import { initialValues, schema, getListCostStatus, getListCostStatusColor, dataAdapter, updateDataAdapter } from '../constants'
import moment from 'moment'
import routes from 'app/routing/routes'
import { Button } from 'react-bootstrap'
import { helix } from 'ldrs'

const CostDetail = () => {
  const { id } = useParams()
  const intl = useIntl()
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetListCost, handleUpdateListCost } = useCustomerSuppliers()
  const {handleUpdateArticleCost} = useArticles();
  const [cost, setCost] = useState(initialValues)
  const [editing, setEditing] = useState(false)

  const location = useLocation();
  const { row } =  location.state;

  // const getListCost = useCallback(async () => {
  //   try {
  //     const response = await handleGetListCost(id)
  //     const data = dataAdapter(response.data.result)
  //     console.log(data)
  //     setCost(data)
  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // }, [id, handleGetListCost])

  const updateListCost = async (values) => {
    try {
      const data = updateDataAdapter(values)

      if(values.especial){
        // delete values.purchase_add_cost
        // delete values.purchase_add_cost_percent
        const response = await handleUpdateListCost(id, data)
      }
      else{
        // delete values.customer_supplier_id
        // delete values.id
        console.log(data)
        const params= {
            manual_list_cost: (data.list_cost && data.list_cost>0 && data.date_to)?
            {
                list_cost: data.list_cost,
                date_from: moment().format("YYYYMMDD"),
                date_to:  data.date_to ? moment(data.date_to).format("YYYYMMDD"):null,
            } : null,
            purchase_discount_extra: data.purchase_discount_extra || 0,
            purchase_add_cost: data.purchase_add_cost || 0,
            purchase_add_cost_percent: data.purchase_add_cost_percent || 0,
            product_var_id:data.product_var_id       
          }
        await handleUpdateArticleCost(params);
      }


      toast.success("Registro actualizado correctamente", {theme:"colored"})
      // getListCost()
      setEditing(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  // useEffect(() => {
  //   getListCost()
  // }, [getListCost])

  useEffect(()=>{
    console.log(row)
    setCost({
      ...row, 
      psl:row.psl?parseFloat(row.psl).toFixed(2):'',
      pvp:row.pvp?parseFloat(row.pvp).toFixed(2):'',
      initial_cost:row.initial_cost?parseFloat(row.initial_cost).toFixed(2):'',
      especial:row.id?true:false
    })

  }, [row])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <PageTitle
        // breadcrumbs={[
        //   { title: 'Compras/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
        //   { title: 'Costos/', path: routes.COSTS, isSeparator: false, isActive: true },
        //   { title: `Detalle de costo de lista especial #${id}`, path: `${routes.COSTS}/${id}`, isActive: false }
        // ]}
        // description={cost.id? 'Costo de lista especial #' + {id} : 'Costo artículo '}
      />

      <DetailContainer
        title={cost.id? 'Costo especial #' + id : 'Costo de lista '}
        statusV2={<span className={`ms-4 badge badge-light-${getListCostStatusColor(getListCostStatus(cost))} fs-8 fw-bolder`}>{getListCostStatus(cost)}</span>}
        buttons={<Actions editing={editing} handleEditing={setEditing} submitRef={submitRef} cancelRef={cancelRef} /> }
        // loading={loading}
      >
        <Formik
          initialValues={cost}
          validationSchema={schema}
          onSubmit={updateListCost}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className="d-flex flex-column" >

                { loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "150px" }}></l-helix>}

                <FormCost editing={editing} />

                {editing && (
                  <div className='d-flex flex-row justify-content-end'>
                    <Button
                      ref={cancelRef}
                      variant='secondary'
                      type='button'
                      className='me-3'
                      onClick={() => {
                        setEditing(false)
                        handleReset()
                      }}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button ref={submitRef} type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                  </div>
                )}

              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default CostDetail
