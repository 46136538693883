import { useState, useCallback } from 'react';

import { Articles } from 'services';

const initialQueryString = {
  pageNumber: 1,
  pageSize: 10,
}

const useArticles = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);

  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);

  const handleGetArticles = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);
      const {filter, queryMD} = params;

      if (filter != null && filter != '') {
        query = filter + '&' + new URLSearchParams(queryMD).toString();
      }
      else{
        query = "?" + new URLSearchParams(queryMD).toString();
      }

      const response = await Articles.getArticles(query);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetArticlesV2 = useCallback(async (params) => {
    try {
      const response = await Articles.getArticlesV2(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetArticle = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Articles.getArticle(params);
      return response.data?.result?response.data.result[0]:{};
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSearchArticle = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Articles.searchArticle(params);
      return response?.data || [];
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);
  const handleCreateArticle = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Articles.createArticle(data);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateArticle = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Articles.updateArticle(data);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateArticleCost = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Articles.updateArticleCost(data);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetStock = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Articles.getStock(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetMovements = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Articles.getMovements(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    queryString,
    setQueryString,
    handleGetArticles,
    handleGetArticlesV2,
    handleGetArticle,
    handleCreateArticle,
    handleUpdateArticle,
    handleGetStock,
    handleGetMovements,
    handleUpdateArticleCost,
    handleSearchArticle
  };
};

export default useArticles;