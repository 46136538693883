import service from "../../service";

const PATH = {
  patients: "/patients",
};

export class Patients {
  static getPatients = async (token, query, params) => {
    const response = await service.get(`${PATH.patients}`, {
      params,
    });

    return response
  }

  static getPatient = async (id, token) => {
    const response = await service.get(`${PATH.patients}/${id}`)
    return response
  }

  static createPatient = async (data, token) => {
    const response = await service.post(PATH.patients, data)
    return response
  }

  static updatePatient = async (id, data, token) => {
    const response = await service.patch(`${PATH.patients}/${id}`, data)
    return response
  }

  static updatePatientStatus = async (id, data) => {
    const response = await service.patch(`${PATH.patients}/${id}/status`, data)
    return response
  }

  static getPatientAddresses = async (id, params) => {
    const response = await service.get(`${PATH.patients}/${id}/addresses`, {
      params
    })
    return response
  }

  static addPatientAddress = async (id, data, token) => {
    const response = await service.post(`${PATH.patients}/${id}/address`, data)
    return response
  };

  static linkAddressToPatient = async (id, address_id) => {
    const response = await service.post(`${PATH.patients}/${id}/address/${address_id}`)
    return response
  }

  static updatePatientAddress = async (id, address_id, data) => {
    const response = await service.patch(`${PATH.patients}/${id}/address/${address_id}`, data)

    return response
  }

  static deletePatientAddress = async (id, address_id, token) => {
    const response = await service.delete(`${PATH.patients}/${id}/address/${address_id}`)
    return response
  }

  static getPatientAssociatedCustomers = async (id, params) => {
    const response = await service.get(`${PATH.patients}/${id}/customer-suppliers`, {
      params,
    })
    return response
  }
}