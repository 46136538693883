/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment';

const HistoryWidget = ({className, events}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Eventos</span>
          <span className='text-muted fw-semibold fs-7'>
            al {moment().format("DD/MM/YYYY HH:mm")}
          </span>
        </h3>

        <div className='card-toolbar'></div>
      </div>

      <div className='card-body pt-5'>
        <div className='timeline-label'>
          {events.map((event, index) => (
            <div className='timeline-item' key={index}>
              <div className='timeline-label fw-bold text-gray-800 fs-6' style={{ width: "130px" }}>
                {event.display_date}
              </div>

              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1' />
              </div>

              <div className='fw-mormal timeline-content text-muted ps-3'>
                {event.description}
              </div>
            </div>
          ))}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export { HistoryWidget}