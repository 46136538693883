import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'


import { TransactionalDocumentTypeI } from 'interfaces'
import { currencyFormat } from 'utilities'

const types = {
  [TransactionalDocumentTypeI.FACTURA]: 'Factura',
  [TransactionalDocumentTypeI.NOTA_DEBITO]: 'Nota de débito',
  [TransactionalDocumentTypeI.NOTA_CREDITO]: 'Nota de crédito',
  [TransactionalDocumentTypeI.REMITO]: 'Remito',
}

const COLUMNS = [
  {
    Header: "ID",
    id: 'id_real',
    accessor: (row) => (
      <div className=''>
        {row.id}
      </div>
    ),
    hasOrder: true
  },
  {
    Header: <div className='text-center'>Fecha</div>,
    id: 'fecha',
    accessor: (row) => (
      <div className='text-center'>
        {row?.fecha ? moment(row.fecha).format("DD/MM/YYYY") : '-'}
      </div>
    ),
    hasOrder: true
  },
  {
    Header: 'Tipo',
    id: 'tipo_comprobante',
    accessor: (row) => (
      <>
        {row.tipo_comprobante}
      </>
    )
  },
  {
    Header: <div className='text-center'>Número</div>,
    id: 'nro_completo',
    accessor: (row) => (
      <div className='text-center'>
        {row?.nro_completo ? row.nro_completo : '-'}
      </div>
    ),
    hasOrder: true
  },
  {
    Header: <div className=''>Cliente / Proveedor</div>,
    accessor: 'razon_social',
    hasOrder: true
  },
  {
    Header: <div className='text-center'>Pedido Venta</div>,
    id: 'sales_order_id',
    accessor: (row) => (
      <div className='text-center'>
        {row.sales_order_id}
      </div>
    ),
    hasOrder: true
  },
  {
    Header: <div className='text-center'>Orden Egreso</div>,
    id: 'out_order_id',
    accessor: (row) => (
      <div className='text-center'>
        {row.out_order_id}
      </div>
    ),
    hasOrder: true
  },
  {
    Header: <div className='text-center'>CAE</div>,
    id: 'cae',
    accessor: (row) => (
      <div className='text-center'>
        {row?.cae || '-'}
      </div>
    ),
    hasOrder: true
  },
  {
    Header: <div className='text-end'>Total</div>,
    id: 'total',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row.total)}
      </div>
    ),
    hasOrder: true
  },
]

export {
  COLUMNS,
}