import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'

const Tooltip = ({ placement = 'top', text, maxWidth, children }) => {
  const tooltipStyle = {}

  if (maxWidth) {
    tooltipStyle.maxWidth = maxWidth
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <TooltipBS id={`tooltip-${placement}`} style={tooltipStyle}>
          {text}
        </TooltipBS>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip