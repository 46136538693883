import { useState, useCallback } from 'react'
import { ShipmentAddresses } from 'services'

const useShipmentAddresses = () => {
  const [loading, setLoading] = useState(false)

  const handleGetShipmentAddresses = useCallback(async (token, query = '', params = {}) => {
    try {
      setLoading(true)

      if (!query) query = ''
      const response = await ShipmentAddresses.getShipmentAddresses(token, query, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetShipmentAddress = useCallback(async (id, token) => {
    try {
      setLoading(true)
      const response = await ShipmentAddresses.getShipmentAddress(id, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleSearchAddress = useCallback(async (params) => {
    try {
      setLoading(true)

      let query = ''
      const { filter, queryMD } = params

      if (filter != null && filter !== ''){
        query = `${filter}&${new URLSearchParams(queryMD).toString()}`;
      } else {
        query = `?${new URLSearchParams(queryMD).toString()}`;
      }

      const response = await ShipmentAddresses.searchAddress(query)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddShipmentAddress = useCallback(async (data, token) => {
    try {
      setLoading(true)
      const response = await ShipmentAddresses.addShipmentAddress(data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateShipmentAddress = useCallback(async (id, data, token) => {
    try {
      setLoading(true)
      const response = await ShipmentAddresses.updateShipmentAddress(id, data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateAgent = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await ShipmentAddresses.updateAgent(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateEnableShipmentAddress = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await ShipmentAddresses.updateEnableShipmentAddress(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteShipmentAddress = useCallback(async (id, token) => {
    try {
      setLoading(true)
      const response = await ShipmentAddresses.deleteShipmentAddress(id, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetShipmentAddressAssociatedCustomers = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await ShipmentAddresses.getShipmentAddressAssociatedCustomers(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetShipmentAddresses,
    handleGetShipmentAddress,
    handleSearchAddress,
    handleAddShipmentAddress,
    handleUpdateShipmentAddress,
    handleUpdateEnableShipmentAddress,
    handleDeleteShipmentAddress,
    handleGetShipmentAddressAssociatedCustomers,
    handleUpdateAgent
  }
}

export default useShipmentAddresses