import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'

import { CustomTable } from 'components'
import { formatTableDate } from 'utilities'

const COLUMNS = [
  {
    Header: 'Artículo',
    id: 'product_var_name',
    accessor: (row) => (
      <>
        {row.product_var_name}
      </>
    ),
  },
  {
    Header: 'Fecha de vencimiento',
    id: 'expiration_date',
    accessor: (row) => (
      <>
        {formatTableDate(row.expiration_date)}
      </>
    )
  },
  {
    Header: 'Lote',
    id: 'lot_number',
    accessor: (row) => (
      <>
        {row.lot_number}
      </>
    )
  },
  {
    Header: 'Cantidad',
    id: 'qty',
    accessor: (row) => (
      <>
        {row.qty}
      </>
    ),
  },
]

const IncomeDetailModal = ({ show, onClose, inOrderId, detail }) => {
  const [currDetail, setCurrDetail] = useState([])

  useEffect(() => {
    setCurrDetail(detail)
  }, [inOrderId, detail])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Detalle de ingreso #{inOrderId}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CustomTable columns={COLUMNS} data={currDetail} />
      </Modal.Body>
    </Modal>
  )
}

export default IncomeDetailModal