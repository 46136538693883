import { useState, useMemo, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { CustomTable } from 'components'
import { ArticleSelectorModal } from './SelectArticleModal/ArticleSelectorModal'
import { ArticleItemModalV2 } from './'
import { columnsPedidoCompra, columnsPedidoIngreso } from '../../constants'
import { useClickOutside } from 'hooks'
import ContextMenu from 'components/ContextMenu'
import { ESTADOS_PEDIDO_COMPRA } from 'pages/Purchases/Requests/List'
import TotalSummaryTable from '../TotalSummaryTable'

const ArticlesTab = ({ income, editEnable, setInitialTab, loading }) => {
  const { id } = useParams()
  const { values, handleReset } = useFormikContext()
  const [showModal, setShowModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [detailData, setDetailData] = useState(null)

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
      setCurrentRow(row)
      if(e){
        e.preventDefault();
        setCurrentPos({x:e.clientX, y:e.clientY, target:e.target.id})
        if(!showContextMenu)
          setShowContextMenu(true);
      }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if(currentPos.target=='threeDots'){
      setCurrentPos({...currentPos, target:''})
      return
    }
    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    if ( values.order_status_id == ESTADOS_PEDIDO_COMPRA.BORRADOR) {
      if (!Boolean(currentRow?.outdated_pvp)) {
        actions.push({
          label: "Editar",
          icon: "bi bi-pencil text-primary",
          action: actionEdit
        })
      }

      actions.push({
        label: "Eliminar detalle",
        icon: "bi bi-trash text-danger",
        action: actionDelete
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      setDetailData(currentRow)
      setDetailModal(true)
    }
  }
  const actionDelete = () => {
    if(currentRow){
      handleDelete(currentRow.id)
    }
  }
  /* Fin menu contextual */

  const onHide = (data) => {
    if (data && data.articles && data.articles.length > 0) {
      values.addArticle(data)
    }
    setShowModal(false)
  }

  const handlePurchaseUpdate = (detailData) => {
    let newData = {...values }

    const {
      addArticle,
      deleteArticle,
      updateArticle,
      order_status_name,
      order_status_label,
      order_status_id,
      revocation_notes,
      revocation_user_name,
      revocation_date,
      ...rest
    } = newData

    const idx = rest.detail.findIndex(det => det.id == detailData.id);

    if (idx !== -1) {
      // rest.detail[idx] = { ...detailData, var_id: detailData.id };
      rest.detail[idx] = { ...detailData };
      // console.log({ ...detailData, in_request_id: values.id });
      values.updateArticle({ ...detailData, var_id: detailData.product_var_id, in_request_id: values.id });
    }

  }

  const handleDelete = async (articleId) => {
    try {
      const art = {
        id: articleId,
        request_id: id
      }

      values.deleteArticle(art)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => {
    let col = [];
    if(income){
      col = columnsPedidoIngreso;
    }
    else{
      col = columnsPedidoCompra
    }

    return [
      ...col,
      {
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => (
          <>
            { values.order_status_id==ESTADOS_PEDIDO_COMPRA.BORRADOR 
              ? <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
              : <></>
            }
          </>
        )
      }
    ]

  }, [values.detail])


  
  return (
    <>
      <div className='row mb-8 align-center'>
        <div className={`${values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) ? 'd-flex flex-row align-items-center justify-content-between' : ''}`}>
          {values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) &&
            <div className='col text-start mb-8 fw-bold flex-grow-1'>
              Por favor, elimine los artículos con PVP desactualizado e ingreselos nuevamente.
            </div>
          }

          <div className='col text-end mb-8' style={{ flex: 'none' }}>
            {editEnable &&
              <Button variant='secondary'
                onClick={() => {
                  setShowModal(true)}
                }
                className='me-5'
              >
                <i className="bi bi-database-add" />
                Agregar artículo
              </Button>
            }

            {editEnable &&
              <Button variant='primary' type="submit" disabled={loading}>
                <i className="bi bi-save me-2" />
                Guardar
              </Button>
            }
          </div>
        </div>
      </div>

      <CustomTable columns={columns} data={values?.detail}  handleContextMenu={handleContextMenu}  />

      {values.detail.length > 0 && <div className='mt-8'><TotalSummaryTable /></div>}

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />

      <ArticleItemModalV2
        show={detailModal}
        onHide={() => {
          setDetailData(null)
          setDetailModal(false)
        }}
        detail={detailData}
        handleUpdate={(data) => handlePurchaseUpdate(data)}
      />

      <ArticleSelectorModal show={showModal} onHide={onHide} />
    </>
  )
}

export default ArticlesTab