import * as Yup from 'yup'

const initialValues = {
  id: 0,
  description: '',
  created_at: '',
  updated_at: '',
  is_enabled: false,
}

const schema = Yup.object().shape({
  id: Yup.number().required('Campo obligatorio'),
  description: Yup.string().required('* Ingrese una descripción'),
})

export {
  initialValues,
  schema
}