import { useIntl } from 'react-intl'
import moment from 'moment';
import { Link } from 'react-router-dom';
import routes from 'app/routing/routes';
import '../../styles.css'

import { currencyFormat } from 'utilities';

const useGetColumns = () => {
  const intl = useIntl()
/*

*/

  let COLUMNS =  [
    {
      Header:  <div className="text-center">ID</div>,
      id: 'pedidoId',
      hasOrder:true,
      accessor: (row) => (
        <div className='text-center'>
          {row.PedidoId}
        </div>
      ),
    },
    {
      Header:  <div className="text-center">Fecha</div>,
      id: 'PedidoFecha',
      accessor: (row) => (
        <div className='text-center'>
          {row.PedidoFecha ? moment(row.PedidoFecha).format("DD/MM/YYYY") : ''}
        </div>
      )
    },
    {
      Header:  <div className="text-center">Cliente</div>,
      id: 'cliente',
      accessor: (row) => (
        <div className='text-center'>
           <p style={{margin:"0"}}>{row.ClienteNombre}</p>
           <p style={{margin:"0"}}>{row.TipoDocu}: {row.NroDocu}</p>
        </div>
      )
    },
    {
      Header:  <div className="text-center">Tipo Entrega</div>,
      id: 'EntregaEspecial',
      accessor: (row) => (
        <div className='text-center'>
          {row.EntregaEspecial}
        </div>
      )
    },
    {
      Header:  <div className="text-center">Remito</div>,
      id: 'remito',
      className:'col-comprobante',
      accessor: (row) => (
        <div className='text-center'>
          {row.NroRemito}
        </div>
      )
    },
    {
      Header:  <div className="text-center">Factura</div>,
      id:'factura',
      className:'col-comprobante',
      accessor: (row)=>(
        <div className="break-word text-center">
          {row.NroComp}
        </div>
      )
    },
    {
      Header:  <div className="text-end">Total</div>,
      id: 'importe_total',
      accessor: (row) => (
        <div className='text-end'>
          {row.total === 0 ? '' : currencyFormat.format(row.importe_total)}
        </div>
      ),
    },
    {
      Header:  <div className="text-center">Vendedor</div>,
      id: 'Vendedor',
      accessor: (row) => (
        <div className='text-center'>
          {row.Vendedor}
        </div>
      )
    },
    {
      Header:  <div className="text-center">Estado pedido</div>,
      id: 'EstadoPedido',
      accessor: (row) => (
        <div className='text-center'>
          {row.EstadoPedido}
        </div>
      )
    },
    // {
    //   Header:  <div className="text-center">Artículo</div>,
    //   id:'articulo',
    //   accessor: (row)=>(
    //     <>
    //       <p style={{margin:"0"}}>{row.articulo}</p>
    //       <p style={{margin:"0"}}>Ppio: {row.droga}</p>
    //       <p style={{margin:"0"}}>GTIN: {row.gtin}</p>
    //     </>
    //   ),
    // },
    // {
    //   Header:  <div className="text-center">Cantidad</div>,
    //   id:'cantidad',
    //   accessor: (row)=>(
    //     <div className='text-center'>
    //       {row.cantidad}
    //     </div>
    //   ),
    // },
    // {
    //   Header:  <div className="text-center">Lote/Vto</div>,
    //   id:'lote',
    //   accessor: (row)=>(
    //     <>
    //       <p style={{margin:"0"}} className='text-center'>{row.lote}</p>
    //       <p style={{margin:"0", fontSize:"0.9em"}} >{row.vencimiento?moment(row.vencimiento).format("DD/MM/YYYY"):''}</p>
    //     </>
    //   ),
    // },
    // {
    //   Header:  <div className="text-center">No. Pedido</div>,
    //   id:'pedido_de_compra',
    //   accessor: (row)=>(
    //     <div className='text-center'>
    //       {row.pedido_de_compra}
    //     </div>
    //   ),
    // },

  ]

  return {
    COLUMNS,
  }

}

export default useGetColumns