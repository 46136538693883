import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import { AssignmentOutlined, AccountBoxOutlined, FmdGoodOutlined, GroupsOutlined } from '@mui/icons-material'

import { usePatients } from 'hooks'
import { DetailContainer, TabsContainer } from 'containers'

import { initialValues, schema, dataAdapter, updateDataAdapter } from './constants'
import { Actions, GeneralTab, ContactTab, AddressesTab, AssociatedCustomers } from './partials'

import { replaceNullsWithEmptyString } from 'utilities'

import routes from 'app/routing/routes'

const PatientDetail = () => {
  const { id } = useParams()
  const intl = useIntl()
  const submitButtonRef = useRef(null)
  const cancelButtonRef = useRef(null)

  const { loading, handleGetPatient, handleUpdatePatient } = usePatients()

  const [patient, setPatient] = useState(initialValues)
  const [updating, setUpdating] = useState(false)

  const getPatient = useCallback(async () => {
    try {
      const response = await handleGetPatient(id, 'token')
      let data = dataAdapter(response.result)

      setPatient(replaceNullsWithEmptyString(data))
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetPatient])

  const handleUpdate = async (values) => {
    let data = updateDataAdapter(values)

    if (!data.documentation && !data.health_insurance.affiliate_number) {
      toast.error('Debe completar el documento y/o nro. de afiliado del paciente')
      return
    }

    if (data.documentation && !data.documentation_type_id) {
      toast.error('Debe seleccionar un tipo de documento para el paciente')
      return
    }

    // if (data.health_insurance.affiliate_number && !data.health_insurance.health_insurance_id) {
    //   toast.error('Debe seleccionar una obra social para el paciente')
    //   return
    // }

    console.log(data.contact)
    if (
      (data.contact?.email !== '' || Boolean(data.contact?.has_notifications) || data.contact?.phone !== '' || data.contact?.mobile_suffix !== '' || data.contact?.mobile !== '' || data.contact?.observations !== '')
      && data.contact?.name == ''
    ) {
      toast.error('Debe ingresar el nombre del contacto para guardar la información de los datos de contacto')
      return
    }

    try {
      const response = await handleUpdatePatient(id, data, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setUpdating(false)
      getPatient()
    }
  }

  const handleEdit = () => {
    setUpdating(true)
  }

  useEffect(() => {
    getPatient()
  }, [getPatient])

  return (
    <>
      <DetailContainer
        title={`Detalle del paciente #${id}`}
        buttons={<Actions updating={updating} handleEdit={handleEdit} submitRef={submitButtonRef} cancelRef={cancelButtonRef} />}
      >
        <Formik
          initialValues={patient}
          validationSchema={schema}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column'>
                <TabsContainer
                  initialValue='general'
                  tabs={[
                    { value: 'general', label: intl.formatMessage({ id: 'TABS.PATIENTS.GENERAL' }), icon: <AssignmentOutlined />, show: true},
                    { value: 'contact', label: intl.formatMessage({ id: 'TABS.PATIENTS.CONTACT' }), icon: <AccountBoxOutlined />, show: true},
                    { value: 'addresses', label: intl.formatMessage({ id: 'TABS.PATIENTS.ADDRESSES' }), icon: <FmdGoodOutlined />, show: true},
                    { value: 'customers', label: intl.formatMessage({ id: 'TABS.PATIENTS.CUSTOMERS' }), icon: <GroupsOutlined />, show: true},
                  ]}
                  tabsContent={[
                    <GeneralTab updating={updating} />,
                    <ContactTab updating={updating} />,
                    <AddressesTab updating={updating} handleUpdating={setUpdating} handleGetPatient={getPatient} />,
                    <AssociatedCustomers />,
                  ]}
                />

                {updating && (
                  <>
                    <div className='separator my-8'></div>

                    <div className='d-flex align-items-center justify-content-end'>
                      <Button
                        ref={cancelButtonRef}
                        variant='secondary'
                        type='button'
                        className='me-3'
                        onClick={() => {
                          setUpdating(false)
                          handleReset()
                        }}
                      >
                        <i className="bi bi-slash-circle" />
                        Cancelar
                      </Button>

                      <Button ref={submitButtonRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </div>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default PatientDetail