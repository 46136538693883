import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button, FormLabel, Modal } from 'react-bootstrap'
import Select from "react-select"
import { toast } from 'react-toastify'

import { useStock, useDepots } from 'hooks'

const today = new Date();
const monthAgo = new Date();
monthAgo.setMonth(today.getMonth() - 1);

export const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

export const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '38px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '1.1rem',
  }),
};


const SpaceStorageSelector = ({ show, onClose, handleGetDepotMovements }) => {
  const [submitted, setSubmitted] = useState(false)
  const [entities, setEntities] = useState([])
  // const [businessOptions, setBusinessOptions] = useState([])
  const [ownerOptions, setOwnerOptions] = useState([])
  const [storageOptions, setStorageOptions] = useState([])
  const [owner, setOwner] = useState(null)
  const [business, setBusiness] = useState(null)
  const [origin, setOrigin] = useState(null)
  const [destiny, setDestiny] = useState(null)
  const [observations, setObservations] = useState("")

  const { handleCreateDepotMovement } = useStock()
  const { handleGetStorageBusinessUnits } = useDepots()

  const getStorages = async () => {
    const storage = await handleGetStorageBusinessUnits()
    setEntities(storage)

    // Filtrar y obtener dueños únicos según customer_suppliers_id y business_unit_id
    const uniqueOwners = Array.from(new Map(storage.map(item => [item.customer_suppliers_id, item])).values())

    // Obtener todos los Dueños y sus respectivos almacenes
    const owners = uniqueOwners.map(item => ({
      value: item.customer_suppliers_id,
      label: item.customer_supplier_name,
      business_unit_id: item.business_unit_id
    }))

    setOwnerOptions(owners)

    // Obtener todos los almacenes sin filtrar por la Unidad de Negocio
    const allStorageOptions = storage.map(s => ({
      ...s,
      value: s.id,
      label: s.name
    }))

    setStorageOptions(allStorageOptions)
  }

  const handleChangeOwner = (e) => {
    setOwner(e)
    setOrigin(null)
    setDestiny(null)
    setSubmitted(false)
    setStorageOptions(
      entities
        .filter(item => item.customer_suppliers_id === e.value)
        .map(s => ({
          ...s,
          value: s.id,
          label: s.name
        }))
    )
  }

  const onSave = async (values) => {
    if( !owner || !origin || !destiny || !observations){
      setSubmitted(true)
      return;
    }
    
    try {
      let data = {
        storage_space_id_origin: origin?.id,
        storage_space_id_dest: destiny?.id,
        observations:observations
      }
      const response = await handleCreateDepotMovement(data)

      toast.success(response.data?.message)
      onClose()
      handleGetDepotMovements()
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getStorages()
    setBusiness(null)
    setOwner(null)
    setOrigin(null)
    setDestiny(null)
    setSubmitted(false)
  }, [show])

  if (!show) return null

  return (
    <Modal show={show} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Nuevo movimiento interno de stock</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            business: '',
            owner: '',
            origin: '',
            destiny: ''
          }}
          // validationSchema={{}}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className="row">
                  <div className='col-6'>
                    <FormLabel style={labelStyle}>Dueño</FormLabel>
                    <Select
                      name='owner'
                      options={ownerOptions}
                      value={owner}
                      onChange={handleChangeOwner}
                      styles={customStyles}
                      className={`${(!owner && submitted) ? 'border border-danger' : ''}`}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className='col'>
                    <FormLabel style={labelStyle}>Almacén Origen</FormLabel>
                    <Select
                      name='origin'
                      options={storageOptions}
                      value={origin}
                      onChange={e => {
                        setOrigin(e)
                        setSubmitted(false)
                      }}
                      styles={customStyles}
                      className={`${(!origin && submitted) ? 'border border-danger' : ''}`}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className='col'>
                    <FormLabel style={labelStyle}>Almacén Destino</FormLabel>
                    <Select
                      name='destiny'
                      options={storageOptions}
                      value={destiny}
                      onChange={e => {
                        setDestiny(e)
                        setSubmitted(false)
                      }}
                      styles={customStyles}
                      className={`${(!destiny && submitted) ? 'border border-danger' : ''}`}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className='col'>
                    <FormLabel style={labelStyle}>Motivo</FormLabel>
                    <input type='text'
                      name='observations'
                      value={observations}
                      onChange={e=>setObservations(e.target?.value)}
                      className={`form-control ${(!observations && submitted) ? 'border border-danger' : ''}`}
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='d-flex align-items-center justify-content-end'>
                  <Button variant='secondary' type='button' onClick={onClose} className='me-3'><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button variant='primary' type='submit'>Continuar</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default SpaceStorageSelector