export enum ShipmentOrderStatusI {
  PENDIENTE_DESPACHO = 1,
  EN_DESPACHO = 2,
  EN_DISTRIBUCION = 3,
}

export enum DispatchOrderStatusI {
  PENDIENTE = 1,
  DESPACHADO = 2,
  CANCELADO = 3,
}