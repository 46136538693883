import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'

import { useSalesOrders, useOutOrders, useTransactionalDocuments, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './TransactionalDocumentsColumns'

export const label = (status) => {
  if (!status) {
    return ''
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "Aprobado c/observaciones";
    case "SUCCESS":
      return "Aprobado";
    case "DEFERRED":
      return "Factura Diferida";
    case "ERROR_NEGOCIO_AFIP":
      return "Error AFIP";
    case "DISCARDED":
      return "Descartada";
    case "CANCELED":
    case "ANULADO":
      return "Anulado";
    case "ERROR_PROCESAR_COMPROBANTE":
      return 'Error comprobante'
    default:
      return '';
  }
}

export const labelColor = (status) => {
  if (!status) {
    return ""
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "estado-amarillo";
    case "SUCCESS":
      return "estado-verde";
    case "ERROR_NEGOCIO_AFIP":
    case "ERROR_PROCESAR_COMPROBANTE":
      return "estado-rojo";
    case "DEFERRED":
      return "estado-celeste";
    case "CANCELED":
    case "DISCARDED":
    case "ANULADO":
      return "estado-naranja";
    default:
      return '';
  }
}

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const TransactionalDocumentsTab = () => {
  const { values } = useFormikContext()
  const [transactionalDocuments, setTransactionalDocuments] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handleGetComprobantesAsociados } = useSalesOrders()
  const { handlePrintFactura, handlePrintRemito} = useTransactionalDocuments()

  const getTransactionalDocuments = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }
      const response = await handleGetComprobantesAsociados(values.id, params)
      const data = response.data.result.map(d => {
        return {
          ...d,
          label_color: labelColor(d.status),
          label: label(d.status)
        }
      })

      setTransactionalDocuments(data)
      // setTotalCount(response.data.metadata.count)
    } catch (error) {
      setTransactionalDocuments([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [])

  const printWaybill = async () => {
    try {
      let copias = 2
      const response = await handlePrintRemito(currentRow.id, copias)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('¡Remito descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `remito-${currentRow}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

      } else {
        // Manejar errores si la solicitud no fue exitosa
        console.error('Error al descargar el PDF')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const printFactura = async () => {
    try {
      const response = await handlePrintFactura(currentRow?.codigo_interno, currentRow?.id)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('¡Comprobante descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `comprobante-${currentRow.id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar el comprobante')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
      setCurrentRow(row)

      if (e) {
        e.preventDefault()

        setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

        if (!showContextMenu) {
          setShowContextMenu(true)
        }
      }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow?.afip && !currentRow?.remito && (currentRow?.status !== 'ERROR_NEGOCIO_AFIP' || currentRow?.status !== 'ERROR_PROCESAR_COMPROBANTE')) {
      actions.push({
        label: 'Descargar comprobante',
        icon: 'bi bi-download text-primary',
        action: printFactura
      })
    }

    if (!currentRow?.afip && currentRow?.remito) {
      actions.push({
        label: 'Descargar',
        icon: 'bi bi-download text-primary',
        action: printWaybill
      })
    }

    return actions
  }
  /* Menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getTransactionalDocuments()
  }, [getTransactionalDocuments])

  return (
    <>
      {/* <FileModal show={modal} onClose={handleCloseModal} onSave={addFile} selectedFile={selectedFile} handleSelectedFile={setSelectedFile} /> */}

      <div className='mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8'>
          <label className='form-label m-0'>Comprobantes del pedido</label>
        </div>

        <TableContainer>
          <CustomTable columns={columns} data={transactionalDocuments} queryMD={query} setQueryMD={setQuery} paginationOptions={paginationOptions} />
        </TableContainer>

        <ContextMenu
          ref={contextRef}
          currentPos={currentPos}
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
        />
      </div>
    </>
  )
}

export default TransactionalDocumentsTab