import { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx/xlsx.mjs';
import { helix } from 'ldrs'
import moment from 'moment'

import { useClickOutside, useRequest } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import useGetColumns from './columns'

import { PurchaseHistoryDialog } from 'pages/Purchases/Requests/List/PurchaseHistoryDialog'

import routes from 'app/routing/routes'
import { ESTADOS_PEDIDO_COMPRA } from 'pages/Purchases/Requests/List';

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
}

export const labelColor = (status) => {
  switch(status.toUpperCase()){
    case "GENERADO":
    case "PENDIENTE_INGRESO":
    case "BORRADOR":
      return "estado-amarillo";
    case "COMPLETO_PARCIAL":
    case "COMPLETO":
      return "estado-verde";
    case "CANCELADO":
    case "RECHAZADO":
      return "estado-rojo";
  }
}

const OrdersAuthorizations = () => {
  const { loading, handleGetRequestsV2, handleAuthorizeRequest, handleCheckInRequestPVP, handleRejectRequest } = useRequest()
  const [data, setData] = useState([])

  // const [filter, setFilter] = useState("?order_status_id=1");
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0);

  const { COLUMNS } = useGetColumns()
  const [showHistoryModal, setShowHistoryModal] = useState(false)

  const getRequests = useCallback(async () => {
    try {
      let params = {
        order_status_id: [1],
      }

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      const response = await handleGetRequestsV2(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.order_status_name)
      }))

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetRequestsV2, queryMD])

  const navigate = useNavigate();

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if(currentPos.target=='threeDots'){
      setCurrentPos({ ...currentPos, target: '' })
      return
    }
    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    if (currentRow) {
      actions.push({
        label: "Ver",
        icon: "bi bi-eye text-primary",
        action: actionEdit
      })

      if (currentRow.order_status_id == ESTADOS_PEDIDO_COMPRA.GENERADO) {
        actions.push({
          label: "Autorizar",
          icon: "bi bi-clipboard-check text-success",
          action: actionAuthorize
        })

        actions.push({
          label: "Rechazar",
          icon: "bi bi-clipboard-x text-danger",
          action: actionReject
        })
      }

      actions.push({
        label: "Eventos de la Orden",
        icon: "bi bi-clock-history text-primary",
        action: actionViewEvents
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      if (currentRow) {
        if (currentRow.order_type_id == 1) {
          navigate(`${routes.PURCHASE_REQUEST_DETAIL}/${currentRow.id}`);
        } else {
          navigate(`${routes.INCOME_REQUEST_DETAIL}/${currentRow.id}`);
        }
      }
    }
  }

  const actionAuthorize = async () => {
    if (currentRow) {
      try {
        const { id } = currentRow;

        const response = await handleCheckInRequestPVP(id)

        let text = `¿Confirma la autorización del pedido #${id}?`

        if (response.data.result.length > 0) {
          let qty = response.data.result.length
          text = `El pedido #${id} tiene (${qty}) detalle${qty > 1 ? 's' : ''} con PVP desactualizado, ¿quiere autorizarlo?`;
        }

        const resp = await Swal.fire({
          title: "Advertencia",
          text: text,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, autorizar pedido",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          const resp = await handleAuthorizeRequest({ id })
          toast.success("Pedido autorizado", { theme: "colored" })
        }
        getRequests()
      } catch(err) {
        toast.error(err.message, { theme: "colored" })
      }
    }
  }

  const actionReject = async () => {
    if (currentRow) {
      try {
        const { id } = currentRow;
        const inputValue = '';
        const resp = await Swal.fire({
          title: "Advertencia",
          text: `¿Rechaza la autorización del pedido #${id}?`,
          icon: "warning",
          input:"textarea",
          inputValue,
          inputLabel: "Motivo",
          inputAttributes: {
              autocomplete:"off"
          },
          inputValidator: (value) => {
              if(!value || value.trim()==''){
                return "Por favor, completar motivo de rechazo"
              }
          },
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, rechazar autorización",
          customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary", inputLabel:"form-label", input:"mt-1" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          await handleRejectRequest({ id, revocation_notes:resp.value })
          toast.success("Autorización rechazada", { theme: "colored" })
        }
        getRequests()
      } catch(err) {
        toast.error(err.message, { theme: "colored" })
      }
    }
  }

  const actionViewEvents = () => {
    setShowHistoryModal(true)
  }

  const onCloseHistoryModal = (data) => {
    setShowHistoryModal(false)
  }
  /* Fin menu contextual */

  const exportData = async () => {
    try {
      let params = {
        ...queryMD,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetRequestsV2(params)

      const datos = response.data.result.map(d => {
        let data = {
          "Nº Pedido": d.id,
          "Tipo": d.order_type_label,
          "Proveedor": d.supplier_name,
          "Fecha creación": moment(d.created_at).format('DD/MM/YYYY'),
          "Ingreso estimado": d?.estimated_in_date ? moment(d.estimated_in_date).format('DD/MM/YYYY') : '-',
          "Ingreso": d?.in_order_created_at ? moment(d.in_order_created_at).format('DD/MM/YYYY') : '-',
          "Articulos": d.product_qty,
          "Monto": d.total,
          "Estado": d.order_status_label
        }

        return data
      })

      let ws = XLSX.utils.json_to_sheet(datos);

      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Ordenes");
      XLSX.writeFile(wb, `Autorizaciones ${moment().format("YYYYMMDD-HHmm")}.xlsx` );
    } catch (error) {
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <div className='d-flex justify-content-around'>
          <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
        </div>
      )
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD
  };

  useEffect(() => {
    getRequests()
  }, [getRequests])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <PurchaseHistoryDialog show={showHistoryModal} onClose={onCloseHistoryModal} currentRow={currentRow} />

      <TableContainer
        title='Autorizaciones'
        filter={<Filter setData={setData} queryMD={queryMD} setQueryMD={setQueryMD} setTotalCount={setTotalCount} exportData={exportData} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} handleContextMenu={handleContextMenu} queryMD={queryMD} setQueryMD={setQueryMD} paginationOptions={paginationOptions} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default OrdersAuthorizations