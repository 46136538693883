import service from "services/service";
import { DocumentationTypeI } from "interfaces";

const PATH = {
  documentationTypes: "/documentation-types",
};

export class DocumentationTypes {
  static getDocumentationTypes = async (token: string) => {
    const response = await service.get(PATH.documentationTypes, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  static getDocumentationType = async (id: DocumentationTypeI['id'], token: string) => {
    const response = await service.get(`${PATH.documentationTypes}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };
}