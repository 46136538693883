
import React, { useEffect } from 'react';
import {  useFormikContext } from 'formik';
import {  InputBS, SelectField } from 'components';
import { useGetProductDetail, useProducts } from 'hooks/Inventory';
import moment from "moment";

const ProductForm = () => {
  const { setFieldValue, values } = useFormikContext();
  const {distributionChannelOptions, productTypesOptions} = useProducts();
  const { productFamilies, productMakers } = useGetProductDetail()

  return (
    <>

        <div className="row mt-4">
            <div className='col-3'>
              <SelectField label="Tipo" name="product_type_id" options={productTypesOptions} marginTop='3px' />
            </div>
            <div className='col'>
              <InputBS  id='name' name='name' label="Nombre producto" disabled={values.ab_link}/>
            </div>
        </div>

        <div className="row mt-5 mb-5">

            <div className='col'>
              <SelectField 
                label={values.product_type_id==1?'Ppio. Activo':'Familia'} 
                name="family_id" 
                options={productFamilies.map(f=>({label:f.description, value:f.id}))} 
                marginTop='3px' />
            </div>

            <div className='col'>
              <SelectField 
                label={values.product_type_id==1?'Laboratorio':'Fabricante'} 
                name="maker_id" 
                options={productMakers.map(f=>({label:f.description, value:f.id}))} 
                marginTop='3px' />
            </div>
            <div className="col">
              <SelectField
                  label="Canal Comercial"
                  name="channels"
                  options={distributionChannelOptions}
                  isMulti="true"
                  placeholder="Seleccionar..."
                />
            </div>
        </div>


    </>
  );
};


export default ProductForm;
