import momento from 'moment'

import { formatInputDate } from "utilities"

const initialValues = {
  id: '',
  dispatch_order_status_id: '',
  dispatch_order_status_label: '',
  dispatch_order_status_color: '',
  dispatch_order_detail_count: '',
  detail: [],
  created_at: '',
  shipping_carrier_service_name: '',
  cancel_date: '',
}

const newInitialValues = {
  id: '',
}

const dataAdapter = (data) => {
  let detail = data.detail.length === 0 ? [] : data.detail.map(d => ({
    ...d,
    created_at: formatInputDate(d.created_at),
    cancel_date: formatInputDate(d?.cancel_date),
  }))

  let values = {
    ...data,
    detail: detail,
    created_at: formatInputDate(data.created_at),
    cancel_date: formatInputDate(data?.cancel_date),
  }

  return values
}

const newDataAdapter = (data) => {
  let values = {
    ...data,
  }

  return values
}

export {
  initialValues,
  dataAdapter,
  newDataAdapter,
}