import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import Select from 'react-select';
import { useLogisticStock } from "hooks";
import moment from 'moment'

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}
const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px',
    height: '43px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};
export function ArticleLotNumberModal({ show, onHide, movement}) {

    const [articleData, setArticleData] = useState({});
    const [optionArticles, setOptionArticles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [errorQty, setErrorQty] = useState(false);
    const {handleGetStockList, handleGetStockDetail} = useLogisticStock();
    const [optionsLotNumber, setOptionsLotNumber] = useState([]);

    const lotRef = useRef(null);

    const handleChange = (e)=>{
        let {name, value} = e.target;
        if(name=='qty'){
          value = value.replace(/\D/g, '');
        }
        setArticleData({...articleData, [name]:value})
    }

    const onChangeArticle = async (option) => {

      lotRef?.current?.clearValue();

      setSubmitted(false);

      const params = {
        product_var_id:option.value,
        customer_supplier_id:movement?.storage_space_origin?.customer_supplier_id,
        business_unit_id:movement?.storage_space_origin?.business_unit_id,
        only_available:1
      }
      const response = await handleGetStockDetail(params);

      setOptionsLotNumber(response.result
        .map(d=>({
          value:d.lot_number, 
          label: d.lot_number  + ( d.serial_number ? (' - ' + d.serial_number): '') + ' - Vto:' + (d.expiration_date?moment(d.expiration_date).format("DD/MM/YYYY"):"") + ' - ' + d.storage_space_name,
          storage_space_inventory_status_id:d.storage_space_inventory_status_id,
          serial_number: d.serial_number,
          expiration_date: d.expiration_date
        })))

      setArticleData({
        product_var_id:option.value, 
        gtin:option.gtin, 
        traced:option.traced, 
        qty:option.traced? '1':'',
        lot_number:'',
        expiration_date:'',
        serial_number:''
      })
      // setOptionsLotNumber(option.options_lot_number.filter(d=>d.storage_space_inventory_status_id==1)) // Solo los que estén habilitados
    };

    const fetchArticles = async()=>{
      const _art = await handleGetStockList({});
      setOptionArticles(_art.result.map(a=>({label:a.name, value:a.product_var_id, gtin:a.gtin, traced:a.traced})))
    }

    const handleSubmit = ()=>{
        setSubmitted(true)
        const {qty,lot_number,expiration_date} = articleData;

        if(!qty || !lot_number ||  qty=='' || lot_number=='' || !articleData?.product_var_id){
            return;
        }

        onHide(articleData)
    }


    useEffect(()=>{
      if(show){
        fetchArticles();
        setArticleData({});
        setOptionArticles([]);
        setOptionsLotNumber([]);
        setSubmitted(false);
        setErrorQty(false);
      }
    }, [show])


    return (
      <>
            <Modal size="md" show={show} onHide={onHide}  backdrop='static' >

            <Modal.Body className="">
              <h3>Movimiento interno</h3>  
              <div className="row">
              <div className="col">
                <FormLabel className="d-block mt-4">Origen: <span className="fw-light">{movement?.storage_space_origin?.name}</span></FormLabel>
                <FormLabel className="d-block">Destino: <span className="fw-light">{movement?.storage_space_dest?.name}</span></FormLabel>
              </div>
              </div>                     

                    <div className="row mt-10">
                      <div className="col">
                        <FormLabel style={labelStyle}>Artículo</FormLabel>
                        <Select
                          options={optionArticles}
                          onChange={onChangeArticle}
                          styles={customStyles}
                          className={`${submitted && (!articleData.product_var_id)?"border border-danger":""}`}
                        />
                      </div>
                    </div>

                    <div className="row mt-10">
                      <div className="col">
                        <FormLabel style={labelStyle}>GTIN</FormLabel>
                        <input type="text" 
                          className="form-control"
                          id='gtin'
                          value={articleData.gtin || ''} autoComplete="off" disabled={true}/>
                      </div>
                    </div>
                    <div className="row mt-10">
                      <div className="col">
                        <label htmlFor='qty' className='form-label'>Cantidad</label>
                        <input type="text" 
                            className={`form-control ${submitted && (!articleData.qty || articleData.qty=='')?"border border-danger":""}`}
                            id='qty' name="qty" maxLength={5} 
                            value={articleData.qty || ''} 
                            autoComplete="off" 
                            onChange={handleChange} 
                            disabled={articleData.traced}/>
                        {errorQty && <div className='text-danger'>La cantidad no puede ser superior al pendiente</div>}
                      </div>
                    </div>
                    <div className="row mt-10">
                      <div className="col">
                        <label className='form-label'>Lote</label>
                        <Select
                          ref={lotRef}
                          options={optionsLotNumber}
                          onChange={(obj)=> setArticleData({
                            ...articleData, 
                            lot_number:obj?.value, 
                            serial_number:obj?.serial_number,
                            expiration_date:obj?.expiration_date
                          })}
                          styles={customStyles}
                          className={`${submitted && (!articleData.lot_number || articleData.lot_number=='')?"border border-danger":""}`}
                        />
                      </div>
                    </div>

            </Modal.Body>

            <Modal.Footer style={{borderTop: "0 none"}}>
              <Button variant="secondary" onClick={()=>onHide(null)}>Salir</Button>
              <Button type="button" variant="primary" onClick={handleSubmit}>Guardar</Button>
            </Modal.Footer>


          </Modal>
    </>
    )
  }
  