import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useHealthInsurances, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import ContextMenu from 'components/ContextMenu'
import { CustomTable } from 'components'

import { useGetColumns } from './constants'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const HealthInsurances= () => {
  const { handleGetHealthInsurances } = useHealthInsurances()
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const navigate = useNavigate()
  const intl = useIntl()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getHealthInsurances = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetHealthInsurances(params)
      setData(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setData([])
    }
  }, [handleGetHealthInsurances, query])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Editar obra social",
      icon: "bi bi-pencil text-primary",
      action: () => navigate(`${routes.HEALTH_INSURANCES}/${currentRow?.id}`)
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getHealthInsurances()
  }, [getHealthInsurances])

  return (
    <>
      <TableContainer
        title={intl.formatMessage({ id: 'TITLE.HEALTH_INSURANCES' })}
        goTo={routes.HEALTH_INSURANCES_NEW}
        // filter={}
      >
        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default HealthInsurances