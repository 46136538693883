import { useState, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import Filter from './Filter'
import useGetColumns from './columns'
import useReports from 'hooks/useReports'
import moment from 'moment';
import { useClickOutside, useTransactionalDocuments } from 'hooks'
import ContextMenu from 'components/ContextMenu'
import { toast } from 'react-toastify'

const ESTADOS_ORDEN = {
  GENERADO:1,
  PENDIENTE_INGRESO:2,
  INGRESO_PARCIAL:3,
  VENCIDO:4,
  CANCELADO:5,
  COMPLETO:6,
  BORRADOR:7,
  AUTORIZADO:8,
  CONFIRMADO:9,
  COMPLETO_PARCIAL:10,
  INGRESADO:11,
  RECHAZADO:12,
  ERROR_TRAZA:13
};

export const labelColor =(order_status_id)=>{
    switch(order_status_id){
      case ESTADOS_ORDEN.BORRADOR:
        return "estado-gris";
      case ESTADOS_ORDEN.INGRESADO:
        return "estado-verde";
      case ESTADOS_ORDEN.ERROR_TRAZA:
        return "estado-rojo";
    }
}

const Verifarma = () => {

  const [data, setData] = useState([])
  const [queryMD, setQueryMD] = useState({})
  const [totalCount, setTotalCount] = useState(0);
  const { handleGetVentasReport, handleGetVentasDetalleReport, loading } = useReports()
  const {handlePrintFactura, loading:loadingDownload} = useTransactionalDocuments()
  const { COLUMNS } = useGetColumns()
  const [conDetalle, setConDetalle] = useState(false)


    /* Menu contextual */
    const [showContextMenu, setShowContextMenu] = useState(false)
    const [currentPos, setCurrentPos] = useState({x:0, y:0})
    const [currentRow, setCurrentRow] = useState(null)
    const threeDotsRef = useRef(null);
    const contextRef = useRef()
  
    const handleContextMenu = (e, row) => {
        setCurrentRow(row)
        if(e){
          e.preventDefault();
          setCurrentPos({x:e.clientX, y:e.clientY, target:e.target.id})
          if(!showContextMenu)
            setShowContextMenu(true);
        }
    };
  
    useClickOutside(contextRef, () => {
      //Para que no cierre la primera vez que hace click en threeDots
      if(currentPos.target=='threeDots'){
        setCurrentPos({...currentPos, target:''})
        return
      }
      if (showContextMenu) {
        setShowContextMenu(false)
      }      
    })
  
    const availableActions = ()=>{
      let actions = [];
      actions.push({label:"Descargar", icon:"bi bi-download text-primary", action:downloadDocument})
      return actions
    }
  
    const downloadDocument = async()=>{
      if(currentRow){
        try {
          const response = await handlePrintFactura(currentRow?.tipo_comprobante_agrupado, currentRow?.id_comprobante)
  
          // const arrayBuffer = await response.arrayBuffer()
          if (response.status === 200) {
            toast.success('Comprobante descargado!')
  
            // Crear un Blob a partir de la respuesta
            const blob = await response.data
  
            // Crear un enlace de descarga
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `comprobante-${currentRow.id_comprobante}-${currentRow.tipo_comprobante_agrupado}.pdf`
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            URL.revokeObjectURL(url)
          } else {
            // Manejar errores si la solicitud no fue exitosa
            toast.error('Error al descargar el comprobante')
          }
        } catch (error) {
          toast.error(error.message)
        }
      }    
    }
  
    /* Fin menu contextual */

  const getReport = async () => {
    try {

      const response = await handleGetVentasReport(queryMD)
      if(response.result){
        const data = response.result;
      // const dataColored = data.map(d=>({...d, label_color:labelColor(d.order_status_id)}))
        setData(data);
        setTotalCount(response.metadata.count);
      }

    } catch (error) {
      setData([])
      console.log(error)
    }
  }

  const getExport = async (query) => {
    try {
      if(query.addDetail==1){
          delete query.addDetail;
          const response = await handleGetVentasDetalleReport(query)
          if(response.result){
            return response.result
          }  
      }
      else{
        const response = await handleGetVentasReport(query)
        if(response.result){
          return response.result
        }  
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
    }
   ], [COLUMNS])


  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD ,
    setObQuery:setQueryMD
  };

  useEffect(()=>{
      if(queryMD?.pageNumber){
          getReport()
      }
  }, [queryMD])

  useEffect(()=>{
      
  }, [conDetalle])

  return (
    <>

      <TableContainer
        title='Verifarma'
        filter={<Filter queryMD={queryMD} setQueryMD={setQueryMD} getExport={getExport} setConDetalle={setConDetalle}/>}
      >
        {(loading || loadingDownload) && 
          <div className='w-100 text-center'>
            <div className='spinner-border text-success'>

            </div>
          </div>
        }
        
        <CustomTable columns={columns} data={data}  paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD}  />
        

      </TableContainer>
    

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
    
  )
}

export default Verifarma
