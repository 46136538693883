import * as Yup from 'yup'

const waybillSchema = Yup.object().shape({
  waybill_number: Yup.string()
    .test(
      'contains-R',
      'Debe contener la letra R',
      function (value) {
        if (!value) return false;
        return value.includes('R');
      }
    )
    .test(
      'contains-more-than-one-R',
      'Debe contener una única letra R',
      function (value) {
        if (!value) return false;
        const rCount = (value.match(/R/g) || []).length;
        return rCount === 1;
      }
    )
    .test(
      'len',
      'El mínimo de caracteres, incluyendo la letra, es de 12 caracteres. El máximo de caracteres, incluyendo la letra R, deben ser de 20 caracteres',
      function (value) {
        if (!value) return false;
        return value.length >= 12 && value.length <= 20;
      }
    )
})

const invoiceSchema = Yup.object().shape({
  supplier_invoice: Yup.string()
    .test(
      'contains-ABCMET',
      'Debe contener una de las siguientes letras: A, B, C, M, E, T',
      function (value) {
        if (!value) return false;
        return /[ABCMET]/.test(value);
      }
    )
    .test(
      'contains-more-than-one-ABCMET',
      'La factura contiene más de una letra A, B, C, M, E o T',
      function (value) {
        if (!value) return false;
        const matches = value.match(/[ABCMET]/g || []);
        const uniqueMatches = new Set(matches);
        return (matches.length === 1 && uniqueMatches.size === 1);
      }
    )
    .test(
      'len',
      'El mínimo de caracteres, incluyendo la letra, es de 12 caracteres. El máximo de caracteres, incluyendo la letra, deben ser de 20 caracteres',
      function (value) {
        if (!value) return false;
        return value.length >= 12 && value.length <= 20;
      }
    ),
  supplier_invoice_date: Yup.string().nonNullable(),
})

export {
  waybillSchema,
  invoiceSchema,
}