import { useState, useCallback } from 'react';

import { DocumentTypes } from 'services';

const useDocumentTypes = () => {
  const [loading, setLoading] = useState(false);

  const handleGetDocumentTypes = useCallback(async (token, params = {}) => {
    try {
      setLoading(true);
      const response = await DocumentTypes.getDocumentTypes(token, params);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetDocumentType = useCallback(async (id, token) => {
    try {
      setLoading(true);
      const response = await DocumentTypes.getDocumentType(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateDocumentType = useCallback(async (data, token) => {
    try {
      setLoading(true);
      const response = await DocumentTypes.createDocumentType(data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateDocumentType = useCallback(async (id, data, token) => {
    try {
      setLoading(true);
      const response = await DocumentTypes.updateDocumentType(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteDocumentType = useCallback(async (id, token) => {
    try {
      setLoading(true);
      const response = await DocumentTypes.deleteDocumentType(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    handleGetDocumentTypes,
    handleGetDocumentType,
    handleCreateDocumentType,
    handleUpdateDocumentType,
    handleDeleteDocumentType
  };
};

export default useDocumentTypes;