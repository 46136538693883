import * as Yup from 'yup'

const initialValues = {
  id: 0,
  name: '',
  entity: '',
  icon: '',
}

const schema = Yup.object().shape({
  id: Yup.number().required('Campo obligatorio'),
  name: Yup.string().required('* Ingrese un nombre'),
  entity: Yup.string().required('* Seleccione una entidad'),
})

export {
  initialValues,
  schema
}