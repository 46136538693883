import { useState, useCallback, useEffect } from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useBusinessUnits, useBudgets, useCustomerSuppliers, useSellers, usePaymentConditions, useBillingTypes } from 'hooks'
import { InputBS, Label, SelectField, Error, Checkbox } from 'components'

import { BudgetStatusI, BillingTypesI } from 'interfaces'
import { formatInputDate } from 'utilities'

const GeneralTab = () => {
  const { values, setFieldValue, setFieldTouched, handleReset } = useFormikContext();
  const intl = useIntl()

  const { handleGetBudgets } = useBudgets()
  const [budgets, setBudgets] = useState([])

  const { handleGetCustomerSuppliers } = useCustomerSuppliers()
  const [customerSuppliers, setCustomerSuppliers] = useState([])

  const { handleGetBusinessUnits } = useBusinessUnits()
  const [businessUnits, setBusinessUnits] = useState([])

  const { handleGetSellers } = useSellers()
  const [sellers, setSellers] = useState([])

  const { handleGetPaymentConditions } = usePaymentConditions()
  const [paymentConditions, setPaymentConditions] = useState([])

  const { handleGetBillingTypes } = useBillingTypes()
  const [billingTypes, setBillingTypes] = useState([])

  const getBudgets = useCallback(async () => {
    try {
      const response = await handleGetBudgets('token', { budget_status_id: BudgetStatusI.PEDIDO })
      const data = response.data.result
      setBudgets(data)
    } catch (error) {
      setBudgets([])
      toast.error(error.message)
    }
  }, [handleGetBudgets])

  const getCustomerSuppliers = useCallback(async () => {
    try {
      const response = await handleGetCustomerSuppliers('token', '', { is_enabled: 1, is_customer: 1})
      setCustomerSuppliers(response.data.result)
    } catch (error) {
      setCustomerSuppliers([])
      toast.error(error.message)
    }
  }, [handleGetCustomerSuppliers])

  const getBusinessUnits = useCallback(async () => {
    try {
      const response = await handleGetBusinessUnits({ order_by: 'ASC' })
      setBusinessUnits(response.data.result)
    } catch (error) {
      setBudgets([])
      toast.error(error.message)
    }
  }, [handleGetBusinessUnits])

  const getSellers = useCallback(async () => {
    try {
      const response = await handleGetSellers('token', '?is_enabled=1')
      setSellers(response.data.result)
    } catch (error) {
      setSellers([])
      toast.error(error.message)
    }
  }, [handleGetSellers])

  const getPaymentConditions = useCallback(async () => {
    try {
      let params = {
        // is_enabled: 1
      }
      const response = await handleGetPaymentConditions(params)
      setPaymentConditions(response.result)
    } catch (error) {
      setPaymentConditions([])
      toast.error(error.message)
    }
  }, [handleGetPaymentConditions])

  const getBillingTypes = useCallback(async () => {
    try {
      let params = {
        is_active: 1,
      }
      const response = await handleGetBillingTypes(params)
      setBillingTypes(response.data.result.map(d => ({ value: d.id, label: d.label })))
    } catch (error) {
      setBillingTypes([])
      toast.error(error.message)
    }
  }, [handleGetBillingTypes])

  useEffect(() => {}, [values.budget_id, values.customer_supplier_id])

  useEffect(() => {
    getBusinessUnits()
    getBudgets()
    getCustomerSuppliers()
    getSellers()
    getPaymentConditions()
    getBillingTypes()
  }, [getBusinessUnits, getBudgets, getCustomerSuppliers, getSellers, getPaymentConditions, getBillingTypes])

  return (
    <>
      {/* <div className='row mb-8'>
        <div className='col-2'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='has_budget' label={intl.formatMessage({ id: 'FORM.SALES_ORDERS.GENERAL.BUDGET' })} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='has_budget' />
              <Label htmlFor='has_budget' label={intl.formatMessage({ id: 'FORM.SALES_ORDERS.GENERAL.HAS_BUDGET' })} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        {values.has_budget
          ?
            <div className='col-4'>
              <Label label={intl.formatMessage({ id: 'FORM.SALES_ORDERS.GENERAL.BUDGET' })} htmlFor='budget_id' />
              <Typeahead
                id='budget_id'
                name='budget_id'
                minLength={1}
                labelKey={option => `Presupuesto #${option.id}`}
                filters={() => true}
                options={budgets}
                promptText='Buscar presupuesto'
                searchText='Buscando...'
                placeholder='Buscar presupuesto'
                emptyLabel='No se encontró el presupuesto'
                onChange={(selected) => {
                  selected && selected.length > 0 && setFieldValue('budget', `Presupuesto #${selected[0].id}`)
                  if (!selected || selected.length === 0) {
                    handleReset()
                    return
                  }

                  const value = selected[0]
                  setFieldValue('budget_id', value.id)
                  setFieldValue('budget', `Presupuesto #${value.id}`)
                  setFieldValue('customer_supplier_id', value.customer_supplier_id)
                  setFieldValue('customer_supplier_name', value.customer_supplier_business_name)
                  setFieldValue('business_unit_id', value.business_unit_id)
                  setFieldValue('seller_id', value.seller_id)
                  setFieldValue('payment_condition_id', value.payment_condition_id)
                  setFieldValue('dispatch_date', formatInputDate(value.dispatch_date))
                  setFieldValue('de_blister', Boolean(value.de_blister))
                  setFieldValue('billing_type_id', value.billing_type_id)
                  setFieldValue('billing_date', formatInputDate(value?.billing_date))
                }}
                onBlur={e => setFieldTouched('budget_id', true)}
                defaultInputValue={values.budget}
              />
              <ErrorMessage className='text-danger' name='budget_id' render={msg => <Error message={msg} />} />
            </div>
          : <div className='col-4'></div>
        }
      </div> */}

      <div className='row my-8'>
        <div className='col-6'>
          <Label label={intl.formatMessage({ id: 'FORM.SALES_ORDERS.GENERAL.CUSTOMER' })} htmlFor='customer_supplier_id' />
          <Typeahead
            id='customer_supplier_id'
            name='customer_supplier_id'
            minLength={1}
            labelKey={option => `#${option.id} | ${option.business_name}`}
            filters={['id', 'business_name']}
            options={customerSuppliers}
            promptText='Buscar cliente'
            searchText='Buscando...'
            placeholder={values.customer_supplier_name ? values.customer_supplier_name : 'Buscar cliente'}
            emptyLabel='No se encontró el cliente'
            onChange={(selected) => {
              if (!selected || selected.length === 0) {
                setFieldValue('customer_supplier_id', '')
                setFieldValue('customer_supplier_name', '')
                setFieldValue('seller_id', '')
                setFieldValue('payment_condition_id', '')
                setFieldValue('billing_type_id', '')
                setFieldValue('price_update_id', '')
                setFieldValue('patient_id', '')
                setFieldValue('address_id_traza', '')
                setFieldValue('address_id_ship', '')
                setFieldValue('de_blister', '')
              } else {
                const value = selected[0]
                setFieldValue('customer_supplier_id', value.id)
                setFieldValue('customer_supplier_name', value.business_name)
                setFieldValue('seller_id', value?.seller_id && value.seller.is_enabled ? value.seller_id : '')
                setFieldValue('payment_condition_id', value.payment_condition_id)
                setFieldValue('billing_type_id', value?.billing_type_id ? value.billing_type_id : '')
                setFieldValue('price_update_id', value?.price_update_id ? value.price_update_id : '')
                setFieldValue('de_blister', value?.de_blister ? value.de_blister : '')
              }
            }}
            onBlur={e => setFieldTouched('customer_supplier_id', true)}
            defaultInputValue={values.customer_supplier_name}
          />
          <ErrorMessage className='text-danger' name='customer_supplier_id' render={msg => <Error message={msg} />} />
        </div>

        <div className='col-3'>
          <SelectField
            id='business_unit_id'
            name='business_unit_id'
            label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.BUSINESS_UNIT' })}
            options={[{ value: '', label: 'Seleccione una unidad de negocio' }, ...businessUnits.map(item => ({ value: item.id, label: item.name }))]}
          />
        </div>

        {values.customer_supplier_id &&
          <div className='col-3'>
            <SelectField
              id='seller_id'
              name='seller_id'
              label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.SELLER' })}
              options={[{ value: '', label: 'Seleccione un vendedor' }, ...sellers.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))]}
              disabled={!values.customer_supplier_id}
            />
          </div>
        }
      </div>

      <div className='row my-8'>
        {values.customer_supplier_id &&
          <div className='col-3'>
            <SelectField
              id='payment_condition_id'
              name='payment_condition_id'
              label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.PAYMENT_CONDITION' })}
              options={[{ value: '', label: 'Seleccione una condición de pago' }, ...paymentConditions.map(item => ({ value: item.id, label: item.label }))]}
              disabled={!values.customer_supplier_id}
            />
          </div>
        }

        <div className='col-3'>
          <InputBS id='issue_date' name='issue_date' type='date' label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.ISSUE_DATE' })} disabled />
        </div>

        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='bidding' label={'Extras'} />

            {/* <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='de_blister' />
              <Label htmlFor='de_blister' label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.DE_BLISTER' })} styles='mb-0 ms-4' />
            </div> */}

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='bidding' />
              <Label htmlFor='bidding' label={'Licitación'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        {Boolean(values.bidding) &&
          <div className='col-3'>
            <InputBS id='bidding_value' name='bidding_value' label='Nro. Licitación' />
          </div>
        }
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <InputBS id='purchase_order' name='purchase_order' label='Nro. Orden de compra' />
        </div>

        <div className='col-3'>
          <SelectField
            id='billing_type_id'
            name='billing_type_id'
            label='Facturación'
            options={billingTypes}
          />
        </div>

        {/* {values.billing_type_id === BillingTypesI.DIFERIDA &&
          <div className='col-3'>
            <InputBS id='billing_date' name='billing_date' type='date' label='Fecha' />
          </div>
        } */}
      </div>
    </>
  )
}

export default GeneralTab