import * as Yup from 'yup'

const createInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
}

const createSchema = Yup.object().shape({
  first_name: Yup.string().required('* Ingrese un nombre'),
  last_name: Yup.string().required('* Ingrese un apellido'),
  email: Yup.string().email('Invalid email').required('* Ingrese un email'),
})

export {
  createInitialValues,
  createSchema,
}