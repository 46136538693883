import { useState, useCallback, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useArticles } from 'hooks'

import { FormTable } from 'components'

const StockModal = ({ show, onHide, article }) => {
  const { handleGetStock } = useArticles()
  const [stock, setStock] = useState([])

  const getStock = useCallback(async () => {
    if (show) {
      try {
        const response = await handleGetStock(article.id)
        setStock(response.data.result)
      } catch (error) {
        setStock([])
        toast.error(error.message)
      }
    }
  }, [show, article?.id, handleGetStock])

  useEffect(() => {
    getStock()
  }, [getStock])

  if (!show) return null

  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Stock de #{article.id} - {article.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormTable
          columns={[
            { header: 'GTIN', styles: 'align-center text-center' },
            { header: 'Dueño', styles: 'align-center text-center' },
            { header: 'Unidad de negocio', styles: 'align-center text-center' },
            { header: 'Disponible', styles: 'align-center text-center' },
            { header: 'No disponible', styles: 'align-center text-center' },
            { header: 'Reservado', styles: 'align-center text-center' },
            { header: 'Trazable', styles: 'align-center text-center' },
          ]}
          responsive={false}
        >
          {stock.length === 0
            ? <tr><td colSpan={5}>El artículo no tiene información de stock disponible.</td></tr>
            :
              stock.map((s, i) => (
                <tr key={`stock-${i}`}>
                  <td className='align-center text-center'>
                    <strong>{s.gtin}</strong>
                  </td>
                  <td className='align-center text-center'>
                    <strong>{s.customer_suppliers_name}</strong>
                  </td>
                  <td className='align-center text-center'>
                    <strong>{s.business_unit_name}</strong>
                  </td>
                  <td className='align-center text-center'>
                    <strong>{s.stock_available}</strong>
                  </td>
                  <td className='align-center text-center'>
                    <strong>{s.stock_not_available}</strong>
                  </td>
                  <td className='align-center text-center'>
                    <strong>{s.stock_reserved}</strong>
                  </td>
                  <td className='align-center text-center'>
                    {Boolean(s.traced)
                      ? <span className='badge bg-success text-white'>SÍ</span>
                      : <span className='badge bg-danger text-white'>NO</span>
                    }
                  </td>
                </tr>
              ))
          }
        </FormTable>

        <div className='separator my-8'></div>
        <div className='text-end'>
          <Button
            variant='secondary'
            type='button'
            onClick={() => {
              setStock([])
              onHide()
            }}
          >
            Cerrar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default StockModal
