import { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import Swal from 'sweetalert2'

import { useWaybill, useClickOutside, useDispatchOrders } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'

import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import useGetColumns from './columns'
import { initialValues, dataAdapter } from './constants'
import { ESTADOS_ORDEN_DESPACHO, labelColor } from '../List'
import { formatInputDate } from 'utilities'

import routes from 'app/routing/routes'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC',
}

const DispatchOrderDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetDispatchOrder, handleRemoveOrderDeliveryFromDispatch, handleConfirmDispatch } = useWaybill()
  const { handleGetDispatchOrder: handleGetDispatchOrderDS, handleGetDispatchOrderDetail } = useDispatchOrders()

  const [data, setData] = useState(initialValues)
  const [detail, setDetail] = useState([])
  const [detailQuery, setDetailQuery] = useState(initialObQuery)
  const [detailCount, setDetailCount] = useState(0)
  const [editing, setEditing] = useState(false)

  const { COLUMNS } = useGetColumns()

  const getData = useCallback(async () => {
    try {
      const response = await handleGetDispatchOrder({ id: id, queryString: 'add_out_orders=1' })

      const adaptedData = dataAdapter(response.data.result)

      setData(adaptedData)
    } catch (error) {
      toast.error(error.message)
      navigate(routes.LOGISTICS_DISPATCH_ORDERS)
    }
  }, [id, handleGetDispatchOrder, navigate])

  const getDispatchOrders = useCallback(async () => {
    try {
      const response = await handleGetDispatchOrderDS(id)
      let data = response.data.result
      data.label_color = labelColor(data.dispatch_order_status_id)

      setData(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetDispatchOrderDS])

  const getDispatchOrderDetail = useCallback(async () => {
    try {
      let params = {}

      if (detailQuery && detailQuery.pageNumber) {
        params = {
          ...params,
          ...detailQuery
        }
      }
      const response = await handleGetDispatchOrderDetail(id, params)

      const data = response.data.result
      const count = response.data.metadata.count

      setDetail(data)
      setDetailCount(count)
    } catch (error) {
      setDetail([])
      setDetailCount(0)
      toast.error(error.message)
    }
  }, [handleGetDispatchOrderDetail])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    if (data.dispatch_order_status_id == ESTADOS_ORDEN_DESPACHO.PENDIENTE) {
      actions.push({
        label: "Quitar del despacho",
        icon: "bi bi-box-arrow-up text-danger",
        action: actionQuitDispatch
      })
    }

    if (currentRow?.dispatch_order_status_id != ESTADOS_ORDEN_DESPACHO.CANCELADO) {
      actions.push({
        label: "Hoja de ruta",
        icon: "bi bi-download text-success",
        action: () => actionPrint(data.id)
      })
    }

    return actions
  }

  const actionQuitDispatch = async () => {
    if (currentRow) {
      const repetidos = data.detail.filter(d => d.id == currentRow.id).length;

      const result = await Swal.fire({
        title: `¿Está seguro de quitar la orden de envío ${currentRow.order_delivery_id} del despacho ${id}?`,
        text: repetidos>1?`La órden de envío tiene ${repetidos} órdenes de egreso, se quitarán todas del despacho`:'',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, quitar del despacho',
        cancelButtonText: 'No',
        customClass: { confirmButton: 'btn btn-primary m-0 ms-2', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (result.isConfirmed) {
        try {
          const params = {
            dispatch_order_id: id,
            detail_id: currentRow.id
          }
          await handleRemoveOrderDeliveryFromDispatch(params)
          toast.success('Orden de despacho cancelada correctamente')
        } catch (error) {
          toast.error(error.message)
        } finally {
          getData()
        }
      }
    }
  }

  const actionPrint = async (id) => {
    if (id) {
      try {
        const response = await handleGetDispatchOrderDetail(id, { pageNumber: 1 })

        if (response?.data?.result) {
          const datos = response.data.result.map(d => {
            let data = {
              "Despacho": d.dispatch_order_id,
              "Envío": d.order_delivery_id,
              "Domicilio": `${d.address_traza?.route} ${d.address_traza?.street_number}`,
              "Localidad": d.address_traza?.locality,
              "CP": d.address_traza?.postal_code,
              "Provincia": d.address_traza?.province,
              "Transportista": d.shipping_carrier_service?.shipping_carrier_name,
              "Servicio": d.shipping_carrier_service?.shipping_carrier_service,
              "F. Creación": moment(d.created_at).format('DD/MM/YYYY'),
            }

            return data
          })

          var ws = XLSX.utils.json_to_sheet(datos)
          var wb = XLSX.utils.book_new()

          XLSX.utils.book_append_sheet(wb, ws, "Hoja de Ruta")
          XLSX.writeFile(wb, `Despacho ${id} - ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        availableActions().length > 0
          ? <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
          : <></>
      )
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: detailCount,
    obQuery: detailQuery,
    setObQuery: setDetailQuery
  };

  useEffect(() => {
    getDispatchOrders()
    getDispatchOrderDetail()
  }, [getDispatchOrders, getDispatchOrderDetail])

  return (
    <>
      <DetailContainer
        title={`Orden de despacho #${id}`}
        statusV2={data &&
          <>
            <span className={`badge ${data?.label_color} justify-content-center fw-normal`} style={{ width: "150px" }}>{data.dispatch_order_status_label}</span>

            <div className='mt-7'>
              <div className='mt-7'>
                <div className='mt-7'>

                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "50px" }}>Transportista:</td>
                        <td><span className='fw-bold ps-5'>{data?.shipping_carrier_service_name}</span></td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" }}>Cant. órdenes de envío:</td>
                        <td><span className='fw-bold ps-5'>{data?.cant_envios}</span></td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" }}>Cant. órdenes de egreso:</td>
                        <td><span className='fw-bold ps-5'>{data?.cant_ordenes_egreso}</span></td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" }}>Fecha creación:</td>
                        <td><span className='fw-bold ps-5'>{moment(data.created_at).format('DD/MM/YYYY HH:mm')}</span></td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" }}>Fecha despacho:</td>
                        <td><span className='fw-bold ps-5'>{data?.dispatch_at ? moment(data.dispatch_at).format('DD/MM/YYYY HH:mm') : '-'}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        }
        buttons={
          <div className='d-flex justify-content-center align-items-center'>
            <Button variant='secondary' onClick={() => navigate(routes.LOGISTICS_DISPATCH_ORDERS)} className='me-3'><i className="ki-duotone ki-left" />Volver</Button>

            {availableActions().length > 0
              ?
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">Acciones</Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: "200px" }}>
                    {availableActions().map((data, index) => (
                      <Dropdown.Item key={index} onClick={() => data.action()}>
                        <i className={`${data.icon} pe-3`} />
                        {data.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              : <></>
            }
          </div>
        }
      >
        <Formik
          initialValues={data}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column' encType='multipart/form-data'>
                <div className='separator'></div>

                <div className='row mb-8'>
                  <div className='col'>

                  <TableContainer>
                    <CustomTable columns={columns} data={detail} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
                  </TableContainer>

                  <ContextMenu
                    showContextMenu={showContextMenu}
                    setShowContextMenu={setShowContextMenu}
                    actions={availableActions()}
                    currentPos={currentPos}
                    ref={contextRef}
                  />

                  </div>
                </div>

                {editing && (
                  <div className='d-flex align-items-center justify-content-end'>
                    <Button
                      ref={cancelRef}
                      variant="secondary"
                      type='button'
                      className='me-3'
                      onClick={() => {
                        setEditing(false)
                        handleReset()
                      }}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button ref={submitRef} type='submit' variant="primary" disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default DispatchOrderDetail