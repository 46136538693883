import { useState, useCallback } from 'react';

import { PaymentMethods } from 'services';
import {
  PaymentMethodI,
  GetPaymentMethodsI,
  GetPaymentMethodI,
  CreatePaymentMethodI,
  UpdatePaymentMethodI,
  CreationResponseI,
  UpdateResponseI,
  DeleteResponseI
} from 'interfaces';

interface UsePaymentMethodsReturn {
  loading: boolean;
  handleGetPaymentMethods: (token: string) => Promise<GetPaymentMethodsI>;
  handleGetPaymentMethod: (id: PaymentMethodI['id'], token: string) => Promise<GetPaymentMethodI>;
  handleCreatePaymentMethod: (data: CreatePaymentMethodI, token: string) => Promise<CreationResponseI>;
  handleUpdatePaymentMethod: (id: PaymentMethodI['id'], data: UpdatePaymentMethodI, token: string) => Promise<UpdateResponseI>;
  handleDeletePaymentMethod: (id: PaymentMethodI['id'], token: string) => Promise<DeleteResponseI>;
}

const usePaymentMethods = (): UsePaymentMethodsReturn => {
  const [loading, setLoading] = useState(false);

  const handleGetPaymentMethods = useCallback(async (token: string) => {
    try {
      setLoading(true);
      const response = await PaymentMethods.getPaymentMethods(token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetPaymentMethod = useCallback(async (id: PaymentMethodI['id'], token: string) => {
    try {
      setLoading(true);
      const response = await PaymentMethods.getPaymentMethod(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreatePaymentMethod = useCallback(async (data: CreatePaymentMethodI, token: string) => {
    try {
      setLoading(true);
      const response = await PaymentMethods.createPaymentMethod(data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdatePaymentMethod = useCallback(async (id: PaymentMethodI['id'], data: UpdatePaymentMethodI, token: string) => {
    try {
      setLoading(true);
      const response = await PaymentMethods.updatePaymentMethod(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeletePaymentMethod = useCallback(async (id: PaymentMethodI['id'], token: string) => {
    try {
      setLoading(true);
      const response = await PaymentMethods.deletePaymentMethod(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    handleGetPaymentMethods,
    handleGetPaymentMethod,
    handleCreatePaymentMethod,
    handleUpdatePaymentMethod,
    handleDeletePaymentMethod
  };
};

export default usePaymentMethods;