import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useWaybill } from 'hooks'

import { FormTable } from 'components'

const CreateDispatchOrderModal = ({ show, onHide, shipmentOrders, handleShipmentOrders, handleGetShipmentOrders }) => {
  const [items, setItems] = useState([])

  const { handleCreateDispatchOrder, loading } = useWaybill()

  const handleSubmit = async (values) => {
    const items = values.shipment_orders.filter(item => item.checked)
    const data = {
      ...values,
      shipment_orders: items,
    }

    try {
      const response = await handleCreateDispatchOrder(data)
      toast.success("Despacho creado correctamente", {theme: "colored"})
      handleShipmentOrders([])
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleShipmentOrders([])
      handleGetShipmentOrders()
      onHide()
    }
  }

  useEffect(() => {
    setItems(shipmentOrders)
  }, [shipmentOrders])

  if (!show) return null

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Crear Orden de despacho</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            shipment_orders: items,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            const handleCheckboxChange = (index) => {
              const updated_shipment_orders = [...values.shipment_orders]
              updated_shipment_orders[index].checked = !updated_shipment_orders[index].checked
              setFieldValue('shipment_orders', updated_shipment_orders)
            }

            return (
              <Form>
                <h2 className='mb-4'>Órdenes de envío seleccionadas</h2>

                <FormTable
                  columns={[
                    { header: '', styles: 'text-center' },
                    { header: 'Nro.' },
                    { header: 'Transportista', styles: 'text-center' },
                    { header: 'Operativa', styles: 'text-center' },
                    { header: 'Servicio' },
                    { header: 'Tipo de envío', styles: 'text-center' }
                  ]}
                  responsive={false}
                >
                  {values.shipment_orders.map((item, index) => (
                    <tr key={item.id}>
                      <td className='align-middle'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            id={`flexCheckDefault-${index}`}
                            className='form-check-input'
                            type='checkbox'
                            checked={item.checked}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </div>
                      </td>
                      <td className='align-middle'>{item.id}</td>
                      <td className='text-center align-middle'>{item.shipping_carrier}</td>
                      <td className='text-center align-middle'>{item?.shipping_carrier_service_code || '-'}</td>
                      <td className='align-middle'>{item.shipping_carrier_service}</td>
                      <td className='text-center align-middle'>{item?.shipping_type || '-'}</td>
                    </tr>
                  ))}
                </FormTable>

                <div className='separator my-8'></div>
                <div className='row'>
                  <div className='col-12 text-end'>
                    <Button
                      variant='secondary'
                      type='button'
                      className='me-3'
                      onClick={() => {
                        setFieldValue('shipment_orders', shipmentOrders)
                        onHide()
                      }}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button type='submit' variant='primary' onClick={handleSubmit} disabled={loading}>
                      <i className='bi bi-save me-2' />
                      Crear
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CreateDispatchOrderModal