import { useEffect, useState } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'

import useDepots from 'hooks/Inventory/useDepots'
import { Input, SelectField } from 'components'

const optionsBusinessUnits = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Farma' },
  { value: 2, label: 'Shop' }
]

const initialValues = {
  text: "",
  storage_space_id: null,
  customer_supplier_id: null,
  business_unit_id: null,
  inventory_status_id: null
}

const InnerFilter = ({setTitle, loading})=>{
  const {values, setFieldValue} = useFormikContext();
  const {handleGetStorageSpaces, handleGetStorageOwners, handleGetStorageStatuses, handleGetWarehouses} = useDepots()
  const [optionsStorageSpaces, setOptionStorageSpaces] = useState([])
  const [optionsOwners, setOptionOwners] = useState([])
  const [optionsStatus, setOptionStatus] = useState([])
  const [optionsWarehouse, setOptionWarehouse] = useState([])


  const loadOptions = async()=>{
    const _storage = await handleGetStorageSpaces();
    const _owners = await handleGetStorageOwners();
    const _statuses = await handleGetStorageStatuses();
    const _warehouses = await handleGetWarehouses();
    _storage.unshift({id:'', name:'Todos'});
    _owners.unshift({id:'', business_name:'Todos'});
    _statuses.unshift({id:'', name:'Todos'});
    _warehouses.unshift({id:'', name:'Todos'});
    setOptionStorageSpaces(_storage?.map(i=>({...i, value:i.id, label:i.name})))
    setOptionOwners(_owners?.map(i=>({value:i.id, label:i.business_name})))    
    setOptionStatus(_statuses?.map(i=>({value:i.id, label:i.name})))   
    setOptionWarehouse(_warehouses?.map(i=>({value:i.id, label: (i.name!='Todos'?i.general_depot_name + ' - ' + i.name : 'Todos')})))
  }

  useEffect(() => {
    const _storage = optionsStorageSpaces.filter(i => i.value === values.storage_space_id)[0]

    if (_storage) {
      setFieldValue('customer_supplier_id', _storage.customer_suppliers_id)
      setFieldValue('business_unit_id', _storage.business_unit_id)
      setFieldValue('inventory_status_id', _storage.inventory_status_id)
      setFieldValue('warehouse_id', _storage.warehouse_id)
      setTitle(`depósito ${_storage.general_depot_name}, almacén ${_storage.warehouse_name}`)
    }
  }, [values.storage_space_id])

  useEffect(() => {
    loadOptions()
  }, [])

  return(
    <>
      <div className='row'>
        <div className='col-6'>
          <Input id='text' name='text' />
          <p className='text-muted'>Artículo o Ppio.Activo</p>
        </div>

        <div className='col-3'>
          <SelectField
            id='business_unit_id'
            name='business_unit_id'
            options={optionsBusinessUnits}
            disabled={values.storage_space_id > 0}
            placeholder='Selecionar unidad de negocio'
            marginTop={'-20px'}
          />
          <p className='text-muted'>Unidad de negocio</p>
        </div>

        <div className='col-3'>
          <SelectField
            id='customer_supplier_id'
            name='customer_supplier_id'
            options={optionsOwners}
            disabled={values.storage_space_id > 0}
            placeholder='Selecionar dueño'
            marginTop={'-20px'}
          />
          <p className='text-muted'>Dueño</p>
        </div>
      </div>

      <div className="row">
        <div className='col text-end'>
          <Button  variant="primary" type='submit' disabled={loading}>
            <i className="bi bi-search mb-1"/>
            Buscar
          </Button>
        </div>
      </div>

      <div className='row'></div>
    </>
  )
}

const Filter = ({ setFilter, queryMD, setQueryMD, setTitle, loading  }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        let query = '?'
        for (const key in values) {
          if (values[key] !== '') {
            if (key === 'request_type_id') {
              if(values[key] && values[key].value){
                query += `${key}=${values[key].value}&`
              }
            } else {
              if(values[key]!=null)
                query += `${key}=${values[key]}&`
            }
          }
        }
        query = query.slice(0, -1)
        setFilter(query)
        setQueryMD({...queryMD, pageNumber:1})
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <InnerFilter setTitle={setTitle} loading={loading} />
        </Form>
      )}
    </Formik>
  )
}

export default Filter