import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'

import { useBillingTypes, usePriceUpdate } from 'hooks'

import { InputBS, SelectField } from 'components'

const CustomersTab = () => {
  const { values } = useFormikContext()

  const { handleGetBillingTypes } = useBillingTypes()
  const { handleGetPriceUpdates } = usePriceUpdate()

  const [billingTypes, setBillingTypes] = useState([])
  const [priceUpdates, setPriceUpdates] = useState([])

  const getBillingTypes = useCallback(async () => {
    try {
      const response = await handleGetBillingTypes()
      setBillingTypes(response.data.result.map(d => ({ value: d.id, label: d.label })))
    } catch (error) {
      setBillingTypes([])
      toast.error(error.response)
    }
  }, [handleGetBillingTypes])

  const getPriceUpdates = useCallback(async () => {
    try {
      const response = await handleGetPriceUpdates()
      setPriceUpdates(response.data.result.map(d => ({ value: d.id, label: d.label })))
    } catch (error) {
      setPriceUpdates([])
      toast.error(error.response)
    }
  }, [handleGetPriceUpdates])

  useEffect(() => {
    getBillingTypes()
    getPriceUpdates()
  }, [getBillingTypes, getPriceUpdates])

  return (
    <>
      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='rnos' name='rnos' label={'Rnos'} />
        </div>

        <div className='col-3'>
          <SelectField
            id='billing_type_id'
            name='billing_type_id'
            label={'Facturación'}
            options={billingTypes}
            placeholder='Seleccione una facturación'
          />
        </div>

        <div className='col-3'>
          <SelectField
            id='price_update_id'
            name='price_update_id'
            label={'Actualización de precio'}
            options={priceUpdates}
            placeholder='Seleccione una opción'
          />
        </div>

        <div className='col-3'>
          <SelectField
            id='de_blister'
            name='de_blister'
            label={'Destroquelar'}
            options={[
              { value: false, label: 'NO' },
              { value: true, label: 'SÍ' },
            ]}
            placeholder='Seleccione una opción'
          />
        </div>
      </div>
    </>
  )
}

export default CustomersTab