import { useState, useCallback, useEffect } from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { useIntl } from 'react-intl'
import { Typeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'

import { useCustomerSuppliers, usePatients, useShipmentAddresses, useHomeDeliveryOptions } from 'hooks'

import { ShipmentAddressModal } from 'containers'
import { Label, Checkbox, SelectField, TextareaBS, Error, InputBS } from 'components'

import { HomeDeliveryOptionI } from 'interfaces'
import { Button } from 'react-bootstrap'

const AddresseeTab = ({ editing, handleGetSalesOrder }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const intl = useIntl()

  const [addressModal, setAddressModal] = useState(false)
  const [patientAddressInfo, setPatientAddressInfo] = useState(null)

  const { handleGetShipmentAddresses } = useShipmentAddresses()
  const [shipmentAddresses, setShipmentAddresses] = useState([])

  const { handleGetCustomerSupplierPatients, handleGetCustomerSupplierShipmentAddresses } = useCustomerSuppliers()
  const [patients, setPatients] = useState([])
  const [customerSupplierShipmentAddresses, setCustomerSupplierShipmentAddresses] = useState([])

  const { handleGetPatientAddresses, handleAddPatientAddress, handleUpdatePatientAddress } = usePatients()
  const [patientAddresses, setPatientAddresses] = useState([])

  const { handleGetHomeDeliveryOptions } = useHomeDeliveryOptions()
  const [homeDeliveryOptions, setHomeDeliveryOptions] = useState([])

  const patientAddressSubmit = async (data) => {
    try {
      let response

      if (patientAddressInfo?.id) {
        response = await handleUpdatePatientAddress(Number(values.patient_id), Number(values.address_id_ship), data)

        toast.success("Registro actualizado correctamente", {theme:"colored"})
        return
      }

      response = await handleAddPatientAddress(Number(values.patient_id), data, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getPatientAddresses()
      handleGetSalesOrder()
      setAddressModal(false)
    }
  }

  const getCustomerSupplierPatients = useCallback(async () => {
    try {
      const params = {
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierPatients(Number(values.customer_supplier_id), params)
      const data = response.data.result.map(p => ({
        ...p,
        display_name : `[${p.id}] ${p.documentation ? `${p.documentation} - ${p.first_name} ${p.last_name}` : `${p.first_name} ${p.last_name}`}${p?.affiliate_number ? ` | Nro. afiliado: ${p.affiliate_number}` : ''}`
      }))

      setPatients(data)
    } catch (error) {
      setPatients([])
      toast.error(error.message)
    }
  }, [handleGetCustomerSupplierPatients, values.customer_supplier_id])

  const getCustomerSupplierShipmentAddresses = useCallback(async () => {
    try {
      const response = await handleGetCustomerSupplierShipmentAddresses(Number(values.customer_supplier_id), 'token')
      setCustomerSupplierShipmentAddresses(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetCustomerSupplierShipmentAddresses, values.customer_supplier_id])

  const getPatientAddresses = useCallback(async () => {
    try {
      const response = await handleGetPatientAddresses(Number(values.patient_id))
      setPatientAddresses(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetPatientAddresses, values.patient_id])

  const getShipmentAddresses = useCallback(async () => {
    try {
      const response = await handleGetShipmentAddresses('token', '?is_ours=1')
      setShipmentAddresses(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetShipmentAddresses])

  const getHomeDeliveryOptions = useCallback(async () => {
    try {
      const response = await handleGetHomeDeliveryOptions()
      setHomeDeliveryOptions(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetHomeDeliveryOptions])

  useEffect(() => {
    if (values.to_patient) {
      getCustomerSupplierPatients()
    } else {
      setFieldValue('patient_id', '')
      setFieldValue('patient_name', '')
    }
  }, [values.to_patient, getCustomerSupplierPatients, setFieldValue])

  useEffect(() => {
    if (values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {
      getPatientAddresses()
      getShipmentAddresses()
    }  else if (values.home_delivery_option_id === HomeDeliveryOptionI.CLIENTE) {
      getCustomerSupplierShipmentAddresses()
      getShipmentAddresses()
    } else {
      getCustomerSupplierShipmentAddresses()

      setFieldValue('address_id_ship', '')
      setFieldValue('shipment_address_id_home_delivery', '')
    }
  }, [values.home_delivery_option_id, getPatientAddresses, getShipmentAddresses, getCustomerSupplierShipmentAddresses, setFieldValue])

  useEffect(() => {
    getHomeDeliveryOptions()
  }, [getHomeDeliveryOptions])

  /* TODO: EL CAMPO DIRECCION ES OBLIGATORIO */

  return (
    <>
      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='dispatch_date' name='dispatch_date' type='date' label={'Fecha de entrega'} disabled={!editing} />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='to_patient' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE' })} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='requires_coordination' disabled={!editing} />
              <Label htmlFor='requires_coordination' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE.COORDINATION' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='to_patient' disabled={!editing} />
              <Label htmlFor='to_patient' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE.TO_PATIENT' })} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        <div className='col-3'>
          <SelectField
            id='home_delivery_option_id'
            name='home_delivery_option_id'
            label='Entrega especial'
            options={homeDeliveryOptions.map(o => ({ value: o.id, label: o.label }))}
            disabled={!editing}
          />
        </div>

        {values.to_patient && (
          <div className='col-6'>
            <Label label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.PATIENT' })} htmlFor='patient_id' />
            <Typeahead
              id='patient_id'
              name='patient_id'
              minLength={ 3}
              labelKey={option => option?.display_name}
              options={patients}
              filterBy={['id', 'documentation', 'first_name', 'last_name', 'affiliate_number']}
              promptText={'Buscar paciente'}
              searchText='Buscando...'
              placeholder={'Buscar paciente'}
              emptyLabel={'No se encontró el paciente'}
              onChange={(selected) => {
                if (!selected || selected.length === 0) {
                  setFieldValue('patient_id', '')
                  setFieldValue('patient_name', '')
                  setFieldValue('to_patient', false)
                  setFieldValue('address_id_ship', '')
                  setFieldValue('shipment_address_id_home_delivery', '')
                } else {
                  const value = selected[0]
                  setFieldValue('patient_id', value.id)
                  setFieldValue('patient_name', `${value.first_name} ${value.last_name}`)
                }
              }}
              onBlur={e => setFieldTouched('patient_id', true)}
              defaultInputValue={values.patient_name}
              disabled={!editing}
            />
            <ErrorMessage className='text-danger' name='patient_id' render={msg => <Error message={msg} />} />
          </div>
        )}
      </div>

      <ShipmentAddressModal
        show={addressModal}
        onClose={() => setAddressModal(false)}
        values={patientAddressInfo}
        onSave={patientAddressSubmit}
      />

      {values.to_patient && values.patient_id && values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE &&
        <div className='row my-8'>

          <div className='col-6'>
            <SelectField
              id='address_id_ship'
              name='address_id_ship'
              label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })}
              options={[{ value: '', label: 'Seleccione una dirección de entrega' }, ...patientAddresses.map(address => ({ value: address.id, label: `${address.name} - ${address.address.formatted_address}` }))]}
              disabled={!editing}
            />
          </div>

          {values.address_id_ship &&
            <>
              <div className='d-flex flex-row align-items-center col' style={{ paddingTop: '20px' }}>
                <Button variant='secondary' className='me-4' 
                    onClick={() => {
                      setPatientAddressInfo(patientAddresses.find(address => address.id === values.address_id_ship))
                      setAddressModal(true)
                    }} 
                >Editar dirección</Button>

                <Button variant='primary' onClick={() => {
                    setPatientAddressInfo(null)
                    setAddressModal(true)
                  }} 
                >Crear dirección</Button>

              </div>
            </>
          }

          {!values.address_id_ship &&
            <div className='d-flex flex-row align-items-center col-3' style={{ paddingTop: '20px' }}>
              <Button variant='primary' onClick={() => {
                  setPatientAddressInfo(null)
                  setAddressModal(true)
                }} >Crear dirección</Button>
            </div>
          }
        </div>
      }

      {values.home_delivery_option_id === HomeDeliveryOptionI.CLIENTE && (
        <div className='row my-8'>
          <div className='col-12'>
            <SelectField
              id='shipment_address_id_home_delivery'
              name='shipment_address_id_home_delivery'
              label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })}
              options={[{ value: '', label: 'Seleccione una dirección de entrega' }, ...customerSupplierShipmentAddresses.map(address => ({ value: address.id, label: `${address.name} - ${address.address.formatted_address}` }))]}
              disabled={!editing}
            />
          </div>
        </div>
      )}

      <div className='row my-8'>
        <div className='col-12'>
          <SelectField
            id='address_id_traza'
            name='address_id_traza'
            label={intl.formatMessage({id: (values.home_delivery_option_id !== HomeDeliveryOptionI.NO)
              ? 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_CUSTOMER_SECONDARY'
              : 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_CUSTOMER' })}
            options={
              values.home_delivery_option_id === HomeDeliveryOptionI.NO
                ? [{ value: '', label: 'Seleccione una dirección de entrega' }, ...customerSupplierShipmentAddresses.map(address => ({ value: address.id, label: `${address.name} - ${address.address.formatted_address}` }))]
                : [{ value: '', label: 'Seleccione una dirección de trazabilidad' }, ...shipmentAddresses.map(address => ({ value: address.id, label: `${address.name} - ${address.address.formatted_address}` }))]
            }
            disabled={!editing}
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-12'>
          <TextareaBS id='observations' name='observations' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.OBSERVATIONS' })} disabled={!editing} />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-12'>
          <TextareaBS id='dispatch_observations' name='dispatch_observations' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.DISPATCH_OBSERVATIONS' })} disabled={!editing} />
        </div>
      </div>
    </>
  )
}

export default AddresseeTab