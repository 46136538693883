import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useClickOutside, usePaymentMethods } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { useGetColumns } from './constants'
import routes from 'app/routing/routes'

const PaymentMethods = () => {
  const navigate = useNavigate()

  const { handleGetPaymentMethods, handleUpdatePaymentMethod } = usePaymentMethods()
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const intl = useIntl()

  const getPaymentMethods = useCallback(async () => {
    try {
      const response = await handleGetPaymentMethods()
      setData(response.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetPaymentMethods])

  const cancelPaymentMethod = async (id, cancel) => {
    try {
      let today = new Date().toISOString()
      let data = {
        name: currentRow?.name,
        cancel_date: cancel ? `${today.split('T')[0]} ${today.split('T')[1].split('.')[0]}` : null,
      }
      await handleUpdatePaymentMethod(id, data, 'token')

      let message = `Método de pago ${cancel ? 'cancelado' : 'habilitado'} correctamente`
      toast.info(message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getPaymentMethods()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Editar",
      icon: "bi bi-pencil-square text-primary",
      action: () => navigate(`${routes.PAYMENT_METHODS}/${currentRow?.id}`)
    })

    actions.push({
      label: Boolean(currentRow?.cancel_date) ? 'Habilitar' : 'Cancelar',
      icon: `${Boolean(currentRow?.cancel_date) ? 'bi-check2-circle text-success' : 'bi-x-circle text-danger'} `,
      action: () => cancelPaymentMethod(currentRow?.id, !Boolean(currentRow?.cancel_date))
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
      ),
    },
  ], [COLUMNS])

  useEffect(() => {
    getPaymentMethods()
  }, [handleGetPaymentMethods])

  useEffect(() => {}, [data])

  return (
    <>
      <TableContainer title={intl.formatMessage({ id: 'TITLE.PAYMENT_METHODS' })} goTo={routes.PAYMENT_METHODS_NEW}>
        <CustomTable columns={columns} data={data} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default PaymentMethods