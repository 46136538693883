import { Link } from "react-router-dom"

import routes from "app/routing/routes"
import { Tooltip } from "components"

const COLUMNS =  [
  {
    Header: "ID Artículo",
    id: 'product_var_id',
    accessor: (row) => (
      <div className=''>
        <Tooltip text='Ver'>
          <Link
            to={`${routes.INVENTORY_STOCK}/${row.product_var_id}`}
            state={{
              product_var_id: row.product_var_id,
              business_unit_id: row.business_unit_id || 1,
              customer_supplier_id: row.customer_supplier_id || 1,
              business_unit_name: row.business_unit_name,
              customer_suppliers_name: row.customer_suppliers_name,
              stock_available: row.stock_available,
              stock_reserved: row.stock_reserved,
              stock_not_available: row.stock_not_available,
            }}
            className='ml-2'
          >
            {row.product_var_id}
          </Link>
        </Tooltip>
      </div>
    ),
  },
  {
    Header: "GTIN",
    accessor: 'gtin',
  },
  {
    Header: "Nombre",
    id:"name",
    accessor: 'name',
    hasOrder:true
  },
  {
    Header: <div className='text-center'>Trazable</div>,
    id: 'traced',
    accessor: (row) => (
      <div className="text-center">
        <span className={`badge badge-${Boolean(row.traced) ? 'success' : 'danger'}`}>
          {Boolean(row.traced) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Negocio</div>,
    id: 'business_unit_name',
    accessor: (row) => (
      <div className="text-center">
        {row.business_unit_name}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Dueño</div>,
    id: "customer_suppliers_name",
    accessor: (row) => (
      <div className="text-center">
        {row.customer_suppliers_name}
      </div>
    ),
    hasOrder:true
  },
  {
    Header: <div className='text-center'>Disponible</div>,
    id:"stock_available",
    accessor: (row)=>(
      <div className='text-center'>
      {+row.stock_available}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Reservado</div>,
    id:"stock_reserved",
    accessor: (row)=>(
      <div className='text-center'>
      {+row.stock_reserved}
      </div>
    )
  },
  {
    Header: <div className='text-center'>No disponible</div>,
    id:"stock_not_available",
    accessor: (row)=>(
      <div className='text-center'>
      {+row.stock_not_available}
      </div>
    ),
  },
]

export {
  COLUMNS
}