import { useState, useRef, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useHealthInsurances } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer } from 'containers'
import { InputBS, SelectField } from 'components'

import { Actions } from './partials'
import { updateInitialValues, updateSchema } from './constants'

import routes from 'app/routing/routes'

const HealthInsuranceDetail= () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const [initialValues, setInitialValues] = useState(updateInitialValues)
  const [editing, setEditing] = useState(false)

  const { loading, handleGetHealthInsurance, handleUpdateHealthInsurance } = useHealthInsurances()

  const getHealthInsurance = useCallback(async () => {
    try {
      const response = await handleGetHealthInsurance(id, '')
      setInitialValues(response.result)
    } catch (error) {
      toast.error(error.message)
      navigate(routes.HEALTH_INSURANCES)
    }
  }, [handleGetHealthInsurance, id])

  const updateHealthInsurance = async (values) => {
    const data = {
      name: values.name,
      is_enabled: Boolean(values.is_enabled)
    }

    try {
      const response = await handleUpdateHealthInsurance(Number(id), data, 'token')

      toast.success(response.message)
      setEditing(false)
    } catch (error) {
      toast.error(error.message)
      setEditing(true)
    } finally {
      getHealthInsurance()
    }
  }

  const handleEdit = () => {
    setEditing(true)
  }

  useEffect(() => {
    getHealthInsurance()
  }, [getHealthInsurance])

  return (
    <>
      <DetailContainer
        title={`Detalle de obra social #${id}`}
        buttons={<Actions editing={editing} handleEdit={handleEdit} submitRef={submitRef} cancelRef={cancelRef} />}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={updateSchema}
          onSubmit={updateHealthInsurance}
          enableReinitialize
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className="d-flex flex-column">
                <div className='row mt-8'>
                  <div className='col-6'>
                    <InputBS id='name' name='name' label={intl.formatMessage({ id: 'COLUMN.HEALTH_INSURANCES.NAME' })} disabled={!editing} />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-2'>
                    <SelectField
                      id='is_enabled'
                      name='is_enabled'
                      label={'Habilitada'}
                      options={[ { value: 0, label: 'NO' }, { value: 1, label: 'SÍ' } ]}
                      disabled={!editing}
                    />
                  </div>
                </div>

                {editing && (
                  <>
                    <div className='separator my-8'></div>

                    <div className='d-flex align-items-center justify-content-end'>
                      <Button
                        ref={cancelRef}
                        variant='secondary'
                        type='button'
                        className='me-3'
                        onClick={() => {
                          setEditing(false)
                          handleReset()
                        }}
                      >
                        <i className="bi bi-slash-circle" />
                        Cancelar
                      </Button>

                      <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </div>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default HealthInsuranceDetail