import { useState, useEffect, useCallback } from 'react'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs';

import { useSalesOrders, useReports } from 'hooks'

import { Input, SelectField } from 'components'

import { SalesOrderStatusI } from 'interfaces'

import { labelColor } from 'pages/Sells/Orders/List'

const initialFormikValues = {
  sales_order_id: '',
  text: '',
  article_text: '',
  cantidad_remitido: '',
  cantidad_reservado: '',
  cantidad_faltante: '',
  sales_order_status_id_filter: [SalesOrderStatusI.FALTA_STOCK, SalesOrderStatusI.RESERVA_PARCIAL],
}

const Filter = ({ setData, queryMD, setQueryMD, setTotalCount }) => {
  const { loading, handleGetReporteAnalisStock } = useReports()

  const { handleGetSalesOrderStatus } = useSalesOrders()
  const [statusOptions, setStatusOptions] = useState([])

  const filterData = async (values) => {
    let params = {
      ...queryMD,
      ...values,
      id: values?.id ? values.id : undefined,
      text: values?.text ? values.text : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      cantidad_remitido: values?.cantidad_remitido ? values.cantidad_remitido : undefined,
      cantidad_reservado: values?.cantidad_reservado ? values.cantidad_reservado : undefined,
      cantidad_faltante: values?.cantidad_faltante ? values.cantidad_faltante : undefined,
      sales_order_status_id_filter: values?.sales_order_status_id_filter ? values.sales_order_status_id_filter : undefined,
    }

    try {
      const response = await handleGetReporteAnalisStock(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.EstadoPedidoId),
      }))

      setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  const exportData = async (values) => {
    try {
      const params = {
        ...queryMD,
        ...values,
        sales_order_id: values?.sales_order_id ? values.sales_order_id : undefined,
        text: values?.text ? values.text : undefined,
        cantidad_remitido: values?.cantidad_remitido ? values.cantidad_remitido : undefined,
        cantidad_reservado: values?.cantidad_reservado ? values.cantidad_reservado : undefined,
        cantidad_faltante: values?.cantidad_faltante ? values.cantidad_faltante : undefined,
        article_text: values?.article_text ? values.article_text : undefined,
        sales_order_status_id_filter: values?.sales_order_status_id_filter ? values.sales_order_status_id_filter : undefined,
        pageNumber: 1,
        pageSize: undefined
      }

      const response = await handleGetReporteAnalisStock(params)

      if (response.data.result) {
        const datos = response.data.result.map(d => {
          let data = {
            ...d,
            PedidoFecha: moment(d.PedidoFecha).format('DD/MM/YYYY'),
            FechaAut: d?.FechaAut ? moment(d.FechaAut).format('DD/MM/YYYY HH:mm') : '',
            ProxIngresoEstim: d?.ProxIngresoEstim ? moment(d.ProxIngresoEstim).format('DD/MM/YYYY') : '',
          }

          return data;
        })

        var ws = XLSX.utils.json_to_sheet(datos);

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Análisis de stock");
        XLSX.writeFile(wb, `Análisis de stock ${moment().format("YYYYMMDD-HHmm")}.xlsx`);
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getSalesOrderStatus = useCallback(async () => {
    let params = {}

    try {
      const response = await handleGetSalesOrderStatus(params)

      const statuses = response.data.result
        // .filter(s=>s.name!='RESERVA_PARCIAL')
        .map(s => {
          return {
            value: s.id,
            label: s.label,
          }
        })

      setStatusOptions(statuses)
    } catch (error) {
      setStatusOptions([])
      toast.error(error.message)
    }
  }, [handleGetSalesOrderStatus])

  useEffect(() => {
    getSalesOrderStatus()
  }, [getSalesOrderStatus])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={filterData}
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='sales_order_id' name='sales_order_id' />
              <p className='text-muted'>ID Pedido</p>
            </div>

            <div className="col-5">
              <Input id='text' name='text' />
              <p className='text-muted'>ID Cliente / Razón social / Documento</p>
            </div>

            <div className='col-5'>
              <Input id='article_text' name='article_text' />
              <p className='text-muted'>ID Artículo / Artículo / SKU / Droga / Laboratorio</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <Input id='cantidad_remitido' name='cantidad_remitido' />
              <p className='text-muted'>Cant. remitido mayor a</p>
            </div>

            <div className='col-2'>
              <Input id='cantidad_reservado' name='cantidad_reservado' />
              <p className='text-muted'>Cant. reservado mayor a</p>
            </div>

            <div className='col-2'>
              <Input id='cantidad_faltante' name='cantidad_faltante' />
              <p className='text-muted'>Cant. pendiente mayor a</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='sales_order_status_id_filter'
                name='sales_order_status_id_filter'
                options={statusOptions || []}
                isMulti
                marginTop={'-20px'}
                placeholder='Seleccione un estado'
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className="row">
            <div className='col-12 text-end'>
              <Button type='button' variant='secondary' onClick={() => exportData(values)} className='me-3' disabled={loading}>
                <i className='bi bi-download pe-3' />
                Exportar
              </Button>

              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm()
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter