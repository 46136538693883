import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useProducts, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './constants'
import Filter from './Filter'

import routes from 'app/routing/routes'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const Products = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetProductsV2, handleDeleteProduct} = useProducts()
  const [data, setData] = useState([])

  const [filter, setFilter] = useState("");
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0);

  const getProducts = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber){
        params = {
          ...params,
          ...queryMD,
        }
      }

      if (currentUser && currentUser?.products) {
        const { products } = currentUser

        params = {
          ...params,
          id_filter: products?.id_filter || '',
          name: products?.name || '',
          maker_name: products?.maker_name || '',
          family_name: products?.family_name || '',
          product_type_id: products?.product_type_id || '',
        }
      }

      const response = await handleGetProductsV2(params)
      const data = response.data.result;

      setData(data)
      setTotalCount(response.data.metadata.count);
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetProductsV2, queryMD, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-pencil-square text-primary fs-3",
      action: () => navigate(`${routes.PRODUCTS}/${currentRow.id}`)
    })

    actions.push({
      label: 'Eliminar',
      icon: 'bi bi-trash text-danger fs-3',
      action: () => deleteProduct(currentRow)
    })

    return actions
  }

  const deleteProduct = async (row) => {
    try {
      if (row) { // CANCELADO o RECHAZADO
        const msg = `Va a eliminar el producto [${row.id}] ${row.name}, ¿confirma?`

        const resp = await Swal.fire({
          title: "Advertencia",
          text: msg,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, eliminar",
          customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          await handleDeleteProduct(row.id)
          toast.success('Producto eliminado correctamente')
        }
      }
    } catch (err) {
      toast.error(err.message)
    } finally {
      getProducts()
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD ,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    getProducts()
  }, [getProducts])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Productos'}
        goTo={routes.PRODUCTS_NEW}
        filter={<Filter setData={setData} setTotalCount={setTotalCount} setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default Products