import { useState, useCallback, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { DetailContainer } from 'containers'
import { SelectField } from 'components'

import { useDocumentTypes } from 'hooks'
import { Actions, IconVisualizer } from '../partials'

import { initialValues, schema } from './constants'

import routes from 'app/routing/routes'
import { Button } from 'react-bootstrap'
import { InputBS } from 'components'
import { documentTypeEntitiesSelect } from 'interfaces'

const DocumentTypeDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { loading, handleGetDocumentType, handleUpdateDocumentType } = useDocumentTypes()
  const [documentType, setDocumentType] = useState(initialValues)
  const [edit, setEdit] = useState(false)

  const getDocumentType = useCallback(async () => {
    try {
      const response = await handleGetDocumentType(id, 'token')

      setDocumentType({
        ...response.result,
      })
    } catch (error) {
      toast.error(error.message)
    }
  }, [id, handleGetDocumentType])

  const updateDocumentType = async (values) => {
    try {
      let data = { ...values }
      delete data.id

      const response = await handleUpdateDocumentType(id, data, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setEdit(false)
      getDocumentType()
    }
  }

  useEffect(() => {
    getDocumentType()
  }, [getDocumentType])

  useEffect(() => {}, [documentType])

  if (!id) {
    navigate(routes.DOCUMENT_TYPES)
  }

  return (
    <>
      <DetailContainer
        title={`Detalle del tipo de documento #${id}`}
        buttons={<Actions editing={edit} handleEdit={setEdit} submitRef={submitRef} cancelRef={cancelRef} />}
      >
        <Formik
          initialValues={documentType}
          validationSchema={schema}
          onSubmit={updateDocumentType}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleReset }) => {
            return (
              <Form className='d-flex flex-column'>
                <div className='row my-8'>
                  <div className='col-6'>
                    <InputBS id='name' name='name' label='Nombre' disabled={!edit} />
                  </div>

                  <div className='col-6'>
                    <SelectField
                      id='entity'
                      name='entity'
                      lable='Entidad'
                      options={documentTypeEntitiesSelect}
                      placeholder='Seleccione una entidad'
                      disabled={!edit}
                    />
                  </div>
                </div>

                <div className='row my-8'>
                  <IconVisualizer icon={values.icon} />

                  <div className='col-8'>
                    <InputBS id='icon' name='icon' label={intl.formatMessage({ id: 'FORM.DOCUMENT_TYPES.ICON' })} disabled={!edit} />
                  </div>

                  <div className='col-2'>
                    <ul className='list-unstyled'>
                      <li>- <a href='https://icons.getbootstrap.com/' target='__blank'>Bootstrap</a></li>
                      <li>- <a href='https://fontawesome.com/icons' target='__blank'>Font Awesome</a></li>
                      <li>- <a href='https://icons8.com/line-awesome' target='__blank'>Line Awesome</a></li>
                    </ul>
                  </div>
                </div>

                {edit && (
                  <>
                    <div className='separator my-8'></div>

                    <div className='d-flex align-items-center justify-content-end'>
                      <Button
                        ref={cancelRef}
                        variant='secondary'
                        type='button'
                        className='me-3'
                        onClick={() => {
                          setEdit(false)
                          handleReset()
                        }}
                      >
                        <i className="bi bi-slash-circle" />
                        Cancelar
                      </Button>

                      <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </div>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default DocumentTypeDetail
