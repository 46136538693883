import service from '../service'

const PATH = {
  provinces: '/provinces',
}

export class Provinces {
  static getProvinces = async (params) => {
    const response = await service.get(PATH.provinces, {
      params,
    })

    return response
  }
}