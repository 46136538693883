import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { FormLabel } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useMaps, useProvinces } from 'hooks'
import { InputBS, SwitchField, SelectField } from 'components'

const MapDirection = ({ editing = false }) => {
  const intl = useIntl()

  const { values, setFieldValue } = useFormikContext()

  const { getAutocompleteOptions, getAddressDetail } = useMaps()

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [address, setAddress] = useState(null)

  const { handleGetProvinces } = useProvinces()
  const [provinces, setProvinces] = useState([])

  const setSingleSelections = async (e) => {
    try {
      if (e[0].place_id) {
        getAddressDetail(e[0].place_id).then(res => {
          setAddress(res)

          setFieldValue('address.place_id', e[0].place_id ?? '')
          setFieldValue('address.route', res.route ?? '')
          setFieldValue('address.street_number', res.street_number ?? '')
          setFieldValue('address.floor', res.floor ?? '')
          setFieldValue('address.sublocality_level_1', res.sublocality_level_1 ?? '')
          setFieldValue('address.administrative_area_level_1', res.administrative_area_level_1 ?? '')
          setFieldValue('address.administrative_area_level_2', res.administrative_area_level_2 ?? '')
          setFieldValue('address.locality', res.locality ?? '')
          setFieldValue('address.postal_code', res.postal_code ?? '')
          setFieldValue('address.postal_code_suffix', res.postal_code_suffix ?? '')
          setFieldValue('address.country', res.country ?? '')
          setFieldValue('address.latitude', res.latitude ?? 0)
          setFieldValue('address.longitude', res.longitude ?? 0)
          setFieldValue('address.google_result', res.google_result ?? '')
          setFieldValue('address.origin', res.origin ?? '')
          setFieldValue('address.formatted_address', res.formatted_address ?? '')
          setFieldValue('address.locality_custom', res.locality_custom ?? '')
          setFieldValue('address.custom_address_level1_label', res.custom_address_level1_label ?? '')
          setFieldValue('address.custom_address_level1', res.custom_address_level1 ?? '')
          setFieldValue('address.custom_address_level2_label', res.custom_address_level2_label ?? '')
          setFieldValue('address.custom_address_level2', res.custom_address_level2 ?? '')
        })
      }
    } catch (err) {
      toast.error(err.message)
    }
  }

  const handleSearch = (query) => {
    setIsLoading(true)

    getAutocompleteOptions(query)
      .then(res => {
        setOptions(res)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getProvinces = useCallback(async () => {
    try {
      const response = await handleGetProvinces()
      setProvinces(response.data.result.map(p => ({ value: p.label, label: p.label })))
    } catch (error) {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    if (values.address) {
      setAddress(values.address)
    }
  }, [values.address, setAddress])

  // useEffect(() => {
  //   if (Boolean(values.address.manual)) {
  //     getProvinces()
  //   }
  // }, [values.address.manual])

  useEffect(()=>{
    getProvinces()
  }, [])

  return (
    <>
      {!editing &&
        <div className='row mb-8'>
          <div className='col-8'>
            <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>{intl.formatMessage({ id: 'MAP.ADDRESS' })}</FormLabel>
            <AsyncTypeahead
              id='buscadorLinkAlfabeta'
              isLoading={isLoading}
              minLength={3}
              labelKey={(option) => `${option.description}`}
              onSearch={handleSearch}
              options={options}
              filterBy={() => true}
              promptText='Escribe para buscar un domicilio'
              searchText='Buscando...'
              placeholder='Escribe para buscar un domicilio'
              onChange={(e) => setSingleSelections(e)}
              disabled={editing}
              defaultInputValue={
                values?.address?.id && values?.address?.origin === 'GOOGLE'
                  ? `${values.address.route} ${values.address.street_number}, ${values.address.postal_code}, ${values.address.locality}, ${values.address.administrative_area_level_2}, ${values.address.administrative_area_level_1}, ${values.address.country}`
                  : ''
              }
            />
          </div>

          <div className='col-4'>
            <div className='d-flex' style={{ paddingTop: '32px' }}>
              <SwitchField id='address.manual' name='address.manual' />
              <span style={{ fontSize: '1.1rem' }}>{intl.formatMessage({ id: 'MAP.MANUAL' })}</span>
            </div>
          </div>
        </div>
      }

      {(address || values.address.manual) &&

        <div className='d-flex justify-content-between mb-8'>

          <div className='w-45'>
            <div className='row mb-8'>
              <div className='col-12'>
                <InputBS id='address.route' name='address.route' label={intl.formatMessage({ id: 'MAP.ROUTE' })} disabled={!values.address.manual || editing} />
              </div>
            </div>

            <div className='row my-8'>
              <div className='col-6'>
                <InputBS id='address.street_number' name='address.street_number' label={intl.formatMessage({ id: 'MAP.STREET_NUMBER' })} disabled={!values.address.manual || editing} />
              </div>

              <div className='col-6'>
                <InputBS id='address.floor' name='address.floor' label={intl.formatMessage({ id: 'MAP.FLOOR' })} disabled={editing} />
              </div>
            </div>

            <div className='row my-8'>
              <div className='col-6'>
                <InputBS id='address.postal_code' name='address.postal_code' label={intl.formatMessage({ id: 'MAP.POSTAL_CODE' })} disabled={!values.address.manual || editing} />
              </div>

              <div className='col-6'>
                <InputBS
                  id='address.locality'
                  name='address.locality'
                  label={'Localidad'}
                  disabled={!values.address.manual || editing}
                />
              </div>
            </div>

            <div className='row mt-8'>
              <div className='col-6'>
                <InputBS
                  id='address.administrative_area_level_2'
                  name='address.administrative_area_level_2'
                  label={'Comuna / Partido'}
                  disabled={!values.address.manual || editing}
                  />
              </div>

              <div className='col-6'>
                {/* { Boolean(values.address.manual)
                  ? */}
                    <SelectField
                      id='address.administrative_area_level_1'
                      name='address.administrative_area_level_1'
                      options={provinces}
                      label='Provincia'
                      disabled={!values.address.manual || editing}
                      required={values.address.administrative_area_level_1 == '' || !provinces.some(p => p.value == values.address.administrative_area_level_1)}
                      placeholder='Seleccionar provincia'
                    />
                  {/* :
                    <InputBS
                      id='address.administrative_area_level_1'
                      name='address.administrative_area_level_1'
                      label={'Provincia'}
                      disabled={!values.address.manual || editing}
                    />
                } */}
              </div>
            </div>

            {values.address.origin === 'MIGRACION' &&
              <div className='row mt-8'>
                <div className='col-6'></div>
                <div className='col-6'>
                  <InputBS
                    id='address.administrative_area_level_1'
                    name='address.administrative_area_level_1'
                    label={'Provincia importación'}
                    disabled
                  />
                </div>
              </div>
            }
          </div>

          <div className='w-45 h-full' style={{ backgroundColor: '#ccc' }} />
        </div>
      }
    </>
  )
}

export default MapDirection