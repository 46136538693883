import { useState } from 'react'
import { Link } from 'react-router-dom';
import Select from 'react-select'

import { Tooltip } from 'components'

import routes from 'app/routing/routes';

import { labelColor } from 'pages/Sells/OutOrders/List'

const customStyles = {
  container: (base) => ({ ...base, width: '350px' }),
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '0.9rem',
    zIndex: 9999
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '0.9rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '0.9rem',
  }),
};

// Create an editable cell renderer
export const EditableCell = ({
    row,
    options,
    handleChange, // This is a custom function that we supplied to our table instance
    service_id
}) => {
    const initialValue = {}
    const [value, setValue] = useState(initialValue)

    const getValue = () => {
      if (options) {
        return options.find(option => option.value === service_id)
      } else {
        return ""
      }
  };

  const onChange = (val) => {
    setValue(options.find(option => option === val) )
    handleChange(val)
  };

  return (
    <Select
      options={options}
      value={getValue()}
      onChange={onChange}
      styles={customStyles}
      menuPortalTarget={document.body}
      menuPlacement='top'
    />
  )
}

const useGetColumns = () => {
  const COLUMNS =  [
    {
      Header: <div className='text-center'>Pedido</div>,
      id: 'sales_order_id',
      accessor: (row) => (
        <div className='text-center'>
          {/* <Tooltip text='Ir a pedido'>
            <Link to={`${routes.SALES_ORDERS}/${row.sales_order_id}`}> */}
              {row.sales_order_id}
            {/* </Link>
          </Tooltip> */}
        </div>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>OE</div>,
      id: 'out_order_id',
      accessor: (row) => (
        <div className='text-center'>
          <Tooltip text='Ir a OE'>
            <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>
              {row.out_order_id}
            </Link>
          </Tooltip>
        </div>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>Estado OE</div>,
      id: 'out_order_status_id',
      className:'col-status',
      accessor: (row) =>(
        <span className={`badge ${labelColor(row.out_order_status_id)} w-100 justify-content-center m-0 fw-normal`}>
          {row.out_order_status_label}
        </span>
      ),
    },
    {
      Header: "Cliente",
      id: 'customer_supplier_id',
      accessor: (row) => (
        <>
          <strong>[{row.customer_supplier_id}] {row.customer_supplier_business_name}</strong>

          {row.patient_id &&
            <p className='m-0 p-0 mt-4'><strong>Paciente:</strong> [{row.patient_id}] {row.patient_name}</p>
          }
        </>
      ),
    },
    {
      Header: <div className='text-center'>Cant. artículos</div>,
      id: 'qty',
      accessor: (row) => (
        <div className='text-center'>
          {row.qty}
        </div>
      ),
    },
    {
      Header: 'Dirección',
      id: 'address_ship.nombre',
      accessor: (row) => (
        <>
          <strong className='m-0 p-'>{row.address_ship?.nombre}</strong>
          <p className='m-0 mt-2'>{row.address_ship?.domicilio?.trim()}</p>
        </>
      ),
    },
    {
      Header: 'Localidad',
      id: 'address_ship.localidad',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.address_ship?.localidad?.trim()}</p>
      ),
    },
    {
      Header: 'Provincia',
      id: 'address_ship.provincia',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.address_ship?.provincia?.trim()}</p>
      ),
    },
    {
      Header: <div className='text-center'>Orden de envío</div>,
      id: 'delivery.id',
      accessor: (row) => (
        <div className='text-center'>
          {row?.delivery
            ?
              <Tooltip text='Ir a órden de envío'>
                <Link to={`${routes.LOGISTICS_SHIPMENT_ORDERS}/${row.delivery.id}`} target='_blank'>
                  {row.delivery.id}
                </Link>
              </Tooltip>
            : '-'
          }
        </div>
      ),
    },
    {
      Header: "Transporte",
      id: 'delivery',
      accessor: (row)=>(
        <>
          <p className='m-0 p-0'><b>{row.delivery?.shipping_carrier_name}</b></p>
          <p className='m-0 p-0'>{row.delivery?.shipping_carrier_service}</p>
        </>
      ),
    },
    {
      Header: "",
      id: "info",
      accessor: (row) => (
        <div className='d-flex'>
          {Boolean(row.has_refrigeration) &&
            <Tooltip text='Requiere frío'>
              <span className='bi bi-snow text-primary fs-3 me-2' />
            </Tooltip>
          }

          {Boolean(row.has_traced) &&
            <Tooltip text='Artículos trazables'>
              <span className='bi bi-broadcast-pin text-primary fs-3 me-2' />
            </Tooltip>
          }

          {row.remito_id &&
            <Tooltip text='Remitido'>
              <span className='bi bi-r-square text-primary fs-3 me-2' />
            </Tooltip>
          }

          {(row.afip_id && row.afip_estado) &&
            <Tooltip
              text={
                row.afip_estado == "SUCCESS"
                  ? "Facturado"
                  : (row.afip_estado.includes("SUCCESS")
                    ? "Facturado con obs."
                    : (row.afip_estado.includes("ERROR")
                      ? "Error al facturar"
                      : ""
                    )
                  )
              }
            >
              <span className='bi bi-receipt text-primary fs-3 me-2' />
            </Tooltip>
          }

          {Boolean(row.is_complete) &&
            <Tooltip text='Armada'>
              <span className='bi bi-c-square text-primary fs-3 me-2' />
            </Tooltip>
          }
        </div>
      )
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns