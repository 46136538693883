import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Tooltip } from 'components'
import { DocumentTypeEntitiesI } from 'interfaces'

import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: 'ID',
      id: 'id',
      accessor: (row) => (
        <Tooltip text='Ir a detalle'>
          <Link to={`${routes.DOCUMENT_TYPES}/${row.id}`}>{row.id}</Link>
        </Tooltip>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.DOCUMENT_TYPES.ICON' }),
      id: 'icon',
      accessor: (row) => (
        <i className={`${row.icon}`} />
      )
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.DOCUMENT_TYPES.NAME' }),
      accessor: 'name',
    },
    {
      Header: 'Entidad',
      id: 'entity',
      accessor: (row) => (
        DocumentTypeEntitiesI[row.entity]
      )
    }
  ]

  return {
    COLUMNS
  }
}