import { useState, useCallback } from 'react';

import { Sellers } from 'services';

const useSellers = () => {
  const [loading, setLoading] = useState(false);

  const handleGetSellers = useCallback(async (token, query = '', params = {}) => {
    try {
      setLoading(true)
      const response = await Sellers.getSellers(token, query, params)

      return response
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetSeller = useCallback(async (id, token) => {
    try {
      setLoading(true)
      const response = await Sellers.getSeller(id, token)

      return response.data
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateSeller = useCallback(async (data, token) => {
    try {
      setLoading(true);
      const response = await Sellers.createSeller(data, token)

      return response.data
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateSeller = useCallback(async (id, data, token) => {
    try {
      setLoading(true)
      const response = await Sellers.updateSeller(id, data, token)

      return response.data
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteSeller = useCallback(async (id, token) => {
    try {
      setLoading(true)
      const response = await Sellers.deleteSeller(id, token)

      return response.data
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetSellers,
    handleGetSeller,
    handleCreateSeller,
    handleUpdateSeller,
    handleDeleteSeller
  };
}

export default useSellers