import { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { Modal, Button, Card } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { MapDirection } from 'components'

import { addAddressInitialValues, addAddressSchema } from './constants'

const AddressModal = ({ show, onClose, values = addAddressInitialValues, onSave, submodal = false }) => {
  const [initialValues, setInitialValues] = useState(addAddressInitialValues)

  const handleClose = async () => {
    if (values?.address?.id) {
      onClose()
    } else {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `La dirección no fue guardada, ¿quiere cerrar igualmente?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si, cerrar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        onClose()
      }
    }
  }

  useEffect(() => {
    setInitialValues({
      address: {
        ...addAddressInitialValues.address,
        ...values?.address,
      },
    })
  }, [setInitialValues, values])

  if (!show) return null

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size='xl'
      backdrop='static'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{values?.address?.id ? `Editar dirección #${values.address.id}` : 'Nueva dirección'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={addAddressSchema}
          onSubmit={(data) => {
            let new_address = {
              ...data,
              origin: data.address?.id ? data.address?.origin : Boolean(data.address.manual) ? 'MANUAL' : 'GOOGLE',
            }
            onSave(new_address)
          }}
          enableReinitialize
        >
          {({ values, errors, handleSubmit }) => {
            return (
              <>
                <MapDirection handleSubmit={handleSubmit} />

                <Card.Footer className='d-flex align-items-center justify-content-end'>
                  <Button type='button' variant='secondary' className='me-3' onClick={handleClose}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button type='submit' variant='primary' onClick={handleSubmit}><i className='bi bi-save me-2' />Guardar</Button>
                </Card.Footer>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddressModal