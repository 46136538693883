import { useState, useCallback, useEffect } from 'react'
import { Formik } from 'formik'
import { Modal, Card, FormLabel } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { usePatients, useDocumentationTypes, /*useHealthInsurances*/ } from 'hooks'
import { InputBS, SelectField } from 'components'

import { addPatientInitialValues, addPatientSchema } from '../constants'

const PatientModal = ({ show, onClose, onSave }) => {
  const intl = useIntl()

  const { loading: pLoading, handleGetPatients } = usePatients()
  const [options, setOptions] = useState([])

  const { handleGetDocumentationTypes } = useDocumentationTypes()
  const [documentationTypes, setDocumentationTypes] = useState([])

  // const { handleGetHealthInsurances } = useHealthInsurances()
  // const [healthInsurances, setHealthInsurances] = useState([])

  const getPatients = useCallback(async (query) => {
      try {
          let params = {
            text: query,
          }
          const response = await handleGetPatients('', null, params)
          setOptions(response.data.result)
      } catch (error) {
          toast.error(error.message)
      }
  }, [handleGetPatients])

  const getDocumentationTypes = useCallback(async () => {
      try {
          const response = await handleGetDocumentationTypes('token')
          setDocumentationTypes(response.result)
      } catch (error) {
          toast.error(error.message)
      }
  }, [handleGetDocumentationTypes])

  // const getHealthInsurances = useCallback(async () => {
  //     try {
  //       let params = {
  //         is_enabled: 1,
  //       }
  //       const response = await handleGetHealthInsurances(params)
  //       setHealthInsurances(response.data.result)
  //     } catch (error) {
  //       toast.error(error.message)
  //     }
  // }, [handleGetHealthInsurances])

  const handleSelected = async (selected, setFieldValue) => {
    setFieldValue('id', selected.id ?? '')
    setFieldValue('documentation_type_id', selected.documentation_type_id ?? '')
    setFieldValue('documentation_type_name', selected.documentation_type_name ?? '')
    setFieldValue('health_insurance.health_insurance_id', selected.health_insurance?.health_insurance_id ?? '')
    setFieldValue('health_insurance.id', selected.health_insurance?.id ?? '')
    setFieldValue('health_insurance.affiliate_number', selected.health_insurance?.affiliate_number ?? '')
    setFieldValue('health_insurance.plan', selected.health_insurance?.plan ?? '')
    setFieldValue('documentation', selected.documentation ?? '')
    setFieldValue('first_name', selected.first_name ?? '')
    setFieldValue('last_name', selected.last_name ?? '')
  }

  useEffect(() => {
    getPatients()
    getDocumentationTypes()
    // getHealthInsurances()
  }, [getPatients, getDocumentationTypes])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'MODAL.CUSTOMER_SUPPLIERS.PATIENTS.TITLE' })}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={addPatientInitialValues}
          validationSchema={addPatientSchema}
          onSubmit={(values) => {
            if (!values.documentation && !values.health_insurance.affiliate_number) {
              toast.error('Debe completar el documento y/o nro. de afiliado del paciente')
              return
            }

            if (values.documentation && !values.documentation_type_id) {
              toast.error('Debe seleccionar un tipo de documento para el paciente')
              return
            }
            // if (
            //   (!values.documentation || values.documentation.trim() == ''|| !values.documentation_type_id) &&
            //   (!values.health_insurance || values.health_insurance.affiliate_number.trim() == '' || !values.health_insurance.health_insurance_id)
            // ) {
            //   toast.error("Ingresar tipo y nro. documento o bien tipo y nro. afiliado de obra social")
            //   return
            // }

            // En el back, si la obra social no existía y se carga acá, y no tiene id == '', no lo agrega y tira error
            // console.log(values)
            onSave({
              ...values,
              health_insurance: values.health_insurance
                ? { ...values.health_insurance, id: values.health_insurance.id ? values.health_insurance.id : '' }
                : null
              }
            )
          }}
          enableReinitialize
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <>
                <div className='row mb-8'>
                  <div className='col-6'>
                    <FormLabel style={{ fontSize: "1.05rem", fontWeight: "500", color: "var(--bs-gray-800)" }}>{intl.formatMessage({ id: 'MODAL.CUSTOMER_SUPPLIERS.PATIENTS.PATIENT' })}</FormLabel>
                    <AsyncTypeahead
                      id='patientSearch'
                      isLoading={pLoading}
                      minLength={3}
                      labelKey={(o) => `#${o.id} | ${o.first_name} ${o.last_name}${o?.documentation ? ` - ${o.documentation} ` : ''}${o?.health_insurance?.health_insurance_id ? ` | ${o.health_insurance.health_insurance_name}${o.health_insurance?.affiliate_number ? ` - ${o.health_insurance.affiliate_number}` : ''}` : `${o.health_insurance?.affiliate_number ? ` - ${o.health_insurance.affiliate_number}` : ''}`}`}
                      onSearch={getPatients}
                      options={options}
                      filterBy={() => true}
                      prompText='Escribe para buscar un paciente'
                      searchText='Buscando...'
                      placeholder='Escribe para buscar un paciente'
                      onChange={(e) => {
                        if (e && e.length > 0) {
                          handleSelected(e[0], setFieldValue)
                        }
                        else{
                          handleSelected({}, setFieldValue)

                        }
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <InputBS id='id' name='id' label={'ID'} disabled />
                  </div>
                </div>

                <div className='row my-8'>
                  <div className='col-2'>
                    <SelectField
                      id='documentation_type_id'
                      name='documentation_type_id'
                      label={"Tipo documento"}
                      options={documentationTypes.map(item => ({ value: item.id, label: item.type }))}
                      disabled={!values.id}
                      placeholder='Seleccionar'
                    />
                  </div>
                  <div className='col-4'>
                    <InputBS id='documentation' name='documentation' label={"Documento"} maxLength='11' disabled={!values.id} />
                  </div>

                  {/* <div className='col-2'>
                    <SelectField
                      id='health_insurance.health_insurance_id'
                      name='health_insurance.health_insurance_id'
                      label={'Obra social'}
                      options={healthInsurances.map(item => { return { value: item.id, label: item.name }})}
                      placeholder='Seleccionar'
                      disabled={!values.id}
                    />
                  </div> */}
                  <div className='col-6'>
                    <InputBS id='health_insurance.affiliate_number' name='health_insurance.affiliate_number' label={'Número afiliado'} maxLength='20' disabled={!values.id} />
                  </div>
                  {/* <div className='col-2'>
                    <InputBS id='health_insurance.plan' name='health_insurance.plan' label={'Plan'} maxLength='20' disabled={!values.id} />
                  </div> */}
                </div>

                <div className='row my-8'>
                  <div className='col-6'>
                    <InputBS id='first_name' name='first_name' label={"Nombre"} disabled />
                  </div>

                  <div className='col-6'>
                    <InputBS id='last_name' name='last_name' label={"Apellido"} disabled />
                  </div>
                </div>

                <Card.Footer className='d-flex flex-row justify-content-end'>
                  <button className='btn btn-primary' type='button' onClick={handleSubmit}>Vincular</button>
                </Card.Footer>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default PatientModal