import React, { useState } from 'react';
import './styles.css'
import Rack from './rack';
import { useLocation, useNavigate } from 'react-router-dom';


const WarehouseDetail = () => {
    const location = useLocation();
    const depot = location.state?.depot;


  return (
    <>
    <div className="warehouse-layout">
        <div>
            <h3>Espacio de almacenamiento {depot.name}</h3>
            <h4>{depot.cool_type_name}</h4>
        </div>

        <div className="d-flex" style={{height:"300px"}}>
            {depot?.racks?.map((rack, index)=>(
                <Rack key={index} rackData={rack} tempType={depot?.cool_type_name}>
                    
                </Rack>
            ))
            }
        </div>

    </div>

</>
  );
};

export default WarehouseDetail;