import * as Yup from 'yup'
import { currentDate, formatInputDate } from 'utilities'
import { ListCostsStatusI, ListCostsStatusColorI } from 'interfaces'
import moment from 'moment'

const initialValues = {
  id: '',
  customer_supplier_id: '',
  customer_supplier_business_name: '',
  product_var_id: '',
  product_var_name: '',
  product_id: '',
  pvp: 0.00,
  psl: 0.00,
  manual_price_on: false,
  purchase_discount_extra: 0.00000,
  list_cost: 0.00,
  date_from: '',
  date_to: '',
  initial_cost_type:0,
  initial_cost: 0.00,
  purchase_add_cost_type:0,
  purchase_add_cost: 0.00,
  purchase_add_cost_percent: 0.00000,
  created_at: '',
  canceled_at: '',
  canceled_by_user_id: '',
  canceled_by: ''
}

const schema = Yup.object().shape({
  // customer_supplier_id: Yup.number().required('* Debe seleccionar un cliente').nonNullable(),
  product_var_id: Yup.number().required('* Debe seleccionar una variación').nonNullable(),
})

const getListCostStatus = (row) => {
  if (row.date_to === null || row.date_to === '' || row.date_to > row.date_from) {
    return 'Vigente'
  } else if (row.date_to.split('T')[0] <= currentDate) {
    return 'Vencido'
  } else if (row.canceled_at !== null) {
    return 'Anulado'
  } else {
    return ''
  }
}

const getListCostStatusColor = (status) => {
  switch (status) {
    case ListCostsStatusI.VIGENTE:
      return ListCostsStatusColorI.VIGENTE;
    case ListCostsStatusI.VENCIDO:
      return ListCostsStatusColorI.VENCIDO;
    case ListCostsStatusI.ANULADO:
      return ListCostsStatusColorI.ANULADO;
    default:
      return 'info'; // Un color por defecto si el estado no coincide con ninguno de los enumerados
  }
}

const dataAdapter = (data) => {
  return {
    ...data,
    pvp: data.pvp?parseFloat(data.pvp).toFixed(2):'',
    psl: data.psl?parseFloat(data.psl).toFixed(2):'',
    date_from: data.date_from?moment(data.date_from).format("YYYY-MM-DD"):'',
    date_to: data.date_to?moment(data.date_to).format("YYYY-MM-DD"):'',
    initial_cost: data.initial_cost?parseFloat(data.initial_cost).toFixed(2):'',
    purchase_add_cost: data.purchase_add_cost?parseFloat(data.purchase_add_cost).toFixed(2):'',
    created_at: formatInputDate(data.created_at),
  }
}

const addDataAdapter = (data) => {
  let values = {
    ...data,
    purchase_discount_extra: data.initial_cost_type == 1 ? 0.00000 : Number(data.initial_cost_percent),
    list_cost: data.initial_cost_type == 1  ? Number(data.initial_cost) : 0.00,
    date_from: data.initial_cost_type == 1  ? formatInputDate(data.date_from) : null,
    date_to: null //data.manual_price_on ? formatInputDate(data.date_to) : null,
  }

  delete values.id
  delete values.customer_supplier_business_name
  delete values.product_var_name
  delete values.product_id
  delete values.pvp
  delete values.psl
  delete values.manual_price_on
  delete values.initial_cost
  delete values.purchase_add_cost
  delete values.purchase_add_cost_percent
  delete values.created_at
  delete values.canceled_at
  delete values.canceled_by_user_id
  delete values.canceled_by
  delete values.initial_cost_type
  delete values.purchase_add_cost_type
  delete values.initial_cost_percent

  return values
}

const updateDataAdapter = (data) => {
  let values = {
    ...data,
    purchase_discount_extra: data.initial_cost_type == 1 ? 0.00000 : Number(data.initial_cost_percent),
    list_cost: data.initial_cost_type == 1 ? Number(data.initial_cost) : 0.00,
    date_from: data.initial_cost_type == 1 ? formatInputDate(data.date_from) : null,
    date_to: data.initial_cost_type == 1 ? formatInputDate(data.date_to) : null,
  }

  delete values.customer_supplier_business_name
  delete values.product_var_name
  delete values.product_id
  delete values.pvp
  delete values.psl
  delete values.manual_price_on
  delete values.initial_cost
  delete values.created_at
  delete values.created_by_user_id
  delete values.canceled_at
  delete values.canceled_by_user_id
  delete values.canceled_by
  delete values.initial_cost_type
  delete values.purchase_add_cost_type
  delete values.initial_cost_percent
  
  delete values.customer_supplier_name
  delete values.purchase_discount_base
  delete values.pvp_alfabeta
  delete values.vat_rate
  delete values.purchase_vat_rate
  delete values.add_cost_percent
  delete values.add_cost
  delete values.sircreb_amount
  delete values.sircreb_rate
  delete values.total_cost
  delete values.especial

  return values

}

export {
  initialValues,
  schema,
  getListCostStatus,
  getListCostStatusColor,
  dataAdapter,
  addDataAdapter,
  updateDataAdapter,
}