import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PurchaseIncomeOrderColumnI } from 'interfaces'

import { currencyFormat, formatTableDate } from 'utilities'
import routes from 'app/routing/routes'
import moment from "moment";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


const useGetColumns = () => {

  /*
{
    "product_var_name": "BACTRIM FORTE comp.x 10",
    "product_var_id": 67,
    "pending_in_request_stock": 1,
    "stock_available": 1,
    "stock_not_available": 0,
    "stock_reserved": 0,
    "stock_needed": "9"
}
  */
  const COLUMNS = [
    {
      Header: <div className='text-center'>Artículo</div>,
      id: 'name',
      accessor: (row) => (
        <div className=''>
          {row.name}
        </div>
      ),
    },
    {
      Header: <div className=''>Droga</div>,
      id: 'droga',
      accessor: (row) => (
        <div className=''>
          {row.droga}
        </div>
      )
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>ID Artículo</div>),
      id: 'id',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.id}</div>
      </>
    },
    {
      Header: <div className='text-center'>Laboratorio</div>,
      id: 'laboratorio',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.laboratorio}</div>
      </>
    },
    {
      Header: <div className='text-center'>SKU</div>,
      id: 'sku',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.sku}</div>
      </>
    },
    {
      Header:  () => (        
        // <Tooltip text="Disponible">
          <div style={{textAlign:"center"}}>Disp</div>
        // </Tooltip>
      ),
      id: 'Disponible',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.Disponible}</div>
      </>
    },  
    {
      Header:  () => (
        // <Tooltip text="Prueba TOOLTIP">
          <div style={{textAlign:"center"}}>No Disp</div>
        // </Tooltip>
      ),
      id: 'NoDisponible',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.NoDisponible}</div>
      </>
    },  
    {
      Header: () => (
        // <Tooltip text="Prueba TOOLTIP">
          <div style={{textAlign:"center"}}>Pend Ing</div>
        // </Tooltip>
      ),
      id: 'PendienteIngreso',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.PendienteIngreso}</div>
      </>
    },
    {
      Header: () => (
        // <Tooltip text="Prueba TOOLTIP">
          <div style={{textAlign:"center"}}>
            Pend Aut
          </div>
        // </Tooltip>
        ),
      id: 'EnAutorizacion',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.EnAutorizacion}</div>
      </>
    },

    {
      Header:  () => (
        // <Tooltip text="Prueba TOOLTIP">
          <div style={{textAlign:"center"}}>Res</div>
        // </Tooltip>
      ),
      id: 'Reservado',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.Reservado}</div>
      </>
    },  
    {
      Header:  () => (
        // <Tooltip text="Prueba TOOLTIP">
          <div style={{textAlign:"center"}}>Falta</div>
        // </Tooltip>
      ),
      id: 'FaltaStock',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.FaltaStock}</div>
      </>
    },  
    {
      Header:  () => (
        <OverlayTrigger overlay={<Tooltip style={{ width: '250px'}}>{"Cajas necesarias a comprar (Falta - Pend. Ingreso - Disponible)"}</Tooltip>}>
          <div style={{textAlign:"center"}}>Comprar</div>
        </OverlayTrigger>
        ),
      id: 'Necesario',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.Necesario}</div>
      </>
    },   
    {
      Header:  () => (
        <OverlayTrigger overlay={<Tooltip style={{ width: '250px'}}>{"Cajas necesarias a comprar incluyendo pendientes de autorización (Falta + Pend. Autorización - Pend. Ingreso - Disponible)"}</Tooltip>}>
          <div style={{textAlign:"center"}}>Comprar + Aut</div>
        </OverlayTrigger>
      ),
      id: 'NecesarioMasAutorizacion',
      accessor: (row)=>
      <>
        <div className='text-center'>{row.NecesarioMasAutorizacion}</div>
      </>
    },

  ]

  return {
    COLUMNS
  }
}

export default useGetColumns