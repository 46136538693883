import { FC } from 'react'
import { FormTableI } from './types'

const FormTable: FC<FormTableI> = ({ columns, responsive = true, children }) => {
  return (
    <div className={responsive ? 'table-responsive' : ''}>
      <table className='table table-striped table-row-bordered table-row-dashed table-rounded border gy-7 gs-7'>
        <thead>
          <tr className='fw-bold fs-6 text-muted border-bottom-2 border-gray-200'>
            {columns.map((column, index) => (
              <td key={index} className={column.styles}>{column.header}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    </div>
  )
}

export default FormTable