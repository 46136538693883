import * as Yup from 'yup'

const provinces = [
  'Ciudad Autónoma de Buenos Aires',
  'Buenos Aires',
  'Catamarca',
  'Córdoba',
  'Corrientes',
  'Entre Ríos',
  'Jujuy',
  'Mendoza',
  'La Rioja',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Fe',
  'Santiago del Estero',
  'Tucumán',
  'Chaco',
  'Chubut',
  'Formosa',
  'Misiones',
  'Neuquén',
  'La Pampa',
  'Río Negro',
  'Santa Cruz',
  'Tierra del Fuego',
]

const googleAddress = {
  manual: false,
  place_id: '',
  route: '',
  street_number: '',
  floor: '',
  sublocality_level_1: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  locality: '',
  postal_code: '',
  postal_code_suffix: '',
  country: '',
  latitude: 0,
  longitude: 0,
  google_result: '',
  origin: '',
  formatted_address: '',
}

const contact = {
  name: '',
  email: '',
  has_notifications: false,
  phone: '',
  mobile_suffix: '',
  mobile: '',
  observations: '',
}

const initialValues = {
  name: '',
  address: googleAddress,
  contact: contact,
  observations: '',
  has_traceability: false,
  agent_type_id: '',
  gln: '',
  integrates_verifarma: false,
  is_ours: false,
  is_enabled: true,
}

const directionSchema = Yup.object().shape({
  manual: Yup.boolean().default(false),
  route: Yup.string(),
  street_number: Yup.string(),
  postal_code_suffix: Yup.string(),
  postal_code: Yup.string(),
  floor: Yup.string(),
  administrative_area_level_1: Yup.string().test(
    'is-province',
    '* Seleccione una provincia',
    function (value) {
      return value === '' || !provinces.some(p => p === value);
    }
  ),
  administrative_area_level_2: Yup.string(),
  country: Yup.string(),
})

const contactSchema = Yup.object().shape({
  name: Yup.string(),
  // email: Yup.string().email('* Ingrese un email válido'),
  has_notifications: Yup.boolean().default(false),
  phone: Yup.string(),
  mobile_suffix: Yup.string().matches(/^(\+)?[0-9\s]+$/, '* El sufijo debe ser numérico y puede incluir el signo + al principio'),
  // mobile: Yup.string().min(10, '* El número de celular debe tener al menos 10 dígitos').label('Celular'),
  observations: Yup.string(),
})

const schema = Yup.object().shape({
  name: Yup.string().required('* Ingrese un nombre para la dirección'),
  direction: directionSchema,
  contact: contactSchema,
  observations: Yup.string(),
  has_traceability: Yup.boolean().default(false),
  agent_type_id: Yup.string().when(['has_traceability'], {
    is: (has_traceability) => Boolean(has_traceability),
    then: (schema) => schema.required('* Seleccione un tipo de agente').nonNullable(),
    otherwise: (schema) => schema,
  }),
  gln: Yup.string().when(['has_traceability'], {
    is: (has_traceability) => Boolean(has_traceability),
    then: (schema) => schema.required('* Indique el GLN').nonNullable(),
    otherwise: (schema) => schema,
  }),
  integrates_verifarma: Yup.boolean().default(false),
  is_ours: Yup.boolean().default(false),
  is_enabled: Yup.boolean().default(true),
})

const dataAdapter = (values) => {
  let data = {
    ...values,
    address: {
      ...googleAddress,
      ...values.address,
      route: values.address?.route ? values.address.route : '',
      street_number: values.address?.street_number ? values.address.street_number : '',
      floor: values.address?.floor ? values.address.floor : '',
      postal_code: values.address?.postal_code ? values.address.postal_code : '',
      locality: values.address?.locality ? values.address.locality : '',
      administrative_area_level_1: values.address?.administrative_area_level_1 ? values.address.administrative_area_level_1 : '',
      administrative_area_level_2: values.address?.administrative_area_level_2 ? values.address.administrative_area_level_2 : '',
      manual: values.address.origin === 'MANUAL' || values.address.origin === 'MIGRACION' ? true : false,
    },
    contact: {
      ...contact,
      ...values.contact,
      name: values.contact?.name ? values.contact.name : '',
      email: values.contact?.email ? values.contact.email : '',
      has_notifications: Boolean(values.contact?.has_notifications),
      phone: values.contact?.phone ? values.contact.phone : '',
      mobile_suffix: values.contact?.mobile_suffix ? values.contact.mobile_suffix : '',
      mobile: values.contact?.mobile ? values.contact.mobile : '',
      observations: values.contact?.observations ? values.contact.observations : '',
    },
    has_traceability: Boolean(values.has_traceability),
    agent_type_id: values?.agent_type_id || '',
    agent_type_name: values?.agent_type_name || '',
    gln: values?.gln ? values.gln : '',
    integrates_verifarma: Boolean(values.integrates_verifarma),
    is_ours: Boolean(values.is_ours),
    is_enabled: Boolean(values.is_enabled),
    observations: values?.observations ? values.observations : '',
  }

  return data
}

const updateDataAdapter = (values) => {
  let data = {
    ...values,
    agent_type_id: values?.agent_type_id ? Number(values.agent_type_id) : null,
    gln: values?.gln ? values.gln : null,
  }
  delete data.locality_custom
  delete data.custom_address_level1_label
  delete data.custom_address_level2_label
  delete data.custom_address_level1
  delete data.custom_address_level2
  delete data.agent_type_name
  delete data.province_id
  delete data.province_label
  delete data.province_name

  return data
}

export {
  initialValues,
  schema,
  dataAdapter,
  updateDataAdapter,
}