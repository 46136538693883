import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useIncomeOrder } from 'hooks'

import { Input, SelectField } from 'components'

import { labelColor } from '.'

const request_type = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Pedido de Compra' },
  { value: 2, label: 'Pedido de Ingreso' }
]

const initialValues = {
  id_text: '',
  date_from: '',
  date_to: '',
  comprobante: '',
  text: '',
  documento: '',
  order_status_id: [],
  direccion: '',
  localidad: '',
  provincia: '',
  article_text: '',
}

const order_statuses = [
  { value: 1, name: 'GENERADO', label: 'Pendiente de autorización' },
  { value: 2, name: 'PENDIENTE_INGRESO', label: 'Pendiente de ingreso' },
  { value: 3, name: 'INGRESO_PARCIAL', label: 'Ingreso parcial' },
  { value: 4, name: 'VENCIDO', label: 'Vencido' },
  { value: 5, name: 'CANCELADO', label: 'Cancelado' },
  { value: 6, name: 'COMPLETO', label: 'Completo' },
  { value: 7, name: 'BORRADOR', label: 'Borrador' },
  { value: 8, name: 'AUTORIZADO', label: 'Autorizado' },
  { value: 9, name: 'CONFIRMADO', label: 'Confirmado' },
  { value: 10, name: 'COMPLETO_PARCIAL', label: 'Completo parcial' },
  { value: 11, name: 'INGRESADO', label: 'Ingresado' },
  { value: 12, name: 'RECHAZADO', label: 'Rechazado' },
  { value: 13, name: 'ERROR_TRAZA', label: 'Error traza' },
];

const Filter = ({ setData, setFilter, queryMD, setQueryMD, setTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleGetIncomeOrders } = useIncomeOrder()

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id_text: values?.id_text || undefined,
      date_from: values?.date_from || undefined,
      date_to: values?.date_to || undefined,
      comprobante: values?.comprobante || undefined,
      text: values?.text || undefined,
      documento: values?.documento || undefined,
      order_status_id: values?.order_status_id || undefined,
      direccion: values?.direccion || undefined,
      localidad: values?.localidad || undefined,
      provincia: values?.provincia || undefined,
      article_text: values?.article_text || undefined,
      pageNumber: 1,
      pageSize: 10,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = filterData(values)
      const response = await handleGetIncomeOrders(params)
      const data = response.result.map(d => ({
        ...d,
        label_color: labelColor(d.order_status_id)
      }))

      setCurrentUser((prevCurrentUser) => ({
        ...prevCurrentUser,
        in_orders: { ...params }
      }))

      setQueryMD({ ...params })
      setData(data)
      setTotalCount(response.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (currentUser?.user_id) {
      const { in_orders } = currentUser

      setInitialFormikValues({
        ...initialValues,
        id_text: in_orders?.id_text || '',
        date_from: in_orders?.date_from || '',
        date_to: in_orders?.date_to || '',
        comprobante: in_orders?.comprobante || '',
        text: in_orders?.text || '',
        documento: in_orders?.documento || '',
        order_status_id: in_orders?.order_status_id || [],
        direccion: in_orders?.direccion || '',
        localidad: in_orders?.localidad || '',
        provincia: in_orders?.provincia || '',
        article_text: in_orders?.article_text || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' disabled={loading} />
              <p className='text-muted'>Nro. / No. Pedido</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. ingreso</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. ingreso</p>
            </div>

            <div className='col-6'>
              <Input id='comprobante' name='comprobante' disabled={loading} />
              <p className='text-muted'>Remito / Factura</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='text' name='text' disabled={loading} />
              <p className='text-muted'>ID / Proveedor</p>
            </div>

            <div className='col-3'>
              <Input id='documento' name='documento' disabled={loading} />
              <p className='text-muted'>Documento</p>
            </div>

            <div className='col-3'>
              <SelectField
                id='order_status_id'
                name='order_status_id'
                options={order_statuses || []}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <Input id='direccion' name='direccion' disabled={loading} />
              <p className='text-muted'>Nombre / Domicilio / GLN</p>
            </div>

            <div className='col-4'>
              <Input id='localidad' name='localidad' disabled={loading} />
              <p className='text-muted'>Localidad</p>
            </div>

            <div className='col-4'>
              <Input id='provincia' name='provincia' disabled={loading} />
              <p className='text-muted'>Provincia</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='article_text' name='article_text' disabled={loading} />
              <p className='text-muted'>Artículo / Droga</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Buscando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter