import { useEffect, useRef, useState } from 'react';
import { KTSVG } from '_metronic/helpers';
import { useAuth } from 'app/modules/auth';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const useFocus=()=>{
    const htmlElRef=useRef(null);
    const setFocus= ()=>{htmlElRef.current && htmlElRef.current.focus()}
    return [htmlElRef, setFocus]
}

const ZebraScanner = ({ processEnter, disabled}) => {
    const [inputRef, setInputFocus] = useFocus();
    const [inputInFocus, setInputInFocus] = useState(false);
    const {currentUser, auth} = useAuth();
    const [gs1Manual, setGs1Manual] = useState("");
    let cadena="";
    const cadenaRef=useRef("");

    const handleKeyDown = (event)=>{
        event.preventDefault();
        if(event.key=='Enter'){
            processEnter(cadenaRef.current)
            cadenaRef.current='';
        }
        else{
            cadenaRef.current += event.key
        }
    }    

    const handleManualKeyDown = (event)=>{
        if(gs1Manual && gs1Manual.length>0){
            cadena=gs1Manual;
            if(event.key=='Enter'){
                event.preventDefault();
                processEnter(cadena);
                cadena="";
                inputRef.current.value="";
            }
        }
    }

    const btnClick = (ev)=>{
        ev.preventDefault();
        setInputFocus()
        setInputInFocus(true);
    }

    useEffect(()=>{
        if(!disabled){
            setInputFocus();
            setInputInFocus(true);
            setGs1Manual("")
            cadenaRef.current=''
        }
    }, [disabled])

    useEffect(()=>{
        setInputFocus();
        setInputInFocus(true);
        setGs1Manual("")
        cadenaRef.current=''
    }, [])


    return (
        <>  
            <div className='row'>
                <div className='col-10'></div>
                <div className='col-2 text-center'>

                {
                    disabled?
                        <div className='spinner-border text-success' />
                    :
                        <>
                        <a href="#" className="btn btn-icon btn-light pulse pulse-white "  onClick={btnClick} >
                            <KTSVG
                                path="/media/icons/duotune/electronics/elc007.svg"
                                className={`svg-icon svg-icon-3x ${inputInFocus?"text-success":"text-danger"}`}
                            />
                            { !inputInFocus && <span className="pulse-ring"></span>}
                        </a> 
                            <span className={`'p-3 d-block' ${inputInFocus? "":"text-danger"}`}>{inputInFocus? "Lector Activo":"Lector Inactivo"}</span> 
                        </>
                }
                    
                    
                </div>

                <input 
                    type="text"  
                    autoComplete="off" 
                    defaultValue={""} //{dataInputText} 
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                    style={{opacity:"0", width:"100px"}}
                    onBlur={()=>setInputInFocus(false)}
                    disabled={disabled}
                />

                {currentUser?.user_id==1?
                <>
                    <FormGroup className="">
                        <FormLabel>Codigo GS1</FormLabel>
                        <FormControl type="text"  autoComplete="off" className="form-control" value={gs1Manual || ""} onChange={e=>setGs1Manual(e?.target?.value)} 
                            onKeyDown={handleManualKeyDown}/>
                    </FormGroup>
                </>
                :
                <></>
                }

            </div>  
        </>
    );
};

export default ZebraScanner;
