import { useState, useCallback } from 'react';

import { Carriers } from 'services';
import { CarrierI, GetCarriersI, GetCarrierI, CreateCarrierI, UpdateCarrierI, CreationResponseI, UpdateResponseI, DeleteResponseI } from 'interfaces';

interface UseCarriersReturn {
  loading: boolean;
  handleGetCarriers: (token: string) => Promise<GetCarriersI>;
  handleGetCarrier: (id: CarrierI['id'], token: string) => Promise<GetCarrierI>;
  handleCreateCarrier: (data: CreateCarrierI, token: string) => Promise<CreationResponseI>;
  handleUpdateCarrier: (id: CarrierI['id'], data: UpdateCarrierI, token: string) => Promise<UpdateResponseI>;
  handleDeleteCarrier: (id: CarrierI['id'], token: string) => Promise<DeleteResponseI>;
}

const useCarriers = (): UseCarriersReturn => {
  const [loading, setLoading] = useState(false);

  const handleGetCarriers = useCallback(async (token: string) => {
    try {
      setLoading(true);
      const response = await Carriers.getCarriers(token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetCarrier = useCallback(async (id: CarrierI['id'], token: string) => {
    try {
      setLoading(true);
      const response = await Carriers.getCarrier(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateCarrier = useCallback(async (data: CreateCarrierI, token: string) => {
    try {
      setLoading(true);
      const response = await Carriers.createCarrier(data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateCarrier = useCallback(async (id: CarrierI['id'], data: UpdateCarrierI, token: string) => {
    try {
      setLoading(true);
      const response = await Carriers.updateCarrier(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteCarrier = useCallback(async (id: CarrierI['id'], token: string) => {
    try {
      setLoading(true);
      const response = await Carriers.deleteCarrier(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [])

  return {
    loading,
    handleGetCarriers,
    handleGetCarrier,
    handleCreateCarrier,
    handleUpdateCarrier,
    handleDeleteCarrier
  };
};

export default useCarriers;