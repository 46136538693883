import React from 'react'

const SuppliersColumns = () => {

const COLUMNS = [
  {
    Header: 'ID',
    id: 'supplier_id',
    accessor: (row) => (
      <>
        {row.supplier_id}
      </>
    ),
  },
  {
    Header: 'Razón social',
    id: 'supplier_business_name',
    accessor: (row) => (
      <>
        {row.supplier_business_name}
      </>
    ),
  },
  {
    Header: 'Documento',
    id: 'supplier_documentation',
    accessor: (row) => (
      <>
        {row?.supplier_documentation ? row.supplier_documentation : '-'}
      </>
    )
  },
]

return {
  COLUMNS
}
}

export default SuppliersColumns
