import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import routes from 'app/routing/routes'

const labelColor = (status) => {
  if (!status) {
    return ""
  }

  switch (status.toUpperCase()) {
    case "MANUAL":
      return "estado-amarillo";
    case "GOOGLE":
      return "estado-verde";
    case "MIGRACION":
      return "estado-rojo";
    default:
      return '';
  }
}

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: intl.formatMessage({ id: 'COLUMN.SHIPMENT_ADDRESSES.ID' }),
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.SHIPMENT_ADDRESSES}/${row.id}`}>
          {row.id}
        </Link>
      ),
    },
    {
      Header: <div className='text-center'>Origen</div>,
      id: 'origin',
      accessor: (row) => (
        <span className={ `badge ${labelColor(row.address.origin)} w-100 justify-content-center`}>
          {row.address.origin}
        </span>
      ),
    },
    {
      Header: 'Nombre / Domicilio',
      id: 'address.name',
      accessor: (row) => (
        <>
          <strong className='m-0 p-0'>{row.name}</strong>
          <p className='m-0 p-0 mt-2'>{row.address.domicilio}</p>
        </>
      )
    },
    {
      Header: 'Localidad',
      id: 'address.localidad_completa',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.address.localidad_completa}</p>
      )
    },
    {
      Header: 'Provincia',
      id: 'address.administrative_area_level_1',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.address?.administrative_area_level_1} ({row.address?.locality})</p>
      )
    },
    {
      Header: <div className='text-center'>{intl.formatMessage({ id: 'COLUMN.SHIPMENT_ADDRESSES.TRACEABILITY' })}</div>,
      id: 'has_traceability',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${Boolean(row.has_traceability) ? 'success' : 'danger'}`}>{Boolean(row.has_traceability) ? 'Sí' : 'No'}</span>
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Tipo de agente</div>,
      id: 'agent_type_id',
      accessor: (row) => (
        <div className='text-center'>
          {row.agent_type_id
            ? <strong>{row.agent_type_name}</strong>
            : <i>Sin tipo de agente</i>
          }
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.SHIPMENT_ADDRESSES.GLN' }),
      id: 'gln',
      accessor: (row) => (
        <>
          {row.gln && row?.gln?.trim() !== ''
            ? row.gln
            : <i>Sin GLN</i>
          }
        </>
      ),
    },
    {
      Header: <div className='text-center'>{intl.formatMessage({ id: 'COLUMN.SHIPMENT_ADDRESSES.OURS' })}</div>,
      id: 'is_ours',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${Boolean(row.is_ours) ? 'success' : 'danger'}`}>{Boolean(row.is_ours) ? 'Sí' : 'No'}</span>
        </div>
      ),
    },
    {
      Header: <div className='text-center'>{intl.formatMessage({ id: 'COLUMN.SHIPMENT_ADDRESSES.IS_ENABLED' })}</div>,
      id: 'is_enabled',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge badge-${Boolean(row.is_enabled) ? 'success' : 'danger'}`}>{Boolean(row.is_enabled) ? 'Sí' : 'No'}</span>
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}