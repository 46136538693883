import { FC } from 'react'
import { Card } from 'react-bootstrap'

import { Loading } from 'components'

import { DetailContainerI } from './types'

const DetailContainer: FC<DetailContainerI> = ({ loading = false, title, status, statusV2, buttons, children }) => {
  return (
    <Card className="mb-8">
      <Card.Header>
        <div className='pt-8 pb-5'>
          <h3 className='me-5 d-inline' style={{verticalAlign:"middle"}}>{title}</h3>
          {status && <span className='fs-8 p-2 rounded ms-5' style={{ backgroundColor: 'yellow' }}>{status}</span>}
          {statusV2}
        </div>
        {/* <div className="badge estado-verde justify-content-center ms-5 fw-normal" style={{ width: '150px', marginTop: '5px' }}>
          Completo
        </div> */}

        {/* <Card.Title className="mb-0">
          {title}
          {status && <span className='fs-8 ms-4 p-2 rounded' style={{ backgroundColor: 'yellow' }}>{status}</span>}
          {statusV2}
        </Card.Title> */}
        {buttons}
      </Card.Header>
      <Card.Body className='pt-0'>
        {loading ? <Loading /> : children}
      </Card.Body>
    </Card>
  )
}

export default DetailContainer