import { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useCustomerSuppliers, useClickOutside } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Actions from './partials/Actions'
import Filter from './Filter'
import { COLUMNS } from './columns'
import DiscountModal from './partials/DiscountModal'
import UpdateDiscountModal from './partials/UpdateDiscountModal'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'product_var_name',
  sortOrder: 'ASC',
}

const CustomerSupplierDiscounts = () => {
  const { id } = useParams()
  const { currentUser } = useAuth()

  const { loading, handleGetCustomerSupplier, handleGetDiscounts, handleAddDiscount, handleUpdateDiscount } = useCustomerSuppliers()
  const [data, setData] = useState(null)
  const [discounts, setDiscounts] = useState([])

  const [modal, setModal] = useState(false)
  const [update, setUpdate] = useState(null)
  const [updateModal, setUpdateModal] = useState(false)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getDetail = useCallback(async () => {
    try {
      const response = await handleGetCustomerSupplier(id)
      setData(response.result)
    } catch (error) {
      setData(null)
      toast.error(error.message)
    }
  }, [handleGetCustomerSupplier])

  const getDiscounts = useCallback(async () => {
    try {
      let params = {}

      if (query && query?.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.clientes_descuentos) {
        const { clientes_descuentos } = currentUser

        params = {
          ...params,
          product_var_id: clientes_descuentos?.product_var_id || '',
          text: clientes_descuentos?.text || '',
          is_active: clientes_descuentos?.is_active || '',
        }
      }

      const response = await handleGetDiscounts(id, params)
      const data = response.data.result

      setDiscounts(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setDiscounts([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetDiscounts, query, currentUser])

  const addDiscounts = async (data) => {
    try {
      const response = await handleAddDiscount(id, data);
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getDiscounts()
    }
  }

  const updateDiscount = async (data) => {
    try {
      const response = await handleUpdateDiscount(id, data)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getDiscounts()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Editar',
      icon: 'bi bi-pencil-square text-primary',
      action: () => {
        setUpdate({ ...currentRow })
        setUpdateModal(true)
      }
    })

    actions.push({
      label: Boolean(currentRow?.is_active) ? 'Deshabilitar' : 'Habilitar',
      icon: Boolean(currentRow?.is_active) ? 'bi bi-x-circle text-danger' : 'bi bi-check-all text-success',
      action: () => updateDiscount({
        ...currentRow,
        is_active: !Boolean(currentRow?.is_active)
      })
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getDetail()
  }, [getDetail])

  useEffect(() => {
    getDiscounts()
  }, [getDiscounts])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <DiscountModal
        show={modal}
        onClose={() => setModal(false)}
        onSave={(values) => {
          setModal(false)
          addDiscounts(values)
        }}
        customerSupplier={data}
      />

      <UpdateDiscountModal
        show={updateModal}
        onClose={() => {
          setUpdateModal(false)
          setUpdate(null)
        }}
        onSave={(values) => {
          updateDiscount(values)
          setUpdateModal(false)
          setUpdate(null)
        }}
        discount={update}
      />

      <DetailContainer
        title={loading ? 'Descuentos de...' : `Descuentos de #${id} - ${data?.business_name}`}
        buttons={<Actions values={data} loading={loading} />}
      >
        <div className='my-8'>
          <div className='d-flex justify-content-between align-items-center mb-8'>
            <label className='form-label m-0'>Descuentos</label>

            <div>
              <Button variant='primary' onClick={() => setModal(true)}>Añadir descuento</Button>
            </div>
          </div>

          <TableContainer
            filter={<Filter handleData={setDiscounts} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />}
          >
            {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

            <CustomTable columns={columns} data={discounts} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} queryMD={query} setQueryMD={setQuery} />
          </TableContainer>
        </div>

        <ContextMenu
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
          ref={contextRef}
        />
      </DetailContainer>
    </>
  )
}

export default CustomerSupplierDiscounts