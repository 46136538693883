import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useCustomerSuppliers } from 'hooks'
import { SelectField, Input } from 'components'
import { Button } from 'react-bootstrap'

const ORIGIN_OPTIONS = [
  { value: '', label: 'Todos' },
  { value: 'GOOGLE', label: 'GOOGLE' },
  { value: 'MANUAL', label: 'MANUAL' },
  { value: 'MIGRACION', label: 'MIGRACION' },
]

const initialValues = {
  patient_id_filter: '',
  nombre: '' ,
  documento: '',
  contacto: '',
  is_enabled: '',
}

const Filter = ({ handleData, handleTotalCount, query, handleQuery }) => {
  const { id } = useParams()
  const { loading, handleGetCustomerSupplierPatients } = useCustomerSuppliers()

  const filterData = async (values) => {
    let params = {
      ...query,
      ...values,
      patient_id_filter: values?.patient_id_filter ? values.patient_id_filter : undefined,
      nombre: values?.nombre ? values.nombre : undefined,
      documento: values?.documento ? values.documento : undefined,
      contacto: values?.contacto ? values.contacto : undefined,
      is_enabled: values?.is_enabled ? values.is_enabled : undefined,
    }

    try {
      const response = await handleGetCustomerSupplierPatients(id, params)
      const data = response.data.result

      handleQuery({ ...params, pageNumber: 1, pageSize: 10 })
      handleData(data)
      handleTotalCount(response.data.metadata.count)
    } catch (error) {
      handleData([])
      handleTotalCount(0)
      toast.error(error.message)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filterData}
    >
      {({ values, errors, handleSubmit }) => {
        return (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit(); // Realizar la acción del filtro aquí
              }
            }}
          >
            <div className='row'>
              <div className='col-2'>
                <Input id='patient_id_filter' name='patient_id_filter' />
                <p className='text-muted'>ID</p>
              </div>

              <div className='col-6'>
                <Input id='nombre' name='nombre' />
                <p className='text-muted'>Nombre completo</p>
              </div>

              <div className='col-2'>
                <Input id='documento' name='documento' />
                <p className='text-muted'>Documento / Nro. afiliado</p>
              </div>

              <div className='col-2'>
                <SelectField
                  id='is_enabled'
                  name='is_enabled'
                  options={[
                    { value: '', label: 'Todos' },
                    { value: '1', label: 'Sí' },
                    { value: '0', label: 'No' },
                  ]}
                  placeholder='Seleccionar'
                  marginTop={'-20px'}
                />
                <p className='text-muted'>Habilitado</p>
              </div>
            </div>

            <div className='row'>
              <div className='col text-end'>
                <Button type='button' variant="primary" onClick={handleSubmit} disabled={loading}>
                  <i className="bi bi-search mb-1" />
                  Buscar
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter