import { CustomTable } from "components";
import React, { useEffect, useMemo, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'

export function AnmatEventsModal({ show, onHide, events, waybill_number}) {

    const [data, setData] = useState([])

    const agregarCamposAdicionales = ()=>{

      const clavesPropias = events.mapped?.map(d => {
        let gtin = d.gtin;
        if (gtin.length < 14) {
          gtin = gtin.padEnd(14, '0');
        }
        return gtin + d.lot_number + d.serial_number
      })
      
      const _anmat = events.original?.map(d=>({...d, encontrado: clavesPropias.includes(d.gtin + d.lote + d.numero_serial) }));
      
      setData(_anmat)

    }
    

    useEffect(()=>{
        agregarCamposAdicionales()
    }, [show])
/*
    {
      "d_evento": "DISTRIBUCION DEL PRODUCTO A UN ESLABON POSTERIOR",
      "f_evento": "26/02/2024",
      "f_transaccion": "26/02/2024 11:23",
      "gln_destino": "7798166400002",
      "gln_origen": "7798157560005",
      "gtin": "07798098725990",
      "id_evento": "1",
      "id_transaccion": "10154691040",
      "id_transaccion_global": "4073076709",
      "lote": "P100575524",
      "n_factura": null,
      "n_remito": "R000502627067",
      "nombre": "HIZENTRA",
      "numero_serial": "0204830498",
      "razon_social_destino": "REDIMER S.A.",
      "razon_social_origen": "ROFINA S A I C F",
      "vencimiento": "31/12/2025"
  }
  */
    const columns =[
      {
        Header: "Producto",
        accessor: 'nombre',
      },
      {
        Header: () => (<div style={{textAlign:"center"}}>GTIN</div>),
        id: 'gtin',
        accessor: (row) => (
          <div className='text-center'>{row.gtin}</div>
        )
      },
      {
        Header:  () => (<div style={{textAlign:"center"}}>Lote</div>),
        id:"lote",
        accessor: (row) =>(
          <div className="text-center">
            {row.lote}
          </div>
        ) 
      },
      {
        Header:  () => (<div style={{textAlign:"center"}}>Nro. serie</div>),
        id: 'numero_serial',
        accessor: (row) =>(
          <div className="text-center">
            {row.numero_serial}
          </div>
        ) 
      },
      {
        Header:  () => (<div style={{textAlign:"center"}}>Origen</div>),
        id: 'gln_origen',
        accessor: (row) =>(
          <>
            <p className="text-center p-0 m-0">
              {row.razon_social_origen}
            </p>
            <p className="text-center p-0 m-0">
              {row.gln_origen}
            </p>
          </>
        ) 
      },
      {
        Header:  () => (<div style={{textAlign:"center"}}>Destino</div>),
        id: 'gln_destino',
        accessor: (row) =>(
          <>
            <p className="text-center p-0 m-0">
              {row.razon_social_destino}
            </p>
            <p className="text-center p-0 m-0">
              {row.gln_destino}
            </p>
          </>
        ) 
      },
      {
        Header:  () => (<div style={{textAlign:"center"}}>Encontrado</div>),
        id:"encontrado",
        accessor: (row) =>(
          <div className="text-center">
              <i className={row.encontrado==true?'bi bi-check-circle text-success':'bi bi-slash-circle text-danger'}></i>
          </div>
        ) 
      },
  
    ]

    return (
      <>
            <Modal size="xl" show={show} onHide={onHide}  backdrop='static' >

            <Modal.Body className="">
              <div className="mt-3 mb-3" style={{fontSize:"1.2em"}}>Transacciones pendientes de confirmación del Remito <b>{waybill_number}</b></div>

              <div className="row">
                <div className="col">
                  <CustomTable columns={columns} data={data || []} />
                </div>
              </div>
              


              {/* <h3>Eventos anmat</h3>                       
              <table className="table gs-2s gy-2s gx-2s">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                        <th>GTIN</th>
                        <th>Evento</th>
                    </tr>
                </thead>
                <tbody>
                    {events?.original?.map((serie, index) => (                                        
                        <tr key={index}>
                            <td style={{fontSize:"0.9rem"}}>{serie.gtin}</td>
                            <td style={{fontSize:"0.9rem"}}>{serie.d_evento}</td>
                        </tr>
                        )
                    )}
                </tbody>
            </table> */}
            </Modal.Body>

            <Modal.Footer style={{borderTop: "0 none"}}>
              <Button variant="secondary" onClick={onHide}>Salir</Button>
            </Modal.Footer>


          </Modal>
    </>
    )
  }
  