const initialValues = {
  id: '',
  name: '',
  address_id: '',
  address: {
    id: '',
    shipment_address_id: '',
    route: '',
    street_number: '',
    floor: '',
    locality: '',
    locality_custom: '',
    sublocality_level_1: '',
    country: '',
    postal_code: '',
    postal_code_suffix: '',
    formatted_address: '',
    place_id: '',
    google_result: '',
    latitude: '',
    longitude: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    custom_address_level1: '',
    custom_address_level1_label: '',
    custom_address_level2: '',
    custom_address_level2_label: '',
  },
  contact_id: '',
  contact: {
    name: '',
    email: '',
    phone: '',
    mobile: '',
    mobile_suffix: '',
    observations: '',
  },
  observations: '',
  has_traceability: true,
  agent_type_id: '',
  agent_type_name: '',
  gln: '',
  verifarma: false,
  is_ours: false,
  is_enabled: true,
}

export {
  initialValues,
}